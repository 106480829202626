import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal, Table } from 'react-bootstrap';

import { toast } from 'react-toastify'

import moment from 'moment'

import Dialog from '../../services/Dialogs';

import { SERVER_ } from '../../config';


const ModalInformacionAsignamientosVehiculo = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, vehiculo } = params

  const dialog = new Dialog();


  const [envio_server, setSeEnvio] = useState(false);

  const devolverVehiculo = async (uuid_asignamiento:any, event:any) => {
    await dialog.open({
      accept: 'Si',
      cancel: 'Espera',
      dialogClass: '',
      message: '¿Estás seguro de la información?',
      target: event.target,
      template: ''
    });

    let dialogo = await dialog.waitForUser();
    if (dialogo) {
      try {
        setSeEnvio(true);
        let res_ = await RequestService.update(`vehiculo/devolver/${uuid_asignamiento}`, { });

        if (res_) {
          toast.success('El registro se hizo correctamente');
          setTimeout(() => {
            handleClose();
          }, 0);
        } else {
          toast.error('Problemas...');
        }
      } catch (e:any) {
        toast.error(e.response.data);
      }
    }
  };


  return (
    <>
      <Modal show={show} size={'lg'} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Historial de Asignamientos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(vehiculo.idusuario_asignado)
          }


          <div className="row">
            <div className="col-sm-3">
                <p className="nm"><small>Marca</small></p>
                <h4>{vehiculo.marca}</h4>
            </div>
            <div className="col-sm-3">
                <p className="nm"><small>Modelo</small></p>
                <h4>{vehiculo.modelo}</h4>
            </div>
            <div className="col-sm-3">
                <p className="nm"><small>Placas</small></p>
                <h4>{vehiculo.placas}</h4>
            </div>
            <div className="col-sm-3">
                <p className="nm"><small>Número de Motor</small></p>
                <h4>{vehiculo.numero_motor}</h4>
            </div>
          </div>



          {

            vehiculo.idusuario_asignado && (

                <React.Fragment>
                  
                <p className="nm"><small>Asignado actualmente a</small></p>

                  {
                    // JSON.stringify(vehiculo.idusuario_asignado.nombres)
                  }
                  <h4>{vehiculo.idusuario_asignado.nombres} {vehiculo.idusuario_asignado.paterno} {vehiculo.idusuario_asignado.materno} </h4>

                </React.Fragment>

              )

          }





          {
            vehiculo.historial_asignamiento?.length > 0 && (
                <>
                <p className="nm"><small>Historial de Asignamientos</small></p>

                  {
                    // JSON.stringify(vehiculo.historial_asignamiento)
                }

                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Asignación</th>
                        <th>Devolución</th>
                        <th>Documento</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vehiculo.historial_asignamiento.map((asignamiento:any,number_asignamiento:number) => (
                        <tr key={number_asignamiento}>
                          <td className="">{`${asignamiento.idusuario.nombres} ${asignamiento.idusuario.paterno} ${asignamiento.idusuario.materno}`}</td>
                          <td>{moment(asignamiento.fecha_asignacion).format('YYYY-MM-DD HH:mm')} hrs</td>
                          <td>{asignamiento.fecha_devolucion ? moment(asignamiento.fecha_devolucion).format('YYYY-MM-DD HH:mm') + ' hrs' : <button className="btn btn-sm btn-link" onClick={(e:any)=>{devolverVehiculo(asignamiento.uuid,e)}}>Registrar</button>}</td>
                          <td style={{width:100, textAlign:'center'}}>
                            <a href={`${SERVER_}vehiculo/documento_asignacion/${asignamiento.uuid}`} target="_blank" rel="noopener noreferrer"><small>Abrir</small></a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>


                </>
              )
          }


        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalInformacionAsignamientosVehiculo;