import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'



import { tipoPresupuesto, calcularTiempoTranscurrido } from '../../services/funcionesPublicas'

import ModalDetallePago from './ModalDetallePago'

import { SERVER_ } from  '../../config';


const Pagos = () =>{

  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ presupuestos, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let response : any = await RequestService.getEndPoint(`busqueda_pagos/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true])


  const todos = () =>{
    setEstatus([true])
  }
  const ninguno = () =>{
    setEstatus([false])
  }





  React.useEffect(() => {
    cargarMiReporte(0) 
  }, []);





  const [ presupuesto_seleccionado, setPresupuesto ] = useState<any>({})

  const [ show_ModalDetalleVisitaCobranza, setModalDetalleVisitaCobranza ] = useState<boolean>(false)
  const handleShow_ModalDetalleVisitaCobranza = (presupuesto:any) => {
    setPresupuesto(presupuesto)
    setModalDetalleVisitaCobranza(true)
  }
  const handleClose_ModalDetalleVisitaCobranza = () => {
    setModalDetalleVisitaCobranza(false)
  }


      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Pagos</h4>
                    <hr/>



                    <div className="row">
                    
                        <div className="mb-2">
                            <label className="form-label">Búsqueda</label>
                            <div className="hstack gap-3">
                              <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                              <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                              <div className="vr"></div>
                              <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                            </div>
                        </div>
                        
                        <div>
                          <Form.Check inline name="group1" type="checkbox" label="CAPTURA" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
                        </div>
                        
                      <ul className="list-inline">
                        <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                      </ul>

                    </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     presupuestos.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Folio</th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              presupuestos.map( (presupuesto:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                                    <td className="v text-left" style={{"width":"160px"}}>
                                                        <p className="nm"><b>{presupuesto.folio}</b></p>
                                                    </td>
                                                    <td className="v text-left" >
                                                        <p className="nm"><small><b>{presupuesto.nombre_boutique}</b></small></p> 
                                                    </td>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                        {
                                                          // JSON.stringify(presupuesto.idregistro_ingreso_egreso)
                                                        }

                                                        {
                                                        // JSON.stringify(presupuesto.visita)
                                                        }
                                                    </td>



                                                    <td className="v text-left"  >
                                                      <p className="nm"><small>{presupuesto.descripcion}</small> <a href={`${SERVER_}ficha_presupuesto/${presupuesto.uuid_presupuesto}`} target="_blank"><i className="fa fa-link"/></a> </p>
                                                    </td>

                                                    <td className={`v text-center ${presupuesto.tipo_presupuesto}`} style={{"width":"180px"}} >
                                                      <p className="nm">{tipoPresupuesto(presupuesto.tipo_presupuesto)}</p>
                                                    </td>



                                                    <td className={`v text-center estatus_presupuesto_${presupuesto.idestatus_presupuesto.id}`}style={{"width":"220px"}} >
                                                      <p className="nm">{presupuesto.idestatus_presupuesto.estatus}</p>
                                                      {
                                                        // [5].indexOf(presupuesto.idestatus_presupuesto.id)
                                                        JSON.stringify(presupuesto.idestatus_presupuesto)

                                                      }
                                                      {
                                                        presupuesto.visita && (
                                                            <React.Fragment>
                                                              {
                                                                // JSON.stringify(presupuesto.visita.idestatus_atencion)
                                                              }
                                                              {' '}
                                                              {
                                                                // presupuesto.idestatus_presupuesto.id
                                                              }
                                                              {' '}
                                                              <small><b>{presupuesto.visita.idestatus_atencion.estatus}</b> { presupuesto.visita.folio }</small>
                                                              
                                                            </React.Fragment>
                                                          )
                                                      }
                                                    </td>
                                                    <td className="v text-left" style={{"width":"100px"}}>
                                                        <p className="nm"><b>{moment(presupuesto.fecha_creacion).format('YYYY-MM-DD')}</b></p>
                                                        <p className="nm"><small>{calcularTiempoTranscurrido(presupuesto.fecha_creacion)}</small></p>
                                                    </td>
                                                    <td style={{"width":"40px"}}>
                                                      {
                                                            <React.Fragment>
                                                              {
                                                                [7].indexOf(presupuesto.idestatus_presupuesto.id) > -1 &&  (
                                                                    <React.Fragment>
                                                                        {
                                                                          // JSON.stringify(presupuesto.idregistro_ingreso_egreso)
                                                                        }
                                                                        <button type="button" onClick={(e:any)=>{handleShow_ModalDetalleVisitaCobranza(presupuesto)}} className="btn btn-sm btn-secondary"><i className="fa fa-money"/></button>
                                                                    </React.Fragment> 
                                                                  )
                                                              }
                                                            </React.Fragment>
                                                      }
                                                    </td>


                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}

                    
                </div>
              </div>

            </div>





  {
    show_ModalDetalleVisitaCobranza && (
      <ModalDetallePago show={show_ModalDetalleVisitaCobranza} handleClose={handleClose_ModalDetalleVisitaCobranza} presupuesto={presupuesto_seleccionado} />
      )

  }




          </>

      );
  

}

export default Pagos
