import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import FullCalendar from '@fullcalendar/react'
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import dayGridPlugin from '@fullcalendar/daygrid'


import moment from 'moment'

import { obtenerFechasConTecnico } from '../../services/funcionesPublicas'

const ModalPreviewCalendarioZonaProgramacion = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, configuracion, index_areas } = params





  const [bookings, setBookings] = useState<any>([ ])

  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Preview Visitas por Zona</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <small>Zona</small>
          <h3>{configuracion.areas[index_areas].area}</h3>
          <hr/>
          {
            // JSON.stringify(obtenerFechasConTecnico(configuracion.areas[index_areas].subareas))
          }

      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin ]}
        initialView="dayGridMonth"
        events={obtenerFechasConTecnico(configuracion.areas[index_areas].subareas)}
      />


          {
            // JSON.stringify(configuracion.areas[index_areas].subareas)
          }
          {
            // JSON.stringify(configuracion.areas[index_areas])
          }
        </Modal.Body> 
      </Modal>
    </>
  );
}


export default ModalPreviewCalendarioZonaProgramacion;