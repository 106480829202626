import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import Pagination from "../../services/Pagination";
import { Table, Form, Button } from 'react-bootstrap'





import ModalDetallePresupuesto from './ModalDetallePresupuesto'
// import ModalValidarPresupuesto from './ModalValidarPresupuesto'

import ModalValidacionGerenciaPresupuesto from './ModalValidacionGerenciaPresupuesto'

import { tipoPresupuesto, calcularTiempoTranscurrido } from '../../services/funcionesPublicas'
import { rol } from '../../services/rol'

import './colores_presupuestos.scss'



const PresupuestosAdministracion = () =>{


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 40
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`operacion/presupuestos/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(0)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true,true,true,true,true,true,true,true,true])


  const todos = () =>{
    setEstatus([true,true,true,true,true,true,true,true,true])
  }
  const ninguno = () =>{
    setEstatus([false,false,false,false,false,false,false,false,false])
  }



  const [ presupuesto_seleccionado, setPresupuesto ] = useState<any>({})

  const [ show_ModalDetallePresupuesto, setModalDetallePresupuesto ] = useState<boolean>(false)
  const handleShow_ModalDetallePresupuesto = (presupuesto:any) => {
    setPresupuesto(presupuesto)
    setModalDetallePresupuesto(true)
  }
  const handleClose_ModalDetallePresupuesto = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalDetallePresupuesto(false)
  }

  // const [ show_ModalValidarPresupuesto, setModalValidarPresupuesto ] = useState<boolean>(false)
  // const handleShow_ModalValidarPresupuesto = (presupuesto:any) => {
  //   setPresupuesto(presupuesto)
  //   setModalValidarPresupuesto(true)
  // }
  // const handleClose_ModalValidarPresupuesto = () => {
  //   cargarMiReporte(currentPage===0?0:currentPage-1)
  //   setModalValidarPresupuesto(false)
  // }

  const [ show_ModalValidacionGerenciaPresupuesto, setModalValidacionGerenciaPresupuesto ] = useState<boolean>(false)
  const handleShow_ModalValidacionGerenciaPresupuesto = (presupuesto:any) => {
    setPresupuesto(presupuesto)
    setModalValidacionGerenciaPresupuesto(true)
  }
  const handleClose_ModalValidacionGerenciaPresupuesto = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalValidacionGerenciaPresupuesto(false)
  }




  React.useEffect(() => {
    cargarMiReporte(0)
  }, []);



      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Aprobación de Presupuestos</h4>
                    <hr/>



        <div className="row">
        
            <div className="mb-2">
                <label className="form-label">Búsqueda</label>
                <div className="hstack gap-3">
                  <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                  <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                  <div className="vr"></div>
                  <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                </div>
            </div>
            
                        <div>

                                                    
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="NUEVA SOLICITUD" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="CREANDO PRESUPUESTO" checked={arreglo_estatus[1]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}} id={`opciones_2`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="APROBACIÓN GERENCIA" checked={arreglo_estatus[2]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}} id={`opciones_3`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="APROBACIÓN CLIENTE" checked={arreglo_estatus[3]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}} id={`opciones_4`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="ACEPTADA POR CLIENTE" checked={arreglo_estatus[4]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[4] = e.target.checked;  setEstatus(ne);}} id={`opciones_5`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="INCLUIDO EN IGUALA" checked={arreglo_estatus[5]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[5] = e.target.checked;  setEstatus(ne);}} id={`opciones_6`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="ACEPTADA" checked={arreglo_estatus[6]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[6] = e.target.checked;  setEstatus(ne);}} id={`opciones_7`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="RECHAZADA" checked={arreglo_estatus[7]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[7] = e.target.checked;  setEstatus(ne);}} id={`opciones_8`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="CANCELADA" checked={arreglo_estatus[8]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[8] = e.target.checked;  setEstatus(ne);}} id={`opciones_9`} />


                        </div>


          <ul className="list-inline">
            <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
          </ul>

        </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Folio</th>
                                              <th scope="col">Descripción</th>
                                              <th scope="col">Boutique</th>
                                              <th scope="col">Creó</th>
                                              <th scope="col">Estatus</th>
                                              <th scope="col">Tipo</th>
                                              <th scope="col">Fecha</th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (presupuesto:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12, background : (["ROLE_BOUTIQUE"].indexOf(presupuesto.idusuario.rol) > -1 ? '#e8e8e8' : '') }}>
                                                    <td onClick={(e:any)=>{handleShow_ModalDetallePresupuesto(presupuesto)}} className={`v text-left pointer `} style={{"width":"160px",background : presupuesto.entra_iguala ? '#f4dd7e': '' }}>
                                                        <p className="nm"><b>{presupuesto.folio}</b></p>
                                                    </td>
                                                    <td className="v text-left"  >
                                                      <small><b>{presupuesto.descripcion}</b></small>
                                                    </td>
                                                    <td className="v text-left"  >
                                                      {presupuesto.idboutique&&(<small>{presupuesto.idboutique?.nombre_boutique}</small>)}
                                                    </td>
                                                    <td className="v text-left" style={{"width":"200px"}} >
                                                      <p className="nm"><b>{presupuesto.idusuario.nombres}</b> <small>{presupuesto.idusuario.paterno} {presupuesto.idusuario.materno}</small></p>
                                                      {rol(presupuesto.idusuario.rol)}
                                                    </td>
                                                    <td className={`v text-center estatus_presupuesto_${presupuesto.idestatus_presupuesto.id}`}style={{"width":"260px"}} >
                                                      <p className="nm">{presupuesto.idestatus_presupuesto.estatus}</p>
                                                    </td>


                                                    <td className={`v text-left ${presupuesto.tipo_presupuesto}`} style={{"width":"150px"}} >
                                                      <p className="nm">{tipoPresupuesto(presupuesto.tipo_presupuesto)}</p>
                                                    </td>



                                                    <td className="v text-left" style={{"width":"140px"}}>
                                                        <p className="nm"><b>{moment(presupuesto.fecha_creacion).format('YYYY-MM-DD hh:mm:ss')}</b></p>
                                                        <p className="nm"><small>{calcularTiempoTranscurrido(presupuesto.fecha_creacion)}</small></p>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"50px",background : presupuesto.entra_iguala ? '#f4dd7e': '' }}>
                                                        {
                                                          // JSON.stringify(presupuesto.fue_calendarizado)
                                                        }
                                                        {
                                                          presupuesto.fue_calendarizado && ( <i  className="fa fa-calendar"/> )
                                                        }
                                                        {
                                                          // <Button onClick={(e:any)=>{handleShow_ModalDetallePresupuesto(cual)}} size="sm" variant="warning"><i className="fa fa-address-book-o"/></Button>
                                                        }



                                                        {
                                                          [3].indexOf(presupuesto.idestatus_presupuesto.id) > -1 && (
                                                                <Button onClick={(e:any)=>{handleShow_ModalValidacionGerenciaPresupuesto(presupuesto)}} size="sm" variant="warning"><i className="fa fa-address-book-o"/></Button>
                                                            )
                                                        }
                                                        
                                                        
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}
                    
                </div>
              </div>

            </div>





  {
    show_ModalDetallePresupuesto && (
      <ModalDetallePresupuesto show={show_ModalDetallePresupuesto} handleClose={handleClose_ModalDetallePresupuesto} presupuesto={presupuesto_seleccionado} />
      )

  }


  
  {
    show_ModalValidacionGerenciaPresupuesto && (
      <ModalValidacionGerenciaPresupuesto show={show_ModalValidacionGerenciaPresupuesto} handleClose={handleClose_ModalValidacionGerenciaPresupuesto} presupuesto={presupuesto_seleccionado}  />
      )

  }





          </>

      );
  

}

export default PresupuestosAdministracion