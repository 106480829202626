import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'


import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'


import Dialog from '../../services/Dialogs'


import ModalNuevoEquipoCritico from './ModalNuevoEquipoCritico'
import ModalEditarEquipoCritico from './ModalEditarEquipoCritico'


import ModalListaSupervision from './ModalListaSupervision'



const EquiposCriticos = () =>{

      const [ equipos, setRegistro ] = useState<any>([])


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 40
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  // const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`equipos_criticos/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setRegistro(response.data.resultados)
      setCargando(false)
    }
    catch(e:any){

      if (e.response?.status) {
        switch (e.response.status) {
          case 404:
            toast.error("Debes escribir algo")
            break;
          default:
            toast.error(e.response?.data)
        }
      }
      else{
        toast.error("Problemas con la busqueda")
      }
      setCargando(false)

    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true])


  const todos = () =>{
    setEstatus([true])
  }
  const ninguno = () =>{
    setEstatus([false])
  }


  React.useEffect(() => {
    busqueda()
  }, []);





  const [ show_ModalNuevoEquipoCritico, setModalNuevoEquipoCritico ] = useState<boolean>(false)
  const handleShow_ModalNuevoEquipoCritico = () => {
    setModalNuevoEquipoCritico(true)
  }
  const handleClose_ModalNuevoEquipoCritico = () => {
        busqueda()
    setModalNuevoEquipoCritico(false)
  }



  const [ equipo_seleccionado, setEquipoSeleccionado ] = useState<any>({})
  const [ show_ModalEditarEquipoCritico, setModalEditarEquipoCritico ] = useState<boolean>(false)
  const handleShow_ModalEditarEquipoCritico = (equipo:any) => {
    setEquipoSeleccionado(equipo)
    setModalEditarEquipoCritico(true)
  }
  const handleClose_ModalEditarEquipoCritico = () => {
    setModalEditarEquipoCritico(false)
  }



  const [ show_ModalListaSupervision, setModalListaSupervision ] = useState<boolean>(false)
  const handleShow_ModalListaSupervision = (equipo:any) => {
    setEquipoSeleccionado(equipo)
    setModalListaSupervision(true)
  }
  const handleClose_ModalListaSupervision = () => {
        busqueda()
    setModalListaSupervision(false)
  }





const dialog = new Dialog();

const [ envio_server, setSeEnvio ] = useState<boolean>(false)
const eliminarEquipo = async (event:any,uuid_equipo:string) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.delete( `equipo_critico/eliminar/${uuid_equipo}` )

        if(res_){
          toast.success('El Registro de Equipo Crítico fue eliminado')
          setTimeout(()=>{
            busqueda()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){

          if (e.response?.status) {
            switch (e.response.status) {
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setCargando(false)

        }
        
      }
     
}




    





      return (
          <>

              
                <h6>Equipos Criticos</h6>


              




        <div className="row">
        
            <div className="mb-2">
                <label className="form-label">Búsqueda</label>
                <div className="hstack gap-3">
                  <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                  <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                  <div className="vr"></div>
                  <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                </div>
            </div>
            
            
            <ul className="list-inline">
              <li className="list-inline-item pointer" onClick={handleShow_ModalNuevoEquipoCritico}><p className="nm">Agregar Equipo</p></li>
            </ul>

        </div>




                          {  (!cargando_) && (

                              <>

                                   {
                                     equipos.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                          <table className="table table-sm">
                                            <thead>
                                              <tr>
                                                <th scope="col">Tipo</th>
                                                <th scope="col">Marca</th>
                                                <th scope="col">Modelo</th>
                                                <th scope="col">Capacidad</th>
                                                <th scope="col">Voltaje</th>
                                                <th scope="col">Enfriador</th>
                                                <th scope="col">Calentador</th>
                                                <th scope="col"> </th>
                                                <th scope="col"> </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                            {
                                              equipos.map((equipo:any)=>

                                                    <tr key={ Math.random().toString(36).substr(2, 9) }>
                                                      <td >{equipo.tipo_equipo}</td>
                                                      <td style={{width:140}}><p className="nm"><b>{equipo.marca}</b></p></td>
                                                      <td style={{width:200}}><p className="nm">{equipo.modelo}</p></td>
                                                      <td className="v text-right" style={{width:150}}><p className="nm">{equipo.capacidad}</p></td>
                                                      <td className="v text-right" style={{width:100}}><p className="nm">{equipo.voltaje_operacion}</p></td>
                                                      <td className="v text-center" style={{width:60}}><p className="nm">{equipo.enfriador?'SI':''}</p></td>
                                                      <td className="v text-center" style={{width:60}}><p className="nm">{equipo.calefactor?'SI':''}</p></td>
                                                      <td className="v text-center" style={{width:40}}>
                                                        <button onClick={(e:any)=>{handleShow_ModalListaSupervision(equipo)}} className="btn btn-sm btn-info"><i className="fa fa-list"/></button>
                                                      </td>
                                                      <td className="v text-center" style={{width:40}}>
                                                        <button onClick={(e:any)=>{handleShow_ModalEditarEquipoCritico(equipo)}} className="btn btn-sm btn-warning"><i className="fa fa-edit"/></button>
                                                      </td>
                                                      <td style={{width:40}}>
                                                        <button onClick={(e:any)=>{eliminarEquipo(e,equipo)}} className="btn btn-sm btn-danger"><i className="fa fa-trash"/></button>
                                                      </td>

                                                    </tr>

                                                )
                                            }

                                            </tbody>
                                          </table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}




              {
                // JSON.stringify(equipos)
              }




  {
    show_ModalNuevoEquipoCritico && (
      <ModalNuevoEquipoCritico show={show_ModalNuevoEquipoCritico} handleClose={handleClose_ModalNuevoEquipoCritico}  />
      )

  }

  {
    show_ModalEditarEquipoCritico && (
      <ModalEditarEquipoCritico show={show_ModalEditarEquipoCritico} handleClose={handleClose_ModalEditarEquipoCritico} equipo={equipo_seleccionado} />
      )

  }



  {
    show_ModalListaSupervision && (
      <ModalListaSupervision show={show_ModalListaSupervision} handleClose={handleClose_ModalListaSupervision} equipo={equipo_seleccionado} />
      )

  }

          </>

      );
  

}

export default EquiposCriticos

