import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'


import ModalNuevaCuenta from './ModalNuevaCuenta'
import ModalEditarCuenta from './ModalEditarCuenta'



const ModalCuentasBancarias = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params



  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 30
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`cuentas_bancarias/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&perPage=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true,true])


  const todos = () =>{
    setEstatus([true,true])
  }
  const ninguno = () =>{
    setEstatus([false,false])
  }



      React.useEffect(() => {
        busqueda()
      }, []);


  // const [ show_ModalNuevoIngresoEgreso, setModalNuevoIngresoEgreso ] = useState<boolean>(false)
  // const handleShow_ModalNuevoIngresoEgreso = () => {
  //   setModalNuevoIngresoEgreso(true)
  // }
  // const handleClose_ModalNuevoIngresoEgreso = () => {
  //   setModalNuevoIngresoEgreso(false)
  // }


  const [ cuenta_bancaria, setCuenta ] = useState<any>({})
  // const [ show_ModalEditarIngresoEgreso, setModalEditarIngresoEgreso ] = useState<boolean>(false)
  // const handleShow_ModalEditarIngresoEgreso = (registro:any) => {
  //   setRegistroIngresoEgreso(registro)
  //   setModalEditarIngresoEgreso(true)
  // }
  // const handleClose_ModalEditarIngresoEgreso = () => {
  //       busqueda()
  //   setModalEditarIngresoEgreso(false)
  // }





  const [ show_ModalNuevaCuenta, setModalNuevaCuenta ] = useState<boolean>(false)
  const handleShow_ModalNuevaCuenta = () => {
    setModalNuevaCuenta(true)
  }
  const handleClose_ModalNuevaCuenta = () => {
    setModalNuevaCuenta(false)
  }



  const [ show_ModalEditarCuenta, setModalEditarCuenta ] = useState<boolean>(false)
  const handleShow_ModalEditarCuenta = (cuenta_bancaria:any) => {
    setCuenta(cuenta_bancaria)
    setModalEditarCuenta(true)
  }
  const handleClose_ModalEditarCuenta = () => {
    setModalEditarCuenta(false)
  }

  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Cuentas Bancarias</Modal.Title>
        </Modal.Header>
        <Modal.Body>



        <div className="row">
        
            <div className="mb-2">
                <label className="form-label">Búsqueda</label>
                <div className="hstack gap-3">
                  <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                  <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                  <div className="vr"></div>
                  <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                </div>
            </div>
            

            
          <ul className="list-inline">
            <li className="list-inline-item"><small className="cursor" onClick={()=>{handleShow_ModalNuevaCuenta()}}>Nuevo Registro</small></li>
          </ul>

        </div>




                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Banco</th>
                                              <th scope="col">Descripción de Cuenta</th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (cuenta:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                                    <td className="v text-left" style={{"width":"160px"}}>
                                                        <p className="nm"><small> {cuenta.banco} </small></p>
                                                    </td>
                                                    <td className="v text-left"  >
                                                        <p className="nm"><small><b>{cuenta.descripcion}</b></small></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"40px"}}>
                                                        <button type="button" onClick={(e:any)=>{handleShow_ModalEditarCuenta(cuenta)}} className="btn btn-sm btn-outline-warning"><i className="fa fa-edit"/></button>
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}




        </Modal.Body>

      </Modal>




  {
    show_ModalNuevaCuenta && (
      <ModalNuevaCuenta show={show_ModalNuevaCuenta} handleClose={handleClose_ModalNuevaCuenta}  />
      )

  }





  {
    show_ModalEditarCuenta && (
      <ModalEditarCuenta show={show_ModalEditarCuenta} handleClose={handleClose_ModalEditarCuenta} cuenta_bancaria={cuenta_bancaria} />
      )

  }


    </>
  );
}


export default ModalCuentasBancarias;