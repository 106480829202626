import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'

import ModalMensajesTicket from '../Tickets/ModalMensajesTicket'

import ModalNuevoTicketOperarios from './ModalNuevoTicketOperarios'



import { rol } from '../../services/rol'
import { calcularTiempoTranscurrido } from '../../services/funcionesPublicas'


const MisTickets = () =>{



  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 30
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let response : any = await RequestService.getEndPoint(`mis_tickets/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  // const busqueda = async () =>{
  //   cargarMiReporte(0)
  //   setCurrentPage(1)
  // }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true,true,true,true])


  const todos = () =>{
    setEstatus([true,true,true,true])
  }
  const ninguno = () =>{
    setEstatus([false,false,false,false,false])
  }



    React.useEffect(() => {
      cargarMiReporte(0)
    }, []);



  const [ ticket_seleccionado, setTicketSeleccionado ] = useState<any>({})
  const [ show_ModalMensajesTicket, setModalMensajesTicket ] = useState<boolean>(false)
  const handleShow_ModalMensajesTicket = (ticket:any) => {
    setTicketSeleccionado(ticket)
    setModalMensajesTicket(true)
  }
  const handleClose_ModalMensajesTicket = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalMensajesTicket(false)
  }



  const [ show_ModalNuevoTicket, setModalNuevoTicket ] = useState<boolean>(false)
  const handleShow_ModalNuevoTicket = () => {
    setModalNuevoTicket(true)
  }
  const handleClose_ModalNuevoTicket = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalNuevoTicket(false)
  }


      return (
          <>
              <div className="container-fluid">


                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">


                         <h3 className="mt-3">Mis Tickets</h3>


              
                          <div className="mt-2 mb-2">
                          

                              <div>
                                <Form.Check inline name="group1" type="checkbox" label="NUEVO" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
                                <Form.Check inline name="group1" type="checkbox" label="EN ATENCIÓN" checked={arreglo_estatus[1]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}} id={`opciones_2`} />
                                <Form.Check inline name="group1" type="checkbox" label="ATENDIDO" checked={arreglo_estatus[2]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}} id={`opciones_3`} />
                                <Form.Check inline name="group1" type="checkbox" label="CERRADO" checked={arreglo_estatus[3]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}} id={`opciones_4`} />
                              </div>
                              
                          <ul className="list-inline">
                            <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalNuevoTicket()}}>Nuevo Ticket</small></li>
                            <li className="list-inline-item"><small className="pointer" onClick={()=>{cargarMiReporte(0)}}>Recargar Lista</small></li>
                          </ul>

                          </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                       <div className="table-responsive">
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">Tipo / Estatus</th>
                                                <th scope="col">Boutique</th>
                                                <th scope="col">Descripción</th>
                                                <th scope="col">Creación</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                  resultados.map( (ticket:any,indice:number) =>
                                                      <tr key={ Math.random().toString(36).substr(2, 9) }>
                                                        <td className={`v pointer estatus_ticket_${ticket.idestatus_ticket.id}`} onClick={(e:any)=>{console.log(ticket)}} style={{width:200}}>
                                                          <p className="nm"><small><b>{ticket.idtipo_ticket.tipo_ticket}</b></small></p>
                                                          <p className="nm">{ticket.idestatus_ticket.estatus}</p>
                                                        </td>
                                                        <td className="v" style={{width:220}}>
                                                          {
                                                            ticket.idboutique && (
                                                                    <p className="nm"><small>{ticket.idboutique.nombre_boutique}</small></p>
                                                            )
                                                          }
                                                        </td>
                                                        <td className="v">
                                                          <p className="nm"><small>{ticket.descripcion_ticket}</small></p>
                                                          <p className="nm">{calcularTiempoTranscurrido(ticket.fecha_creacion_ticket)}</p>
                                                        </td>
                                                        <td className="v" style={{width:120}}>

                                                          <p className="nm">{moment(ticket.fecha_creacion_ticket).format('YYYY-MM-DD')}</p>
                                                          <p className="nm"><small>{moment(ticket.fecha_creacion_ticket).format('HH:mm')} hrs</small></p>
                                                        </td>
                                                        <td className="v" style={{width:40}}>
                                                            <button type="button" className="btn btn-sm btn-primary position-relative" onClick={(e:any)=>{handleShow_ModalMensajesTicket(ticket)}}>
                                                              <i className="fa fa-envelope"/>
                                                              {
                                                                ticket.mensajes_no_leidos > 0 && (
                                                                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                                    {ticket.mensajes_no_leidos}
                                                                  </span>
                                                                )
                                                              }
                                                            </button>
                                                        </td>
                                                      </tr>
                                                   )
                                                }
                                            </tbody>
                                          </table>
                                        </div>



                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }

                                   {
                                    resultados.length === 0 && (

                                        <>

                                            <div className="alert alert-warning" role="alert">
                                              <i className="fa fa-ticket"/> Aún no cuentas con Tickets
                                            </div>

                                        </>

                                      )
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}



                    </div>
                  </div>


              </div>
              

              
  {
    show_ModalMensajesTicket && (
      <ModalMensajesTicket show={show_ModalMensajesTicket} handleClose={handleClose_ModalMensajesTicket} ticket={ticket_seleccionado} />
      )

  }



  {
    show_ModalNuevoTicket && (
      <ModalNuevoTicketOperarios show={show_ModalNuevoTicket} handleClose={handleClose_ModalNuevoTicket} boutique_cerrada={false} />
      )

  }




          </>

      );
  

}

export default MisTickets
