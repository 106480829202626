import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';



import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import Dialog from '../../services/Dialogs'

import moment from 'moment'

import { toast } from 'react-toastify'

import { SERVER_ } from  '../../config';

import { getBase64 } from '../../services/funcionesPublicas'

const ModalClienteCambiarLogo = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, cliente } = params


  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          logotipo : yup.string().required(mensajes_validacion_generico),

  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)

  const [preview, setPreview] = useState<any>(null);

  const handleFileChange = (event:any, setFieldValue:any) => {
    const file : any = event.target.files[0];

    if (file) {
      setFieldValue('logotipo', file);

      // Crear una URL de objeto para la vista previa
      const filePreview = URL.createObjectURL(file);
      setPreview(filePreview);
    }
  };

  const enviarFormulario = async (registro:any,event:any) =>{

   if (registro.logotipo) {
    const base64 = await getBase64(registro.logotipo);
    registro.logotipo = base64;
  }


        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.update( `cliente/actualizar_logo/${cliente.uuid_cliente}` , { ...registro })

          if(res_){
            toast.success('El Logo del cliente fue cambiado')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }


  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Cambiar Logo del Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(cliente)
          }
          <div className="row">
            <div className="col">
              <small>Cliente</small>
              <h5>{cliente.cliente}</h5>
            </div>
            <div className="col-2">
              <img src={`${SERVER_}cliente/logotipo/${cliente.uuid_cliente}`} style={{width:'100%'}} />
            </div>
          </div>




            <Formik
              validationSchema={schema}
              onSubmit={(values,e)=>{enviarFormulario(values, e)}}
              initialValues={{

                    logotipo : ""

              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form id='my-form' noValidate onSubmit={handleSubmit}>

                   {
                    // JSON.stringify(values)
                  }

                           <Form.Group className="mb-3" controlId="logotipo">
                            <Form.Label>Archivo</Form.Label>
                            <Form.Control
                              accept=".jpg, .jpeg, .png"
                              type="file"
                              name="logotipo"
                              onChange={(e) => handleFileChange(e, setFieldValue)}
                              isInvalid={!!errors.logotipo}
                            />
                            <Form.Control.Feedback type="invalid">{errors.logotipo}</Form.Control.Feedback>
                            {/* Vista previa del logotipo seleccionado */}
                            {preview && (
                              <img className="mt-2" src={preview} style={{width:'100%' }} />
                            )}
                          </Form.Group>



                </Form>
              )}
            </Formik>






        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalClienteCambiarLogo;