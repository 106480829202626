import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import moment from 'moment'

import { formatearFecha } from '../../services/funcionesPublicas'
import { getStartAndEndDates, obtenerInicioFinSemanaActual, formatToTwoDecimals, calcularTiempoTranscurrido } from '../../services/funcionesPublicas'



import ModalDetalleActividad from '../Operacion/ModalDetalleActividad'


import ModalBoutiqueEncuestaServicio from './ModalBoutiqueEncuestaServicio'


const ModalBoutiqueVisitas = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, boutique } = params

  const [ fecha, setFecha ] = useState<any>( new Date() )



  const [ servicios, setAsistencias ] = useState<any>([])
  const [ cargando_, setSeEnvio ] = useState<boolean>(false)
  const cargarAsistencias = async(fecha_inicio:any,fecha_fin:any)=>{
    setFecha(fecha)
    try{
      setSeEnvio(true)
      // let response : any = await RequestService.getEndPoint(`boutique/reporte_visitas/${boutique.uuid}/${moment(fecha).format('YYYY-MM-DD')}`)
      let response : any = await RequestService.getEndPointPost(`boutique/reporte_visitas/${boutique.uuid}/${moment(fecha_inicio).format('YYYY-MM-DD')}/${moment(fecha_fin).format('YYYY-MM-DD')}`, { } )
      setAsistencias(response.data)
      setStartDate_(fecha_inicio);
      setEndDate_(fecha_fin); 

      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      setAsistencias([])
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarAsistencias(inicioMes, new Date())
  }, []);



  const [ tarea_seleccionada, setTareaSeleccionada ] = useState<any>({})


  const [ show_ModalDetalleActividad, setModalDetalleActividad ] = useState<boolean>(false)
  const handleShow_ModalDetalleActividad = (tarea:any) => {
    setTareaSeleccionada({...tarea, uuid_servicio : tarea.uuid })
    setModalDetalleActividad(true)
  }
  const handleClose_ModalDetalleActividad = () => {
        // cargarAsistencias(fecha)
    setModalDetalleActividad(false)
  }










const { inicioMes, finMes } = getStartAndEndDates(new Date());

  const [startDate, setStartDate] = useState(inicioMes);
  const [endDate, setEndDate] = useState(new Date());


  const [startDate_, setStartDate_] = useState(inicioMes);
  const [endDate_, setEndDate_] = useState(new Date());


  const onChangeRango = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end); 
    // cargarAsistencias()
  };





  // const cargarAsistencias = async(fecha_inicio:any,fecha_fin:any)=>{
  //   try{
  //     setSeEnvio(true)
  //     let response : any = await RequestService.getEndPointPost(`reporte/analiticas/${moment(fecha_inicio).format('YYYY-MM-DD')}/${moment(fecha_fin).format('YYYY-MM-DD')}`, { arreglo_estatus : arreglo_estatus} )
  //     setAsistencias(response.data)
  //     setStartDate_(fecha_inicio);
  //     setEndDate_(fecha_fin); 

  //     setSeEnvio(false)
  //   }
  //   catch(e){
  //     setSeEnvio(false)
  //     setAsistencias([])
  //     toast.error('Problemas al intentar cargar')
  //   }
  // }


  const [ show_ModalBoutiqueEncuestaServicio, setModalBoutiqueEncuestaServicio ] = useState<boolean>(false)
  const handleShow_ModalBoutiqueEncuestaServicio = (tarea:any) => {
    setTareaSeleccionada({...tarea, uuid_servicio : tarea.uuid })
    setModalBoutiqueEncuestaServicio(true)
  }
  const handleClose_ModalBoutiqueEncuestaServicio = () => {
    cargarAsistencias(startDate_, endDate_)
    setModalBoutiqueEncuestaServicio(false)
  }


  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Reporte de Visitas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }


                      <small>Boutique</small>
                      <h3>{ boutique.nombre_boutique }</h3>
 

                      <div className="row">
                        <div className="col">
                          <small>Contacto en Boutique</small>
                          <p className="nm">{boutique.nombre_contacto}</p>
                        </div>
                        <div className="col">
                          <small>Teléfono de Contacto</small>
                          <p className="nm">{boutique.telefono_contacto}</p>
                        </div>
                      </div>



 


                  <div className="mt-4">
                    <label htmlFor="x">Rango de Fecha de la Consulta</label>
                    <DatePicker className="form-control" onChange={onChangeRango} startDate={startDate} endDate={endDate} selectsRange dateFormat="dd-MMM-yyyy" />
                  </div>
 
                      <ul className="list-group list-group-flush mb-2">
                        <li className="list-group-item"><small className="pointer" onClick={(e:any)=>{cargarAsistencias(startDate,endDate)}}>Cargar Reporte</small></li>
                      </ul>
              {
                // JSON.stringify(fecha.toString())
              }

              



                          {  (!cargando_) && (

                              <>

                                   {
                                     servicios.length > 0 && (

                                       <>



                                                  <h4 className="mb-4">
                                                    {
                                                      moment(startDate_).format('YYYY-MM-DD') !== moment(endDate_).format('YYYY-MM-DD') && ('REPORTE ENTRE '+ moment(startDate_).format('DD [DE] MMMM [DEL] YYYY').toUpperCase() + ' Y EL ' + moment(endDate_).format('DD [DE] MMMM [DEL] YYYY').toUpperCase() )
                                                    }
                                                    {
                                                      moment(startDate_).format('YYYY-MM-DD') === moment(endDate_).format('YYYY-MM-DD') && ('REPORTE PARA EL '+moment(startDate_).format('DD [DE] MMMM [DEL] YYYY').toUpperCase())
                                                    }
                                                  </h4>



                                              {
                                                // JSON.stringify(boutique)
                                              }



                                            <table className="table table-sm">
                                              <thead>
                                                <tr>
                                                  <th scope="col">Folio / Estatus</th> 
                                                  <th scope="col">Tipo</th>
                                                  <th scope="col">Técnico</th>
                                                  <th scope="col">Día de Servicio</th>
                                                  <th scope="col">Entrada / Salida</th>
                                                  <th scope="col"></th>
                                                </tr>
                                              </thead>
                                              <tbody>{
                                                      servicios.map((visita:any)=>

                                                            <tr key={ Math.random().toString(36).substr(2, 9) }>
                                                              <td onClick={(e:any)=>{handleShow_ModalDetalleActividad(visita)}}  className={`v pointer estatus_atencion_${visita.idestatus_atencion.id}`} style={{width:170}}>


                                                                <p className="nm"><b>{visita.folio}</b></p>
                                                                <p className="nm"><small>{visita.idestatus_atencion.estatus}</small></p>


                                                              </td> 
                                                              <td className={`v tipo_orden_${visita.idtipo_orden.id}`} style={{width:230}}>
                                                                <p className="nm"> 
                                                                <small>{visita.idtipo_orden.tipo_orden}</small></p>
                                                              </td>
                                                              <td className="v">
                                                                <p className="nm"><b>{visita.idtecnico.nombres}</b> <small> {visita.idtecnico.paterno} {visita.idtecnico.materno}</small></p>
                                                                <p className="nm">  <small><i className="fa fa-phone" /> {visita.idtecnico.celular} </small></p>
                                                              </td>  
                                                              <td className="v" style={{width:160}}>
                                                                <small>{moment(visita.fecha_programada).format('DD/MMM/YYYY').toUpperCase().replace('.','')} </small>
                                                              </td>  
                                                              <td className="v" style={{width:180}}>
                                                                {
                                                                  visita.fecha_inicio_servicio && (<p className="nm"> <i   className="fa fa-circle" style={{color:'#00db00',textShadow:'0px 0px 6px #3de34b'}} /> <small>{moment(visita.fecha_inicio_servicio).format('HH:mm')} hrs</small> </p>)
                                                                }
                                                                {
                                                                  visita.fecha_fin_servicio && (<p className="nm"><i   className="fa fa-circle" style={{color:'#00db00',textShadow:'0px 0px 6px #3de34b'}} /> <small>{moment(visita.fecha_fin_servicio).format('HH:mm')} hrs</small>  </p>)
                                                                }
                                                              </td>
                                                              <td className="v" style={{width:80}}>
                                                                {
                                                                  // JSON.stringify(visita.idestatus_atencion.id)
                                                                }

                                                                {
                                                                  ( [7].indexOf(visita.idestatus_atencion.id) > -1  && !visita.calificacion_boutique) && (<button className="btn btn-link btn-sm" onClick={(e:any)=>{handleShow_ModalBoutiqueEncuestaServicio(visita)}}>CALIFICAR</button>)
                                                                }
                                                              </td>
                                                            </tr>


                                                        )

                                                    }
                                              </tbody>
                                            </table>

                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}

        </Modal.Body> 
      </Modal>




  {
    show_ModalDetalleActividad && (
      <ModalDetalleActividad show={show_ModalDetalleActividad} handleClose={handleClose_ModalDetalleActividad} tarea={tarea_seleccionada} />
      )

  }


  {
    show_ModalBoutiqueEncuestaServicio && (
      <ModalBoutiqueEncuestaServicio show={show_ModalBoutiqueEncuestaServicio} handleClose={handleClose_ModalBoutiqueEncuestaServicio} visita={tarea_seleccionada} />
      )

  }

    </>
  );
}


export default ModalBoutiqueVisitas;