import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Form, Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { Formik } from 'formik';
import * as yup from 'yup';


import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


const ModalAsignarAtencionPresupuestoTecnico = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  // const { show, handleClose } = params
  const { show, handleClose, setConfiguracion, fecha_inicio, fecha_fin, tecnicos_seleccionado, configuracion, boutique_seleccionada, index_areas_seleccionada, index_subareas_seleccionada, fechas } = params



  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
          tecnico_seleccionado : yup.number().required(mensajes_validacion_generico),
          campo_fecha : yup.string().required(mensajes_validacion_generico),
  });




  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Asignar Fechas de Presupuestos</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <small>Boutique</small>
          <h4>{boutique_seleccionada.nombre_boutique}</h4>

          {
            // JSON.stringify(boutique_seleccionada.presupuestos)
          }

          {
            boutique_seleccionada.presupuestos.map((presupuesto:any,indice_presupuesto:number)=>
                <React.Fragment>
                  {
                    // JSON.stringify(presupuesto)
                  }

<div className="card bg-ligth" >
  <div className="card-body">


    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{ 
        console.log(values) 
        let c = {...configuracion}
        console.log(c.areas[index_areas_seleccionada].subareas[index_subareas_seleccionada].tecnicos[values.tecnico_seleccionado])
        // setConfiguracion(c)
        toast.success('Asignamos la visita de atención de el presupuesto correctamente')


      }}
      initialValues={{

            tecnico_seleccionado : "",
            campo_fecha : null,

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id={`my-form-${indice_presupuesto}`} noValidate onSubmit={handleSubmit}>

        
        {
          // JSON.stringify(values)
        }
        <small>Presupuesto</small>
        <h5>{presupuesto.folio}</h5>

                                  <div className="row mb-3">
                                    <div className="col-sm-6">
                                          <div className="mb-3">
                                            <Form.Label>Fecha</Form.Label>
                                            <DatePicker selected={values.campo_fecha} minDate={fecha_inicio} maxDate={fecha_fin} className="form-control"  dateFormat="yyyy-MM-dd"  onChange={(date) => setFieldValue('campo_fecha', date ) } />
                                          </div>
                                    </div>
                                    <div className="col-sm-6">

                <div>
                  <label htmlFor="tecnico_" className="form-label">Técnico Asignado</label>

                            {tecnicos_seleccionado.map((tecnico:any,index:number)=>

                                <React.Fragment key={index}>

                                      <Form.Check
                                        required
                                        type="radio"
                                        name="tecnico_seleccionado"
                                        label={`${tecnico.nombres} ${tecnico.paterno} ${tecnico.materno}`} 
                                        onChange={(e:any)=>{setFieldValue('tecnico_seleccionado',index)}}
                                        isInvalid={!!errors.tecnico_seleccionado}
                                        feedback={errors.tecnico_seleccionado}
                                        id={`valitado${index}-${indice_presupuesto}`}
                                      />

                                </React.Fragment>

                              )}

                </div>


                                    </div>
                                  </div>





                <Button variant="success" size="sm" form={`my-form-${indice_presupuesto}`} type="submit">Guardar <i className="fa fa-save"/></Button>

        </Form>
      )}
    </Formik>

  </div>
</div>


                </React.Fragment>
              )
          }


          {
            // JSON.stringify(configuracion)
          }


        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalAsignarAtencionPresupuestoTecnico;