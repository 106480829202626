import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal, Table, Badge } from 'react-bootstrap';

import Pagination from "../../services/Pagination";
import { Form } from 'react-bootstrap'

import { toast } from 'react-toastify'

import moment from 'moment'

import { Formik } from 'formik';
import * as yup from 'yup';

import { SERVER_ } from  '../../config';

import { FormateaNumero } from '../../services/funcionesPublicas'

import { rol } from '../../services/rol'



import ModalResetPasswordUsuario from '../Recursos_humanos/ModalResetPasswordUsuario'
import ModalAnexos from '../Recursos_humanos/ModalAnexos'



import ModalClienteCrearUsuario from './ModalClienteCrearUsuario'
import ModalClienteEditarUsuario from './ModalClienteEditarUsuario'


import Dialog from '../../services/Dialogs'

const ModalClienteUsuarios = ( params : any ) => {

  const { show, handleClose, cliente } = params

const dialog = new Dialog();


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`cliente/usuarios/${cliente.uuid}/busqueda?where=${text_busqueda}&skip=${skip}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(0)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true])


  const todos = () =>{
    setEstatus([true])
  }
  const ninguno = () =>{
    setEstatus([false])
  }




  const [ show_ModalClienteCrearUsuario, setModalClienteCrearUsuario ] = useState<boolean>(false)
  const handleShow_ModalClienteCrearUsuario = () => {
    setDeBoutique(false)
    setModalClienteCrearUsuario(true)
  }
  const handleClose_ModalClienteCrearUsuario = () => {
    setModalClienteCrearUsuario(false)
  }
  const handleShow_ModalClienteCrearUsuarioBoutique = () => {
    setDeBoutique(true)
    setModalClienteCrearUsuario(true)
  }




  const [ show_ModalResetPasswordUsuario, setModalResetPasswordUsuario ] = useState<boolean>(false)
  const handleShow_ModalResetPasswordUsuario = (usuario:any) => {
     setUsuarioSeleccionado(usuario)
    setModalResetPasswordUsuario(true)
  }
  const handleClose_ModalResetPasswordUsuario = () => {
    setModalResetPasswordUsuario(false)
  }

  const [ usuario_seleccionado, setUsuarioSeleccionado ] = useState<any>({})
  const [ showModalAnexos, setShowModalAnexos ] = useState<any>(false)

  const onHideModalAnexos = () => {
    setShowModalAnexos(false)
  }

  const documentosAnexos = (usuario:string)=>{
      setUsuarioSeleccionado(usuario)
      setShowModalAnexos(true)
  }

 const [ de_boutique, setDeBoutique ] = useState<boolean>(false)



const [ envio_server, setSeEnvio ] = useState<boolean>(false)
const eliminarAccesoBoutique = async (event:any,uuid_acceso:string) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: `<p>El Usuario dejará de tener acceso a la Boutique</p>`
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.delete( `cliente/boutique/eliminar_acceso/${uuid_acceso}` )

        if(res_){
          toast.success('Consulta_correcta')
          setTimeout(()=>{
            cargarMiReporte(0)
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}

  const [ showModalAgregarAccesoBoutique, setshowModalAgregarAccesoBoutique ] = useState<boolean>(false)

  const handleOpenAgregarAccesoBoutique = async (usuario:any) =>{
     setUsuarioSeleccionado(usuario)
    setshowModalAgregarAccesoBoutique(true)
  }

  const handleCloseAgregarAccesoBoutique = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setshowModalAgregarAccesoBoutique(false)
  }

    





  const [ boutiques, setBoutiques ] = useState<any>([])
  const cargarBoutiques = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`cliente/boutiques/${cliente.uuid}`)
      setBoutiques(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          uuid_boutique : yup.string().required(mensajes_validacion_generico),

  });




const agregarAccesoUsuario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `cliente/acceso_boutique/${usuario_seleccionado.uuid_usuario}` , { ...registro })

        if(res_){
          toast.success('EL acceso al usuario fue otorgado')
          setTimeout(()=>{
            cargarMiReporte(currentPage===0?0:currentPage-1)
            handleCloseAgregarAccesoBoutique()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}



  const [ show_ModalClienteEditarUsuario, setModalClienteEditarUsuario ] = useState<boolean>(false)
  const handleShow_ModalClienteEditarUsuario = (usuario:any) => {
     setUsuarioSeleccionado(usuario)
    setModalClienteEditarUsuario(true)
  }
  const handleClose_ModalClienteEditarUsuario = async () => {
    // await cargarBoutiques()
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalClienteEditarUsuario(false)
  }



  React.useEffect(() => {
    cargarBoutiques()
  }, []);

  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Usuarios de Acceso al Panel de las Boutiques</Modal.Title>
        </Modal.Header>
        <Modal.Body>




        <div className="row">
        
            <div className="">
              <label className="form-label">Búsqueda</label>
              <div className="input-group mb-3">
                <input type="text" className="form-control" onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                <button onClick={busqueda} className="btn btn-primary" type="button" ><i className="fa fa-search" /></button>
              </div>
            </div>

          {
            // <div>
            //     <Form.Check inline name="group1" type="checkbox" label="CAPTURA" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
            // </div>
          }
            
          <ul className="list-inline">
            <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalClienteCrearUsuario()}}>Nuevo Usuario</small></li>
            <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalClienteCrearUsuarioBoutique()}}>Nuevo Usuario de Boutique</small></li>
          </ul>

        </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>



                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col"></th>
                                              <th scope="col">Ingreso</th>
                                              <th scope="col">Nombre </th>
                                              <th scope="col">Boutique</th>
                                              <th scope="col">Usuario</th>
                                              <th scope="col">Contacto</th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {
                                              resultados.map((usuario:any)=>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}} className={ !usuario.activo ? 'table-warning' : '' }>
                                                    <td className={ 'v text-left ' + usuario.rol } style={{"width":"60px"}}>
                                                        <img src={`${SERVER_}perfil/foto/${usuario.uuid_usuario}`} style={{width:'100%'}} />
                                                    </td>
                                                    {
                                                      // <td className="v text-left" style={{"width":"120px"}}>
                                                      //   <p className="nm">
                                                      //     <b>{usuario.curp}</b> 
                                                      //   </p>
                                                      // </td>
                                                    }
                                                    <td className="v text-left" style={{"width":"120px"}}>
                                                      <small>{moment(usuario.fecha_creacion).format('YYYY-MM-DD')}</small>
                                                      <br/>
                                                      <small>{moment(usuario.fecha_creacion).from(moment())}</small>
                                                    </td>
                                                    <td className="v text-left" >
                                                      <p className="nm">
                                                          <b>{usuario.nombres} </b>
                                                          <br/>
                                                          <small>{usuario.paterno} {usuario.materno}</small>
                                                      </p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"200px"}}>


                                                      {
                                                        ['ROLE_BOUTIQUE'].indexOf(usuario.rol) > -1 && (
                                                          <React.Fragment>

                                                            {
                                                              usuario.boutiques && (
                                                                <>

                                                                  {
                                                                    usuario.boutiques.length > 0 && (
                                                                      <>
                                                                        {
                                                                          usuario.boutiques.map((boutique:any)=><Badge key={ Math.random().toString(36).substr(2, 9) } bg="light">{boutique.nombre_boutique} <i onClick={(e:any)=>{eliminarAccesoBoutique(e,boutique.uuid_acceso)}} className="fa fa-trash pointer"/></Badge>)
                                                                        }
                                                                      </>
                                                                    )
                                                                  }


                                                                </>
                                                              )
                                                            }
                                                            
                                                            <Badge onClick={(e:any)=>{handleOpenAgregarAccesoBoutique(usuario)}} className="pointer" bg="light">AGREGAR BOUTIQUE <i className="fa fa-plus"/></Badge>


                                                          </React.Fragment>
                                                        )
                                                      }




                                                    </td>
                                                    <td className="v text-left" style={{"width":"160px"}}>
                                                        <p className="nm">{usuario.username}</p>
                                                        <small>{rol(usuario.rol)}</small>
                                                    </td>

                                                    <td className="v text-right" style={{"width":"100px"}}>
                                                      <p className="nm"><small>{FormateaNumero(usuario.celular)}</small></p>
                                                      <small>{usuario.email}</small>
                                                    </td>
                                                    {
                                                      // <td className="v text-right" style={{"width":"40px"}}>
                                                      //       <Button onClick={()=>{documentosAnexos(usuario)}} className="nm" size="sm" variant="link"><i className="fa fa-folder" /></Button>
                                                      // </td>
                                                    }

                                                    <td className="v text-right" style={{"width":"40px"}}>
                                                          <Button  variant="warning" onClick={()=>{handleShow_ModalResetPasswordUsuario(usuario)}} size="sm"><i className="fa fa-key"/></Button>
                                                    </td>
                                                    <td className="v text-right" style={{"width":"40px"}}>

                                                        

                                                          <Button disabled={!usuario.activo} onClick={()=>{handleShow_ModalClienteEditarUsuario(usuario)}} size="sm"><i className="fa fa-pencil"/></Button>

                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}





          {
            // JSON.stringify(usuarios)
          }
        </Modal.Body>
      </Modal>



  {
    show_ModalResetPasswordUsuario && (
      <ModalResetPasswordUsuario show={show_ModalResetPasswordUsuario} handleClose={handleClose_ModalResetPasswordUsuario} usuario={usuario_seleccionado} />
      )

  }



  {
    show_ModalClienteCrearUsuario && (
      <ModalClienteCrearUsuario show={show_ModalClienteCrearUsuario} handleClose={handleClose_ModalClienteCrearUsuario} cliente={cliente} de_boutique={de_boutique} />
      )

  }



      {
        showModalAnexos && (
            <ModalAnexos onHideModalAnexos={onHideModalAnexos} showModalAnexos={showModalAnexos} usuario={usuario_seleccionado} />
         )
      }



  {
    show_ModalClienteEditarUsuario && (
      <ModalClienteEditarUsuario show={show_ModalClienteEditarUsuario} handleClose={handleClose_ModalClienteEditarUsuario}  usuario={usuario_seleccionado} />
      )

  }




      {

        showModalAgregarAccesoBoutique && (


              <Modal show={showModalAgregarAccesoBoutique} onHide={handleCloseAgregarAccesoBoutique} backdrop="static">
                <Modal.Header closeButton>
                  <Modal.Title>Agregar Acceso Boutique</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  
                  <h6>Acceso a la Boutique</h6>

                      <p className="nm">
                          <b>{usuario_seleccionado.nombres} </b>
                          <br/>
                          <small>{usuario_seleccionado.paterno} {usuario_seleccionado.materno}</small>
                      </p>



                        <Formik
                          validationSchema={schema}
                          onSubmit={(values,e)=>{agregarAccesoUsuario(values, e)}}
                          initialValues={{

                                uuid_boutique : "",

                          }}
                        >
                          {({
                            handleSubmit,
                            handleChange,
                            setFieldValue,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors,
                          }) => (
                            <Form id='my-form' noValidate onSubmit={handleSubmit}>


                                <Form.Group controlId="uuid_boutique">
                                  <Form.Label>Boutique</Form.Label>
                                      {
                                        boutiques.map((boutique:any)=>
                                          <Form.Check  name="hahaha" type={'radio'} id={`default-${boutique.uuid}`}>
                                          
                                            <Form.Check.Input name="hahaha" type={'radio'} isInvalid={!!errors.uuid_boutique}  checked={values.uuid_boutique===boutique.uuid} onChange={(e:any)=>{setFieldValue('uuid_boutique',boutique.uuid)}} key={ Math.random().toString(36).substr(2, 9) }/>
                                            <Form.Check.Label>
                                              <h6 className="nm">{boutique.nombre_boutique}</h6>
                                              <p className="nm">{boutique.idestado.estado}</p>
                                            </Form.Check.Label>
                                          </Form.Check>
                                        )
                                      }
                                    <Form.Control.Feedback type="invalid">{<>{errors.uuid_boutique}</>}</Form.Control.Feedback>
                                </Form.Group>



                            </Form>
                          )}
                        </Formik>




                  {
                    // JSON.stringify(usuario_seleccionado)
                  }

                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseAgregarAccesoBoutique}>
                    Cancelar
                  </Button>
                  <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
                </Modal.Footer>
              </Modal>



          )
      }






    </>
  );
}


export default ModalClienteUsuarios;