// import ModalReporteEvidenciasVisuales from './ModalReporteEvidenciasVisuales'

//   const [ show_ModalReporteEvidenciasVisuales, setModalReporteEvidenciasVisuales ] = useState<boolean>(false)
//   const handleShow_ModalReporteEvidenciasVisuales = () => {
//     setModalReporteEvidenciasVisuales(true)
//   }
//   const handleClose_ModalReporteEvidenciasVisuales = () => {
//     setModalReporteEvidenciasVisuales(false)
//   }


//   {
//     show_ModalReporteEvidenciasVisuales && (
//       <ModalReporteEvidenciasVisuales show={show_ModalReporteEvidenciasVisuales} handleClose={handleClose_ModalReporteEvidenciasVisuales} registro={reporte_seleccionado} tipo="REPORTE_SUPERVISION"  />
//       )

//   }

// import axios from 'axios';

import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import axios from 'axios';

import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import Dialog from '../../services/Dialogs'

import moment from 'moment'

import { calcularTiempoTranscurrido, evaluarAtencion, evaluarAtencionGlobal } from '../../services/funcionesPublicas'


import { SERVER_ } from  '../../config';


const CargarEvidencias = (params:any) => {



  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          folio : yup.string().required(mensajes_validacion_generico),
          descripcion : yup.string().required(mensajes_validacion_generico),

  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registros:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro de la información?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.create( `reporte_evidencias_visuales/crear_reporte_visual/${params.registro.uuid_reporte}` , { ...registros })

          if(res_){
            toast.success('MENSAJE')
            console.log(res_)
            params.setReporteVisualHecho(res_.data)
            // setTimeout(()=>{
              // params.handleClose()
            // },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }


  return (
      <React.Fragment>
          

    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            folio : params.registro.folio,
            descripcion : "",

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>

        {
          // JSON.stringify(registro.registro.folio)
        }

        {
          // JSON.stringify(values)
        }


            <Form.Group className="mb-3" controlId="descripcion">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="descripcion"
                value={values.descripcion}
                onChange={(e)=>{ setFieldValue('descripcion', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.descripcion}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.descripcion}</>} </Form.Control.Feedback>
            </Form.Group>


          <div className="d-grid gap-2 d-md-block">
            <button className="btn btn-danger" type="button">Cancelar</button>
            {' '}
            <button className="btn btn-success" form='my-form' type="submit">Crear Reporte</button>
          </div>



        </Form>
      )}
    </Formik>

      </React.Fragment>
    )

}



const CrearReporteVisita = (params:any) => {



  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          folio : yup.string().required(mensajes_validacion_generico),
          descripcion : yup.string().required(mensajes_validacion_generico),

  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registros:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro de la información?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.create( `visita/reporte_evidencias_visuales/crear_reporte_visual/${params.registro.uuid}` , { ...registros })

          if(res_){
            toast.success('El Reporte se creó correctamente. Ahora puedes subir imágenes de evidencia')
            // console.log(res_)
            params.setReporteVisualHecho(res_.data)
            // setTimeout(()=>{
              // params.handleClose()
            // },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }


  return (
      <React.Fragment>
          

    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            folio : params.registro.folio,
            descripcion : "",

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>


            <Form.Group className="mb-3" controlId="descripcion">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="descripcion"
                value={values.descripcion}
                onChange={(e)=>{ setFieldValue('descripcion', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.descripcion}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.descripcion}</>} </Form.Control.Feedback>
            </Form.Group>


          <div className="d-grid gap-2 d-md-block">
            <button className="btn btn-danger" type="button">Cancelar</button>
            {' '}
            <button className="btn btn-success" form='my-form' type="submit">Crear Reporte</button>
          </div>



        </Form>
      )}
    </Formik>

      </React.Fragment>
    )

}



const ModalReporteEvidenciasVisuales = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, registro, tipo } = params

  const [ idreporte_evidencia_visual, setReporteVisualHecho ] = useState<any>(registro.idreporte_evidencia_visual?registro.idreporte_evidencia_visual:null)


const dialog = new Dialog();


  const [arreglo_fotos, setArregloFotos] = useState<any>([]);
  const [enviando, setEnviando] = useState(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []).map(file => ({
      file,
      preview: URL.createObjectURL(file),
      enviando: false,
      enviado: false,
    }));
    setArregloFotos((prevFotos: any[]) => [...prevFotos, ...files]);
  };

  const eliminarFoto = (index: number) => {
    const fotoToRemove = arreglo_fotos[index];
    URL.revokeObjectURL(fotoToRemove.preview);
    setArregloFotos((prevFotos: any[]) => prevFotos.filter((_, i) => i !== index));
  };

  const eliminarTodasLasFotos = () => {
    arreglo_fotos.forEach((foto:any) => URL.revokeObjectURL(foto.preview));
    setArregloFotos([]);
  };

  const enviarFotos = async () => {
    if(arreglo_fotos.length === 0){
      toast.error('Debes agregar al menos 1 imagen')
      return
    }
    setEnviando(true);
    // let a_ = [...arreglo_fotos]
    for (let i = 0; i < arreglo_fotos.length; i++) {
      const formData = new FormData();
      formData.append('imagen', arreglo_fotos[i].file);
      try {
        await axios.post(`${SERVER_}reporte_evidencias_visuales/upload/${idreporte_evidencia_visual.uuid}`, formData);
        // console.log('enviado');
        
            // setTimeout(()=>{
              eliminarFoto(i);
              // i--;  // decremento el índice ya que hemos eliminado una foto del arreglo
            // },1000)
      } catch (error) {
        toast.error('No pudimos enviar la imagen')
        console.error('Error enviando la imagen', error);
        break;  // Detener el envío de más fotos si una falla
      }
    }

    toast.success('Todas la imágenes se agregaron al reporte')
    setArregloFotos([])
    setEnviando(false);
    cargarReporte_evidencia_visual()
  };





  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarReporte_evidencia_visual = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`reporte_evidencias_visuales/${idreporte_evidencia_visual.uuid}`)
      setReporteVisualHecho(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }



const eliminarAnexo = async (event:any,uuid_anexo:string) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.delete( `reporte_evidencias_visuales/eliminar_anexo/${uuid_anexo}` )

        if(res_){
          toast.warning('El anexo fue eliminado del reporte')
          cargarReporte_evidencia_visual()
          // setTimeout(()=>{
          //   cargar()
          // },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}



const actualizarAnexo = async (event:any,uuid_anexo:string, descripcion:string) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `reporte_evidencias_visuales/actualizar_anexo/${uuid_anexo}`, { descripcion : descripcion } )

        if(res_){
          toast.success('La descripción del anexo fué actualizado')
          cargarReporte_evidencia_visual()
          // setTimeout(()=>{
          //   cargar()
          // },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}





const girarFoto = async (event:any,uuid_anexo:string) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `reporte_evidencias_visuales/girar_anexo/${uuid_anexo}`, { } )

        if(res_){
          toast.success('La descripción del anexo fué actualizado')
          cargarReporte_evidencia_visual()
          // setTimeout(()=>{
          //   cargar()
          // },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}






  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Reporte de Evidencias Visuales</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(tipo)
          }




              <small>Boutique</small>
              <h5>{registro.nombre_boutique? registro.nombre_boutique : registro.idboutique.nombre_boutique }</h5>



              <small>Fecha del Reporte</small>
              <h5 className="nm">{moment(registro.fecha_creacion).format('YYYY-MM-DD')}</h5>
                <p className="nm mb-2"><small>{calcularTiempoTranscurrido(registro.fecha_creacion)}</small></p>





          {
            !idreporte_evidencia_visual && (

                <React.Fragment>

                  {
                    tipo === "REPORTE_SUPERVISION" && (
                        <React.Fragment>
                          
                          <CargarEvidencias registro={registro} setReporteVisualHecho={setReporteVisualHecho} handleClose={handleClose} />
                        </React.Fragment>
                      )
                  }

                  {
                    tipo === "REPORTE_VISITA" && (
                        <React.Fragment>
                          <CrearReporteVisita registro={registro} setReporteVisualHecho={setReporteVisualHecho} handleClose={handleClose} />
                        </React.Fragment>
                      )
                  }

                  
                </React.Fragment>

              )
          }



          {
             idreporte_evidencia_visual && (

                <React.Fragment>

                <hr/>
                  
                  <h4>Creando Reporte</h4>


          {
            // JSON.stringify(idreporte_evidencia_visual.anexos)
          }

          <div className="row align-items-end">
            <div className="col-sm-8">
                <div>
                  <label className="form-label">Selecciona Archivos para Agregar al Reporte</label>
                  <input type="file" multiple onChange={handleFileChange} accept="image/jpeg, image/jpg, image/png" disabled={enviando} className="form-control" />
                </div>
            </div>
            <div className="col-sm-2">
                <button className="btn btn-danger me-2" onClick={eliminarTodasLasFotos} disabled={enviando}>Quitar Todas</button>
            </div>
            <div className="col-sm-2">
                <button className="btn btn-primary" onClick={enviarFotos} disabled={enviando}>Enviar Fotos</button>
            </div>

          </div>

      <div className="mb-4">
      </div>


      <div className="row row-cols-1 row-cols-md-3 g-4">
        {arreglo_fotos.map((foto:any, index:number) => (
          <div key={index} className="col">
            <div className="card h-100">
              <img src={foto.preview} alt={foto.file.name} className="card-img-top" style={{ objectFit: 'cover', height: 'auto' }} />
              <div className="card-body">
                <p className="card-text">{foto.file.name}</p>
                <p className="text-muted">{foto.enviando ? 'Enviando...' : ''}</p>
                <button className="btn btn-danger" onClick={() => eliminarFoto(index)} disabled={enviando}>Eliminar</button>
              </div>
            </div>
          </div>
        ))}
      </div>










          {
            idreporte_evidencia_visual.anexos.length === 0 && (
                <React.Fragment>
                  <div className="alert alert-warning mt-3" role="alert">
                    <p className="nm"><i className="fa fa-warning"/> Aún no cargas ninguna evidencia visual.</p>
                  </div>
                </React.Fragment>
              )
          }

          {
            idreporte_evidencia_visual.anexos.length > 0 && (
                <React.Fragment>
                  {
                    // JSON.stringify(idreporte_evidencia_visual.anexos.length)
                  }

                  <div className="mt-4">

                  <h5>Total de {idreporte_evidencia_visual.anexos.length} imagen(es) de evidencia </h5>


                  <div className="row row-cols-1 row-cols-md-2 g-4">
                    {idreporte_evidencia_visual.anexos.map((foto:any, index:number) => (
                      <div key={index} className="col">
                        <div className="card h-100">
                          {
                            <img src={SERVER_+`reporte_evidencias_visuales/preview_anexo/${foto.uuid}/${ Math.random().toString(36).substr(2, 9) }`} alt={foto.uuid} className="card-img-top" style={{ objectFit: 'cover', height: 'auto' }} />
                          }
                          <div className="card-body">
                            <textarea 
                              className="form-control mb-2" 
                              value={foto.descripcion} 
                              onChange={(e) => {
                                let r = {...idreporte_evidencia_visual}
                                r.anexos[index].descripcion = e.target.value.toUpperCase()
                                setReporteVisualHecho(r)
                              }}
                              placeholder="Descripción de la foto"
                            ></textarea>
                            <button className="btn btn-success me-2" onClick={(e:any) => { actualizarAnexo(e,foto.uuid, foto.descripcion) }}>Guardar</button>
                            <button className="btn btn-danger me-2" onClick={(e:any) => eliminarAnexo(e,foto.uuid)}>Eliminar</button>
                            <button className="btn btn-warning" onClick={(e:any) => girarFoto(e,foto.uuid)}>Girar</button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  </div>


                </React.Fragment>
              )
          }



                </React.Fragment>

              )
          }



        </Modal.Body>





      </Modal>
    </>
  );
}


export default ModalReporteEvidenciasVisuales;