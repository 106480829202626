import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'



import FullCalendar from '@fullcalendar/react'
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list';

import ModalDetalleEvento from './ModalDetalleEvento'


const CalendarioFull = () =>{


    const [bookings, setBookings] = useState<any>([ ])




    const [ mis_tareas, setMis_tareas ] = useState<any>([])
    const cargarMis_tareas = async()=>{
      try{
        
        let response : any = await RequestService.getEndPoint(`tecnicos/tareas_periodo`)
        // setMis_tareas(response.data)
        setBookings(response.data)


      }
      catch(e){
        toast.error('Problemas al intentar cargar')
      }
    }


    React.useEffect(() => {
      cargarMis_tareas()
    }, []);




    const [ show_ModalDetalleEvento, setModalDetalleEvento ] = useState<boolean>(false)
    const [ evento_seleccionado, setEventoSeleccionado ] = useState<any>({})
    const handleShow_ModalDetalleEvento = (info:any) => {
      setEventoSeleccionado(info.event)
      setModalDetalleEvento(true)
    }
    const handleClose_ModalDetalleEvento = () => {
      setModalDetalleEvento(false)
    }



      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Calendario de Operación del Periodo Actual</h4>
                    <hr/>



                    <FullCalendar
                      plugins={[dayGridPlugin, interactionPlugin, listPlugin ]}
                      initialView="dayGridMonth"
                      
                      eventClick={handleShow_ModalDetalleEvento}
                      events={bookings}
                    />


                    {
                                        // <Calendar bookings={bookings} />
                    }



                    
                </div>
              </div>

            </div>


            {
              show_ModalDetalleEvento && (
                <ModalDetalleEvento show={show_ModalDetalleEvento} handleClose={handleClose_ModalDetalleEvento} evento={evento_seleccionado} />
                )

            }



          </>

      );
  

}

export default CalendarioFull
