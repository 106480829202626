import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';



import moment from 'moment'
import 'moment/locale/es'

import { FormateaNumero, obtenerNombreDia } from '../../services/funcionesPublicas'


import Dialog from '../../services/Dialogs'


const ModalNuevoTicketResponsable = ( params : any ) => {

  const { show, handleClose, boutique } = params

  // const [ cargando_, setCargando ] = useState( true )

  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          descripcion_ticket : yup.string().required(mensajes_validacion_generico),
          idtipo_ticket : yup.number().nullable().required(mensajes_validacion_generico),
          idboutique : yup.string().nullable().required(mensajes_validacion_generico),

  });



const [ cargando_, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `ticket/nuevo_responsable` , { ...registro })

        if(res_){
          toast.success('El Ticket fue registrado. Recuerda dale seguimiento mientras el ticket esté abierto.')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
          setSeEnvio(false)
        } 
        }
        catch(e:any){
          setSeEnvio(false)
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}


  const [ boutiques, setBoutiques ] = useState<any>([])
  const cargarBoutiques = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/boutiques`)
      setBoutiques(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarBoutiques()
  }, []);




  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>





                          {  (!cargando_) && (

                              <>

                                  <Formik
                                    validationSchema={schema}
                                    onSubmit={(values,e)=>{enviarFormulario(values, e)}}
                                    initialValues={{

                                          descripcion_ticket : "",
                                          idtipo_ticket : null,
                                          idboutique : "",

                                    }}
                                  >
                                    {({
                                      handleSubmit,
                                      handleChange,
                                      setFieldValue,
                                      handleBlur,
                                      values,
                                      touched,
                                      isValid,
                                      errors,
                                    }) => (
                                      <Form id='my-form' noValidate onSubmit={handleSubmit}>


                                          <Form.Group controlId="idtipo_ticket">
                                            <Form.Label>Tipo de Ticket</Form.Label>
                                              <Form.Check  isInvalid={!!errors.idtipo_ticket} type={'radio'} id={`4`} label={`QUEJA MANTENIMIENTO`} checked={values.idtipo_ticket===4} onChange={(e:any)=>{setFieldValue('idtipo_ticket',4)}} key={ Math.random().toString(36).substr(2, 9) }/>
                                              <Form.Check  isInvalid={!!errors.idtipo_ticket} type={'radio'} id={`5`} label={`QUEJA LIMPIEZA`} checked={values.idtipo_ticket===5} onChange={(e:any)=>{setFieldValue('idtipo_ticket',5)}} key={ Math.random().toString(36).substr(2, 9) }/>
                                              <Form.Check  isInvalid={!!errors.idtipo_ticket} type={'radio'} id={`6`} label={`QUEJA CONSERVACIÓN`} checked={values.idtipo_ticket===6} onChange={(e:any)=>{setFieldValue('idtipo_ticket',6)}} key={ Math.random().toString(36).substr(2, 9) }/>
                                              <Form.Check  isInvalid={!!errors.idtipo_ticket} type={'radio'} id={`10`} label={`BOUTIQUE CERRADA`} checked={values.idtipo_ticket===10} onChange={(e:any)=>{setFieldValue('idtipo_ticket',10)}} key={ Math.random().toString(36).substr(2, 9) }/>
                                              <Form.Check  isInvalid={!!errors.idtipo_ticket} type={'radio'} id={`111`} label={`VISITA`} checked={values.idtipo_ticket===1} onChange={(e:any)=>{setFieldValue('idtipo_ticket',1)}} key={ Math.random().toString(36).substr(2, 9) }/>
                                              <Form.Check  isInvalid={!!errors.idtipo_ticket} type={'radio'} id={`99`} label={`OTRO`} checked={values.idtipo_ticket===99} onChange={(e:any)=>{setFieldValue('idtipo_ticket',99)}} key={ Math.random().toString(36).substr(2, 9) }/>
                                              <Form.Control.Feedback type="invalid">{<>{errors.idtipo_ticket}</>}</Form.Control.Feedback>
                                          </Form.Group>


                                          <Form.Group className="mb-3" controlId="idboutique">
                                            <Form.Label>Boutique</Form.Label>
                                            <Form.Select required name="idboutique" value={values.idboutique} onChange={(e)=>{ setFieldValue('idboutique', e.target.value ) }} isInvalid={!!errors.idboutique}>
                                              <option value="">SELECCIONA UNA BOUTIQUE</option>
                                              {
                                                boutiques.map( (boutique:any, index_boutiques:number) =>
                                                  <React.Fragment key={ index_boutiques }>
                                                            <option value={boutique.uuid}> {boutique.nombre_boutique}</option>
                                                  </React.Fragment>
                                                )
                                              }

                                            </Form.Select>
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid"> {errors.idboutique} </Form.Control.Feedback>
                                          </Form.Group>


                                          <Form.Group   className="mb-3" controlId="descripcion_ticket">
                                            <Form.Label>Descripción del Ticket</Form.Label>
                                            <Form.Control
                                              as="textarea"
                                              rows={5}
                                              required
                                              name="descripcion_ticket"
                                              value={values.descripcion_ticket}
                                              onChange={(e)=>{ setFieldValue('descripcion_ticket', (e.target.value || "").toUpperCase() ) }}
                                              isInvalid={!!errors.descripcion_ticket}
                                            />
                                            <Form.Control.Feedback type="invalid"> {<>{errors.descripcion_ticket}</>} </Form.Control.Feedback>
                                          </Form.Group>


                                      </Form>
                                    )}
                                  </Formik>



                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}



          {
            // JSON.stringify(boutique)
          }

        </Modal.Body>

            {  (!cargando_) && (

                <>

                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Cancelar
                        </Button>
                        <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
                      </Modal.Footer>

                </>

             )}
      </Modal>
    </>
  );
}


export default ModalNuevoTicketResponsable;