import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { SERVER_ } from '../../config'


import FichaEmpleado from '../FichaEmpleado'

import Dialog from '../../services/Dialogs'


const ModalHerramientaInformacion = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, herramienta } = params


  const dialog = new Dialog();

  const [ envio_server, setSeEnvio ] = useState<boolean>(false)

  const desasignarHerramienta = async (event:any) =>{

        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: ``
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.delete( `empleado/desasignar_herramienta/${herramienta.uuid_herramienta}`)

          if(res_){
            toast.success('La herramienta se quitó de la lista de herramientas asignadas a este empleado')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){

            if (e.response?.status) {
              switch (e.response.status) {
                case 404:
                  toast.error("Debes escribir algo")
                  break;
                default:
                  toast.error(e.response?.data)
              }
            }
            else{
              toast.error("Problemas con la busqueda")
            }
            setSeEnvio(false)

          }
          
        }
       
  }

    


  return (
    <>
      <Modal show={show} size={'lg'} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Información de la Herramienta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(herramienta)
          }

          {
            herramienta.idusuario_asignado && (
                <React.Fragment>
                    <div className="row align-items-center">
                      <div className="col-sm-9">
                        <small>Asignado a</small>
                        <FichaEmpleado empleado={herramienta.idusuario_asignado} />
                      </div>
                      <div className="col-sm-3">
                        <div className="d-grid gap-2">
                          <button onClick={desasignarHerramienta} className="btn btn-lg btn-danger">QUITAR</button>
                        </div>
                      </div>
                    </div>
                </React.Fragment> 
              )
          }

          <h5 className="mt-4 mb-4">{herramienta.descripcion}</h5>


          <div className="row">
            <div className="col-sm-4">
              <small>Marca</small>
              <p className="nm">{herramienta.marca}</p>
            </div>
            <div className="col-sm-4">
                <small>Modelo</small>
                <p className="nm">{herramienta.modelo}</p>
            </div>
            <div className="col-sm-4">
              <small>Número de Serie</small>
              <p className="nm">{herramienta.numero_serie}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-8">
              <small>Tipo</small>
              <p className="nm">{herramienta.tipo_herramienta}</p>
            </div>
            <div className="col-sm-4">
                <small>Estado</small>
                <p className="nm">{herramienta.estado}</p>
            </div>
          </div>


          <div className="row">
            <div className="col-sm-8">
              <small>Fecha de Creación</small>
              <p className="nm">{new Date(herramienta.fecha_creacion).toLocaleString()}</p>
            </div>
            <div className="col-sm-4">
                <small>Precio</small>
                <p className="nm">$ {herramienta.precio}</p>
            </div>
          </div>


          {
            !herramienta.idusuario_asignado && (
                <React.Fragment>
                  <div className="row">
                    <div className="col-sm-12">
                      <small>Almacén</small>
                      <p className="nm">{herramienta.almacen}</p>
                    </div>
                  </div>
                </React.Fragment> 
              )
          }





          <h5 className="mt-3">Imágenes de la Herramienta</h5>

          <div className="row">
            <div className="col-sm-3">
                <img src={`${SERVER_}herramienta_imagen/foto_a/${herramienta.uuid_herramienta}/${ Math.random().toString(36).substr(2, 9) }`} alt="Foto A"  width="100%" style={{border:'solid 1px'}} />
            </div>
            <div className="col-sm-3">
                <img src={`${SERVER_}herramienta_imagen/foto_b/${herramienta.uuid_herramienta}/${ Math.random().toString(36).substr(2, 9) }`} alt="Foto A"  width="100%" style={{border:'solid 1px'}} />
            </div>
            <div className="col-sm-3">
                <img src={`${SERVER_}herramienta_imagen/foto_c/${herramienta.uuid_herramienta}/${ Math.random().toString(36).substr(2, 9) }`} alt="Foto A"  width="100%" style={{border:'solid 1px'}} />
            </div>
            <div className="col-sm-3">
                <img src={`${SERVER_}herramienta_imagen/foto_d/${herramienta.uuid_herramienta}/${ Math.random().toString(36).substr(2, 9) }`} alt="Foto A"  width="100%" style={{border:'solid 1px'}} />
            </div>
          </div>


        </Modal.Body>

      </Modal>
    </>
  );
}


export default ModalHerramientaInformacion;