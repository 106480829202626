import React, { useState, useRef } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Form, Col, Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import Dialog from '../../services/Dialogs'

import moment from 'moment'


import { estadoCuidadoHerramienta } from '../../services/funcionesPublicas'


const ModalHerramientaEditar = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, herramienta } = params


  const dialog = new Dialog();



  const [ envio_server, setSeEnvio ] = useState<boolean>(false)



  const [ tipo_herramienta, setTipo_herramienta ] = useState<any>([])
  const cargarTipo_herramienta = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`catalogos/tipo_herramienta`)
      setTipo_herramienta(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  const [ almacenes, setAlmacen ] = useState<any>([])
  const cargarAlmacenes = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`catalogos/almacenes`)
      setAlmacen(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarTipo_herramienta()
    cargarAlmacenes()
  }, []);



    



  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          numero_serie : yup.string().required(mensajes_validacion_generico),
          marca : yup.string().required(mensajes_validacion_generico),
          descripcion : yup.string(), //.required(mensajes_validacion_generico),
          precio : yup.number().required(mensajes_validacion_generico),
          estado : yup.string().required(mensajes_validacion_generico),
          idtipo_herramienta : yup.number().required(mensajes_validacion_generico),
          idalmacen : yup.string().required(mensajes_validacion_generico) ,
  });

  const schema_asignado = yup.object().shape({

          numero_serie : yup.string().required(mensajes_validacion_generico),
          marca : yup.string().required(mensajes_validacion_generico),
          descripcion : yup.string(), //.required(mensajes_validacion_generico),
          precio : yup.number().required(mensajes_validacion_generico),
          estado : yup.string().required(mensajes_validacion_generico),
          idtipo_herramienta : yup.number().required(mensajes_validacion_generico),
          idalmacen : yup.string().nullable() ,
  });



  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.update( `inventario/herramienta/editar_informacion/${herramienta.uuid_herramienta}` , { registro : registro })

          if(res_){
            toast.warning('La información se actualizó')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }






  const [imagenes, setImagenes] = useState<any>([]);
  const inputRef : any = useRef();

  const convertirABase64 = (archivo:any) => {
    return new Promise((resolve:any, reject:any) => {
      const reader = new FileReader();
      reader.readAsDataURL(archivo);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const agregarImagenes = async (event:any) => {
    const archivos : any = Array.from(event.target.files);
    if ((imagenes.length + archivos.length) <= 4) {
      const nuevasImagenes = await Promise.all(archivos.map((archivo:any) => convertirABase64(archivo)));
      setImagenes((prevImagenes:any) => [...prevImagenes, ...nuevasImagenes]);
    } else {
      alert('No puedes subir más de 4 imágenes.');
    }
  };

  const borrarImagen = (indice:any) => {
    const nuevasImagenes = imagenes.filter((_:any, i:number) => i !== indice);
    setImagenes(nuevasImagenes);
  };






  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Editar Herramienta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(tipo_herramienta)
          }






    <Formik
      validationSchema={ herramienta.idusuario_asignado === null ? schema : schema_asignado}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            numero_serie : herramienta.numero_serie,
            marca : herramienta.marca,
            modelo : herramienta.modelo,
            descripcion : herramienta.descripcion,
            precio : herramienta.precio,
            estado : herramienta.estado,
            idtipo_herramienta : herramienta.uuid_tipo_herramienta,
            idalmacen : herramienta.uuid_almacen,

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>


{
  // JSON.stringify(values)
}
{
  // JSON.stringify(herramienta.idusuario_asignado)
}
              {

                herramienta.idusuario_asignado === null && (


                      <Form.Group  className="mb-3" controlId="idalmacen">
                        <Form.Label>Almacén donde se guarda</Form.Label>
                        <Form.Select required value={values.idalmacen} onChange={(e)=>{ setFieldValue('idalmacen',e.target.value) }} isInvalid={!!errors.idalmacen} >
                          <option>Selecciona un Almacén</option>
                                      {
                                        almacenes.map((almacen:any)=>
                                            <option value={almacen.uuid}>{almacen.almacen}</option>
                                          )
                                      }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{<>{errors.idalmacen}</>} </Form.Control.Feedback>
                      </Form.Group>

                  )


              }


                <Form.Group  className="mb-3" controlId="idtipo_herramienta">
                  <Form.Label>Tipo de Herramienta</Form.Label>
                  <Form.Select required value={values.idtipo_herramienta} onChange={(e)=>{ setFieldValue('idtipo_herramienta',e.target.value) }} isInvalid={!!errors.idtipo_herramienta} >
                    <option>Selecciona un tipo de Herramienta</option>
                                {
                                  tipo_herramienta.map((tipo:any)=>
                                      <option value={tipo.id}>{tipo.tipo_herramienta}</option>
                                    )
                                }
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{<>{errors.idtipo_herramienta}</>} </Form.Control.Feedback>
                </Form.Group>



            <Form.Group className="mb-3" controlId="numero_serie">
              <Form.Label>Número de Serie</Form.Label>
              <Form.Control 
                required
                name="numero_serie"
                value={values.numero_serie}
                onChange={(e)=>{ setFieldValue('numero_serie', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.numero_serie}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.numero_serie}</>} </Form.Control.Feedback>
            </Form.Group>


            <Form.Group className="mb-3" controlId="marca">
              <Form.Label>Marca</Form.Label>
              <Form.Control 
                required
                name="marca"
                value={values.marca}
                onChange={(e)=>{ setFieldValue('marca', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.marca}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.marca}</>} </Form.Control.Feedback>
            </Form.Group>


            <Form.Group className="mb-3" controlId="modelo">
              <Form.Label>Modelo</Form.Label>
              <Form.Control 
                required
                name="modelo"
                value={values.modelo}
                onChange={(e)=>{ setFieldValue('modelo', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.modelo}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.modelo}</>} </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="precio">
              <Form.Label>Precio de la Herramienta</Form.Label>
              <Form.Control 
                type="number"
                required
                name="precio"
                value={values.precio}
                onChange={(e)=>{ setFieldValue('precio', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.precio}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.precio}</>} </Form.Control.Feedback>
            </Form.Group>


            <Form.Group className="mb-3" controlId="descripcion">
              <Form.Label>Descripción o Detalles </Form.Label>
              <Form.Control
                as="textarea"
                required
                name="descripcion"
                value={values.descripcion}
                onChange={(e)=>{ setFieldValue('descripcion', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.descripcion}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.descripcion}</>} </Form.Control.Feedback>
            </Form.Group>



                            <Form.Group controlId="idarea">
                              <Form.Label>Estado de Cuidado</Form.Label>
                                {
                                  ["NUEVO","MEDIO_USO","MAL_ESTADO"].map((estado:any)=>
                                    <Form.Check inline isInvalid={!!errors.estado} type={'radio'} id={`default-${estado}`} label={estadoCuidadoHerramienta(estado)} checked={values.estado===estado} onChange={(e:any)=>{setFieldValue('estado',estado)}} key={ Math.random().toString(36).substr(2, 9) }/>
                                  )
                                }

                                <Form.Control.Feedback type="invalid">{<>{errors.estado}</>}</Form.Control.Feedback>
                            </Form.Group>









        </Form>
      )}
    </Formik>





        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalHerramientaEditar;