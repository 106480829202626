import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import moment from 'moment'



import Dialog from '../../services/Dialogs'

import { SERVER_ } from  '../../config';


const ModalClienteEditarUsuario = ( params : any ) => {

  const { show, handleClose, usuario } = params

const [ envio_server, setSeEnvio ] = useState<boolean>(false)

  const dialog = new Dialog();



  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

  nombres : yup.string().required(mensajes_validacion_generico),
  paterno : yup.string().required(mensajes_validacion_generico),
  materno : yup.string(),


  // curp: yup.string(), //.required(mensajes_validacion_generico).matches( REG_CURP , "Formato de CURP invalido").required('El CURP es un campo obligatorio'),
  // rfc: yup.string(), //.required(mensajes_validacion_generico),
  email: yup.string().required(mensajes_validacion_generico).email('Formato de Email no valido').required('Este campo es obligatorio'),
  celular: yup.string().required(mensajes_validacion_generico).matches(/^\d{10}$/,"Formato no valido"),

  activo : yup.boolean(),


  });


const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: `
          <small>Nombre(s)</small>
          <p class="nm">${registro.nombres} ${registro.paterno} ${registro.materno} </p>
          <small>Teléfono</small>
          <p class="nm">${registro.celular} </p>
          <small>Correo Electrónico</small>
          <p class="nm">${registro.email} </p>
        `
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{


        setSeEnvio(true)

        let URL_CREATE = `cliente/cambiar_datos_usuario/${usuario.uuid_usuario}`

        let res_ = await RequestService.update( URL_CREATE , { ...registro })

        if(res_){
          toast.success('La información del usuario fué modificada')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          console.log(e)
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Editar Información de Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(usuario)
          }




    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={
        {

            // foto_ : "", 
            nombres : usuario.nombres, 
            paterno : usuario.paterno, 
            materno : usuario.materno, 
            celular : usuario.celular, 
            email : usuario.email, 
            activo : usuario.activo, 
            // curp : "", 



      }

        // {"username":"johnsmith","foto":"","foto_":"","nombres":"RICARDO","paterno":"PÉREZ","materno":"","rol":"ROLE_TECNICO","celular":"5551111111","email":"johndoe@example.com","curp":"GOMA010101HDFXXX01","rfc":"ABCD920101123"}



    }
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>


                  <h6>Información Personal</h6>


                  <Row>
                    <Col md="12">
 


                    <Form.Group   className="mb-3" controlId="nombres">
                      <Form.Label>Nombre(s)</Form.Label>
                      <Form.Control
                        required
                        name="nombres"
                        value={values.nombres}
                        onChange={(e)=>{ setFieldValue('nombres', (e.target.value.toUpperCase()) ) }}
                        isInvalid={!!errors.nombres}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">{<>{errors.nombres}</>}</Form.Control.Feedback>
                    </Form.Group>




                    <div className="row">
                      <div className="col-md-6">

                          <Form.Group   className="mb-3" controlId="paterno">
                            <Form.Label>Apellido Paterno</Form.Label>
                            <Form.Control
                              required
                              name="paterno"
                              value={values.paterno}
                              onChange={(e)=>{ setFieldValue('paterno', (e.target.value.toUpperCase()) ) }}
                              isInvalid={!!errors.paterno}
                            />
                            <Form.Control.Feedback type="invalid">{<>{errors.paterno}</>}</Form.Control.Feedback>
                          </Form.Group>

                      </div>
                      <div className="col-md-6">
                          <Form.Group   className="mb-3" controlId="materno">
                            <Form.Label>Apellido Materno</Form.Label>
                            <Form.Control
                              name="materno"
                              value={values.materno}
                              onChange={(e)=>{ setFieldValue('materno', (e.target.value.toUpperCase()) ) }}
                              isInvalid={!!errors.materno}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                          </Form.Group>
                        </div>
                    </div>

 
 


                    </Col>
                  </Row>






          <h5>Información de Contacto</h5>



          <Row>
            <Form.Group as={Col} md="7" className="mb-3" controlId="email">
              <Form.Label>Correo Electrónico</Form.Label>
              <Form.Control
                type="email"
                required
                name="email"
                value={values.email}
                onChange={(e)=>{ setFieldValue('email', e.target.value ) }}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {<>{errors.email}</>} </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="5" className="mb-3" controlId="celular">
              <Form.Label>Celular</Form.Label>
              <Form.Control
                type="text"
                required
                name="celular"
                value={values.celular}
                onChange={(e)=>{ setFieldValue('celular',e.target.value) }}
                isInvalid={!!errors.celular}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {<>{errors.celular}</>} </Form.Control.Feedback>
            </Form.Group>
          </Row>



        <Form.Check 
            type={'checkbox'}
            checked={values.activo}
            onChange={handleChange}
            id={`default-activo`}
            label={`ESTE USUARIO SE ENCUENTRA ACTIVO Y PUEDE ACCEDER AL SISTEMA`}
          />





        </Form>
      )}
    </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalClienteEditarUsuario;