import { SERVER_ } from  '../../config';


import React, { useState } from 'react';

import RequestService from "../../services/request.service";


import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import moment from 'moment'

const ModalGafete = ( params : any ) => {


  const { show, handleClose, usuario } = params


  const [_startDate, _setStartDate] = useState(new Date());
  const [_endDate, _setEndDate] = useState(new Date());

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());


  const actualizarGafete = ()=>{
    _setStartDate(startDate)
    _setEndDate(endDate)
  }


   const onChangeRango = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Gafete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }

        <div className="mb-3">
          <label className="form-label">Vigencia del Gafete</label>
          <div className="text-center">
            <DatePicker onChange={onChangeRango} className="form-control" dateFormat="dd/MM/yyyy" startDate={startDate} endDate={endDate} selectsRange />
          </div>

        </div>

        <button type="submit" onClick={actualizarGafete} className="btn btn-primary">Actualizar </button>



      <iframe src={`${SERVER_}gafete/${usuario.uuid}/${moment(_startDate).format('YYYY-MM-DD')}/${moment(_endDate).format('YYYY-MM-DD')}`} className="mt-4" width={'100%'} height={700} ></iframe>





        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalGafete;