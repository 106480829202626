import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal, Form } from 'react-bootstrap';

import { Formik, Field, FieldArray, ErrorMessage } from 'formik';
import * as yup from 'yup';

import { toast } from 'react-toastify'

import Dialog from '../../services/Dialogs'


const ModalBoutiqueNuevoRefaccionamiento = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params




  const [ boutiques, setBoutiques ] = useState<any>([])
  const cargarBoutiques = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/refaccionamiento/boutiques`)
      setBoutiques(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarBoutiques()
  }, []);






  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
        tipo_presupuesto : yup.string().required(mensajes_validacion_generico),
        idboutique : yup.string().when('tipo_presupuesto',
            (tipo_presupuesto,schema)=>{
                // console.log('value business : ',tipo_presupuesto);
                if(["GASTO_OPERACION","GASTOS_MOVILIZACION"].indexOf(tipo_presupuesto)>-1){
                   return schema;
                }else{
                   return schema.required(mensajes_validacion_generico);
                }
            }), //.required(mensajes_validacion_generico),
        descripcion : yup.string().required(mensajes_validacion_generico),
        solicitado : yup.boolean(),
        dias_finaliza_estimados : yup.number(), //.nullable().required(mensajes_validacion_generico),

  material_actividades: yup.array()
    .of(yup.object().shape({
        descripcion: yup.string().required(mensajes_validacion_generico),
        aprobada : yup.boolean()
    }))


  });



const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (registro:any,event:any) =>{

  let r = { ...registro }
  r.descripcion += `


  
HORARIO DE ENTREGA
${r.horario_entrega}
  `

  // console.log(r)

  // return



      let lista = ``
      for(let material of registro.material_actividades){
        lista += `<li class="list-group-item">
          <p class="nm">${material.descripcion}</p>
          <small><b>(x ${material.cantidad})</b></small>
        </li>`
      }


      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: `
          <small>Estás solicitando:</small>
          <ol class="list-group list-group-flush">${lista}</ol>

        `
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `presupuestos/nuevo` , r )

        if(res_){
          toast.success('La solicitud fue creada correctamente')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}

const [ articulos, setArticulos ] = useState<any>([
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "TRAPEADOR DE CORDON 500 GR BASTON MADERA CASTOR" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "TOALLA INTERDOBLADA SANITAS 20 PQ 100 HJS" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "AROMATIZANTE EN AEROSOL 400 ML / 323 GRS (VARIOS AROMAS) (WIESE)" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "BOLSA TRANSPARENTE RECICLADA BAJA DENSIDAD (KILO) MEDIDA 60X90" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "BOLSA TRANSPARENTE RECICLADA BAJA DENSIDAD (KILO) MEDIDA 70X90" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "BOLSA TRANSPARENTE RECICLADA BAJA DENSIDAD (KILO) MEDIDA 90X1.20" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "CLORO (1 LT)" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "CLORO (4 LT)" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "BLANQUEADOR CLORALEX 950 ML" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "GUANTE DE LATEX ROJO CHICO" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "GUANTE DE LATEX ROJO MEDIANO" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "GUANTE DE LATEX ROJO GRANDE" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "SHAMPOO LIQUIDO P/MANOS LIMPIEL (5LT) ALMENDRA" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "SHAMPOO LIQUIDO P/MANOS LIMPIEL (5LT) NARANJA" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "SHAMPOO LIQUIDO P/MANOS LIMPIEL (5LT) HERBAL" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "SHAMPOO LIQUIDO P/MANOS LIMPIEL (5LT) NEUTRO" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "PAÑO DE MICROFIBRA 36 X 35 CM VERDE" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "PAÑO DE MICROFIBRA 36 X 35 CM AMARILLO" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "PAÑO DE MICROFIBRA 36 X 35 CM AZUL" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "PAÑO DE MICROFIBRA 36 X 35 CM ROJO" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "PASTILLA DESODORANTE PARA BAÑO CON GANCHO 70 GRS WEISE HERBAL" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "PASTILLA DESODORANTE PARA BAÑO CON GANCHO 70 GRS WEISE POTPOURRI" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "PASTILLA DESODORANTE PARA BAÑO CON GANCHO 70 GRS WEISE CITRUS" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "PASTILLA DESODORANTE PARA BAÑO CON GANCHO 70 GRS WEISE LAVANDA" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "TOALLA EN ROLLO TORK UNIVERSAL 6 RL 180 MTS, HOJA SENCILLATOALLA EN ROLLO TORK UNIVERSAL 6 RL 180 M" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "DETERGENTE ROMA 1 KG" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "HIG JUMBO JR MARLI 12 RL 200 MTS" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "HIG JUMBO JR MARLI HOJA DOBLE GOFRADO ROLLO DE 200 MTS" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "REPUESTO AROMATIZANTE EN AEROSOL 180 GRS/ 256 ML (WIESE) HAWAIIAN GINGER" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "REPUESTO AROMATIZANTE EN AEROSOL 180 GRS/ 256 ML (WIESE) MANZANA" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "REPUESTO AROMATIZANTE EN AEROSOL 180 GRS/ 256 ML (WIESE) OCEAN BREEZE" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "REPUESTO AROMATIZANTE EN AEROSOL 180 GRS/ 256 ML (WIESE) TAHITI" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "REPUESTO AROMATIZANTE EN AEROSOL 180 GRS/ 256 ML (WIESE) LIMA LIMON" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "REPUESTO AROMATIZANTE EN AEROSOL 180 GRS/ 256 ML (WIESE) CANELA" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "LIMPIADOR FABULOSO 1 LT LAVANDA" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "LIMPIADOR FABULOSO 1 LT PASION DE FRUTAS" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "LIMPIADOR FABULOSO 1 LT MAR FRESCO" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "LIMPIADOR FABULOSO 1 LT FRESCO AMANECER" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "LIMPIADOR FABULOSO 1 LT ENERGIA NARANJA" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "LIMPIADOR DETERGENTE MULTISUPERFICIES FRESCOOL (1 LT) LIMA LIMON" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "LIMPIADOR DETERGENTE MULTISUPERFICIES FRESCOOL (1 LT) BRIZA MARINA" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "LIMPIADOR DETERGENTE MULTISUPERFICIES FRESCOOL (1 LT) LAVANDA" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "LIMPIADOR MULTIUSO DE 5LT" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "PLUMERO SACUDIDOR PLASTICO DE 66CM 40 GRS DE RAFIA" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "PLUMERO SACUDIDOR PLASTICO CON EXTENSION DE 125CM 40 GRS DE RAFIA" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "DESCRIPCION: BOTE ATOMIZADOR 1 LT" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "PISTOLA PARA BOTE ATOMIZADOR DE USO RUDO" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "MR MUSCULO WINDEX LIMPIA CRISTALES 500 ML" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "LIMPIADOR P/ VIDRIOS CRISTASEL (1 LT)" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "BOLSA DE ALTA DENSIDAD DE 76.2 X 94 CM CALIBRE 10 MICRAS. 20 ROLLOS DE 25 PZS C/U" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "BOLSA DE ALTA DENSIDAD DE 101.6 X 122 CM CALIBRE 12. 10 ROLLOS DE 25 PZS C/U." },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "BOLSA ALTA DEN. NATURAL 76.2X94 CM CAL 10, EXCELENTE RESISTENCIA TÉRMICA Y QUÍMICA" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "LIMPIADOR UNIVERSAL PINOL 1LT" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "REPUESTO DE MOP 60 CM" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "REPUESTO DE MOP 90 CM" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "CEPILLO QUITA PELUSA GRANDE" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "SERVITOALLA KIMLARK 60 HJS" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "AROMATIZANTE ECODELI PORRÓN PIEZA DE 5 LT" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "AROMATIZANTE ECODELI DE 20 LT" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "ESCOBA DE ABANICO LARGA DE ALTA RIGIDEZ" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "CEPILLO PARA BAÑO" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "FRANELA LISA (CARPE) MT GRIS" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "FRANELA LISA (CARPE) MT BLANCA" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "FRANELA LISA (CARPE) MT ROJA" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "JERGA BLANCA POPULAR 1 MT (CARPE)" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "JALADOR PARA PISOS 50 CM. HULE REFORZADO" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "FIBRA BLANCA P66 (3M)" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "BOLSA NEGRA RECICLADA BAJA DENSIDAD (KILO) DIFERENTES MEDIDAS" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "CONO DESECHABLE DEL #108" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "FIBRA VERDE CON ESPONJA MONARCA" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "FIBRA VERDE P96 (3M)" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "LIMPIADOR DE ACERO INOX 200 GR" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "CUBETA CON EXPRIMIDOR" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "ABRILLANTADOR DE MUEBLES EN AEROSOL" },
{ cantidad : 0, idunidad_medida : 29, seleccionado : false, descripcion : "CEPILLO T/ PLANCHA" },
])



  const schema_add = yup.object().shape({
          descripcion : yup.string().required(mensajes_validacion_generico),
  });


 




  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Solicitud de Insumos Materiales </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }


    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{
            idboutique : "",
            tipo_presupuesto : "PRESUPUESTO_INSUMOS",
            descripcion : "INSUMOS MATERIALES",

            horario_entrega : " ",
            fecha_entrega : " ",

            dias_finaliza_estimados : '',
            // actividades_propuestas : [{ actividad : "", aprobada : false }],
            material_actividades : [],
            solicitado : false
      }}

    >
      {({
        resetForm,
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>

        {
          // JSON.stringify(values)
        }

        <p>A continuación, para su comodidad, favor de marcar de la siguiente lista de materiales comúnmente ocupados en boutique para solicitar:</p>



                          <React.Fragment>

                            <Form.Group className="mb-3" controlId="idboutique">
                              <Form.Label>Boutique</Form.Label>
                              <Form.Select required name="idboutique" value={values.idboutique} onChange={(e)=>{ setFieldValue('idboutique', e.target.value ) }} isInvalid={!!errors.idboutique}>
                                <option value="">SELECCIONA UNA BOUTIQUE</option>
                                {
                                  boutiques.map( (boutique:any) =>
                                    <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>
                                              <option value={boutique.uuid}> {boutique.nombre_boutique}</option>
                                    </React.Fragment>
                                  )
                                }

                              </Form.Select>
                              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid"> {errors.idboutique} </Form.Control.Feedback>
                            </Form.Group>

                            </React.Fragment>


                            <Form.Group className="mb-3" controlId="horario_entrega">
                              <Form.Label>Horario de Entrega</Form.Label>
                              <Form.Control 
                                required
                                name="horario_entrega"
                                value={values.horario_entrega}
                                onChange={(e)=>{ setFieldValue('horario_entrega', (e.target.value || "").toUpperCase() ) }}
                                isInvalid={!!errors.horario_entrega}
                              />
                              <Form.Control.Feedback type="invalid"> {<>{errors.horario_entrega}</>} </Form.Control.Feedback>
                              <div id="emailHelp" className="form-text">Sugiere un horario para la entrega de materiales</div>
                            </Form.Group>





                            {

                              // JSON.stringify(values)
                            }


                              <Form.Label>Materiales</Form.Label>

                            <div className="row">
                              {
                                articulos.map( (articulo:any,i:number) =>
                                    <React.Fragment key={ i }>

                                      {
                                        // JSON.stringify(articulo)
                                      }
                                      <div className="col-md-1 text-center align-items-center">
                                          <input type="checkbox" checked={articulo.seleccionado} id={`arti_${i}`} onChange={(e:any)=>{   
                                                                                                                      let a = [...articulos]; 
                                                                                                                      a[i].seleccionado = e.target.checked; 
                                                                                                                      if(a[i].seleccionado === true) a[i].cantidad = 1;
                                                                                                                      else a[i].cantidad = 0;
                                                                                                                      let inserta = []
                                                                                                                      for(let articulo of a) if(articulo.seleccionado) inserta.push({ descripcion : `${articulo.descripcion}  `, cantidad : articulo.cantidad,  idunidad_medida : articulo.idunidad_medida, aprobada : false });
                                                                                                                      setFieldValue( "material_actividades", inserta )                                                                                                                    
                                                                                                                      setArticulos(a); 
                                                                                                                    }} />
                                      </div>
                                      <div className="col-md-8 align-items-center">
                                          <p><label htmlFor={`arti_${i}`}><small>{articulo.descripcion}</small></label></p>
                                      </div>
                                      <div className="col-md-3 align-items-center">
                                          <input type="number"  className="form-control form-control-sm" disabled={!articulo.seleccionado} placeholder="Cantidad" min={1} value={articulo.cantidad} onChange={(e:any)=>{   
                                                                                                                      let a = [...articulos]; 
                                                                                                                      a[i].cantidad = e.target.value; 
                                                                                                                      let inserta = []
                                                                                                                      for(let articulo of a) if(articulo.seleccionado) inserta.push({ descripcion : `${articulo.descripcion}  `, cantidad : articulo.cantidad, idunidad_medida : articulo.idunidad_medida, aprobada : false });
                                                                                                                      setFieldValue( "material_actividades", inserta )                                                                                                                    
                                                                                                                      setArticulos(a); 
                                                                                                                    }}  />
                                      </div> 

                                    </React.Fragment>

                                 )
                              }
                            </div>

 

 

    <Formik
      validationSchema={schema_add}
      onSubmit={(values, { resetForm })=>{

          let a = [...articulos]; 
          // a[i].cantidad = e.target.value; 
          // let inserta = []
          // for(let articulo of a) if(articulo.seleccionado) inserta.push({ descripcion : `${articulo.descripcion}  `, cantidad : articulo.cantidad, idunidad_medida : articulo.idunidad_medida, aprobada : false });
          // setFieldValue( "material_actividades", inserta )                                                                                                                    
          a.push({ cantidad : 1, idunidad_medida : 29, seleccionado : true, descripcion : values.descripcion },)
          setArticulos(a); 

            console.log('Datos enviados:', values);
            resetForm();

      }}
      initialValues={{

            descripcion : "",

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id="parentForm" noValidate onSubmit={handleSubmit}>


            <Form.Group className="mb-3" controlId="descripcion">
              <Form.Label>Puedes agregar una sugerencia de material</Form.Label>
              <Form.Control
                required
                name="descripcion"
                value={values.descripcion}
                onChange={(e)=>{ setFieldValue('descripcion', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.descripcion}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.descripcion}</>} </Form.Control.Feedback>
              <div id="emailHelp" className="form-text">Los materiales que sugieras podrían no ser entregados</div>
            </Form.Group>


                <Button variant="success" onClick={(e:any)=>{handleSubmit(e)}} type="button">Guardar <i className="fa fa-save"/></Button>

        </Form>
      )}
    </Formik>







        </Form>
      )}
    </Formik>





        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalBoutiqueNuevoRefaccionamiento;