import React, { useState } from "react";
import AuthService from "../features/auth/auth.service";


import { Button, Modal } from 'react-bootstrap'

import PasswordChecklist from "react-password-checklist"

import Dialog from '../services/Dialogs'

import { toast } from 'react-toastify'

import RequestService from "../services/request.service";




import useFetch from '../services/useFetch';




import ModalCambiarFoto from './ModalCambiarFoto'


import { SERVER_ } from  '../config';



import { rol } from '../services/rol'



import './Profile.scss';


const Profile = () => {


    const VERSION_INSTALADA = localStorage.getItem("version_app");
    const [ VERSION_ACTUAL, serVersionActual ] = useState<string>('')

    const cargarVersionActual = async () =>{
      try{
          let res = await RequestService.getEndPoint('version_app')
          serVersionActual(res.data)

      }
      catch(e){
        toast.error('Problemas cargando la versión actual')
        toast.error(JSON.stringify(e))
      }
    }

    React.useEffect(() => {
      cargarVersionActual()
    }, []);









  const [password, setPassword] = useState("")
  const [passwordAgain, setPasswordAgain] = useState("")

  const dialog = new Dialog();

  const handleLogout = async () => {
    if(await dialog.confirm('Desear cerrar el acceso?',{}) ){
        AuthService.logout();
        window.location.reload();
    }
  }

  const currentUser  = AuthService.getCurrentUser();

  const [ ver_contrasena, setVerContrasena ] = useState<boolean>(false)

  const [show, setShow] = useState(false);
  const [send, setSend] = useState(false);

  const handleClose = () => setShow(false);
  const cambiarContrasenaModal = () =>{ 
      setPassword("")
      setPasswordAgain("")
      setShow(true);
  }
  
  const cambiarContrasena = async () =>{ 
      
      try{
          if(await dialog.confirm('¿Estás seguro?',{}) ){
              setShow(false);
              await RequestService.update('cambiar_password/'+currentUser.uuid,{ psw : password })
              toast.warning('Debes volver a entrar al sistema')
              setTimeout(()=>{
                  AuthService.logout();
                  window.location.reload();
              },1500)
          }
      }
      catch(e){
          toast.error('Error al cambiar la contraseña')
      }
  }




  const deleteCache = async ()=>{
    
          if(await dialog.confirm('¿Estás seguro?',{}) ){
                  localStorage.clear();
                  window.location.reload();
          }
  }

  



  const [ show_ModalCambiarFoto, setModalCambiarFoto ] = useState<boolean>(false)
  const handleShow_ModalCambiarFoto = () => {
    setModalCambiarFoto(true)
  }
  const handleClose_ModalCambiarFoto = () => {
    setModalCambiarFoto(false)
  }


      return (

        <React.Fragment>

            <div className="container">
              <div className="row">
                  <div className="col-lg-4 col-xl-4">
                      <div className="card-box text-center">

                             <div className="userMain">
                               <div className="userBlock">
                                   <div className="backgrounImg">
                                   </div>
                                   <div className="userImg">
                                       <img src={`${SERVER_}perfil/foto/${currentUser.uuid}`} onClick={handleShow_ModalCambiarFoto} />
                                   </div>
                                   <div className="userDescription">
                                       <h5>{currentUser.nombres}</h5>
                                       <p>{currentUser.uuid}</p>
                                   </div>


                                    <div className="text-left ms-3 me-3 mt-3 mb-3">

                                        <h6>Información de Usuario</h6>

                                        <p className="text-muted mb-2 font-13"><strong>Nombre : </strong> <span className="ml-2">{currentUser.nombres} {currentUser.paterno} {currentUser.materno}</span></p>
                                        <p className="text-muted mb-2 font-13"><strong>Celular : </strong><span className="ml-2">{(currentUser.celular)}</span></p>
                                        <p className="text-muted mb-2 font-13"><strong>Email : </strong> <span className="ml-2 ">{currentUser.email}</span></p>

                                        <p className="text-muted mb-2 font-13"><strong>Privilegios : </strong> <span className="ml-2 ">{rol(currentUser.rol)}</span></p>

                                        <p className="text-muted mb-2 font-13"><strong>Versión Instalada : </strong> <span className="ml-2 ">{VERSION_INSTALADA||`No podemos verificar la versión instalada `}</span></p>
                                        <p className="text-muted mb-2 font-13"><strong>Versión Actual : </strong> <span className="ml-2 ">{VERSION_ACTUAL}</span></p>



                                    </div>


                               </div>
                             </div>


                              <p className="nm mt-4"><small>Opciones</small></p>
                              <ul className="list-inline">
                                  <li className="list-inline-item" key={ Math.random().toString(36).substr(2, 9) }>
                                    <button onClick={()=>{cambiarContrasenaModal()}} className="btn btn-link">Cambiar Contraseña</button>
                                  </li>
                                  <li className="list-inline-item" key={ Math.random().toString(36).substr(2, 9) }>
                                    <button onClick={()=>{handleLogout()}} className="btn btn-link">Salir</button>
                                  </li>
                                  <li className="list-inline-item" key={ Math.random().toString(36).substr(2, 9) }>
                                    <button onClick={()=>{deleteCache()}} className="btn btn-link">Desinstalar</button>
                                  </li>
                              </ul>


                              <p className="mt-4 text-center">
                                <img src="./img/logo_.png" width="30%" />
                              </p>

                                    
                      </div> 


                  </div> 
                  <div className="col-lg-8 col-xl-8">

                      {
                        // JSON.stringify(currentUser)
                      }


                          <React.Fragment>

                            <h5>Módulos del Uso</h5>
                            <hr/>

                            <div className="row align-items-center">



                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-gears"/> Administración</h5>
                                      <p className="card-text">Gestión de la plataforma</p>
                                      <a href="./administracion" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        // (['ROLE_ADMIN','ROLE_JEFE_ADMIN'].indexOf(currentUser.rol) > -1) && (


                        //       <div className="col-sm-4">
                        //           <div className="card">
                        //             <div className="card-body">
                        //               <h5 className="card-title"><i className="fa fa-address-book-o"/> Contratos</h5>
                        //               <p className="card-text">Contratos de Clientes</p>
                        //               <a href="./administracion" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                        //             </div>
                        //           </div>
                        //       </div>

                        // )
                      }


                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN','ROLE_RESPONSABLE_OPERACION' ,'ROLE_RESPONSABLE_TECNICOS','ROLE_RESPONSABLE_LIMPIEZA'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-archive"/> Reportes</h5>
                                      <p className="card-text">Reportes y Analíticas</p>
                                      <a href="./reportes_analiticas" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN','ROLE_RECURSOS_MATERIALES'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-tags"/> Inventario</h5>
                                      <p className="card-text">Control de Inventario</p>
                                      <a href="./inventario" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-truck"/> Flotillas</h5>
                                      <p className="card-text">Control Vehicular</p>
                                      <a href="./vehiculos" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-calendar"/> Visualizar Calendario</h5>
                                      <p className="card-text">Roll de Operación</p>
                                      <a href="./calendario_preview" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }

                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN','ROLE_RESPONSABLE_OPERACION','ROLE_RESPONSABLE_TECNICOS','ROLE_RESPONSABLE_LIMPIEZA'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-calendar"/> Calendario de Trabajo</h5>
                                      <p className="card-text">Visualización de la Operación</p>
                                      <a href="./calendario" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN','ROLE_ATENCION_CLIENTES'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-ticket"/> Tickets</h5>
                                      <p className="card-text">Gestión de Tickets de Atención</p>
                                      <a href="./tickets" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }

                      {
                        (['ROLE_CLIENTE','ROLE_BOUTIQUE'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card text-white bg-info">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-ticket"/> Tickets</h5>
                                      <p className="card-text">Tickets de Atención</p>
                                      <a href="./mis_tickets" className="btn btn-light btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }

                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN','ROLE_RESPONSABLE_OPERACION','ROLE_RESPONSABLE_OPERACION','ROLE_RESPONSABLE_TECNICOS','ROLE_RESPONSABLE_LIMPIEZA'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-cubes"/>Operación</h5>
                                      <p className="card-text">Control de la Operación</p>
                                      <a href="./operacion" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }

                      {
                        // (['ROLE_ADMIN','ROLE_RESPONSABLE_OPERACION'].indexOf(currentUser.rol) > -1) && (


                        //       <div className="col-sm-4">
                        //           <div className="card">
                        //             <div className="card-body">
                        //               <h5 className="card-title"><i className="fa fa-sitemap"/> Operadores </h5>
                        //               <p className="card-text">Asignación de Trabajo</p>
                        //               <a href="./operacion/limpieza" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                        //             </div>
                        //           </div>
                        //       </div>

                        // )
                      }


 
                      {
                        (['ROLE_TECNICO','ROLE_LIMPIEZA','ROLE_CONSERVACION'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card text-white bg-primary">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-list-ul"/> Tareas Diarias</h5>
                                      <p className="card-text">Listado de Tareas del Día</p>
                                      <a href="./tareas" className="btn btn-light btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }

                      {
                        (['ROLE_TECNICO','ROLE_LIMPIEZA','ROLE_CONSERVACION'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card text-white bg-primary">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-list-ul"/> Mis Reportes</h5>
                                      <p className="card-text">Reporte de Tareas Asignadas </p>
                                      <a href="./mi_reporte" className="btn btn-light btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }
 
 

                       {
                        (['ROLE_TECNICO','ROLE_LIMPIEZA','ROLE_CONSERVACION' ].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card text-white bg-primary">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-ticket"/> Tickets</h5>
                                      <p className="card-text">Gestión de Tickets de Atención</p>
                                      <a href="./mis_tickets" className="btn btn-light btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }



                       {
                        (['ROLE_RESPONSABLE_TECNICOS','ROLE_RESPONSABLE_LIMPIEZA'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-ticket"/> Tickets</h5>
                                      <p className="card-text">Gestión de Tickets de Atención</p>
                                      <a href="./mis_tickets" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


 
                      {
                        (['ROLE_TECNICO','ROLE_LIMPIEZA','ROLE_CONSERVACION'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card text-white bg-primary">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-calendar"/> Mi Calendario</h5>
                                      <p className="card-text">Mis tareas asignadas</p>
                                      <a href="./mi_calendario" className="btn btn-light btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }
 
 


 
                      {
                        (['ROLE_BOUTIQUE'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card text-white bg-info">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-calendar"/> Visitas Programadas </h5>
                                      <p className="card-text">Propuesta de Visitas</p>
                                      <a href="./calendario/propuesta" className="btn btn-light btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }
 
 
                      {
                        // (['ROLE_CLIENTE','ROLE_BOUTIQUE'].indexOf(currentUser.rol) > -1) && (


                        //       <div className="col-sm-4">
                        //           <div className="card text-white bg-info">
                        //             <div className="card-body">
                        //               <h5 className="card-title"><i className="fa fa-calendar"/> Calendario de Servicio</h5>
                        //               <p className="card-text">Servicios Programados</p>
                        //               <a href="./calendario/visitas" className="btn btn-light btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                        //             </div>
                        //           </div>
                        //       </div>

                        // )
                      }
 




                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN','ROLE_RESPONSABLE_OPERACION','ROLE_RESPONSABLE_PRESUPUESTO'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-building"/> Clientes</h5>
                                      <p className="card-text">Panel de Clientes</p>
                                      <a href="./clientes" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }

 
                      {
                        (['ROLE_CLIENTE','ROLE_BOUTIQUE'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card text-white bg-info">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-desktop"/> Panel de Cliente</h5>
                                      <p className="card-text">Mis Servicios</p>
                                      <a href="./mi_panel" className="btn btn-light btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        (['ROLE_BOUTIQUE'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card text-white bg-info">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-shopping-basket"/> Insumos</h5>
                                      <p className="card-text">Material, artículos y Repuestos </p>
                                      <a href="./cliente/refaccionamiento" className="btn btn-light btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }





                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-user-secret"/> Proveedores</h5>
                                      <p className="card-text">Gestión de Proveedores </p>
                                      <a href="./proveedores/lista" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }







                      {
                        (['ROLE_ADMIN','ROLE_RESPONSABLE_PRESUPUESTO','ROLE_RESPONSABLE_LIMPIEZA','ROLE_RESPONSABLE_TECNICOS'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-dollar"/> Presupuestos</h5>
                                      <p className="card-text">Costes de Obra y/o Servicio</p>
                                      <a href="./operacion/presupuestos" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }




                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-check-circle"/> Presupuestos</h5>
                                      <p className="card-text">Aprobación de Obra/Servicio</p>
                                      <a href="./administracion/presupuestos" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                          {/*'RESIDENTE_OBRA',*/}
                          {/*'AUXILIAR_OBRA',*/}
                          {/*'ROLE_OBRA',*/}
                          {/*'ROLE_SHEI',*/}

                      
                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN','ROLE_RESPONSABLE_OPERACION','RESIDENTE_OBRA'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-shopping-basket"/> Requisiciones</h5>
                                      <p className="card-text">Aprobación de Requisiciones</p>
                                      <a href="./requisiciones" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }

                      {
                        (['ROLE_ADMIN','ROLE_COMPROBACIONES'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-search"/> Comprobaciones</h5>
                                      <p className="card-text">Comprobacion de egresos</p>
                                      <a href="./comprobaciones_egresos" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        (['ROLE_ADMIN','ROLE_COMPRAS'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-shopping-basket"/> Compras</h5>
                                      <p className="card-text">Compra con Proveedores</p>
                                      <a href="./compras" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        (['ROLE_ADMIN','ROLE_CONTABILIDAD'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-dollar"/> Pago Requisiciones</h5>
                                      <p className="card-text">Depositos de egresos</p>
                                      <a href="./deposito_egresos" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }




                      {
                        (['ROLE_TECNICO','ROLE_CONSERVACION'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body text-white bg-primary">
                                      <h5 className="card-title"><i className="fa fa-dollar"/> Mis Presupuestos</h5>
                                      <p className="card-text">Costes de Obra y/o Servicio</p>
                                      <a href="./tecnico/presupuestos" className="btn btn-light btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }

                      {
                        // (['ROLE_TECNICO','ROLE_CONSERVACION'].indexOf(currentUser.rol) > -1) && (


                        //       <div className="col-sm-4">
                        //           <div className="card">
                        //             <div className="card-body text-white bg-primary">
                        //               <h5 className="card-title"><i className="fa fa-money"/> Mis Requisiciones</h5>
                        //               <p className="card-text">Solicitudes de Presupuestos</p>
                        //               <a href="./mis_requisiciones" className="btn btn-light btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                        //             </div>
                        //           </div>
                        //       </div>

                        // )
                      }

                      {
                        // JSON.stringify(currentUser)
                      }

                      {
                        ([
                          'ROLE_ADMIN', 
                          'ROLE_RESPONSABLE_TECNICOS', 
                          'ROLE_RESPONSABLE_LIMPIEZA', 
                          'ROLE_RESPONSABLE_OPERACION',
                          'ROLE_RECURSOS_HUMANOS',
                          'ROLE_CONTABILIDAD',
                          'ROLE_RESPONSABLE_PRESUPUESTO',
                          'ROLE_SISTEMAS',
                          'ROLE_RECURSOS_MATERIALES',
                          'ROLE_ATENCION_CLIENTES',
                          'ROLE_MARKETING',
                          'ROLE_RECLUTAMIENTO',
                          'ROLE_JEFE_ADMIN',
                          'ROLE_COMPROBACIONES',
                          'ROLE_COMPRAS',

                          'RESIDENTE_OBRA',
                          'AUXILIAR_OBRA',
                          // 'ROLE_OBRA',
                          'ROLE_SHEI',

                          ].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-money"/> Mis Requisiciones</h5>
                                      <p className="card-text">Pagos, Viáticos, y Reembolsos</p>
                                      <a href="./mis_requisiciones" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN','ROLE_CONTABILIDAD'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-calendar-o"/> Pagos</h5>
                                      <p className="card-text">Programación de Pagos</p>
                                      <a href="./programacion_pagos" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }




                      {
                        (['ROLE_RESPONSABLE_TECNICOS','ROLE_RESPONSABLE_LIMPIEZA'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-dollar"/> Mis Presupuestos</h5>
                                      <p className="card-text">Costes de Obra y/o Servicio</p>
                                      <a href="./tecnico/presupuestos" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }





                      {
                        (['ROLE_RESPONSABLE_TECNICOS','ROLE_RESPONSABLE_LIMPIEZA'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-eye"/> Reportes</h5>
                                      <p className="card-text">Reporte de Obra y/o Servicio</p>
                                      <a href="./reporte_servicios" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }



                      {
                        // (['ROLE_ADMIN'].indexOf(currentUser.rol) > -1) && (


                        //       <div className="col-sm-4">
                        //           <div className="card">
                        //             <div className="card-body">
                        //               <h5 className="card-title"><i className="fa fa-credit-card"/> Cobranza</h5>
                        //               <p className="card-text">Gestión de crédito al Cliente</p>
                        //               <a href="./cobranza" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                        //             </div>
                        //           </div>
                        //       </div>

                        // )
                      }



                      {
                        (['ROLE_CLIENTE'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card text-white bg-info">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-dollar"/> Presupuestos</h5>
                                      <p className="card-text">Costes de Obra y/o Servicio</p>
                                      <a href="./cliente/presupuestos" className="btn btn-light btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }

                      {
                        // (['ROLE_CLIENTE'].indexOf(currentUser.rol) > -1) && (


                        //       <div className="col-sm-4">
                        //           <div className="card text-white bg-info">
                        //             <div className="card-body">
                        //               <h5 className="card-title"><i className="fa fa-credit-card"/> Facturación</h5>
                        //               <p className="card-text">Gestión del Crédito y Pagos</p>
                        //               <a href="./recursos_humanos" className="btn btn-light btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                        //             </div>
                        //           </div>
                        //       </div>

                        // )
                      }


                      {
                        // (['ROLE_TECNICO','ROLE_LIMPIEZA','ROLE_CONSERVACION'].indexOf(currentUser.rol) > -1) && (


                        //       <div className="col-sm-4">
                        //           <div className="card text-white bg-primary">
                        //             <div className="card-body">
                        //               <h5 className="card-title"><i className="fa fa-money"/> Mis Pagos</h5>
                        //               <p className="card-text">Reporte de mis Pagos</p>
                        //               <a href="./recursos_humanos" className="btn btn-light btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                        //             </div>
                        //           </div>
                        //       </div>

                        // )
                      }


                      {
                        (['ROLE_RECURSOS_HUMANOS','ROLE_RECLUTAMIENTO'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-star"/> Reporte Incidencias</h5>
                                      <p className="card-text">Reporte de la incidencias</p>
                                      <a href="./incidencias" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }



                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN','ROLE_CONTABILIDAD'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-money"/> Contabilidad</h5>
                                      <p className="card-text">Registro y Control de Pagos</p>
                                      <a href="./contabilidad" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }



                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN','ROLE_CONTABILIDAD'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-dollar"/> Cobros</h5>
                                      <p className="card-text">Registro y Control de Cobros</p>
                                      <a href="./cobro_servicios" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      

                      {
                        // (['ROLE_ADMIN'].indexOf(currentUser.rol) > -1) && (


                        //       <div className="col-sm-4">
                        //           <div className="card">
                        //             <div className="card-body">
                        //               <h5 className="card-title"><i className="fa fa-money"/> Pagos y Nómina</h5>
                        //               <p className="card-text">Registro y Control de Pagos</p>
                        //               <a href="./pagos" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                        //             </div>
                        //           </div>
                        //       </div>

                        // )
                      }


                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN','ROLE_RECURSOS_HUMANOS','ROLE_RECLUTAMIENTO'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-users"/> Recursos Humanos</h5>
                                      <p className="card-text">Gestión de Accesos y Privilegios</p>
                                      <a href="./recursos_humanos" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }




                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN' ].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-newspaper-o"/> Circulares</h5>
                                      <p className="card-text">Informes Corporativos</p>
                                      <a href="./circulares" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        (['ROLE_BOUTIQUE','ROLE_CLIENTE'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card text-white bg-info">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-newspaper-o"/> Circulares</h5>
                                      <p className="card-text">Informes Corporativos</p>
                                      <a href="./circulares" className="btn btn-light btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }






                      {
                        ([
                            'ROLE_RECURSOS_HUMANOS',
                            'ROLE_RECLUTAMIENTO',

                            'ROLE_RESPONSABLE_TECNICOS',
                            'ROLE_RESPONSABLE_LIMPIEZA',

                            'ROLE_RESPONSABLE_OPERACION',
                            
                            'ROLE_RESPONSABLE_PRESUPUESTO',

                              'ROLE_ADMIN',

                              'ROLE_CONTABILIDAD',

                              'ROLE_OBRA',
                              'ROLE_SISTEMAS',
                              'ROLE_RECURSOS_MATERIALES',
                              'ROLE_SHEI',
                              'ROLE_ATENCION_CLIENTES',

                              'ROLE_MARKETING',
                              'ROLE_RECLUTAMIENTO',
                              'ROLE_JEFE_ADMIN',

                              'ROLE_COMPROBACIONES',
                              'ROLE_COMPRAS',

                              'RESIDENTE_OBRA',
                              'AUXILIAR_OBRA',
                              'ROLE_OBRA',
                              'ROLE_SHEI',

                            ].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-building"/> Mapa de Clientes</h5>
                                      <p className="card-text">Clientes del Corporativo</p>
                                      <a href="./corporativo_clientes" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }
 





                      {
                        ([
                            'ROLE_RECURSOS_HUMANOS',
                            'ROLE_RECLUTAMIENTO',

                            'ROLE_RESPONSABLE_TECNICOS',
                            'ROLE_RESPONSABLE_LIMPIEZA',

                            'ROLE_RESPONSABLE_OPERACION',
                            
                            'ROLE_RESPONSABLE_PRESUPUESTO',

                              'ROLE_ADMIN',

                              'ROLE_CONTABILIDAD',

                              'ROLE_OBRA',
                              'ROLE_SISTEMAS',
                              'ROLE_RECURSOS_MATERIALES',
                              'ROLE_SHEI',
                              'ROLE_ATENCION_CLIENTES',

                              'ROLE_MARKETING',
                              'ROLE_RECLUTAMIENTO',
                              'ROLE_JEFE_ADMIN',

                              'ROLE_COMPROBACIONES',
                              'ROLE_COMPRAS',

                              'RESIDENTE_OBRA',
                              'AUXILIAR_OBRA',
                              'ROLE_OBRA',
                              'ROLE_SHEI',

                            ].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-wrench"/> Herramientas</h5>
                                      <p className="card-text">Instrumentos asignados</p>
                                      <a href="./mis_herramientas" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }



                      {
                        (['ROLE_TECNICO','ROLE_CONSERVACION','ROLE_LIMPIEZA'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card text-white bg-primary">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-wrench"/> Herramientas</h5>
                                      <p className="card-text">Instrumentos asignados</p>
                                      <a href="./mis_herramientas" className="btn btn-light btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }
 

 


 

                      {
                        ([
                            'ROLE_RECURSOS_HUMANOS',
                            'ROLE_RECLUTAMIENTO',

                            'ROLE_RESPONSABLE_TECNICOS',
                            'ROLE_RESPONSABLE_LIMPIEZA',

                            'ROLE_RESPONSABLE_OPERACION',
                            
                            'ROLE_RESPONSABLE_PRESUPUESTO',

                              'ROLE_ADMIN',

                              'ROLE_CONTABILIDAD',

                              'ROLE_OBRA',
                              'ROLE_SISTEMAS',
                              'ROLE_RECURSOS_MATERIALES',
                              'ROLE_SHEI',
                              'ROLE_ATENCION_CLIENTES',

                              'ROLE_MARKETING',
                              'ROLE_RECLUTAMIENTO',
                              'ROLE_JEFE_ADMIN',

                              'RESIDENTE_OBRA',
                              'AUXILIAR_OBRA',
                              'ROLE_OBRA',
                              'ROLE_SHEI',

                              'ROLE_COMPROBACIONES',
                              'ROLE_COMPRAS',

                            ].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-question-circle"/> FAQ</h5>
                                      <p className="card-text">Centro de Ayuda</p>
                                      <a href="./faqs" className="btn btn-primary btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }
 


  
                      {
                        (['ROLE_TECNICO','ROLE_LIMPIEZA','ROLE_CONSERVACION'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-4">
                                  <div className="card text-white bg-primary">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-question-circle"/> FAQ</h5>
                                      <p className="card-text">Centro de Ayuda</p>
                                      <a href="./faqs" className="btn btn-light btn-sm">Entrar <i className="fa fa-sign-in" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                            </div>

                          </React.Fragment>






                  </div> 

              </div>
            </div>


          <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Cambiar de Contraseña</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                
                  <form>
                      <div className="form-group mb-3">
                          <label className="form-label">Password:</label>
                          <input className="form-control" type={!ver_contrasena?'password':'text'} onChange={e => setPassword(e.target.value)} />
                      </div>
                      <div className="form-group mb-3">
                          <label className="form-label">Repetir Password:</label>
                          <input className="form-control" type={!ver_contrasena?'password':'text'} onChange={e => setPasswordAgain(e.target.value)} />
                      </div>

                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" id="ver_contra" checked={ver_contrasena} onChange={(e:any)=>{setVerContrasena(e.target.checked)}}  />
                      <label className="form-check-label" htmlFor="ver_contra"><small>Ver Contraseñas</small></label>
                    </div>

                    <br/>

                      <PasswordChecklist
                          rules={["minLength","specialChar","number","capital","match"]}
                          messages={{
                              minLength: "La contraseña tiene más de 8 carácteres.",
                              specialChar: "La contraseña debe contener carácteres especiales (* - , . ´)",
                              number: "La contraseña debe tener un número.",
                              capital: "La contraseña debe tener una letra mayúscula.",
                              match: "Las contraseñas coinciden.",
                          }}
                          minLength={8}
                          value={password}
                          valueAgain={passwordAgain}
                          onChange={(isValid) => {
                              setSend(!isValid)
                          }}
                      />
                  </form>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" disabled={send} onClick={cambiarContrasena}>
                Guardar Cambios
              </Button>
            </Modal.Footer>
          </Modal>









  {
    show_ModalCambiarFoto && (
      <ModalCambiarFoto show={show_ModalCambiarFoto} handleClose={handleClose_ModalCambiarFoto} usuario={currentUser} />
      )

  }


        </React.Fragment>


      );
    
}

export default Profile
