import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { SERVER_ } from '../../config'



const ModalVehiculoCargaFotos = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, vehiculo } = params



      // 'PATCH /vehiculos/actualizar_foto/:cual_foto/:uuid_vehiculo' : 'Vehiculos.actualizar_foto_vehiculo',




  const [ rand, setRand ] = useState<any>(Math.random().toString(36).substr(2, 9))


  const handleFileChange = (event:any,cual_foto:string) => {
    const selectedFile : any = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // setFotoA(reader.result);
        handleUpload(reader.result,cual_foto)
      };
      reader.readAsDataURL(selectedFile);
    }
  };


  const handleUpload = async (foto:any,cual_foto:string) => {
    if (foto) {
      try {
        const imagenBase64 : any = foto.split(',')[1];

        await RequestService.update( `vehiculo/actualizar_foto/${cual_foto}/${vehiculo.uuid_vehiculo}` , { foto : imagenBase64 })
        toast.success('Archivo subido con éxito');
        setRand(Math.random().toString(36).substr(2, 9))
      } catch (error) {
        console.error('Error al subir el archivo', error);
        toast.error('Error al subir el archivo');
      }
    } else {
      toast.error('Por favor, selecciona un archivo primero');
    }
  };







  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Ficha Vehícular</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <div className="row">
            <div className="col-sm-3">
                <p className="nm"><small>Marca</small></p>
                <h4>{vehiculo.marca}</h4>
            </div>
            <div className="col-sm-3">
                <p className="nm"><small>Modelo</small></p>
                <h4>{vehiculo.modelo}</h4>
            </div>
            <div className="col-sm-3">
                <p className="nm"><small>Placas</small></p>
                <h4>{vehiculo.placas}</h4>
            </div>
            <div className="col-sm-3">
                <p className="nm"><small>Número de Motor</small></p>
                <h4>{vehiculo.numero_motor}</h4>
            </div>
          </div>



          <div className="row">
            <div className="col-sm-3">
                <p className="nm"><small>Transmisión</small></p>
                <h4>{vehiculo.transmision}</h4>
            </div>
            <div className="col-sm-3">
                <p className="nm"><small>Color </small></p>
                <h4>{vehiculo.color}</h4>
            </div>
            <div className="col-sm-3">
                <p className="nm"><small>Servicio Cada (KM)</small></p>
                <h4>{vehiculo.servicio_cada}</h4>
            </div>
            <div className="col-sm-3">
                <p className="nm"><small>KM Inicial</small></p>
                <h4>{vehiculo.km_inicial}</h4>
            </div>
          </div>

                <p className="nm"><small>Número de Tarjeta de Circulación</small></p>
                <h4>{vehiculo.tiempo_servicio}</h4>


                <p className="nm"><small>Descripción o Detalles del Vehículo</small></p>
                <h4>{vehiculo.detalles||''}</h4>





          <div className="row">
            <div className="col-sm-4">
                <p className="nm"><small>Frente</small></p>
                  <label>
                    <input type="file" id="foto_frente" accept=".jpg,.jpeg,.png" onChange={(e:any)=>{handleFileChange(e,'foto_frente')}} style={{display:'none'}} />
                    <img src={`${SERVER_}vehiculo/fotos/foto_frente/${vehiculo.uuid_vehiculo}/${Math.random().toString(36).substr(2, 9)}`} width="100%" />
                  </label>
            </div>
            <div className="col-sm-4">
                <p className="nm"><small>Lateral 1</small></p>
                  <label>
                    <input type="file" id="foto_lateral_1" accept=".jpg,.jpeg,.png" onChange={(e:any)=>{handleFileChange(e,'foto_lateral_1')}} style={{display:'none'}} />
                    <img src={`${SERVER_}vehiculo/fotos/foto_lateral_1/${vehiculo.uuid_vehiculo}/${Math.random().toString(36).substr(2, 9)}`} width="100%" />
                  </label>
            </div>
            <div className="col-sm-4">
                <p className="nm"><small>Lateral 2</small></p>
                  <label>
                    <input type="file" id="foto_lateral_2" accept=".jpg,.jpeg,.png" onChange={(e:any)=>{handleFileChange(e,'foto_lateral_2')}} style={{display:'none'}} />
                    <img src={`${SERVER_}vehiculo/fotos/foto_lateral_2/${vehiculo.uuid_vehiculo}/${Math.random().toString(36).substr(2, 9)}`} width="100%" />
                  </label>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4">
                <p className="nm"><small>Trasera</small></p>
                  <label>
                    <input type="file" id="foto_trasera" accept=".jpg,.jpeg,.png" onChange={(e:any)=>{handleFileChange(e,'foto_trasera')}} style={{display:'none'}} />
                    <img src={`${SERVER_}vehiculo/fotos/foto_trasera/${vehiculo.uuid_vehiculo}/${Math.random().toString(36).substr(2, 9)}`} width="100%" />
                  </label>
            </div>
            <div className="col-sm-4">
                <p className="nm"><small>Arriba</small></p>
                  <label>
                    <input type="file" id="foto_arriba" accept=".jpg,.jpeg,.png" onChange={(e:any)=>{handleFileChange(e,'foto_arriba')}} style={{display:'none'}} />
                    <img src={`${SERVER_}vehiculo/fotos/foto_arriba/${vehiculo.uuid_vehiculo}/${Math.random().toString(36).substr(2, 9)}`} width="100%" />
                  </label>
            </div>
            <div className="col-sm-4">
                <p className="nm"><small>Interior 1</small></p>
                  <label>
                    <input type="file" id="foto_interior_1" accept=".jpg,.jpeg,.png" onChange={(e:any)=>{handleFileChange(e,'foto_interior_1')}} style={{display:'none'}} />
                    <img src={`${SERVER_}vehiculo/fotos/foto_interior_1/${vehiculo.uuid_vehiculo}/${Math.random().toString(36).substr(2, 9)}`} width="100%" />
                  </label>
            </div>
          </div>



          <div className="row">
            <div className="col-sm-4">
                <p className="nm"><small>Interior 2</small></p>
                  <label>
                    <input type="file" id="foto_interior_2" accept=".jpg,.jpeg,.png" onChange={(e:any)=>{handleFileChange(e,'foto_interior_2')}} style={{display:'none'}} />
                    <img src={`${SERVER_}vehiculo/fotos/foto_interior_2/${vehiculo.uuid_vehiculo}/${Math.random().toString(36).substr(2, 9)}`} width="100%" />
                  </label>
            </div>
            <div className="col-sm-4">
                <p className="nm"><small>Llaves</small></p>
                  <label>
                    <input type="file" id="foto_llaves" accept=".jpg,.jpeg,.png" onChange={(e:any)=>{handleFileChange(e,'foto_llaves')}} style={{display:'none'}} />
                    <img src={`${SERVER_}vehiculo/fotos/foto_llaves/${vehiculo.uuid_vehiculo}/${Math.random().toString(36).substr(2, 9)}`} width="100%" />
                  </label>
            </div>
            <div className="col-sm-4">
                <p className="nm"><small>Extra</small></p>
                  <label>
                    <input type="file" id="foto_extra" accept=".jpg,.jpeg,.png" onChange={(e:any)=>{handleFileChange(e,'foto_extra')}} style={{display:'none'}} />
                    <img src={`${SERVER_}vehiculo/fotos/foto_extra/${vehiculo.uuid_vehiculo}/${Math.random().toString(36).substr(2, 9)}`} width="100%" />
                  </label>
            </div>
          </div>







          


          

          {
            // JSON.stringify(vehiculo)
          }
        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalVehiculoCargaFotos;