import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import Dialog from '../../services/Dialogs'

const ModalNuevoIngresoEgreso = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params


  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
      tipo : yup.string().required(mensajes_validacion_generico),
      descripcion : yup.string().required(mensajes_validacion_generico),
  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro de la información?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.create( `administracion_ingresos_egresos/nuevo_registro` , { ...registro })

          if(res_){
            toast.success('El registro fue agregado correctamente')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }





  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Registro de Ingreso y Egreso</Modal.Title>
        </Modal.Header>
        <Modal.Body>


    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            tipo : "",
            descripcion : ""

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>



              <div className="mb-3">
                <label className="form-label">Tipo </label>
                  <Form.Select aria-label="Selección un Tipo de Ingreso o Egreso" required value={values.tipo} onChange={(e)=>{ setFieldValue('tipo',e.target.value) }} isInvalid={!!errors.tipo} >
                    <option>Selecciona el Tipo de Ingreso</option>
                    <option value={`INGRESO`}>INGRESO</option>
                    <option value={`EGRESO`}>EGRESO</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid"> {errors.tipo} </Form.Control.Feedback>
              </div>




            <Form.Group className="mb-3">
              <Form.Label>Descripción del Concepto</Form.Label>
              <Form.Control
                required
                name="descripcion"
                value={values.descripcion}
                onChange={(e)=>{ setFieldValue('descripcion', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.descripcion}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.descripcion}</>} </Form.Control.Feedback>
            </Form.Group>




        </Form>
      )}
    </Formik>




        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalNuevoIngresoEgreso;