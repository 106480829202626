import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal, Col, Row, Nav, Tab, Tabs } from 'react-bootstrap';

import { toast } from 'react-toastify'

import moment from 'moment'


import Dialog from '../../services/Dialogs'


import { calcularTiempoTranscurrido } from '../../services/funcionesPublicas'

import './vacaciones.scss'

const ModalVacaciones = ( params : any ) => {

  const { show, handleClose, empleado } = params

  const dialog = new Dialog()


  const [ cargando_, setCargando ] = useState( false )
  

  const [ dias_pedidos, setDiasPedidos ] = useState<any>([])


  const cargar = async( )=>{
    try{
      setCargando(true)
      let response : any = await RequestService.getEndPoint(`vacaciones/dias_pedidos/${empleado.uuid_ciudadano}`)
      setDiasPedidos(response.data)
      setCargando(false)
    }
    catch(e:any){
      setCargando(false)
      toast.error(e.response.data + '. Problemas al intentar cargar la lista de vacaciones')
    }
  }






      React.useEffect(() => {
        cargar()
        cargarHistorial_licencias()
      }, []);


    const periodo_1 = [{ mes :"enero", indice : 0 },
      { mes :"febrero", indice : 1 },
      { mes :"marzo", indice : 2 },
      { mes :"abril", indice : 3 },
      { mes :"mayo", indice : 4 },
      { mes :"junio", indice : 5 },
      { mes :"julio", indice : 6 },
      { mes :"agosto", indice : 7 },
      { mes :"septiembre", indice : 8 },
      { mes :"octubre", indice : 9 },
      { mes :"noviembre", indice : 10 },
      { mes :"diciembre", indice : 11 }]

  const [months, setMonths ] = useState<any>( periodo_1 );

  const year = new Date().getFullYear();

  // function to check and grey out previous & next months visible dates
  const isExtraDays = (week:any, date:any) => {
    if (week === 0 && date > 10) {
      return true;
    } else if (week === 5 && date < 10) {
      return true;
    } else if (week === 4 && date < 10) {
      return true;
    } else {
      return false;
    }
  };

  //function to get all days by week
  const getDate = (month:any) => {
    var calendar = [];

    const startDate = moment([year, month])
      .clone()
      .startOf("month")
      .startOf("week");

    const endDate = moment([year, month]).clone().endOf("month");

    const day = startDate.clone().subtract(1, "day");

    // looping a month by a week
    while (day.isBefore(endDate, "day")) {
      calendar.push(
        Array(7)
          .fill(0)
          .map(() => day.add(1, "day").clone().format("DD"))
      );
    }

    if (calendar.length > 0) {
      return calendar.map((week, index) => (
        <tr key={ Math.random().toString(36).substr(2, 9) } className="calender-row">
          {week.map((day) => (
            <td onClick={()=>{agregarDia( moment().format('YYYY')+'-'+((month+1).toString()).padStart(2,'0')+'-'+day  , isExtraDays(index, day) ) }} 
              key={ Math.random().toString(36).substr(2, 9) } className={ `calender-col 
                                                                            ${ dias_seleccionados.indexOf(moment().format('YYYY')+'-'+((month+1).toString()).padStart(2,'0')+'-'+day) > -1 && !isExtraDays(index, day)  ? 'no_seleccionable_picado' : '' } 
                                                                            ${ dias_pedidos.indexOf(moment().format('YYYY')+'-'+((month+1).toString()).padStart(2,'0')+'-'+day) > -1 && !isExtraDays(index, day) ? ('no_seleccionable') : ('') }` } >
              <span className="day-value">
                {isExtraDays(index, day) ? (
                  <span className="isDates-grey">{day}</span>
                ) : (
                   day
                )}
              </span>
            </td>
          ))}
        </tr>
      ));
    }
  };


  const [ dias_seleccionados, setDiasSeleccionados ] = useState<any>([])

  const agregarDia = (dia:any,estan_en_mes:boolean)=>{
    if((dias_seleccionados.length + dias_pedidos.length) > 15){
      let d = []
      for(let D of dias_seleccionados) if( D !== dia) d.push(D)
      setDiasSeleccionados(d)
      return
    }
    let d = [...dias_seleccionados]
    if(dias_seleccionados.indexOf(dia) === -1 && !estan_en_mes && dias_pedidos.indexOf(dia) === -1) d.push(dia);
    else{
      d = []
      for(let D of dias_seleccionados) if( D !== dia) d.push(D)
    }
    setDiasSeleccionados(d)
  }

  const enviar = async()=>{
    if(dias_seleccionados.length===0){ 
        toast.warning('Debes seleccionar al menos un día de vacaciones')
        return
    }
    if(await dialog.confirm('¿Estás seguro?',{})){
      try{
        setCargando(true)
        let res = await RequestService.getEndPointPost('vacaciones/agregar_dias/'+empleado.uuid_ciudadano, { dias_seleccionados : dias_seleccionados, observaciones : detalles })
        if(res.data){
          console.log(dias_seleccionados)
          toast.success('Las vacaciones fueron notificados al departamento de Recursos Humanos')        
          handleClose()
        }
        setCargando(false)
      }
      catch(e){
        setCargando(false)
        toast.error('Problemas al intentar guardar los días')
      }
    }
  }


const [detalles, setDetalles] = useState<string>('');




  const [ historial_licencias, setHistorial_licencias ] = useState<any>([])
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarHistorial_licencias = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`vacaciones/historial_vacaciones/${empleado.uuid_ciudadano}`)
      setHistorial_licencias(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }




const eliminarSolicitud = async (event:any,uuid_solicitud:string) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.delete( `Vacaciones/eliminar/${uuid_solicitud}`)

        if(res_){
          toast.warning('El Registro de las Vacaciones fue eliminado')
          setTimeout(()=>{
            // cargar()
              cargarHistorial_licencias()
              cargar()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}



  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Configuración de las Vacaciones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(empleado)
          }


          <h5>Empleado</h5>

          <div className="row">
            <div className="col-sm-4">
              <small>Nombre(s)</small>
              <h6>{empleado.nombres}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Paterno</small>
              <h6>{empleado.paterno}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Materno</small>
              <h6>{empleado.materno}</h6>
            </div>
          </div>


                <hr className="border border-primary border-3 opacity-75 mb-4"/>


    <Tabs
      defaultActiveKey="profile"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="Registro">



{
  // JSON.stringify(config.periodo)
}
{
  // JSON.stringify(months)
}
{
  (dias_seleccionados.length + dias_pedidos.length) > 15 && (

<div className="alert alert-danger" role="alert">
  No puedes agregar más días de descanso
</div>

    )
}


    <div className="row">

      {months.map((month:any, index:number) => (

        <div key={ Math.random().toString(36).substr(2, 9) } className="col-sm-6">
            <div className="tableContainer">
              <table   className="calender-date">
              <tbody>
                <tr>
                  <th className="month-name-col" colSpan={7}>
                    <h4 className="month-name">{month.mes}</h4>
                  </th>
                </tr>
                <tr style={{color:'grey'}}>
                  <th>Do</th>
                  <th>Lu</th>
                  <th>Ma</th>
                  <th>Mi</th>
                  <th>Ju</th>
                  <th>Vi</th>
                  <th>Sa</th>
                </tr>
                {getDate(month.indice)}
                </tbody>
              </table>
            </div>
        </div>

      ))}


    </div>

{



    // <Tab.Container id="left-tabs-example" defaultActiveKey="first">
    //   <Row>
    //     <Col sm={3}>
    //       <Nav variant="pills" className="flex-column">
    //         <Nav.Item>
    //           <Nav.Link eventKey="first">Tab 1</Nav.Link>
    //         </Nav.Item>
    //         <Nav.Item>
    //           <Nav.Link eventKey="second">Tab 2</Nav.Link>
    //         </Nav.Item>
    //       </Nav>
    //     </Col>
    //     <Col sm={9}>
    //       <Tab.Content>
    //         <Tab.Pane eventKey="first">
    //           <p>lorem</p>
    //         </Tab.Pane>
    //         <Tab.Pane eventKey="second">
    //           <p>lorem 2</p>




    //         </Tab.Pane>
    //       </Tab.Content>
    //     </Col>
    //   </Row>
    // </Tab.Container>

}


                    <div className="mb-3 mt-3">
                      <label htmlFor="razon" className="form-label">Detalles de la solicitud:</label>
                      <textarea className="form-control" name="observaciones" value={detalles} onChange={(e:any)=>{setDetalles(e.target.value.toUpperCase())}} rows={3}></textarea>
                    </div>




      </Tab>
      <Tab eventKey="profile" title="Historial">

                {
                  historial_licencias.map((solicitud:any)=>
                      <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>

                            <div className="card mb-2">
                              <div className="card-body">
                                <small>Descripción de la Solicitud</small>
                                <h5>{solicitud.detalles_solicitud}</h5>
                                <small>Fecha de la Solicitud</small>
                                <h5>{calcularTiempoTranscurrido(solicitud.fecha_creacion)}</h5>
                                {
                                  // JSON.stringify(solicitud.dias)
                                }
                                <small>Días Solicitados</small>
                                <ol>
                                  {
                                    solicitud.dias.map((dia:any)=>
                                        <li key={ Math.random().toString(36).substr(2, 9) }>
                                          {moment(dia.fecha).format('DD/MM/YYYY')}
                                        </li>
                                      )
                                  }
                                </ol>

                                
                                <button type="button" className="btn btn-link" onClick={(e:any)=>{eliminarSolicitud(e,solicitud.uuid)}}><i className="fa fa-trash"/></button>

                              </div>
                            </div>

                      </React.Fragment> 
                    )
                }

                {
                  historial_licencias.length === 0 && (<p><i className="fa fa-warning"/> No cuenta con registros</p>)
                }


      </Tab>
    </Tabs>









        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" onClick={(e)=>{enviar()}} type="button">Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalVacaciones;