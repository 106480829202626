import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';


import Dialog from '../../services/Dialogs'




const ModalEditarElemento = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, elemento } = params


  const dialog = new Dialog();

  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
          concepto : yup.string().required(mensajes_validacion_generico),
          monto_total : yup.number().required(mensajes_validacion_generico),
          idunidad_medida : yup.number().required(mensajes_validacion_generico),
          cantidad : yup.number().required(mensajes_validacion_generico),

  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)

  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.update( `requisicion/elemento/editar_informacion/${elemento.uuid}` , { ...registro })

          if(res_){
            toast.success('Los cambios en el elemento fueron registrados')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }



  React.useEffect(() => {
    cargarTipo_unidad_medida()
  }, []);



  const [ tipo_unidad_medida, setTipo_unidad_medida ] = useState<any>([])
  const cargarTipo_unidad_medida = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/tipo_unidad_medida`)
      setTipo_unidad_medida(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }





  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Editar Elemento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(elemento.idunidad_medida.id)
          }

    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            concepto : elemento.concepto,

            monto_total : elemento.monto_total,
            idunidad_medida : elemento.idunidad_medida?.id,
            cantidad : elemento.cantidad,


      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>


            <Form.Group className="mb-3" controlId="concepto">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="concepto"
                value={values.concepto}
                onBlur={(e)=>{ setFieldValue('concepto', e.target.value.toUpperCase() ) }}
                onChange={(e)=>{ setFieldValue('concepto', e.target.value ) }}
                isInvalid={!!errors.concepto}
                style={{textTransform:'uppercase'}}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.concepto}</>} </Form.Control.Feedback>
            </Form.Group>





                        <Form.Group className="mb-3" controlId="monto_total">
                          <Form.Label>Monto o Precio</Form.Label>
                          <Form.Control
                            required
                            name="monto_total"
                            value={values.monto_total}
                            onBlur={(e)=>{ setFieldValue('monto_total', e.target.value.toUpperCase() ) }}
                            onChange={(e)=>{ setFieldValue('monto_total', e.target.value ) }}
                            isInvalid={!!errors.monto_total}
                          />
                          <Form.Control.Feedback type="invalid"> {<>{errors.monto_total}</>} </Form.Control.Feedback>
                        </Form.Group>



                        {

                          elemento.tipo === 'compra' && (

                                    <div className="row">
                                        <Form.Group as={Col} sm={8} className="mb-3" controlId="idunidad_medida">
                                          <Form.Label>Unidad Medida</Form.Label>
                                          <Form.Select value={values.idunidad_medida} required onChange={(e)=>{ setFieldValue('idunidad_medida', e.target.value ) }}  isInvalid={!!errors.idunidad_medida}>
                                            <option value={''} >Selecciona una unidad de medida</option>
                                                  {
                                                    tipo_unidad_medida.map( (unidad_medida:any,indice_tipo_unidad_medida:number) =>
                                                      <React.Fragment key={indice_tipo_unidad_medida}>
                                                                
                                                                {
                                                                  true && (<option selected={unidad_medida.id===values.idunidad_medida} value={unidad_medida.id}> {unidad_medida.unidad_medida}</option>)
                                                                } 
                                                      </React.Fragment>
                                                    )
                                                  }
                                          </Form.Select>
                                          <Form.Control.Feedback type="invalid">{<>{errors.idunidad_medida}</>} </Form.Control.Feedback>
                                        </Form.Group>

                                      <Form.Group as={Col} sm={4} className="mb-3" controlId="cantidad">
                                        <Form.Label>Cantidad</Form.Label>
                                        <Form.Control 
                                          required
                                          name="cantidad"
                                          value={values.cantidad}
                                          onChange={(e)=>{ setFieldValue('cantidad', parseFloat(e.target.value) ) }}
                                          isInvalid={!!errors.cantidad}
                                        />
                                        <Form.Control.Feedback type="invalid"> {<>{errors.cantidad}</>} </Form.Control.Feedback>
                                      </Form.Group>
                                    </div>
                         

                            )

                        }



        </Form>
      )}
    </Formik>




        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalEditarElemento;