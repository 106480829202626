import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import FullCalendar from '@fullcalendar/react'
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import dayGridPlugin from '@fullcalendar/daygrid'

import ModalDetalleEvento from './ModalDetalleEvento'

import moment from 'moment'
import 'moment/locale/es'

const ModalPreviewCalendarioAdmin = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, calendario } = params



  const [ show_ModalDetalleEvento, setModalDetalleEvento ] = useState<boolean>(false)
  const [ evento_seleccionado, setEventoSeleccionado ] = useState<any>({})
  const handleShow_ModalDetalleEvento = (info:any) => {
    setEventoSeleccionado(info.event)
    setModalDetalleEvento(true)
  }
  const handleClose_ModalDetalleEvento = () => {
    setModalDetalleEvento(false)
  }




  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Calendario de la Programación del Nuevo Periodo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(calendario)
          }

      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin ]}
        initialView="dayGridMonth"
        
        eventClick={handleShow_ModalDetalleEvento}
        events={calendario}
      />


        </Modal.Body>
      </Modal>


  {
    show_ModalDetalleEvento && (


      <Modal show={show_ModalDetalleEvento} onHide={handleClose_ModalDetalleEvento} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Detalles de la Cita</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          {
            evento_seleccionado.extendedProps.metadata.folio === 'DIA_LIBRE' && (
                    <div className="row mb-3">
                      <div className="col-sm-6">
                        <h3 className="nm">Día Libre</h3>
                      </div>
                    </div>
              )
          }

          <div className="row mb-3">
            <div className="col-sm-6">
              <small>Fecha</small>
              <h5>{moment(evento_seleccionado.extendedProps.metadata.fecha_programada).format('YYYY-MM-DD')}</h5>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-sm-12">
              <small>Técnico</small>
              <h5 className="nm">{evento_seleccionado.extendedProps.metadata.idtecnico.nombres} </h5>
              <p className="nm"><small>{evento_seleccionado.extendedProps.metadata.idtecnico.paterno} {evento_seleccionado.extendedProps.metadata.idtecnico.materno}</small></p>
            </div>
          </div>

            {evento_seleccionado.extendedProps.metadata.idresponsable !== null && (

                      <div className="row mb-3">
                        <div className="col-sm-12">
                          <small>Responsable</small>
                            <h5 className="nm">{evento_seleccionado.extendedProps.metadata.idresponsable.nombres} </h5>
                            <p className="nm"><small>{evento_seleccionado.extendedProps.metadata.idresponsable.paterno} {evento_seleccionado.extendedProps.metadata.idresponsable.materno}</small></p>
                        </div>
                      </div>

                )
            }

            {
              evento_seleccionado.extendedProps.metadata.idboutique !== null && (
                    <div className="row mb-3">
                      <div className="col-sm-12">
                        <small>Boutique</small>
                        <h5>{evento_seleccionado.extendedProps.metadata.idboutique.nombre_boutique}</h5>
                        <p>{evento_seleccionado.extendedProps.metadata.idboutique.direccion}</p>


                        <small>Contacto</small>
                        <h5>{evento_seleccionado.extendedProps.metadata.idboutique.nombre_contacto}</h5>
                        <p>{evento_seleccionado.extendedProps.metadata.idboutique.telefono}</p>

                        
                      </div>
                    </div>
                )
            }



          {
            // JSON.stringify(evento_seleccionado)
          }

        </Modal.Body>
      </Modal>

      )

  }

    </>
  );
}


export default ModalPreviewCalendarioAdmin;