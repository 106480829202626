import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Form, Button, Modal, Col, Row, ButtonGroup } from 'react-bootstrap';

import { toast } from 'react-toastify'

import Dialog from '../../services/Dialogs'



import { Formik } from 'formik';
import * as yup from 'yup';


import { formatearNumero, tipoActividadLabel, labelUnidadMedida, ordenarPorTipo, tipoPresupuesto, calcularTiempoTranscurrido } from '../../services/funcionesPublicas'
import { rol } from '../../services/rol'


import ModalAgregarElementoPresupuesto from './ModalAgregarElementoPresupuesto'

import moment from 'moment'

const ModalTerminarPresupuesto = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, presupuesto } = params

  const dialog = new Dialog()


const cancelarPresupuesto = async(e:any ) => {
    try{

        await dialog.open({
          accept: 'SI',
          cancel: 'NO',
          dialogClass: '',
          message: 'Estás seguro?',
          target: e.target,
          template: `<p>Recuerda que una vez enviado el reporte no podrá modificarse</p>`
        })
        let dialogo : any = await dialog.waitForUser()
        
        if(dialogo){
          let res = await RequestService.update(`presupuesto/cancelar/${presupuesto.uuid}`, {} )
          if(res.data){
            toast.success('Se actualizo el estatus del Presupuesto')
            handleClose()
          }
          
        }

    }
    catch(e){
      toast.error('Tuvimos problemas  ')
    }

  }



  const construirActividades = (actividades:any)=>{
    let a = []
    for(let actividad of actividades){
      a.push({
          "idarticulo":actividad.idarticulo,
          "costo":actividad.costo,
          "precio":actividad.precio,
          "indispensable":actividad.indispensable,
          "urgente":actividad.urgente,
          "descripcion":actividad.descripcion,
          "tipo":actividad.tipo,
          "cantidad":actividad.cantidad,
          "idunidad_medida":actividad.idunidad_medida
        })
    }
    return a
  }



  const [ finalizar, setFinalizar ] = useState<any>(false)

  const [ actividades, setActividades ] = useState<any>(construirActividades(presupuesto.actividades_materiales))


  const agregarActividad = (tipo:number)=>{

    let a = [...actividades]
    a.push({
            idarticulo : null,
            costo : "",
            precio : "",
            indispensable : false,
            urgente : false,
            descripcion : "",
            tipo : tipo,
            cantidad : 1,
            idunidad_medida : tipo===3?null:""
          })

    setActividades(a)

  }


  const [ articulos, setArticulos ] = useState<any>([])
  const cargarArticulos = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/articulos`)
      setArticulos(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarArticulos()
    cargarTipo_unidad_medida()
    sumarSubtotales()
  }, []);



  const [ tipo_unidad_medida, setTipo_unidad_medida ] = useState<any>([])
  const cargarTipo_unidad_medida = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/tipo_unidad_medida`)
      setTipo_unidad_medida(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }






const mensajes_validacion_generico = "Este campo es obligatorio"
const schema = yup.object().shape({

          condiciones_ejecucion : yup.string().nullable(),

          descripcion : yup.string().required(mensajes_validacion_generico),


          tipo_condicion_pago : yup.string().when('entra_iguala',
                            (entra_iguala,schema)=>{
                                if(entra_iguala){
                                   return schema;
                                }else{
                                   return schema.required(mensajes_validacion_generico);
                                }
                            }),
          vigencia_dias_precios : yup.string().when('entra_iguala',
                            (entra_iguala,schema)=>{
                                if(entra_iguala){
                                   return schema;
                                }else{
                                   return schema.required(mensajes_validacion_generico);
                                }
                            }),
          costo_servicio : yup.string().when('entra_iguala',
                            (entra_iguala,schema)=>{
                                if(entra_iguala){
                                   return schema;
                                }else{
                                   return schema.required(mensajes_validacion_generico);
                                }
                            }),



          entra_iguala : yup.boolean(),
  });


  const schema_gasto_operacion = yup.object().shape({
          condiciones_ejecucion : yup.string(),
          tipo_condicion_pago : yup.string(),
          vigencia_dias_precios : yup.string(),
          costo_servicio : yup.string().nullable(),
          entra_iguala : yup.boolean(),
  });







const [ envio_server, setSeEnvio ] = useState<boolean>(false)

const enviarFormulario = async (registro:any,save:boolean,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        // let res_ = await RequestService.update( `URL_REQUEST` , {  actividades : actividades })
        let res_ = await RequestService.update(`presupuesto/enviar_valiacion_gerencia/${presupuesto.uuid}`, { registro : registro, actividades : actividades, guardar : save }  )

        if(res_){
          toast.success('El Estado del Presupuesto Cambio')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}



  const [ suma_subtotal, setSuma ] = useState<number>(0)
  const [ costo_servicio, setCostoServicio ] = useState<number>(presupuesto.costo_servicio)

  const sumarSubtotales = () =>{
    let s = 0
    for(let actividad of actividades){
      s += ( actividad.costo * actividad.cantidad )
    }
    setSuma(s)

  }
  


const guardarPresupuesto = async (event:any,registro:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: `<small><i class="fa fa-save"/> Se guardará la información del presupuesto</small>`
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `presupuesto/${presupuesto.uuid}` , { registro : registro, actividades : actividades } )
        if(res_){
          toast.info('El Presupuesto se Guardo Correctamente')
          setTimeout(()=>{
            // cargar()
          },0)
        }
        else{
          toast.warning('Problemas...')
        } 
        }
        catch(e:any){
          console.log(e.response.data)
          toast.warning('No pudimos guardar el presupuesto. Verifica toda la información.')
          // console.log(e.response.data)
        } 
        
      }
     
}



const aceptarPresupuesto = async (event:any,registro:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `presupuesto/aceptar_gastos/${presupuesto.uuid}` , { registro : registro, actividades : actividades } )

        if(res_){
          toast.success('El Presupuesto paso a Depósito')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}



const rechazarPresupuesto = async (event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `presupuesto/rechazar_gastos/${presupuesto.uuid}` , { })

        if(res_){
          toast.success('El Presupuesto paso a Depósito')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}




const aterrizarLista = async (event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: `<small>La lista de artículos que hayas creado se borrara</small>`
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){

        // {"idarticulo":null,"costo":"0","precio":0,"indispensable":false,"urgente":false,"descripcion":"XXXXXXXXX","tipo":0,"cantidad":1,"idunidad_medida":"29"}
        // setActividades([])

        let materiales = []
        for(let actividad of presupuesto.actividades_propuestas){
          // console.log(actividad.actividad)
              materiales.push({
                      idarticulo : null,
                      costo : 0,
                      precio : "",
                      indispensable : false,
                      urgente : false,
                      descripcion : actividad.actividad.split(' x ')[0],
                      tipo : 0,
                      cantidad : parseInt(actividad.actividad.split(' x ')[1]),
                      idunidad_medida : null
                    })
        }

        // console.log(materiales)

        setActividades(materiales)
        setSuma(0)
        // sumarSubtotales()
        
      }
     
}








  const [ opcion_modal_elemento, setOpcionModalElemento ] = useState<any>(null)
  const [ indice_seleccionado, setIndiceSeleccionado ] = useState<any>(null)
  const [ element_seleccionado, setElementoSeleccionado ] = useState<any>(null)

  const [ tipo_elemento_seleccionado, setTipoElementoSeleccionado ] = useState<any>(null)

  const [ show_ModalAgregarElementoPresupuesto, setModalAgregarElementoPresupuesto ] = useState<boolean>(false)
  const handleShow_ModalAgregarElementoPresupuesto = (tipo_elemento:string) => {
    setTipoElementoSeleccionado(tipo_elemento)
    setModalAgregarElementoPresupuesto(true)
    setOpcionModalElemento(false)
    setIndiceSeleccionado(null)
    setElementoSeleccionado(null)
  }
  const handleClose_ModalAgregarElementoPresupuesto = () => {
    cargarArticulos()
    sumarSubtotales()
    setOpcionModalElemento(false)
    setIndiceSeleccionado(null)
    setModalAgregarElementoPresupuesto(false)
  }


  const handleShow_ModalEditarElementoPresupuesto = (tipo_elemento:number,elemento:any,indice_elemento:number) => {
    sumarSubtotales()

    let tipo = 'servicio'
    if(tipo_elemento===0) tipo = 'material'

    setTipoElementoSeleccionado(tipo)
    setModalAgregarElementoPresupuesto(true)
    setOpcionModalElemento(true)
    setIndiceSeleccionado(indice_elemento)
    setElementoSeleccionado(elemento)
  }




  return (
    <>

    <Formik
      validationSchema={()=>{

        if (["GASTO_OPERACION","GASTOS_MOVILIZACION"].indexOf(presupuesto.tipo_presupuesto) > -1 ) return schema_gasto_operacion
        if(["ROLE_BOUTIQUE"].indexOf(presupuesto.idusuario.rol) > -1) return schema_gasto_operacion
        return schema

      }}
      onSubmit={(values,e)=>{enviarFormulario(values, false, e)}}
      initialValues={{

            condiciones_ejecucion : presupuesto.condiciones_ejecucion,
            tipo_condicion_pago : presupuesto.tipo_condicion_pago,
            vigencia_dias_precios : presupuesto.vigencia_dias_precios,
            costo_servicio : presupuesto.costo_servicio,
            entra_iguala : presupuesto.entra_iguala,

            descripcion : presupuesto.descripcion

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>





      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Terminar Presupuesto</Modal.Title>
        </Modal.Header>

          {
            // JSON.stringify(presupuesto.visita.idcalendario)
          }

          <div className={`text-center ${presupuesto.tipo_presupuesto}`} style={{padding: '10px 0', marginBottom:5}}  >
            <p className="nm"><small><b>{tipoPresupuesto(presupuesto.tipo_presupuesto)}</b></small></p>
          </div>



        <Modal.Body>


          {
            ["ROLE_BOUTIQUE"].indexOf(presupuesto.idusuario.rol) > -1 && (
                <React.Fragment>
                  
                      <div className="alert alert-primary nm"  >
                        <p className="nm"><i className="fa fa-star"/> Esta solicitud fue hecha por el contacto de la Boutique</p>
                      </div>


                </React.Fragment>
              )
          }

          {
            // presupuesto.idusuario
          }


                    <h6 className="mt--4">Realizó:</h6>

                    <div className="row">
                      <div className="col-sm-6">

                          <p className="nm"><b>{presupuesto.idusuario.nombres}</b> {presupuesto.idusuario.paterno} {presupuesto.idusuario.materno}</p>
                          <small style={{fontSize:10}}>{rol(presupuesto.idusuario.rol)}</small>


                      </div>
                      <div className="col-sm-2">
                        <small>Celular</small>
                        <h6>{presupuesto.idusuario.celular}</h6>
                      </div>
                      <div className="col-sm-4">

                        <small>Correo Electrónico</small>
                        <h6>{presupuesto.idusuario.email}</h6>
 
                      </div>
                    </div>



          {
            presupuesto.idboutique && (
                <React.Fragment>

                    <div className="row">
                      <div className="col-sm-4">
                          <h5 className="mt-2">Boutique</h5>
                          <p className="nm">{presupuesto.idboutique.nombre_boutique}</p>
                          {
                            presupuesto.solicitado && (<p className="nm"><i className="fa fa-star"/> Solicitado por la Boutique</p>)
                          }

                          {
                            // JSON.stringify(presupuesto.idboutique.idsubarea)
                          }
                      </div>
                      <div className="col-sm-2">
                          <h5 className="mt-2">Folio</h5>
                          <p>{presupuesto.folio}</p>
                      </div>
                      <div className="col-sm-2">
                          {
                            presupuesto?.visita &&
                              <>
                                <h5 className="mt-2">Folio de Servicio</h5>
                                <p>{presupuesto?.visita ? presupuesto.visita?.folio : 'En Proceso'}</p>
                              </> 
                            }
                      </div>
                      <div className="col-sm-4">-
                          {
                            presupuesto?.visita &&
                              <>
                                <h5 className="mt-2">Periodo</h5>
                                <p>DEL {moment(presupuesto?.visita?.idcalendario?.fecha_inicio).format('YYYY/MMM/DD').toUpperCase().replace('.','')} AL {moment(presupuesto?.visita?.idcalendario?.fecha_fin).format('YYYY/MMM/DD').toUpperCase().replace('.','')}</p>
                                {
                                  // JSON.stringify(presupuesto?.visita?.idcalendario)
                                }
                              </> 
                            }
                      </div>
                    </div>

                </React.Fragment>
              )
          }







          <h5 className="mt-2">Descripción General</h5>
          <p>{presupuesto.descripcion}</p>



          {
            (presupuesto.visita && presupuesto.visita?.actividades_reporte.length === 0) && (
              <React.Fragment>

                  <h5 className="mt-2">Actividades realizadas durante el servicio</h5>
                  <ol>
                    { presupuesto.visita.actividades_reporte.map( (actividad:any,indice_actividad:number)=><li key={indice_actividad}>{actividad}</li>)}
                  </ol>
                
              </React.Fragment>
            )
          }






                    {
                      // JSON.stringify(presupuesto.visita.actividades_reporte)
                    }








{
  ["ROLE_BOUTIQUE"].indexOf(presupuesto.idusuario.rol) === -1 && (
      <React.Fragment>
        
          {
            ["GASTO_OPERACION","GASTOS_MOVILIZACION"].indexOf(presupuesto.tipo_presupuesto) === -1  && (
                <React.Fragment>
                    <h6 className="mt-2">Tiempo Estimado de la Obra</h6>
                    <p>{presupuesto.dias_finaliza_estimados} Día(s)</p>
                </React.Fragment>
              )
          }


          {
            ["GASTO_OPERACION","GASTOS_MOVILIZACION"].indexOf(presupuesto.tipo_presupuesto) > -1  && (
                <h5 className="mt-2">Detalle de las Actividades a Realizar</h5>
              )
          }


      </React.Fragment>
    )
}


    {
      presupuesto.actividades_propuestas.length > 0 && (
          <React.Fragment>

          {
            (["GASTO_OPERACION","GASTOS_MOVILIZACION"].indexOf(presupuesto.tipo_presupuesto) === -1 && ["ROLE_BOUTIQUE"].indexOf(presupuesto.idusuario.rol) === -1)  && (
                <h5 className="mt-2">Detalle del levantamiento durante el Servicio</h5>
              )
          }

          {
            ["ROLE_BOUTIQUE"].indexOf(presupuesto.idusuario.rol) > -1 && (
                <h5 className="mt-2">Detalle del Refaccionamiento</h5>
              )
          }


          
                            <table className="table table-sm" >
                              <thead>
                                <tr>
                                  <th scope="col">Descripción</th>
                                  <th scope="col">Unidad</th>
                                  <th scope="col">Cantidad</th>
                                </tr>
                              </thead>
                              <tbody>
                                  {
                                    [...presupuesto.actividades_propuestas].sort(ordenarPorTipo).map((material:any,index_material:number)=>


                                    <tr key={index_material}>
                                      <td>
                                        <p className="nm"><small>{material.descripcion}</small></p>
                                      </td>
                                      <td className="v" style={{width:180}}>
                                        <p className="nm"><small>{labelUnidadMedida(parseInt(material.idunidad_medida),tipo_unidad_medida)}</small></p>
                                      </td>
                                      <td className="v" style={{width:80}}>
                                        <p className="nm text-right">{formatearNumero(parseFloat(material.cantidad)||0)}</p>
                                      </td>
                                      
                                    </tr>

                                      )
                                  }
                              </tbody>
                            </table>

                            {
                              // JSON.stringify(presupuesto.actividades_propuestas)
                            }
                            

          </React.Fragment>
        )
    }




 

          {
            // ["ROLE_BOUTIQUE"].indexOf(presupuesto.idusuario.rol) > -1 && (
            //         <p className=""><button type="button" onClick={aterrizarLista} className="btn nm btn-link">Aterrizar a lista de entrega</button></p>
            //   )
          }
{
  // JSON.stringify(actividades)
}



      <br/>



          {
            ["GASTO_OPERACION","GASTOS_MOVILIZACION"].indexOf(presupuesto.tipo_presupuesto) === -1  && (
                <React.Fragment>


                  {
                    ["ROLE_BOUTIQUE"].indexOf(presupuesto.idusuario.rol) === -1 && (
                        <React.Fragment>

                          <Form.Group   className="mb-3" controlId="descripcion">
                            <Form.Label>Descripción del Presupuesto</Form.Label>
                            <Form.Control
                              as="textarea"
                              required
                              rows={4}
                              name="descripcion"
                              value={values.descripcion}
                              onChange={(e)=>{ setFieldValue('descripcion', e.target.value ) }}
                              onBlur={(e)=>{ setFieldValue('descripcion', e.target.value.toUpperCase() ) }}
                              style={{textTransform:'uppercase'}}
                              isInvalid={!!errors.descripcion}
                            />
                            <Form.Control.Feedback type="invalid"> {<>{errors.descripcion}</>} </Form.Control.Feedback>
                          </Form.Group>


                          <Form.Group   className="mb-3" controlId="condiciones_ejecucion">
                            <Form.Label>Condiciones de Ejecución</Form.Label>
                            <Form.Control
                              as="textarea"
                              required
                              rows={4}
                              name="condiciones_ejecucion"
                              value={values.condiciones_ejecucion}
                              onChange={(e)=>{ setFieldValue('condiciones_ejecucion', e.target.value ) }}
                              onBlur={(e)=>{ setFieldValue('condiciones_ejecucion', e.target.value.toUpperCase() ) }}
                              style={{textTransform:'uppercase'}}
                              isInvalid={!!errors.condiciones_ejecucion}
                            />
                            <Form.Control.Feedback type="invalid"> {<>{errors.condiciones_ejecucion}</>} </Form.Control.Feedback>
                          </Form.Group>

                        </React.Fragment>
                      )
                  }


                  {
                    ["ROLE_BOUTIQUE"].indexOf(presupuesto.idusuario.rol) > -1 && (
                      
                        <React.Fragment>
                          <Form.Group   className="mb-3" controlId="condiciones_ejecucion">
                            <Form.Label>Información para la entrega</Form.Label>
                            <Form.Control
                              as="textarea"
                              required
                              rows={4}
                              name="condiciones_ejecucion"
                              value={values.condiciones_ejecucion}
                              onChange={(e)=>{ setFieldValue('condiciones_ejecucion', e.target.value ) }}
                              onBlur={(e)=>{ setFieldValue('condiciones_ejecucion', e.target.value.toUpperCase() ) }}
                              style={{textTransform:'uppercase'}}
                              isInvalid={!!errors.condiciones_ejecucion}
                            />
                            <Form.Control.Feedback type="invalid"> {<>{errors.condiciones_ejecucion}</>} </Form.Control.Feedback>
                          </Form.Group>
                        </React.Fragment>

                      )
                  }



          {
            ["GASTO_OPERACION","GASTOS_MOVILIZACION"].indexOf(presupuesto.tipo_presupuesto) === -1  && (
                <React.Fragment>
                    
                                  <Form.Group className="mb-3">
                                    <Form.Check
                                      checked={values.entra_iguala}
                                      onChange={(e)=>{ setFieldValue('entra_iguala', (e.target.checked) ) }}
                                      id="solo2"
                                      label="Entra en la iguala mensual por el servicio"
                                    />
                                  </Form.Group>

                </React.Fragment>
              )
          }




                    {
                      !values.entra_iguala && (

                            <Row>
                            <Form.Group as={Col} sm={3} className="mb-3" controlId="vigencia_dias_precios">
                              <Form.Label>Vigencia de los Precios</Form.Label>
                              <Form.Control
                                required
                                name="vigencia_dias_precios"
                                value={values.vigencia_dias_precios}
                                onChange={(e)=>{ setFieldValue('vigencia_dias_precios', (e.target.value) ) }}
                                isInvalid={!!errors.vigencia_dias_precios}
                              />
                              <Form.Control.Feedback type="invalid"> {<>{errors.vigencia_dias_precios}</>} </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} sm={3} className="mb-3" controlId="costo_servicio">
                              <Form.Label>Costo del Servicio</Form.Label>
                              <Form.Control
                                required
                                name="costo_servicio"
                                value={values.costo_servicio}
                                onChange={(e)=>{ setFieldValue('costo_servicio', (e.target.value) ); setCostoServicio(parseFloat(e.target.value)); sumarSubtotales() }}
                                isInvalid={!!errors.costo_servicio}
                              />
                              <Form.Control.Feedback type="invalid"> {<>{errors.costo_servicio}</>} </Form.Control.Feedback>
                            </Form.Group>

                 
                               <Form.Group as={Col} sm={6}  className="mb-3" controlId="tipo_condicion_pago">
                                <Form.Label>Selecciona la Condición de Pago</Form.Label>
                                <Form.Select required name="tipo_condicion_pago" value={values.tipo_condicion_pago} onChange={(e)=>{ setFieldValue('tipo_condicion_pago', e.target.value ) }} isInvalid={!!errors.tipo_condicion_pago}>
                                  <option value="">SELECCIONA UN TIPO</option>
                                    <option value={`PAGO_CONTADO`}>PAGO DE CONTADO</option>
                                    <option value={`PAGO_CREDITO`}>PAGO A CRÉDITO</option>
                                </Form.Select>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid"> {<>{errors.tipo_condicion_pago}</>} </Form.Control.Feedback>
                              </Form.Group>
                            </Row>

                        )
                    }





                    
                </React.Fragment>
              )
          }







          {
            actividades.length > 0 && (
                <React.Fragment>



          {
            // ["GASTO_OPERACION","GASTOS_MOVILIZACION"].indexOf(presupuesto.tipo_presupuesto) === -1  && (
            //     <React.Fragment>
                    
            //                       <Form.Group className="mb-3">
            //                         <Form.Check
            //                           checked={values.entra_iguala}
            //                           onChange={(e)=>{ setFieldValue('entra_iguala', (e.target.checked) ) }}
            //                           id="solo"
            //                           label="Entra en la iguala mensual por el servicio"
            //                         />
            //                       </Form.Group>

            //     </React.Fragment>
            //   )
          }


          {
            // JSON.stringify(actividades)
          }


                  <h5 className="mt-2">Explosión de precios unitarios</h5>

                  <table className="table table-sm"  >
                    <thead>
                      <tr>
                        <th scope="col">Tipo</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Unidad</th>
                        <th scope="col">Cantidad</th>
                        <th scope="col">P.U.</th>
                        <th scope="col">Costo</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      [...actividades].sort(ordenarPorTipo).map((material:any,index_material:number)=>


                      <tr key={index_material}>
                        <td className="v" style={{width:120}}>
                          <p className="nm">
                          {
                            // JSON.stringify(material.idunidad_medida)
                          }
                          <small><b>{tipoActividadLabel(material.tipo)}</b></small>
                          </p>
                        </td>
                        <td className="v">
                          <p className="nm"><small>{material.descripcion}</small></p>
                        </td>
                        <td className="v" style={{width:150}}>
                          <p className="nm"><small>{labelUnidadMedida(parseInt(material.idunidad_medida),tipo_unidad_medida)}</small></p>
                          {
                            // JSON.stringify(tipo_unidad_medida)
                          }
                          {
                            // JSON.stringify(material)
                          }
                        </td>
                        <td className="v" style={{width:100}}>
                          <p className="nm text-right">{ Number.isNaN(parseFloat((material.cantidad))) ? '' : ' '+formatearNumero(parseFloat(material.cantidad)||0)}</p>
                        </td>
                        <td className="v" style={{width:120}}>
                          <p className="nm text-right"> { Number.isNaN(parseFloat((material.costo))) ? '' : '$ '+formatearNumero(parseFloat(material.costo)||0)}</p>
                        </td>
                        <td className="v" style={{width:120}}>
                          <p className="nm text-right"> {( Number.isNaN(parseFloat((material.costo))) ? '' :  '$ '+formatearNumero(parseFloat((material.costo)||0)*(parseFloat(material.cantidad)||0)) )   }</p>
                        </td>

                        <td className="v" style={{width:30}}>
                            <button className="btn btn-warning btn-xs" onClick={(e:any)=>{handleShow_ModalEditarElementoPresupuesto(material.tipo,material,index_material)}}><i className="fa fa-edit"/></button>
                        </td>
                        <td className="v" style={{width:30}}>
                            <button className="btn btn-danger btn-xs" onClick={(e:any)=>{let a =[...actividades]; a.splice(index_material, 1); setActividades(a)}}><i className="fa fa-trash"/></button>
                        </td>
                                      
                      </tr>

                        )
                    }




                    {

                      !values.entra_iguala && (
                          <React.Fragment>

                                <tr className="table-subs">
                                  <td className="v" style={{width:100}} colSpan={5}>
                                    <p className="nm text-right"><b>SUB TOTAL:</b></p>
                                  </td>
                                  <td className="v" style={{width:100}}>
                                    <p className="nm text-right">$ {formatearNumero(suma_subtotal||0)}</p>
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>

                                {
                                      ["GASTO_OPERACION","GASTOS_MOVILIZACION"].indexOf(presupuesto.tipo_presupuesto) === -1  && (
                                      <React.Fragment>
                                          <tr className="table-subs">
                                            <td className="v" style={{width:100}} colSpan={5}>
                                              <p className="nm text-right"><b>SERVICIO:</b></p>
                                            </td>
                                            <td className="v" style={{width:100}}>
                                              <p className="nm text-right">$ {formatearNumero(costo_servicio)}</p>
                                            </td>
                                  <td></td>
                                  <td></td>
                                          </tr>
                                          <tr className="table-subs">
                                            <td className="v" style={{width:100}} colSpan={5}>
                                              <p className="nm text-right"><b>SERVICIO + SUB TOTAL:</b></p>
                                            </td>
                                            <td className="v" style={{width:100}}>
                                              <p className="nm text-right">$ {formatearNumero(costo_servicio + (suma_subtotal||0))}</p>
                                            </td>
                                  <td></td>
                                  <td></td>
                                          </tr>
                                      </React.Fragment>
                                    )
                                }

                                <tr className="table-impuestos">
                                  <td className="v" style={{width:100}} colSpan={5}>
                                    <p className="nm text-right"><b>IMPUESTOS:</b></p>
                                  </td>
                                  <td className="v" style={{width:100}}>
                                    <p className="nm text-right">$ {formatearNumero((costo_servicio + (suma_subtotal||0))*.16)}</p>
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr className="table-total">
                                  <td className="v" style={{width:100}} colSpan={5}>
                                    <p className="nm text-right"><b>TOTAL:</b></p>
                                  </td>
                                  <td className="v" style={{width:100}}>
                                    <p className="nm text-right">$ {formatearNumero( (costo_servicio + (suma_subtotal||0)) + ((costo_servicio + (suma_subtotal||0))*.16)  )}</p>
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>


                          </React.Fragment> 
                        )

                    }


                    {

                      values.entra_iguala && (
                          <React.Fragment>

                                <tr className="table-subs">
                                  <td className="v" style={{width:100}} colSpan={5}>
                                    <p className="nm text-right"><b>TOTAL:</b></p>
                                  </td>
                                  <td className="v" style={{width:100}}>
                                    <p className="nm text-right">$ {formatearNumero(suma_subtotal||0)}</p>
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>




                          </React.Fragment> 
                        )

                    }


                    </tbody>
                  </table>








                </React.Fragment>
              )
          }




                
                    <button className="btn btn-link" type="button" onClick={()=>{handleShow_ModalAgregarElementoPresupuesto('material')}}>Agregar Material</button>




          {
            ["ROLE_BOUTIQUE"].indexOf(presupuesto.idusuario.rol) === -1 && (
                <React.Fragment>
                

                    {
                      ["GASTO_OPERACION","GASTOS_MOVILIZACION"].indexOf(presupuesto.tipo_presupuesto) === -1  && (
                          <React.Fragment>

                              {/*<button className="btn btn-link" type="button" onClick={()=>{agregarActividad(1)}}>Agregar Mano de Obra</button>
                              <button className="btn btn-link" type="button" onClick={()=>{agregarActividad(2)}}>Agregar Herramienta</button>
                              <button className="btn btn-link" type="button" onClick={()=>{agregarActividad(3)}}>Agregar Servicio</button>
                              */}

                              <button className="btn btn-link" type="button" onClick={()=>{handleShow_ModalAgregarElementoPresupuesto('mano_obra')}}>Agregar Mano de Obra</button>
                              <button className="btn btn-link" type="button" onClick={()=>{handleShow_ModalAgregarElementoPresupuesto('herramienta')}}>Agregar Herramienta</button>
                              <button className="btn btn-link" type="button" onClick={()=>{handleShow_ModalAgregarElementoPresupuesto('servicio')}}>Agregar Servicio</button>
                              
                          </React.Fragment>
                        )
                    }

                    {
                      ["GASTO_OPERACION","GASTOS_MOVILIZACION"].indexOf(presupuesto.tipo_presupuesto) > -1  && (
                          <React.Fragment>

                              <button className="btn btn-link" type="button" onClick={()=>{handleShow_ModalAgregarElementoPresupuesto('gasto')}}>Agregar Gasto</button>
                              
                          </React.Fragment>
                        )
                    }


                </React.Fragment>
              )
          }




                    <div className="row mt-4">
                      <div className="col-3">
                        <Button variant="danger" className="w-100" onClick={cancelarPresupuesto} >
                          <i className="fa fa-warning"/> Cancelar Presupuesto
                        </Button>
                      </div>
                      <div className="col-9">

                          <ButtonGroup className="w-100">

                            {
                              actividades.length > 0 && (
                                  <React.Fragment>

                                                <Button onClick={(e:any)=>{aceptarPresupuesto(e,values)}} variant="success" > Aprobar <i className="fa fa-check-circle"/></Button>


                                    {
                                          ["GASTO_OPERACION","GASTOS_MOVILIZACION"].indexOf(presupuesto.tipo_presupuesto) === -1  && (
                                          <React.Fragment>
                                              <Button variant="success" form='my-form' type="submit">Mandar a Autorización </Button>
                                          </React.Fragment>
                                        )
                                    }
                                    {
                                          ["GASTO_OPERACION","GASTOS_MOVILIZACION"].indexOf(presupuesto.tipo_presupuesto) > -1  && (
                                          <React.Fragment>
                                                <Button onClick={(e:any)=>{rechazarPresupuesto(e)}} variant="danger" > Rechazar <i className="fa fa-times-circle"/></Button>
                                          </React.Fragment>
                                        )
                                    }


                                    {
                                      (values.entra_iguala && ["ROLE_BOUTIQUE"].indexOf(presupuesto.idusuario.rol) > -1) && (
                                          <React.Fragment>
                                            
                                                <Button onClick={(e:any)=>{rechazarPresupuesto(e)}} variant="danger" > Rechazar <i className="fa fa-times-circle"/></Button>
                                          </React.Fragment>
                                        )
                                    }

                                    <Button onClick={(e:any)=>{guardarPresupuesto(e,values)}} variant="info" > <i className="fa fa-save"/></Button>


                                  </React.Fragment>
                                )
                            }


                          </ButtonGroup>

                      </div>
                    </div>





        </Modal.Body>


      </Modal>



        </Form>
      )}
    </Formik>







  {
    show_ModalAgregarElementoPresupuesto && (
      <ModalAgregarElementoPresupuesto 
            show={show_ModalAgregarElementoPresupuesto} 
            handleClose={handleClose_ModalAgregarElementoPresupuesto} 
            tipo_elemento={tipo_elemento_seleccionado} 
            sumarSubtotales={sumarSubtotales} 
            setActividades={setActividades}
            actividades={actividades}
            editar={opcion_modal_elemento}
            indice={indice_seleccionado}

          />
      )

  }


    </>
  );
}


export default ModalTerminarPresupuesto;