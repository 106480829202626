import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import moment from 'moment'
import 'moment/locale/es'



import FullCalendar from '@fullcalendar/react'
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list';






const ModalCalendarioReportes = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params



    const [reportes, setReportes] = useState<any>([ ])



    const [ mis_tareas, setMis_tareas ] = useState<any>([])
    const cargarMis_tareas = async()=>{
      try{
        
        let response : any = await RequestService.getEndPoint(`calendario_supervision`)
        // setMis_tareas(response.data)
        setReportes(response.data)


      }
      catch(e){
        toast.error('Problemas al intentar cargar')
      }
    }


    React.useEffect(() => {
      cargarMis_tareas()
    }, []);






  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Calendario de Reportes</Modal.Title>
        </Modal.Header>
        <Modal.Body>

                    <FullCalendar
                      plugins={[dayGridPlugin, interactionPlugin, listPlugin ]}
                      initialView="dayGridMonth"
                      events={reportes}
                    />


        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalCalendarioReportes;