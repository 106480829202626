import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { Form, Col, Row } from 'react-bootstrap'

import { Formik, FieldArray, ErrorMessage } from 'formik';
import * as yup from 'yup';

import Dialog from '../../services/Dialogs'

const ModalEditarEquipoCritico = ( params : any ) => {

  const { show, handleClose, equipo } = params


  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
          idtipo_equipo : yup.number().required(mensajes_validacion_generico),
          marca : yup.string().required(mensajes_validacion_generico),
          modelo : yup.string().required(mensajes_validacion_generico),
          comentarios : yup.string(),
          capacidad : yup.string().required(mensajes_validacion_generico),
          voltaje_operacion : yup.string().required(mensajes_validacion_generico),
          enfriador : yup.boolean(),
          calefactor : yup.boolean(),

          instrucciones_mantenimiento: yup.array()
            .of(yup.object().shape({
              instruccion: yup.string().required(mensajes_validacion_generico).max(200, "Max 28 chars")
              // Rest of your amenities object properties
            }))

  });


      const [ tipos_equipos, setTiposEquipos ] = useState<any>([])

      const cargar = async () =>{
        try{
            let res = await RequestService.getEndPoint('tipos_equipos')
            setTiposEquipos(res.data)

        }
        catch(e){
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
        }
      }

      React.useEffect(() => {
        cargar()
      }, []);




const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `equipos_criticos/actualizar/${equipo.uuid}` , { ...registro, instrucciones_mantenimiento : registro.instrucciones_mantenimiento.map((instruccion:any)=>{return instruccion.instruccion }) })

        if(res_){
          toast.success('Se el equipo se registró correctamente')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}





  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Cambiar Información del Equipo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }

                <Formik
                  validationSchema={schema}
                  onSubmit={(values,e)=>{enviarFormulario(values, e)}}
                  initialValues={{

                        idtipo_equipo : equipo.idtipo_equipo.id,
                        marca : equipo.marca,
                        modelo : equipo.modelo,
                        comentarios : equipo.comentarios,
                        capacidad : equipo.capacidad,
                        voltaje_operacion : equipo.voltaje_operacion,
                        enfriador : equipo.enfriador,
                        calefactor : equipo.calefactor,

                        instrucciones_mantenimiento : JSON.parse(equipo.instrucciones_mantenimiento).map((ins:any)=>{return {instruccion:ins}})

                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                  }) => (
                    <Form id='my-form' noValidate onSubmit={handleSubmit}>


                    {
                      // JSON.stringify(equipo.instrucciones_mantenimiento.map((ins:any)=>{return {instruccion:ins}}))
                    }
                    {
                      // JSON.stringify(values)
                    }

                          <Form.Group  className="mb-3" controlId="idtipo_equipo">
                            <Form.Label>Tipo de Equipo Crítico</Form.Label>
                            <Form.Select required value={values.idtipo_equipo} onChange={(e)=>{ setFieldValue('idtipo_equipo',e.target.value) }} isInvalid={!!errors.idtipo_equipo} >
                              <option>Selecciona un tipo</option>
                                  {
                                    tipos_equipos.map((tipo:any)=>
                                        <option key={ Math.random().toString(36).substr(2, 9) } value={tipo.id}>{tipo.tipo_equipo}</option>
                                      )
                                  }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{<>{errors.idtipo_equipo}</>} </Form.Control.Feedback>
                          </Form.Group>




                        <Form.Group   className="mb-3" controlId="marca">
                          <Form.Label>Marca</Form.Label>
                          <Form.Control
                            as="textarea"
                            required
                            name="marca"
                            value={values.marca}
                            onChange={(e)=>{ setFieldValue('marca', e.target.value ) }}
                            onBlur={(e)=>{ setFieldValue('marca', e.target.value.toUpperCase() ) }}
                            style={{textTransform:'uppercase'}}
                            isInvalid={!!errors.marca}
                          />
                            <Form.Control.Feedback type="invalid">{<>{errors.marca}</>} </Form.Control.Feedback>
                        </Form.Group>


                        <Form.Group   className="mb-3" controlId="modelo">
                          <Form.Label>Modelo</Form.Label>
                          <Form.Control 
                            required
                            name="modelo"
                            value={values.modelo}
                            onChange={(e)=>{ setFieldValue('modelo', e.target.value ) }}
                            onBlur={(e)=>{ setFieldValue('modelo', e.target.value.toUpperCase() ) }}
                            style={{textTransform:'uppercase'}}

                            isInvalid={!!errors.modelo}
                          />
                            <Form.Control.Feedback type="invalid">{<>{errors.modelo}</>} </Form.Control.Feedback>
                        </Form.Group>





                          <Row>
                               <Col sm="6">

                              <Form.Group   className="mb-3" controlId="capacidad">
                                <Form.Label>Capacidad</Form.Label>
                                <Form.Control 
                                  required
                                  name="capacidad"
                                  value={values.capacidad}
                                  onChange={(e)=>{ setFieldValue('capacidad', (e.target.value || "").toUpperCase() ) }}
                                  isInvalid={!!errors.capacidad}
                                />
                            <Form.Control.Feedback type="invalid">{<>{errors.capacidad}</>} </Form.Control.Feedback>
                              </Form.Group>

                              <Form.Group   className="mb-3" controlId="voltaje_operacion">
                                <Form.Label>Voltaje de Operación</Form.Label>
                                <Form.Control 
                                  required
                                  name="voltaje_operacion"
                                  value={values.voltaje_operacion}
                                  onChange={(e)=>{ setFieldValue('voltaje_operacion', (e.target.value || "").toUpperCase() ) }}
                                  isInvalid={!!errors.voltaje_operacion}
                                />
                            <Form.Control.Feedback type="invalid">{<>{errors.voltaje_operacion}</>} </Form.Control.Feedback>
                              </Form.Group>
                              </Col>
                              <Col>
                                <Form.Label>Propiedades</Form.Label>
                                <Form.Check type="switch" id="enfriador" label="Es Enfriador" checked={values.enfriador} onChange={(e:any)=>{setFieldValue('enfriador',e.target.checked)}} />
                                <Form.Check type="switch" id="calefactor" label="Es Calefactor" checked={values.calefactor} onChange={(e:any)=>{setFieldValue('calefactor',e.target.checked)}} />
                              </Col>
                          </Row>
                              


                        <Form.Group   className="mb-3" controlId="comentarios">
                          <Form.Label>Comentarios</Form.Label>
                          <Form.Control
                            as="textarea"
                            name="comentarios"
                            value={values.comentarios}
                            onChange={(e)=>{ setFieldValue('comentarios', e.target.value ) }}
                            onBlur={(e)=>{ setFieldValue('comentarios', e.target.value.toUpperCase() ) }}
                            style={{textTransform:'uppercase'}}
                            isInvalid={!!errors.comentarios}
                          />
                            <Form.Control.Feedback type="invalid">{<>{errors.comentarios}</>} </Form.Control.Feedback>
                        </Form.Group>




                        <h4>Actividades para el Mantenimiento</h4>
                        <FieldArray
                            name="instrucciones_mantenimiento"
                            render={(arrayHelpers:any) => (
                                <div>
                                  {
                                    (values.instrucciones_mantenimiento && values.instrucciones_mantenimiento.length > 0) && (
                                    <React.Fragment>
                                      {
                                        values.instrucciones_mantenimiento.map((amenity:any, index:any) => 
                                            <div key={index} className="mb-2">
                                                <div className="hstack gap-3">
                                                  <input className="form-control form-control-sm me-auto" type="text" name={`instrucciones_mantenimiento[${index}].instruccion`} value={values.instrucciones_mantenimiento[index].instruccion} 
                                                  onChange={(e)=>{ setFieldValue(`instrucciones_mantenimiento[${index}].instruccion`, e.target.value ) }} 
                                                  onBlur={(e)=>{ setFieldValue(`instrucciones_mantenimiento[${index}].instruccion`, e.target.value.toUpperCase() ) }} 
                                                  style={{textTransform:'uppercase'}}
                                                  />
                                                  <div className="vr"></div>
                                                  <button type="button" onClick={() => arrayHelpers.remove(index)} className="btn btn-sm btn-outline-danger"><i className="fa fa-trash"/></button>
                                                </div>
                                            </div>
                                        )
                                      }
                                    </React.Fragment>
                                    )
                                  }
                                  <button type="button" className="btn mt-2 btn-sm btn-info" onClick={() => arrayHelpers.push({  instruccion: "" })}>Agregar Instrucción </button>
                                </div>
                            )}
                        />





                    </Form>
                  )}
                </Formik>




        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalEditarEquipoCritico;