import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import { Button, Modal, Col, Row } from 'react-bootstrap';

import { toast } from 'react-toastify'


import Dialog from '../../services/Dialogs'


const ModalConfiguracionHorarios = ( params : any ) => {

  const { show, handleClose, boutique } = params


  const [ data_boutique, setDataBoutique ] = useState<any>({ ...boutique })


  const dialog = new Dialog();
  
const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

          if(
            !data_boutique.abre_lunes && 
            !data_boutique.abre_martes && 
            !data_boutique.abre_miercoles && 
            !data_boutique.abre_jueves && 
            !data_boutique.abre_viernes && 
            !data_boutique.abre_sabado && 
            !data_boutique.abre_domingo 
            ){
            toast.error('Debes seleccionar por lo menos un día de la semana')
            return
          }

        setSeEnvio(true)
        let d = {
          abre_lunes : data_boutique.abre_lunes, 
          abre_martes : data_boutique.abre_martes, 
          abre_miercoles : data_boutique.abre_miercoles, 
          abre_jueves : data_boutique.abre_jueves, 
          abre_viernes : data_boutique.abre_viernes, 
          abre_sabado : data_boutique.abre_sabado, 
          abre_domingo : data_boutique.abre_domingo, 
          horario_lunes : data_boutique.horario_lunes, 
          horario_martes : data_boutique.horario_martes, 
          horario_miercoles : data_boutique.horario_miercoles, 
          horario_jueves : data_boutique.horario_jueves, 
          horario_viernes : data_boutique.horario_viernes, 
          horario_sabado : data_boutique.horario_sabado, 
          horario_domingo : data_boutique.horario_domingo, 
        }
        let res_ = await RequestService.update( `boutique/horarios/${boutique.uuid}` , { ...d })

        if(res_){
          toast.success('Los horarios se actualizaron correctamente')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Configuración de Horarios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(data_boutique)
          }

          <p className="nm">Boutique</p>
          <h4>{boutique.nombre_boutique}</h4>


          <p className="nm">Horarios</p>

          <Row>
            <Col>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={data_boutique.abre_lunes} onChange={(e:any)=>{ let d = { ... data_boutique }; d.abre_lunes = e.target.checked; setDataBoutique(d); }} />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    Lunes
                  </label>
                </div>
            </Col>
            <Col sm="8" md="8">
              <input type="text" className="form-control" disabled={!data_boutique.abre_lunes} value={data_boutique.horario_lunes||''} onChange={(e:any)=>{setDataBoutique({ ... data_boutique, horario_lunes : e.target.value.toUpperCase() })}} />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="horario_martes" checked={data_boutique.abre_martes} onChange={(e:any)=>{ let d = { ... data_boutique }; d.abre_martes = e.target.checked; setDataBoutique(d); }} />
                  <label className="form-check-label" htmlFor="horario_martes">
                    Martes
                  </label>
                </div>
            </Col>
            <Col sm="8" md="8">
              <input type="text" className="form-control" disabled={!data_boutique.abre_martes} value={data_boutique.horario_martes||''} onChange={(e:any)=>{setDataBoutique({ ... data_boutique, horario_martes : e.target.value.toUpperCase() })}} />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="horario_miercoles" checked={data_boutique.abre_miercoles} onChange={(e:any)=>{ let d = { ... data_boutique }; d.abre_miercoles = e.target.checked; setDataBoutique(d); }} />
                  <label className="form-check-label" htmlFor="horario_miercoles">
                    Miércoles
                  </label>
                </div>
            </Col>
            <Col sm="8" md="8">
              <input type="text" className="form-control" disabled={!data_boutique.abre_miercoles} value={data_boutique.horario_miercoles||''} onChange={(e:any)=>{setDataBoutique({ ... data_boutique, horario_miercoles : e.target.value.toUpperCase() })}} />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="horario_jueves" checked={data_boutique.abre_jueves} onChange={(e:any)=>{ let d = { ... data_boutique }; d.abre_jueves = e.target.checked; setDataBoutique(d); }} />
                  <label className="form-check-label" htmlFor="horario_jueves">
                    Jueves
                  </label>
                </div>
            </Col>
            <Col sm="8" md="8">
              <input type="text" className="form-control" disabled={!data_boutique.abre_jueves} value={data_boutique.horario_jueves||''} onChange={(e:any)=>{setDataBoutique({ ... data_boutique, horario_jueves : e.target.value.toUpperCase() })}} />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="horario_viernes" checked={data_boutique.abre_viernes} onChange={(e:any)=>{ let d = { ... data_boutique }; d.abre_viernes = e.target.checked; setDataBoutique(d); }} />
                  <label className="form-check-label" htmlFor="horario_viernes">
                    Viernes
                  </label>
                </div>
            </Col>
            <Col sm="8" md="8">
              <input type="text" className="form-control" disabled={!data_boutique.abre_viernes} value={data_boutique.horario_viernes||''} onChange={(e:any)=>{setDataBoutique({ ... data_boutique, horario_viernes : e.target.value.toUpperCase() })}} />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="horario_sabado" checked={data_boutique.abre_sabado} onChange={(e:any)=>{ let d = { ... data_boutique }; d.abre_sabado = e.target.checked; setDataBoutique(d); }} />
                  <label className="form-check-label" htmlFor="horario_sabado">
                    Sábado
                  </label>
                </div>
            </Col>
            <Col sm="8" md="8">
              <input type="text" className="form-control" disabled={!data_boutique.abre_sabado} value={data_boutique.horario_sabado||''} onChange={(e:any)=>{setDataBoutique({ ... data_boutique, horario_sabado : e.target.value.toUpperCase() })}} />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="horario_domingo" checked={data_boutique.abre_domingo} onChange={(e:any)=>{ let d = { ... data_boutique }; d.abre_domingo = e.target.checked; setDataBoutique(d); }} />
                  <label className="form-check-label" htmlFor="horario_domingo">
                    Domingo
                  </label>
                </div>
            </Col>
            <Col sm="8" md="8">
              <input type="text" className="form-control" disabled={!data_boutique.abre_domingo} value={data_boutique.horario_domingo||''} onChange={(e:any)=>{setDataBoutique({ ... data_boutique, horario_domingo : e.target.value.toUpperCase() })}} />
            </Col>
          </Row>



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" onClick={enviarFormulario} type="button">Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalConfiguracionHorarios;