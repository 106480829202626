import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal, Form, Col } from 'react-bootstrap';

import { Formik, Field, FieldArray, ErrorMessage } from 'formik';
import * as yup from 'yup';

import { toast } from 'react-toastify'

import Dialog from '../../services/Dialogs'


import { sonValoresIguales, obtenerDiaSemana, ordenarPorTipo, tipoActividadLabel, labelUnidadMedida, formatearNumero } from '../../services/funcionesPublicas'




const AgregarMaterialesActividades = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose,  material_actividades, setMaterialActividades } = params


  const [ materiales, setMateriales ] = useState<any>([...material_actividades])


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
          descripcion : yup.string().required(mensajes_validacion_generico),
          idunidad_medida : yup.string().required(mensajes_validacion_generico),
          cantidad : yup.number().required(mensajes_validacion_generico),
  });




  React.useEffect(() => {
    cargarTipo_unidad_medida()
  }, []);



  const [ tipo_unidad_medida, setTipo_unidad_medida ] = useState<any>([])
  const cargarTipo_unidad_medida = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/tipo_unidad_medida`)
      setTipo_unidad_medida(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  const eliminar = (indice:number) =>{
    let a = [...materiales]; 
    a.splice(indice,1); 
    setMateriales(a)
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Materiales Usados en el Servicio</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          {
            // JSON.stringify(materiales)
          }



          <p className="nm"><small>Materiales Utilizados en el Servicio</small></p>


          {
            materiales.length > 0 && (
              <table className="table table-sm mt-2" style={{fontSize:12}}>
                <thead>
                  <tr>
                    <th scope="col">Descripción</th>
                    <th scope="col">Unidad</th>
                    <th scope="col">Cantidad</th>
                    <th scope="col"> </th>
                  </tr>
                </thead>
                <tbody>
                {
                  [...materiales].sort(ordenarPorTipo).map((material:any,index:number)=>


                  <tr key={ Math.random().toString(36).substr(2, 9) }> 
                    <td>
                      <p className="nm"><small>{material.descripcion}</small></p>
                    </td>
                    <td className="v" style={{width:120}}>
                      <p className="nm"><small>{labelUnidadMedida(parseInt(material.idunidad_medida),tipo_unidad_medida)}</small></p>
                    </td>
                    <td className="v" style={{width:80}}>
                      <p className="nm text-right">{formatearNumero(parseFloat(material.cantidad)||0)}</p>
                    </td> 
                    <td className="v" style={{width:30}}>
                        <i onClick={(e:any)=>{ eliminar(index) }} className="fa fa-trash pointer"/>
                    </td> 
                  </tr>

                    )
                }


                </tbody>
              </table>
              )
          }

          {
            materiales.length === 0 && (
                  <div className="alert alert-light">
                    No has agregado materiales usados en esta actividad
                  </div>
              )
          }



                          
    <Formik
      validationSchema={schema}
      onSubmit={(values,{ resetForm })=>{
        setMateriales([...materiales, {...values} ])
        resetForm()

      }}
      initialValues={{
            descripcion : "",
            idunidad_medida : "",
            cantidad : "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form-y' noValidate onSubmit={handleSubmit}>


            <Form.Group className="mb-3" controlId="descripcion">
              <Form.Label>Descripcion del Material</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="descripcion"
                value={values.descripcion}
                onChange={(e)=>{ setFieldValue('descripcion', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.descripcion}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.descripcion}</>} </Form.Control.Feedback>
            </Form.Group>

 
 


            <div className="row">
                <Form.Group as={Col} sm={8} className="mb-3" controlId="idunidad_medida">
                  <Form.Label>Unidad Medida</Form.Label>
                  <Form.Select value={values.idunidad_medida} required onChange={(e)=>{ setFieldValue('idunidad_medida', e.target.value ) }}  isInvalid={!!errors.idunidad_medida}>
                    <option value={''} >Selecciona una unidad de medida</option>
                          {
                            tipo_unidad_medida.map( (unidad_medida:any,indice_tipo_unidad_medida:number) =>
                              <React.Fragment key={indice_tipo_unidad_medida}>
                                        
                                        {
                                          true && (<option selected={unidad_medida.id===values.idunidad_medida} value={unidad_medida.id}> {unidad_medida.unidad_medida}</option>)
                                        } 
                              </React.Fragment>
                            )
                          }
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{<>{errors.idunidad_medida}</>} </Form.Control.Feedback>
                </Form.Group>

              <Form.Group as={Col} sm={4} className="mb-3" controlId="cantidad">
                <Form.Label>Cantidad</Form.Label>
                <Form.Control 
                  required
                  name="cantidad"
                  value={values.cantidad}
                  onChange={(e)=>{ setFieldValue('cantidad', parseFloat(e.target.value) ) }}
                  isInvalid={!!errors.cantidad}
                />
                <Form.Control.Feedback type="invalid"> {<>{errors.cantidad}</>} </Form.Control.Feedback>
              </Form.Group>
            </div>
 
 



          
            <Form.Group className="mb-3" controlId="actividad">
                <Button variant="success" form='my-form-y' type="submit"><i className="fa fa-plus"/></Button>
            </Form.Group>



        </Form>
      )}
    </Formik>





        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" onClick={(e:any)=>{ setMaterialActividades(materiales); handleClose()  }} type="button">Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
 





const ModalTecnicoNuevoPresupuesto = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params


  const [ boutiques, setBoutiques ] = useState<any>([])
  const cargarBoutiques = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/boutiques`)
      setBoutiques(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarBoutiques()
    cargarTipo_unidad_medida()
  }, []);


 

  const [ tipo_unidad_medida, setTipo_unidad_medida ] = useState<any>([])
  const cargarTipo_unidad_medida = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/tipo_unidad_medida`)
      setTipo_unidad_medida(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  // if("PRESUPUESTO_CONSERVACION"===tipo) return "CONSERVACIÓN" 
  // if("PRESUPUESTO_REPARACION"===tipo) return "REPARACION" 
  // if("PRESUPUESTO_INSTALACION"===tipo) return "INSTALACIÓN" 
  // if("PRESUPUESTO_SUMINISTRO"===tipo) return "SUMINISTRO" 
  // if("PRESUPUESTO_SUMINISTRO_INSTALACION"===tipo) return "SUMINISTRO E INSTALACION" 
  // if(""===tipo) return "GASTOS DE OPERACIÓN" 
  // if(""===tipo) return "GASTOS DE MOVILIZACIÓN" 



  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
        tipo_presupuesto : yup.string().required(mensajes_validacion_generico),
        idboutique : yup.string().when('tipo_presupuesto',
            (tipo_presupuesto,schema)=>{
                // console.log('value business : ',tipo_presupuesto);
                if(["GASTO_OPERACION","GASTOS_MOVILIZACION"].indexOf(tipo_presupuesto)>-1){
                   return schema;
                }else{
                   return schema.required(mensajes_validacion_generico);
                }
            }), //.required(mensajes_validacion_generico),
        descripcion : yup.string().required(mensajes_validacion_generico),
        solicitado : yup.boolean(),
        dias_finaliza_estimados : yup.number(), //.nullable().required(mensajes_validacion_generico),

  // actividades_propuestas: yup.array()
  //   .of(yup.object().shape({
  //       actividad: yup.string().required(mensajes_validacion_generico),
  //       aprobada : yup.boolean()
  //   }))


  });



const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: '<p>Registro de Solicitud de Presupuesto</p>'
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `presupuestos/nuevo` , { ...registro, material_actividades : material_actividades })

        if(res_){
          toast.success('Tu Solicitud de Presupuesto fue registrada correctamente',{ icon : '💾' })
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}






  const [ material_actividades, setMaterialActividades ] = useState<any>([])




  const [ show_AgregarMaterialesActividades, setAgregarMaterialesActividades ] = useState<boolean>(false)
  const handleShow_AgregarMaterialesActividades = () => {
    setAgregarMaterialesActividades(true)
  }
  const handleClose_AgregarMaterialesActividades = () => {
    setAgregarMaterialesActividades(false)
  }





  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Solicitud de Presupuesto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }


    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{
            idboutique : "",
            tipo_presupuesto : "",
            descripcion : "",
            dias_finaliza_estimados : '',
            // actividades_propuestas : [{ actividad : "", aprobada : false }],
            solicitado : false
      }}

    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>

        {
          // JSON.stringify(values)
        }

                    <div className="row">
                      <div className="col-sm-8">
                        <Form.Group className="mb-3" controlId="tipo_presupuesto">
                          <Form.Label>Tipo de Presupuesto</Form.Label>
                          <Form.Select required name="tipo_presupuesto" value={values.tipo_presupuesto} onChange={(e)=>{ setFieldValue('tipo_presupuesto', e.target.value ) }} isInvalid={!!errors.tipo_presupuesto}>
                            <option disabled={values.tipo_presupuesto!==''} value="">SELECCIONA UN TIPO</option>
                              <option value={`PRESUPUESTO_CONSERVACION`}>CONSERVACIÓN</option>
                              <option value={`PRESUPUESTO_REPARACION`}>REPARACIÓN</option>
                              <option value={`PRESUPUESTO_INSTALACION`}>INSTALACIÓN</option>
                              <option value={`PRESUPUESTO_LIMPIEZA`}>LIMPIEZA</option>
                              <option value={`PRESUPUESTO_SUMINISTRO`}>SUMINISTRO</option>
                              <option value={`PRESUPUESTO_SUMINISTRO_INSTALACION`}>SUMINISTRO E INSTALACIÓN</option>
                              <option value={`GASTO_OPERACION`}>GASTO DE OPERACIÓN</option>
                              <option value={`GASTOS_MOVILIZACION`}>GASTO DE MOVILIZACIÓN</option>
                          </Form.Select>
                          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid"> {errors.tipo_presupuesto} </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      {
                        ["GASTO_OPERACION","GASTOS_MOVILIZACION"].indexOf(values.tipo_presupuesto) === -1 &&(

                            <div className="col-sm-4">
                                <Form.Group className="mb-3" controlId="dias_finaliza_estimados">
                                  <Form.Label>Días estimados </Form.Label>
                                  <Form.Control
                                    required
                                    name="dias_finaliza_estimados"
                                    value={values.dias_finaliza_estimados}
                                    onChange={(e)=>{ setFieldValue('dias_finaliza_estimados', e.target.value ) }}
                                    isInvalid={!!errors.dias_finaliza_estimados}
                                  />
                                  <Form.Control.Feedback type="invalid"> {<>{errors.dias_finaliza_estimados}</>} </Form.Control.Feedback>
                                </Form.Group>
                            </div>

                        )}

                    </div>


                      {
                        ["GASTO_OPERACION","GASTOS_MOVILIZACION"].indexOf(values.tipo_presupuesto) === -1 &&(

                          <React.Fragment>

                            <Form.Group className="mb-3" controlId="idboutique">
                              <Form.Label>Boutique</Form.Label>
                              <Form.Select required name="idboutique" value={values.idboutique} onChange={(e)=>{ setFieldValue('idboutique', e.target.value ) }} isInvalid={!!errors.idboutique}>
                                <option value="">SELECCIONA UNA BOUTIQUE</option>
                                {
                                  boutiques.map( (boutique:any) =>
                                    <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>
                                              <option value={boutique.uuid}> {boutique.nombre_boutique}</option>
                                    </React.Fragment>
                                  )
                                }

                              </Form.Select>
                              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid"> {errors.idboutique} </Form.Control.Feedback>
                            </Form.Group>

                            {values.idboutique!==''&&(
                                  <Form.Group className="mb-3">
                                    <Form.Check
                                      checked={values.solicitado}
                                      onChange={(e)=>{ setFieldValue('solicitado', (e.target.checked) ) }}
                                      id="solo"
                                      label="Este presupuesto fue solicitado por el gerente de la boutique"
                                    />
                                  </Form.Group>
                              )}

                            </React.Fragment>


                        )}



                      <Form.Group className="mb-3" controlId="descripcion">
                        <Form.Label>Descripción del Presupuesto</Form.Label>
                        <Form.Control
                          as="textarea"
                          required
                          rows={4}
                          name="descripcion"
                          value={values.descripcion}
                          onChange={(e)=>{ setFieldValue('descripcion', (e.target.value || "").toUpperCase() ) }}
                          isInvalid={!!errors.descripcion}
                        />
                        <Form.Control.Feedback type="invalid"> {<>{errors.descripcion}</>} </Form.Control.Feedback>
                      </Form.Group>



{
  // JSON.stringify(material_actividades)
}
                      {
                        ["GASTOS_MOVILIZACION"].indexOf(values.tipo_presupuesto) === -1 &&(

                            <React.Fragment>

                                  <Form.Label>Materiales Solicitados en el levantamiento</Form.Label>
           

                                    {
                                      material_actividades.length > 0 && (
                                        <table className="table table-sm mt-2" >
                                          <thead>
                                            <tr>
                                              <th scope="col">Descripción</th>
                                              <th scope="col">Unidad</th>
                                              <th scope="col">Cantidad</th> 
                                            </tr>
                                          </thead>
                                          <tbody>
                                          {
                                            [...material_actividades].sort(ordenarPorTipo).map((material:any,index:number)=>


                                            <tr key={ Math.random().toString(36).substr(2, 9) }> 
                                              <td>
                                                <p className="nm"><small>{material.descripcion}</small></p>
                                              </td>
                                              <td className="v" style={{width:220}}>
                                                <p className="nm"><small>{labelUnidadMedida(parseInt(material.idunidad_medida),tipo_unidad_medida)}</small></p>
                                              </td>
                                              <td className="v" style={{width:80}}>
                                                <p className="nm text-right">{formatearNumero(parseFloat(material.cantidad)||0)}</p>
                                              </td>  
                                            </tr>

                                              )
                                          }


                                          </tbody>
                                        </table>
                                        )
                                    }

                                    {
                                      material_actividades.length === 0 && (
                                            <div className="alert alert-light">
                                              No has agregado materiales usados en esta actividad
                                            </div>
                                        )
                                    }




                        <div className="mb-3">
                            <button className="btn btn-sm btn-link" type="button" onClick={handleShow_AgregarMaterialesActividades}>Agregar o Cambiar actividades Realizadas</button>
                        </div>

                            </React.Fragment>

                              )
                      }

 




{
                      // <Form.Group className="mb-3" controlId="idboutique">
                      //   <Form.Label><small>Haz una lista de las actividades y/o artículos que se necesitan para la Actividad:</small></Form.Label>

                      //  <FieldArray
                      //    name="actividades_propuestas"
                      //    render={arrayHelpers => (
                      //      <div>
                      //        {values.actividades_propuestas && values.actividades_propuestas.length > 0 ? (
                      //          values.actividades_propuestas.map((actividad, index) => (
                      //            <div key={index}>

                      //            {
                      //             // JSON.stringify(errors.actividades_propuestas[index].actividad)
                      //            }

                      //              <div className="mt-2">
                      //                 <label className="form-label">Actividad y/o Artículo ({index+1})</label>
                      //                <Field as="textarea" rows={2} name={`actividades_propuestas.${index}.actividad`} onChange={(e:any)=>{ setFieldValue(`actividades_propuestas.${index}.actividad`, e.target.value.toUpperCase() ) }} className={`form-control ${ (!isValid?'is-invalid':'') }`} />
                      //                 {
                      //                   // JSON.stringify(errors)
                      //                 }
                      //                     <div className="invalid-feedback" style={{display:'inline'}}>
                      //                       <ErrorMessage name={`actividades_propuestas.${index}.actividad`} />
                      //                     </div>
                      //              </div>

                      //              <p>
                      //                 <small><a onClick={() => arrayHelpers.insert(index+1, { actividad : "", aprobada : false } )} className="link-opacity-10"   >+ Actividad</a></small> { ' ' }
                      //                 <small><a onClick={() => arrayHelpers.remove(index)} className="link-opacity-10"  >- Actividad</a></small>
                      //             </p>

                      //            </div>
                      //          ))
                      //        ) : (
                      //          <button type="button" onClick={() => arrayHelpers.push('')}>
                      //            {/* show this when user has removed all friends from the list */}
                      //            + Actividad
                      //          </button>
                      //        )}
                      //        <div>

                      //        </div>
                      //      </div>
                      //    )}
                      //  />

                      // </Form.Group>
}

 


        </Form>
      )}
    </Formik>





        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>



  {
    show_AgregarMaterialesActividades && (
      <AgregarMaterialesActividades show={show_AgregarMaterialesActividades} handleClose={handleClose_AgregarMaterialesActividades} material_actividades={material_actividades} setMaterialActividades={setMaterialActividades} />
      )

  }




    </>
  );
}


export default ModalTecnicoNuevoPresupuesto;