import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'


import moment from 'moment'
import 'moment/locale/es'


import ModalNuevoReporte from './ModalNuevoReporte'
import ModalNuevoReporteEquipos from './ModalNuevoReporteEquipos'
import ModalEditarReporte from './ModalEditarReporte'
import ModalEditarReporteEquipos from './ModalEditarReporteEquipos'

import ModalDetalleReporte from './ModalDetalleReporte'

import ModalCalendarioReportes from './ModalCalendarioReportes'



import ModalReporteEvidenciasVisuales from '../Reportes/ModalReporteEvidenciasVisuales'





import { calcularTiempoTranscurrido, evaluarAtencionGlobal } from '../../services/funcionesPublicas'

import { rol } from '../../services/rol'

import Dialog from '../../services/Dialogs'


import { SERVER_ } from  '../../config';

const Reporte_servicios = () =>{

const dialog = new Dialog();

const [ envio_server, setSeEnvio ] = useState<boolean>(false)

  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let response : any = await RequestService.getEndPoint(`reporte_visitas_supervision/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true])


  const todos = () =>{
    setEstatus([true])
  }
  const ninguno = () =>{
    setEstatus([false])
  }




  const [ show_ModalNuevoReporte, setModalNuevoReporte ] = useState<boolean>(false)
  const handleShow_ModalNuevoReporte = () => {
    setModalNuevoReporte(true)
  }
  const handleClose_ModalNuevoReporte = () => {
    setModalNuevoReporte(false)
  }


  const [ reporte_seleccionado, setReporteSeleccionado ] = useState<any>(null)
  const [ show_ModalDetalleReporte, setModalDetalleReporte ] = useState<boolean>(false)
  const handleShow_ModalDetalleReporte = (reporte:any) => {
    setReporteSeleccionado(reporte)
    setModalDetalleReporte(true)
  }
  const handleClose_ModalDetalleReporte = () => {
    setModalDetalleReporte(false)
  }





  const [ show_ModalEditarReporte, setModalEditarReporte ] = useState<boolean>(false)
  const handleShow_ModalEditarReporte = (reporte:any) => {
    setReporteSeleccionado(reporte)
    setModalEditarReporte(true)
  }
  const handleClose_ModalEditarReporte = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalEditarReporte(false)
  }



const eliminarReporte = async (event:any,uuid_reporte:string) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.delete( `reporte_supervision/${uuid_reporte}` )

        if(res_){
          toast.warning('El Reporte fue eliminado')
          setTimeout(()=>{
            // handleClose()
            cargarMiReporte(currentPage===0?0:currentPage-1)
            // cargar()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}

const finalizarReporte = async (event:any,uuid_reporte:string) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.getEndPointPost( `reporte_supervision/finalizar/${uuid_reporte}`, {})

        if(res_){
          toast.success('El Reporte fue marcado como finalizado')
          setTimeout(()=>{
            // handleClose()
            cargarMiReporte(currentPage===0?0:currentPage-1)
            // cargar()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}





  const [ show_ModalReporteEvidenciasVisuales, setModalReporteEvidenciasVisuales ] = useState<boolean>(false)
  const handleShow_ModalReporteEvidenciasVisuales = (reporte:any) => {
    setReporteSeleccionado(reporte)
    setModalReporteEvidenciasVisuales(true)
  }
  const handleClose_ModalReporteEvidenciasVisuales = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalReporteEvidenciasVisuales(false)
  }




  const [ show_ModalNuevoReporteEquipos, setModalNuevoReporteEquipos ] = useState<boolean>(false)
  const handleShow_ModalNuevoReporteEquipos = () => {
    setModalNuevoReporteEquipos(true)
  }
  const handleClose_ModalNuevoReporteEquipos = () => {
    setModalNuevoReporteEquipos(false)
  }




  const [ show_ModalEditarReporteEquipos, setModalEditarReporteEquipos ] = useState<boolean>(false)
  const handleShow_ModalEditarReporteEquipos = (reporte:any) => {
    setReporteSeleccionado(reporte)
    setModalEditarReporteEquipos(true)
  }
  const handleClose_ModalEditarReporteEquipos = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalEditarReporteEquipos(false)
  }








  const [ show_ModalCalendarioReportes, setModalCalendarioReportes ] = useState<boolean>(false)
  const handleShow_ModalCalendarioReportes = () => {
    setModalCalendarioReportes(true)
  }
  const handleClose_ModalCalendarioReportes = () => {
    setModalCalendarioReportes(false)
  }




      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Reporte de Supervisión</h4>
                    <hr/>







                            <div className="row">
                            
                                <div className="mb-2">
                                    <label className="form-label">Búsqueda</label>
                                    <div className="hstack gap-3">
                                      <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                                      <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                                      <div className="vr"></div>
                                      <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                                    </div>
                                </div>
                                
                    {         
                       // <div>
                       //            <Form.Check inline name="group1" type="checkbox" label="CAPTURA" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
                       //          </div>
                              }
                                
                              <ul className="list-inline">
                                {
                                  // <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                                }
                                <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalNuevoReporte()}}>Nuevo Reporte Conservación</small></li>
                                <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalNuevoReporteEquipos()}}>Nuevo Reporte Equipos</small></li>
                                <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalCalendarioReportes()}}>Calendario de Reportes</small></li>
                              </ul>

                            </div>




                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead style={{fontSize:10}}>
                                            <tr>
                                              <th scope="col">Folio</th>
                                              <th scope="col">Boutique</th>
                                              <th scope="col">Creación</th>
                                              <th scope="col">Tipo de Reporte</th>
                                              <th scope="col">Estatus</th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (reporte:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>

                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                      <p className="nm"><b>{reporte.folio}</b></p>
                                                    </td>
                                                    <td className="v text-left"   >
                                                      <p className="nm"><b>{reporte.acronimo_folio}</b> <small>{reporte.nombre_boutique}</small></p>
                                                    </td>

                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                        <p className="nm"><b>{moment(reporte.fecha_creacion).format('YYYY-MM-DD')}</b></p>
                                                        <p className="nm"><small>{calcularTiempoTranscurrido(reporte.fecha_creacion)}</small></p>

                                                    </td>
                                                    <td className={`v text-left REPORTE_${reporte.tipo_reporte}`} style={{"width":"180px"}}>
                                                        { reporte.tipo_reporte === "MANTENIMIENTO" && (<small>MANTENIMIENTO</small>) }
                                                        { reporte.tipo_reporte === "CONSERVACION" && (<small>CONSERVACIÓN</small>) }
                                                    </td>
                                                    <td className="v text-left" style={{"width":"240px"}}>
                                                        {
                                                          reporte.finalizado && (
                                                              <React.Fragment>
                                                                  
                                                                  { reporte.tipo_reporte === "MANTENIMIENTO" && (<p className="nm"><b>{evaluarAtencionGlobal({ areas : reporte.reporte.equipos.map((equipo:any)=>{ return { ...equipo, lista_caminata_supervision : equipo.lista_supervision  } }), observaciones : reporte.reporte.observaciones })}</b></p>) }
                                                                  { reporte.tipo_reporte === "CONSERVACION" && (<p className="nm"><b>{evaluarAtencionGlobal(reporte.reporte)}</b></p>) }

                                                              </React.Fragment>
                                                            )
                                                        }
                                                        {
                                                          !reporte.finalizado && (
                                                              <React.Fragment>
                                                                  <p className="nm">Sin terminar</p>
                                                              </React.Fragment>
                                                            )
                                                        }
                                                    </td>
                                                    <td className="v text-left" style={{"width":"50px"}}>
                                                      
                                                      <button type="button" onClick={(e:any)=>{handleShow_ModalDetalleReporte(reporte)}} className="btn btn-sm btn-link"><i className="fa fa-eye"/></button>

                                                    </td>
                                                    <td className="v text-left" style={{"width":"50px"}}>
                                                        {
                                                          reporte.finalizado && (
                                                              <React.Fragment>
                                                                  <a href={`${SERVER_}supervision/hoja_supervision/${reporte.uuid_reporte}`} target="_blank" className="btn btn-link"><i className="fa fa-print"/></a>
                                                              </React.Fragment>
                                                            )
                                                        }
                                                    </td>
                                                    <td className="v text-left" style={{"width":"50px"}}>
                                                        {
                                                          !reporte.finalizado && (
                                                              <React.Fragment>
                                                                  { reporte.tipo_reporte === "MANTENIMIENTO" && (<button type="button" onClick={(e:any)=>{handleShow_ModalEditarReporteEquipos(reporte)}} className="btn btn-sm btn-warning"><i className="fa fa-edit"/></button>) }
                                                                  { reporte.tipo_reporte === "CONSERVACION" && (<button type="button" onClick={(e:any)=>{handleShow_ModalEditarReporte(reporte)}} className="btn btn-sm btn-warning"><i className="fa fa-edit"/></button>) }
                                                              </React.Fragment>
                                                            )
                                                        }
                                                    </td>
                                                    <td className="v text-left" style={{"width":"50px"}}>
                                                        {
                                                          (!reporte.finalizado) && (
                                                              <React.Fragment>
                                                                  <button type="button" onClick={(e:any)=>{handleShow_ModalReporteEvidenciasVisuales(reporte)}} className={`btn btn-sm ${( reporte.idreporte_evidencia_visual ? 'btn-info' : 'btn-warning' )}`}><i className="fa fa-cloud"/></button>
                                                              </React.Fragment>
                                                            )
                                                        }
                                                    </td>
                                                    <td className="v text-left" style={{"width":"50px"}}>
                                                        {
                                                          !reporte.finalizado && (
                                                              <React.Fragment>
                                                                  <button type="button" onClick={(e:any)=>{finalizarReporte(e,reporte.uuid_reporte)}} className="btn btn-sm btn-info"><i className="fa fa-check-circle"/></button>
                                                              </React.Fragment>
                                                            )
                                                        }
                                                    </td>
                                                    <td className="v text-left" style={{"width":"50px"}}>
                                                        {
                                                          !reporte.finalizado && (
                                                              <React.Fragment>
                                                                  <button type="button" onClick={(e:any)=>{eliminarReporte(e,reporte.uuid_reporte)}} className="btn btn-sm btn-danger"><i className="fa fa-trash"/></button>
                                                              </React.Fragment>
                                                            )
                                                        }
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}




                    
                </div>
              </div>

            </div>



  {
    show_ModalNuevoReporte && (
      <ModalNuevoReporte show={show_ModalNuevoReporte} handleClose={handleClose_ModalNuevoReporte}  />
      )

  }



  {
    show_ModalNuevoReporteEquipos && (
      <ModalNuevoReporteEquipos show={show_ModalNuevoReporteEquipos} handleClose={handleClose_ModalNuevoReporteEquipos}  />
      )

  }


  {
    show_ModalDetalleReporte && (
      <ModalDetalleReporte show={show_ModalDetalleReporte} handleClose={handleClose_ModalDetalleReporte} reporte={reporte_seleccionado}  />
      )

  }




  {
    show_ModalEditarReporte && (
      <ModalEditarReporte show={show_ModalEditarReporte} handleClose={handleClose_ModalEditarReporte} reporte={reporte_seleccionado} />
      )

  }


  {
    show_ModalEditarReporteEquipos && (
      <ModalEditarReporteEquipos show={show_ModalEditarReporteEquipos} handleClose={handleClose_ModalEditarReporteEquipos} reporte={reporte_seleccionado} />
      )

  }





  {
    show_ModalReporteEvidenciasVisuales && (
      <ModalReporteEvidenciasVisuales show={show_ModalReporteEvidenciasVisuales} handleClose={handleClose_ModalReporteEvidenciasVisuales} registro={reporte_seleccionado} tipo="REPORTE_SUPERVISION" />
      )

  }








  {
    show_ModalCalendarioReportes && (
      <ModalCalendarioReportes show={show_ModalCalendarioReportes} handleClose={handleClose_ModalCalendarioReportes}  />
      )

  }


          </>

      );
  

}

export default Reporte_servicios
