import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal, Form } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { Formik } from 'formik';
import * as yup from 'yup';

import Dialog from '../../services/Dialogs'

import { FormateaNumero, obtenerNombreDia, calcularTiempoTranscurrido } from '../../services/funcionesPublicas'

import moment from 'moment'
import 'moment/locale/es'


import './chat.scss'

const ModalMensajesTicket = ( params : any ) => {

  const { show, handleClose, ticket } = params

  const boutique = ticket.idboutique ? { ... ticket.idboutique } : null


  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
          mensaje : yup.string().required(mensajes_validacion_generico),
  });



const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `ticket/mensaje/agregar/${ticket.uuid_ticket}` , { ...registro })

        if(res_){
          toast.success('El Mensaje se agregó')
          setTimeout(()=>{
            // handleClose()
              cargarMensajes()
              setSeEnvio(false)
          },0)
        }
        else{
          toast.error('Problemas...')
          setSeEnvio(false)
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          setSeEnvio(false)
          // console.log(e.response.data)
        } 
        
      }
     
}


  const [ mensajes, setMensajes ] = useState<any>([])
  const cargarMensajes = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`ticket/mensajes/${ticket.uuid_ticket}`)
      setMensajes(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarMensajes()
  }, []);



    




  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Mensajes del Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        {
          // JSON.stringify(ticket.idestatus_ticket.id)
        }

            <small>Folio</small>
            <h6>{String((ticket.id)).padStart(6, '0')}</h6>

        {
          boutique && (
              <React.Fragment>


            <small>Boutique</small>
            <h3>{ boutique.nombre_boutique }</h3>


            <h6 className=" ">{boutique.direccion}</h6>
            <h6 className=" ">{boutique.idestado.estado}, {boutique.idmunicipio.municipio}</h6>
            <p className="nm"><b>Contacto Boutique: </b> {boutique.nombre_contacto} <small><i className="fa fa-phone"/> <a href={`tel:${boutique.telefono_contacto}`}>{FormateaNumero(boutique.telefono_contacto)}</a></small></p>

              
              </React.Fragment>
            )
        }


            <p className="nm"><b>Tipo de Ticket: </b> <small>{ticket.idtipo_ticket.tipo_ticket}</small></p>

                                                          <p className="nm"><small>{ticket.descripcion_ticket}</small></p>
                                                          <p className="nm">{calcularTiempoTranscurrido(ticket.fecha_creacion_ticket)}</p>



            {
              mensajes.length > 0 && (
                    <> 
                      <h6 className="mt-4">Mensajes del Ticket:</h6>
                      <div className="chat mb-3">
                        <div className="messages" id="chat">
                        {
                          mensajes.map((mensaje:any)=>
                              <>
                              {mensaje.idusuario.rol==='ROLE_ADMIN' && (<div className={`message stark`}><p className="nm">{mensaje.mensaje}</p> <small>{mensaje.leido_atencion?<i className="fa fa-envelope-open"/>:<i className="fa fa-envelope"/>} {' '} {calcularTiempoTranscurrido(mensaje.fecha_creacion)}</small> </div> )}
                              {mensaje.idusuario.rol!=='ROLE_ADMIN' && (<div className={`message parker`}><p className="nm">{mensaje.mensaje}</p>  <small>{mensaje.leido_creador?<i className="fa fa-envelope-open"/>:<i className="fa fa-envelope"/>} {' '} {calcularTiempoTranscurrido(mensaje.fecha_creacion)} </small> </div> )}
                              </>
                            )
                        }
                        </div>
                        <span className="pointer" onClick={(e:any)=>{cargarMensajes()}}><small><i className="fa fa-refresh"/> Cargar Mensajes</small></span>
                      </div>
                    </>
                )
            }


            {
              // JSON.stringify(mensajes)
            }

                          {  (!envio_server) && (

                                <Formik
                                  validationSchema={schema}
                                  onSubmit={(values,e)=>{enviarFormulario(values, e)}}
                                  initialValues={{
                                        mensaje : "",
                                  }}
                                >
                                  {({
                                    handleSubmit,
                                    handleChange,
                                    setFieldValue,
                                    handleBlur,
                                    values,
                                    touched,
                                    isValid,
                                    errors,
                                  }) => (
                                    <Form id='my-form' noValidate onSubmit={handleSubmit}>





                                                       
                                  {
                                    [1,2].indexOf(ticket.idestatus_ticket.id) > -1 && (
                                     <Form.Group className="mb-3" controlId="mensaje">
                                        <Form.Label>Nuevo Mensaje</Form.Label>
                                        <Form.Control
                                          as="textarea"
                                          required
                                          name="mensaje"
                                          value={values.mensaje}
                                          onBlur={(e)=>{ setFieldValue('mensaje', e.target.value.toUpperCase() ) }}
                                          onChange={(e)=>{ setFieldValue('mensaje', e.target.value ) }}
                                          isInvalid={!!errors.mensaje}
                                          style={{textTransform:'uppercase'}}
                                        />
                                        <Form.Control.Feedback type="invalid"> {<>{errors.mensaje}</>} </Form.Control.Feedback>
                                      </Form.Group>
                                      )
                                  }





                                    </Form>
                                  )}
                                </Formik>


                           )}

                          {  envio_server && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}

 


        </Modal.Body>
        {
          [1,2].indexOf(ticket.idestatus_ticket.id) > -1 && (
              <Modal.Footer>
                  {  (!envio_server) && ( <Button variant="success" form='my-form' type="submit">AGREGAR MENSAJE</Button> )}
              </Modal.Footer>
            )
        }

      </Modal>
    </>
  );
}


export default ModalMensajesTicket;