import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Form, Modal, Tabs, Tab } from 'react-bootstrap';

import { toast } from 'react-toastify'

import moment from 'moment'


import Dialog from '../../services/Dialogs'



const ModalDevolutivos = ( params : any ) => {

  const dialog = new Dialog()

  const { show, handleClose, empleado } = params

  const [ cargando_, setCargando ] = useState( false )
  const [ dias_disponibles, setDiasDisponibles ] = useState<any>([])

  const cargarDiasDisponibles = async()=>{
    try{
      setCargando(true)
      let response : any = await RequestService.getEndPoint(`incidencias/devolutivos/${empleado.uuid_ciudadano}`)
      setDiasDisponibles(response.data)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar notificar a Recursos Humanos')
    }
  }




      // const agregarDevolutivos = async () =>{
      //   try{
      //       setRegistro(res.data)

      //   }
      //   catch(e){
      //     toast.error('Problemas cargando')
      //     toast.error(JSON.stringify(e))
      //   }
      // }



  React.useEffect(() => {
    cargarDiasDisponibles()
  }, []);


  const [key, setKey] = useState(0);


  const enviar = async()=>{
    if(await dialog.confirm('¿Estás seguro?',{})){
      try{
        setCargando(true)
        let dias_seleccionados = []
        for(let dias of dias_disponibles[0]){
          if(!dias.deshabilitada && dias.checked) dias_seleccionados.push(dias)
        }

        let res = await RequestService.getEndPointPost('devolutivos/agregar/'+empleado.uuid_ciudadano, { dias_seleccionados : dias_seleccionados, observaciones : detalles })
        if(res.data){
          console.log(dias_seleccionados)
          toast.success('Los devolutivos fueron notificados al departamento de Recursos Humanos')        
          handleClose()
        }
        setCargando(false)
      }
      catch(e){
        setCargando(false)
        toast.error('Problemas al intentar cargar los días')
      }
    }
  }



const [detalles, setDetalles] = useState<string>('');


  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Devolutivos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(empleado)
          }
          {
            // JSON.stringify(dias_disponibles)
          }
          <h5>Empleado</h5>

          <div className="row">
            <div className="col-sm-4">
              <small>Nombre(s)</small>
              <h6>{empleado.nombres}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Paterno</small>
              <h6>{empleado.paterno}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Materno</small>
              <h6>{empleado.materno}</h6>
            </div>
          </div>


                <hr className="border border-primary border-3 opacity-75 mb-4"/>


            <Tabs
              activeKey={key}
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              {
                dias_disponibles.map( (mes:any,index:number) =>

                      <Tab eventKey={index} title={'Quincena Actual '} key={ Math.random().toString(36).substr(2, 9) }>
                          {
                            mes.map((dia:any, ind:number)=>
                                  <div key={ Math.random().toString(36).substr(2, 9) }>
                                      <Form.Check type="checkbox" id={`default-${ind}`} onChange={(e:any)=>{ let diasDisp = [...dias_disponibles]; diasDisp[index][ind].checked = !diasDisp[index][ind].checked; setDiasDisponibles(diasDisp) }} checked={dia.checked} disabled={dia.deshabilitada} label={`${moment(dia.fecha).format('dddd, DD [DE] MMMM [DE] YYYY').toUpperCase() }`} />
                                  </div>
                              )
                          }
                      </Tab>

                 )
              }


            </Tabs>


                    <div className="mb-3 mt-3">
                      <label htmlFor="razon" className="form-label">Detalles de la solicitud:</label>
                      <textarea className="form-control" name="observaciones" value={detalles} onChange={(e:any)=>{setDetalles(e.target.value.toUpperCase())}} rows={3}></textarea>
                    </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" onClick={enviar} type="button">Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalDevolutivos;