import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import moment from 'moment'

import { SERVER_ } from '../../config'

import { Form } from 'react-bootstrap';


const ModalReportesRequisiciones = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params


  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const onChange = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };


    //   if(tipo_fecha == 'fecha_creacion'){
    // w = ` where r.fecha_creacion between "${req.param('fecha_inicio')}" and "${req.param('fecha_fin')}" `
    //   }
    //   if(tipo_fecha == ''){
    //     w = ` where r.fecha_atendida between "${req.param('fecha_inicio')}" and "${req.param('fecha_fin')}" `
    //   }
    //   if(tipo_fecha == ''){
    //     w = ` where r.fecha_paso_atencion between "${req.param('fecha_inicio')}" and "${req.param('fecha_fin')}" `
    //   }

      const [ tipo_fecha, setTipoFecha ] = useState<string>('fecha_creacion')

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Reporte de Requisiciones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(null)
          }

          <h6 className="mb-3">Selecciona un rango de fechas para el reporte:</h6>

          <DatePicker selected={startDate} onChange={onChange} startDate={startDate} endDate={endDate} selectsRange inline />

          <br/>

          <Form.Check
            type={'radio'}
            name="tipo"
            id={`default-1`}
            checked={tipo_fecha==='fecha_creacion'}
            onChange={(e:any)=>{setTipoFecha('fecha_creacion')}}
            label={`Reporte según la Fecha de Creación`}
          />
          <Form.Check
            type={'radio'}
            name="tipo"
            id={`default-2`}
            checked={tipo_fecha==='fecha_limite'}
            onChange={(e:any)=>{setTipoFecha('fecha_limite')}}
            label={`Reporte según la Fecha Límite`}
          />
          <Form.Check
            type={'radio'}
            name="tipo"
            id={`default-3`}
            checked={tipo_fecha==='fecha_atendida'}
            onChange={(e:any)=>{setTipoFecha('fecha_atendida')}}
            label={`Reporte según la Fecha de Atención`}
          />
          <Form.Check
            type={'radio'}
            name="tipo"
            id={`default-4`}
            checked={tipo_fecha==='fecha_paso_atencion'}
            onChange={(e:any)=>{setTipoFecha('fecha_paso_atencion')}}
            label={`Reporte según la Fecha que pasó Atención`}
          />

          <br/>


        <Button variant="primary" onClick={(e:any)=>{window.open(`${SERVER_}excel/download/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}/${tipo_fecha}`)}}>Descargar Reporte</Button>


        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalReportesRequisiciones;