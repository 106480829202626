import React, { useState } from 'react';

import { SERVER_ } from '../config'

import { rol } from '../services/rol'

const FichaEmpleado = ( params : any ) => {
	
	const { empleado } = params

	return (
		<React.Fragment>

		  <div className="d-flex align-items-center">
		  	<span className={`${empleado.rol}`} style={{ width: '10px', height: '70px', marginRight: 20 }}></span>
		    <img className="mr-3" src={`${SERVER_}perfil/foto/${empleado.uuid}`} alt="" width="70"  />
		    <div className="lh-100" style={{paddingLeft:20}}>
		      <h5 className="mb-0 nm lh-100">{empleado.nombres} <small>{empleado.paterno} {empleado.materno} </small></h5>
		      <p className="nm">{rol(empleado.rol)}</p>
		      <small className="nm"><i className="fa fa-phone"/> {empleado.celular}</small>
		    </div>
		  </div>

		</React.Fragment>
	)

}


export default FichaEmpleado;
