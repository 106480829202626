import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import { Button, Modal } from 'react-bootstrap'


import FullCalendar from '@fullcalendar/react'
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import dayGridPlugin from '@fullcalendar/daygrid'

// import ModalDetalleEvento from './ModalDetalleEvento'
import ModalDetalleCitaServicio from './ModalDetalleCitaServicio'




const ModalCalendarioPreviewCliente = ( params : any ) => {

  // const user = AuthService.getCurrentUser()

  const { show, handleClose, calendario } = params

 const [bookings, setBookings] = useState<any>([ ])





  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Calendario de Visitas Sugerido</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }

          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin ]}
            initialView="dayGridMonth"
            events={calendario}
          />



        </Modal.Body> 
      </Modal>





    </>
  );
}


export default ModalCalendarioPreviewCliente;