import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Form, Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import Select from 'react-select'

import Dialog from '../../services/Dialogs'

import moment from 'moment'


import { diferenciaHoras, obtenerDiaSemana } from '../../services/funcionesPublicas'


const ModalEditarVisita = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, tarea } = params



  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          indicaciones : yup.string().required(mensajes_validacion_generico),
          fecha_programada : yup.string().required(mensajes_validacion_generico),
          idboutique : yup.string().required(mensajes_validacion_generico).nullable(),
          idresponsable : yup.string(), //.required(mensajes_validacion_generico),
          idtecnico : yup.string().required(mensajes_validacion_generico),
          idtipo_orden : yup.string().required(mensajes_validacion_generico),

  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro de la información?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.update( `calendario/editar_actividad/${tarea.uuid_servicio}` , { ...registro, fecha_programada : moment(registro.fecha_programada).format('YYYY-MM-DD') })

          if(res_){
            toast.warning('La actividad fue actualizada correctamente')
            setTimeout(()=>{
              // handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }


  const [ boutiques, setBoutiques ] = useState<any>([])




  React.useEffect(() => {
    cargarLista_responsables()
    cargarLista_tecnicos()
    cargarTipo_orden_trabajo()
    cargarBoutiques()
  }, []);




  const [ lista_responsables, setLista_responsables ] = useState<any>({
    mantenimiento : [],
    conservacion : [],
    limpieza : [],
  })
  const cargarLista_responsables = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`zonas/lista_responsables`)
      setLista_responsables(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }



  const [ lista_quien_atiende, setLista_tecnicos ] = useState<any>({
      tecnicos : [],
      conservacion : [],
      limpieza : [],
  })
  const cargarLista_tecnicos = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`zonas/lista_tecnicos`)
      setLista_tecnicos(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }




  const [ tipo_orden_trabajo, setTipo_orden_trabajo ] = useState<any>([])
  const cargarTipo_orden_trabajo = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/tipo_orden_trabajo`)
      setTipo_orden_trabajo(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }



  const [ opciones_seleccionadas, setOpcionesSeleccionadas ] = useState<any>({})
  const [ opcionesTodas, setTodasOpciones ] = useState<any[]>([]);

  const cargarBoutiques = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`todas_las_boutiques`)
      setTodasOpciones(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar las opciones')
    }
  }



  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Editar Tarea</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        {
          // tarea.uuid
        }


          {
            // JSON.stringify(tarea.idresponsable.uuid)
          }


          <Formik
            validationSchema={schema}
            onSubmit={(values,e)=>{enviarFormulario(values, e)}}
            initialValues={{

                  indicaciones : tarea.indicaciones,
                  idboutique : tarea.idboutique.uuid,
                  idresponsable : tarea.idresponsable ? tarea.idresponsable.uuid : '',
                  idtecnico : tarea.idtecnico.uuid,
                  idtipo_orden : tarea.idtipo_orden.id+'',
                  fecha_programada : new Date(moment(tarea.fecha_programada).toString()),

            }}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form id='my-form' noValidate onSubmit={handleSubmit}>


          <div className="row">
            <div className="col-sm-8">
              <small>Boutique</small>
              <h5>{tarea.idboutique.nombre_boutique}</h5>
              <p><small>{tarea.idboutique.direccion}</small></p>
            </div>
            <div className="col-sm-4">
              <small>Folio</small>
              <h5>{tarea.folio}</h5>
            </div>
            <div className="col-sm-6">

            </div>
          </div>




              {
                // JSON.stringify(values)
              }

{
                    // <Form.Group
                    //   controlId="ticket_type_id"
                    //   className="mb-3"
                    // >

                    //   <Form.Label>Selecciona una Boutique</Form.Label>
                    //         <Select
                    //           onChange={(opt:any,e:any)=>{ setOpcionesSeleccionadas(opt); setFieldValue('idboutique',opt.value) }}
                    //           isClearable={false}
                    //           name="colors"
                    //           defaultValue={opciones_seleccionadas||'Seleccionar'}
                    //           options={opcionesTodas}
                    //           className={` ${ (errors.idboutique?'is-invalid':'') }`}
                    //           styles={{ control: styles => ({ ...styles, borderColor: errors.idboutique ? 'red' : '#ced4da' }) }}
                    //           classNamePrefix="select"
                    //         />
                    //   <Form.Control.Feedback type="invalid">{<>{errors.idboutique}</>}</Form.Control.Feedback>
                    // </Form.Group>

}



                        {
                          true && (

                              <>



                  <Form.Group className="mb-3" controlId="campo1">
                    <Form.Label>Indicaciones para la Actividad</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      required
                      name="indicaciones"
                      value={values.indicaciones}
                      onBlur={(e)=>{ setFieldValue('indicaciones', e.target.value.toUpperCase() ) }}
                      onChange={(e)=>{ setFieldValue('indicaciones', e.target.value ) }}
                      style={{textTransform:'uppercase'}}
                      isInvalid={!!errors.indicaciones}
                    />
                    <Form.Control.Feedback type="invalid"> {<>{errors.indicaciones}</>} </Form.Control.Feedback>
                  </Form.Group>


                  <div className="row">
                    <div className="">
                        <div className="mb-3">
                          <Form.Label>Fecha</Form.Label>
                          <DatePicker selected={values.fecha_programada} className="form-control"  dateFormat="yyyy-MM-dd"  onChange={(date) => setFieldValue('fecha_programada', date ) } />
                        </div>
                    </div>
                  </div>




                         <Form.Group className="mb-3" controlId="idtipo_orden">
                          <Form.Label>Tipo de Orden de Trabajo</Form.Label>
                          <Form.Select required name="idtipo_orden" value={values.idtipo_orden} onChange={(e)=>{ setFieldValue('idtipo_orden', e.target.value ); setFieldValue('idtecnico',""); setFieldValue('idresponsable',"") }} isInvalid={!!errors.idtipo_orden}>
                            <option disabled>SELECCIONA UN TIPO DE ORDEN</option>
                              {
                                tipo_orden_trabajo.map((tipo_orden:any,index_tipo_orden:number)=>
                                    <React.Fragment key={index_tipo_orden}>
                                      {
                                        [10,11,1,8,9,4].indexOf(tipo_orden.id) === -1 && (
                                            <option value={tipo_orden.id}>{tipo_orden.tipo_orden} </option>  
                                          )
                                        
                                      }
                                    </React.Fragment>
                                  )
                              }
                          </Form.Select>
                          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid"> {<>{errors.idtipo_orden}</>} </Form.Control.Feedback>
                        </Form.Group>

                        {
                          // values.idpresupuesto
                        }

                        <h5>Responsables de la Actividad</h5>

                        {

                          ["ROLE_RESPONSABLE_OPERACION","ROLE_ADMIN"].indexOf(user.rol) > -1 && (
                              <React.Fragment>

                                 <Form.Group className="mb-3" controlId="idresponsable">
                                  <Form.Label>Selecciona un Responsable</Form.Label>
                                  <Form.Select required name="idresponsable" value={values.idresponsable} onChange={(e)=>{ setFieldValue('idresponsable', e.target.value ) }} isInvalid={!!errors.idresponsable}>
                                    <option value="">SELECCIONA UN RESPONSABLE</option>



                                    {
                                      ["1","2","3","8","99"].indexOf(values.idtipo_orden) > -1 && (
                                          <>
                                              {
                                                lista_responsables.mantenimiento.map((responsable:any)=>
                                                    <option key={ Math.random().toString(36).substr(2, 9) } value={responsable.uuid}>{responsable.nombres} {responsable.paterno} {responsable.materno} </option>
                                                  )
                                              }
                                          </>
                                        )
                                    }


                                    {
                                      ["7"].indexOf(values.idtipo_orden) > -1 && (
                                          <>
                                            {
                                              lista_responsables.limpieza.map((tecnico:any)=>
                                                  <option key={ Math.random().toString(36).substr(2, 9) } value={tecnico.uuid}>{tecnico.nombres} {tecnico.paterno} {tecnico.materno} </option>
                                                )
                                            }
                                          </>
                                        )
                                    }


                                    {
                                      ["4","5","6"].indexOf(values.idtipo_orden) > -1 && (
                                          <>
                                              {
                                                lista_responsables.conservacion.map((responsable:any)=>
                                                    <option key={ Math.random().toString(36).substr(2, 9) } value={responsable.uuid}>{responsable.nombres} {responsable.paterno} {responsable.materno} </option>
                                                  )
                                              }
                                          </>
                                        )
                                    }

                                  </Form.Select>
                                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                  <Form.Control.Feedback type="invalid"> {<>{errors.idresponsable}</>} </Form.Control.Feedback>
                                </Form.Group>


                              </React.Fragment> 
                            )

                        }

                            {
                              // JSON.stringify(values.idtipo_orden)
                            }

                         <Form.Group className="mb-3" controlId="idtecnico">
                          <Form.Label>Selecciona un Técnico</Form.Label>
                          <Form.Select required name="idtecnico" value={values.idtecnico} onChange={(e)=>{ setFieldValue('idtecnico', e.target.value ) }} isInvalid={!!errors.idtecnico}>
                            <option value="">SELECCIONA UN TÉCNICO</option>

                            {
                              ["1","2","3","8","99"].indexOf(values.idtipo_orden) > -1 && (
                                  <>
                                    {
                                      lista_quien_atiende.tecnicos.map((tecnico:any)=>
                                          <option key={ Math.random().toString(36).substr(2, 9) } value={tecnico.uuid}>{tecnico.nombres} {tecnico.paterno} {tecnico.materno} </option>
                                        )
                                    }
                                  </>
                                )
                            }

                            {
                              ["7"].indexOf(values.idtipo_orden) > -1 && (
                                  <>
                                    {
                                      lista_quien_atiende.limpieza.map((tecnico:any)=>
                                          <option key={ Math.random().toString(36).substr(2, 9) } value={tecnico.uuid}>{tecnico.nombres} {tecnico.paterno} {tecnico.materno} </option>
                                        )
                                    }
                                  </>
                                )
                            }

                            {
                              ["4","5","6"].indexOf(values.idtipo_orden) > -1 && (
                                  <>
                                    {
                                      lista_quien_atiende.conservacion.map((tecnico:any)=>
                                          <option key={ Math.random().toString(36).substr(2, 9) } value={tecnico.uuid}>{tecnico.nombres} {tecnico.paterno} {tecnico.materno} </option>
                                        )
                                    }

                                    {
                                      lista_quien_atiende.tecnicos.map((tecnico:any)=>
                                          <option key={ Math.random().toString(36).substr(2, 9) } value={tecnico.uuid}>{tecnico.nombres} {tecnico.paterno} {tecnico.materno} </option>
                                        )
                                    }
                                  </>
                                )
                            }



                          </Form.Select>
                          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid"> {<>{errors.idtecnico}</>} </Form.Control.Feedback>
                        </Form.Group>





                            {
                              // JSON.stringify(values.idtecnico)
                            }

                              </>

                            )
                        }






              </Form>
            )}
          </Formik>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalEditarVisita;