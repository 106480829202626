import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal } from 'react-bootstrap';
import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import { toast } from 'react-toastify'

import Dialog from '../../services/Dialogs'





const mensajes_validacion_generico = 'Este campo es obligatorio'

const ModalCambiarUsername = ( params : any ) => {

  const { show, handleClose, usuario } = params

  const dialog = new Dialog();


const schema = yup.object().shape({

  username: yup.string().required(mensajes_validacion_generico).matches(/^[a-z0-9_.]+$/,'El Usuario solo puede tener letras y números, puntos y guiones bajos. No se aceptan espacios ni caracteres especiales'),

});


const enviarFormulario = async (nuevo_usuario:any,event:any,) =>{
  try{
      await dialog.open({
        accept: 'Actualizar Nombre de Usuario',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        // setSeEnvio(true)
      // console.log('lo que se envia es:', nuevo_usuario)
        let res_nuevo_usuario = await RequestService.update('recursos_humanos/usuarios/cambiar_username/'+usuario.uuid, nuevo_usuario)

        if(res_nuevo_usuario){
          toast.success('Tu registro se actualizó correctamente, ahora puede acceder a la plataforma Gesti+ CRM')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Error al crear usuario en el servidor')
        }  
        
      }
    }
    catch(e:any){
      toast.error(e.response.data)
    }
     
}



const [ validado_por_admin, setValidadoAdmin ] = useState<boolean>(false)

  const solicitarPrivilegioAdministrador = async (e:any) =>{
    try{

        dialog.open({
          accept: 'Si',
          cancel: 'No',
          dialogClass: 'custom',
          message: 'Estás seguro?',
          target: e.target,
          template: `<p>Necesitas autorización para realizar este movimiento</p>
          <label>Username<input type="text" name="usuario" value=""></label>
          <label>Password<input type="password" autocomplete="off" autoComplete="off" name="passwi" value=""></label>`
        })
        let respuestas : any = await dialog.waitForUser()
        // console.log(respuestas)
        if(respuestas && respuestas.usuario !=='' && respuestas.passwi !==''){
          let res = await RequestService.getEndPointPost('verificar/administrador', respuestas )
          setValidadoAdmin(true)
        }
        else toast.error('Debes escribir una contraseña y un usuario')
    }
    catch(e:any){
      toast.error(e.response.data)
    }

  }



  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Cambiar Nombre de Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(usuario)
          }

          <dl>
            <dt>Nombre</dt>
            <dd>{usuario.nombres+` `+usuario.paterno+` `+usuario.materno}</dd>
          </dl>

<div className="alert alert-warning" role="alert">
  <i className="fa fa-warning" /> ESPERA !
  <p>Cambiar el Nombre de Usuario puede acarrear problemas, insistimos no cambiar el nombre de usuario si no existe problemas.</p>
</div>

    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

      username:  usuario.username,

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>
        {
          // JSON.stringify(values)
        }

            <Form.Group   controlId="username">
              <Form.Label>Nombre de Usuario</Form.Label>
              <Form.Control
                type="text"
                required
                name="username"
                value={values.username}
                onChange={(e)=>{ setFieldValue('username', e.target.value ) }}
                isInvalid={!!errors.username}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


        </Form>
      )}
    </Formik>



        </Modal.Body>
        <Modal.Footer>


        {
          !validado_por_admin && (
            <>
              <Button variant="success" onClick={solicitarPrivilegioAdministrador} type="button">PRIVILEGIO DE ADMINISTRADOR <i className="fa fa-key"/></Button>

            </>
          )
        }

        {
          validado_por_admin && (
            <>
            
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>

            </>
          )
        }

        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalCambiarUsername;