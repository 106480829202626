import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import { Table, Form } from 'react-bootstrap'

import moment from 'moment'
import 'moment/locale/es'

import { calcularDiasPasados, obtenerDiaSemana } from '../../services/funcionesPublicas'

import ModalPreviewCalendarioAdmin from './ModalPreviewCalendarioAdmin'

const CalendarioPreview = () =>{

      const [ config, setRegistro ] = useState<any>(null)
      const cargar = async () =>{
        try{
            let res = await RequestService.getEndPoint(`calendario/preview_administracion`)
            setRegistro(res.data)

        }
        catch(e){
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
        }
      }

      React.useEffect(() => {
        cargar()
      }, []);


      const [ ver_dias_libres, setDiasLibres ] = useState<boolean>(false)



  const [ show_ModalPreviewCalendarioAdmin, setModalPreviewCalendarioAdmin ] = useState<boolean>(false)
  const handleShow_ModalPreviewCalendarioAdmin = () => {
    setModalPreviewCalendarioAdmin(true)
  }
  const handleClose_ModalPreviewCalendarioAdmin = () => {
    setModalPreviewCalendarioAdmin(false)
  }

const ordenarPorFechaDescendente = (arreglo:any) => {
    return arreglo.sort(function(a:any, b:any) {
        const fechaA : any = new Date(a.fecha_programada);
        const fechaB : any = new Date(b.fecha_programada);
        return fechaB - fechaA;
    });
}

      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Calendario Preview</h4>
                    <hr/>


                    {
                      config === null && (

                          <>
                            Cargando...
                          </>

                        )
                    }


                    {
                      config !== null && (

                          <>

                            <div className="row">
                              <div className="col-sm-4">
                                <small>Fecha de Inicio del Periodo</small>
                                <h5>{moment(config.fecha_inicio).format('DD [DE] MMMM [DE] YYYY').toUpperCase()}</h5>
                              </div>
                              <div className="col-sm-4">
                                <small>Fecha de Fin del Periodo</small>
                                <h5>{moment(config.fecha_fin).format('DD [DE] MMMM [DE] YYYY').toUpperCase()}</h5>
                              </div>
                              <div className="col-sm-1">
                                <small>Días del Periodo</small>
                                <h5>{calcularDiasPasados(config.fecha_inicio,config.fecha_fin)}</h5>
                              </div>
                              <div className="col-sm-1 align-items-center">
                                <button className="btn btn-link" onClick={handleShow_ModalPreviewCalendarioAdmin}><i className="fa fa-calendar-o"/></button>
                              </div>
                              <div className="col-sm-2 align-items-center">
                                {
                                  config.calendario_publicado && (<p className="nm">Publicado </p>)
                                }
                                {
                                  !config.calendario_publicado && (<p className="nm">Sin Aplicar</p>)
                                }
                                {
                                  config.preview_boutique && (<p className="nm">Vista de Boutique </p>)
                                }
                                {
                                  // !config.preview_boutique && (<p className="nm">preview_boutique </p>)
                                }
                              </div>
                            </div>


{
        // <div className="row">

        //     <div>
        //       <Form.Check inline name="group1" type="checkbox" label="DÍAS LIBRES" checked={ver_dias_libres} onChange={(e)=>{ setDiasLibres(e.target.checked);}} id={`opciones_1`} />
        //     </div>

        // </div>

}

        {
          // JSON.stringify(config)
        }



                            {
                              config.formateado.length > 0 && (

                                <React.Fragment>
{
  // JSON.stringify(ordenarPorFechaDescendente(config.formateado))
}

                                    <small>Visitas Propuestas para el Período</small>


                                    <table className="table table-sm"  >
                                      <thead>
                                        <tr>


                                              {
                                                ( !ver_dias_libres ) && (
                                                    <>
                                                      <th scope="col">TIPO</th>
                                                      <th scope="col">Técnico</th>
                                                      <th scope="col">Boutique</th>
                                                      <th scope="col">Fecha</th>
                                                    </>
                                                  )
                                              }


                                        </tr>
                                      </thead>
                                      <tbody>{
                                              ordenarPorFechaDescendente(config.formateado).map((fecha:any,index_fecha:number)=>

                                                  <React.Fragment key={index_fecha}>

                                                    {
                                                      (fecha.folio !== 'DIA_LIBRE' && !ver_dias_libres) && (

                                                    <tr >
                                                      <td className={`v tipo_orden_${fecha.tipo_orden}`} style={{width:250}}>
                                                        <p className="nm"><b>{fecha.tipo}</b></p>
                                                      </td>
                                                      <td className="v"  >
                                                        <p className="nm"><small>{fecha.idtecnico.nombres} {fecha.idtecnico.paterno} {fecha.idtecnico.materno}</small></p>
                                                      </td> 
                                                      <td className="v" style={{width:350}}>
                                                        <p className="nm">{fecha.idboutique.nombre_boutique}</p>
                                                      </td>
                                                      {

                                                      // <td className="v" style={{width:180}}>
                                                      //   <p className="nm"><small>{fecha.idboutique.idsubarea.subarea}</small></p>
                                                      // </td>
                                                      }
                                                      <td className="v" style={{width:200}}>
                                                        <p className="nm"><b><small>{moment(fecha.fecha_programada).format('DD [DE] MMMM [DE] YYYY').toUpperCase()}</small></b></p>
                                                      </td> 
                                                    </tr>

                                                        )
                                                    }


                                                  </React.Fragment>

                                                )

                                            }
                                      </tbody>
                                    </table>


                                    {
                                      config.visitas.length > 0 && (

                                        <React.Fragment>
                                          
                                            <h5>Visitas Calendarizadas</h5>


                                            <table className="table table-sm" style={{fontSize:'12px'}}>
                                              <thead>
                                                <tr>


                                                      {
                                                        ( !ver_dias_libres ) && (
                                                            <>
                                                              <th scope="col">TIPO</th>
                                                              <th scope="col">Técnico</th>
                                                              <th scope="col">Responsable</th>
                                                              <th scope="col">Boutique</th>
                                                              <th scope="col">Fecha</th>
                                                            </>
                                                          )
                                                      }


                                                </tr>
                                              </thead>
                                              <tbody>{
                                                      ordenarPorFechaDescendente(config.visitas).map((fecha:any,index_fecha:number)=>

                                                          <React.Fragment key={index_fecha}>

                                                            {
                                                              (fecha.folio !== 'DIA_LIBRE' && !ver_dias_libres) && (

                                                            <tr >
                                                              <td className={`v tipo_orden_${fecha.idtipo_orden.id}`} style={{width:250}}>
                                                                <p className="nm"><b>{fecha.idtipo_orden.tipo_orden}</b></p>
                                                              </td>
                                                              <td className="v"  >
                                                                <p className="nm"><small>{fecha.idtecnico.nombres} {fecha.idtecnico.paterno} {fecha.idtecnico.materno}</small></p>
                                                              </td>
                                                              <td className="v" style={{width:240}}>
                                                                { fecha.idresponsable !== null && (<p className="nm"><small>{fecha.idresponsable.nombres} {fecha.idresponsable.paterno} {fecha.idresponsable.materno}</small></p>)}
                                                              </td>
                                                              <td className="v" style={{width:250}}>
                                                                <p className="nm">{fecha.idboutique.nombre_boutique}</p>
                                                              </td>
                                                              {

                                                              // <td className="v" style={{width:180}}>
                                                              //   <p className="nm"><small>{fecha.idboutique.idsubarea.subarea}</small></p>
                                                              // </td>
                                                              }
                                                              <td className="v" style={{width:200}}>
                                                                <p className="nm"><b><small>{moment(fecha.fecha_programada).format('DD [DE] MMMM [DE] YYYY').toUpperCase()}</small></b></p>
                                                              </td>
                                                            </tr>

                                                                )
                                                            }


                                                          </React.Fragment>

                                                        )

                                                    }
                                              </tbody>
                                            </table>

                                        </React.Fragment>

                                        )
                                    }



                                </React.Fragment>

                                )
                            }

 







                          </>

                        )
                    }









                    {
                      // JSON.stringify(config)
                    }
                    
                </div>
              </div>

            </div>





  {
    show_ModalPreviewCalendarioAdmin && (
      <ModalPreviewCalendarioAdmin show={show_ModalPreviewCalendarioAdmin} handleClose={handleClose_ModalPreviewCalendarioAdmin} calendario={config.preview_calendario} />
      )

  }



          </>

      );
  

}

export default CalendarioPreview
