import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'


import Pagination from "../../services/Pagination";
import { Table, Form, Button } from 'react-bootstrap'


import ModalNuevoProveedor from './ModalNuevoProveedor'
import ModalEditarProveedor from './ModalEditarProveedor'
import ModalProveedorListaArticulos from './ModalProveedorListaArticulos'

const ListaProveedores = () =>{

  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let response : any = await RequestService.getEndPoint(`proveedores/busqueda?where=${text_busqueda}&skip=${skip}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(0)
    setCurrentPage(1)
  }


  const [ show_ModalNuevoProveedor, setModalNuevoProveedor ] = useState<boolean>(false)
  const handleShow_ModalNuevoProveedor = () => {
    setModalNuevoProveedor(true)
  }
  const handleClose_ModalNuevoProveedor = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalNuevoProveedor(false)
  }



  const [ proveedor_seleccionado, setProveedorSeleccionado ] = useState<any>(null)
  const [ show_ModalEditarProveedor, setModalEditarProveedor ] = useState<boolean>(false)
  const handleShow_ModalEditarProveedor = (proveedor:any) => {
    setProveedorSeleccionado(proveedor)
    setModalEditarProveedor(true)
  }
  const handleClose_ModalEditarProveedor = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalEditarProveedor(false)
  }


  const [ show_ModalProveedorListaArticulos, setModalProveedorListaArticulos ] = useState<boolean>(false)
  const handleShow_ModalProveedorListaArticulos = (proveedor:any) => {
    setProveedorSeleccionado(proveedor)
    setModalProveedorListaArticulos(true)
  }
  const handleClose_ModalProveedorListaArticulos = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalProveedorListaArticulos(false)
  }


      return (
          <>


                    <h6>Lista de Proveedores</h6>
                    

                    <div className="row">
                    
                        <div className="mb-2">
                            <label className="form-label">Búsqueda</label>
                            <div className="hstack gap-3">
                              <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                              <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                              <div className="vr"></div>
                              <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                            </div>
                        </div>
                        
                      <ul className="list-inline">
                        <li className="list-inline-item"><p onClick={handleShow_ModalNuevoProveedor} className="nm pointer">Agregar Proveedor</p></li>
                      </ul>
                        

                    </div>





                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Proveedor</th>
                                              <th scope="col">Razón Social</th>
                                              <th scope="col">Teléfono</th>
                                              <th scope="col">Dirección</th>
                                              <th scope="col">Contacto</th>

                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (cual:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                                    <td className="v text-left">
                                                        <p className="nm">{cual.proveedor}</p>    
                                                    </td>
                                                    <td className="v text-left" style={{"width":"300px"}}>
                                                        <p className="nm">{cual.razon_social}</p>    
                                                    </td>
                                                    <td className="v text-left" style={{"width":"150px"}}>
                                                        <p className="nm">{cual.telefono}</p>    
                                                    </td>
                                                    <td className="v text-left" style={{"width":"400px"}}>
                                                        <p className="nm">{cual.direccion}</p>    
                                                    </td>

                                                    <td className="v text-left" style={{"width":"300px"}}>
                                                        <p className="nm"><small><b>{cual.nombre_contacto}</b></small></p>
                                                        <small>{cual.telefono_contacto}</small>
                                                    </td>


                                                    <td className="v text-left" style={{"width":"30px"}}>
                                                        <Button onClick={(e:any)=>{handleShow_ModalEditarProveedor(cual)}} size="sm" variant="warning"><i className="fa fa-edit"/></Button>
                                                    </td>

                                                    <td className="v text-left" style={{"width":"30px"}}>
                                                        <Button size="sm" onClick={(e:any)=>{handleShow_ModalProveedorListaArticulos(cual)}} variant="primary"><i className="fa fa-list"/></Button>
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}






  {
    show_ModalNuevoProveedor && (
      <ModalNuevoProveedor show={show_ModalNuevoProveedor} handleClose={handleClose_ModalNuevoProveedor}  />
      )

  }




  {
    show_ModalEditarProveedor && (
      <ModalEditarProveedor show={show_ModalEditarProveedor} handleClose={handleClose_ModalEditarProveedor} proveedor={proveedor_seleccionado}  />
      )

  }



  {
    show_ModalProveedorListaArticulos && (
      <ModalProveedorListaArticulos show={show_ModalProveedorListaArticulos} handleClose={handleClose_ModalProveedorListaArticulos} proveedor={proveedor_seleccionado}  />
      )

  }



          </>

      );
  

}

export default ListaProveedores
