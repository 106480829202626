import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'


import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'


import ModalNuevaSubzona from './ModalNuevaSubzona'

import ModalModificarSubzona from './ModalModificarSubzona'


const Subzonas = () => {


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 50
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`subareas_trabajo/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e:any){

      if (e.response?.status) {
        switch (e.response.status) {
          case 404:
            toast.error("Debes escribir algo")
            break;
          default:
            toast.error(e.response?.data)
        }
      }
      else{
        toast.error("Problemas con la busqueda")
      }
      setCargando(false)

    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true])


  const todos = () =>{
    setEstatus([true])
  }
  const ninguno = () =>{
    setEstatus([false])
  }


      React.useEffect(() => {
        busqueda()
      }, []);




  const [ show_ModalNuevaSubzona, setModalNuevaSubzona ] = useState<boolean>(false)
  const handleShow_ModalNuevaSubzona = () => {
    setModalNuevaSubzona(true)
  }
  const handleClose_ModalNuevaSubzona = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalNuevaSubzona(false)
  }





  const [ show_ModalModificarSubzona, setModalModificarSubzona ] = useState<boolean>(false)
  const [ subzona_seleccionada, setSubzonaSeleccionada ] = useState<any>(null)
  const handleShow_ModalModificarSubzona = (subzona:any) => {
    setSubzonaSeleccionada(subzona)
    setModalModificarSubzona(true)
  }
  const handleClose_ModalModificarSubzona = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalModificarSubzona(false)
  }


      return (

          <>

                <h6>Rutas</h6>

                <ul className="list-inline">
                  <li onClick={handleShow_ModalNuevaSubzona} className="list-inline-item pointer"><p className="nm">Agregar Ruta</p></li>
                </ul>
           

                  <div className="row">
                      <div className="mb-2">
                          <label className="form-label">Búsqueda</label>
                          <div className="hstack gap-3">
                            <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                            <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                            <div className="vr"></div>
                            <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                          </div>
                      </div>
                  </div>




                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Ruta</th>
                                              <th scope="col">Zona</th>
                                              <th scope="col">R Mantenimiento</th>
                                              <th scope="col">R Conservación</th>
                                              <th scope="col">R Limpieza</th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (cual:any,indice:number) =>
                                                  <tr key={ indice } style={{fontSize:12}}>
                                                    <td className="v text-left"  >
                                                        <p className="nm"><small><b>{cual.subarea}</b></small></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                        <small>{cual.area}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                        {cual.idresponsable_mantenimiento && (<small>{cual.idresponsable_mantenimiento.nombres}</small>)}
                                                    </td>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                        {cual.idresponsable_conservacion && (<small>{cual.idresponsable_conservacion.nombres}</small>)}
                                                    </td>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                        {cual.idresponsable_limpieza && (<small>{cual.idresponsable_limpieza.nombres}</small>)}
                                                    </td>
                                                    <td className="v text-left" style={{"width":"40px"}}>
                                                        <button onClick={(e:any)=>{handleShow_ModalModificarSubzona(cual)}} className="btn btn-sm btn-warning"><i className="fa fa-edit"/></button>
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}













    {
      show_ModalNuevaSubzona && (
        <ModalNuevaSubzona show={show_ModalNuevaSubzona} handleClose={handleClose_ModalNuevaSubzona}  />
        )

    }




  {
    show_ModalModificarSubzona && (
      <ModalModificarSubzona show={show_ModalModificarSubzona} handleClose={handleClose_ModalModificarSubzona} subarea={subzona_seleccionada} />
      )

  }






          </>

      );
  

}

export default Subzonas
