import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import moment from 'moment'

import { diferenciaHoras, obtenerDiaSemana } from '../../services/funcionesPublicas'



const ModalDetalleEvento = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, evento } = params

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Detalles del Evento</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        {
          !evento.extendedProps.metadata.dia_libre && (
              <>




          <div className="row">
            <div className="col-sm-6">
              <small>Folio</small>
              <h5>{evento.extendedProps.metadata.folio}</h5>
              {
                // JSON.stringify(tarea.idpresupuesto)
              }
            </div>
            <div className="col-sm-6">
              <small>Fecha</small>
              <h5 className="nm"><small>{obtenerDiaSemana(evento.extendedProps.metadata.fecha_programada).toUpperCase()}</small> - {moment(evento.extendedProps.metadata.fecha_programada).format('YYYY-MM-DD')}</h5>
              <small></small>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <small>Boutique</small>
              <h5>{evento.extendedProps.metadata.idboutique.nombre_boutique}</h5>
              <p>{evento.extendedProps.metadata.idboutique.direccion}</p>
            </div>
          </div>

          <div className="row mb-2">
            <div className={`col-sm-6 estatus_atencion_${evento.extendedProps.metadata.idestatus_atencion.id}`}>
              <small>Estatus</small>
              <h5>{evento.extendedProps.metadata.idestatus_atencion.estatus}</h5>
            </div>
            <div className={`col-sm-6 tipo_orden_${evento.extendedProps.metadata.idtipo_orden.id}`}>
              <small>Tipo de Servicio</small>
              <h5>{evento.extendedProps.metadata.idtipo_orden.tipo_orden}</h5>
            </div>
          </div>


              <small>Atención</small>
              <h5>{evento.extendedProps.metadata.idtecnico.nombres} {evento.extendedProps.metadata.idtecnico.paterno} {evento.extendedProps.metadata.idtecnico.materno}</h5>



              <small>Indicaciones para la Actividad</small>
              <h5>{evento.extendedProps.metadata.indicaciones}</h5>








              </>
            )
        }

          {
            // JSON.stringify(evento.extendedProps.metadata)
          }
        </Modal.Body> 
      </Modal>
    </>
  );
}


export default ModalDetalleEvento;