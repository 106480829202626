import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import Pagination from "../../services/Pagination";
import { Table, Form, Button } from 'react-bootstrap'


import ModalEditarArticulo from './ModalEditarArticulo'


const ProveedoresActiculos = () =>{


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( true )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let response : any = await RequestService.getEndPoint(`articulos/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(0)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true,true])


  const todos = () =>{
    setEstatus([true,true])
  }
  const ninguno = () =>{
    setEstatus([false,false])
  }



  const [ articulo_seleccionado, setArticuloSeleccionado ] = useState<any>({})
  const [ show_ModalEditarArticulo, setModalEditarArticulo ] = useState<boolean>(false)
  const handleShow_ModalEditarArticulo = (articulo:any) => {
    setArticuloSeleccionado(articulo)
    setModalEditarArticulo(true)
  }
  const handleClose_ModalEditarArticulo = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalEditarArticulo(false)
  }

      return (
          <>

             <h6>Lista de Artículos de los Proveedores</h6>
                    




              <div className="row">
              
                  <div className="mb-2">
                      <label className="form-label">Búsqueda</label>
                      <div className="hstack gap-3">
                        <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                        <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                        <div className="vr"></div>
                        <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                      </div>
                  </div>
                  
                  <div>
                    <Form.Check inline name="group1" type="checkbox" label="ACTIVO" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
                    <Form.Check inline name="group1" type="checkbox" label="NO ACTIVO" checked={arreglo_estatus[1]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}} id={`opciones_2`} />
                  </div>
                  
                <ul className="list-inline">
                  <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                </ul>

              </div>





                  {  (!cargando_) && (

                      <>

                           {
                             resultados.length > 0 && (

                               <>


                               <h5>Resultados</h5>


                                        <Table hover responsive="lg">
                                          <thead style={{fontSize:10}}>
                                            <tr>
                                              <th scope="col">Proveedor</th>
                                              <th scope="col">Tipo</th>
                                              <th scope="col">Unidad de Medida</th>
                                              <th scope="col">Artículo</th>
                                              <th scope="col">$ Proveedor</th>
                                              <th scope="col">$ Cliente</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (cual:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                                    <td className="v text-left" >
                                                        <p className="nm"><b>{cual.proveedor.proveedor}</b></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"160px"}}>
                                                        <p className="nm"><b>{cual.idtipo_articulo.tipo_articulo}</b></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"160px"}}>
                                                        <p className="nm"><b>{cual.idunidad_medida.unidad_medida}</b></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"260px"}}>
                                                        <p className="nm"><small><b>{cual.articulo}</b></small></p>
                                                        <small>{cual.descripcion}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"100px"}}>
                                                        <p className="nm"><b>$ {cual.precio}</b></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"100px"}}>
                                                        <p className="nm"><b>$ {cual.costo}</b></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"40px"}}>
                                                        <Button onClick={(e:any)=>{handleShow_ModalEditarArticulo(cual)}} size="sm" variant="warning"><i className="fa fa-edit"/></Button>
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>



                                  <Pagination
                                    itemsCount={allSessionsCount}
                                    itemsPerPage={sessionsPerPage}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    cambiarPaginaEvento={cargarMiReporte}
                                    alwaysShown={false}
                                  />


                                </>



                               )
                             
                           }


                      </>

                   )}

                  {  cargando_ && (

                      <>
                        <div className="loader">
                          <div className="loaderBar"></div>
                        </div>
                      </>


                   )}



  {
    show_ModalEditarArticulo && (
      <ModalEditarArticulo show={show_ModalEditarArticulo} handleClose={handleClose_ModalEditarArticulo} articulo={articulo_seleccionado} panel_proveedor={false} />
      )

  }


          </>

      );
  

}

export default ProveedoresActiculos
