import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

//Fullcalendar and Realted Plugins
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"; // needed
import listPlugin from '@fullcalendar/list'; //For List View
import esLocale from '@fullcalendar/core/locales/es';

import moment from 'moment'


import { obtenerFechasConTecnico, eliminarDuplicados } from '../../services/funcionesPublicas'


const ModalPreviewCalendarioCompleto = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params


  const [ lista_actividades_programacion, setLista_actividades_programacion ] = useState<any>({
    calendario_programacion : {
      preview_calendario : {
        areas : []
      }
    },
    calendario_programacion_conservacion : {
      preview_calendario : {
        areas : []
      }
    },
  })
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarLista_actividades_programacion = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`calendario/full_programacion`)
      setLista_actividades_programacion(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarLista_actividades_programacion()
  }, []);



 const returnArrayFechasPorArea = (arreglo_areas:any,colorin:string)=>{
  let fechas : any = []
    for(let area of arreglo_areas){
      fechas = fechas.concat(obtenerFechasConTecnico(area.subareas))
      for(let fecha of fechas){
        if(fecha.backgroundColor !== "#e6e2ad"){
          fecha.backgroundColor = colorin
          fecha.borderColor = colorin
        }
      }
    }
    return fechas
 }



  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Preview Visitas Mantenimiento y Conservación</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {
            // JSON.stringify(returnArrayFechasPorArea(lista_actividades_programacion.calendario_programacion.preview_calendario.areas,'#000000'))
          }
          {
            // JSON.stringify(eliminarDuplicados([].concat(returnArrayFechasPorArea(lista_actividades_programacion.calendario_programacion.preview_calendario.areas,'#1e90ff'),returnArrayFechasPorArea(lista_actividades_programacion.calendario_programacion_conservacion.preview_calendario.areas,'#ffa500'))).map((fecha:any)=>{ return {...fecha, date : fecha.start} }))
          }

      <FullCalendar
      locale={esLocale}
        plugins={[dayGridPlugin, interactionPlugin, listPlugin ]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,dayGridDay,listWeek'
        }}
        initialView="dayGridMonth"
        events={eliminarDuplicados([].concat(returnArrayFechasPorArea(lista_actividades_programacion.calendario_programacion.preview_calendario.areas,'#1e90ff'),returnArrayFechasPorArea(lista_actividades_programacion.calendario_programacion_conservacion.preview_calendario.areas,'#ffa500'))).map((fecha:any)=>{ return {...fecha, date : fecha.start} })}
      />





        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" type="button">Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalPreviewCalendarioCompleto;