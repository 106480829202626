import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { SERVER_ } from '../../config'


import { toast } from 'react-toastify'


import { estadoCuidadoHerramienta } from '../../services/funcionesPublicas'

const ModalHerramientaCargarFotos = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, herramienta } = params



  const [foto_a, setFotoA] = useState<any>(null);
  const [foto_b, setFotoB] = useState<any>(null);
  const [foto_c, setFotoC] = useState<any>(null);
  const [foto_d, setFotoD] = useState<any>(null);


  const [ rand, setRand ] = useState<any>(Math.random().toString(36).substr(2, 9))


  const handleFileChange = (event:any,cual_foto:string) => {
    const selectedFile : any = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFotoA(reader.result);
        handleUpload(reader.result,cual_foto)
      };
      reader.readAsDataURL(selectedFile);
    }
  };


  const handleUpload = async (foto:any,cual_foto:string) => {
    if (foto) {
      try {
        const imagenBase64 : any = foto.split(',')[1];

        await RequestService.update( `inventario/herramienta/actualizar_foto/${cual_foto}/${herramienta.uuid_herramienta}` , { foto : imagenBase64 })
        toast.success('Archivo subido con éxito');
        setRand(Math.random().toString(36).substr(2, 9))
      } catch (error) {
        console.error('Error al subir el archivo', error);
        toast.error('Error al subir el archivo');
      }
    } else {
      toast.error('Por favor, selecciona un archivo primero');
    }
  };



  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Fotos de Evidencia de la Herramienta</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <p  >
            <strong>Marca:</strong> {herramienta.marca}<br/>
            <strong>Modelo:</strong> {herramienta.modelo}<br/>
            <strong>Número de Serie:</strong> {herramienta.numero_serie}<br/>
            <strong>Estado:</strong> {estadoCuidadoHerramienta(herramienta.estado)}<br/>
            <strong>Tipo:</strong> {herramienta.tipo_herramienta}<br/>
            <strong>Almacén:</strong> {herramienta.almacen}<br/>
          </p>

{
  // JSON.stringify(herramienta)
}



    <p className="text-center">
      <small>Foto A</small>
      <input type="file" id="foto_a" accept=".jpg,.jpeg,.png" onChange={(e:any)=>{handleFileChange(e,'foto_a')}} style={{display:'none'}} />
      <label htmlFor="foto_a">
        <img src={`${SERVER_}herramienta_imagen/foto_a/${herramienta.uuid_herramienta}/${rand}`} width="50%" style={{border:'solid 1px'}} />
      </label>
    </p>


    <p className="text-center">
      <small>Foto B</small>
      <input type="file" id="foto_b" accept=".jpg,.jpeg,.png" onChange={(e:any)=>{handleFileChange(e,'foto_b')}} style={{display:'none'}} />
      <label htmlFor="foto_b">
        <img src={`${SERVER_}herramienta_imagen/foto_b/${herramienta.uuid_herramienta}/${rand}`} width="50%" style={{border:'solid 1px'}} />
      </label>
    </p>


    <p className="text-center">
      <small>Foto C</small>
      <input type="file" id="foto_c" accept=".jpg,.jpeg,.png" onChange={(e:any)=>{handleFileChange(e,'foto_c')}} style={{display:'none'}} />
      <label htmlFor="foto_c">
        <img src={`${SERVER_}herramienta_imagen/foto_c/${herramienta.uuid_herramienta}/${rand}`} width="50%" style={{border:'solid 1px'}} />
      </label>
    </p>


    <p className="text-center">
      <small>Foto D</small>
      <input type="file" id="foto_d" accept=".jpg,.jpeg,.png" onChange={(e:any)=>{handleFileChange(e,'foto_d')}} style={{display:'none'}} />
      <label htmlFor="foto_d">
        <img src={`${SERVER_}herramienta_imagen/foto_d/${herramienta.uuid_herramienta}/${rand}`} width="50%" style={{border:'solid 1px'}} />
      </label>
    </p>




        </Modal.Body>

      </Modal>
    </>
  );
}


export default ModalHerramientaCargarFotos;