import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal, Form } from 'react-bootstrap';

import { toast } from 'react-toastify'

import Dialog from '../../services/Dialogs'


import { calcularTiempoTranscurrido } from '../../services/funcionesPublicas'

const ModalNuevoReporteEquipos = ( params : any ) => {

const dialog = new Dialog();


  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params


  const [ boutique_reporte, setBoutiqueReporte ]  = useState<any>(null)

  const [ boutiques, setBoutiques ] = useState<any>([])
  const cargarBoutiques = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/boutiques`)
      setBoutiques(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarBoutiques()
  }, []);

  const [ envio_server, setSeEnvio ] = useState<boolean>(false)

  const [ reporte_supervision, setReporteSupervision ] = useState<any>({
    observaciones : "",
    equipos : []
  })
  const cargarReporteCheck = async(uuid_boutique:string)=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`reporte_visitas_supervision_equipos/${uuid_boutique}`)
      // console.log(response.data)
      let reporte : any = {
        observaciones : "",
        equipos : []
      }
      for(let equipo of response.data){

        // let reporte_para_equipo = equipo.lista_supervision.map((registro:any)=>{return { calificacion : "", descripcion : lista, observaciones : "" } })
        // reporte.push({ calificacion : "", descripcion : lista, observaciones : "" } )
        reporte.equipos.push({ seleccionado : false, equipo : equipo, lista_supervision : equipo.lista_supervision.map((registro:any)=>{return { calificacion : "", descripcion : registro, observaciones : "" } }) })

      }
      setReporteSupervision(reporte)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


const guardarReporte = async (event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `reporte_supervision_equipos/crear_reporte/${boutique_reporte}` , { reporte_supervision : reporte_supervision })

        if(res_){
          toast.success('El reporte fue guardado correctamente')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}




    


    
  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Reporte</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <Form.Group className="mb-3" controlId="idboutique">
            <Form.Label>Boutique</Form.Label>
            <Form.Select required name="idboutique" value={boutique_reporte} onChange={(e)=>{ setBoutiqueReporte(  e.target.value ); cargarReporteCheck(e.target.value) }}  >
              <option value="">SELECCIONA UNA BOUTIQUE</option>
              {
                boutiques.map( (boutique:any) =>
                  <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>
                            <option value={boutique.uuid}> {boutique.nombre_boutique}</option>
                  </React.Fragment>
                )
              }
            </Form.Select>
          </Form.Group>








          {
            boutique_reporte === '' && (
                <p className="nm"><i className="fa fa-warning"/> Debes seleccionar una Boutique para reportar</p>
              )
          }

          {
            reporte_supervision.equipos.length > 0 && (
                <React.Fragment>
                  {
                    // JSON.stringify(reporte_supervision)
                  }


          <div className="mt-2 mb-2">
            <label className="form-label">Observaciones Generales</label>
            <textarea className="form-control" rows={2} value={reporte_supervision.observaciones} onChange={(e:any)=>{ let r = {...reporte_supervision}; r.observaciones = e.target.value.toUpperCase(); setReporteSupervision(r) }}></textarea>
          </div>



                  {
                    reporte_supervision.equipos.map((equipo_critico:any,index:number)=>
                        <React.Fragment key={index}>

                          <div className="card mb-2">
                              <div className="card-header d-flex align-items-center">
                                  <input type="checkbox" className="me-3" id={`index_check_${index}`} checked={equipo_critico.seleccionado} onChange={(e:any)=>{ let a = {...reporte_supervision}; a.equipos[index].seleccionado = e.target.checked; setReporteSupervision(a) }} />
                                  <div>
                                      <label htmlFor={`index_check_${index}`}>
                                        <h5 className="card-title mb-0">{equipo_critico.equipo.marca} <small>{equipo_critico.equipo.modelo}</small></h5>
                                        <small className="card-subtitle text-muted">
                                          {
                                            equipo_critico.equipo.detalles
                                          }
                                        </small>
                                          {
                                            // JSON.stringify(equipo_critico.equipo)
                                          }
                                      </label>
                                  </div>
                              </div>

                              <div className="card-body">
                                <p className="nm">Último Mantenimiento <b>{calcularTiempoTranscurrido(equipo_critico.equipo.fecha_ultimo_mantenimiento)}</b> </p>
                                {
                                  equipo_critico.seleccionado && (
                                      <React.Fragment>
                                          {
                                            // JSON.stringify(equipo_critico.lista_supervision)
                                          }

                              {
                                equipo_critico.lista_supervision.map((registro:any,indice_registro:number)=>
                                    <React.Fragment key={indice_registro}>
                                        <div className="mt-1">
                                          <label className="form-label">{registro.descripcion} </label>

                                                <div className="form-check form-check-inline">
                                                  <input className="form-check-input" name={`inlineRadio3${index}_${indice_registro}`} type="radio" id={`inlineRadio3${index}_${indice_registro}`} checked={registro.calificacion==="BUENO"} onChange={(e:any)=>{ let r = {...reporte_supervision}; r.equipos[index].lista_supervision[indice_registro].calificacion = "BUENO"; setReporteSupervision(r) }} />
                                                  <label className="form-check-label delgadita" htmlFor={`inlineRadio3${index}_${indice_registro}`}>BUENO</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                  <input className="form-check-input" name={`inlineRadio2${index}_${indice_registro}`} type="radio" id={`inlineRadio2${index}_${indice_registro}`} checked={registro.calificacion==="REGULAR"} onChange={(e:any)=>{ let r = {...reporte_supervision}; r.equipos[index].lista_supervision[indice_registro].calificacion = "REGULAR"; setReporteSupervision(r) }} />
                                                  <label className="form-check-label delgadita" htmlFor={`inlineRadio2${index}_${indice_registro}`}>REGULAR</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                  <input className="form-check-input" name={`inlineRadio1${index}_${indice_registro}`} type="radio" id={`inlineRadio1${index}_${indice_registro}`} checked={registro.calificacion==="MALO"} onChange={(e:any)=>{ let r = {...reporte_supervision}; r.equipos[index].lista_supervision[indice_registro].calificacion = "MALO"; setReporteSupervision(r) }} />
                                                  <label className="form-check-label delgadita" htmlFor={`inlineRadio1${index}_${indice_registro}`}>MALO</label>
                                                </div>

                                                <div className="mb-3" >
                                                  <label className="form-label">Observaciones</label>
                                                  <textarea className="form-control" rows={1} value={registro.observaciones} onChange={(e:any)=>{ let r = {...reporte_supervision}; r.equipos[index].lista_supervision[indice_registro].observaciones = e.target.value.toUpperCase(); setReporteSupervision(r) }}></textarea>
                                                </div>


                                        </div>
                                    </React.Fragment>
                                  )
                              }
                              
                                      </React.Fragment>
                                    )
                                }
                              </div>
                          </div>

                        </React.Fragment>
                      )
                  }


                </React.Fragment>
              )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" onClick={guardarReporte} type="button">Crear Reporte</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}



export default ModalNuevoReporteEquipos;