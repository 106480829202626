import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { formatearNumero, condicionesPago, calcularTiempoTranscurrido, tipoActividadLabel, ordenarPorTipo, labelUnidadMedida, tipoPresupuesto } from '../../services/funcionesPublicas'

import Dialog from '../../services/Dialogs'

import moment from 'moment'

const ModalDetalleRefaccionamiento = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, presupuesto } = params

  const dialog = new Dialog()

const confirmarreporteEntradaSalida = async(e:any ) => {
    try{

        await dialog.open({
          accept: 'SI',
          cancel: 'NO',
          dialogClass: '',
          message: 'Estás seguro?',
          target: e.target,
          template: ``
        })
        let dialogo : any = await dialog.waitForUser()
        
        if(dialogo){
          let res = await RequestService.update(`presupuesto/enviar_valiacion/${presupuesto.uuid_presupuesto}`, {} )
          if(res.data){
            toast.success('Se actualizo el estatus del Presupuesto')
            handleClose()
          }
          
        }

    }
    catch(e){
      toast.error('Tuvimos problemas  ')
    }

  }
 

  React.useEffect(() => {
    sumarSubtotales()
    cargarTipo_unidad_medida()
  }, []);

 


  const [ tipo_unidad_medida, setTipo_unidad_medida ] = useState<any>([])
  const cargarTipo_unidad_medida = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/tipo_unidad_medida`)
      setTipo_unidad_medida(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  const [ suma_subtotal, setSuma ] = useState<number>(0)

  const sumarSubtotales = () =>{
    let s = 0
    // console.log(presupuesto.material_actividades)
    for(let actividad of presupuesto.material_actividades){
      s += ( actividad.costo * actividad.cantidad )
    }
    setSuma(s)

  }



  return (
    <>
      <Modal show={show} size={`${ (presupuesto.idestatus_presupuesto.id > 3 ? 'xl' : 'lg') }`} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Detalles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(presupuesto.idestatus_presupuesto.id)
          }

          {
            [1,2].indexOf(presupuesto.idestatus_presupuesto.id) > -1 && (

                <>

                    <div className="row">
                          {presupuesto.idboutique&&(
                              <React.Fragment>
                                  <div className="col-sm-8">
                                      <h5 className="mt-2">Boutique</h5>
                                      <p className="nm">{presupuesto.idboutique.nombre_boutique}</p>
                                        {
                                          presupuesto.solicitado && (<p className="nm"><i className="fa fa-star"/> Solicitado por la Boutique</p>)
                                        }
                                  </div>
                              </React.Fragment>
                            )}

                      <div className={`${(["GASTO_OPERACION","GASTOS_MOVILIZACION"].indexOf(presupuesto.tipo_presupuesto) === -1?'col-sm-4':'col')}`}>
                          <h5 className="mt-2">Folio</h5>
                          <p>{presupuesto.folio}</p>
                      </div>
                    </div>





                    <h5 className="mt-2">Descripción General</h5>
                    <p>{presupuesto.descripcion}</p>




                    {
                      presupuesto.actividades_propuestas.length > 0 && (
                          <React.Fragment>
                                    
                             <h5 className="mt-2">Materiales necesitados en el Servicio</h5>
         

                            <table className="table   mt-2" >
                              <thead>
                                <tr>
                                  <th scope="col">Descripción</th>
                                  <th scope="col">Unidad</th>
                                  <th scope="col">Cantidad</th> 
                                </tr>
                              </thead>
                              <tbody>
                              {
                                [...presupuesto.actividades_propuestas].sort(ordenarPorTipo).map((material:any,index:number)=>


                                <tr key={ Math.random().toString(36).substr(2, 9) }> 
                                  <td>
                                    <p className="nm"><small>{material.descripcion}</small></p>
                                  </td>
                                  <td className="v" style={{width:120}}>
                                    {material.idunidad_medida&&(<p className="nm"><small>{material.idunidad_medida.unidad_medida}</small></p>)}
                                  </td>
                                  <td className="v" style={{width:80}}>
                                    <p className="nm text-right">{formatearNumero(parseFloat(material.cantidad)||0)}</p>
                                  </td>
                                </tr>

                                  )
                              }


                              </tbody>
                            </table>
                            
                          </React.Fragment>
                        )
                    }





                    <div className="row">




                      <div className="col-sm-6">
                          <h6 className="mt-">Fecha de Creación</h6>
                          <p className="nm">{moment(presupuesto.fecha_creacion).format('YYYY-MM-DD hh:mm:ss')}</p>
                          <p className="nm"><small>{calcularTiempoTranscurrido(presupuesto.fecha_creacion)}</small></p>
                      </div>
                    </div>





                </>

              )
          }

          {
            [3,4,5,6,7,8,9].indexOf(presupuesto.idestatus_presupuesto.id) > -1 && (

                <>

        <div className="row">
          <div className="col-sm-4">

          <h6 className="mt-2">Folio</h6>
          <p>{presupuesto.folio}</p>

          <h6 className="mt-2">Boutique</h6>
          <p className="nm">{presupuesto.idboutique.nombre_boutique}</p>
          {
            presupuesto.solicitado && (<p className="nm"><i className="fa fa-star"/> Solicitado por la Boutique</p>)
          }

          <h6 className="mt-">Fecha de Creación</h6>
          <p className="nm">{moment(presupuesto.fecha_creacion).format('YYYY-MM-DD hh:mm:ss')}</p>
          <p className="nm"><small>{calcularTiempoTranscurrido(presupuesto.fecha_creacion)}</small></p>

          <h5 className="mt-2">Descripción General</h5>
          <p>{presupuesto.descripcion}</p>

          <h6 className="mt-2">Materiales solicitados</h6>
          <ol>
            {
              presupuesto.actividades_propuestas.map((actividad:any)=>
                  <li>
                    <p className="nm"><small>{actividad.descripcion}</small></p>
                  </li>
                )
            }
          </ol>

          {
            // JSON.stringify(presupuesto)
          }

          {
            // presupuesto.tipo_condicion_pago

          // <h6 className="mt-2">Condiciones de Pago</h6>
          // <p>{presupuesto.tipo_condicion_pago===''?'POR DEFINIR':condicionesPago(presupuesto.tipo_condicion_pago)}</p>

          // <h6 className="mt-2">Condiciones de Ejecución</h6>
          // <p>{presupuesto.condiciones_ejecucion===''?'POR DEFINIR': (presupuesto.condiciones_ejecucion)}</p>

          // <h6 className="mt-2">Vigencia de Precios</h6>
          // <p>{presupuesto.vigencia_dias_precios} Día(s)</p>
          }



          {
            // ["GASTO_OPERACION","GASTOS_MOVILIZACION"].indexOf(presupuesto.tipo_presupuesto) === -1 &&(

            //     <React.Fragment>
            //         <h6 className="mt-2">Tiempo Estimado de la Obra</h6>
            //         <p>{presupuesto.dias_finaliza_estimados} Día(s)</p>
            //     </React.Fragment>

            // )
          }


          </div>
          <div className="col-sm-8">
            {
              // JSON.stringify(presupuesto.entra_iguala)
            }

                  <h5 className="mt-2">Explosión de precios unitarios</h5>

                  <table className="table table-sm"  >
                    <thead>
                      <tr>
                        <th scope="col">Tipo</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Unidad</th>
                        <th scope="col">Cantidad</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      [...presupuesto.material_actividades].sort(ordenarPorTipo).map((material:any,index:number)=>


                      <tr>
                        <td className="v" style={{width:120}}>
                          <p className="nm">
                          {
                            // JSON.stringify(material.idunidad_medida)
                          }
                          <small><b>{tipoActividadLabel(material.tipo)}</b></small>
                          </p>
                        </td>
                        <td>
                          <p className="nm"><small>{material.descripcion}</small></p>
                        </td>
                        <td className="v" style={{width:120}}>
                          {material.idunidad_medida&&(<p className="nm"><small>{material.idunidad_medida.unidad_medida}</small></p>)}
                        </td>
                        <td className="v" style={{width:80}}>
                          <p className="nm text-right">{formatearNumero(parseFloat(material.cantidad)||0)}</p>
                        </td>
                      </tr>

                        )
                    }





                    </tbody>
                  </table>


          </div>
        </div>



          
                </>

              )
          }



        </Modal.Body>


      {
        [1].indexOf(presupuesto.idestatus_presupuesto.id) > -1 && (


        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" onClick={confirmarreporteEntradaSalida} type="button">Solicitar  </Button>
        </Modal.Footer>


          )
      }

      </Modal>
    </>
  );
}


export default ModalDetalleRefaccionamiento;