import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'
import moment from 'moment'


import { calcularTiempoTranscurrido, evaluarAtencion, evaluarAtencionGlobal } from '../../services/funcionesPublicas'


import { SERVER_ } from  '../../config';


const ModalDetalleReporte = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, reporte } = params





  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Detalle del Reporte</Modal.Title>
        </Modal.Header>
        <Modal.Body>


              <div className="row">
                <div className="col-sm-4">
                    <small>Folio</small>
                    <h5>{reporte.folio}</h5>
                </div>
                <div className="col-sm-4">
                    <small>Boutique</small>
                    <h5>{reporte.nombre_boutique}</h5>
                </div>
                <div className="col-sm-4">
                    <small>Fecha del Reporte</small>
                    <h5 className="nm">{moment(reporte.fecha_creacion).format('YYYY-MM-DD')}</h5>
                      <small>{calcularTiempoTranscurrido(reporte.fecha_creacion)}</small>
                </div>
              </div>











              {
                // reporte.finalizado && (
                //     <React.Fragment>
                //         <p className="text-center">
                //           <a href={`${SERVER_}supervision/hoja_supervision/${reporte.uuid_reporte}`} target="_blank" className="btn btn-link">Descargar Reporte de Supervisión</a>
                //         </p>         
                //     </React.Fragment>
                //   )
              }


              <small>Observaciones en general</small>
              <h6>{reporte.reporte.observaciones||'Sin observaciones'}</h6>





              {
                reporte.tipo_reporte === "MANTENIMIENTO" && (
                    <React.Fragment>


                      {
                        // JSON.stringify(reporte.reporte.equipos)
                      }
 

                        {
                          reporte.reporte.equipos.map((equipo:any)=>
                              <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>

                                {
                                  equipo.seleccionado && (

                                    <div className="card">
                                      <div className="card-header">
                                        <h6 className="nm">{equipo.equipo.marca} <small>{equipo.equipo.modelo}</small></h6>
                                        <p className="nm"><small>{equipo.equipo.comentarios}</small></p>
                                      </div>
                                      <div className="card-body">
                                        {
                                          // JSON.stringify(equipo)
                                        }
                                        {
                                          // evaluarAtencion({...equipo, lista_caminata_supervision : equipo.lista_supervision })
                                        }

                                        <dl className="row nm">  
                                            <dt className="col-sm-6"><h6>CALIFICACIÓN GENERAL</h6></dt>
                                            <dd className="col-sm-6 text-right"><p className="nm">{evaluarAtencion({...equipo, lista_caminata_supervision : equipo.lista_supervision })}</p></dd>
                                        </dl>

                                      <table className="table table-sm">
                                        <thead>
                                          <tr>
                                            <th scope="col">Descripción</th>
                                            <th scope="col">Calificación</th>
                                            <th scope="col">Comentarios</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {
                                            equipo.lista_supervision.map((registro:any)=>
                                                <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>
                                                    <tr>
                                                      <td><small>{registro.descripcion}</small></td>
                                                      <td style={{width:100}}><small>{registro.calificacion||'Sin Calificar'}</small></td>
                                                      <td style={{width:300}}><small>{registro.observaciones||''}</small></td>
                                                    </tr>
                                                </React.Fragment>
                                              )
                                          }
                                        </tbody>
                                      </table>


                                      </div>
                                    </div>

                                    )
                                }




                                {
                                  !equipo.seleccionado && (
                                        <React.Fragment>

                                          <div className="alert alert-warning" role="alert">
                                            {
                                              // JSON.stringify(equipo)
                                            }
                                            <h6 className="nm">{equipo.equipo.marca} <small>{equipo.equipo.modelo}</small></h6>
                                            <p className="nm"><small>{equipo.equipo.comentarios}</small></p>
                                            <small>Equipo no evaluado en la revisión</small>

                                          </div>

                                        </React.Fragment>
                                    )
                                }



                                    
                              </React.Fragment>
                            )
                        }




                    </React.Fragment> 
                  )
              }




              {
                reporte.tipo_reporte === "CONSERVACION" && (
                    <React.Fragment>


              <small>Resultado de la Evaluación</small>
              <h6>{evaluarAtencionGlobal(reporte.reporte)}</h6>


                <p><b><small>Reporte por Áreas</small></b></p>
              <h6 className="mb-4">{reporte.reporte.observaciones}</h6>



                        {
                          // JSON.stringify(reporte.reporte.observaciones)
                        }

                        {
                          reporte.reporte.areas.map((area:any)=>
                              <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>


                                    <div className="card">
                                      <div className="card-header">
                                        <h6 className="nm">{area.area}</h6>
                                        <p className="nm"><small>{area.observaciones}</small></p>
                                      </div>
                                      <div className="card-body">
                                        {
                                          // JSON.stringify(area)
                                        }
                                        {
                                          // evaluarAtencion(area)
                                        }

                                        <dl className="row nm">  
                                            <dt className="col-sm-6"><h6>CALIFICACIÓN GENERAL</h6></dt>
                                            <dd className="col-sm-6 text-right"><p className="nm">{evaluarAtencion(area)}</p></dd>
                                        </dl>

                                      <table className="table table-sm">
                                        <thead>
                                          <tr>
                                            <th scope="col">Descripción</th>
                                            <th scope="col">Calificación</th>
                                            <th scope="col">Comentarios</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {
                                            area.lista_caminata_supervision.map((registro:any)=>
                                                <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>
                                                    <tr>
                                                      <td><small>{registro.descripcion}</small></td>
                                                      <td style={{width:100}}><small>{registro.calificacion||'Sin Calificar'}</small></td>
                                                      <td style={{width:300}}><small>{registro.observaciones||''}</small></td>
                                                    </tr>
                                                </React.Fragment>
                                              )
                                          }
                                        </tbody>
                                      </table>


                                      </div>
                                    </div>
                              </React.Fragment>
                            )
                        }


 

                    </React.Fragment> 
                  )
              }





          {
            // JSON.stringify(reporte.reporte.areas)
          }
        </Modal.Body>

      </Modal>
    </>
  );
}


export default ModalDetalleReporte;