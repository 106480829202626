import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import Pagination from "../../services/Pagination";
import { Table, Form, Button } from 'react-bootstrap'




import { calcularTiempoTranscurrido } from '../../services/funcionesPublicas'



import ModalDetallePresupuesto from './ModalDetallePresupuesto'




import ModalRevisarPresupuesto from './ModalRevisarPresupuesto'

import { rol } from '../../services/rol'


import './colores_presupuestos.scss'

const PresupuestosBoutiques = () => {


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let response : any = await RequestService.getEndPoint(`cliente/presupuestos/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(0)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([false,false,false,true,true,true,false,false,true])


  const todos = () =>{
    setEstatus([false,false,false,true,true,true,false,false,true])
  }
  const ninguno = () =>{
    setEstatus([false,false,false,false,false,false,false,false,false])
  }

      // if(arreglo_estatus[0]) estatus_seleccionados.push(1) false
      // if(arreglo_estatus[1]) estatus_seleccionados.push(2) false
      // if(arreglo_estatus[2]) estatus_seleccionados.push(3) false
      // if(arreglo_estatus[3]) estatus_seleccionados.push(4) true
      // if(arreglo_estatus[4]) estatus_seleccionados.push(5) true
      // if(arreglo_estatus[5]) estatus_seleccionados.push(9) true

  const [ presupuesto_seleccionado, setPresupuesto ] = useState<any>({})

  const [ show_ModalDetallePresupuesto, setModalDetallePresupuesto ] = useState<boolean>(false)
  const handleShow_ModalDetallePresupuesto = (presupuesto:any) => {
    setPresupuesto(presupuesto)
    setModalDetallePresupuesto(true)
  }
  const handleClose_ModalDetallePresupuesto = () => {
    // cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalDetallePresupuesto(false)
  }


  const [ show_ModalRevisarPresupuesto, setModalRevisarPresupuesto ] = useState<boolean>(false)
  const handleShow_ModalRevisarPresupuesto = (presupuesto:any) => {
    setPresupuesto(presupuesto)
    setModalRevisarPresupuesto(true)
  }
  const handleClose_ModalRevisarPresupuesto = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalRevisarPresupuesto(false)
  }


      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Revisión de Presupuestos</h4>
                    <hr/>



                    <div className="row">
                    
                        <div className="mb-2">
                            <label className="form-label">Búsqueda</label>
                            <div className="hstack gap-3">
                              <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                              <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                              <div className="vr"></div>
                              <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                            </div>
                        </div>
                        
                        <div>

                                                    
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="APROBACIÓN CLIENTE" checked={arreglo_estatus[3]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}} id={`opciones_4`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="ACEPTADA POR CLIENTE" checked={arreglo_estatus[4]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[4] = e.target.checked;  setEstatus(ne);}} id={`opciones_5`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="INCLUIDO EN IGUALA" checked={arreglo_estatus[5]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[5] = e.target.checked;  setEstatus(ne);}} id={`opciones_6`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="CANCELADA" checked={arreglo_estatus[8]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[8] = e.target.checked;  setEstatus(ne);}} id={`opciones_9`} />


                        </div>


                        <ul className="list-inline">
                          <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                        </ul>

                    </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Folio</th>
                                              <th scope="col">Descripción</th>
                                              <th scope="col">Boutique</th>
                                              <th scope="col">Creó</th>
                                              <th scope="col">Estatus</th>
                                              <th scope="col">Creación</th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (presupuesto:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12, background : (["ROLE_BOUTIQUE"].indexOf(presupuesto.idusuario.rol) > -1 ? '#e8e8e8' : '') }} >
                                                    <td onClick={(e:any)=>{handleShow_ModalDetallePresupuesto(presupuesto)}} className={`v pointer text-left _estatus_${presupuesto.idestatus_presupuesto.id}`} style={{"width":"160px", background : presupuesto.entra_iguala ? '#f4dd7e': '' }}>
                                                        <p className="nm"><small><b>{presupuesto.folio} {presupuesto.entra_iguala&&(<i className="fa fa-asterisk"/>)}</b></small></p>
                                                            
                                                    </td>
                                                    <td className="v text-left"  >
                                                      <b>{presupuesto.descripcion}</b>
                                                    </td>
                                                    <td className="v text-left"  >
                                                      <p className="nm">{presupuesto.idboutique.nombre_boutique}</p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"200px"}} >
                                                      <p className="nm"><b>{presupuesto.idusuario.nombres}</b> <small>{presupuesto.idusuario.paterno} {presupuesto.idusuario.materno}</small></p>
                                                      {rol(presupuesto.idusuario.rol)}
                                                    </td>
                                                    <td className={`v text-center estatus_presupuesto_${presupuesto.idestatus_presupuesto.id}`}style={{"width":"220px"}} >
                                                      <p className="nm">{presupuesto.idestatus_presupuesto.estatus}</p>
                                                      {
                                                        // [5].indexOf(presupuesto.idestatus_presupuesto.id)
                                                        // JSON.stringify(presupuesto.visita)

                                                      }
                                                      {
                                                        presupuesto.visita && (
                                                            <React.Fragment>

                                                              {
                                                                [5].indexOf(presupuesto.idestatus_presupuesto.id) > -1 && [3,4,7].indexOf(presupuesto.visita.idestatus_atencion.id) > -1 && (
                                                                    <React.Fragment>
                                                                        {
                                                                          // JSON.stringify(presupuesto.idregistro_ingreso_egreso)
                                                                        }
                                                                        se espera el cobro
                                                                    </React.Fragment> 
                                                                  )
                                                              }


                                                              {
                                                                // JSON.stringify(presupuesto.visita.idestatus_atencion)
                                                              }
                                                              {' '}
                                                              {
                                                                // presupuesto.idestatus_presupuesto.id
                                                              }
                                                              {' '}
                                                              <small><b>{presupuesto.visita.idestatus_atencion.estatus}</b> { presupuesto.visita.folio }</small>
                                                              
                                                            </React.Fragment>
                                                          )
                                                      }
                                                    </td>

                                                    <td className="v text-left" >
                                                        <p className="nm"><b>{moment(presupuesto.fecha_creacion).format('YYYY-MM-DD HH:mm')}</b></p>
                                                        <p className="nm"><small>{calcularTiempoTranscurrido(presupuesto.fecha_creacion)}</small></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"50px"}}>
                                                        {
                                                          // JSON.stringify(cual.idestatus_presupuesto.id)
                                                        }
                                                        {
                                                          // <Button onClick={(e:any)=>{handleShow_ModalDetallePresupuesto(cual)}} size="sm" variant="warning"><i className="fa fa-address-book-o"/></Button>
                                                        }


                                                        {
                                                          [4].indexOf(presupuesto.idestatus_presupuesto.id) > -1 && (
                                                                <Button onClick={(e:any)=>{handleShow_ModalRevisarPresupuesto(presupuesto)}} size="sm" variant="warning"><i className="fa fa-address-book-o"/></Button>
                                                            )
                                                        }
                                                        
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}
                    
                </div>
              </div>

            </div>



  {
    show_ModalDetallePresupuesto && (
      <ModalDetallePresupuesto show={show_ModalDetallePresupuesto} handleClose={handleClose_ModalDetallePresupuesto} presupuesto={presupuesto_seleccionado} />
      )

  }




  {
    show_ModalRevisarPresupuesto && (
      <ModalRevisarPresupuesto show={show_ModalRevisarPresupuesto} handleClose={handleClose_ModalRevisarPresupuesto} presupuesto={presupuesto_seleccionado} />
      )

  }

          </>

      );
  

}


export default PresupuestosBoutiques
