import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal, Tab,Tabs } from 'react-bootstrap';

import { toast } from 'react-toastify'

import Dialog from '../../services/Dialogs'


import Pagination from "../../services/Pagination";
import { Table, Form, Dropdown } from 'react-bootstrap'


import { SERVER_ } from  '../../config';

import { FormateaNumero, estadoCuidadoHerramienta } from '../../services/funcionesPublicas'


import FichaEmpleado from '../FichaEmpleado'


import { rol } from '../../services/rol'

import '../../rol_classes.scss'

const ModalAsignarHerramientas = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params


  const [ usuario, setUsuario ] = useState<any>(null)
  const [ herramientas_seleccionadas, setHerramientas ] = useState<any>([])







  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 30
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const [ arreglo_estatus, setEstatus ] = useState<any>([true,false,false,false,false])

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`busqueda_herramientas/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

















  const [ arreglo_roles, setArregloRoles ] = useState<any>([true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true])



  const [allSessionsCountEmpleado,setCounterEmpleado] = useState(0);
  const sessionsPerPageEmpleado = 5
  const [currentPageEmpleado, setCurrentPageEmpleado] = useState(1);

  const [ paginaEmpleado, setPaginaEmpleado ] = useState(0)
  const [ text_busqueda_empleado, setTextoBusquedaEmpleado ] = useState('')




  const [ reporte, setReporteEmpleado ] = useState<any>([])

  
      const currentUser  = AuthService.getCurrentUser();

  const cargarMiReporteEmpleado = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPageEmpleado);
      let response : any = await RequestService.getEndPoint('recursos_humanos/'+arreglo_roles.join(',')+'/false/lista_personal?where='+text_busqueda_empleado+'&skip='+skip+'&limit='+sessionsPerPageEmpleado)
      setCounterEmpleado(response.data.totalCount)

      setReporteEmpleado(response.data.results)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar generar tu reporte. Contacta con los administradores del sistema')
    }
  }

  const busquedaEmpleados = async () =>{
    // setPagina(0)
    cargarMiReporteEmpleado(0)
    setCurrentPageEmpleado(0)
  }



  // const agregarHerramienta = (herramienta:any)=>{

  //   console.log(herramienta)
  //   let r = [...herramientas_seleccionadas]
  //   r.push(herramienta)
  //   setHerramientas(r)

  // }
const agregarHerramienta = (nuevaHerramienta:any) => {
  setHerramientas((herramientasPrevias:any) => {
    // Verificar si la herramienta ya existe en el arreglo
    const yaExiste = herramientasPrevias.some((herramienta:any) => herramienta.uuid_herramienta === nuevaHerramienta.uuid_herramienta);
    
    // Si ya existe, retornar el arreglo actual sin cambios
    if (yaExiste) return herramientasPrevias;

    // Si no existe, agregar la nueva herramienta y retornar el nuevo arreglo
    return [...herramientasPrevias, {...nuevaHerramienta,foto:''}];
  });
};


const eliminarHerramienta = (indice:number) => {
  setHerramientas((herramientasPrevias:any) => 
    herramientasPrevias.filter((_:any, i:number) => i !== indice)
  );
};



const dialog = new Dialog();

const [ envio_server, setSeEnvio ] = useState<boolean>(false)

const enviarFormulario = async (event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `herramientas/asignar_herramientas_empleado` , { usuario : {...usuario,foto:''}, herramientas : herramientas_seleccionadas })

        if(res_){
          toast.success('Consulta_correcta')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}




    


  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Asignación de herramientas a Empleado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <div className="row">
            <div className="col-4">
              

                <Tabs
                  defaultActiveKey="profile"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="home" title="🛠️ Herramientas">

                                    <div className="mb-2">
                                        <label className="form-label">Búsqueda herramienta</label>
                                        <div className="hstack gap-3">
                                          <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                                          <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                                        </div>
                                    </div>


                                      {  (!cargando_) && (

                                          <>

                                               {
                                                 resultados.length > 0 && (

                                                   <>


                                                   <h5>Resultados</h5>


                                                    <Table responsive="sm">
                                                      <thead>
                                                        <tr>
                                                          <th scope="col">Almacén</th>
                                                          <th scope="col">#S</th>
                                                          <th scope="col">Marca</th>
                                                          <th scope="col">Modelo</th>
                                                          <th scope="col"></th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {


                                                          resultados.map( (herramienta:any,indice_herramienta:number) =>
                                                              <tr key={ indice_herramienta } style={{fontSize:10}}>
                                                                <td className="v text-left"   >
                                                                    <p className="nm"><small> {herramienta.almacen} </small></p>
                                                                </td>
                                                                <td className="v text-left"  style={{"width":"120px"}}>
                                                                    <p className="nm"><small><b>{herramienta.numero_serie}</b></small></p>
                                                                </td>
                                                                <td className="v text-left"  style={{"width":"170px"}}>
                                                                    <p className="nm"><small><b>{herramienta.marca}</b></small></p>
                                                                </td>
                                                                <td className="v text-left"  style={{"width":"150px"}}>
                                                                    <p className="nm"><small><b>{herramienta.modelo}</b></small></p>
                                                                </td>
                                                                <td className="v text-left"  style={{"width":"40px"}}>
                                                                    <button type="button" onClick={(e:any)=>{agregarHerramienta(herramienta)}} className="btn btn-info btn-sm"><i className="fa fa-circle"/></button>
                                                                </td>
                                                              </tr>
                                                           )
                                                        }

                                                      </tbody>
                                                    </Table>


                                                      <Pagination
                                                        itemsCount={allSessionsCount}
                                                        itemsPerPage={sessionsPerPage}
                                                        currentPage={currentPage}
                                                        setCurrentPage={setCurrentPage}
                                                        cambiarPaginaEvento={cargarMiReporte}
                                                        alwaysShown={false}
                                                      />


                                                    </>



                                                   )
                                                 
                                               }


                                          </>

                                       )}

                                      {  cargando_ && (

                                          <>
                                            <div className="loader">
                                              <div className="loaderBar"></div>
                                            </div>
                                          </>


                                       )}



















                  </Tab>
                  <Tab eventKey="profile" title="👤 Empleados">


                                      <div className="">
                                        <label className="form-label">Búsqueda Empleado</label>
                                        <div className="input-group mb-3">
                                          <input type="text" className="form-control" onKeyDown={(e)=>{if(e.keyCode === 13){ busquedaEmpleados() }}} placeholder="" value={text_busqueda_empleado} onChange={event => setTextoBusquedaEmpleado(event.target.value)} />
                                          <button onClick={busquedaEmpleados} className="btn btn-default" type="button" id="button-addon2"><i className="fa fa-search" /></button>
                                        </div>
                                      </div>




                                      {  (!cargando_) && (

                                          <>



                                                  



                                                 {
                                                   reporte.length > 0 && (

                                                     <>


                                                     <h5>Usuarios del Sistema</h5>


                                                      <Table responsive="lg">
                                                        <thead>
                                                          <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col"></th>
                                                            <th scope="col"></th>
                                                            <th scope="col"></th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {
                                                            reporte.map( (usuario:any,indice_empleado:number) =>
                                                                <tr key={ indice_empleado } style={{fontSize:12}} className={ !usuario.activo ? 'table-warning' : '' }>
                                                                  <td className={ 'v text-left ' + usuario.rol } style={{"width":"8px"}}>
                                                                      
                                                                  </td>
                                                                <td className="v" style={{"width":"50px"}}>
                                                                  <img src={`${SERVER_}perfil/foto/${usuario.uuid}?k=${(Math.floor(Math.random() * 6) + 1)}`} style={{width:'100%'}}  />
                                                                </td>
                                                                  <td className="v" >
                                                                    <p className="nm"><b>{usuario.nombres} <small>{usuario.paterno} {usuario.materno}</small></b></p>
                                                                    <p className="nm">{rol(usuario.rol)} {' '} </p>
                                                                  </td> 
                                                                  <td className="v text-right" style={{"width":"40px"}}>
             
                                                                        <Button disabled={!usuario.activo} onClick={(e:any)=>{setUsuario(usuario)}} size="sm"><i className="fa fa-circle"/></Button>

                                                                  </td>
                                                                </tr>
                                                             )
                                                          }

                                                        </tbody>
                                                      </Table>


                                                      <Pagination
                                                        itemsCount={allSessionsCountEmpleado}
                                                        itemsPerPage={sessionsPerPageEmpleado}
                                                        currentPage={currentPageEmpleado}
                                                        setCurrentPage={setCurrentPageEmpleado}
                                                        cambiarPaginaEvento={cargarMiReporteEmpleado}
                                                        alwaysShown={false}
                                                      />




                                                      </>



                                                     )
                                                 }






                                          </>

                                       )}

                                      {  cargando_ && (

                                          <>
                                            <div className="loader">
                                              <div className="loaderBar"></div>
                                            </div>
                                          </>


                                       )}












                    
                  </Tab>
                </Tabs>



            </div>
            <div className="col-8">
              

              {
                !usuario && (
                    <React.Fragment>
                        <div className="alert alert-danger" role="alert">
                            <p className="nm"><i className="fa fa-warning"/> 👤 Debes seleccionar un Empleado. Para seleccionar debes ir al panel lateral en la pestaña Emeplados para buscar y seleccionar el empleado para asignar herramienta.</p>
                        </div>
                      
                    </React.Fragment>   
                  )
              }

              {
                usuario && (
                    <React.Fragment>
                        <div className="alert alert-info" role="alert">
                            {
                              // JSON.stringify({...usuario,foto:''})
                            }

                                <h5>Empleado para Asignar Herramientas</h5>

                                <FichaEmpleado empleado={usuario} />

                        </div>
                      
                    </React.Fragment>   
                  )
              }






              {
                herramientas_seleccionadas.length === 0 && (
                    <React.Fragment>
                        <div className="alert alert-danger" role="alert">
                            <p className="nm"><i className="fa fa-warning"/> 🛠️ Debes seleccionar al menos una herramienta para entregar. Para seleccionar debes ir al panel lateral en la pestaña Herramientas.</p>
                        </div>
                      
                    </React.Fragment>   
                  )
              }





              {
                herramientas_seleccionadas.length > 0 && (
                    <React.Fragment>
                        <div className="alert alert-info" role="alert">
                            {
                              // JSON.stringify(herramientas_seleccionadas)
                            }

                                <h5> Herramientas para Asignar</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col" colSpan={1}> </th>
                                              <th scope="col">Tipo</th>
                                              <th scope="col">Almacén</th>
                                              <th scope="col"># Serie</th>
                                              <th scope="col">Marca</th>
                                              <th scope="col">Modelo</th>
                                              <th scope="col">Estado</th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              herramientas_seleccionadas.map( (herramienta:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                                    <td className="v text-left"  style={{"width":"70px"}} >
                                                             {
                                                              // JSON.stringify(herramienta)
                                                             }
                                                             <img src={`${SERVER_}herramienta_imagen/foto_a/${herramienta.uuid_herramienta}/${Math.random().toString(36).substr(2, 9)}`} height="60px" />
                                                    </td>
                                                    
                                                    <td className="v text-left"   >
                                                        <p className="nm"><small> {herramienta.tipo_herramienta} </small></p>
                                                    </td>
                                                    <td className="v text-left"  style={{"width":"200px"}}>
                                                        <p className="nm"><small> {herramienta.almacen} </small></p>
                                                    </td>
                                                    <td className="v text-left"  style={{"width":"120px"}}>
                                                        <p className="nm"><small><b>{herramienta.numero_serie}</b></small></p>
                                                    </td>
                                                    <td className="v text-left"  style={{"width":"200px"}}>
                                                        <p className="nm"><small><b>{herramienta.marca}</b></small></p>
                                                    </td>
                                                    <td className="v text-left"  style={{"width":"150px"}}>
                                                        <p className="nm"><small><b>{herramienta.modelo}</b></small></p>
                                                    </td>
                                                    <td className="v text-left"  style={{"width":"100px"}}>
                                                        <p className="nm"><small><b>{estadoCuidadoHerramienta(herramienta.estado)}</b></small></p>
                                                    </td>

                                                    <td className="v text-left"  style={{"width":"40px"}}>
                                                        <button type="button" onClick={(e:any)=>{eliminarHerramienta(indice)}} className="btn btn-danger btn-sm"><i className="fa fa-trash"/></button>
                                                    </td>
                                                    
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>
                        </div>
                      
                    </React.Fragment>   
                  )
              }



            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" disabled={ !usuario || herramientas_seleccionadas.length === 0 } onClick={enviarFormulario} type="button">Crear Asignación</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalAsignarHerramientas;