import React, { useState } from 'react';

import RequestService from "../../services/request.service";


import {Button, Modal, Tab, Tabs } from 'react-bootstrap';

import { toast } from 'react-toastify'


import Dialog from '../../services/Dialogs'

import { SERVER_ } from  '../../config';

import moment from 'moment'

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';



const ModalFichaEmpleado = ( params : any ) => {


  const dialog = new Dialog();


  const [ empleado, setEmpleado ] = useState<any>({ movimientos_nomina : [], metadata : {email:'',celular:''}, usuario : { uuid : '' }, info_nomina : { nomina : { nomina : '' }, puesto : { puesto : '' }, departamento : { departamento : '' }, nivel_jerarquico : { nivel_jerarquico : "" }, grupo_funcional : { grupo_funcional : "" } }  })

  const { show, handleClose, uuid_empleado } = params

  const [ cargando_, setCargando ] = useState( false )

  const cargaFicha = async() => {

    try{
      let res = await RequestService.getEndPoint('incidencias/ficha_trabajador/'+uuid_empleado)
      setEmpleado(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de nominas')
    }

  }


  const [key, setKey] = useState<any>('informacion_personal');


  React.useEffect(() => {
    cargaFicha()
  }, []);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());



  const [_startDate, _setStartDate] = useState(new Date());
  const [_endDate, _setEndDate] = useState(new Date());


  const actualizarGafete = ()=>{
    _setStartDate(startDate)
    _setEndDate(endDate)
  }


   const onChangeRango = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

 const eliminarMovimiento = async(uuid_movimiento:string)=>{
    if(await dialog.confirm('¿Estás seguro?',{})){
      try{
        setCargando(true)
        let res = await RequestService.delete('movimiento/eliminar/'+uuid_movimiento)
        if(res.data){
          toast.warning('Este cambio fue notificado al departamento de Recursos Humanos')        
          cargaFicha()
        }
        setCargando(false)
      }
      catch(e){
        setCargando(false)
        toast.error('Problemas al intentar cargar los días')
      }
    }
  }






  return (
    <>
      <Modal show={show} size={'xl'} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>FICHA DE EMPLEADO</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(empleado)
          }





    
          <div className="row gutters-sm">
            <div className="col-md-4 mb-3">


              <div className="card">
                <div className="card-header">
                  Empleado
                </div>
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                  <img src={`${SERVER_}perfil/foto/${empleado.uuid}?${Math.random().toString(36).substr(2, 9)}`}   style={{width:'100%'}} />
                    <div className="mt-3">
                      <h4>{empleado.nombres} {empleado.paterno} {empleado.materno} </h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-3">
                <div className="card-header">
                  Información Laboral
                </div>
                <div className="card-body">

                  <div className="row">
                    <div className="col-sm-5">
                      <h6 className="mb-0">Zona</h6>
                    </div>
                    <div className="col-sm-7 text-secondary">
                    {empleado?.subarea_trabajo?.idarea?.area}
                      {
                        // JSON.stringify(empleado?.subarea_trabajo.idarea.area)
                      }
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-sm-5">
                      <h6 className="mb-0">Subzona</h6>
                    </div>
                    <div className="col-sm-7 text-secondary">
                      {empleado?.subarea_trabajo?.subarea}
                    </div>
                  </div>


                </div>
              </div>

            </div>
            <div className="col-md-8">





    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="informacion_personal" title="Información">


              <div className="card mb-3">
                <div className="card-header">
                  Información de Personal
                </div>
                <div className="card-body">

                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">C.U.R.P.</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {empleado.curp}
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-3">
                <div className="card-header">
                  Información de Contacto
                </div>
                <div className="card-body">

                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Correo Electrónico</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {empleado.email||''}
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Teléfono</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {empleado.celular}
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Dirección</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {empleado.direccion}
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">C.P.</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {empleado.cp}
                    </div>
                  </div>
                </div>
              </div>


      </Tab>

    </Tabs>






            </div>
          </div>






        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalFichaEmpleado;