import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Form, Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { Formik } from 'formik';
import * as yup from 'yup';



import { diasDeLaSemana, obtenerNumeroDiaSemana } from '../../services/funcionesPublicas'


const ModalAsignarTecnicoBoutique = ( params : any ) => {

  const user = AuthService.getCurrentUser()


  // const { tecnicos } = params


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          tecnico_seleccionado : yup.number().required(mensajes_validacion_generico),
          dia_seleccionado : yup.number().required(mensajes_validacion_generico),
          numero_visitas : yup.number().required(mensajes_validacion_generico),

  });



        // ={setConfiguracion}
        // ={configuracion}
        // ={tecnicos_seleccionado}
        // ={boutique_seleccionada}

        //   ={setConfiguracion}
        // ={configuracion}
        // ={tecnicos_seleccionado}
        // ={boutique_seleccionada}
        // ={index_areas_seleccionada}
        // ={index_subareas_seleccionada}


  const [ tecnico_seleccionado, setTecnico ] = useState<any>(null)
  const [ dia_seleccionado, setDia ] = useState<any>(null)
  const [ numero_visitas, setNumeroVisitas ] = useState<any>(2)

  const { show, handleClose, tecnicos_seleccionado, setConfiguracion, configuracion, boutique_seleccionada, index_areas_seleccionada, index_subareas_seleccionada, fechas } = params

const reset = ()=>{
  setTecnico(null)
  setDia(null)
  setNumeroVisitas(null)
  // setBoutique('')
}


const actualizarFechas = (values:any)=>{
  let fechas = configuracion.areas[index_areas_seleccionada].subareas[index_subareas_seleccionada].tecnicos[values.tecnico_seleccionado].fechas
  let i = 0  
  for(let fecha of fechas){
    // console.log(fecha,dia_seleccionado,obtenerNumeroDiaSemana(fecha.fecha))
    if(values.dia_seleccionado===obtenerNumeroDiaSemana(fecha.fecha) && i < values.numero_visitas){
      console.log('indice',i)
      console.log('numero_visitas',values.numero_visitas)
      fecha.idboutique = boutique_seleccionada.uuid_boutique
      i += 1
    }
    
  }
  let c = {...configuracion}
  c.areas[index_areas_seleccionada].subareas[index_subareas_seleccionada].tecnicos[values.tecnico_seleccionado].fechas = fechas
  setConfiguracion(c)
  handleClose()
}

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Selección Automatica</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <small>Boutique</small>
          <h5>{boutique_seleccionada.nombre_boutique}</h5>






    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{ actualizarFechas(values) }}
      initialValues={{

            tecnico_seleccionado : "",
            dia_seleccionado : "",
            numero_visitas : 1,

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>

        
        {
          JSON.stringify(values)
        }



                <div className="mb-3">
                  <label htmlFor="tecnico_" className="form-label">Técnico Asignado</label>

                            {tecnicos_seleccionado.map((tecnico:any,index:number)=>

                                <React.Fragment key={index}>

                                      <Form.Check
                                        required
                                        type="radio"
                                        name="tecnico_seleccionado"
                                        label={`${tecnico.nombres} ${tecnico.paterno} ${tecnico.materno}`} 
                                        onChange={(e:any)=>{setFieldValue('tecnico_seleccionado',index)}}
                                        isInvalid={!!errors.tecnico_seleccionado}
                                        feedback={errors.tecnico_seleccionado}
                                        id={`valitado${index}`}
                                      />


                                </React.Fragment>

                              )}

                </div>



                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Día</label>

                            {diasDeLaSemana().map((dia:any,index:number)=>

                                <React.Fragment key={index}>

                                      <Form.Check
                                        required
                                        type="radio"
                                        name="dia_seleccionado"
                                        label={dia}
                                        onChange={(e:any)=>{setFieldValue('dia_seleccionado',index)}}
                                        isInvalid={!!errors.dia_seleccionado}
                                        feedback={errors.dia_seleccionado}
                                        id={`dias_${index}`}
                                      />


                                </React.Fragment>

                              )}
                </div>





                <div className="mb-3">
                  <label htmlFor="numero_visitas" className="form-label">Número de Visitas en el Periodo</label>
                  <select className="form-select" value={values.numero_visitas} onChange={(e:any)=>{setFieldValue("numero_visitas",e.target.value)}}>
                    { Array.from(Array(boutique_seleccionada.numero_visitas).keys()).map((v:any)=><option value={v+1}>{v+1}</option>)}
                  </select>
                </div>




        </Form>
      )}
    </Formik>









        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>


      </Modal>
    </>
  );
}


export default ModalAsignarTecnicoBoutique;