import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import moment from 'moment'


import ModalActividadesNoConcluidaReagendar from './ModalActividadesNoConcluidaReagendar'


const ModalActividadesNoConcluidas = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params


  const [ servicios_pendientes, setServicios_pendientes ] = useState<any>([])
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarServicios_pendientes = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`operacion/mantenimiento/servicios_pendientes`)
      setServicios_pendientes(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarServicios_pendientes()
  }, []);


  const [ show_ModalActividadesNoConcluidaReagendar, setModalActividadesNoConcluidaReagendar ] = useState<boolean>(false)
  const [ actividad_seleccionada, setActividadSeleccionada ] = useState<any>({})
  const handleShow_ModalActividadesNoConcluidaReagendar = (actividad:any) => {
    setActividadSeleccionada(actividad)
    setModalActividadesNoConcluidaReagendar(true)
  }
  const handleClose_ModalActividadesNoConcluidaReagendar = () => {
    cargarServicios_pendientes()
    setModalActividadesNoConcluidaReagendar(false)
  }

    
  return (
    <>
      <Modal show={show} fullscreen={true} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Actividades que requieren días extras</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(servicios_pendientes)
          }


                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Folio / Estatus</th>
                                    <th scope="col"></th>
                                    <th scope="col">Tipo</th>
                                    <th scope="col">Técnico</th>
                                    <th scope="col">Responsable</th>
                                    <th scope="col">Boutique</th>
                                    <th scope="col">Sub Zona</th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody>{
                                        servicios_pendientes.map((fecha:any)=>

                                              <tr key={ Math.random().toString(36).substr(2, 9) }>
                                                <td className={`v estatus_atencion_${fecha.idestatus_atencion.id}`} style={{width:140}}>
                                                  <p className="nm"><b>{fecha.folio}</b></p>
                                                  <p className="nm"><small>{fecha.idestatus_atencion.estatus}</small></p>
                                                </td>
                                                <td className="v" style={{width:40}}>
                                                  {
                                                    fecha.idpresupuesto && (<i className="fa fa-money"/>)
                                                  }
                                                </td>
                                                <td className="v" style={{width:160}}>
                                                  <p className="nm"><small>{fecha.idtipo_orden.tipo_orden}</small></p>
                                                </td>
                                                <td className="v"  >
                                                  <b>{fecha.idtecnico.nombres}</b>
                                                  <p className="nm"><small> {fecha.idtecnico.paterno} {fecha.idtecnico.materno}</small></p>
                                                </td>
                                                <td className="v" style={{width:240}}>
                                                  { fecha.idresponsable !== null && (<p className="nm"><b>{fecha.idresponsable.nombres}</b> <br/><small> {fecha.idresponsable.paterno} {fecha.idresponsable.materno}</small></p>)}
                                                </td>
                                                <td className="v" style={{width:400}}>
                                                  <p className="nm">{fecha.idboutique.nombre_boutique}</p>
                                                </td>
                                                <td className="v" style={{width:180}}>
                                                  <p className="nm"><small>{fecha.idboutique.idsubarea.subarea}</small></p>
                                                </td>
                                                <td className="v" style={{width:100}}>
                                                </td>
                                                <td className="v" style={{width:200}}>
                                                  <p className="nm"><b><small>{moment(fecha.fecha_programada).format('DD [DE] MMMM [DE] YYYY').toUpperCase()}</small></b></p>
                                                </td>
                                                <td className="v" style={{width:40}}>
                                                  <button onClick={(e:any)=>{handleShow_ModalActividadesNoConcluidaReagendar(fecha)}} type="button" className="btn btn-sm btn-warning"><i className="fa fa-calendar"/></button>
                                                </td>
                                              </tr>


                                          )

                                      }
                                </tbody>
                              </table>

        </Modal.Body>




      </Modal>





  {
    show_ModalActividadesNoConcluidaReagendar && (
      <ModalActividadesNoConcluidaReagendar show={show_ModalActividadesNoConcluidaReagendar} handleClose={handleClose_ModalActividadesNoConcluidaReagendar} actividad={actividad_seleccionada} />
      )

  }



    </>
  );
}


export default ModalActividadesNoConcluidas;