import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';
import { Form, Col, Row } from 'react-bootstrap'

import { toast } from 'react-toastify'

import { Formik } from 'formik';
import * as yup from 'yup';

import Dialog from '../../services/Dialogs'


const ModalNuevaZona = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params


  const dialog = new Dialog();




  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          area : yup.string().required(mensajes_validacion_generico),
          idresponsable_mantenimiento : yup.string().nullable(true),
          idresponsable_conservacion : yup.string().nullable(true),
          idresponsable_limpieza : yup.string().nullable(true),

  });



const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `zona/nueva` , { ...registro })

        if(res_){
          toast.success('La información de la Zona fue registrada')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}




  const [ responsables, setResponsables ] = useState<any>({
    mantenimiento : [],
    conservacion : [],
    limpieza: [],
  })
  const cargarResponsables = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`zonas/lista_responsables`)
      setResponsables(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarResponsables()
  }, []);



    




  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Agregar Zona</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }




    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            area : "",
            idresponsable_mantenimiento : "",
            idresponsable_conservacion : "",
            idresponsable_limpieza : "",

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>


            <Form.Group   className="mb-3" controlId="area">
              <Form.Label>Zona</Form.Label>
              <Form.Control
                required
                name="area"
                value={values.area}
                onBlur={(e)=>{ setFieldValue('area', e.target.value.toUpperCase() ) }}
                onChange={(e)=>{ setFieldValue('area', e.target.value ) }}
                style={{textTransform:'uppercase'}}
                isInvalid={!!errors.area}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.area}</>} </Form.Control.Feedback>
            </Form.Group>


                <Form.Group className="mb-3" controlId="idresponsable_mantenimiento">
                  <Form.Label>Responsable de Mantenimiento</Form.Label>
                  <Form.Select required name="idresponsable_mantenimiento" value={values.idresponsable_mantenimiento} onChange={(e)=>{ setFieldValue('idresponsable_mantenimiento', e.target.value ) }} isInvalid={!!errors.idresponsable_mantenimiento}>
                    <option disabled={values.idresponsable_mantenimiento!==''} value="">Sin Responsable</option>
                    {
                      responsables.mantenimiento.map( (responsable:any) =>
                        <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>
                                  <option value={responsable.uuid}> {responsable.nombres} {responsable.paterno} {responsable.materno}</option>
                        </React.Fragment>
                      )
                    }

                  </Form.Select>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">{<>{errors.idresponsable_mantenimiento}</>}</Form.Control.Feedback>
                </Form.Group>



                <Form.Group className="mb-3" controlId="idresponsable_conservacion">
                  <Form.Label>Responsable de Conservación</Form.Label>
                  <Form.Select required name="idresponsable_conservacion" value={values.idresponsable_conservacion} onChange={(e)=>{ setFieldValue('idresponsable_conservacion', e.target.value ) }} isInvalid={!!errors.idresponsable_conservacion}>
                    <option disabled={values.idresponsable_conservacion!==''} value="">Sin Responsable</option>
                    {
                      responsables.conservacion.map( (responsable:any) =>
                        <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>
                                  <option value={responsable.uuid}> {responsable.nombres} {responsable.paterno} {responsable.materno}</option>
                        </React.Fragment>
                      )
                    }

                  </Form.Select>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">{<>{errors.idresponsable_conservacion}</>}</Form.Control.Feedback>
                </Form.Group>



                <Form.Group className="mb-3" controlId="idresponsable_limpieza">
                  <Form.Label>Responsable de Limpieza</Form.Label>
                  <Form.Select required name="idresponsable_limpieza" value={values.idresponsable_limpieza} onChange={(e)=>{ setFieldValue('idresponsable_limpieza', e.target.value ) }} isInvalid={!!errors.idresponsable_limpieza}>
                    <option disabled={values.idresponsable_limpieza!==''} value="">Sin Responsable</option>
                    {
                      responsables.limpieza.map( (responsable:any) =>
                        <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>
                                  <option value={responsable.uuid}> {responsable.nombres} {responsable.paterno} {responsable.materno}</option>
                        </React.Fragment>
                      )
                    }

                  </Form.Select>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">{<>{errors.idresponsable_limpieza}</>}</Form.Control.Feedback>
                </Form.Group>





        </Form>
      )}
    </Formik>



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalNuevaZona;