import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Form, Button, Modal, Badge } from 'react-bootstrap';

import { toast } from 'react-toastify'


import moment from 'moment'

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import { agregarDias, obtenerRangoFechasArreglo, obtenerDiaSemana, diasDeLaSemana, contarNoBaseODescanso } from '../../services/funcionesPublicas'


import Dialog from '../../services/Dialogs'


const ConfiguracionConservacion = (params:any) => {


const dialog = new Dialog();

const { configuracion, setCalendario_configuraciones } = params


const [ envio_server, setSeEnvio ] = useState<boolean>(false)

const [ tecnicos_seleccionado, setTecnicos ] = useState<any>(null)
const [ boutique_seleccionada, setBoutique ] = useState<any>(null)
const [ index_areas_seleccionada, setIndexAreas ] = useState<any>(null)
const [ index_subareas_seleccionada, setIndexSubareas ] = useState<any>(null)
const [ index_tecnicos_seleccionada, setIndexTecnicos ] = useState<any>(null)
const [ fechas_seleccionadas, setFechasSeleccionadas ] = useState<any>(null)
// const [ dia_seleccionado, setDia ] = useState<any>('')


  const [ show_ModalPreviewCalendarioZonaProgramacion, setModalPreviewCalendarioZonaProgramacion ] = useState<boolean>(false)
  const handleShow_ModalPreviewCalendarioZonaProgramacion = (index_areas:number) => {
    setIndexAreas(index_areas)
    setModalPreviewCalendarioZonaProgramacion(true)
  }
  const handleClose_ModalPreviewCalendarioZonaProgramacion = () => {
    setModalPreviewCalendarioZonaProgramacion(false)
  }

  const configuracionSubareaLista = (subarea:any)=>{
    let states = []

    for(let boutique of subarea.boutiques){
      states.push(
          // contarVisitas(boutique.uuid_boutique,subarea.tecnicos) >== boutique.
        contarVisitas(boutique.uuid_boutique,subarea.tecnicos)>=boutique.numero_visitas
        )
    }

    // contarVisitas(boutique.uuid_boutique,subarea.tecnicos)

    return states.every(valor => valor === true)

  }
  
const contarVisitas = (uuid_boutique:string, visitasTecnicos:any)=> {
    return visitasTecnicos.reduce((total:any, tecnico:any) => {
        return total + tecnico.fechas.reduce((contador:any, fecha:any) => {
            return contador + (fecha.idboutique === uuid_boutique ? 1 : 0);
        }, 0);
    }, 0);
}


const recargarSubarea = async (event:any,subarea:any,index_areas:number,index_subareas:number) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.getEndPoint( `calendario/recargar/preview_subarea_conservacion/${subarea.uuid}` )
        // console.log(res_)
        let c = {...configuracion}
        // console.log(c)
        c.preview_calendario_conservacion[index_areas].subareas[index_subareas] = res_.data
        setCalendario_configuraciones(c)

        if(res_){
          toast.warning('El Calendario fue actualizado', { icon : '⚠️' })
          setTimeout(()=>{
            // cargar()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          console.log(e)
          // toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}



const recargarArea = async (event:any,area:any,index_areas:number) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.getEndPoint( `calendario/recargar/preview_area_conservacion/${area.uuid}` )
        // console.log(res_)
        let c = {...configuracion}
        // console.log(c)
        c.preview_calendario_conservacion[index_areas] = res_.data
        setCalendario_configuraciones(c)

        if(res_){
          toast.warning('El Calendario fue actualizado', { icon : '⚠️' })
          setTimeout(()=>{
            // cargar()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          console.log(e)
          // toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}



const recargarZona = async (event:any ) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.getEndPoint( `calendario/recargar/preview_conservacion` )
        let c = {...configuracion}
        // c.areas[index_areas].subareas[index_subareas] = res_.data
        c.preview_calendario_conservacion = res_.data
        setCalendario_configuraciones(c)

        if(res_){
          toast.warning('El Calendario fue actualizado', { icon : '⚠️' })
          setTimeout(()=>{
            // cargar()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}



const resetearFechas = (index_areas:number,index_subareas:number,index_tecnicos:number)=>{
  let fechas = configuracion.preview_calendario_conservacion[index_areas].subareas[index_subareas].tecnicos[index_tecnicos].fechas
  for(let fecha of fechas){
      fecha.idboutique = "BASE"
  }
  let c = {...configuracion}
  c.preview_calendario_conservacion[index_areas].subareas[index_subareas].tecnicos[index_tecnicos].fechas = fechas
  setCalendario_configuraciones(c)

}



  const [ show_ModalAsignarEquiposCritiosVisita, setModalAsignarEquiposCritiosVisita ] = useState<boolean>(false)
  const handleShow_ModalAsignarEquiposCritiosVisita = (index_areas:number,index_subareas:number,index_tecnicos:number) => {
    setIndexAreas(index_areas)
    setIndexSubareas(index_subareas)
    setIndexTecnicos(index_tecnicos)
    setModalAsignarEquiposCritiosVisita(true)
  }
  const handleClose_ModalAsignarEquiposCritiosVisita = () => {
    setModalAsignarEquiposCritiosVisita(false)
  }
	




const cambiarOpcionesFecha = (tipo:string, value:any, index_areas:number, index_subareas:number, index_tecnicos:number, index_dia:number ) =>{

  let conf = {...configuracion}

      conf.preview_calendario_conservacion[index_areas].subareas[index_subareas].tecnicos[index_tecnicos].fechas[index_dia].idboutique = value
      conf.preview_calendario_conservacion[index_areas].subareas[index_subareas].tecnicos[index_tecnicos].fechas[index_dia].equipos_criticos = []

  setCalendario_configuraciones(conf)

}


  const [ show_ModalAsignarTecnicoBoutique, setModalAsignarTecnicoBoutique ] = useState<boolean>(false)
  const handleShow_ModalAsignarTecnicoBoutique = (tecnicos:any,boutique:any,index_areas:number,index_subareas:number,fechas:any) => {
    setTecnicos(tecnicos)
    setBoutique(boutique)
    setIndexAreas(index_areas)
    setIndexSubareas(index_subareas)
    setFechasSeleccionadas(fechas)
    // setDia(tecnico_seleccionado)
    setModalAsignarTecnicoBoutique(true)
  }
  const handleClose_ModalAsignarTecnicoBoutique = () => {
    setModalAsignarTecnicoBoutique(false)
  }



  const [ show_ModalAsignarAtencionPresupuestoTecnico, setModalAsignarAtencionPresupuestoTecnico ] = useState<boolean>(false)
  const handleShow_ModalAsignarAtencionPresupuestoTecnico = (tecnicos:any,boutique:any,index_areas:number,index_subareas:number,fechas:any) => {
    setTecnicos(tecnicos)
    setBoutique(boutique)
    setIndexAreas(index_areas)
    setIndexSubareas(index_subareas)
    setFechasSeleccionadas(fechas)

    setModalAsignarAtencionPresupuestoTecnico(true)
  }
  const handleClose_ModalAsignarAtencionPresupuestoTecnico = () => {
    setModalAsignarAtencionPresupuestoTecnico(false)
  }


	return (

		<>

                      <div className="table-responsive-">
                        <table className="table">
                          <thead className="table-dark">
                            <tr>
                              <th scope="col">Zona <i onClick={(e:any)=>{recargarZona(e)}}  className="fa pointer fa-refresh"/></th>
                            </tr>
                          </thead>
                          <tbody>
                          {
                            configuracion.preview_calendario_conservacion.map((area:any,index_areas:number)=>

                              <React.Fragment key={ index_areas }>
                            
                            <tr>
                              <th className="table-light" scope="row" style={{width:150}}>
                                <h4 onClick={(e:any)=>{handleShow_ModalPreviewCalendarioZonaProgramacion(index_areas)}} className=" nm">{area.area} <i onClick={(e:any)=>{recargarArea(e,area,index_areas)}} className="fa pointer fa-refresh"/></h4>
                              </th>
                            </tr> 
                            <tr>
                              <td>
                                {
                                  area.subareas.map((subarea:any,index_subareas:number)=>

                                        <table className={`table table-borderless  ${ index_subareas % 2 ? 'table-light-' : '' }`} key={ index_subareas } style={{margin:0,padding:0,width:'100%', background:(configuracionSubareaLista(subarea)?'#86dac5':'')}}>
                                          <tbody>
                                            <tr>
                                              <td className="v" style={{width:250}}>
                                              {
                                                // JSON.stringify(subarea)
                                              }
                                                <h6 className="nm text-center">{subarea.subarea} <i onClick={(e:any)=>{recargarSubarea(e,subarea,index_areas,index_subareas)}} className="fa fa-refresh"/> </h6>
                                              </td>
                                              <td style={{width:'100%'}}>
                                              
{
  // JSON.stringify((subarea))
}                       
{
  // JSON.stringify(configuracionSubareaLista(subarea))
}


                                                {
                                                  subarea.tecnicos.map((tecnico:any,index_tecnicos:number)=>

                                                   <React.Fragment key={ index_tecnicos }>

                                                    {
                                                    //   <p style={{fontSize:14}}>{
                                                    //   JSON.stringify(tecnico.fechas)
                                                    // }</p>
                                                    }


                                                                <table className="table table-borderless -"   style={{margin:0,padding:0,width:'100%'}}>
                                                                  <tbody>
                                                                    <tr>
                                                                      <td className="v" style={{verticalAlign:'middle',width:200}} rowSpan={2}>
                                                                        {
                                                                          // JSON.stringify(tecnico)
                                                                        }
                                                                        <p className="nm">{tecnico.nombres}</p>
                                                                        <small><b>{tecnico.paterno} {tecnico.materno}</b> 
                                                                        <br/>
                                                                            {' '}
                                                                            {
                                                                              // contarNoBaseODescanso(tecnico.fechas)
                                                                            }
                                                                            { contarNoBaseODescanso(tecnico.fechas) > 0 && (<i onClick={(e:any)=>{handleShow_ModalAsignarEquiposCritiosVisita(index_areas,index_subareas,index_tecnicos)}} className="fa fa-gear pointer"/>)}
                                                                            {' '}
                                                                            <i onClick={(e:any)=>{resetearFechas(index_areas,index_subareas,index_tecnicos)}} className="fa fa-trash pointer"/> 
                                                                        </small>
                                                                      </td>
                                                                      {
                                                                        tecnico.fechas.map((fecha:any,indice_fecha:number)=>

                                                                            <React.Fragment key={ indice_fecha }>
                                                                              <td className={` ${ fecha.idboutique !== '' ? 'asignado_conservacion' :'' } ${ fecha.idboutique === 'BASE' ? 'base_conservacion' : '' }  ${ fecha.idboutique === 'DESCANSO' ? 'descanso_conservacion' : '' } `}>
                                                                                <p className="nm text-center" style={{fontSize:11}}><b>{ moment(fecha.fecha).format('DD') }</b> <small>{obtenerDiaSemana(fecha.fecha)}</small> </p>
                                                                                {
                                                                                  // '???'
                                                                                }

                                                                              </td>
                                                                            </React.Fragment>

                                                                          )
                                                                      }
                                                                    </tr>
                                                                    <tr>
                                                                      {
                                                                        tecnico.fechas.map((fecha:any,index_dia:number)=>

                                                                            <React.Fragment key={ index_dia }>
                                                                              <td className={` ${ fecha.idboutique !== '' ? 'asignado_conservacion' :'' } ${ fecha.idboutique === 'BASE' ? 'base_conservacion' : '' } ${ fecha.idboutique === 'DESCANSO' ? 'descanso_conservacion' : '' } `} >



                                                                                  <select className="form-select form-select-sm" style={{width:'100px',lineHeight: 1}} value={fecha.idboutique} onChange={(e:any)=>{ cambiarOpcionesFecha('',e.target.value, index_areas, index_subareas, index_tecnicos, index_dia ) }}>
                                                                                    
                                                                                    {subarea.boutiques.map((boutique:any,keyb:number)=>(
                                                                                        <React.Fragment key={keyb}>
                                                                                            <option  value={boutique.uuid_boutique}>{boutique.acronimo_folio}</option>
                                                                                        </React.Fragment>
                                                                                      ))
                                                                                  }

                                                                                    <option value="DESCANSO">DESCANSO</option>
                                                                                    <option value="BASE">BASE</option>

                                                                                  </select>

                                                                                  {
                                                                                    // JSON.stringify(fecha)
                                                                                  }

                                                                              </td>
                                                                            </React.Fragment>

                                                                          )
                                                                      }
                                                                    </tr>
                                                                  </tbody>
                                                                </table>


                                                     </React.Fragment>


                                                    )
                                                }




                                              </td>

                                            </tr>
                                            <tr>
                                              <td colSpan={1}>
                                              </td>
                                              <td colSpan={1}>
                                                {
                                                  // JSON.stringify(subarea.boutiques)
                                                }
                                                {
                                                  subarea.boutiques.map((boutique:any,indice_boutiques:number)=>(<>

                                                          {
                                                            <React.Fragment key={ indice_boutiques }>
                                                          
                                                            <Button variant="outline-dark" size="sm" >
                                                              {boutique.nombre_boutique} <small>{boutique.acronimo_folio}</small> 
                                                              <br/>
                                                              <Badge onClick={(e:any)=>{handleShow_ModalAsignarTecnicoBoutique(subarea.tecnicos, boutique, index_areas,index_subareas,[])}} bg="secondary">VISITAS: {boutique.numero_visitas}</Badge>
                                                              {' '}
                                                              <Badge bg={`${contarVisitas(boutique.uuid_boutique,subarea.tecnicos)>=boutique.numero_visitas?'success':'warning'}`}>PROGRAMADAS: {contarVisitas(boutique.uuid_boutique,subarea.tecnicos)} </Badge>
                                                              {' '}
                                                              <Badge bg="light">EQC: {boutique.equipos_criticos.length}</Badge>
                                                              {' '}
                                                              { boutique.presupuestos.length > 0 && (<Badge onClick={(e:any)=>{handleShow_ModalAsignarAtencionPresupuestoTecnico(subarea.tecnicos, boutique, index_areas,index_subareas,[])}} bg="danger">PRESUPUESTOS: {boutique.presupuestos.length}</Badge>)}
                                                            </Button>
                                                            {' '}

                                                            </React.Fragment>
                                                          }

                                                    </>))
                                                }
                                              </td>
                                            </tr>

                                          </tbody>
                                        </table>




                                    )
                                }
                              </td> 
                            </tr>

                              </React.Fragment>



                              )
                          }

                          </tbody>
                        </table>
                      </div>
                    


		</>


	)
}


export default ConfiguracionConservacion;