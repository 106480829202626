import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { Form, Col, Row, Tabs, Tab } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import Dialog from '../../services/Dialogs'

import moment from 'moment'


import { rol } from '../../services/rol'

const ModalRegistroMovimientosUsuario = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, usuario } = params



  const [ lista_movimientos, setLista_movimientos ] = useState<any>([])
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarLista_movimineots = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`empleado/movimientos/${usuario.uuid}`)
      setLista_movimientos(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarLista_movimineots()
  }, []);




  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          descripcion : yup.string().required(mensajes_validacion_generico),
          movimiento : yup.string().required(mensajes_validacion_generico),
          fecha_movimiento : yup.string().required(mensajes_validacion_generico),
          idusuario : yup.string().required(mensajes_validacion_generico),

  });



  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.create( `empleado/registro_movimientos/baja` , { ...registro, fecha_movimiento : moment(registro.fecha_movimiento).format('YYYY-MM-DD') })

          if(res_){
            toast.success('El movimiento fue registrado')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Registro de Movimientos</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <small>Usuario</small>
        <h5>{usuario.nombres} {usuario.paterno} {usuario.materno} </h5>

        <small>Rol</small>
        <h5>{rol(usuario.rol)} </h5>

        <small>Fecha de Ingreso</small>
        <h5>{usuario.nombres} {usuario.paterno} {usuario.materno} </h5>



        {
          usuario.activo && (<p>Usuario Actualmente Activo <i className="fa fa-star"/></p>)
        }




        {
          !usuario.activo && (<p>Este usuario está de baja ⛔ </p>)
        }




    <Tabs
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="Movimientos">



          {
            lista_movimientos.length === 0 && (
                  <div className="alert alert-warning" role="alert">
                    <i className="fa fa-warning"/> No existen movimientos registrados
                  </div>
              )
          }



          {
            lista_movimientos.length > 0 && (
                  <React.Fragment>
                    
                    {
                      // JSON.stringify(lista_movimientos)
                    }

                    <ol className="list-group list-group-numbered list-group-flush">
                      { lista_movimientos.map((movimiento:any,indice_movimiento:number)=><li key={indice_movimiento} className="list-group-item"><small><b>{movimiento.movimiento}</b></small> <p className="nm">{movimiento.descripcion}</p><small>{' '}{moment(movimiento.fecha_movimiento).format('DD/MM/YYYY')}</small></li>)}
                    </ol>


                  </React.Fragment>
              )
          }



      </Tab>


        {
          usuario.activo && (
              <Tab eventKey="baja" title="Baja">
                      <React.Fragment>
                        
                          <Formik
                            validationSchema={schema}
                            onSubmit={(values,e)=>{enviarFormulario(values, e)}}
                            initialValues={{
                                  idusuario : usuario.uuid,
                                  descripcion : "",
                                  movimiento : "BAJA",
                                  fecha_movimiento : new Date(),
                            }}
                          >
                            {({
                              handleSubmit,
                              handleChange,
                              setFieldValue,
                              handleBlur,
                              values,
                              touched,
                              isValid,
                              errors,
                            }) => (
                              <Form id='my-form' noValidate onSubmit={handleSubmit}>


                                  <Form.Group className="mb-3" controlId="campo1">
                                    <Form.Label>Descripción de la Baja</Form.Label>
                                    <Form.Control
                                      as="textarea"
                                      required
                                      rows={5}
                                      name="descripcion"
                                      value={values.descripcion}
                                      onBlur={(e)=>{ setFieldValue('descripcion', e.target.value.toUpperCase() ) }}
                                      onChange={(e)=>{ setFieldValue('descripcion', e.target.value ) }}
                                      isInvalid={!!errors.descripcion}
                                      style={{textTransform:'uppercase'}}
                                    />
                                    <Form.Control.Feedback type="invalid"> {<>{errors.descripcion}</>} </Form.Control.Feedback>
                                  </Form.Group>

                                  <div className="row">
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                          <Form.Label>Fecha del Movimiento</Form.Label>
                                          <DatePicker selected={values.fecha_movimiento} className="form-control"  dateFormat="yyyy-MM-dd" maxDate={new Date()} onChange={(date) => setFieldValue('fecha_movimiento', date ) } />
                                        </div>
                                    </div>
                                  </div>




                                  <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>

                              </Form>
                            )}
                          </Formik>

                      </React.Fragment>
              </Tab>
            )
        }

        {
          !usuario.activo && (
              <Tab eventKey="reingreso" title="Reingreso">
                      <React.Fragment>
                        
                          <Formik
                            validationSchema={schema}
                            onSubmit={(values,e)=>{enviarFormulario(values, e)}}
                            initialValues={{
                                  idusuario : usuario.uuid,
                                  descripcion : "",
                                  movimiento : "REINGRESO",
                                  fecha_movimiento : new Date(),
                            }}
                          >
                            {({
                              handleSubmit,
                              handleChange,
                              setFieldValue,
                              handleBlur,
                              values,
                              touched,
                              isValid,
                              errors,
                            }) => (
                              <Form id='my-form' noValidate onSubmit={handleSubmit}>


                                  <Form.Group className="mb-3" controlId="campo1">
                                    <Form.Label>Descripción del Reingreso</Form.Label>
                                    <Form.Control
                                      as="textarea"
                                      required
                                      rows={5}
                                      name="descripcion"
                                      value={values.descripcion}
                                      onBlur={(e)=>{ setFieldValue('descripcion', e.target.value.toUpperCase() ) }}
                                      onChange={(e)=>{ setFieldValue('descripcion', e.target.value ) }}
                                      isInvalid={!!errors.descripcion}
                                      style={{textTransform:'uppercase'}}
                                    />
                                    <Form.Control.Feedback type="invalid"> {<>{errors.descripcion}</>} </Form.Control.Feedback>
                                  </Form.Group>

                                  <div className="row">
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                          <Form.Label>Fecha del Movimiento</Form.Label>
                                          <DatePicker selected={values.fecha_movimiento} className="form-control"  dateFormat="yyyy-MM-dd" maxDate={new Date()} onChange={(date) => setFieldValue('fecha_movimiento', date ) } />
                                        </div>
                                    </div>
                                  </div>




                                  <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>

                              </Form>
                            )}
                          </Formik>

                      </React.Fragment>
              </Tab>
            )
        }

    </Tabs>






        </Modal.Body> 
      </Modal>
    </>
  );
}


export default ModalRegistroMovimientosUsuario;