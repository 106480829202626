import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { sumarMontosTotales, sumarMontosTotalesNoAprobados,  sumarMontosTotalesAprobados, tipoDeElementoRequisicion, formatearNumero, formatearFecha, formatearFechaHora, formatearFechaEstandar, obtenerUnidadMedida } from '../../services/funcionesPublicas'

import moment from 'moment'

import { SERVER_ } from '../../config'



const ModalPagoRequisicionesDetalle = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, elemento } = params

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Detalle de pago de Requisiciones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(elemento)
          }

          <small>Concepto</small>
          <h6 className="nm">{elemento.concepto}
          {'  '}
          {
            !elemento?.idestatus_elemento?.id && (<i className="fa fa-clock-o" />) 
          }
          </h6>

          {
            // JSON.stringify(elemento?.meta_data.detalles_deposito)
          }

          {
            elemento?.meta_data?.detalles_deposito && (
                <>
                  <small>Detalles</small>
                  <h6 className="nm"><b>{elemento?.meta_data?.detalles_deposito}</b></h6>
                </>
              )
          }

          {
            [5].indexOf(elemento?.idestatus_elemento?.id) > -1 && (
                <>
                  ✅
                </>
              )
          }


                {
                  elemento.idegreso && (
                      <>
                      <br/>
                        <small>Ćomprobación de Depósito</small>
                        <p className="nm"><button onClick={()=>{ window.open(`${SERVER_}contabilidad/archivo/${elemento?.idegreso?.uuid}`) }} className="btn btn-sm btn-link nm">Abrir</button></p>
                      </>
                    )
                }


                <small>Monto</small>
                <h6 className="nm">$ {formatearNumero((elemento.monto_total*1))}</h6>

                          {
                            [6].indexOf(elemento.idestatus_elemento.id) > -1 && (
                                <>
                                  <small>Monto Comprobado</small>
                                  <h6 className="">$ {formatearNumero(elemento?.monto_comprobado)}</h6>

                                  <p>
                                    <i className="fa fa-check-square" /> Comprobado
                                  </p>
                                </>
                              )
                          }


                <small>Tipo de Requisición</small>
                <h6 className="nm">{tipoDeElementoRequisicion(elemento.tipo)}</h6>

                                

                           {
                             [5].indexOf(elemento?.idestatus_elemento?.id) > -1 && (
                                 <>
                                   <small>Ćomprobación de Evidencia del Gasto</small>
                                   <p className=""><button onClick={()=>{ window.open(`${SERVER_}comprobaciones/evidencia/archivo/${elemento?.uuid}`) }} className="btn btn-sm btn-link nm">Abrir</button></p>

                                   <small>Monto Comprobado</small>
                                   <h6 className="">$ {formatearNumero(elemento?.monto_comprobado)}</h6>

                                   <p className="nm">
                                     <i className="fa fa-check-square" /> Comprobado
                                   </p>
                                 </>
                               )
                           }              



        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalPagoRequisicionesDetalle;