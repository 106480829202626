import React, { useState, useEffect } from 'react';
import RequestService from "../../services/request.service";
import AuthService from '../../features/auth/auth.service';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Form, Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import Dialog from '../../services/Dialogs';
import moment from 'moment';
import { SERVER_ } from '../../config';

const ModalVehiculoAsignar = (params:any) => {
  const user = AuthService.getCurrentUser();
  const { show, handleClose, vehiculo } = params;

  const [usuarios, setUsuarios] = useState<any>([]);
  
  const cargarUsuarios = async () => {
    try {
      let response = await RequestService.getEndPoint('vehiculos/asignacion/lista_usuarios');
      setUsuarios(response.data);
    } catch (e) {
      toast.error('Problemas al intentar cargar');
    }
  };

  useEffect(() => {
    cargarUsuarios();
  }, []);

  const dialog = new Dialog();

  const mensajes_validacion_generico = "Este campo es obligatorio";
  const schema = yup.object().shape({
    idusuario_asignado: yup.string().required(mensajes_validacion_generico),
    archivo: yup.string().required(mensajes_validacion_generico),
  });

  const [envio_server, setSeEnvio] = useState(false);

  const enviarFormulario = async (registro:any, event:any) => {
    await dialog.open({
      accept: 'Si',
      cancel: 'Espera',
      dialogClass: '',
      message: '¿Estás seguro de la información?',
      target: event.target,
      template: ''
    });

    let dialogo = await dialog.waitForUser();
    if (dialogo) {
      try {
        setSeEnvio(true);
        let res_ = await RequestService.update(`vehiculo/asignar/${vehiculo.uuid_vehiculo}`, { ...registro, archivo: registro.archivo.split(',')[1] });

        if (res_) {
          toast.success('El Vehículo de asigno correctamente');
          setTimeout(() => {
            handleClose();
          }, 0);
        } else {
          toast.error('Problemas...');
        }
      } catch (e:any) {
        toast.error(e.response.data);
      }
    }
  };

  const handleFileChange = async (event:any, setFieldValue:any) => {
    const file = event.target.files[0];

    if (file) {
      const base64String = await convertFileToBase64(file);
      setFieldValue('archivo', base64String);
    }
  };

  const convertFileToBase64 = (file:any) => {
    return new Promise((resolve:any, reject:any) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error:any) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Asignar Vehículo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={schema}
            onSubmit={enviarFormulario}
            initialValues={{
              idusuario_asignado: vehiculo.idusuario_asignado ? vehiculo.idusuario_asignado.uuid : "",
              archivo: ""
            }}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form id='my-form' noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="idusuario_asignado">
                  <Form.Label>Usuario para Asignar el Vehículo</Form.Label>
                  <Form.Select 
                    name="idusuario_asignado" 
                    value={values.idusuario_asignado} 
                    isInvalid={!!errors.idusuario_asignado} 
                    required 
                    onChange={(e) => { setFieldValue('idusuario_asignado', e.target.value) }} 
                  >
                    <option disabled={values.idusuario_asignado !== ''} value={""}>Selecciona un Usuario</option>
                    {usuarios.map((usuario:any, indice_usuario:number) => (
                      <option key={indice_usuario} value={usuario.uuid}>
                        {usuario.nombres} {usuario.paterno} {usuario.materno}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid"> {<>{errors.idusuario_asignado}</>} </Form.Control.Feedback>
                </Form.Group>

                {values.idusuario_asignado !== '' && (
                  <React.Fragment>
                    <p>
                      <a href={`${SERVER_}vehiculo/asignacion/formato_resguardo/${values.idusuario_asignado}/${vehiculo.uuid_vehiculo}`} target="_blank" rel="noopener noreferrer">
                        Impresión Resguardo Vehícular <i className="fa fa-file-pdf-o" />
                      </a>
                    </p>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>
                        Seleccionar Archivo {values.archivo && (<i className="fa fa-check-circle" />)}
                      </Form.Label>
                      <Form.Control 
                        type="file" 
                        name="archivo" 
                        accept=".pdf" 
                        isInvalid={!!errors.archivo} 
                        required 
                        onChange={(e) => { handleFileChange(e, setFieldValue) }} 
                      />
                      <Form.Control.Feedback type="invalid"> {<>{errors.archivo}</>} </Form.Control.Feedback>
                    </Form.Group>
                  </React.Fragment>
                )}
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">
            Guardar <i className="fa fa-save" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalVehiculoAsignar;
