import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { formatearNumero, condicionesPago, calcularTiempoTranscurrido, tipoActividadLabel, ordenarPorTipo } from '../../services/funcionesPublicas'
import { tipoPresupuesto } from '../../services/funcionesPublicas'

import Dialog from '../../services/Dialogs'

import moment from 'moment'

import { SERVER_ } from  '../../config';


import ModalGenerarPagoServicio from './ModalGenerarPagoServicio'



const ModalDetalleVisitaCobranza = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, presupuesto } = params




  const [ suma_subtotal, setSuma ] = useState<number>(0)

  const sumarSubtotales = () =>{
    let s = 0
    // console.log(presupuesto.material_actividades)
    for(let actividad of presupuesto.material_actividades){
      s += ( actividad.costo * actividad.cantidad )
    }
    setSuma(s)

  }




  const [ show_ModalGenerarPagoServicio, setModalGenerarPagoServicio ] = useState<boolean>(false)
  const handleShow_ModalGenerarPagoServicio = () => {
    setModalGenerarPagoServicio(true)
  }
  const handleClose_ModalGenerarPagoServicio = () => {
    setModalGenerarPagoServicio(false)
  }



  React.useEffect(() => {
    sumarSubtotales() 
  }, []);


  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Detalle de Cobro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }








        <div className="row">
          <div className="col-sm-4">

          <h6 className="mt-2">Folio *</h6>
          <p>{presupuesto.folio}</p>

          {presupuesto.idboutique&&(
              <React.Fragment>
                <h6 className="mt-2">Boutique</h6>
                <p className="nm">{presupuesto.idboutique.nombre_boutique}</p>
                  {
                    presupuesto.solicitado && (<p className="nm"><i className="fa fa-star"/> Solicitado por la Boutique</p>)
                  }
              </React.Fragment>
            )}



          <h6 className="mt-">Fecha de Creación</h6>
          <p className="nm">{moment(presupuesto.fecha_creacion).format('YYYY-MM-DD hh:mm:ss')}</p>
          <p className="nm"><small>{calcularTiempoTranscurrido(presupuesto.fecha_creacion)}</small></p>

          <h5 className="mt-2">Descripción General</h5>
          <p>{presupuesto.descripcion}</p>

          <h6 className="mt-2">Descripción de la Solicitud de Presupuesto</h6>
          <ol>
            {
              presupuesto.actividades_propuestas.map((actividad:any)=>
                  <li>
                    <p className="nm"><small>{actividad.actividad}</small></p>
                  </li>
                )
            }
          </ol>











          </div>
          <div className="col-sm-8">
            {
              // JSON.stringify(presupuesto.material_actividades)
            }

                  <h5 className="mt-2">Explosión de precios unitarios</h5>

                  {
                    presupuesto.entra_iguala && (
                        <React.Fragment>
                          SI ENTRA
                        </React.Fragment>
                      )
                  }

                  <table className="table table-sm" style={{fontSize:12}}>
                    <thead>
                      <tr>
                        <th scope="col">Tipo</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Unidad</th>
                        <th scope="col">Cantidad</th>
                        <th scope="col">P.U.</th>
                        <th scope="col">Costo</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      [...presupuesto.material_actividades].sort(ordenarPorTipo).map((material:any,index:number)=>


                      <tr>
                        <td className="v" style={{width:120}}>
                          <p className="nm">
                          {
                            // JSON.stringify(material.idunidad_medida)
                          }
                          <small><b>{tipoActividadLabel(material.tipo)}</b></small>
                          </p>
                        </td>
                        <td>
                          <p className="nm"><small>{material.descripcion}</small></p>
                        </td>
                        <td className="v" style={{width:120}}>
                          {material.idunidad_medida&&(<p className="nm"><small>{material.idunidad_medida.unidad_medida}</small></p>)}
                        </td>
                        <td className="v" style={{width:80}}>
                          <p className="nm text-right">{formatearNumero(parseFloat(material.cantidad)||0)}</p>
                        </td>
                        <td className="v" style={{width:80}}>
                          <p className="nm text-right">$ {formatearNumero(parseFloat(material.costo)||0)}</p>
                        </td>
                        <td className="v" style={{width:80}}>
                          <p className="nm text-right">$ {formatearNumero(parseFloat((material.costo)||0)*(parseFloat(material.cantidad)||0))}</p>
                        </td>
                      </tr>

                        )
                    }



                      <tr className="table-subs">
                        <td className="v" style={{width:100}} colSpan={5}>
                          <p className="nm text-right"><b>SUB TOTAL:</b></p>
                        </td>
                        <td className="v" style={{width:100}}>
                          <p className="nm text-right">$ {formatearNumero(suma_subtotal||0)}</p>
                        </td>
                      </tr>


                          <React.Fragment>

                            <tr className="table-subs">
                              <td className="v" style={{width:100}} colSpan={5}>
                                <p className="nm text-right"><b>SERVICIO:</b></p>
                              </td>
                              <td className="v" style={{width:100}}>
                                <p className="nm text-right">$ {formatearNumero(presupuesto.costo_servicio||0)}</p>
                              </td>
                            </tr>
                            <tr className="table-subs">
                              <td className="v" style={{width:100}} colSpan={5}>
                                <p className="nm text-right"><b>SERVICIO + SUB TOTAL:</b></p>
                              </td>
                              <td className="v" style={{width:100}}>
                                <p className="nm text-right">$ {formatearNumero(presupuesto.costo_servicio + (suma_subtotal||0))}</p>
                              </td>
                            </tr>
                            <tr className="table-impuestos">
                              <td className="v" style={{width:100}} colSpan={5}>
                                <p className="nm text-right"><b>IMPUESTOS:</b></p>
                              </td>
                              <td className="v" style={{width:100}}>
                                <p className="nm text-right">$ {formatearNumero((presupuesto.costo_servicio + (suma_subtotal||0))*.16)}</p>
                              </td>
                            </tr>
                            <tr className="table-total">
                              <td className="v" style={{width:100}} colSpan={5}>
                                <p className="nm text-right"><b>TOTAL:</b></p>
                              </td>
                              <td className="v" style={{width:100}}>
                                <p className="nm text-right">$ {formatearNumero( ((presupuesto.costo_servicio||0) + (suma_subtotal||0)) + (((presupuesto.costo_servicio||0) + (suma_subtotal||0))*.16)  )}</p>
                              </td>
                            </tr>


                          </React.Fragment>







                    </tbody>
                  </table>


          </div>
        </div>






        </Modal.Body>



        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" onClick={handleShow_ModalGenerarPagoServicio} type="button">GENERAR PAGO</Button>
        </Modal.Footer>

      </Modal>







  {
    show_ModalGenerarPagoServicio && (
      <ModalGenerarPagoServicio show={show_ModalGenerarPagoServicio} handleClose={handleClose_ModalGenerarPagoServicio} presupuesto={presupuesto} monto_total={suma_subtotal}/>
      )

  }




    </>
  );
}


export default ModalDetalleVisitaCobranza;