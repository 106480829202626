import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal, Form } from 'react-bootstrap';

import { toast } from 'react-toastify'


import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import Dialog from '../../services/Dialogs'


import { formatearFecha } from '../../services/funcionesPublicas'

import moment from 'moment'

const ModalAsistencias = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params

  const dialog = new Dialog()


  const [ fecha, setFecha ] = useState<any>( new Date() )



  const [ servicios, setAsistencias ] = useState<any>([])
  const [ cargando_, setSeEnvio ] = useState<boolean>(false)
  const cargarAsistencias = async(fecha:any)=>{
    setFecha(fecha)
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`asistencias/${tolerancia_mts}/${moment(fecha).format('YYYY-MM-DD')}`)
      setAsistencias(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      setAsistencias([])
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarAsistencias(new Date())
  }, []);


  const [ tolerancia_mts, setTolerancia ] = useState(40)






  const [ visita_seleccionada, setVisitaSeleccionada ] = useState<any>(null)

  const [showFaltas, setShowFaltas] = useState(false);

  const handleCloseFaltas = () => setShowFaltas(false);
  const handleShowFaltas = (visita:any) =>{
      setVisitaSeleccionada(visita)
      setShowFaltas(true);
  }

const [retardo, setRetardo] = useState<boolean>(false);
const [justificada, setJustificada] = useState<boolean>(false);
const [detalles, setDetalles] = useState<string>('');


  const enviar = async()=>{
    if(await dialog.confirm('¿Estás seguro?',{})){
      try{
        setSeEnvio(true)
        let res = await RequestService.getEndPointPost('reportar/faltas_retardos/'+visita_seleccionada.idtecnico?.uuid, { fecha : fecha, retardo : retardo, observaciones : detalles, justificada : justificada })
        if(res.data){
          toast.success('La falta/retardo fueron notificados al departamento de Recursos Humanos')        
          setDetalles('')
          setRetardo(false)
          setJustificada(false)
          handleCloseFaltas()
          cargarAsistencias(fecha)
        }
        setSeEnvio(false)
      }
      catch(e:any){
        setSeEnvio(false)
        console.log(e.response.data)
        toast.error(e.response.data+ '. Problemas al intentar notificar a Recursos Humanos')
      }
    }
  }

  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Asistencias</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">

                  <div className="mb-3">
                    <label htmlFor="x">Fecha de Servicio</label>
                    <DatePicker selected={fecha}   className="form-control"  dateFormat="yyyy-MM-dd" maxDate={new Date()} onChange={(date) => cargarAsistencias(date) } />
                  </div>
 
              {
                // JSON.stringify(fecha.toString())
              }

              



                          {  (!cargando_) && (

                              <>

                                   {
                                     servicios.length > 0 && (

                                       <>


                                              <div className="row">
                                                <div className="col-sm-6 align-self-center">
                                                  <h4>{formatearFecha(fecha)}</h4>
                                                </div>
                                                <div className="col-sm-6">
                                                        <label htmlFor="customRange1" className="form-label"><small>Tolerancia de {tolerancia_mts} metros distancia al registro</small></label>
                                                        <input type="range" className="form-range" id="customRange1" min={40} max={300} value={tolerancia_mts} onChange={(e:any)=>{setTolerancia(e.target.value)}} />
                                                </div>
                                              </div>


                                              {
                                                // JSON.stringify(asistencias)
                                              }




                                            <table className="table table-sm">
                                              <thead>
                                                <tr>
                                                  <th scope="col">Folio / Estatus</th> 
                                                  <th scope="col">Tipo</th>
                                                  <th scope="col">Técnico</th>
                                                  <th scope="col">Boutique / Sub Zona</th>
                                                  <th scope="col">Entrada / Salida</th>
                                                  
                                                  {
                                                    ["ROLE_ADMIN","ROLE_RECURSOS_HUMANOS"].indexOf(user.rol) > -1 && (
                                                        <th scope="col"></th>
                                                      )
                                                  }
                                                </tr>
                                              </thead>
                                              <tbody>{
                                                      servicios.map((visita:any)=>

                                                            <tr key={ Math.random().toString(36).substr(2, 9) }>
                                                              <td   className={`v pointer estatus_atencion_${visita.idestatus_atencion.id}`} style={{width:170}}>


                                                                <p className="nm"><b>{visita.folio}</b></p>
                                                                <p className="nm"><small>{visita.idestatus_atencion.estatus}</small></p>


                                                              </td> 
                                                              <td className={`v tipo_orden_${visita.idtipo_orden.id}`} style={{width:250}}>
                                                                <p className="nm"> 
                                                                <small>{visita.idtipo_orden.tipo_orden}</small></p>
                                                              </td>
                                                              <td className="v">
                                                                <p className="nm"><b>{visita.idtecnico.nombres}</b> <small> {visita.idtecnico.paterno} {visita.idtecnico.materno}</small></p>
                                                                <p className="nm">  <small><i className="fa fa-phone" /> {visita.idtecnico.celular} </small></p>
                                                              </td> 
                                                              <td className="v" style={{width:200}}>
                                                                <p className="nm">{visita.idboutique.nombre_boutique}</p>
                                                                <p className="nm"><small>{visita.idboutique.idsubarea.subarea}</small></p>
                                                              </td> 
                                                              <td className="v" style={{width:150}}>
                                                                {
                                                                  visita.fecha_inicio_servicio && (<p className="nm"> 
                                                                                                      { visita.distancia_entrada_es_cerca ? <i title={`A ${Math.round(visita.distancia_entrada)} MTS DEL LUGAR`} className="fa fa-circle" style={{color:'#00db00',textShadow:'0px 0px 6px #3de34b'}} /> : <i title={`A ${Math.round(visita.distancia_entrada)} MTS DEL LUGAR`} className="fa fa-circle" style={{color:'#f79400',textShadow:'0px 0px 6px #f57a00'}} /> }
                                                                                                      {' '}
                                                                                                      <small>{moment(visita.fecha_inicio_servicio).format('HH:mm')} hrs</small>
                                                                                                      </p>)
                                                                }
                                                                {
                                                                  visita.fecha_fin_servicio && (<p className="nm">
                                                                                                      { visita.distancia_salida_es_cerca ? <i title={`A ${Math.round(visita.distancia_salida)} MTS DEL LUGAR`} className="fa fa-circle" style={{color:'#00db00',textShadow:'0px 0px 6px #3de34b'}} /> : <i title={`A ${Math.round(visita.distancia_salida)} MTS DEL LUGAR`} className="fa fa-circle" style={{color:'#f79400',textShadow:'0px 0px 6px #f57a00'}} /> }
                                                                                                      {' '}
                                                                                                      <small>{moment(visita.fecha_fin_servicio).format('HH:mm')} hrs</small> 
                                                                                                      </p>)
                                                                }
                                                              </td> 
                                                              {
                                                                ["ROLE_ADMIN","ROLE_RECURSOS_HUMANOS","ROLE_RESPONSABLE_LIMPIEZA","ROLE_RESPONSABLE_TECNICOS","ROLE_RESPONSABLE_OPERACION"].indexOf(user.rol) > -1 && (
                                                                    <td className="v" style={{width:40}}>
                                                                        <button onClick={(e:any)=>{handleShowFaltas(visita)}} type="button" className="btn btn-sm btn-secondary"><i className="fa fa-calendar"/></button>
                                                                    </td> 
                                                                  )
                                                              }


                                                            </tr>


                                                        )

                                                    }
                                              </tbody>
                                            </table>

                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}



            </div>
          </div>
        </Modal.Body>
      </Modal>


      {
        ["ROLE_ADMIN","ROLE_RECURSOS_HUMANOS","ROLE_RESPONSABLE_LIMPIEZA","ROLE_RESPONSABLE_TECNICOS","ROLE_RESPONSABLE_OPERACION"].indexOf(user.rol) > -1 && (
            <React.Fragment>
              

                <Modal show={showFaltas} onHide={handleCloseFaltas}>
                  <Modal.Header closeButton>
                    <Modal.Title>Registro de Faltas o Retardos</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>

                      <h6>Registro de Incidencia para el día {moment(fecha).format('DD/MMM/YYYY').toUpperCase().replace('.','')}</h6>

 {
  // JSON.stringify(visita_seleccionada.idtecnico?.uuid)
 }

            <div className="mb-3">
              <Form.Check
                checked={retardo}
                onChange={(e:any)=>{setRetardo(e.target.checked)}}
                type="checkbox"
                id={`default-retardo`}
                label={`Marcar como retardo`}
              />
            </div>

            <div className="mb-3">
              <Form.Check
                checked={justificada}
                onChange={(e:any)=>{setJustificada(e.target.checked)}}
                type="checkbox"
                id={`default-justificada`}
                label={`Marcar como justificada`}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="razon" className="form-label">Detalles de la falta:</label>
              <textarea className="form-control" name="observaciones" value={detalles} onChange={(e:any)=>{setDetalles(e.target.value.toUpperCase())}} rows={3}></textarea>
            </div>






                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseFaltas}>
                      Cancelar
                    </Button>
                    <Button variant="success" onClick={enviar}>
                      Registrar
                    </Button>
                  </Modal.Footer>
                </Modal>


            </React.Fragment>
          )
      }




    </>
  );
}


export default ModalAsistencias;