import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Form, Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { Formik } from 'formik';
import * as yup from 'yup';

import Dialog from '../../services/Dialogs'


const ModalAgregarArticulo = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, proveedor } = params


  const dialog = new Dialog();


  const [ tipo_articulo, setTipo_articulo ] = useState<any>([])
  const cargarTipo_articulo = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/tipo_articulo`)
      setTipo_articulo(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarTipo_articulo()
    cargarTipo_unidad_medida()
  }, []);



  const [ tipo_unidad_medida, setTipo_unidad_medida ] = useState<any>([])
  const cargarTipo_unidad_medida = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/tipo_unidad_medida`)
      setTipo_unidad_medida(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


    



  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

      idtipo_articulo : yup.string().required(mensajes_validacion_generico),
      idunidad_medida : yup.string().required(mensajes_validacion_generico),
      articulo : yup.string().required(mensajes_validacion_generico),
      descripcion : yup.string().required(mensajes_validacion_generico),
      precio : yup.number().required(mensajes_validacion_generico),
      costo : yup.number().required(mensajes_validacion_generico),
      idproveedor : yup.string().required(mensajes_validacion_generico),


  });



const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `proveedore/agregar_articulo/${proveedor.uuid}` , { ...registro })

        if(res_){
          toast.success('MENSAJE')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Agregar Artículo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }



    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{


            idtipo_articulo : "", 
            idunidad_medida : "", 
            articulo : "", 
            descripcion : "", 
            precio : "", 
            costo : "", 
            idproveedor : proveedor ? proveedor.uuid : '', 


      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>

{
  // JSON.stringify(values)
}

                      <Form.Group className="mb-3" controlId="idtipo_articulo">
                        <Form.Label>Selecciona el Tipo de Artículo</Form.Label>
                        <Form.Select required name="idtipo_articulo" value={values.idtipo_articulo} onChange={(e)=>{ setFieldValue('idtipo_articulo', e.target.value ) }} isInvalid={!!errors.idtipo_articulo}>
                          <option disabled={values.idtipo_articulo!==''} value="">SELECCIONA UN TIPO DE ARTÍCULO</option>
                          {
                            tipo_articulo.map( (tipo:any) =>
                              <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>
                                        <option value={tipo.id}> {tipo.tipo_articulo}</option>
                              </React.Fragment>
                            )
                          }

                        </Form.Select>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid"> {errors.idtipo_articulo} </Form.Control.Feedback>
                      </Form.Group>


                      <Form.Group className="mb-3" controlId="idunidad_medida">
                        <Form.Label>Selecciona la Unidad de Medida del Artículo</Form.Label>
                        <Form.Select required name="idunidad_medida" value={values.idunidad_medida} onChange={(e)=>{ setFieldValue('idunidad_medida', e.target.value ) }} isInvalid={!!errors.idtipo_articulo}>
                          <option disabled={values.idunidad_medida!==''} value="">SELECCIONA UN TIPO DE UNIDAD</option>
                          {
                            tipo_unidad_medida.map( (unidad_medida:any) =>
                              <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>
                                        <option value={unidad_medida.id}> {unidad_medida.unidad_medida}</option>
                              </React.Fragment>
                            )
                          }

                        </Form.Select>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid"> {errors.idunidad_medida} </Form.Control.Feedback>
                      </Form.Group>



            <Form.Group   className="mb-3" controlId="articulo">
              <Form.Label>Nombre del Artículo</Form.Label>
              <Form.Control
                required
                name="articulo"
                value={values.articulo}
                onChange={(e)=>{ setFieldValue('articulo', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.articulo}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.articulo}</>} </Form.Control.Feedback>
            </Form.Group>


            <Form.Group   className="mb-3" controlId="descripcion">
              <Form.Label>Descripción del Artículo</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="descripcion"
                value={values.descripcion}
                onChange={(e)=>{ setFieldValue('descripcion', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.descripcion}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.descripcion}</>} </Form.Control.Feedback>
            </Form.Group>

 
            <div className="row">
              <div className="col-sm-6">
                <Form.Group   className="mb-3" controlId="precio">
                  <Form.Label>Precio al Cliente</Form.Label>
                  <Form.Control
                    required
                    name="precio"
                    value={values.precio}
                    onChange={(e)=>{ setFieldValue('precio', (e.target.value || "").toUpperCase() ) }}
                    isInvalid={!!errors.precio}
                  />
                  <Form.Control.Feedback type="invalid"> {<>{errors.precio}</>} </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-sm-6">
                <Form.Group   className="mb-3" controlId="costo">
                  <Form.Label>Precio del Proveedor</Form.Label>
                  <Form.Control
                    required
                    name="costo"
                    value={values.costo}
                    onChange={(e)=>{ setFieldValue('costo', (e.target.value || "").toUpperCase() ) }}
                    isInvalid={!!errors.costo}
                  />
                  <Form.Control.Feedback type="invalid"> {<>{errors.costo}</>} </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>


        </Form>
      )}
    </Formik>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalAgregarArticulo;