import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'



import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import Dialog from '../../services/Dialogs'

import moment from 'moment'

const ModalEditarRegistroIngreso = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, registro } = params


  const [ ingresos, setRegistos] = useState<any>([])

  const cargarRegistros = async() => {

    try{
      let res = await RequestService.getEndPoint('catalogo_ingresos')
      setRegistos(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar')
    }

  }

  const [ cuentas_bancarias, setCuentasBancarias] = useState<any>([])

  const cargarCuentas = async() => {

    try{
      let res = await RequestService.getEndPoint('catalogo_cuentas_bancarias')
      setCuentasBancarias(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar')
    }

  }

  const [ departamentos, setDepartamentos] = useState<any>([])

  const cargarDepartamentos = async() => {

    try{
      let res = await RequestService.getEndPoint('lista_deptos')
      setDepartamentos(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar')
    }

  }


  React.useEffect(() => {
    cargarRegistros()
    // cargarSucursales()
    // cargarDepartamentos()
    cargarCuentas()
  }, []);



  const [ sucursales, setSucursales] = useState<any>([])

  const cargarSucursales = async() => {

    try{
      let res = await RequestService.getEndPoint('sucursales')
      // console.log(res.data)
      setSucursales(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de Gerentes')
    }

  }


  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

      descripcion : yup.string(),
      fecha_movimiento : yup.string(),
      archivo: yup.mixed(), //.test('fileRequired', 'Campo obligatorio', (value) => !!value),
      monto : yup.number().required(mensajes_validacion_generico),
      // tipo_ingreso : yup.string().required(mensajes_validacion_generico),

      forma_pago : yup.string().required(mensajes_validacion_generico),
      idcuenta_banco : yup.string().required(mensajes_validacion_generico),


      referencia_movimiento : yup.string(),
      numero_pedido : yup.string(),
      contra_recibo : yup.string(),
      fecha_pedido : yup.string().nullable(),
      numero_factura : yup.string(),


          uuid_tipo_ingreso : yup.string().required(mensajes_validacion_generico),


  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (reg:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro de la información?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.create( `ingresos/editar_registro/${registro.uuid}` , { ...reg, fecha_movimiento :  reg.fecha_movimiento == '' ? '' : moment(reg.fecha_movimiento).format('YYYY-MM-DD') })

          if(res_){
            toast.success('El registro se creó correctamente')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
          setSeEnvio(false)
            toast.error("Problemas durante el registro")
            // console.log(e.response.data)
          } 
          
        }
       
  }


    const handleFileChange = (event:any,setFieldValue:any) => {
        const file = event.target.files[0];
        
        if (file) {
            const reader : any = new FileReader();

            reader.onloadend = function (e:any) {
                const base64 = reader.result.split(',')[1];
                // setBase64String(base64);
                setFieldValue('archivo',base64)
                // console.log(base64)
            };

            reader.readAsDataURL(file);
        }
    }



  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Registro Ingreso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }


    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

                    monto : registro.monto,
                    // tipo_ingreso : tipo_servicio,
                    archivo : "",
                    fecha_movimiento : new Date(),
                    descripcion : registro.descripcion,

                    forma_pago : registro.forma_pago, 
                    idcuenta_banco : registro.idcuenta_banco, 

                    uuid_tipo_ingreso : registro.uuid_tipo_ingreso, 

                    referencia_movimiento : registro.referencia_movimiento, 
                    numero_pedido : registro.numero_pedido, 
                    contra_recibo : registro.contra_recibo, 
                    fecha_pedido : registro.fecha_pedido === "0000-00-00" ? null : moment(registro.fecha_pedido).format('DD-MM-YYYY'), 
                    numero_factura : registro.numero_factura, 


      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>

{
  // JSON.stringify(registro.uuid)
}

            <Form.Group className="mb-3">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="descripcion"
                value={values.descripcion}
                onChange={(e)=>{ setFieldValue('descripcion', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.descripcion}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.descripcion}</>} </Form.Control.Feedback>
            </Form.Group>





            <Form.Group className="mb-3">
              <Form.Label>Fecha de Movimiento</Form.Label>
              <Form.Control
                required
                type="hidden"
                value={values.fecha_movimiento?values.fecha_movimiento.toString():''}
                isInvalid={!!errors.fecha_movimiento}
              />
                    <DatePicker selected={values.fecha_movimiento} className="form-control"  dateFormat="yyyy-MM-dd" maxDate={new Date()} onChange={(date) => setFieldValue('fecha_movimiento', date ) } />
              <Form.Control.Feedback type="invalid"> {<>{errors.fecha_movimiento}</>} </Form.Control.Feedback>
            </Form.Group>





                    <Form.Group className="mb-3" controlId="monto">
                      <Form.Label>Referencia del Movimiento</Form.Label>
                      <Form.Control 
                        name="referencia_movimiento"
                        value={values.referencia_movimiento} 
                        onChange={(e)=>{ setFieldValue('referencia_movimiento', e.target.value ) }}
                        isInvalid={!!errors.referencia_movimiento}
                        style={{textTransform:'uppercase'}}
                      />
                      <Form.Control.Feedback type="invalid"> {<>{errors.referencia_movimiento}</>} </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="monto">
                      <Form.Label>Número de Pedido</Form.Label>
                      <Form.Control 
                        name="numero_pedido"
                        value={values.numero_pedido} 
                        onChange={(e)=>{ setFieldValue('numero_pedido', e.target.value ) }}
                        isInvalid={!!errors.numero_pedido}
                        style={{textTransform:'uppercase'}}
                      />
                      <Form.Control.Feedback type="invalid"> {<>{errors.numero_pedido}</>} </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="monto">
                      <Form.Label>Contra Recibo</Form.Label>
                      <Form.Control 
                        name="contra_recibo"
                        value={values.contra_recibo} 
                        onChange={(e)=>{ setFieldValue('contra_recibo', e.target.value ) }}
                        isInvalid={!!errors.contra_recibo}
                        style={{textTransform:'uppercase'}}
                      />
                      <Form.Control.Feedback type="invalid"> {<>{errors.contra_recibo}</>} </Form.Control.Feedback>
                    </Form.Group>

                    <div className="row">
                      <div className="col-sm-6">
                          <div className="mb-3">
                            <Form.Label>Fecha del Pedido</Form.Label>
                            <DatePicker selected={ values.fecha_pedido === "0000-00-00" ? null : new Date()} className="form-control"  dateFormat="yyyy-MM-dd"   onChange={(date:any) =>{  setFieldValue('fecha_pedido',  date ? date : null ) }} />
                          </div>
                      </div>
                    </div>

                    <Form.Group className="mb-3" controlId="monto">
                      <Form.Label>Número de Factura</Form.Label>
                      <Form.Control 
                        required
                        name="numero_factura"
                        value={values.numero_factura} 
                        onChange={(e)=>{ setFieldValue('numero_factura', e.target.value ) }}
                        isInvalid={!!errors.numero_factura}
                        style={{textTransform:'uppercase'}}
                      />
                      <Form.Control.Feedback type="invalid"> {<>{errors.numero_factura}</>} </Form.Control.Feedback>
                    </Form.Group>







            <Form.Group className="mb-3">
              <Form.Label htmlFor="evidencia">Archivo de Evidencia</Form.Label>
              <Form.Control
                required
                type="file"
                // value={values.archivo}
                id="evidencia"
                accept=".pdf,.jpg,.jpeg,.png"
                onChange={(e)=>{  handleFileChange(e,setFieldValue) }}
                isInvalid={!!errors.archivo}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.archivo}</>} </Form.Control.Feedback>
            </Form.Group>





            <Form.Group className="mb-3">
              <Form.Label>Monto</Form.Label>
              <Form.Control
                type="text"
                required
                name="monto"
                value={values.monto}
                onWheel={event => { event.preventDefault(); }}
                onChange={(e)=>{ setFieldValue('monto', e.target.value ) }}
                isInvalid={!!errors.monto}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.monto}</>} </Form.Control.Feedback>
            </Form.Group>



              <div className="mb-3">
                <label className="form-label">Tipo de Ingreso</label>
                  <Form.Select aria-label="Selección de Tipo de Ingreso" required value={values.uuid_tipo_ingreso} onChange={(e)=>{ setFieldValue('uuid_tipo_ingreso',e.target.value) }} isInvalid={!!errors.uuid_tipo_ingreso} 
                  disabled={ ["5bd78dde-ecc1-480b-8cf3-690c764dc09e","90f1073d-a76e-44ad-ad5e-f2948beff7ae","09c17b5a-0fbe-44f3-8032-5af72cc86a44","f460f25e-9398-464b-b8e4-dfe3b0b4ef92","441da3b9-156f-4ad0-b00d-b4dab0f80bb7","b6de8f28-19a9-4ea4-add7-b831a3ca23e4"].indexOf(values.uuid_tipo_ingreso) > -1 }
                  >
                    <option>Selecciona el Tipo de Ingreso</option>
                    { ingresos.map((ingreso:any)=><option key={ Math.random().toString(36).substr(2, 9) } value={ingreso.uuid}>{ingreso.descripcion}</option>)}

                  </Form.Select>
                      <Form.Control.Feedback type="invalid"> {<>{errors.uuid_tipo_ingreso}</>} </Form.Control.Feedback>
              </div>






              <div className="mb-3">
                <label className="form-label">Cuenta Bancaria</label>
                  <Form.Select aria-label="Selección de Sucursal" required value={values.idcuenta_banco} onChange={(e)=>{ setFieldValue('idcuenta_banco',e.target.value) }} isInvalid={!!errors.idcuenta_banco} >
                    <option>Selecciona una sucursal</option>
                    { cuentas_bancarias.map((cuenta:any)=><option key={ Math.random().toString(36).substr(2, 9) } value={cuenta.uuid}>{cuenta.banco} - {cuenta.descripcion} </option>)}

                  </Form.Select>
                  <Form.Control.Feedback type="invalid"> {<>{errors.idcuenta_banco}</>} </Form.Control.Feedback>
              </div>





              <div className="mb-3">
                <label className="form-label">Forma de Pago</label>
                  <Form.Select aria-label="Selección de Sucursal" required value={values.forma_pago} onChange={(e)=>{ setFieldValue('forma_pago',e.target.value) }} isInvalid={!!errors.forma_pago} >
                    <option>Selecciona una Forma</option>
                    <option value={'EFECTIVO'}>EFECTIVO</option>
                    <option value={'TRANSFERENCIA'}>TRANSFERENCIA</option>
                    <option value={'CHEQUE'}>CHEQUE</option>
                    <option value={'DEPÓSITO EFECTIVO'}>DEPÓSITO EFECTIVO</option>

                  </Form.Select>
                  <Form.Control.Feedback type="invalid"> {<>{errors.forma_pago}</>} </Form.Control.Feedback>
              </div>





        </Form>
      )}
    </Formik>







        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalEditarRegistroIngreso