import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import Pagination from "../../services/Pagination";
import { Table, Form, Button } from 'react-bootstrap'



import ModalNuevoPagoProgramado from './ModalNuevoPagoProgramado'
import ModalReprogramarPagoProgramado from './ModalReprogramarPagoProgramado'

import ModalGenerarPago from './ModalGenerarPago'

import ModalReporteDePagosProgramados from './ModalReporteDePagosProgramados'


import ModalDetallesPagoProgramado from './ModalDetallesPagoProgramado'



import Dialog from '../../services/Dialogs'

import { SERVER_ } from  '../../config';


const ProgramacionPagos = () =>{

  const dialog = new Dialog();

  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`pagos_programados/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e:any){

      if (e.response?.status) {
        switch (e.response.status) {
          default:
            toast.error(e.response?.data)
        }
      }
      else{
        toast.error("Problemas con la busqueda")
      }
      setCargando(false)

    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true,true,true,false])


  const todos = () =>{
    setEstatus([true,true,true,true])
  }
  const ninguno = () =>{
    setEstatus([false,false,false,false])
  }


      React.useEffect(() => {
        busqueda()
      }, []);







  const [ show_ModalNuevoPagoProgramado, setModalNuevoPagoProgramado ] = useState<boolean>(false)
  const handleShow_ModalNuevoPagoProgramado = () => {
    setModalNuevoPagoProgramado(true)
  }
  const handleClose_ModalNuevoPagoProgramado = () => {
        busqueda()
    setModalNuevoPagoProgramado(false)
  }




const [ envio_server, setSeEnvio ] = useState<boolean>(false)
const cancelarPagoProgramado = async (event:any,uuid_pago_programado:string) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `pagos_programados/cancelar_pago/${uuid_pago_programado}` , { })

        if(res_){
          toast.warning('El Pago fué marcado como cancelado')
          setTimeout(()=>{
            busqueda()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){

          if (e.response?.status) {
            switch (e.response.status) {
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }
     
}


  const [ pago_programado_seleccionado, setPagoProgramadoSeleccionado ] = useState<any>(null)
  const [ show_ModalReprogramarPagoProgramado, setModalReprogramarPagoProgramado ] = useState<boolean>(false)
  const handleShow_ModalReprogramarPagoProgramado = (pago_programado:any) => {
    setPagoProgramadoSeleccionado(pago_programado)
    setModalReprogramarPagoProgramado(true)
  }
  const handleClose_ModalReprogramarPagoProgramado = () => {
        busqueda()
    setModalReprogramarPagoProgramado(false)
  }


  const [ show_ModalEditarPagoProgramado, setModalEditarPagoProgramado ] = useState<boolean>(false)
  const handleShow_ModalEditarPagoProgramado = (pago_programado:any) => {
    setPagoProgramadoSeleccionado(pago_programado)
    setModalEditarPagoProgramado(true)
  }
  const handleClose_ModalEditarPagoProgramado = () => {
        busqueda()
    setModalEditarPagoProgramado(false)
  }




  const [ show_ModalGenerarPago, setModalGenerarPago ] = useState<boolean>(false)
  const handleShow_ModalGenerarPago = (pago_programado:any) => {
    setPagoProgramadoSeleccionado(pago_programado)
    setModalGenerarPago(true)
  }
  const handleClose_ModalGenerarPago = () => {
        busqueda()
    setModalGenerarPago(false)
  }



  const [ show_ModalReporteDePagosProgramados, setModalReporteDePagosProgramados ] = useState<boolean>(false)
  const handleShow_ModalReporteDePagosProgramados = () => {
    setModalReporteDePagosProgramados(true)
  }
  const handleClose_ModalReporteDePagosProgramados = () => {
    setModalReporteDePagosProgramados(false)
  }




  const [ show_ModalDetallesPagoProgramado, setModalDetallesPagoProgramado ] = useState<boolean>(false)
  const handleShow_ModalDetallesPagoProgramado = (pago_programado:any) => {
    setPagoProgramadoSeleccionado(pago_programado)
    setModalDetallesPagoProgramado(true)
  }
  const handleClose_ModalDetallesPagoProgramado = () => {
        // busqueda()
    setModalDetallesPagoProgramado(false)
  }



      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Programación de Pagos de Servicios y Terceros</h4>
                    <hr/>


                        <div className="row">
                        
                            <div className="mb-2">
                                <label className="form-label">Búsqueda</label>
                                <div className="hstack gap-3">
                                  <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                                  <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                                  <div className="vr"></div>
                                  <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                                </div>
                            </div>
                            
                            <div>
                              <Form.Check inline name="group1" type="checkbox" label="PROGRAMADO" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_0`} />
                              <Form.Check inline name="group1" type="checkbox" label="REALIZADO" checked={arreglo_estatus[1]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
                              <Form.Check inline name="group1" type="checkbox" label="REPROGRAMADO" checked={arreglo_estatus[2]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}} id={`opciones_2`} />
                              <Form.Check inline name="group1" type="checkbox" label="CANCELADO" checked={arreglo_estatus[3]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}} id={`opciones_3`} />
                            </div>
                            
                          <ul className="list-inline">
                            <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalNuevoPagoProgramado()}}>Programar Pago</small></li>
                            <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalReporteDePagosProgramados()}}>Reporte de Pagos Programados</small></li>
                            <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                          </ul>

                        </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Folio</th>
                                              <th scope="col">Fecha</th>
                                              <th scope="col">Proveedor</th>
                                              <th scope="col"></th>
                                              <th scope="col">Estatus</th>
                                              <th scope="col">Monto</th>
                                              <th scope="col">Creó</th>
                                              <th scope="col">Tipo</th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (pago_programado:any,indice_pago_programado:number) =>
                                                  <tr key={indice_pago_programado} style={{fontSize:12}}>

                                                    <td className="v text-left pointer" onClick={(e:any)=>{handleShow_ModalDetallesPagoProgramado(pago_programado)}} style={{"width":"140px"}}>
                                                        <p className="nm"><b>{pago_programado.folio}</b></p>
                                                    </td>

                                                    <td className="v text-left" style={{"width":"160px"}}>
                                                            {
                                                              // JSON.stringify(pago_programado)
                                                            }
                                                            <small>{moment(pago_programado.fecha_programada).format('DD/MMMM/YYYY').toUpperCase().replace('.','')}</small>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"40px"}}>
                                                      { pago_programado.idproveedor && (<small>SI</small>) }
                                                    </td>
                                                    <td className="v text-left" >
                                                        <p className="nm">{pago_programado.proveedor}</p>
                                                        <p className="nm"><b>{pago_programado.detalles_pago}</b></p>
                                                    </td>
                                                    <td className={`v text-left status-${pago_programado.estatus_pago.toLowerCase()}`} style={{"width":"150px"}}>
                                                        <p className="nm"><small><b>
                                                        { pago_programado.estatus_pago === 'PROGRAMADO' && (<>🗓️</>) }
                                                        { pago_programado.estatus_pago === 'REPROGRAMADO' && (<>🔁</>) }
                                                        { pago_programado.estatus_pago === 'CANCELADO' && (<>🚫</>) }
                                                        { pago_programado.estatus_pago === 'REALIZADO' && (<>✅</>) }
                                                        {' '}
                                                        {pago_programado.estatus_pago}
                                                        </b></small></p>
                                                    </td>
                                                    <td className="v text-right" style={{"width":"90px"}}>
                                                        <small>$ {pago_programado.monto}</small>
                                                    </td>
                                                    <td className="v text-right" style={{"width":"150px"}}>
                                                        <p className="nm"><small><b>{pago_programado.nombres}</b></small></p>
                                                        <small>{pago_programado.paterno} {pago_programado.materno}</small>
                                                    </td>
                                                    <td className="v text-right" style={{"width":"150px"}}>
                                                        <small>{pago_programado.tipo_ingreso_egreso}</small>
                                                    </td>


                                                    {

                                                     !pago_programado.idegreso && (
                                                          <>
                                                              <td className="v text-left" style={{"width":"40px"}}>
                                                              {
                                                                // JSON.stringify(pago_programado.idegreso)
                                                              }
                                                                  {
                                                                    ["PROGRAMADO","REPROGRAMADO"].indexOf(pago_programado.estatus_pago) > -1 && (<Button variant="info" onClick={(e:any)=>{handleShow_ModalGenerarPago(pago_programado)}} className="btn-xs"><i className="fa fa-money"/></Button>)
                                                                  }
                                                              </td>
                                                              <td className="v text-left" style={{"width":"40px"}}>
                                                                  {
                                                                    ["PROGRAMADO","REPROGRAMADO"].indexOf(pago_programado.estatus_pago) > -1 && (<Button variant="warning" onClick={(e:any)=>{handleShow_ModalReprogramarPagoProgramado(pago_programado)}} className="btn-xs"><i className="fa fa-exchange"/></Button>)
                                                                  }
                                                              </td>
                                                              <td className="v text-left" style={{"width":"40px"}}>
                                                                  {
                                                                    ["PROGRAMADO","REPROGRAMADO"].indexOf(pago_programado.estatus_pago) > -1 && (<Button variant="warning" onClick={(e:any)=>{handleShow_ModalEditarPagoProgramado(pago_programado)}} className="btn-xs"><i className="fa fa-edit"/></Button>)
                                                                  }
                                                              </td>
                                                              <td className="v text-left" style={{"width":"40px"}}>
                                                                  {
                                                                    ["PROGRAMADO","REPROGRAMADO"].indexOf(pago_programado.estatus_pago) > -1 && (<Button variant="danger" onClick={(e:any)=>{cancelarPagoProgramado(e,pago_programado.uuid_pago_programado)}} className="btn-xs"><i className="fa fa-trash"/></Button>)
                                                                  }
                                                              </td>
                                                          </>
                                                        )

                                                    }


                                                    {

                                                      pago_programado.idegreso && (
                                                          <td colSpan={4} className="text-center v">
                                                                        {
                                                                          (pago_programado.idegreso.archivo !== null && pago_programado.idegreso.archivo !== '') && (
                                                                          <a target="_blank" href={`${SERVER_}contabilidad/archivo/${pago_programado.idegreso.uuid}`}><i className="fa fa-file-o"/></a>
                                                                          )
                                                                        }
                                                          </td>
                                                        )

                                                    }
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}

                    
                </div>
              </div>

            </div>


              {
                show_ModalNuevoPagoProgramado && (
                  <ModalNuevoPagoProgramado show={show_ModalNuevoPagoProgramado} handleClose={handleClose_ModalNuevoPagoProgramado}  />
                  )

              }


              {
                show_ModalReprogramarPagoProgramado && (
                  <ModalReprogramarPagoProgramado show={show_ModalReprogramarPagoProgramado} handleClose={handleClose_ModalReprogramarPagoProgramado} pago_programado={pago_programado_seleccionado} reprogramar={true} />
                  )

              }

              {
                show_ModalEditarPagoProgramado && (
                  <ModalReprogramarPagoProgramado show={show_ModalEditarPagoProgramado} handleClose={handleClose_ModalEditarPagoProgramado} pago_programado={pago_programado_seleccionado} reprogramar={false} />
                  )

              }


              {
                show_ModalGenerarPago && (
                  <ModalGenerarPago show={show_ModalGenerarPago} handleClose={handleClose_ModalGenerarPago} pago_programado={pago_programado_seleccionado}  />
                  )

              }


              {
                show_ModalReporteDePagosProgramados && (
                  <ModalReporteDePagosProgramados show={show_ModalReporteDePagosProgramados} handleClose={handleClose_ModalReporteDePagosProgramados}  />
                  )

              }


              {
                show_ModalDetallesPagoProgramado && (
                  <ModalDetallesPagoProgramado show={show_ModalDetallesPagoProgramado} handleClose={handleClose_ModalDetallesPagoProgramado} pago_programado={pago_programado_seleccionado} />
                  )

              }


          </>

      );
  

}

export default ProgramacionPagos
