import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Form, Button, Modal, OverlayTrigger, Badge } from 'react-bootstrap';

import { toast } from 'react-toastify'

import moment from 'moment'

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import { agregarDias, obtenerRangoFechasArreglo, obtenerDiaSemana, diasDeLaSemana, contarNoBaseODescanso } from '../../services/funcionesPublicas'

import Popover from 'react-bootstrap/Popover';





import Dialog from '../../services/Dialogs'


import ModalAsignarTecnicoBoutique from './ModalAsignarTecnicoBoutique'
import ModalAsignarEquiposCritiosVisita from './ModalAsignarEquiposCritiosVisita'

import ModalPreviewCalendarioZonaProgramacion from './ModalPreviewCalendarioZonaProgramacion'

import ModalAsignarAtencionPresupuestoTecnico from './ModalAsignarAtencionPresupuestoTecnico'


import ModalPreviewCalendarioCompleto from './ModalPreviewCalendarioCompleto'


import './configuracion.scss'



const ModalNuevoCalendarioActividades = ( params : any ) => {

    const user = AuthService.getCurrentUser()

    const { show, handleClose } = params

    const [ fecha_fin, setFechaFin ] = useState<any>(new Date(agregarDias(new Date(),30)))
    const [ fecha_inicio, setFechaInicio ] =  useState<any>(new Date())



    const [ fechas, setFechas ] = useState<any>([])

    const [ calendario_generado, setCalendarioGenerado ] = useState<boolean>(false)
    const [ fechas_generadas, setFechasGeneradas ] = useState<boolean>(false)






  const [ configuracion, setConfiguracion ] = useState<any>([])
  const cargarConfiguracion = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`configuracion_atencion/${moment(fecha_inicio).format('YYYY-MM-DD')}/${moment(fecha_fin).format('YYYY-MM-DD')}`)
      setConfiguracion(response.data)
      setFechasGeneradas(true)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }




const cambiarOpcionesFecha = (value:any, index_areas:number, index_subareas:number, index_tecnicos:number, index_dia:number ) =>{

  let conf = {...configuracion}

  conf.areas[index_areas].subareas[index_subareas].tecnicos[index_tecnicos].fechas[index_dia].idboutique = value
  conf.areas[index_areas].subareas[index_subareas].tecnicos[index_tecnicos].fechas[index_dia].equipos_criticos = []

  setConfiguracion(conf)

}





const [ tecnicos_seleccionado, setTecnicos ] = useState<any>(null)
const [ boutique_seleccionada, setBoutique ] = useState<any>(null)
const [ index_areas_seleccionada, setIndexAreas ] = useState<any>(null)
const [ index_subareas_seleccionada, setIndexSubareas ] = useState<any>(null)
const [ index_tecnicos_seleccionada, setIndexTecnicos ] = useState<any>(null)
const [ fechas_seleccionadas, setFechasSeleccionadas ] = useState<any>(null)
// const [ dia_seleccionado, setDia ] = useState<any>('')




const resetearFechas = (index_areas:number,index_subareas:number,index_tecnicos:number)=>{
  let fechas = configuracion.areas[index_areas].subareas[index_subareas].tecnicos[index_tecnicos].fechas
  for(let fecha of fechas){
      fecha.idboutique = "BASE"
  }
  let c = {...configuracion}
  c.areas[index_areas].subareas[index_subareas].tecnicos[index_tecnicos].fechas = fechas
  setConfiguracion(c)

}




  const [ show_ModalAsignarTecnicoBoutique, setModalAsignarTecnicoBoutique ] = useState<boolean>(false)
  const handleShow_ModalAsignarTecnicoBoutique = (tecnicos:any,boutique:any,index_areas:number,index_subareas:number,fechas:any) => {
    setTecnicos(tecnicos)
    setBoutique(boutique)
    setIndexAreas(index_areas)
    setIndexSubareas(index_subareas)
    setFechasSeleccionadas(fechas)
    // setDia(tecnico_seleccionado)
    setModalAsignarTecnicoBoutique(true)
  }
  const handleClose_ModalAsignarTecnicoBoutique = () => {
    setModalAsignarTecnicoBoutique(false)
  }





                  

const dialog = new Dialog();

const [ envio_server, setSeEnvio ] = useState<boolean>(false)
const crearCalendario = async (event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `calendario/tecnicos/crear` , {configuracion: configuracion, fecha_inicio : moment(fecha_inicio).format('YYYY-MM-DD'), fecha_fin : moment(fecha_fin).format('YYYY-MM-DD') } )

        if(res_){
          toast.success('Consulta_correcta')
          setTimeout(()=>{
            // handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}

const guardarCalendario = async (event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `calendario/tecnicos/guardar` , { configuracion: configuracion, fecha_inicio : moment(fecha_inicio).format('YYYY-MM-DD'), fecha_fin : moment(fecha_fin).format('YYYY-MM-DD'), preview_calendario : configuracion } )

        if(res_){
          toast.success('Consulta_correcta')
          setTimeout(()=>{
            // handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}

  const cargarConfiguracionGuardada = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`calendario/configuracion_previa`)
      let respuesta = response.data
      // console.log(respuesta)
      setFechaInicio(new Date(respuesta.fecha_inicio.replace(/-/g, '\/')))
      setFechaFin(new Date(respuesta.fecha_fin.replace(/-/g, '\/')))


      setConfiguracion(respuesta.preview_calendario)
      // setFechaFin(new Date(respuesta.fecha_inicio))
      // setFechaInicio(new Date(respuesta.fecha_fin))
      setCalendarioGenerado(true)
      setFechasGeneradas(true)
      // setConfiguracion({configuracion:respuesta.preview_calendario})
      // setFechasGeneradas(true)

    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


const publicarABoutiques = async (event:any,estado:boolean) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `calendario/preview_boutique` , { estado : estado})

        if(res_){
          toast.success('Consulta_correcta')
          setTimeout(()=>{
            // cargar()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}






const recargarSubarea = async (event:any,area:any,index_areas:number,index_subareas:number) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.getEndPoint( `calendario/recargar/preview_subarea/${area.uuid}` )
        let c = {...configuracion}
        c.areas[index_areas].subareas[index_subareas] = res_.data
        setConfiguracion(c)

        if(res_){
          toast.success('Consulta_correcta')
          setTimeout(()=>{
            // cargar()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}




  const [ show_ModalPreviewCalendarioZonaProgramacion, setModalPreviewCalendarioZonaProgramacion ] = useState<boolean>(false)
  const handleShow_ModalPreviewCalendarioZonaProgramacion = (index_areas:number) => {
    setIndexAreas(index_areas)
    setModalPreviewCalendarioZonaProgramacion(true)
  }
  const handleClose_ModalPreviewCalendarioZonaProgramacion = () => {
    setModalPreviewCalendarioZonaProgramacion(false)
  }






  const [ show_ModalAsignarEquiposCritiosVisita, setModalAsignarEquiposCritiosVisita ] = useState<boolean>(false)
  const handleShow_ModalAsignarEquiposCritiosVisita = (index_areas:number,index_subareas:number,index_tecnicos:number) => {
    setIndexAreas(index_areas)
    setIndexSubareas(index_subareas)
    setIndexTecnicos(index_tecnicos)
    setModalAsignarEquiposCritiosVisita(true)
  }
  const handleClose_ModalAsignarEquiposCritiosVisita = () => {
    setModalAsignarEquiposCritiosVisita(false)
  }



const contarVisitas = (uuid_boutique:string, visitasTecnicos:any)=> {
    return visitasTecnicos.reduce((total:any, tecnico:any) => {
        return total + tecnico.fechas.reduce((contador:any, fecha:any) => {
            return contador + (fecha.idboutique === uuid_boutique ? 1 : 0);
        }, 0);
    }, 0);
}


  const configuracionSubareaLista = (subarea:any)=>{
    let states = []

    for(let boutique of subarea.boutiques){
      states.push(
          // contarVisitas(boutique.uuid_boutique,subarea.tecnicos) >== boutique.
        contarVisitas(boutique.uuid_boutique,subarea.tecnicos)>=boutique.numero_visitas
        )
    }

    // contarVisitas(boutique.uuid_boutique,subarea.tecnicos)

    return states.every(valor => valor === true)

  }
  

  const [ show_ModalAsignarAtencionPresupuestoTecnico, setModalAsignarAtencionPresupuestoTecnico ] = useState<boolean>(false)
  const handleShow_ModalAsignarAtencionPresupuestoTecnico = (tecnicos:any,boutique:any,index_areas:number,index_subareas:number,fechas:any) => {
    setTecnicos(tecnicos)
    setBoutique(boutique)
    setIndexAreas(index_areas)
    setIndexSubareas(index_subareas)
    setFechasSeleccionadas(fechas)

    setModalAsignarAtencionPresupuestoTecnico(true)
  }
  const handleClose_ModalAsignarAtencionPresupuestoTecnico = () => {
    setModalAsignarAtencionPresupuestoTecnico(false)
  }




  const [ show_ModalPreviewCalendarioCompleto, setModalPreviewCalendarioCompleto ] = useState<boolean>(false)
  const handleShow_ModalPreviewCalendarioCompleto = () => {
    setModalPreviewCalendarioCompleto(true)
  }
  const handleClose_ModalPreviewCalendarioCompleto = () => {
    setModalPreviewCalendarioCompleto(false)
  }



  return (
    <>
      <Modal show={show} fullscreen={true} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton style={{background : '#0480be',color:'white'}}>
          <Modal.Title>Nuevo Calendario de Actividades de Mantenimiento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(configuracion)
          }


          {
            !fechas_generadas && (

                <>

                    <div className="row">
                      <div className="col-sm-6">

                            <div className="mb-3">
                              <Form.Label>Fecha de Inicio</Form.Label>
                              <DatePicker selected={fecha_inicio} className="form-control"  dateFormat="yyyy-MM-dd" onChange={(date) =>{ setFechaInicio(date); setFechaFin(new Date(agregarDias(date,30))) }} />
                            </div>

                      </div>
                      <div className="col-sm-6">

                            <div className="mb-3">
                              <Form.Label>Fecha de Fin</Form.Label>
                              <DatePicker selected={fecha_fin} className="form-control"  dateFormat="yyyy-MM-dd" minDate={fecha_inicio} onChange={(date) =>{ setFechaFin(date); }} />
                            </div>

                      </div>
                    </div>


                    <button type="button" onClick={(e:any)=>{cargarConfiguracion()}} className="btn btn-primary">Generar Calendario</button>
                    {' '}
                    <button type="button" onClick={(e:any)=>{cargarConfiguracionGuardada()}} className="btn btn-info"><i className="fa fa-database"/> Cargar Calendario</button>

                </>

            )
          }




          {
            fechas_generadas && (

                <>
                    <div className="mb-3">
                      
                      <button type="button" onClick={(e:any)=>{setFechasGeneradas(false)}} className="btn btn-primary">Cambiar Fechas</button>
                      {' '}
                      <button type="button" onClick={(e:any)=>{cargarConfiguracion()}} className="btn btn-mantenimiento">Generar Calendario <i className="fa fa-refresh"/></button>
                      {' '}
                      <button type="button" onClick={(e:any)=>{crearCalendario(e)}} className="btn btn-success">Crear Calendario <i className="fa fa-calendar"/></button>
                      {' '}
                      <button type="button" onClick={(e:any)=>{handleShow_ModalPreviewCalendarioCompleto()}} className="btn btn-mantenimiento">Imprimir Preview  <i className="fa fa-print"/></button>
                      {' '}
                      <button type="button" onClick={(e:any)=>{publicarABoutiques(e,true)}} className="btn btn-mantenimiento">PUBLICAR <i className="fa fa-check"/></button>
                      {' '}
                      <button type="button" onClick={(e:any)=>{cargarConfiguracionGuardada()}} className="btn btn-mantenimiento"><i className="fa fa-database"/> Últimos Cambios</button>
                      {' '}
                      <button type="button" onClick={(e:any)=>{guardarCalendario(e)}} className="btn btn-primary">Guardar Calendario <i className="fa fa-save"/></button>


                    </div>


                  {
                    // JSON.stringify(configuracion)
                  }

                      <div className="table-responsive-">
                        <table className="table">
                          <thead className="table-dark">
                            <tr>
                              <th scope="col">Zona</th>
                            </tr>
                          </thead>
                          <tbody>
                          {
                            configuracion.areas.map((area:any,index_areas:number)=>

                              <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>
                            
                            <tr>
                              <th className="table-light" scope="row" style={{width:150}}>
                                <h4 onClick={(e:any)=>{handleShow_ModalPreviewCalendarioZonaProgramacion(index_areas)}} className="pointer nm">{area.area} <i className="fa fa-calendar"/></h4>
                              </th>
                            </tr> 
                            <tr>
                              <td>
                                {
                                  area.subareas.map((subarea:any,index_subareas:number)=>

                                        <table className={`table table-borderless  ${ index_subareas % 2 ? 'table-light-' : '' }`} key={ Math.random().toString(36).substr(2, 9) } style={{margin:0,padding:0,width:'100%', background:(configuracionSubareaLista(subarea)?'#86dac5':'')}}>
                                          <tbody>
                                            <tr>
                                              <td className="v" style={{width:250}}>
                                                <h6 className="nm text-center">{subarea.subarea} <i onClick={(e:any)=>{recargarSubarea(e,subarea,index_areas,index_subareas)}} className="fa fa-refresh"/> </h6>
                                              </td>
                                              <td style={{width:'100%'}}>
                                              
{
  // JSON.stringify((subarea))
}                       
{
  // JSON.stringify(configuracionSubareaLista(subarea))
}


                                                {
                                                  subarea.tecnicos.map((tecnico:any,index_tecnicos:number)=>

                                                   <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>

                                                    {
                                                    //   <p style={{fontSize:14}}>{
                                                    //   JSON.stringify(tecnico.fechas)
                                                    // }</p>
                                                    }


                                                                <table className="table table-borderless -" key={ Math.random().toString(36).substr(2, 9) } style={{margin:0,padding:0,width:'100%'}}>
                                                                  <tbody>
                                                                    <tr>
                                                                      <td className="v" style={{verticalAlign:'middle',width:200}} rowSpan={2}>
                                                                        {
                                                                          // JSON.stringify(tecnico)
                                                                        }
                                                                        <p className="nm">{tecnico.nombres}</p>
                                                                        <small><b>{tecnico.paterno} {tecnico.materno}</b> 
                                                                        <br/>
                                                                            {' '}
                                                                            {
                                                                              // contarNoBaseODescanso(tecnico.fechas)
                                                                            }
                                                                            { contarNoBaseODescanso(tecnico.fechas) > 0 && (<i onClick={(e:any)=>{handleShow_ModalAsignarEquiposCritiosVisita(index_areas,index_subareas,index_tecnicos)}} className="fa fa-gear pointer"/>)}
                                                                            {' '}
                                                                            <i onClick={(e:any)=>{resetearFechas(index_areas,index_subareas,index_tecnicos)}} className="fa fa-trash pointer"/> 
                                                                        </small>
                                                                      </td>
                                                                      {
                                                                        tecnico.fechas.map((fecha:any)=>

                                                                            <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>
                                                                              <td className={` ${ fecha.idboutique !== '' ? 'asignado_mantenimiento' :'' } ${ fecha.idboutique === 'BASE' ? 'base' : '' }  ${ fecha.idboutique === 'DESCANSO' ? 'descanso' : '' } `}>
                                                                                <p className="nm text-center" style={{fontSize:11}}><b>{ moment(fecha.fecha).format('DD') }</b> <small>{obtenerDiaSemana(fecha.fecha)}</small> </p>
                                                                                {
                                                                                  // '???'
                                                                                }

                                                                              </td>
                                                                            </React.Fragment>

                                                                          )
                                                                      }
                                                                    </tr>
                                                                    <tr>
                                                                      {
                                                                        tecnico.fechas.map((fecha:any,index_dia:number)=>

                                                                            <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>
                                                                              <td className={` ${ fecha.idboutique !== '' ? 'asignado_mantenimiento' :'' } ${ fecha.idboutique === 'BASE' ? 'base' : '' } ${ fecha.idboutique === 'DESCANSO' ? 'descanso' : '' } `} >



                                                                                  <select className="form-select form-select-sm" style={{width:'100px'}} value={fecha.idboutique} onChange={(e:any)=>{ cambiarOpcionesFecha(e.target.value, index_areas, index_subareas, index_tecnicos, index_dia ) }}>
                                                                                    
                                                                                    {subarea.boutiques.map((boutique:any,keyb:number)=>(
                                                                                        <React.Fragment key={keyb}>
                                                                                            <option  value={boutique.uuid_boutique}>{boutique.acronimo_folio}</option>
                                                                                        </React.Fragment>
                                                                                      ))
                                                                                  }

                                                                                    <option value="DESCANSO">DESCANSO</option>
                                                                                    <option value="BASE">BASE</option>

                                                                                  </select>

                                                                                  {
                                                                                    // JSON.stringify(fecha)
                                                                                  }

                                                                              </td>
                                                                            </React.Fragment>

                                                                          )
                                                                      }
                                                                    </tr>
                                                                  </tbody>
                                                                </table>


                                                     </React.Fragment>


                                                    )
                                                }




                                              </td>

                                            </tr>
                                            <tr>
                                              <td colSpan={1}>
                                              </td>
                                              <td colSpan={1}>
                                                {
                                                  // JSON.stringify(subarea.boutiques)
                                                }
                                                {
                                                  subarea.boutiques.map((boutique:any)=>(<>

                                                          {
                                                            <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>
                                                          
                                                            <Button variant="outline-dark" size="sm" >
                                                              {boutique.nombre_boutique} <small>{boutique.acronimo_folio}</small> 
                                                              <br/>
                                                              <Badge onClick={(e:any)=>{handleShow_ModalAsignarTecnicoBoutique(subarea.tecnicos, boutique, index_areas,index_subareas,[])}} bg="secondary">VISITAS: {boutique.numero_visitas}</Badge>
                                                              {' '}
                                                              <Badge bg={`${contarVisitas(boutique.uuid_boutique,subarea.tecnicos)>=boutique.numero_visitas?'success':'warning'}`}>PROGRAMADAS: {contarVisitas(boutique.uuid_boutique,subarea.tecnicos)} </Badge>
                                                              {' '}
                                                              <Badge bg="light">EQC: {boutique.equipos_criticos.length}</Badge>
                                                              {' '}
                                                              { boutique.presupuestos.length > 0 && (<Badge onClick={(e:any)=>{handleShow_ModalAsignarAtencionPresupuestoTecnico(subarea.tecnicos, boutique, index_areas,index_subareas,[])}} bg="danger">PRESUPUESTOS: {boutique.presupuestos.length}</Badge>)}
                                                            </Button>
                                                            {' '}

                                                            </React.Fragment>
                                                          }

                                                    </>))
                                                }
                                              </td>
                                            </tr>

                                          </tbody>
                                        </table>




                                    )
                                }
                              </td> 
                            </tr>

                              </React.Fragment>



                              )
                          }

                          </tbody>
                        </table>
                      </div>
                    

                </>

            )
          }












        </Modal.Body>
        
        {
          // calendario_generado && (
          //       <Modal.Footer>
          //         <Button variant="secondary" onClick={handleClose}>
          //           Cancelar
          //         </Button>
          //         <Button variant="success" type="button">Ok</Button>
          //       </Modal.Footer>
          //   )
        }
        
        </Modal>





  {
    show_ModalAsignarTecnicoBoutique && (
      <ModalAsignarTecnicoBoutique show={show_ModalAsignarTecnicoBoutique} handleClose={handleClose_ModalAsignarTecnicoBoutique} 
        setConfiguracion={setConfiguracion}
        configuracion={configuracion}
        tecnicos_seleccionado={tecnicos_seleccionado}
        boutique_seleccionada={boutique_seleccionada}
        index_areas_seleccionada={index_areas_seleccionada}
        index_subareas_seleccionada={index_subareas_seleccionada}
        fechas_seleccionadas={fechas_seleccionadas}
       />
      )

  }






  {
    show_ModalAsignarAtencionPresupuestoTecnico && (
      <ModalAsignarAtencionPresupuestoTecnico show={show_ModalAsignarAtencionPresupuestoTecnico} handleClose={handleClose_ModalAsignarAtencionPresupuestoTecnico} 
        setConfiguracion={setConfiguracion}
        configuracion={configuracion}
        fecha_fin={fecha_fin}
        fecha_inicio={fecha_inicio}
        tecnicos_seleccionado={tecnicos_seleccionado}
        boutique_seleccionada={boutique_seleccionada}
        index_areas_seleccionada={index_areas_seleccionada}
        index_subareas_seleccionada={index_subareas_seleccionada}
        fechas_seleccionadas={fechas_seleccionadas}
       />
      )

  }


  {
    show_ModalAsignarEquiposCritiosVisita && (
      <ModalAsignarEquiposCritiosVisita show={show_ModalAsignarEquiposCritiosVisita} handleClose={handleClose_ModalAsignarEquiposCritiosVisita} 
        index_areas={index_areas_seleccionada}
        index_subareas={index_subareas_seleccionada}
        index_tecnicos={index_tecnicos_seleccionada}
        setConfiguracion={setConfiguracion}
        configuracion={configuracion}/>
      )

  }



  {
    show_ModalPreviewCalendarioZonaProgramacion && (
      <ModalPreviewCalendarioZonaProgramacion show={show_ModalPreviewCalendarioZonaProgramacion} handleClose={handleClose_ModalPreviewCalendarioZonaProgramacion} 
        configuracion={configuracion}
        index_areas={index_areas_seleccionada}
       />
      )

  }












  {
    show_ModalPreviewCalendarioCompleto && (
      <ModalPreviewCalendarioCompleto show={show_ModalPreviewCalendarioCompleto} handleClose={handleClose_ModalPreviewCalendarioCompleto}  />
      )

  }








    </>
  );
}


export default ModalNuevoCalendarioActividades;