import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import { Tabs, Tab } from 'react-bootstrap'

import moment from 'moment'
import 'moment/locale/es'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"; // needed
import listPlugin from '@fullcalendar/list'; //For List View
import esLocale from '@fullcalendar/core/locales/es';



import ModalDetalleEvento from './ModalDetalleEvento'


import { calcularDiasPasados, obtenerDiaSemana } from '../../services/funcionesPublicas'



import './MiCalendario.scss'

const MiCalendario = () =>{

  const [visitas, setBookings] = useState<any>([ ])




  const [ mis_tareas, setMis_tareas ] = useState<any>([])
  const cargarMis_tareas = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`tecnicos/mis_tareas_periodo`)
      // setMis_tareas(response.data)
      setBookings(response.data)


    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarMis_tareas()
  }, []);



    



  const [ show_ModalDetalleEvento, setModalDetalleEvento ] = useState<boolean>(false)
  const [ evento_seleccionado, setEventoSeleccionado ] = useState<any>({})
  const handleShow_ModalDetalleEvento = (info:any) => {
    setEventoSeleccionado(info.event)
    setModalDetalleEvento(true)
  }
  const handleClose_ModalDetalleEvento = () => {
    setModalDetalleEvento(false)
  }


 

      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                 <h3 className="mt-2">Mi Calendario</h3>
                 <p className="">Tareas Programadas</p>


                    <Tabs
                      defaultActiveKey="calendario"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="calendario" title="Calendario">

                                 {
                                  // JSON.stringify(visitas)
                                 }

                                      <FullCalendar
                                      locale={esLocale}
                                        plugins={[dayGridPlugin, interactionPlugin, listPlugin ]}
                                        headerToolbar={{
                                          // left: 'prev,next today dayGridDay dayGridMonth',
                                          left: 'dayGridDay dayGridMonth',
                                          // center: 'title',
                                          center: '',
                                          right: ''
                                          // right: 'dayGridMonth,dayGridWeek,dayGridDay,listWeek'
                                        }}
                                        initialView="dayGridMonth"
                                        
                                        eventClick={handleShow_ModalDetalleEvento}
                                        events={visitas}
                                      />


                      </Tab>
                      <Tab eventKey="lista" title="Lista">
                        

                          {
                            // JSON.stringify(visitas)
                          }

                          {
                            visitas.length === 0 && (
                                <div className="alert alert-secondary" role="alert">
                                  No tienes asignadas visitras aún
                                </div>
                              )
                          }





                          {
                            visitas.length > 0 && (

                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th>Folio / Estatus</th>
                                          <th>Tipo</th>
                                          <th>Fecha de Servicio</th>
                                          <th>Boutique / Dirección</th>
                                          <th>Indicaciones para la Actividad</th>
                                        </tr>
                                      </thead>
                                      <tbody>

                                        {
                                          visitas.map((item:any, index:number) => (
                                          <tr key={index} className="pointer" onClick={(e:any)=>{handleShow_ModalDetalleEvento({event:{extendedProps:{metadata:item.metadata}}})}}>
                                            <td className={`v estatus_atencion_${item.metadata.idestatus_atencion.id}`}  style={{width:140}}>
                                                              <p className="nm"><b>{item.metadata.folio}</b></p>
                                                              <p className="nm"><small>{item.metadata.idestatus_atencion.estatus}</small></p>

                                            </td>
                                            <td className={`v tipo_orden_${item.metadata.idtipo_orden.id}`} style={{width:200}}>
                                              <p className="nm"><b>{item.metadata.idtipo_orden.tipo_orden}</b></p>
                                            </td>
                                            <td className="v">
                                                              <p className="nm"><b><small>{moment(item.metadata.fecha_programada).format('DD [DE] MMMM [DE] YYYY').toUpperCase()}</small></b></p>
                                                              <small>{obtenerDiaSemana(item.metadata.fecha_programada).toUpperCase()}</small>

                                            </td>
                                            
                                            <td className="v">
                                              <p className="nm"><b>{item.metadata.idboutique.nombre_boutique}</b></p>
                                              <p className="nm"><small>{item.metadata.idboutique.direccion}</small></p>
                                            </td>
                                            <td className="v">
                                              <p className="nm"><small>{item.metadata.indicaciones}</small></p>
                                            </td>
                                          </tr>
                                          ))
                                        }


                                      </tbody>
                                    </table>



                              )
                          }

                      </Tab> 
                    </Tabs>
                  




                    
                </div>
              </div>

            </div>




  {
    show_ModalDetalleEvento && (
      <ModalDetalleEvento show={show_ModalDetalleEvento} handleClose={handleClose_ModalDetalleEvento} evento={evento_seleccionado} />
      )

  }



          </>

      );
  

}

export default MiCalendario
