import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'


import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'





import moment from 'moment'
import 'moment/locale/es'


import ModalReporteSalidaActividad from './ModalReporteSalidaActividad'
import ModalReporteServicioIncumplido from './ModalReporteServicioIncumplido'

import ModalReporteDeActividad from './ModalReporteDeActividad'


import ModalDetalleActividad from '../Operacion/ModalDetalleActividad'



import ModalReporteEvidenciasVisuales from '../Reportes/ModalReporteEvidenciasVisuales'



// import ModalReporteSalidaActividad as RSA from '../Operarios/ModalReporteSalidaActividad'


import { SERVER_ } from  '../../config';


const ReporteTareas = () =>{




  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 30
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`mi_reporte/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true,true,true,true,true,true,true])


  const todos = () =>{
    setEstatus([true,true,true,true,true,true,true])
  }
  const ninguno = () =>{
    setEstatus([false,false,false,false,false,false,false])
  }



  const [ tarea_seleccionada, setTareaSeleccionada ] = useState<any>({})
  const [ show_ModalReporteSalidaActividad, setModalReporteSalidaActividad ] = useState<boolean>(false)
  const handleShow_ModalReporteSalidaActividad = (tarea:any) => {
    setTareaSeleccionada(tarea)
    setModalReporteSalidaActividad(true)
  }
  const handleClose_ModalReporteSalidaActividad = () => {
    // cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalReporteSalidaActividad(false)
  }

  const handleClose_ModalReporteSalidaActividadRefresh = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalReporteSalidaActividad(false)
  }


  const [ show_ModalReporteServicioIncumplido, setModalReporteServicioIncumplido ] = useState<boolean>(false)
  const handleShow_ModalReporteServicioIncumplido = (tarea:any) => {
    setTareaSeleccionada(tarea)
    setModalReporteServicioIncumplido(true)
  }
  const handleClose_ModalReporteServicioIncumplido = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalReporteServicioIncumplido(false)
  }



  const [ show_ModalReporteDeActividad, setModalReporteDeActividad ] = useState<boolean>(false)
  const handleShow_ModalReporteDeActividad = (tarea:any) => {
    setTareaSeleccionada(tarea)
    setModalReporteDeActividad(true)
  }
  const handleClose_ModalReporteDeActividad = () => {
    setModalReporteDeActividad(false)
  }

  const handleClose_ModalReporteDeActividadRefresh = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalReporteDeActividad(false)
  }



  const [ show_ModalDetalleActividad, setModalDetalleActividad ] = useState<boolean>(false)
  const handleShow_ModalDetalleActividad = (tarea:any) => {
    setTareaSeleccionada(tarea)
    setModalDetalleActividad(true)
  }
  const handleClose_ModalDetalleActividad = () => {
    // cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalDetalleActividad(false)
  }





  const [ actualizar_lista, setActualizaLista ] = useState<boolean>(false)


  const [ show_ModalReporteEvidenciasVisuales, setModalReporteEvidenciasVisuales ] = useState<boolean>(false)
  const handleShow_ModalReporteEvidenciasVisuales = (tarea:any) => {
    setTareaSeleccionada(tarea)
    setModalReporteEvidenciasVisuales(true)
  }
  const handleClose_ModalReporteEvidenciasVisuales = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalReporteEvidenciasVisuales(false)
  }




      React.useEffect(() => {
        busqueda()
      }, []);


      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Reporte de Mis Tareas</h4>
                    <hr/>
                    



        <div className="row">
        
            <div className="mb-2">
                <label className="form-label">Búsqueda</label>
                <div className="hstack gap-3">
                  <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                  <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                  <div className="vr"></div>
                  <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                </div>
            </div>
            
            <div>
              <Form.Check inline name="group1" type="checkbox" label="ATENDIDAS" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
              <Form.Check inline name="group1" type="checkbox" label="PARA REPORTAR" checked={arreglo_estatus[1]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}} id={`opciones_2`} />

              {
                // <Form.Check inline name="group1" type="checkbox" label="ESPERA DE APROBACIÓN" checked={arreglo_estatus[2]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}} id={`opciones_3`} />
              }
              <Form.Check inline name="group1" type="checkbox" label="SERVICIO SUSPENDIDO" checked={arreglo_estatus[3]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}} id={`opciones_4`} />
              <Form.Check inline name="group1" type="checkbox" label="LOCAL CERRADO" checked={arreglo_estatus[4]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[4] = e.target.checked;  setEstatus(ne);}} id={`opciones_5`} />
              {
                // <Form.Check inline name="group1" type="checkbox" label="OTRO" checked={arreglo_estatus[5]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[5] = e.target.checked;  setEstatus(ne);}} id={`opciones_6`} />
              }
              <Form.Check inline name="group1" type="checkbox" label="FINALIZADO" checked={arreglo_estatus[6]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[6] = e.target.checked;  setEstatus(ne);}} id={`opciones_7`} />
            </div>
            
              <ul className="list-inline">
                <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
              </ul>

            </div>

                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>

                                    <div className="table-responsive">
                                      <table className="table table-sm">
                                        <thead>
                                          <tr>
                                            <th scope="col">Folio / Estatus</th>
                                            <th scope="col"></th>
                                            <th scope="col">Tipo</th>
                                            <th scope="col">Responsable</th>
                                            <th scope="col">Boutique</th>
                                            <th scope="col">Entrada / Salida</th>
                                            <th scope="col"></th>
                                          </tr>
                                        </thead>
                                        <tbody>{
                                                resultados.map((visita:any)=>

                                                      <tr key={ Math.random().toString(36).substr(2, 9) }>
                                                        <td onClick={(e:any)=>{handleShow_ModalDetalleActividad(visita)}} className={`v pointer estatus_atencion_${visita.idestatus_atencion.id}`} style={{width:140}}>
                                                          <p className="nm"><b>{visita.folio}</b></p>
                                                          <p className="nm"><small>{visita.idestatus_atencion.estatus}  </small></p>
                                                        </td>
                                                        <td className="v text-center" style={{width:40}}>
                                                          {
                                                            ([4,7].indexOf(visita.idestatus_atencion.id) > -1 && visita.boutique_reporta_servicio_finalizado === true ) && (<a href={`${SERVER_}reporte/reporte_servicio/${visita.uuid}`} target="_blank"><i className="fa fa-print"/></a>)
                                                          }
                                                        </td>


                                                        <td className={`v tipo_orden_${visita.idtipo_orden.id}`} style={{width:240}}>
                                                          <p className="nm">
                                                          <small>{visita.idtipo_orden.tipo_orden}</small></p>
                                                        </td>



                                                        <td className="v" style={{width:240}}>
                                                          { visita.idresponsable && (<p className="nm"><b>{visita.idresponsable.nombres}</b> <br/><small> {visita.idresponsable.paterno} {visita.idresponsable.materno}</small></p>)}
                                                        </td>
                                                        <td className="v" style={{width:400}}>
                                                          <p className="nm">{visita.idboutique.nombre_boutique}</p>
                                                          {
                                                            [3,4].indexOf(visita.idestatus_atencion.id) > -1  && (
                                                                <>
                                                                  { (!visita.boutique_reporta_servicio_finalizado) && (<p className="nm pointer" onClick={(e:any)=>{handleShow_ModalReporteSalidaActividad(visita)}}><small>Reporte Salida <i className="fa fa-clock-o"/></small></p>)}
                                                                </>
                                                              )
                                                          }
                                                          {
                                                            [3,4,7,99].indexOf(visita.idestatus_atencion.id) > -1  && (
                                                                <>
                                                                  { visita.boutique_reporta_servicio_finalizado === true && (<p className="nm  " ><small>Firmó salida </small></p>)}
                                                                </>
                                                              )
                                                          }
                                                          {
                                                            // [3,4,5,6,7,8,9,99].indexOf(visita.idestatus_atencion.id) > -1 && (
                                                            //     <>
                                                            //       { visita.boutique_reporta_servicio_finalizado === true && (
                                                            //           <React.Fragment>
                                                            //             { [10].indexOf(visita.idtipo_orden.id) < 0 && (<p className="nm "><small>Firmó salida <i className="fa fa-check-circle"/></small></p>)}
                                                            //             { [10].indexOf(visita.idtipo_orden.id) > -1 && (<p className="nm "><small>Firmó entrega <i className="fa fa-check-circle"/></small></p>)}
                                                            //           </React.Fragment>
                                                            //         )}

                                                            //     </>
                                                            //   )
                                                          }


                                                          {
                                                            [3,4].indexOf(visita.idestatus_atencion.id) > -1  && (
                                                                <>
                                                                  { (!visita.boutique_reporta_servicio_finalizado) && (<p className="nm"><small>No se firmó la salida <i className="fa fa-warning"/></small></p>)}
                                                                </>
                                                              )
                                                          }

                                                                      
                                                        </td>

                                                        <td className="v" style={{width:240}}>
                                                          <p className="nm"><b><small>{moment(visita.fecha_inicio_servicio).format('DD/MMM/YYYY, HH:mm [hrs]').toUpperCase()}</small></b></p>
                                                          {visita.fecha_fin_servicio && (<p className="nm"><small>{moment(visita.fecha_fin_servicio).format('DD/MMM/YYYY, HH:mm [hrs]').toUpperCase()}</small></p>)}
                                                        </td>
                                                        <td className="v" style={{width:40}}>
                                                          {
                                                            // JSON.stringify(visita.boutique_reporta_servicio_finalizado)
                                                          }
                                                          {
                                                            // [3].indexOf(visita.idestatus_atencion.id) > -1 && (
                                                            //     <>
                                                            //       { !visita.boutique_reporta_servicio_finalizado && (<button onClick={(e:any)=>{handleShow_ModalReporteSalidaActividad(visita)}} type="button" className="btn btn-sm btn-info"><i className="fa fa-building"/></button>)}
                                                            //     </>
                                                            //   )
                                                          }

                                                          {
                                                            [6,9,99].indexOf(visita.idestatus_atencion.id) > -1 && (
                                                                <>
                                                                  { !visita.tecnico_reporto && (<button onClick={(e:any)=>{handleShow_ModalReporteServicioIncumplido(visita)}} type="button" className="btn btn-sm btn-danger"><i className="fa fa-warning"/></button>)}
                                                                </>
                                                              )
                                                          }

                                                        </td>
                                                        <td className="v" style={{width:40}}>
                                                          {
                                                            // JSON.stringify(visita.boutique_reporta_servicio_finalizado)
                                                          }

                                                          {
                                                            [4].indexOf(visita.idestatus_atencion.id) > -1 && (
                                                                <React.Fragment>
                                                                {
                                                                  visita.boutique_reporta_servicio_finalizado === true && (<button onClick={(e:any)=>{handleShow_ModalReporteDeActividad(visita)}} type="button" className="btn btn-sm btn-info"><i className="fa fa-question-circle"/></button>)
                                                                }
                                                                
                                                                </React.Fragment>
                                                              )
                                                          }
                                                          
                                                        </td>






                                                        <td className="v" style={{width:40}}>
                                                          {
                                                            // [3].indexOf() > -1 && (
                                                                <>
                                                                </>
                                                              // )
                                                          }

                                                        {
                                                          visita.boutique_reporta_servicio_finalizado === true && (
                                                              <React.Fragment>

                                                                {
                                                                  [4].indexOf(visita.idestatus_atencion.id) > -1 && (
                                                                      <>
                                                                      <button type="button" onClick={(e:any)=>{handleShow_ModalReporteEvidenciasVisuales(visita)}} className={`btn btn-sm ${( visita.idreporte_evidencia_visual ? 'btn-info' : 'btn-warning' )}`}><i className="fa fa-cloud"/></button>
                                                                      </>
                                                                    )
                                                                }
                                                                  
                                                              </React.Fragment>
                                                            )
                                                        }



                                                        </td>
                                                      </tr>


                                                  )

                                              }
                                        </tbody>
                                      </table>
                                    </div>



                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}




                </div>
              </div>

            </div>





  {
    show_ModalReporteSalidaActividad && (
      <ModalReporteSalidaActividad show={show_ModalReporteSalidaActividad} handleClose={handleClose_ModalReporteSalidaActividad}  handleCloseRefresh={handleClose_ModalReporteSalidaActividadRefresh} tarea={tarea_seleccionada} setActualizaLista={setActualizaLista} />
      )

  }




  {
    show_ModalDetalleActividad && (
      <ModalDetalleActividad show={show_ModalDetalleActividad} handleClose={handleClose_ModalDetalleActividad} tarea={tarea_seleccionada} />
      )

  }


  {
    show_ModalReporteServicioIncumplido && (
      <ModalReporteServicioIncumplido show={show_ModalReporteServicioIncumplido} handleClose={handleClose_ModalReporteServicioIncumplido} tarea={tarea_seleccionada}  />
      )

  }




  {
    show_ModalReporteDeActividad && (
      <ModalReporteDeActividad show={show_ModalReporteDeActividad} handleClose={handleClose_ModalReporteDeActividad} handleCloseRefresh={handleClose_ModalReporteDeActividadRefresh} tarea={tarea_seleccionada} />
      )

  }




  {
    show_ModalReporteEvidenciasVisuales && (
      <ModalReporteEvidenciasVisuales show={show_ModalReporteEvidenciasVisuales} handleClose={handleClose_ModalReporteEvidenciasVisuales} registro={tarea_seleccionada} tipo="REPORTE_VISITA" />
      )

  }





          </>

      );
  

}

export default ReporteTareas
