import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import moment from 'moment'


import Dialog from '../../services/Dialogs'

import { toast } from 'react-toastify'


const ModalRegistroPagoIguala = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, periodo, boutique, tipo_ingreso } = params


  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
      descripcion : yup.string(),
      fecha_movimiento : yup.string(),
      archivo: yup.mixed().test('fileRequired', 'Campo obligatorio', (value) => !!value),
      monto : yup.number().required(mensajes_validacion_generico),
      tipo_ingreso : yup.string().required(mensajes_validacion_generico),

      forma_pago : yup.string().required(mensajes_validacion_generico),
      idcuenta_banco : yup.string().required(mensajes_validacion_generico),


      referencia_movimiento : yup.string().required(mensajes_validacion_generico),
      numero_pedido : yup.string().required(mensajes_validacion_generico),
      contra_recibo : yup.string(),
      fecha_pedido : yup.string(),
      numero_factura : yup.string().required(mensajes_validacion_generico),



  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async  (registro:any,event:any) =>{

   if (registro.archivo) {
    const base64 = await getBase64(registro.archivo);
    registro.archivo = base64;
  }

        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.create( `cobranza/registro_pago/${boutique.uuid_boutique}/${periodo.uuid}` , { ...registro, fecha_movimiento : moment(registro.fecha_movimiento).format('YYYY-MM-DD'), fecha_pedido : moment(registro.fecha_pedido).format('YYYY-MM-DD') })

          if(res_){
            toast.success('El Registro de Pago fue creado correctamente')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }

const getBase64 = (file:any) => {
  return new Promise((resolve:any, reject:any) => {
    const reader : any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = (error:any) => reject(error);
  });
};

  const [preview, setPreview] = useState<any>(null);

  const handleFileChange = (event:any, setFieldValue:any) => {
    const file : any = event.target.files[0];

    if (file) {
      setFieldValue('archivo', file);

      // Crear una URL de objeto para la vista previa
      const filePreview = URL.createObjectURL(file);
      setPreview(filePreview);
    }
  };

  const rtnMontoPorServicio = (tipo_ingreso:string) => {
    let c = 0
    if(tipo_ingreso==="MANTENIMIENTO") c = boutique.monto_iguala_mantenimiento
    if(tipo_ingreso==="CONSERVACION") c = boutique.monto_iguala_conservacion
    if(tipo_ingreso==="LIMPIEZA") c = boutique.monto_iguala_limpieza
    return c
  }


  const [ cuentas_bancarias, setCuentasBancarias] = useState<any>([])

  const cargarCuentas = async() => {

    try{
      let res = await RequestService.getEndPoint('catalogo_cuentas_bancarias')
      setCuentasBancarias(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar')
    }

  }

  React.useEffect(() => {
    cargarCuentas()
  }, []);


  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Registro de Pago de Iguala</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        {
          tipo_ingreso === "MANTENIMIENTO" && (
              <div className="tipo_orden_3 mb-2">
                <h5 className="nm text-center">REGISTRO IGUALA EN SERVICIO DE MANTENIMIENTO</h5>
              </div>
            )
        }

        {
          tipo_ingreso === "CONSERVACION" && (
              <div className="tipo_orden_5 mb-2">
                <h5 className="nm text-center">REGISTRO IGUALA EN SERVICIO DE CONSERVACIÓN</h5>
              </div>
            )
        }
        
        {
          tipo_ingreso === "LIMPIEZA" && (
              <div className="tipo_orden_7 mb-2">
                <h5 className="nm text-center">REGISTRO IGUALA EN SERVICIO DE LIMPIEZA</h5>
              </div>
            )
        }

            <Formik
              validationSchema={schema}
              onSubmit={(values,e)=>{enviarFormulario(values, e)}}
              initialValues={{

                    monto : rtnMontoPorServicio(tipo_ingreso),
                    tipo_ingreso : tipo_ingreso,
                    archivo : "",
                    fecha_movimiento : new Date(),
                    descripcion : "",

                    forma_pago : "", 
                    idcuenta_banco : "", 

                    referencia_movimiento : "", 
                    numero_pedido : "", 
                    contra_recibo : "", 
                    fecha_pedido : new Date(), 
                    numero_factura : "", 


              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form id='my-form' noValidate onSubmit={handleSubmit}>


                    <Form.Group className="mb-3" controlId="monto">
                      <Form.Label>Monto del Pago</Form.Label>
                      <Form.Control 
                        required
                        name="monto"
                        value={values.monto} 
                        onChange={(e)=>{ setFieldValue('monto', e.target.value ) }}
                        isInvalid={!!errors.monto}
                        style={{textTransform:'uppercase'}}
                      />
                      <Form.Control.Feedback type="invalid"> {<>{errors.monto}</>} </Form.Control.Feedback>
                    </Form.Group>


                    <div className="row">
                      <div className="col-sm-6">
                          <div className="mb-3">
                            <Form.Label>Fecha del Movimiento</Form.Label>
                            <DatePicker selected={values.fecha_movimiento} className="form-control"  dateFormat="yyyy-MM-dd" maxDate={new Date()} onChange={(date) => setFieldValue('fecha_movimiento', date ) } />
                          </div>
                      </div>
                    </div>





                    <Form.Group className="mb-3" controlId="monto">
                      <Form.Label>Referencia del Movimiento</Form.Label>
                      <Form.Control 
                        required
                        name="referencia_movimiento"
                        value={values.referencia_movimiento} 
                        onChange={(e)=>{ setFieldValue('referencia_movimiento', e.target.value ) }}
                        isInvalid={!!errors.referencia_movimiento}
                        style={{textTransform:'uppercase'}}
                      />
                      <Form.Control.Feedback type="invalid"> {<>{errors.referencia_movimiento}</>} </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="monto">
                      <Form.Label>Número de Pedido</Form.Label>
                      <Form.Control 
                        required
                        name="numero_pedido"
                        value={values.numero_pedido} 
                        onChange={(e)=>{ setFieldValue('numero_pedido', e.target.value ) }}
                        isInvalid={!!errors.numero_pedido}
                        style={{textTransform:'uppercase'}}
                      />
                      <Form.Control.Feedback type="invalid"> {<>{errors.numero_pedido}</>} </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="monto">
                      <Form.Label>Contra Recibo</Form.Label>
                      <Form.Control 
                        required
                        name="contra_recibo"
                        value={values.contra_recibo} 
                        onChange={(e)=>{ setFieldValue('contra_recibo', e.target.value ) }}
                        isInvalid={!!errors.contra_recibo}
                        style={{textTransform:'uppercase'}}
                      />
                      <Form.Control.Feedback type="invalid"> {<>{errors.contra_recibo}</>} </Form.Control.Feedback>
                    </Form.Group>

                    <div className="row">
                      <div className="col-sm-6">
                          <div className="mb-3">
                            <Form.Label>Fecha del Pedido</Form.Label>
                            <DatePicker selected={values.fecha_pedido} className="form-control"  dateFormat="yyyy-MM-dd" maxDate={new Date()} onChange={(date) => setFieldValue('fecha_pedido', date ) } />
                          </div>
                      </div>
                    </div>

                    <Form.Group className="mb-3" controlId="monto">
                      <Form.Label>Número de Factura</Form.Label>
                      <Form.Control 
                        required
                        name="numero_factura"
                        value={values.numero_factura} 
                        onChange={(e)=>{ setFieldValue('numero_factura', e.target.value ) }}
                        isInvalid={!!errors.numero_factura}
                        style={{textTransform:'uppercase'}}
                      />
                      <Form.Control.Feedback type="invalid"> {<>{errors.numero_factura}</>} </Form.Control.Feedback>
                    </Form.Group>







                    <Form.Group className="mb-3" controlId="descripcion">
                      <Form.Label>Descripción o Detalles del Pago</Form.Label>
                      <Form.Control
                        rows={6}
                        as="textarea"
                        required
                        name="descripcion"
                        value={values.descripcion} 
                        onChange={(e)=>{ setFieldValue('descripcion', e.target.value ) }}
                        isInvalid={!!errors.descripcion}
                        style={{textTransform:'uppercase'}}
                      />
                      <Form.Control.Feedback type="invalid"> {<>{errors.descripcion}</>} </Form.Control.Feedback>
                    </Form.Group>




              <div className="mb-3">
                <label className="form-label">Cuenta Bancaria</label>
                  <Form.Select aria-label="Selección de Sucursal" required value={values.idcuenta_banco} onChange={(e)=>{ setFieldValue('idcuenta_banco',e.target.value) }} isInvalid={!!errors.idcuenta_banco} >
                    <option>Selecciona una sucursal</option>
                    { cuentas_bancarias.map((cuenta:any)=><option key={ Math.random().toString(36).substr(2, 9) } value={cuenta.uuid}>{cuenta.banco} - {cuenta.descripcion} </option>)}

                  </Form.Select>
                  <Form.Control.Feedback type="invalid"> {errors.idcuenta_banco} </Form.Control.Feedback>
              </div>





              <div className="mb-3">
                <label className="form-label">Forma de Pago</label>
                  <Form.Select aria-label="Selección de Sucursal" required value={values.forma_pago} onChange={(e)=>{ setFieldValue('forma_pago',e.target.value) }} isInvalid={!!errors.forma_pago} >
                    <option>Selecciona una Forma</option>
                    <option value={'EFECTIVO'}>EFECTIVO</option>
                    <option value={'TRANSFERENCIA'}>TRANSFERENCIA</option>
                    <option value={'CHEQUE'}>CHEQUE</option>
                    <option value={'DEPÓSITO EFECTIVO'}>DEPÓSITO EFECTIVO</option>

                  </Form.Select>
                  <Form.Control.Feedback type="invalid"> {errors.forma_pago} </Form.Control.Feedback>
              </div>



                  <Form.Group className="mb-3" controlId="archivo">
                    <Form.Label>Archivo</Form.Label>
                    <Form.Control
                      accept="application/pdf"
                      type="file"
                      name="archivo"
                      onChange={(e) => handleFileChange(e, setFieldValue)}
                      isInvalid={!!errors.archivo}
                    />
                    <Form.Control.Feedback type="invalid">{errors.archivo}</Form.Control.Feedback>
                    {/* Vista previa del archivo seleccionado */}
                    {preview && (
                      <iframe src={preview} style={{width:'100%',height:500}} />
                    )}
                  </Form.Group>




                </Form>
              )}
            </Formik>




        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Crear Registro</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalRegistroPagoIguala;