import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'



import Pagination from "../../services/Pagination";
import { Table, Form, Tabs, Tab } from 'react-bootstrap'


import { calcularTiempoTranscurrido } from '../../services/funcionesPublicas'

import moment from 'moment'

import { SERVER_ } from '../../config'


import { rol } from '../../services/rol'


import ModalAsignamientoHerramientasEmpleado from './ModalAsignamientoHerramientasEmpleado'


const AsignamientosUsuarios = ( params : any ) => {


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 30
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`inventario/asiganimento_usuario/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e:any){

      if (e.response?.status) {
        switch (e.response.status) {
          case 404:
            toast.error("Debes escribir algo")
            break;
          default:
            toast.error(e.response?.data)
        }
      }
      else{
        toast.error("Problemas con la busqueda")
      }
      setCargando(false)

    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true])


  const todos = () =>{
    setEstatus([true])
  }
  const ninguno = () =>{
    setEstatus([false])
  }


      React.useEffect(() => {
        busqueda()
      }, []);


  const [ empleado_seleccionado, setEmpleadoSeleccionado ] = useState<any>(null)


  const [ show_ModalAsignamientoHerramientasEmpleado, setModalAsignamientoHerramientasEmpleado ] = useState<boolean>(false)
  const handleShow_ModalAsignamientoHerramientasEmpleado = (empleado:any) => {
    setEmpleadoSeleccionado(empleado)
    setModalAsignamientoHerramientasEmpleado(true)
  }
  const handleClose_ModalAsignamientoHerramientasEmpleado = () => {
        busqueda()
    setModalAsignamientoHerramientasEmpleado(false)
  }



  return (
    <React.Fragment>


        <div className="row">
        
            <div className="mb-2">
                <label className="form-label">Búsqueda</label>
                <div className="hstack gap-3">
                  <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                  <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                  <div className="vr"></div>
                  <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                </div>
            </div>
            
        </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col" colSpan={3}>Empleado</th>
                                              <th scope="col">Celular</th>
                                              <th scope="col">Herramientas</th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (usuario:any,indice_usuario:number) =>
                                                  <tr key={ indice_usuario } style={{fontSize:12}}>
                                                    <td className="v text-left">
                                                        <p className="nm"><b>{usuario.nombres}</b></p>
                                                            
                                                    </td>
                                                    <td className="v text-left" style={{"width":"200px"}}>
                                                        <small>{usuario.paterno} {usuario.materno}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"200px"}}>
                                                        <small>{rol(usuario.rol)}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"100px"}}>
                                                        <small>{usuario.celular}</small>
                                                    </td>
                                                    <td className="v text-center" onClick={(e:any)=>{handleShow_ModalAsignamientoHerramientasEmpleado(usuario)}} style={{"width":"80px"}}>
                                                        {usuario.herramientas_asignadas}
                                                    </td>
                                                    <td className="v text-center" style={{"width":"40px"}}>
                                                         <a target="_blank" href={`${SERVER_}herramientas/formato_herramientas_asignadas/${usuario.uuid}`}><i className="fa fa-print"/></a>
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}









  {
    show_ModalAsignamientoHerramientasEmpleado && (
      <ModalAsignamientoHerramientasEmpleado show={show_ModalAsignamientoHerramientasEmpleado} handleClose={handleClose_ModalAsignamientoHerramientasEmpleado} empleado={empleado_seleccionado} />
      )

  }






    </React.Fragment>
    )


}

const Asignamientos = ( params : any ) => {



  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )


  const [ asignamientos, setResultados ] = useState<any>([])

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let response : any = await RequestService.getEndPoint(`inventario/asignamientos/busqueda_asignamiento?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }



      React.useEffect(() => {
        busqueda()
      }, []);



      return (
        <React.Fragment>
          



        <div className="row">
        
            <div className="mb-2">
                <label className="form-label">Búsqueda</label>
                <div className="hstack gap-3">
                  <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                  <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                  <div className="vr"></div>
                  <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                </div>
            </div>
            

        </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     asignamientos.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Folio</th>
                                              <th scope="col">Empleado</th>
                                              <th scope="col">Herramientas</th>
                                              <th scope="col">Fecha</th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              asignamientos.map( (asignamiento:any,indice_asignamiento:number) =>
                                                  <tr key={ indice_asignamiento } style={{fontSize:12}}>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                        <p className="nm"><small><b>{asignamiento.folio}</b></small></p>
                                                    </td>
                                                    <td className="v text-left"  >
                                                        <p className="nm"><b>{asignamiento.nombres}</b></p>
                                                        <small>{asignamiento.paterno} {asignamiento.materno}</small>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"80px"}}>
                                                        <p className="nm">{asignamiento.cantidad_herramientas}</p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                        <p className="nm"><b>{moment(asignamiento.fecha_asignamiento).format('YYYY-MM-DD')}</b></p>
                                                        <small>{calcularTiempoTranscurrido(asignamiento.fecha_asignamiento)}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"30px"}}>
                                                        <a target="_blank" href={`${SERVER_}herramientas/formato_resguardo/${asignamiento.uuid_asignamiento}`}><i className="fa fa-print"/></a>
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}




        </React.Fragment>
        )



}





const ModalAsignamientos = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params











  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Asignamientos de herramientas al Personal</Modal.Title>
        </Modal.Header>
        <Modal.Body>


            <Tabs
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="home" title="Empleados">
                <AsignamientosUsuarios />
              </Tab>
              <Tab eventKey="profile" title="Asignamientos">
                <Asignamientos />
              </Tab>
            </Tabs>



            


        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalAsignamientos;