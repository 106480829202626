import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'


import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';



import moment from 'moment'
import 'moment/locale/es'

import { FormateaNumero, obtenerNombreDia } from '../../services/funcionesPublicas'


import Dialog from '../../services/Dialogs'


const ModalNuevoTicketOperarios = ( params : any ) => {


  const user = AuthService.getCurrentUser()



  const { show, handleClose, boutique_cerrada } = params

  // const [ cargando_, setCargando ] = useState( true )

  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          descripcion_ticket : yup.string().required(mensajes_validacion_generico),
          idtipo_ticket : yup.number().nullable().required(mensajes_validacion_generico),

  });



const [ cargando_, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `ticket/nuevo_operario` , { ...registro })

        if(res_){
          toast.success('El ticket fue agregado. Recuerda estar pendiente de los tickets que abras')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
          setSeEnvio(false)
        } 
        }
        catch(e:any){
          setSeEnvio(false)
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}




  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            {
              // <p className="nm"><b>Responsable: </b> {boutique.idresponsable_limpieza.nombres}  <small><i className="fa fa-phone"/> <a href={`tel:${boutique.idresponsable_limpieza.celular}`}>{FormateaNumero(boutique.idresponsable_limpieza.celular)}</a></small></p>
            }
            <h4 className="nm"><b>Día: </b>   <small> {obtenerNombreDia(moment().day())} {moment().format('DD [de] MMMM [de] YYYY')} </small> </h4>


            {
              // JSON.stringify(boutique.idresponsable_limpieza)
            }




                          {  (!cargando_) && (

                              <>

                                  <Formik
                                    validationSchema={schema}
                                    onSubmit={(values,e)=>{enviarFormulario(values, e)}}
                                    initialValues={{

                                          descripcion_ticket : "",
                                          idtipo_ticket : null,

                                    }}
                                  >
                                    {({
                                      handleSubmit,
                                      handleChange,
                                      setFieldValue,
                                      handleBlur,
                                      values,
                                      touched,
                                      isValid,
                                      errors,
                                    }) => (
                                      <Form id='my-form' noValidate onSubmit={handleSubmit}>


                                          <Form.Group controlId="idtipo_ticket">
                                            <Form.Label>Tipo de Ticket</Form.Label>


                                            {
                                            //   ["ROLE_TECNICO"].indexOf(user.rol) > -1 && (<Form.Check inline isInvalid={!!errors.idtipo_ticket} type={'radio'} id={`8`} label={`REPORTE O QUEJA`} checked={values.idtipo_ticket===8} onChange={(e:any)=>{setFieldValue('idtipo_ticket',8)}} key={ Math.random().toString(36).substr(2, 9) }/>)
                                            // }
                                            // {
                                            //   ["ROLE_LIMPIEZA"].indexOf(user.rol) > -1 && (<Form.Check inline isInvalid={!!errors.idtipo_ticket} type={'radio'} id={`9`} label={`REPORTE O QUEJA`} checked={values.idtipo_ticket===9} onChange={(e:any)=>{setFieldValue('idtipo_ticket',9)}} key={ Math.random().toString(36).substr(2, 9) }/>)
                                            // }
                                            // {
                                            //   ["ROLE_RESPONSABLE_TECNICOS","ROLE_RESPONSABLE_LIMPIEZA"].indexOf(user.rol) > -1 && (<Form.Check inline isInvalid={!!errors.idtipo_ticket} type={'radio'} id={`9`} label={`REPORTE O QUEJA`} checked={values.idtipo_ticket===9} onChange={(e:any)=>{setFieldValue('idtipo_ticket',9)}} key={ Math.random().toString(36).substr(2, 9) }/>)
                                            }

                                            <Form.Check inline isInvalid={!!errors.idtipo_ticket} type={'radio'} id={`8`} label={`REPORTE O QUEJA`} checked={values.idtipo_ticket===8} onChange={(e:any)=>{setFieldValue('idtipo_ticket',8)}} key={ Math.random().toString(36).substr(2, 9) }/>
                                              



                                              { boutique_cerrada === true && (<Form.Check inline isInvalid={!!errors.idtipo_ticket} type={'radio'} id={`10`} label={`BOUTIQUE CERRADA`} checked={values.idtipo_ticket===10} onChange={(e:any)=>{setFieldValue('idtipo_ticket',10)}} key={ Math.random().toString(36).substr(2, 9) }/>)}
                                              <Form.Check inline isInvalid={!!errors.idtipo_ticket} type={'radio'} id={`99`} label={`OTRO`} checked={values.idtipo_ticket===99} onChange={(e:any)=>{setFieldValue('idtipo_ticket',99)}} key={ Math.random().toString(36).substr(2, 9) }/>
                                              <Form.Control.Feedback type="invalid">{<>{errors.idtipo_ticket}</>}</Form.Control.Feedback>
                                          </Form.Group>




                                          <Form.Group   className="mb-3" controlId="descripcion_ticket">
                                            <Form.Label>Descripción del Ticket</Form.Label>
                                            <Form.Control
                                              as="textarea"
                                              rows={5}
                                              required
                                              name="descripcion_ticket"
                                              value={values.descripcion_ticket}
                                              onChange={(e)=>{ setFieldValue('descripcion_ticket', (e.target.value || "").toUpperCase() ) }}
                                              isInvalid={!!errors.descripcion_ticket}
                                            />
                                            <Form.Control.Feedback type="invalid"> {<>{errors.descripcion_ticket}</>} </Form.Control.Feedback>
                                          </Form.Group>


                                      </Form>
                                    )}
                                  </Formik>



                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}



          {
            // JSON.stringify(boutique)
          }

        </Modal.Body>

            {  (!cargando_) && (

                <>

                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Cancelar
                        </Button>
                        <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
                      </Modal.Footer>

                </>

             )}
      </Modal>
    </>
  );
}


export default ModalNuevoTicketOperarios;