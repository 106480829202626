import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import Dialog from '../../services/Dialogs'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // importar el estilo
import './circulares.scss'

const ModalAgregarCircular = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params


  const [content, setContent] = useState<any>('');

  const handleChange = (html:any) => {
    setContent(html);
  }



const dialog = new Dialog();

const [ envio_server, setSeEnvio ] = useState<boolean>(false)
const handleSave = async (event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `circulares/crear_circular` , { circular : content })

        if(res_){
          toast.success('El Circular se Agrego')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}


    

  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Circular</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }

    <div className="html-editor">
      <ReactQuill theme="snow" value={content} onChange={handleChange}  className="myQuillEditor"/>
    </div>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" onClick={handleSave} type="button">Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalAgregarCircular;