import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'


import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'


import ModalNuevaZona from './ModalNuevaZona'
import ModalModificarZona from './ModalModificarZona'


const Zonas = () => {

  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( true )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`zonas/busqueda?where=${text_busqueda}&skip=${skip}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(0)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true])


  const todos = () =>{
    setEstatus([true])
  }
  const ninguno = () =>{
    setEstatus([false])
  }


      React.useEffect(() => {
    cargarMiReporte(0)
      }, []);


  const [ show_ModalNuevaZona, setModalNuevaZona ] = useState<boolean>(false)
  const handleShow_ModalNuevaZona = () => {
    setModalNuevaZona(true)
  }
  const handleClose_ModalNuevaZona = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalNuevaZona(false)
  }


  const [ zona_seleccionada, setZona ] = useState<any>({})
  const [ show_ModalModificarZona, setModalModificarZona ] = useState<boolean>(false)
  const handleShow_ModalModificarZona = (zona:any) => {
    setZona(zona)
    setModalModificarZona(true)
  }
  const handleClose_ModalModificarZona = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalModificarZona(false)
  }


      return (
          <>

              
              
                <h6>Zonas</h6>

                <ul className="list-inline">
                  <li onClick={handleShow_ModalNuevaZona} className="list-inline-item pointer"><p className="nm">Agregar Zona</p></li>
                </ul>
           



        <div className="row">
        
            <div className="mb-2">
                <label className="form-label">Búsqueda</label>
                <div className="hstack gap-3">
                  <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                  <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                  <div className="vr"></div>
                  <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                </div>
            </div>
            
        </div>





                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Zona</th>
                                              <th scope="col">R Mantenimiento</th>
                                              <th scope="col">R Conservación</th>
                                              <th scope="col">R Limpieza</th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (cual:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                                    <td className="v text-left">
                                                        <p className="nm"><small><b>{cual.area}</b></small></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"200px"}}>
                                                        { cual.idresponsable_mantenimiento &&  ( <><p className="nm"><b>{cual.idresponsable_mantenimiento.nombres}</b></p><small>{cual.idresponsable_mantenimiento.paterno} {cual.idresponsable_mantenimiento.materno}</small></> )}
                                                    </td>
                                                    <td className="v text-left" style={{"width":"200px"}}>
                                                        { cual.idresponsable_conservacion &&  ( <><p className="nm"><b>{cual.idresponsable_conservacion.nombres}</b></p><small>{cual.idresponsable_conservacion.paterno} {cual.idresponsable_conservacion.materno}</small></> )}
                                                    </td>
                                                    <td className="v text-left" style={{"width":"200px"}}>
                                                        { cual.idresponsable_limpieza &&  ( <><p className="nm"><b>{cual.idresponsable_limpieza.nombres}</b></p><small>{cual.idresponsable_limpieza.paterno} {cual.idresponsable_limpieza.materno}</small></> )}
                                                    </td>
                                                    <td className="v text-left" style={{"width":"50px"}}>
                                                        <button className="btn btn-sm btn-warning" onClick={(e:any)=>{handleShow_ModalModificarZona(cual)}} ><i className="fa fa-edit"/></button>
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}

                    



  {
    show_ModalNuevaZona && (
      <ModalNuevaZona show={show_ModalNuevaZona} handleClose={handleClose_ModalNuevaZona}  />
      )

  }





  {
    show_ModalModificarZona && (
      <ModalModificarZona show={show_ModalModificarZona} handleClose={handleClose_ModalModificarZona} zona={zona_seleccionada}  />
      )

  }



          </>

      );
  

}

export default Zonas
