import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Form, Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { Formik } from 'formik';
import * as yup from 'yup';


import moment from 'moment'


import Dialog from '../../services/Dialogs'

import { formatearNumero, condicionesPago, tipoActividadLabel, labelUnidadMedida, ordenarPorTipo, tipoPresupuesto } from '../../services/funcionesPublicas'


const ModalAsignarPresupuestoVisita = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, visita } = params

  const tarea = { ...visita }




  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
          idpresupuesto : yup.string().required(mensajes_validacion_generico).nullable(),
  });


  const [ presupuestos, setPresupuestos ] = useState<any>([])
  const cargarPresupuestos = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`presupuestos_visita/${visita.uuid_servicio}`)
      setPresupuestos(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarPresupuestos()
  }, []);

  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: '<p>El Presupuesto se asignará a esta visita</p>'
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.update( `visita/asignar_presupuesto/${visita.uuid_servicio}` , { uuid_presupuesto :  (JSON.parse(registro.idpresupuesto)).uuid })

          if(res_){
            toast.success('La actividad fue asignada correctamente')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }



  const [ presupuesto, setPresupuestoView ] = useState<any>(null)
    

  const [ suma_subtotal, setSuma ] = useState<number>(0)

  const sumarSubtotales = (presupuesto:any) =>{
    let s = 0
    // console.log(presupuesto.material_actividades)
    for(let actividad of presupuesto.material_actividades){
      s += ( actividad.costo * actividad.cantidad )
    }
    setSuma(s)

  }


  const [ tipo_unidad_medida, setTipo_unidad_medida ] = useState<any>([])
  const cargarTipo_unidad_medida = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/tipo_unidad_medida`)
      setTipo_unidad_medida(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }




  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Asignar Presupuesto a Visita</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="row">
            <div className="col-sm-6">
              <small>Folio</small>
              <h6>{tarea.folio}</h6>
            </div>
            <div className="col-sm-6">
                <small>Fecha</small>
                <h6>{moment(tarea.fecha_programada).utc().format('DD [de] MMMM [de] YYYY').toUpperCase()}</h6>
            </div>
          </div>


          <div className="row">
            <div className="col-sm-12">
              <small>Boutique</small>
              <h5>{tarea.idboutique.nombre_boutique}</h5>
              <p>{tarea.idboutique.direccion}</p>
            </div>
          </div>


          <Formik
            validationSchema={schema}
            onSubmit={(values,e)=>{enviarFormulario(values, e)}}
            initialValues={{
                  idpresupuesto : "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form id='my-form' noValidate onSubmit={handleSubmit}>

              {
                // JSON.stringify(values.idpresupuesto)
              }


                         <Form.Group className="mb-3" controlId="idpresupuesto">
                          <Form.Label>Selecciona un Presupuesto para Agendar Cita de Atención</Form.Label>
                          <Form.Select required name="idpresupuesto" value={values.idpresupuesto} onChange={(e)=>{ 
                            if(e.target.value !==''){ setFieldValue('idpresupuesto', e.target.value ); setPresupuestoView(JSON.parse(e.target.value)); sumarSubtotales(JSON.parse(e.target.value)); }
                            else { setFieldValue('idpresupuesto', null ); setPresupuestoView(null); }
                          }} isInvalid={!!errors.idpresupuesto}>
                            <option value="">SELECCIONA UN PRESUPUESTO</option>
                              {
                                presupuestos.map((presupuesto:any)=>
                                    <option key={ Math.random().toString(36).substr(2, 9) } value={JSON.stringify(presupuesto)}>{presupuesto.folio} - {presupuesto.idboutique.nombre_boutique}</option>
                                  )
                              }
                          </Form.Select>
                          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid"> {<>{errors.idpresupuesto}</>} </Form.Control.Feedback>
                        </Form.Group>



              </Form>
            )}
          </Formik>




    {
      presupuesto && (

          <>

          {
            presupuesto.idestatus_presupuesto.id < 4 && (

                <>

                    <h5 className="mt-2">Folio</h5>
                    <p>{presupuesto.folio}</p>

                    <h5 className="mt-2">Boutique</h5>
                    <p className="nm">{presupuesto.idboutique.nombre_boutique}</p>
                    {
                      presupuesto.solicitado && (<p className="nm"><i className="fa fa-star"/> Presupuesto solicitado por la Boutique</p>)
                    }

                    <h5 className="mt-2">Descripción General</h5>
                    <p>{presupuesto.descripcion}</p>


                    <h6 className="mt-2">Tiempo Estimado de la Obra</h6>
                    <p>{presupuesto.dias_finaliza_estimados} Día(s)</p>


                    <h5 className="mt-2">Detalle de las Actividades a Realizar</h5>
                    <ol>
                      {
                        presupuesto.actividades_propuestas.map((actividad:any)=>
                            <li>
                              <p className="nm">{actividad.actividad}</p>
                            </li>
                          )
                      }
                    </ol>


                </>

              )
          }

          {
            presupuesto.idestatus_presupuesto.id > 3 && (

                <>

        <div className="row">
          <div className="col-sm-12">
          {
            // JSON.stringify(presupuesto)
          }

          <div className={`text-center ${presupuesto.tipo_presupuesto}`} style={{padding: '10px 0'}}  >
            <p className="nm"><small><b>{tipoPresupuesto(presupuesto.tipo_presupuesto)}</b></small></p>
          </div>

                <h5 className="mt-2">Descripción del Presupuesto</h5>
                <p>{presupuesto.descripcion}</p>


          <div className="row">
            <div className="col-sm-4">
                <h5 className="mt-2">Folio</h5>
                <p>{presupuesto.folio}</p>
            </div>
            <div className="col-sm-8">
                <h5 className="mt-2">Boutique</h5>
                <p className="nm">{presupuesto.idboutique.nombre_boutique}</p>
            </div>
          </div>


{
  // JSON.stringify(presupuesto.tipo_presupuesto)
}

          {
            presupuesto.solicitado === 1 && (<p className="nm"><i className="fa fa-star"/> Presupuesto solicitado por la Boutique</p>)
          }


          <h5 className="mt-2">Condiciones de Ejecución</h5>
          <p>{presupuesto.condiciones_ejecucion||'Ninguna en particular'}</p>



          <div className="row">
            <div className="col-sm-4">
                <h5 className="mt-2">Condiciones de Pago</h5>
                <p>{condicionesPago(presupuesto.tipo_condicion_pago)}</p>
            </div>
            <div className="col-sm-4">
                <h5 className="mt-2">Vigencia de Precios</h5>
                <p>{presupuesto.vigencia_dias_precios}</p>
            </div>
            <div className="col-sm-4">
                <h6 className="mt-2">Tiempo Estimado de la Obra</h6>
                <p>{presupuesto.dias_finaliza_estimados} Día(s)</p>
            </div>
          </div>






          <h5 className="mt-2">Detalle de las Actividades a Realizar</h5>
          <ol>
            {
              presupuesto.actividades_propuestas.map((actividad:any)=>
                  <li>
                    <p className="nm">{actividad.actividad}</p>
                  </li>
                )
            }
          </ol>


            {
              // JSON.stringify(presupuesto.material_actividades)
            }

                  <h5 className="mt-2">Explosión de precios unitarios</h5>

                  <table className="table table-sm" style={{fontSize:12}}>
                    <thead>
                      <tr>
                        <th scope="col">Tipo</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Unidad</th>
                        <th scope="col">Cantidad</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      [...presupuesto.material_actividades].sort(ordenarPorTipo).map((material:any,index:number)=>


                      <tr>
                        <td className="v" style={{width:120}}>
                          <p className="nm">
                          {
                            // JSON.stringify(material.idunidad_medida)
                          }
                          <small><b>{tipoActividadLabel(material.tipo)}</b></small>
                          </p>
                        </td>
                        <td>
                          <p className="nm"><small>{material.descripcion}</small></p>
                        </td>
                        <td className="v" style={{width:120}}>
                          <p className="nm"><small>{labelUnidadMedida(material.idunidad_medida,tipo_unidad_medida)}</small></p>
                        </td>
                        <td className="v" style={{width:80}}>
                          <p className="nm text-right">{formatearNumero(parseFloat(material.cantidad)||0)}</p>
                        </td>
                        
                      </tr>

                        )
                    }



                    </tbody>
                  </table>



          </div>
        </div>



          
                </>

              )
          }


          </>

        )
    }


          {
            // JSON.stringify(visita)
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalAsignarPresupuestoVisita;