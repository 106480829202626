import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal, Badge } from 'react-bootstrap';

import { toast } from 'react-toastify'

import moment from 'moment'

import Dialog from '../../services/Dialogs'


import { fechaPaso } from '../../services/funcionesPublicas'


const ModalContratoSeleccionarBoutique = ( params : any ) => {

  const { show, handleClose, boutique } = params

  const dialog = new Dialog();


  const [ contratos, setContratos ] = useState<any>([])
  const cargarContratos = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`cliente/contratos/${boutique.idcliente.uuid}`)
      setContratos(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar todos_los_municipios')
    }
  }



  React.useEffect(() => {
    cargarContratos()
  }, []);



const [ envio_server, setSeEnvio ] = useState<boolean>(false)
const [ uuid_contrato, setUUIDContrato ] = useState<string>('')

const enviarFormulario = async (event:any) =>{
  if(uuid_contrato===''){
    toast.error('Debes de seleccionar un contrato')
    return
  }
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `boutique/seleccionar_contrato/${boutique.uuid}` , { uuid_contrato : uuid_contrato })

        if(res_){
          toast.success('El Contrato se agrego correctamente')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}





  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Selecciona un Contrato</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <p className="nm">Boutique</p>
          <h4>{boutique.nombre_boutique}</h4>

          {
            contratos.map((contrato:any)=>

              <div className="form-check" key={ Math.random().toString(36).substr(2, 9) }>
                <input className="form-check-input" disabled={fechaPaso(new Date(),new Date(contrato.fecha_inicio),new Date(contrato.fecha_fin))} type="radio" name="flexRadioDefault" id={contrato.uuid} checked={contrato.uuid===uuid_contrato} onChange={(e:any)=>{setUUIDContrato(contrato.uuid)}} />
                <label  htmlFor={contrato.uuid}>
                  <p className="nm">{contrato.folio} - {contrato.idtipo_contrato.tipo_contrato} {fechaPaso(new Date(),new Date(contrato.fecha_inicio),new Date(contrato.fecha_fin)) &&(<small className="nm" style={{color:'red'}}>Expirado</small>)}</p>
                  <p className="nm"><small>DEL {moment(contrato.fecha_inicio).format('YYYY-MM-DD')} AL {moment(contrato.fecha_fin).format('YYYY-MM-DD')}</small></p>
                  <p className="nm">
                              
                         { contrato.mantenimiento && (<Badge pill bg="info"> MANTENIMIENTO </Badge>) }
                         {' '}
                         { contrato.conservacion && (<Badge pill bg="info"> CONSERVACIÓN </Badge>) }
                         {' '}
                         { contrato.limpieza && (<Badge pill bg="warning"> LIMPIEZA </Badge>) }

                  </p>

                  {
                    // JSON.stringify(contrato)
                  }
                </label>

              </div>

              )
          }


          {
            // JSON.stringify(contratos)
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" onClick={enviarFormulario} type="button">Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalContratoSeleccionarBoutique;