import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import { Form, Button, Modal, Col, Row } from 'react-bootstrap';


import { Formik } from 'formik';
import * as yup from 'yup';


import { toast } from 'react-toastify'


import Dialog from '../../services/Dialogs'


import { SERVER_ } from  '../../config';


const ModalClienteResponsableLimpieza = ( params : any ) => {

  const { show, handleClose, boutique_ } = params

  const [ boutique, setBoutique ] = useState<any>({ ... boutique_ })

  const dialog = new Dialog();



  const cargarDetalleBoutique = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`boutique/detalle/${boutique_.uuid}`)
      setBoutique(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }




  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
          idresponsable : yup.string().required(mensajes_validacion_generico),
  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro de la información?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.update( `boutique/cambiar_responsable/limpieza/${boutique_.uuid}` , { ...registro })

          if(res_){
            toast.success('MENSAJE')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }





const eliminarResponsable = async (event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.delete( `boutique/responsable_limpieza/${boutique.uuid}`)

        if(res_){
          toast.warning('Se elimino el response')
          setTimeout(()=>{
            cargarDetalleBoutique()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}




  const [ responsables, setResponsables ] = useState<any>([])
  const cargarResponsables = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`boutique/responsables_limpieza/${boutique.uuid}`)
      setResponsables(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarResponsables()
  }, []);



  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Responsable de Limpieza</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(boutique.idresponsable_limpieza)
          }

                      <small>Boutique</small>
                      <h3>{ boutique.nombre_boutique }</h3>


                      <h6 className="mt-3">Contacto</h6>

                      <div className="row">
                        <div className="col">
                          <small>Contacto en Boutique</small>
                          <p className="nm">{boutique.nombre_contacto}</p>
                        </div>
                        <div className="col">
                          <small>Teléfono de Contacto</small>
                          <p className="nm">{boutique.telefono_contacto}</p>
                        </div>
                      </div>


                      <div className="row">
                        <div className="col">
                        <small>Zona</small>
                          <p className="nm">{boutique.idsubarea.idarea}</p>
                        </div>
                        <div className="col">
                        <small>Sub Zona</small>
                          <p className="nm">{boutique.idsubarea.subarea}</p>
                        </div>
                      </div>


  




              {

                boutique.idresponsable_limpieza && (
                    <>
                    {
                      // JSON.stringify(boutique.idusuario_mantenimiento)
                    }

                      <ul className="list-group mt-3">
                        <li className="list-group-item" style={{padding: '2px'}}>
                          <div className="row">
                            <div className="col-md-3">
                              <img src={`${SERVER_}perfil/foto/${boutique.idresponsable_limpieza.uuid}?k=${(Math.floor(Math.random() * 6) + 1)}`} alt="Usuario 1" className="img-thumbnail" style={{width:90,maxWidth:'none !important'}} />
                            </div>
                            <div className="col-md-9">
                              <h5 className="mt-1 mb-1">{boutique.idresponsable_limpieza.nombres} {boutique.idresponsable_limpieza.paterno} {boutique.idresponsable_limpieza.materno} </h5>
                              <p className="nm">RESPONSABLE DE MANTENIMIENTO</p>
                              <p className="nm"><button onClick={eliminarResponsable} className="btn btn-link btn-sm"><i className="fa fa-trash"/></button></p>
                            </div>
                          </div>
                        </li>
                      </ul>

                    </>
                )

              }


                    {

                      !boutique.idresponsable_limpieza && (
                          <>



                        <Formik
                          validationSchema={schema}
                          onSubmit={(values,e)=>{enviarFormulario(values, e)}}
                          initialValues={{
                                idresponsable : "",
                          }}
                        >
                          {({
                            handleSubmit,
                            handleChange,
                            setFieldValue,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors,
                          }) => (
                            <Form id='my-form' noValidate onSubmit={handleSubmit}>

                              <Form.Group  className="mb-3" controlId="idresponsable">
                                <Form.Label>Responsable</Form.Label>
                                <Form.Select required value={values.idresponsable} onChange={(e)=>{ setFieldValue('idresponsable',e.target.value) }} isInvalid={!!errors.idresponsable} >
                                  <option>Selecciona un tipo de Usuario</option>
                                  {
                                    responsables.map((responsable:any)=>
                                        <option key={ Math.random().toString(36).substr(2, 9) } value={responsable.uuid}>{responsable.nombres} {responsable.paterno} {responsable.materno} </option>
                                      )
                                  }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.idresponsable} </Form.Control.Feedback>
                              </Form.Group>


                            </Form>
                          )}
                        </Formik>



                          </>
                        )
                    }

        </Modal.Body>
        

          {

            !boutique.idresponsable_limpieza && (
                <>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Cancelar
                    </Button>
                    <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
                  </Modal.Footer>


                </>
              )
          }


      </Modal>
    </>
  );
}


export default ModalClienteResponsableLimpieza;