import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'



import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'



import moment from 'moment'
import 'moment/locale/es'


import ModalRegistroDepositosRequisiciones from './ModalRegistroDepositosRequisiciones'

import ModalPagoRequisicionesDetalle from './ModalPagoRequisicionesDetalle'

import ModalProgramarPagoRequisicion from './ModalProgramarPagoRequisicion'


import { formatearNumero } from '../../services/funcionesPublicas'

import './requisiciones.scss'


const PagoRequisiciones = () =>{



  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 50
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`pagos_requisiciones/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e:any){

      if (e.response?.status) {
        switch (e.response.status) {
          case 404:
            toast.error("Debes escribir algo")
            break;
          default:
            toast.error(e.response?.data)
        }
      }
      else{
        toast.error("Problemas con la busqueda")
      }
      setCargando(false)

    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true, false, false, false, false, false, false, false, false, false])
  // const [ arreglo_estatus, setEstatus ] = useState<any>([true, true, true, true, true, true, true, true, true, true])


  const todos = () =>{
    setEstatus([true, true, true, true, true, true, true, true, true, true])
  }
  const ninguno = () =>{
    setEstatus([false, false, false, false, false, false, false, false, false, false])
  }





      React.useEffect(() => {
        busqueda()
      }, []);



  const [ show_ModalRegistroDepositosRequisiciones, setModalRegistroDepositosRequisiciones ] = useState<boolean>(false)
  const [ elemento_requisicion_seleccionada, setRequisicionSeleccionada ] = useState<any>(null)
  const handleShow_ModalRegistroDepositosRequisiciones = (elemento_requisicion:any) => {
    setRequisicionSeleccionada(elemento_requisicion)
    setModalRegistroDepositosRequisiciones(true)
  }
  const handleClose_ModalRegistroDepositosRequisiciones = () => {
        busqueda()
    setModalRegistroDepositosRequisiciones(false)
  }



  const [ show_ModalPagoRequisicionesDetalle, setModalPagoRequisicionesDetalle ] = useState<boolean>(false)
  const handleShow_ModalPagoRequisicionesDetalle = (elemento_requisicion:any) => {
    setRequisicionSeleccionada(elemento_requisicion)
    setModalPagoRequisicionesDetalle(true)
  }
  const handleClose_ModalPagoRequisicionesDetalle = () => {
        busqueda()
    setModalPagoRequisicionesDetalle(false)
  }



  const [ show_ModalProgramarPagoRequisicion, setModalProgramarPagoRequisicion ] = useState<boolean>(false)
  const handleShow_ModalProgramarPagoRequisicion = (elemento_requisicion:any) => {
    setRequisicionSeleccionada(elemento_requisicion)
    setModalProgramarPagoRequisicion(true)
  }
  const handleClose_ModalProgramarPagoRequisicion = () => {
        busqueda()
    setModalProgramarPagoRequisicion(false)
  }




      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Pago de Requisiciones</h4>
                    <hr/>
                    
                      <div className="row">
                      
                          <div className="mb-2">
                              <label className="form-label">Búsqueda</label>
                              <div className="hstack gap-3">
                                <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                                <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                                <div className="vr"></div>
                                <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                              </div>
                          </div>
                          
                        <div>
                            <Form.Check inline name="group1" type="checkbox" label="ACEPTADO" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
                            <Form.Check inline name="group1" type="checkbox" label="NO ACEPTADO" checked={arreglo_estatus[1]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}} id={`opciones_2`} />
                            <Form.Check inline name="group1" type="checkbox" label="EN ESPERA DE COMPROBACIÓN" checked={arreglo_estatus[2]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}} id={`opciones_3`} />
                            <Form.Check inline name="group1" type="checkbox" label="VERIFICAR COMPROBACIÓN" checked={arreglo_estatus[3]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}} id={`opciones_4`} />
                            <Form.Check inline name="group1" type="checkbox" label="COMPROBADO" checked={arreglo_estatus[4]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[4] = e.target.checked;  setEstatus(ne);}} id={`opciones_5`} />
                            <Form.Check inline name="group1" type="checkbox" label="NO COMPROBADO" checked={arreglo_estatus[5]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[5] = e.target.checked;  setEstatus(ne);}} id={`opciones_6`} />
                            <Form.Check inline name="group1" type="checkbox" label="DEVOLUCIÓN POR NO COMPROBAR" checked={arreglo_estatus[6]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[6] = e.target.checked;  setEstatus(ne);}} id={`opciones_7`} />
                            <Form.Check inline name="group1" type="checkbox" label="DEVUELTO POR NO COMPROBAR" checked={arreglo_estatus[7]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[7] = e.target.checked;  setEstatus(ne);}} id={`opciones_8`} />
                            <Form.Check inline name="group1" type="checkbox" label="CANCELADO" checked={arreglo_estatus[8]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[8] = e.target.checked;  setEstatus(ne);}} id={`opciones_9`} />
                            <Form.Check inline name="group1" type="checkbox" label="NO NECESITA COMPROBARSE" checked={arreglo_estatus[9]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[9] = e.target.checked;  setEstatus(ne);}} id={`opciones_99`} />
                        </div>




                        <ul className="list-inline">
                          <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                        </ul>

                      </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Folio</th>
                                              <th scope="col"><i className="fa fa-calendar"/></th>
                                              <th scope="col">Factura</th>
                                              <th scope="col">Solicitó</th>
                                              <th scope="col">Compras</th>
                                              <th scope="col">Proveedor</th>
                                              <th scope="col">Estatus</th>
                                              <th scope="col">Descripción</th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (elemento_requisicion:any,indice_elemento:number) =>
                                                  <tr key={indice_elemento} style={{fontSize:12}}>
                                                    <td onClick={(e:any)=>{handleShow_ModalPagoRequisicionesDetalle(elemento_requisicion)}} className="v pointer text-left" style={{"width":"120px"}}>
                                                            
                                                            <small> {elemento_requisicion.folio}</small>

                                                            {
                                                              // JSON.stringify(elemento_requisicion.pago_programado)
                                                            }


                                                    </td>
                                                    <td className={`v text-center ${elemento_requisicion.pago_programado?'facturado':''}`} style={{width:50}}>
                                                        {
                                                          elemento_requisicion.pago_programado && (<>SI</>)
                                                        }
                                                    </td>
                                                    <td className={`v text-center ${elemento_requisicion.con_factura?'facturado':''}`} style={{width:50}}>
                                                        {
                                                          elemento_requisicion.con_factura === 1 && (<>SI</>)
                                                        }
                                                    </td>
                                                    <td className="v text-left" style={{width:280}}>
                                                        <p className="nm"><small><b>{elemento_requisicion.creo_nombre}</b></small></p>
                                                        <small>{elemento_requisicion.creo_paterno} {elemento_requisicion.creo_materno}</small>
                                                    </td>
                                                    <td className={`v text-center ${elemento_requisicion.para_compras?'prov eedor_si':''}`} style={{width:80}}>
                                                        <p className="nm"><small><b>{elemento_requisicion.para_compras?'🛍️':''}</b></small></p>
                                                    </td>
                                                    <td className={`v text-center ${!elemento_requisicion.compra_proveedor?'proveedor_si_':'proveedor_no'}`} style={{width:80}}>
                                                        <p className="nm"><small><b>{elemento_requisicion.compra_proveedor?'SI':''}</b></small></p>
                                                    </td>
                                                    <td className={`v text-left status-${elemento_requisicion.idestatus_elemento} `} style={{width:200}}>
                                                        <p className="nm"><small><b>{elemento_requisicion.estatus}</b></small></p>
                                                        {/*<p className="nm"><small><b>{elemento_requisicion.idestatus_elemento}</b></small></p>*/}
                                                    </td>
                                                    <td className="v text-left" >
                                                        <p className="nm"><small>{elemento_requisicion.concepto}</small></p>
                                                        {
                                                          // JSON.stringify(elemento_requisicion.pago_programado)
                                                        }
                                                        {
                                                          // JSON.stringify(elemento_requisicion.idelemento)
                                                        }
                                                        {
                                                          elemento_requisicion.pago_programado && (
                                                              <p className="nm">
                                                                <small>
                                                                    <i className="fa fa-warning" /> Este pago fue programado para {moment(elemento_requisicion.pago_programado?.fecha_programada).format('DD/MMMM/YYYY').replace('.','').toUpperCase()} 
                                                                      {' '}<b>{elemento_requisicion.pago_programado?.estatus_pago}</b>
                                                                </small>
                                                              </p>
                                                            )
                                                        }
                                                    </td>
                                                    <td className="v text-left" style={{"width":"120px"}}>
                                                      $ {formatearNumero(elemento_requisicion.monto_total)}
                                                    </td>

                                                    <td className="v text-left" style={{"width":"40px"}}>
                                                      {
                                                        [1].indexOf(elemento_requisicion.idestatus_elemento) > -1 && (
                                                            <button disabled={elemento_requisicion.pago_programado} onClick={(e:any)=>{handleShow_ModalProgramarPagoRequisicion(elemento_requisicion)}} className="btn btn-sm btn-warning"><i className="fa fa-calendar"/></button>
                                                          )
                                                      }
                                                    </td>
                                                    <td className="v text-left" style={{"width":"40px"}}>
                                                      {
                                                        [1].indexOf(elemento_requisicion.idestatus_elemento) > -1 && (
                                                            <button disabled={elemento_requisicion.pago_programado} onClick={(e:any)=>{handleShow_ModalRegistroDepositosRequisiciones(elemento_requisicion)}} className="btn btn-sm btn-danger"><i className="fa fa-dollar"/></button>
                                                          )
                                                      }
                                                    </td>


                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}



                </div>
              </div>

            </div>



  {
    show_ModalRegistroDepositosRequisiciones && (
      <ModalRegistroDepositosRequisiciones show={show_ModalRegistroDepositosRequisiciones} handleClose={handleClose_ModalRegistroDepositosRequisiciones} elemento_requisicion={elemento_requisicion_seleccionada} />
      )

  }



  {
    show_ModalPagoRequisicionesDetalle && (
      <ModalPagoRequisicionesDetalle show={show_ModalPagoRequisicionesDetalle} handleClose={handleClose_ModalPagoRequisicionesDetalle}  elemento={elemento_requisicion_seleccionada}  />
      )

  }


  {
    show_ModalProgramarPagoRequisicion && (
      <ModalProgramarPagoRequisicion show={show_ModalProgramarPagoRequisicion} handleClose={handleClose_ModalProgramarPagoRequisicion} elemento_requisicion={elemento_requisicion_seleccionada} />
      )

  }


          </>

      );
  

}

export default PagoRequisiciones
