import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal, Table } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { SERVER_ } from '../../config'

import FichaEmpleado from '../FichaEmpleado'

import Dialog from '../../services/Dialogs'


const ModalAsignamientoHerramientasEmpleado = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, empleado } = params

  const dialog = new Dialog();


  const [ herramientas_asignadas, setHerramientas_asignadas ] = useState<any>([])
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarHerramientas_asignadas = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`empleado/herramientas_asignadas/${empleado.uuid}`)
      setHerramientas_asignadas(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarHerramientas_asignadas()
  }, []);



    

  const desasignarHerramienta = async (event:any,uuid_herramienta:string) =>{

        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: ``
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.delete( `empleado/desasignar_herramienta/${uuid_herramienta}`)

          if(res_){
            toast.success('La herramienta se quitó de la lista de herramientas asignadas a este empleado')
            setTimeout(()=>{
              cargarHerramientas_asignadas()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){

            if (e.response?.status) {
              switch (e.response.status) {
                case 404:
                  toast.error("Debes escribir algo")
                  break;
                default:
                  toast.error(e.response?.data)
              }
            }
            else{
              toast.error("Problemas con la busqueda")
            }
            setSeEnvio(false)

          }
          
        }
       
  }





  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Herramientas Asignadas</Modal.Title>
        </Modal.Header>
        <Modal.Body>


        <FichaEmpleado empleado={empleado} />






                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col" colSpan={2}>Imágenes</th>
                                              <th scope="col">Tipo</th>
                                              <th scope="col"># Serie</th>
                                              <th scope="col">Marca / Modelo</th>
                                              <th scope="col">Estatus</th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              herramientas_asignadas.map( (herramienta:any,indice_herramienta:number) =>
                                                  <tr key={indice_herramienta} style={{fontSize:12}}>
                                                    <td className="v text-left"  style={{"width":"50px"}}  >
                                                             {
                                                              // JSON.stringify(herramienta)
                                                             }
                                                             <img src={`${SERVER_}herramienta_imagen/foto_a/${herramienta.uuid}/${Math.random().toString(36).substr(2, 9)}`} height="45px" />
                                                    </td>
                                                    <td className="v text-left"  style={{"width":"50px"}} >
                                                             {
                                                              // JSON.stringify(herramienta)
                                                             }
                                                             <img src={`${SERVER_}herramienta_imagen/foto_b/${herramienta.uuid}/${Math.random().toString(36).substr(2, 9)}`} height="45px" />
                                                    </td>
                                                    
                                                    <td className="v text-left" style={{"width":"300px"}}>
                                                        <p className="nm"><b> {herramienta.idtipo_herramienta.tipo_herramienta} </b></p>
                                                    </td>
                                                    <td className="v text-left">
                                                        <p className="nm"><small> {herramienta.descripcion} </small></p>
                                                    </td>
                                                    <td className="v text-left"  style={{"width":"120px"}}>
                                                        <p className="nm"><small><b>{herramienta.numero_serie}</b></small></p>
                                                    </td>
                                                    <td className="v text-left"  style={{"width":"140px"}}>
                                                        <p className="nm"><b>{herramienta.marca}</b></p>
                                                        <p className="nm"><small>{herramienta.modelo}</small></p>
                                                    </td>

                                                    <td className="v text-left"  style={{"width":"40px"}}>
                                                        <button type="button" onClick={(e:any)=>{desasignarHerramienta(e,herramienta.uuid)}} className="btn btn-link btn-sm"><i className="fa fa-address-book-o"/></button>
                                                    </td>
                                                    
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


          {
              // JSON.stringify(herramientas_asignadas)
          }
        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalAsignamientoHerramientasEmpleado;