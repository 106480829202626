import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap'

import moment from 'moment'
import 'moment/locale/es'


import AuthService from '../../features/auth/auth.service'

import { obtenerRangoFechas, FormateaNumero, obtenerNombreDia, esHoy, obtenerDiaSemana, ordenarPorTipo, labelUnidadMedida, formatearNumero } from '../../services/funcionesPublicas'

import ModalInfoVisita from './ModalInfoVisita'
import ModalNuevoTicket from './ModalNuevoTicket'


import ModalPDFPrueba from './ModalPDFPrueba'


import Dialog from '../../services/Dialogs'


import ModalReporteSalidaActividad from './ModalReporteSalidaActividad'

import { SERVER_ } from  '../../config';



import ModalRegistroEntradaSalida from './ModalRegistroEntradaSalida'







const Tareas = () =>{


const dialog = new Dialog();



  const user = AuthService.getCurrentUser()


  const dias_para_consultar : any = obtenerRangoFechas( )

  const dia_hoy = moment().format('YYYY-MM-DD')

  const [ cargando_, setCargando ] = useState( true )

  const [ dias_seleccionado, setDiasSeleccionado ] = useState<any>(moment().format('YYYY-MM-DD'))

      const [ reporte, setRegistro ] = useState<any>([])

      const cargar = async (dia:any) =>{
        try{
            setCargando(true)
            let res = await RequestService.getEndPoint(`operador/mis_tareas_diarias/${dia}`)
            setRegistro(res.data)
            setCargando(false)

        }
        catch(e){
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
            setCargando(false)
        }
      }

      React.useEffect(() => {
        cargar(dias_seleccionado)
      }, []);

  const cambiarDia = (dia:any)=>{

    setDiasSeleccionado(dia)

        cargar(dia)
  }



  const [ boutique_seleccionada, setBoutiqueSeleccionada ] = useState<any>({})
  const [ show_ModalInfoTarea, setModalInfoTarea ] = useState<boolean>(false)
  const handleShow_ModalInfoTarea = (boutique:any) => {
    setBoutiqueSeleccionada(boutique)
    setModalInfoTarea(true)
  }
  const handleClose_ModalInfoTarea = () => {
        cargar(dias_seleccionado)
    setModalInfoTarea(false)
  }



  const [ show_ModalNuevoTicket, setModalNuevoTicket ] = useState<boolean>(false)
  const handleShow_ModalNuevoTicket = (boutique:any) => {
    setBoutiqueSeleccionada(boutique)
    setModalNuevoTicket(true)
  }
  const handleClose_ModalNuevoTicket = () => {
    setModalNuevoTicket(false)
  }



  const [ show_ModalPDFPrueba, setModalPDFPrueba ] = useState<boolean>(false)
  const handleShow_ModalPDFPrueba = () => {
    setModalPDFPrueba(true)
  }
  const handleClose_ModalPDFPrueba = () => {
    setModalPDFPrueba(false)
  }


const geolocationAPI = navigator.geolocation;



  const [ actualizar_lista, setActualizaLista ] = useState<boolean>(false)


  const [ tarea_seleccionada, setTareaSeleccionada ] = useState<any>({})
  const [ show_ModalReporteSalidaActividad, setModalReporteSalidaActividad ] = useState<boolean>(false)
  const handleShow_ModalReporteSalidaActividad = (tarea:any) => {
    setTareaSeleccionada(tarea)
    setModalReporteSalidaActividad(true)
  }

  const handleClose_ModalReporteSalidaActividad = () => {
    setModalReporteSalidaActividad(false)
  }

  const handleClose_ModalReporteSalidaActividadRefresh = () => {
      cargar(dias_seleccionado)
    setModalReporteSalidaActividad(false)
  }


 





  const [ show_ModalRegistroEntradaSalida, setModalRegistroEntradaSalida ] = useState<boolean>(false)
  const [ visita_seleccionada, setVisitaSeleccionada ] = useState<any>(null)
  const [ tipo_registro, setTipoRegistro ] = useState<any>("")
  const handleShow_ModalRegistroEntradaSalida = (visita:any,tipo:string) => {
    setVisitaSeleccionada(visita)
    setTipoRegistro(tipo)
    setModalRegistroEntradaSalida(true)
  }
  const handleClose_ModalRegistroEntradaSalida = () => {
      cargar(dias_seleccionado)
    setModalRegistroEntradaSalida(false)
  }



  const [ tipo_unidad_medida, setTipo_unidad_medida ] = useState<any>([])
  const cargarTipo_unidad_medida = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/tipo_unidad_medida`)
      setTipo_unidad_medida(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


      return (
          <>


              <div className="container-fluid">


              


                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">


                         <h3 className="mt-3">Mis Tareas Diarias</h3>
                         <p className="nm"><i className={`fa fa-circle`} style={{color: user.rol === 'ROLE_TECNICO' ? '#8bc34a':'#03a9f4'}} /> Tareas Programadas</p>


                         {
                          // JSON.stringify(dias_para_consultar)
                         }



                        <ButtonGroup className="d-flex mt-3 mb-4">

                            <ToggleButton
                              id={`radio-1`}
                              type="radio"
                              variant={ 'outline-primary' }
                              name="radio"
                              value={3}
                              checked={dias_seleccionado === dias_para_consultar[0]}
                              onChange={(e) => cambiarDia(dias_para_consultar[0])}
                            >
                              Ayer
                            </ToggleButton>
                            <ToggleButton
                              id={`radio-2`}
                              type="radio"
                              variant={ 'outline-primary' }
                              name="radio"
                              value={3}
                              checked={dias_seleccionado === dias_para_consultar[1]}
                              onChange={(e) => cambiarDia(dias_para_consultar[1])}
                            >
                              Hoy
                            </ToggleButton>
                            <ToggleButton
                              id={`radio-3`}
                              type="radio"
                              variant={ 'outline-primary' }
                              name="radio"
                              value={3}
                              checked={dias_seleccionado === dias_para_consultar[2]}
                              onChange={(e) => cambiarDia(dias_para_consultar[2])}
                            >
                              Mañana
                            </ToggleButton>
                        </ButtonGroup>


                         {
                          // JSON.stringify(dias_para_consultar)
                         }

                          {  (!cargando_) && (

                              <>

                                {
                                  // JSON.stringify(reporte)
                                }
                                                                      {
                                                                        // dias_seleccionado
                                                                      }


                                {
                                  reporte.map((visita:any)=>

                                    <React.Fragment key={ Math.random().toString(36).substr(2, 9) } >

                                      {
                                        // [3,4,5,6,7,8,9,99].indexOf(visita.idestatus_atencion.id) === -1 && (
                                        true && (
                                            <React.Fragment>


                                                      {
                                                        // JSON.stringify(visita.uuid)
                                                      }
                                                      {
                                                        // JSON.stringify(repo)
                                                      }

                                                                      {
                                                                        // visita.fecha_programada
                                                                      }


                                                                <div  className={`card mt-2`} >
                                                                  <div className="card-body">
                                                                  
                                                                    <h5 className="card-title">{visita.idboutique.nombre_boutique}</h5>
                                                                    <h6 className="card-subtitle mb-.2 text-muted">{visita.idboutique.idestado.estado}, {visita.idboutique.idmunicipio.municipio}</h6>

                                                                    <dl className="row nm">

                                                                      <dt className="col-sm-4 b">Folio</dt>
                                                                      <dd className="col-sm-8 nm">{visita.folio}</dd>

                                                                      <dt className="col-sm-4 b">Contacto</dt>
                                                                      <dd className="col-sm-8 nm">{visita.idboutique.nombre_contacto===''?'Sin Contacto Asignado':visita.idboutique.nombre_contacto} <small><i className="fa fa-phone"/> {FormateaNumero(visita.idboutique.telefono)}</small></dd>

                                                                      <dt className="col-sm-4 b">Fecha</dt>
                                                                      <dd className="col-sm-8 nm">
                                                                        {
                                                                          // <small>{obtenerNombreDia(moment(dias_seleccionado).day())} {moment(visita.fecha_programada).format('DD [de] MMMM [de] YYYY')}</small>
                                                                        }
                                                                          <small>
                                                                                  {
                                                                                    obtenerDiaSemana(visita.fecha_programada).toUpperCase()
                                                                                  },
                                                                                  {' '}

                                                                                  {
                                                                                    moment(visita.fecha_programada).format('DD [de] MMMM [de] YYYY').toUpperCase()
                                                                                  }
                                                                        </small>
                                                                      </dd>

{
                                                                      // <dt className="col-sm-4 b">Horario de Boutique</dt>
                                                                      // <dd className="col-sm-8 nm">
                                                                      //       { moment(dias_seleccionado).day() === 0 && ( <> {visita.idboutique.horario_domingo}</> )  }
                                                                      //       { moment(dias_seleccionado).day() === 1 && ( <> {visita.idboutique.horario_lunes}</> )  }
                                                                      //       { moment(dias_seleccionado).day() === 2 && ( <> {visita.idboutique.horario_martes}</> )  }
                                                                      //       { moment(dias_seleccionado).day() === 3 && ( <> {visita.idboutique.horario_miercoles}</> )  }
                                                                      //       { moment(dias_seleccionado).day() === 4 && ( <> {visita.idboutique.horario_jueves}</> )  }
                                                                      //       { moment(dias_seleccionado).day() === 5 && ( <> {visita.idboutique.horario_viernes}</> )  }
                                                                      //       { moment(dias_seleccionado).day() === 6 && ( <> {visita.idboutique.horario_sabado}</> )  }
                                                                      // </dd>
}
                                                                      <dt className="col-sm-4 b">Tipo de Servicio</dt>
                                                                      <dd className={`col-sm-8 nm tipo_orden_${visita.idtipo_orden.id}`}>{visita.idtipo_orden.tipo_orden}</dd>


                                                                      <dt className="col-sm-4 b">Estatus</dt>
                                                                      <dd className={`col-sm-8 nm estatus_atencion_${visita.idestatus_atencion.id}`} style={{color:'white',padding: '4px 15px'}}>{visita.idestatus_atencion.estatus}</dd>



                                                                      <dt className="col-sm-4 b">Descripción</dt>
                                                                      <dd className={`col-sm-8 nm `}>{visita.indicaciones}</dd>



                                                                    {
                                                                      visita.idboutique.idresponsable_limpieza && (
                                                                          <React.Fragment>
                                                                      <dt className="col-sm-4 b">Responsable</dt>
                                                                      <dd className={`col-sm-8 nm`}>{visita.idboutique.idresponsable_limpieza.nombres} <small><i className="fa fa-phone"/> {FormateaNumero(visita.idboutique.idresponsable_limpieza.celular)}</small></dd>
                                                                          </React.Fragment> 
                                                                      )
                                                                    }




                                                                    </dl>






                                                                    
                                                                    <p className="card-text nm"><b> </b> </p>






                                                                    {
                                                                      // SI NO HA TERMINADO EL SERVICIO SE MUESTRA
                                                                    }
                                                                    {
                                                                      [3,4,5,6,7,8,9,99].indexOf(visita.idestatus_atencion.id) === -1 && (
                                                                          <React.Fragment>
                                                                            

                                                                              {
                                                                                visita.idpresupuesto && (
                                                                                    <>

                    {
                      visita.material_actividades.length > 0 && (
                          <React.Fragment>
                                    
                             <h5 className="mt-2">Materiales y Servicios Presupuestados</h5>
         

                            <table className="table table-sm mt-2"  >
                              <thead>
                                <tr>
                                  <th scope="col">Descripción</th>
                                  <th scope="col">Unidad</th>
                                  <th scope="col">Cantidad</th> 
                                </tr>
                              </thead>
                              <tbody>
                              {
                                [...visita.material_actividades].sort(ordenarPorTipo).map((material:any,index:number)=>


                                <tr key={ Math.random().toString(36).substr(2, 9) }> 
                                  <td>
                                    <p className="nm"><small>{material.descripcion}</small></p>
                                  </td>
                                  <td className="v" style={{width:180}}>
                                    <p className="nm"><small>{material.idunidad_medida?.unidad_medida}</small></p>
                                  </td>
                                  <td className="v" style={{width:80}}>
                                    <p className="nm text-right">{formatearNumero(parseFloat(material.cantidad)||0)}</p>
                                  </td>  
                                </tr>

                                  )
                              }


                              </tbody>
                            </table>
                            
                          </React.Fragment>
                        )
                    }



                                                                                    </>
                                                                                  )
                                                                              }



                                                                          </React.Fragment>
                                                                        )
                                                                    }




                                                                        {
                                                                          [7].indexOf(visita.idtipo_orden.id) > -1 && (
                                                                              <React.Fragment>
                                                                                {
                                                                                  visita.idboutique.conservacion_instrucciones_por_dia !== null && (
                                                                                      <React.Fragment>

                                                                                        <dl className="row nm">
                                                                                              <dt className="col-sm-4 b">Instrucciones para hoy: </dt>
                                                                                              <dd className={`col-sm-8 nm `}>
                                                                                                
                                                                                                { moment(visita.fecha_programada).day() === 1 && (visita.idboutique.conservacion_instrucciones_por_dia.lunes||'Realizar actividades normales') }
                                                                                                { moment(visita.fecha_programada).day() === 2 && (visita.idboutique.conservacion_instrucciones_por_dia.martes||'Realizar actividades normales') }
                                                                                                { moment(visita.fecha_programada).day() === 3 && (visita.idboutique.conservacion_instrucciones_por_dia.miercoles||'Realizar actividades normales') }
                                                                                                { moment(visita.fecha_programada).day() === 4 && (visita.idboutique.conservacion_instrucciones_por_dia.jueves||'Realizar actividades normales') }
                                                                                                { moment(visita.fecha_programada).day() === 5 && (visita.idboutique.conservacion_instrucciones_por_dia.viernes||'Realizar actividades normales') }
                                                                                                { moment(visita.fecha_programada).day() === 6 && (visita.idboutique.conservacion_instrucciones_por_dia.sabado||'Realizar actividades normales') }
                                                                                                { moment(visita.fecha_programada).day() === 7 && (visita.idboutique.conservacion_instrucciones_por_dia.domingo||'Realizar actividades normales') }

                                                                                              </dd>
                                                                                        </dl>

                                                                                      </React.Fragment>
                                                                                    )
                                                                                } 

 
                                                                                          


                                                                              </React.Fragment>
                                                                            )
                                                                        }










                                                                    {
                                                                      // JSON.stringify(visita.idboutique.equipos_criticos)
                                                                    }





                                                                          <div className="btn-group mt-2" role="group" aria-label="Basic outlined example">



                                                                              <Button onClick={()=>{window.open(`http://maps.google.com/maps?z=12&t=m&q=loc:${visita.idboutique.localizacion.x}+${visita.idboutique.localizacion.y}`)}} variant="outline-primary"><i className="fa fa-globe"/></Button>
                                                                              <Button onClick={(e:any)=>{handleShow_ModalInfoTarea(visita)}} variant="outline-primary"><i className="fa fa-info"/></Button>
                                                                              {
                                                                                dias_seleccionado === moment().format('YYYY-MM-DD') && (
                                                                                    <>
                                                                                    <Button onClick={(e:any)=>{handleShow_ModalNuevoTicket(visita.idboutique)}} variant="outline-primary">Ticket</Button>
                                                                                    {
                                                                                      // <button className="btn btn-link" onClick={handleShow_ModalPDFPrueba}><i className="fa fa-eye"/></button>
                                                                                    }
                                                                                    </>
                                                                                )
                                                                              }



                                                                              {
                                                                                visita.idestatus_atencion.id == 1 && (
                                                                                    <>
                                                                                      {
                                                                                        dias_seleccionado === moment().format('YYYY-MM-DD') && (
                                                                                            <React.Fragment>
                                                                                              {
                                                                                                // <Button onClick={(e:any)=>{confirmarreporteEntradaSalida(e,repo,true)}} variant="outline-warning">Entrada</Button>
                                                                                              }
                                                                                              <Button onClick={(e:any)=>{handleShow_ModalRegistroEntradaSalida(visita,"ENTRADA")}} variant="outline-warning">Entrada</Button>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                      }
                                                                                    </>
                                                                                  )
                                                                              }

                                                                              {
                                                                                // JSON.stringify(visita.fecha_programada)
                                                                              }
                                                                              {
                                                                                 [2,3].indexOf(visita.idestatus_atencion.id) > -1 && (
                                                                                    <>
                                                                                      {
                                                                                        dias_seleccionado === moment().format('YYYY-MM-DD') && (
                                                                                          <></>
                                                                                            // <Button onClick={(e:any)=>{confirmarreporteEntradaSalida(e,repo,false)}} variant="outline-info">Salida</Button>
                                                                                        )
                                                                                      }
                                                                                              <Button onClick={(e:any)=>{handleShow_ModalRegistroEntradaSalida(visita,"SALIDA")}} variant="outline-info">Salida</Button> 
                                                                                    </>
                                                                                  )
                                                                              }


                                                                              {
                                                                                (visita.idestatus_atencion.id == 2 && !visita.boutique_reporta_servicio_finalizado) && (
                                                                                    <>
                                                                                      <Button onClick={(e:any)=>{handleShow_ModalReporteSalidaActividad(visita)}} variant="outline-info">Entrega</Button>
                                                                                    </>
                                                                                  )
                                                                              }





                                                                          </div>





                                                                                  {
                                                                                    visita.tiene_acceso !== null && (
                                                                                        <React.Fragment>
                                                                                          {
                                                                                            [1,2].indexOf(visita.idestatus_atencion.id) > -1 && (
                                                                                                <div className="alert alert-primary mt-4" role="alert">
                                                                                                    <a href={`${SERVER_}anexo_acceso/${visita.tiene_acceso.uuid}`}  target="_blank">Ficha de Acceso</a>
                                                                                                </div>
                                                                                              )
                                                                                          }


                                                                              {
                                                                                // JSON.stringify(visita.tiene_acceso.uuid)
                                                                              }

                                                                                        </React.Fragment>
                                                                                      )
                                                                                  }


                                                                  </div>
                                                                </div>




                                            </React.Fragment> 
                                          )
                                      }

                                    </React.Fragment>
                                  )
                                }


                                {
                                  reporte.length === 0 && (
                                        <div className="alert alert-warning"  >
                                          <p className="nm text-center"><i className="fa fa-warning"/> No tienes calendarizado actividades de Mantenimiento para este día</p>
                                        </div>

                                    )
                                }

                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}







                    </div>
                  </div>


              </div>
              




  {
    show_ModalPDFPrueba && (
      <ModalPDFPrueba show={show_ModalPDFPrueba} handleClose={handleClose_ModalPDFPrueba}  />
      )

  }


  {
    show_ModalInfoTarea && (
      <ModalInfoVisita show={show_ModalInfoTarea} handleClose={handleClose_ModalInfoTarea} boutique={boutique_seleccionada} />
      )

  }


  {
    show_ModalNuevoTicket && (
      <ModalNuevoTicket show={show_ModalNuevoTicket} handleClose={handleClose_ModalNuevoTicket} boutique={boutique_seleccionada}  />
      )

  }





  {
    show_ModalReporteSalidaActividad && (
      <ModalReporteSalidaActividad show={show_ModalReporteSalidaActividad} handleClose={handleClose_ModalReporteSalidaActividad} handleCloseRefresh={handleClose_ModalReporteSalidaActividadRefresh} tarea={tarea_seleccionada} setActualizaLista={setActualizaLista} />
      )

  }






  {
    show_ModalRegistroEntradaSalida && (
      <ModalRegistroEntradaSalida show={show_ModalRegistroEntradaSalida} handleClose={handleClose_ModalRegistroEntradaSalida} visita={visita_seleccionada} tipo={tipo_registro} />
      )

  }




          </>

      );
  

}

export default Tareas
