import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { formatearNumero, condicionesPago, calcularTiempoTranscurrido, tipoActividadLabel, ordenarPorTipo } from '../../services/funcionesPublicas'
import { tipoPresupuesto } from '../../services/funcionesPublicas'

import Dialog from '../../services/Dialogs'

import moment from 'moment'

import { SERVER_ } from  '../../config';





const ModalDetallePago = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, presupuesto } = params




  const [ suma_subtotal, setSuma ] = useState<number>(0)

  const sumarSubtotales = () =>{
    let s = 0
    // console.log(presupuesto.material_actividades)
    for(let actividad of presupuesto.material_actividades){
      s += ( actividad.costo * actividad.cantidad )
    }
    setSuma(s)

  }




  // const [ show_ModalGenerarPagoServicio, setModalGenerarPagoServicio ] = useState<boolean>(false)
  // const handleShow_ModalGenerarPagoServicio = () => {
  //   setModalGenerarPagoServicio(true)
  // }
  // const handleClose_ModalGenerarPagoServicio = () => {
  //   setModalGenerarPagoServicio(false)
  // }



  React.useEffect(() => {
    sumarSubtotales() 
  }, []);





    const [file, setFile] = useState(null);
    const [base64String, setBase64String] = useState<any>("");
    const [fileType, setFileType] = useState<any>("");

    const handleChange = (event:any) => {
        let selectedFile = event.target.files[0];

        if (selectedFile) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setBase64String(reader.result);
            };

            reader.readAsDataURL(selectedFile);
            setFile(selectedFile);

            // Determinar el tipo de archivo
            const type = selectedFile.type.split('/')[1];
            if (type === "pdf" || type === "png" || type === "jpg" || type === "jpeg") {
                setFileType(type);
            }
        }
    };

    const renderPreview = () => {
        if (fileType === "pdf") {
            return (
                <iframe
                    src={base64String}
                    style={{ height: '400px', width: '100%', border: 'none' }}
                />
            );
        } else if (["png", "jpg", "jpeg"].includes(fileType)) {
            return (
                <img
                    src={base64String}
                    alt="Preview"
                    style={{ maxHeight: '400px', maxWidth: '100%', display: 'block', margin: 'auto' }}
                />
            );
        } else {
            return null;
        }
    };











// generar_pago_presupuesto/:uuid_presupuesto



  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Detalle de Pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            presupuesto.uuid_presupuesto
          }








        <div className="row">
          <div className="col-sm-4">

          <h6 className="mt-2">Folio *</h6>
          <p>{presupuesto.folio}</p>

          {presupuesto.idboutique&&(
              <React.Fragment>
                <h6 className="mt-2">Boutique</h6>
                <p className="nm">{presupuesto.idboutique.nombre_boutique}</p>
                  {
                    presupuesto.solicitado && (<p className="nm"><i className="fa fa-star"/> Solicitado por la Boutique</p>)
                  }
              </React.Fragment>
            )}



          <h6 className="mt-">Fecha de Creación</h6>
          <p className="nm">{moment(presupuesto.fecha_creacion).format('YYYY-MM-DD hh:mm:ss')}</p>
          <p className="nm"><small>{calcularTiempoTranscurrido(presupuesto.fecha_creacion)}</small></p>

          <h5 className="mt-2">Descripción General</h5>
          <p>{presupuesto.descripcion}</p>

          <h6 className="mt-2">Descripción de la Solicitud de Presupuesto</h6>
          <ol>
            {
              presupuesto.actividades_propuestas.map((actividad:any)=>
                  <li>
                    <p className="nm"><small>{actividad.actividad}</small></p>
                  </li>
                )
            }
          </ol>











          </div>
          <div className="col-sm-8">
            {
              // JSON.stringify(presupuesto.material_actividades)
            }

                  <h5 className="mt-2">Explosión de precios unitarios</h5>


                  <table className="table table-sm" style={{fontSize:12}}>
                    <thead>
                      <tr>
                        <th scope="col">Tipo</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Unidad</th>
                        <th scope="col">Cantidad</th>
                        <th scope="col">P.U.</th>
                        <th scope="col">Costo</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      [...presupuesto.material_actividades].sort(ordenarPorTipo).map((material:any,index:number)=>


                      <tr>
                        <td className="v" style={{width:120}}>
                          <p className="nm">
                          {
                            // JSON.stringify(material.idunidad_medida)
                          }
                          <small><b>{tipoActividadLabel(material.tipo)}</b></small>
                          </p>
                        </td>
                        <td>
                          <p className="nm"><small>{material.descripcion}</small></p>
                        </td>
                        <td className="v" style={{width:120}}>
                          {material.idunidad_medida&&(<p className="nm"><small>{material.idunidad_medida.unidad_medida}</small></p>)}
                        </td>
                        <td className="v" style={{width:80}}>
                          <p className="nm text-right">{formatearNumero(parseFloat(material.cantidad)||0)}</p>
                        </td>
                        <td className="v" style={{width:80}}>
                          <p className="nm text-right">$ {formatearNumero(parseFloat(material.costo)||0)}</p>
                        </td>
                        <td className="v" style={{width:80}}>
                          <p className="nm text-right">$ {formatearNumero(parseFloat((material.costo)||0)*(parseFloat(material.cantidad)||0))}</p>
                        </td>
                      </tr>

                        )
                    }



                      <tr className="table-subs">
                        <td className="v" style={{width:100}} colSpan={4}>
                          <p className="nm text-right"><b>Deposito</b></p>
                        </td>
                        <td className="v" style={{width:100}} colSpan={2}>
                          <p className="nm text-right"><h3 className="nm">$ {formatearNumero(suma_subtotal||0)}</h3></p>
                        </td>
                      </tr>






                    </tbody>
                  </table>






        <div>
            <div className="mb-3">
              <label className="form-label">Email address</label>
              <input type="file" onChange={handleChange} accept=".png,.jpg,.jpeg,.pdf" className="form-control" />
            </div>
            {renderPreview()}
            {
            //   base64String && (
            //     <div>
            //         <p>File in Base64:</p>
            //         <textarea 
            //             readOnly 
            //             value={base64String} 
            //             rows="5" 
            //             cols="30"
            //         />
            //     </div>
            // )
            }
        </div>


          </div>
        </div>






        </Modal.Body>



        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" disabled={!file} type="button">CREAR REGISTRO DE PAGO</Button>
        </Modal.Footer>

      </Modal>










    </>
  );
}


export default ModalDetallePago;