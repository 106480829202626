import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import moment from 'moment'


const ModalDetalleCitaServicio = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, evento } = params

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Detalles de la Actividad x</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <div className="row">
            <div className="col-sm-6">
              <small>Folio</small>
              <h5>{evento.extendedProps.metadata.folio}</h5>
            </div>
            <div className="col-sm-6">
              <small>Fecha</small>
              <h5>{moment(evento.extendedProps.metadata.fecha_programada).format('YYYY-MM-DD')}</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <small>Boutique</small>
              <h5>{evento.extendedProps.metadata.idboutique.nombre_boutique}</h5>
              <p>{evento.extendedProps.metadata.idboutique.direccion}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <small>Estatus</small>
              <h5>{evento.extendedProps.metadata.idestatus_atencion.estatus}</h5>
            </div>
            <div className="col-sm-6">
              <small>Tipo de Servicio</small>
              <h5>{evento.extendedProps.metadata.idtipo_orden.tipo_orden}</h5>
            </div>
          </div>


<div className="alert alert-warning mt-4" role="alert">
  <p className="nm"><i className="fa fa-warning"/> Favor de Facilitar el Acceso a los siguientes representanes del corporativo</p>
</div>

          <div className="row mb-3">
            <div className="col-sm-6">
              <small>Técnico</small>
              <h5 className="nm">{evento.extendedProps.metadata.idtecnico.nombres} </h5>
              <p className="nm"><small>{evento.extendedProps.metadata.idtecnico.paterno} {evento.extendedProps.metadata.idtecnico.materno}</small></p>
              <h5 className="nm">CURP</h5>
              <p className="nm"><small>{evento.extendedProps.metadata.idtecnico.curp}</small></p>
            </div>

            {evento.extendedProps.metadata.idresponsable !== null && (

                        <div className="col-sm-6">
                          <small>Responsable</small>
                            <h5 className="nm">{evento.extendedProps.metadata.idresponsable.nombres} </h5>
                            <p className="nm"><small>{evento.extendedProps.metadata.idresponsable.paterno} {evento.extendedProps.metadata.idresponsable.materno}</small></p>
                            <h5 className="nm">CURP</h5>
                            <p className="nm"><small>{evento.extendedProps.metadata.idresponsable.curp}</small></p>

                        </div>

                )
            }

                      </div>


          {
            // JSON.stringify(evento.extendedProps.metadata)
          }
        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalDetalleCitaServicio;