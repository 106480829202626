import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import Dialog from '../../services/Dialogs'

import moment from 'moment'




const ModalReprogramarPagoProgramado = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, pago_programado, reprogramar } = params


  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          detalles_pago : yup.string().required(mensajes_validacion_generico),
          fecha_programada : yup.string().required(mensajes_validacion_generico),

          idproveedor : yup.string(), //.required(mensajes_validacion_generico),

          uuid_tipo_ingreso : yup.string().required(mensajes_validacion_generico),
          monto : yup.number().required(mensajes_validacion_generico),

  });



  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.update( `pago_programados/${ reprogramar ? 'reprogramar' : 'editar' }/${pago_programado.uuid_pago_programado}` , { ...registro, fecha_programada : moment(registro.fecha_programada).format('YYYY-MM-DD') } )

          if(res_){
            toast.warning('El Registro de Pago se reprogramó correctamente')
            setTimeout(()=>{
              // handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }



  const [ egresos, setRegistos] = useState<any>([])

  const cargarRegistros = async() => {

    try{
      let res = await RequestService.getEndPoint('catalogo_egresos')
      setRegistos(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar')
    }

  }



  const [ proveedores, setProveedores ] = useState<any>([])
  const cargarProveedores = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/proveedores`)
      setProveedores(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }




  React.useEffect(() => {
    cargarProveedores()
    cargarRegistros()
  }, []);


  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Reprogramar Pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(pago_programado)
          }



    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            detalles_pago : pago_programado.detalles_pago,
            idproveedor : pago_programado.idproveedor ? pago_programado.uuid_proveedor : '',
            fecha_programada : new Date(pago_programado.fecha_programada),
            uuid_tipo_ingreso : pago_programado.uuid_tipo_ingreso, 
            monto : pago_programado.monto,

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>

          {
            // JSON.stringify(values)
          }




            {
              reprogramar && (

                    <div className="row">
                      <div className="col-sm-12">
                          <div className="mb-3">
                            <Form.Label>Fecha</Form.Label>
                            <DatePicker selected={values.fecha_programada} className="form-control"  dateFormat="yyyy-MM-dd" minDate={new Date()} onChange={(date) => setFieldValue('fecha_programada', date ) } />
                          </div>
                      </div>
                    </div>
                    
                )
            }




            {
              !reprogramar && (

                  <>




            <Form.Group className="mb-3" controlId="detalles_pago">
              <Form.Label>Detalles del Pago</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                required
                name="detalles_pago"
                value={values.detalles_pago}
                onBlur={(e)=>{ setFieldValue('detalles_pago', e.target.value.toUpperCase() ) }}
                onChange={(e)=>{ setFieldValue('detalles_pago', e.target.value ) }}
                isInvalid={!!errors.detalles_pago}
                style={{textTransform:'uppercase'}}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.detalles_pago}</>} </Form.Control.Feedback>
            </Form.Group>




            <Form.Group className="mb-3">
              <Form.Label>Monto</Form.Label>
              <Form.Control
                type="text"
                required
                name="monto"
                value={values.monto}
                onChange={(e)=>{ setFieldValue('monto', e.target.value ) }}
                isInvalid={!!errors.monto}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.monto}</>} </Form.Control.Feedback>
            </Form.Group>


                <Form.Group className="mb-4" controlId="idproveedor">
                  <Form.Label>Selecciona el Proveedor</Form.Label>
                  <Form.Select required name="idproveedor" value={values.idproveedor} onChange={(e)=>{ setFieldValue('idproveedor', e.target.value ) }} isInvalid={!!errors.idproveedor}>
                    <option value="">SELECCIONA EL PROVEEDOR</option>
                    {
                      proveedores.map( (proveedor:any,proveedor_indice:number) =>
                        <React.Fragment key={proveedor_indice}>
                                  <option value={proveedor.uuid}> {proveedor.proveedor}</option>
                        </React.Fragment>
                      )
                    }

                  </Form.Select>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">{<>{errors.idproveedor}</>}</Form.Control.Feedback>
                </Form.Group>


              <div className="mb-3">
                <label className="form-label">Tipo de Egreso</label>
                  <Form.Select aria-label="Selección de Tipo de Egreso" required value={values.uuid_tipo_ingreso} onChange={(e)=>{ setFieldValue('uuid_tipo_ingreso',e.target.value) }} isInvalid={!!errors.uuid_tipo_ingreso} >
                    <option>Selecciona el Tipo de Egreso</option>
                    { egresos.map((ingreso:any)=><option key={ Math.random().toString(36).substr(2, 9) } value={ingreso.uuid}>{ingreso.descripcion}</option>)}

                  </Form.Select>
                  <Form.Control.Feedback type="invalid"> {<>{errors.uuid_tipo_ingreso}</>} </Form.Control.Feedback>
              </div>


                  
                  </>
                    
                )
            }







        </Form>
      )}
    </Formik>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalReprogramarPagoProgramado;