import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'


import { Table } from 'react-bootstrap'


import { SERVER_ } from '../../config'


import { estadoCuidadoHerramienta } from '../../services/funcionesPublicas'


const Herramientas = () =>{

      const [ herramientas, setRegistro ] = useState<any>([])

      const cargar = async () =>{
        try{
            let res = await RequestService.getEndPoint(`mis_herramientas`)
            setRegistro(res.data)

        }
        catch(e){
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
        }
      }

      React.useEffect(() => {
        cargar()
      }, []);


      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Mis Herramientas </h4>

                    {
                      // JSON.stringify(herramientas)
                    }


                    {
                      herramientas.length === 0 && (
                          <React.Fragment>
                            🛠️ No tienes herramientas asignadas o materiales
                          </React.Fragment>
                        )
                    }

                    {
                      herramientas.length > 0 && (

                    <Table responsive="lg">
                      <thead>
                        <tr>
                          <th scope="col" colSpan={4}>Imágen</th>
                          <th scope="col">Herramienta</th>
                          <th scope="col"># Serie</th>
                          <th scope="col">Marca / Modelo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {


                          herramientas.map( (herramienta:any,indice:number) =>
                              <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                <td className="v text-left"  style={{"width":"50px"}}  >
                                         {
                                          // JSON.stringify(herramienta)
                                         }
                                         <img src={`${SERVER_}herramienta_imagen/foto_a/${herramienta.uuid}/${Math.random().toString(36).substr(2, 9)}`} width="100%" />
                                </td>
                                <td className="v text-left"  style={{"width":"50px"}}  >
                                         {
                                          // JSON.stringify(herramienta)
                                         }
                                         <img src={`${SERVER_}herramienta_imagen/foto_b/${herramienta.uuid}/${Math.random().toString(36).substr(2, 9)}`} width="100%" />
                                </td>
                                <td className="v text-left"  style={{"width":"50px"}}  >
                                         {
                                          // JSON.stringify(herramienta)
                                         }
                                         <img src={`${SERVER_}herramienta_imagen/foto_c/${herramienta.uuid}/${Math.random().toString(36).substr(2, 9)}`} width="100%" />
                                </td>
                                <td className="v text-left"  style={{"width":"50px"}}  >
                                         {
                                          // JSON.stringify(herramienta)
                                         }
                                         <img src={`${SERVER_}herramienta_imagen/foto_d/${herramienta.uuid}/${Math.random().toString(36).substr(2, 9)}`} width="100%" />
                                </td>
                                
                                <td className="v text-left"   >
                                    <p className="nm"><b> {herramienta.descripcion} </b></p>
                                    <p className="nm"><small> {herramienta.idtipo_herramienta.tipo_herramienta} </small></p>
                                </td>
                                <td className="v text-left"  style={{"width":"120px"}}>
                                    <p className="nm"><small><b>{herramienta.numero_serie}</b></small></p>
                                </td>
                                <td className="v text-left"  style={{"width":"200px"}}>
                                    <p className="nm"><b>{herramienta.marca}</b></p>
                                    <p className="nm"><small>{herramienta.modelo}</small></p>
                                </td>
                                <td className="v text-left"  style={{"width":"100px"}}>
                                    <p className="nm"><small><b>{estadoCuidadoHerramienta(herramienta.estado)}</b></small></p>
                                </td>

                                
                              </tr>
                           )
                        }

                      </tbody>
                    </Table>


                        )
                    }





                </div>
              </div>

            </div>

          </>

      );
  

}

export default Herramientas
