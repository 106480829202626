import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import Dialog from '../../services/Dialogs'

const ModalAreasAtencionBoutique = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, boutique } = params

const dialog = new Dialog();


  
    const [ areas_atencion, setAreasAtencion ] = useState<any>(boutique.areas_atencion )


    const [ envio_server, setSeEnvio ] = useState<boolean>(false)
    const enviarFormulario = async (event:any) =>{

          await dialog.open({
            accept: 'Si',
            cancel: 'Espera',
            dialogClass: '',
            message: '¿Estás seguro de la información?',
            target: event.target,
            template: ``
          })
          let dialogo : any = await dialog.waitForUser()
          if(dialogo){
            try{

            setSeEnvio(true)
            let res_ = await RequestService.update( `boutique/areas_atencion/${boutique.uuid}` , { areas_atencion: areas_atencion })
            if(res_){
                toast.success('Las Áreas de Atención fueron actualizadas')
            }
            else{
              toast.error('Problemas...')
            } 
            }
            catch(e:any){
              setSeEnvio(true)
              toast.error(e.response.data)
              // console.log(e.response.data)
            } 
            
          }
         
    }




const agregarArea = async (event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        let n : any
        if(areas_atencion){
          n = [...areas_atencion]
        }
        else{
          n = []
        }
          n.push({
            area : "NUEVA ÁREA",
            lista_caminata_supervision : []
          })

          setAreasAtencion(n)
        
      }
     
}


const eliminarArea = async (event:any,indice:number) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        
          let n = [...areas_atencion]

          n.splice(indice,1)

          setAreasAtencion(n)
        
      }
     
}




    

const agregarListaCaminata = async (event:any,indice:number) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        let n = [...areas_atencion]

        // n.splice(1,indice)
        n[indice].lista_caminata_supervision.push("")

          setAreasAtencion(n)
        
      }
     
}


const eliminarRegistroLista = async (event:any,indice_area:number,indice_lista:number) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        let n = [...areas_atencion]

        n[indice_area].lista_caminata_supervision.splice(indice_lista,1)

          setAreasAtencion(n)
        
      }
     
}


    


  return (
    <>
      <Modal show={show}  onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Áreas de Atención</Modal.Title>
        </Modal.Header>
        <Modal.Body>

                      <small>Boutique</small>
                      <h3>{ boutique.nombre_boutique }</h3>

          {
            // JSON.stringify(areas_atencion)
          }
          {
            !areas_atencion && (
                <React.Fragment>
                    <div className="alert alert-danger" role="alert">
                      Es importante configurar las áreas de atención.
                    </div>
                </React.Fragment> 
              )
          }




          {
            areas_atencion && (
                <React.Fragment>
                  {
                    // JSON.stringify(areas_atencion)
                  }

                  {
                    areas_atencion.map((area:any,indice:number)=>

                              <div key={indice}  className="card">
                                <div className="card-header">
                                  {
                                    // JSON.stringify(area.area)
                                  }

                            <div className="hstack gap-3">
                              <input className="form-control me-auto" type="text" value={area.area} onChange={(e:any)=>{ let a = [...areas_atencion]; a[indice].area = e.target.value.toUpperCase(); setAreasAtencion(a) }} />
                              <div className="vr"></div>
                              <button type="button" onClick={(e:any)=>{eliminarArea(e,indice)}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                            </div>


                                </div>
                                <div className="card-body">
                                  <p><b>Lista de Inspección para Supervisión </b></p>
                                  {
                                    // JSON.stringify(area.lista_caminata_supervision.length)
                                  }

                                    {
                                      area.lista_caminata_supervision.length === 0 && (
                                          <React.Fragment>
                                              <div className="alert alert-danger" role="alert">
                                                Es importante agregar la lista de inspección para Supervisión.
                                              </div>
                                          </React.Fragment> 
                                        )
                                    }



                                    {
                                      area.lista_caminata_supervision.length > 0 && (
                                          <React.Fragment>
                                              {
                                                area.lista_caminata_supervision.map((registro:any,indice_lista:number)=>
                                                        <div key={indice_lista} className="hstack gap-3 mb-2">
                                                          <input className="form-control  form-control-sm me-auto" type="text" value={registro} onChange={(e:any)=>{ let a = [...areas_atencion]; a[indice].lista_caminata_supervision[indice_lista] = e.target.value.toUpperCase(); setAreasAtencion(a) }} />
                                                          <div className="vr"></div>
                                                          <button type="button" onClick={(e:any)=>{eliminarRegistroLista(e,indice,indice_lista)}} className="btn btn-sm btn-outline-danger"><i className="fa fa-trash"/></button>
                                                        </div>
                                                  )
                                              }

                                          </React.Fragment> 
                                        )
                                    }
                                    <button type="button" onClick={(e:any)=>{agregarListaCaminata(e,indice)}} className="btn btn-link">Agregar Tarea</button>

                                </div>
                              </div>

                      )
                  }



                </React.Fragment>
              )
          }


          <button type="button" onClick={agregarArea} className="btn btn-link">Agregar Área</button>





        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar Ventana
          </Button>
          <Button variant="success" type="button" onClick={enviarFormulario}>Guardar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalAreasAtencionBoutique;