import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import { Table, Form, Modal, Tabs, Tab } from 'react-bootstrap'

import moment from 'moment'
import 'moment/locale/es'


import ModalCalendarioPreviewCliente from './ModalCalendarioPreviewCliente'


import ModalCargarAccesos from './ModalCargarAccesos'


import { calcularDiasPasados, obtenerDiaSemana } from '../../services/funcionesPublicas'




import ModalVisitasEmpleados from './ModalVisitasEmpleados'


import { SERVER_ } from  '../../config';


const CalendarioPreviewCliente = () =>{

      const [ config, setRegistro ] = useState<any>(null)
      const cargar = async () =>{
        try{
            let res = await RequestService.getEndPoint(`calendario/preview_boutique`)
            setRegistro(res.data)

        }
        catch(e){
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
        }
      }

      React.useEffect(() => {
        cargar()
      }, []);


      const [ ver_dias_libres, setDiasLibres ] = useState<boolean>(false)




  const [ show_ModalCalendarioPreviewCliente, setModalCalendarioPreviewCliente ] = useState<boolean>(false)
  const handleShow_ModalCalendarioPreviewCliente = () => {
    setModalCalendarioPreviewCliente(true)
  }
  const handleClose_ModalCalendarioPreviewCliente = () => {
    setModalCalendarioPreviewCliente(false)
  }


const ordenarPorFechaDescendente = (arreglo:any) => {
    return arreglo.sort(function(a:any, b:any) {
        const fechaA : any = new Date(a.fecha_programada);
        const fechaB : any = new Date(b.fecha_programada);
        return fechaB - fechaA;
    });
}



  const [ visita_seleccionada, setVisitaSeleccionada ] = useState<any>(null)

  const [ show_ModalCargarAccesos, setModalCargarAccesos ] = useState<boolean>(false)
  const handleShow_ModalCargarAccesos = (visita:any) => {
    setVisitaSeleccionada(visita)
    setModalCargarAccesos(true)
  }
  const handleClose_ModalCargarAccesos = () => {
        cargar()
    setModalCargarAccesos(false)
  }





  const [ show_ModalVisitasEmpleados, setModalVisitasEmpleados ] = useState<boolean>(false)
  const handleShow_ModalVisitasEmpleados = () => {
    setModalVisitasEmpleados(true)
  }
  const handleClose_ModalVisitasEmpleados = () => {
    setModalVisitasEmpleados(false)
  }





      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Propuesta de Visitas del Periodo en Curso</h4>
                    <hr/>


                    {
                      config === null && (

                          <>
                            <p className="nm">Actualmente no tienes una propuesta de visitas del período.</p>
                          </>

                        )
                    }


                    {
                      (config !== null && config.estatus === 'CONFIGURANDO') && (

                          <>
                            
                            <div className="alert alert-info" role="alert">
                              <p className="nm">📅 {' '} El Calendario está por publicarse</p>
                            </div>
                          </>

                        )
                    }


                    {
                      (config !== null && config.estatus === 'PREVIEW_CLIENTE') && (

                          <>

                          {
                            // JSON.stringify(config.estatus)
                          }

                            <div className="row">
                              <div className="col-sm-4">
                                <small>Fecha de Inicio del Periodo</small>
                                <h5>{moment(config.fecha_inicio).format('DD [DE] MMMM [DE] YYYY').toUpperCase()}</h5>
                              </div>
                              <div className="col-sm-4">
                                <small>Fecha de Fin del Periodo</small>
                                <h5>{moment(config.fecha_fin).format('DD [DE] MMMM [DE] YYYY').toUpperCase()}</h5>
                              </div>
                              <div className="col-sm-1">
                                <small>Días del Periodo</small>
                                <h5>{calcularDiasPasados(config.fecha_inicio,config.fecha_fin)}</h5>
                              </div>
                              <div className="col-sm-1 align-items-center">
                                <button className="btn btn-link" onClick={handleShow_ModalCalendarioPreviewCliente}><i className="fa fa-calendar-o"/></button>
                              </div>
                              <div className="col-sm-2 align-items-center">
                                {
                                  config.calendario_publicado && (<p className="nm">Calendario de Visitas Sugerido </p>)
                                }
                                {
                                  !config.calendario_publicado && (<p className="nm">Sin Aplicar</p>)
                                }
                                {
                                  config.preview_boutique && (<p className="nm">Vista de Boutique </p>)
                                }
                                {
                                  // !config.preview_boutique && (<p className="nm">preview_boutique </p>)
                                }
                              </div>
                            </div>


                            <div className="row">
                              <div className="col-sm-12">
                                  <button className="btn btn-link" onClick={handleShow_ModalVisitasEmpleados}>Ver documentación de empleados</button>
                              </div>
                            </div>


                            {
                              config.formateado.length === 0 && (
                                  <div className="alert alert-info" role="alert">
                                      <p className="nm">📅 {' '} Aún no tienes configuradas visitas. Puedes abrir un Ticket para solicitar o cambiar alguna fecha de visita</p>
                                  </div>

                                )
                            }

                            {
                              config.formateado.length > 0 && (

                                <React.Fragment>
{
  // JSON.stringify(ordenarPorFechaDescendente(config.formateado))
}



    <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mt-3">
      <Tab eventKey="home" title="Visitas Propuestas para el Período">






                                    <h5 className="mt-4">Visitas Propuestas para el Período</h5>


                                    <table className="table table-sm" >
                                      <thead>
                                        <tr>


                                              {
                                                ( !ver_dias_libres ) && (
                                                    <>
                                                      <th scope="col">TIPO</th>
                                                      <th scope="col">Técnico</th>
                                                      <th scope="col">Boutique</th>
                                                      <th scope="col">Fecha</th>
                                                      <th scope="col"></th>
                                                    </>
                                                  )
                                              }


                                        </tr>
                                      </thead>
                                      <tbody>{
                                              ordenarPorFechaDescendente(config.formateado).map((fecha:any,index_fecha:number)=>

                                                  <React.Fragment key={index_fecha}>

                                                    {
                                                      (fecha.folio !== 'DIA_LIBRE' && !ver_dias_libres) && (

                                                    <tr >
                                                      <td className={`v tipo_orden_${fecha.tipo_orden}`} style={{width:250}}>
                                                        <p className="nm"><b>{fecha.tipo}</b></p>
                                                      </td>
                                                      <td className="v"  >
                                                        <p className="nm"><small>{fecha.idtecnico.nombres} {fecha.idtecnico.paterno} {fecha.idtecnico.materno}</small></p>
                                                      </td>
                                                      <td className="v" style={{width:250}}>
                                                        <p className="nm">{fecha.idboutique.nombre_boutique}</p>
                                                      </td>
                                                      {

                                                      // <td className="v" style={{width:180}}>
                                                      //   <p className="nm"><small>{fecha.idboutique.idsubarea.subarea}</small></p>
                                                      // </td>
                                                      }
                                                      <td className="v" style={{width:200}}>
                                                        <p className="nm"><b><small>{moment(fecha.fecha_programada).format('DD [DE] MMMM [DE] YYYY').toUpperCase()}</small></b></p>
                                                      </td>
                                                      <td className="v" style={{width:30}}>
                                                      {
                                                        // JSON.stringify(fecha.tipo_orden)
                                                      }
                                                        {
                                                          fecha.tiene_acceso && (
                                                              <React.Fragment>
                                                                <button onClick={(e:any)=>{handleShow_ModalCargarAccesos(fecha)}} className="btn btn-info btn-sm"><small><i className="fa fa-cloud-upload"/></small></button>

                                                              </React.Fragment>
                                                            )
                                                        }
                                                        {
                                                          !fecha.tiene_acceso && [1,4].indexOf(fecha.tipo_orden) > -1 && (
                                                              <React.Fragment>
                                                                <button onClick={(e:any)=>{handleShow_ModalCargarAccesos(fecha)}} className="btn btn-link btn-sm"><small><i className="fa fa-cloud-upload"/></small></button>
                                                              </React.Fragment>
                                                            )
                                                        }
                                                      </td>
                                                    </tr>

                                                        )
                                                    }


                                                  </React.Fragment>

                                                )

                                            }
                                      </tbody>
                                    </table>





      </Tab>
      <Tab eventKey="profile" title="Visitas Calendarizadas">


                                    {
                                      config.visitas.length > 0 && (


                                        <React.Fragment>



                                        </React.Fragment>

                                        )
                                    }
                                    {
                                      config.visitas.length > 0 && (

                                        <React.Fragment>
                                          
                                            <h5>Visitas Calendarizadas</h5>


                                            <table className="table table-sm"  >
                                              <thead>
                                                <tr>


                                                      {
                                                        ( !ver_dias_libres ) && (
                                                            <>
                                                              <th scope="col">TIPO</th>
                                                              <th scope="col">Técnico</th>
                                                              <th scope="col">Boutique</th>
                                                              <th scope="col">Fecha</th>
                                                              <th scope="col"></th>
                                                            </>
                                                          )
                                                      }


                                                </tr>
                                              </thead>
                                              <tbody>{
                                                      ordenarPorFechaDescendente(config.visitas).map((fecha:any,index_fecha:number)=>

                                                          <React.Fragment key={index_fecha}>

                                                            {
                                                              (fecha.folio !== 'DIA_LIBRE' && !ver_dias_libres) && (

                                                            <tr >
                                                              <td className={`v tipo_orden_${fecha.idtipo_orden.id}`} style={{width:250}}>
                                                                <p className="nm"><b>{fecha.idtipo_orden.tipo_orden}</b></p>
                                                              </td>
                                                              <td className="v"  >
                                                                <p className="nm"><small>{fecha.idtecnico.nombres} {fecha.idtecnico.paterno} {fecha.idtecnico.materno}</small></p>
                                                              </td> 
                                                              <td className="v" style={{width:250}}>
                                                                <p className="nm">{fecha.idboutique.nombre_boutique}</p>
                                                              </td>
                                                              {

                                                              // <td className="v" style={{width:180}}>
                                                              //   <p className="nm"><small>{fecha.idboutique.idsubarea.subarea}</small></p>
                                                              // </td>
                                                              }
                                                              <td className="v" style={{width:200}}>
                                                                <p className="nm"><b><small>{moment(fecha.fecha_programada).format('DD [DE] MMMM [DE] YYYY').toUpperCase()}</small></b></p>
                                                              </td>
                                                              <td className="v" style={{width:30}}>
                                                              {
                                                                // JSON.stringify(fecha.tipo_orden)
                                                              }
                                                                {
                                                                  fecha.tiene_acceso && (
                                                                      <React.Fragment>
                                                                        <button onClick={(e:any)=>{handleShow_ModalCargarAccesos(fecha)}} className="btn btn-info btn-sm"><small><i className="fa fa-cloud-upload"/></small></button>

                                                                      </React.Fragment>
                                                                    )
                                                                }
                                                                {
                                                                  !fecha.tiene_acceso   && (
                                                                      <React.Fragment>
                                                                        <button onClick={(e:any)=>{handleShow_ModalCargarAccesos(fecha)}} className="btn btn-link btn-sm"><small><i className="fa fa-cloud-upload"/></small></button>
                                                                      </React.Fragment>
                                                                    )
                                                                }
                                                              </td>
                                                            </tr>

                                                                )
                                                            }


                                                          </React.Fragment>

                                                        )

                                                    }
                                              </tbody>
                                            </table>

                                        </React.Fragment>

                                        )
                                    }




      </Tab>
    </Tabs>



                                </React.Fragment>

                                )
                            }



                          </>

                        )
                    }









                    {
                      // JSON.stringify(config)
                    }
                    
                </div>
              </div>

            </div>








  {
    show_ModalCalendarioPreviewCliente && (
      <ModalCalendarioPreviewCliente show={show_ModalCalendarioPreviewCliente} handleClose={handleClose_ModalCalendarioPreviewCliente} calendario={config.preview_calendario}  />
      )

  }



  {
    // show_ModalPreviewCalendarioAdmin && (
    //   <ModalPreviewCalendarioAdmin show={show_ModalPreviewCalendarioAdmin} handleClose={handleClose_ModalPreviewCalendarioAdmin} calendario={config.preview_calendario} />
    //   )

  }




  {
    show_ModalCargarAccesos && (
      <ModalCargarAccesos show={show_ModalCargarAccesos} handleClose={handleClose_ModalCargarAccesos} visita={visita_seleccionada} />
      )

  }





  {
    show_ModalVisitasEmpleados && (
      <ModalVisitasEmpleados show={show_ModalVisitasEmpleados} handleClose={handleClose_ModalVisitasEmpleados}  />
      )

  }







          </>

      );
  

}

export default CalendarioPreviewCliente
