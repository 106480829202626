import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { obtenerDiaSemana, contarNoBaseODescanso } from '../../services/funcionesPublicas'

import moment from 'moment'

const ModalAsignarEquiposCritiosVisita = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, setConfiguracion, configuracion, index_areas,index_subareas,index_tecnicos } = params

  const tecnico = {...configuracion.areas[index_areas].subareas[index_subareas].tecnicos[index_tecnicos]}

  const buscarBoutiquePorUUID = (boutiques:any, uuid_boutique:string)=>{
    return boutiques.find((boutique:any) => boutique.uuid_boutique === uuid_boutique);
}

const verificaCadenaEnArreglo = (cadena:string, arreglo:any)=> {
    // if (arreglo.length === 0) {
    //     return true;
    // } else {
        return arreglo.includes(cadena);
    // }
}

 

  const agregarEquipoAVisita = (indice_fecha:number, indice_arreglo:number,uuid_equipo:string)=>{
    let cambios = {...configuracion} 
    cambios.areas[index_areas].subareas[index_subareas].tecnicos[index_tecnicos].fechas[indice_fecha].equipos_criticos.push(uuid_equipo)
    setConfiguracion(cambios)
    // console.log(cambi)
  }
  const quitarEquipoAVisita = (indice_fecha:number, uuid_equipo:string)=>{
    let cambios = {...configuracion} 
    console.log()
    cambios.areas[index_areas].subareas[index_subareas].tecnicos[index_tecnicos].fechas[indice_fecha].equipos_criticos = cambios.areas[index_areas].subareas[index_subareas].tecnicos[index_tecnicos].fechas[indice_fecha].equipos_criticos.filter((elemento:any) => elemento !== uuid_equipo)

    setConfiguracion(cambios)
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Configurar Equipos Críticos de la Visita</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <small>Técnico</small>
          <h3>{tecnico.nombres} {tecnico.paterno} {tecnico.materno} </h3>


          {
            // JSON.stringify(configuracion[index_areas][index_subareas][index_tecnicos])
          }
          {
            // JSON.stringify(configuracion.areas[index_areas].subareas[index_subareas].tecnicos[index_tecnicos].fechas)
          }
          {
            // contarNoBaseODescanso(configuracion.areas[index_areas].subareas[index_subareas].tecnicos[index_tecnicos].fechas)
          }

          <p>
            <small>Fechas de Visitas Asignadas:</small>
          </p>

          {
            contarNoBaseODescanso(configuracion.areas[index_areas].subareas[index_subareas].tecnicos[index_tecnicos].fechas) > 0 && (

                <>


          {
            configuracion.areas[index_areas].subareas[index_subareas].tecnicos[index_tecnicos].fechas.map((fecha:any,indice_fecha:number)=>

                <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>

                  {
                    // JSON.stringify(fecha)
                  }

                      {
                        ["DESCANSO","BASE"].indexOf(fecha.idboutique) === -1  &&(

                            <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>

                                <div className="alert alert-dark">
                                    <div className="row">
                                      <div className="col-sm-6">
                                          <small>BOUTIQUE</small>
                                          <h6>{(buscarBoutiquePorUUID(configuracion.areas[index_areas].subareas[index_subareas].boutiques,fecha.idboutique)).nombre_boutique}</h6>
                                      </div>
                                      <div className="col-sm-6">
                                          <small>FECHA</small>
                                          <h6>{obtenerDiaSemana(fecha.fecha).toUpperCase()}, {moment(fecha.fecha).format('DD [DE] MMMM [DE] YYYY').toUpperCase()}</h6>
                                      </div>
                                    </div>



                                {
                                  // JSON.stringify(buscarBoutiquePorUUID(configuracion.areas[index_areas].subareas[index_subareas].boutiques,fecha.idboutique).equipos_criticos)
                                }

                                <small>Equipos Críticos para Atender</small>
                                <ol>
                                  {
                                    buscarBoutiquePorUUID(configuracion.areas[index_areas].subareas[index_subareas].boutiques,fecha.idboutique).equipos_criticos.map((equipo:any)=>
                                        <li key={ Math.random().toString(36).substr(2, 9) }> 
                                          <label className="nm"> 
                                            <input type="checkbox" checked={verificaCadenaEnArreglo(equipo.uuid,fecha.equipos_criticos)} onChange={(e:any)=>{ if(!verificaCadenaEnArreglo(equipo.uuid,fecha.equipos_criticos)) agregarEquipoAVisita(indice_fecha,indice_fecha,equipo.uuid); else quitarEquipoAVisita(indice_fecha,equipo.uuid);  }} />
                                            {' '}
                                            {equipo.idequipo.modelo} <b>[{equipo.idequipo.marca}]</b></label>
                                {
                                  // JSON.stringify(verificaCadenaEnArreglo(equipo.uuid,fecha.equipos_criticos))
                                }
                                            {
                                              // <p style={{fontSize:12}}>{JSON.stringify(equipo)}</p>
                                            }
                                        </li>
                                      )
                                  }
                                </ol>




                                {
                                  // JSON.stringify(fecha.equipos_criticos)
                                }   

                                </div>





                                {
                                  // JSON.stringify(buscarBoutiquePorUUID(configuracion.areas[index_areas].subareas[index_subareas].boutiques,fecha.idboutique))
                                }
                            </React.Fragment>
                          )
                      }
                </React.Fragment>

              )
          }

                </>

              )
          }



        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalAsignarEquiposCritiosVisita;