import React, { useState, useRef } from 'react';

import RequestService from "../services/request.service";

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import Dialog from '../services/Dialogs'



import AvatarEditor from 'react-avatar-editor'

// import ReactCrop, {
//   centerCrop,
//   makeAspectCrop,
//   Crop,
//   PixelCrop,
// } from 'react-image-crop'
// import 'react-image-crop/dist/ReactCrop.css'


const ModalCambiarFoto = ( params : any ) => {

  const dialog = new Dialog()


//     const imgRef = useRef<HTMLImageElement>(null)

// const [aspect, setAspect] = useState<number | undefined>(9 / 9)

  const { show, handleClose, usuario } = params

// const [crop, setCrop] = useState<Crop>({
//   unit: 'px', // Can be 'px' or '%'
//   x: 25,
//   y: 25,
//   width: 50,
//   height: 50
// })

//   const [completedCrop, setCompletedCrop] = useState<PixelCrop>()

// const centerAspectCrop = ( mediaWidth: number, mediaHeight: number, aspect: number ) => {
//   return centerCrop(
//     makeAspectCrop(
//       {
//         unit: '%',
//         width: 100,
//         height: 100,
//       },
//       aspect,
//       mediaWidth,
//       mediaHeight,
//     ),
//     mediaWidth,
//     mediaHeight,
//   )
// }

// const imageToDataUri = (img:any, width:any, height:any)=> {

//     // create an off-screen canvas
//     var canvas : any = document.createElement('canvas'),
//         ctx : any = canvas.getContext('2d');

//     // set its dimension to target size
//     canvas.width = width;
//     canvas.height = height;

//     // draw source image into the off-screen canvas:
//     ctx.drawImage(img, 0, 0, width, height);

//     // encode image to data-uri with base64 version of compressed image
//     return canvas.toDataURL();
// }

  const [imgSrc, setImgSrc] = useState('')
  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      // setCrop({undefined}) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>{
        let imagen = reader.result?.toString() || ''
        if(imagen!==''){
          var imag_ = new Image();
          imag_.src = imagen;
          // console.log(imag_)
          // var newDataUri = imageToDataUri(imag_, targetWidth, targetHeight)
        }
        setImgSrc(imagen)

      })
      reader.readAsDataURL(e.target.files[0])
    }
  }


//   const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
//     if (aspect) {
//       // const { width, height } = e.currentTarget
//       // setCrop(centerAspectCrop(width, height, aspect))
//       const { width, height } = e.currentTarget
//       setAspect(9 / 9)
//       setCrop(centerAspectCrop(width, height, 9 / 9))
//     }
//   }


  const onClickSave = () => {
    if (editor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      const canvas = editor.getImage()

      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      const canvasScaled = editor.getImageScaledToCanvas()
    }
  }


  const [ editor, setEditorRef ] = useState<any>({})
  // const setEditorRef = (editor) => (this.editor = editor)




  // const onSelectFile = ( ) =>{}

const confirmarundefined = async(e:any) => {
    try{

        await dialog.open({
          accept: 'SI',
          cancel: 'NO',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: e.target,
          template: ``
        })
        let dialogo : any = await dialog.waitForUser()
        
        if(dialogo){
      const canvas = editor.getImage()

      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      const canvasScaled = editor.getImageScaledToCanvas()
      // console.log(editor.getImage().toDataURL())

          let res = await RequestService.update('perfil/cambiar_foto/'+usuario.uuid, { foto : editor.getImage().toDataURL().split(',')[1], crop : "crop" } )
          if(res.data){
            toast.success('Se actualizo el estatus del Trámite')
            // await cargarMiReporte(pagina)
            handleClose()
          }
          
        }

    }
    catch(e){
      toast.error('Tuvimos problemas al intentar finalizar la verificación del trámite')
    }

  }


  const [ skale, setSkale ] = useState<any>(1)


  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Actualizar Foto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(usuario)
          }
          <small>Usuario</small>
          <h5>{usuario.nombres} {usuario.paterno} {usuario.materon}</h5>

          <div className="mb-3">
            <label htmlFor="formFile" className="form-label">Selecciona una foto</label>
            <input className="form-control" type="file" accept="image/*" onChange={onSelectFile} />
          </div>

          {
            imgSrc && (
                <>
                  <AvatarEditor ref={setEditorRef} image={imgSrc} width={400} height={400} border={50} scale={skale} />

                  <p>
                    <input type='range' onChange={(e:any)=>{setSkale(e.target.value)}} min={1} max={10} step={.1} value={skale} className='custom-slider' />
                  </p>

                </>
              )
          }


          
{
        // <ReactCrop crop={crop} onComplete={(c) => setCompletedCrop(c)} onChange={c => setCrop(c)} aspect={aspect}>
        //   <img src={imgSrc} onLoad={onImageLoad} />
        // </ReactCrop>
      }

{
  // JSON.stringify(imgSrc)
}


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success"   onClick={(e)=>{confirmarundefined(e)}} type="button">Cambiar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalCambiarFoto;