import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Form, Button, Modal } from 'react-bootstrap';

import { Formik } from 'formik';
import * as yup from 'yup';

import { toast } from 'react-toastify'

import Dialog from '../../services/Dialogs'

const ModalNuevaSubzona = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params



  const dialog = new Dialog();



  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

        subarea : yup.string().required(mensajes_validacion_generico),
        idarea : yup.string().required(mensajes_validacion_generico),

  });



const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `subarea/nueva` , { ...registro })

        if(res_){
          toast.success('La Ruta fue agregada correctamente')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}




  const [ areas, setAreas ] = useState<any>([]);
  const cargarAreas = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('todas_las_areas')
      setAreas(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar las Zonas')
    }
  }
  

  React.useEffect(() => {
    cargarAreas()
  }, []);




  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Nueva Ruta</Modal.Title>
        </Modal.Header>
        <Modal.Body>


    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            idarea : "",
            subarea : "",

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>




            <Form.Group   className="mb-3" controlId="subarea">
              <Form.Label>Ruta</Form.Label>
              <Form.Control
                required
                name="subarea"
                value={values.subarea}
                onBlur={(e)=>{ setFieldValue('subarea', e.target.value.toUpperCase() ) }}
                onChange={(e)=>{ setFieldValue('subarea', e.target.value ) }}
                style={{textTransform:'uppercase'}}
                isInvalid={!!errors.subarea}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.subarea}</>} </Form.Control.Feedback>
            </Form.Group>




              <div className="alert alert-primary" role="alert">
                    <Form.Group controlId="idarea">
                      <Form.Label>Zona</Form.Label>
                        {
                          areas.map((area:any)=>
                            <Form.Check inline isInvalid={!!errors.idarea} type={'radio'} id={`default-${area.uuid}`} label={area.area} checked={values.idarea===area.uuid} onChange={(e:any)=>{setFieldValue('idarea',area.uuid)}} key={ Math.random().toString(36).substr(2, 9) }/>
                          )
                        }

                        <Form.Control.Feedback type="invalid">{<>{errors.idarea}</>}</Form.Control.Feedback>
                    </Form.Group>
              </div>


 


        </Form>
      )}
    </Formik>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalNuevaSubzona;