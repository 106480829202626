import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import Dialog from '../../services/Dialogs'

import moment from 'moment'



const ModalAlmacenEditarInformacion = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, almacen } = params




  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
          almacen : yup.string().required(mensajes_validacion_generico),
          detalles : yup.string().required(mensajes_validacion_generico),
  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro de la información?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.update( `almacen/editar_informacion/${almacen.uuid}` , { ...registro })

          if(res_){
            toast.warning('Cambiamos la información del Almacén')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }


  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Editar Información del Almacén</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }



    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{
            almacen : almacen.almacen,
            detalles : almacen.detalles,
      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>


            <Form.Group className="mb-3" controlId="almacen">
              <Form.Label>Nombre del Almacen</Form.Label>
              <Form.Control 
                required
                name="almacen"
                value={values.almacen}
                onChange={(e)=>{ setFieldValue('almacen', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.almacen}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.almacen}</>} </Form.Control.Feedback>
            </Form.Group>



            <Form.Group className="mb-3" controlId="detalles">
              <Form.Label>Detalles </Form.Label>
              <Form.Control
                as="textarea"
                required
                name="detalles"
                value={values.detalles}
                onChange={(e)=>{ setFieldValue('detalles', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.detalles}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.detalles}</>} </Form.Control.Feedback>
            </Form.Group>




        </Form>
      )}
    </Formik>




        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalAlmacenEditarInformacion;