import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { Routes, Route, NavLink, Outlet, useLocation } from 'react-router-dom';

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

const Proveedores = () =>{

      // const [ registro, setRegistro ] = useState<any>([])

      // const cargar = async () =>{
      //   try{
      //       let res = await RequestService.getEndPoint(`URL_REQUEST`)
      //       setRegistro(res.data)

      //   }
      //   catch(e){
      //     toast.error('Problemas cargando')
      //     toast.error(JSON.stringify(e))
      //   }
      // }

      // React.useEffect(() => {
      //   cargar()
      // }, []);


      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Proveedores</h4>
                    <hr/>
                    
                    <nav id="sidebar" className="mt-4">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <NavLink to="/proveedores/lista" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : "" } >Lista de Proveedores</NavLink>
                        </li>
                        <li className="breadcrumb-item">
                          <NavLink to="/proveedores/articulos" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : "" } >Lista de Artículos</NavLink>
                        </li>
                      </ol>
                    </nav>


                    <Outlet />



                </div>
              </div>

            </div>

          </>

      );
  

}

export default Proveedores
