import React, { useRef, useEffect, useState } from 'react';
import { PDFDocument, StandardFonts, rgb, PDFFont } from 'pdf-lib';
import pica from 'pica';

import {Button, Modal } from 'react-bootstrap';

import moment from 'moment'

import { toast } from 'react-toastify'


import AuthService from '../../features/auth/auth.service'


import { Formik, getIn, Form, Field, FieldArray } from 'formik';
import * as yup from 'yup';


const FieldComponent = ({ field, form: { touched, errors } }:any) => {
  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);
  // console.log(field.value)
  return (
    <div className="mt-2">
      <textarea className="form-control" name={field.name} rows={5} value={field.value} onChange={field.onChange}></textarea>
      {touch && error ? <p className="invalid-feedback" style={{display:'inline'}}>{error}</p> : null}
    </div>
  )
}


// const FieldArrayComponent = (arrayHelpers:any) => (
//   <div>
//     {arrayHelpers.form.values.people.map((person:any, index:number) => (
//       <div className="row" key={index}>
//         <div className="col-sm-4">
//             oi oi
//         </div>
//         <div className="col-sm-8">
//           <Field name={`people.${index}.name`} component={FieldComponent} />
//           <Field name={`people.${index}.surname`} component={FieldComponent} />
//           <button type="button" onClick={() => arrayHelpers.push({ name: '', surname: '' })}>+</button>
//           <button type="button" onClick={() => arrayHelpers.remove(index)}>-</button>
//         </div>
//       </div>
//     ))}
//     <div>
//       <button type="submit">Submit</button>
//     </div>
//   </div>
// )


const ModalPDFPrueba = ( params : any ) => {


  const { show, handleClose } = params

  const user = AuthService.getCurrentUser()


  const fileInputRef = useRef<any>(null);

function base64ToArrayBuffer(base64:any) {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

function convertirCoordenadasPdfALienzo(x:number, y:number) {
  const pdfWidth = 612;
  const pdfHeight = 792;

  const lienzoWidth = 612;
  const lienzoHeight = 792;

  const lienzoX = (x / pdfWidth) * lienzoWidth;
  const lienzoY = (y / pdfHeight) * lienzoHeight;

  return { x: lienzoX, y: lienzoY };
}

function fillParagraph(text: string, font: PDFFont, fontSize: number, maxWidth: number) {
  const paragraphs = text.split('\n')
  for (let index = 0; index < paragraphs.length; index++) {
    const paragraph = paragraphs[index]
    if (font.widthOfTextAtSize(paragraph, fontSize) > maxWidth) {
      const words = paragraph.split(' ')
      const newParagraph: string[][] = []
      let i = 0
      newParagraph[i] = []
      for (let k = 0; k < words.length; k++) {
        const word = words[k]
        newParagraph[i].push(word)
        if (
          font.widthOfTextAtSize(newParagraph[i].join(' '), fontSize) > maxWidth
        ) {
          newParagraph[i].splice(-1) // retira a ultima palavra
          i = i + 1
          newParagraph[i] = []
          newParagraph[i].push(word)
        }
      }
      paragraphs[index] = newParagraph.map((p) => p.join(' ')).join('\n')
    }
  }
  return paragraphs.join('\n')
    // var paragraphs = text.split('\n');
    // for (let index = 0; index < paragraphs.length; index++) {
    //     var paragraph = paragraphs[index];
    //     if (font.widthOfTextAtSize(paragraph, fontSize) > maxWidth) {
    //         var words = paragraph.split(' ');
    //         var newParagraph = [];
    //         var i = 0;
    //         newParagraph[i] = [];
    //         for (let k = 0; k < words.length; k++) {
    //             var word = words[k];
    //             newParagraph[i].push(word);
    //             if (font.widthOfTextAtSize(newParagraph[i].join(' '), fontSize) > maxWidth) {
    //                 newParagraph[i].splice(-1); // retira a ultima palavra
    //                 i = i + 1;
    //                 newParagraph[i] = [];
    //                 newParagraph[i].push(word);
    //             }
    //         }
    //         paragraphs[index] = newParagraph.map(p => p.join(' ')).join('\n');
    //     }
    // }
    // return paragraphs.join('\n');
}







  async function generarPDFConImagenes() {
    const files : any = fileInputRef.current.files;
    let archis = []

    if (files.length === 0) {
      alert('Selecciona al menos una imagen.');
      return;
    }

  const letterWidth = 612; // 8.5 x 72 (72 DPI)
  const letterHeight = 792; // 11 x 72 (72 DPI)
// console.log(StandardFonts)

    const pdfDoc = await PDFDocument.create();
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const fontB = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    // Agregar página de carátula
    const coverPage = pdfDoc.addPage();
    coverPage.setSize(letterWidth, letterHeight);
    const { width: coverWidth, height: coverHeight } = coverPage.getSize();

    // console.log('coverWidth / 2',(coverWidth / 2))
    // console.log('coverHeight / 2',(coverHeight / 2))
    let x, y
    let equis_ye = convertirCoordenadasPdfALienzo(0,0)
    x = equis_ye.x
    y = equis_ye.y
    // const { x, y } = convertirCoordenadasPdfALienzo(0,0)


const coverImage : any = await loadImageFromURL(`${window.location.origin}/fondo_reporte_tecnicos.png`);
const fondoImagen : any = await loadImageFromURL(`${window.location.origin}/fondo_reporte_pagina.png`);
// console.log("coverImage")
// console.log(coverImage.arrayBuffer())

// console.log(coverImage)

      const coverImage_ = await pdfDoc.embedPng(coverImage);
      const fondoImagen_ = await pdfDoc.embedPng(fondoImagen);

coverPage.drawImage(coverImage_, {
  x,
  y,
  width: letterWidth,
  height: letterHeight,
  opacity: 1, // Asegurarse de que la imagen tenga una opacidad completa (1)
});

    equis_ye = convertirCoordenadasPdfALienzo(100,500)
    x = equis_ye.x
    y = equis_ye.y


    // const { x, y } = convertirCoordenadasPdfALienzo(100,500)

    const coverText = `NOMBRE:

FECHA:

BOUTIQUE:

DIRECCIÓN:

`;
    const coverText_ = `
${user.nombres} ${user.paterno} ${user.materno}

2023-01-01

LIVERPOOL CAMPECHE

DOMICILIO CONOCIDO

`;


    coverPage.drawText(coverText, {
      // x: coverWidth / 2,
      // y: coverHeight / 2,
      x: x,
      y: y,
      size: 20,
      font: font,
      color: rgb(0,0,0),
      // lineHeight: 30,
      // textAlign: 'center',
      // verticalAlign: 'center',
    });
    coverPage.drawText(coverText_, {
      // x: coverWidth / 2,
      // y: coverHeight / 2,
      x: x+20,
      y: y,
      size: 20,
      font: fontB,
      color: rgb(0,0,0),
      // lineHeight: 30,
      // textAlign: 'center',
      // verticalAlign: 'center',
    });
























    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const image : any = await loadImage(file);
      // console.log(await file.arrayBuffer())

      // console.log(image.width,image.height)


      const page = pdfDoc.addPage();
      page.setSize(letterWidth, letterHeight);

      const { width, height } = page.getSize();
      // console.log('width,height')
      // console.log(width,height)
      const resizedImage : any = resizeImage(image, letterWidth+300, letterHeight+400);

      // console.log(await file.arrayBuffer())
      // console.log("file=",file)
      // console.log("image=",image)
      // console.log("resizedImage=",resizedImage)
      
      // const image_1 = await pdfDoc.embedJpg(await file.arrayBuffer());

      let recortada = await resizedImage
      // console.log("image_2=", base64ToArrayBuffer(recortada.toDataURL().split(',')[1]))
      // console.log("image_2=", typeof recortada)
      // console.log(recortada.toDataURL().split(',')[0])
      const image_ = await pdfDoc.embedPng(base64ToArrayBuffer(recortada.toDataURL().split(',')[1]));


      archis.push({
        detalles : "",
        base64 : recortada.toDataURL().split(',')[1]
      })

      // console.log(recortada.width,recortada.height)

      // console.log('.......')

  const imageWidth = recortada.width;
  const imageHeight = recortada.height;

  const centerX : any = (width - imageWidth) / 2;
  const centerY : any = (height - (imageHeight * 0.9)) / 2;


    let equis_ye = convertirCoordenadasPdfALienzo(0,0)
    x = equis_ye.x
    y = equis_ye.y
page.drawImage(fondoImagen_, {
  x,
  y,
  width: letterWidth,
  height: letterHeight,
  opacity: 1, // Asegurarse de que la imagen tenga una opacidad completa (1)
});





      page.drawImage(image_, {
        // x: 0,
        // y: 0,
        x: centerX,
        y: centerY,
        width: recortada.width,
        height: recortada.height,
      });

      // Agregar pie de página
      const pageNumber = i + 1;
      const fontSize = 10;
      let textito = `Lorem ipsum dolor sit amet consectetur adipiscing elit pulvinar facilisi, eget quis tristique ultricies mollis felis litora commodo varius, hac dis condimentum ridiculus sociis arcu sed erat. Pharetra diam convallis bibendum primis tempus risus urna felis gravida suspendisse, nisi libero varius est penatibus accumsan platea duis orci vestibulum eleifend, class facilisi ad enim lacinia quis magna aliquet torquent.`
      const footerText = fillParagraph( textito ,font,fontSize,500 );
      const footerWidth = page.getWidth() - 150;
      const footerHeight = 30;
      page.drawText(footerText, {
        x: 50,
        y: 114,
        size: fontSize,
        font: font,
        color: rgb(0.75, 0.2, 0.2),
      });
      page.drawText(`${moment().format('YYYY-MM-DD hh:mm:ss')} | ${pageNumber.toString()}`, {
        x: footerWidth,
        y: 20,
        size: fontSize,
        font: font,
        color: rgb(0.75, 0.2, 0.2),
        // textAlign: 'right',
      });
    }

    setCuantosArchivos(archis)

    const pdfBytes = await pdfDoc.save();

    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    let tagPdf : any = document.getElementById('tag_pdf');
    tagPdf.innerHTML = ''
    const embedPDF : any = document.createElement('embed');
    embedPDF.src = url;
    embedPDF.width = '100%';
    embedPDF.height = '600';
    tagPdf.appendChild(embedPDF);
  }

  async function loadImageFromURL(url:string) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Error al cargar la imagen desde la URL (${response.status} ${response.statusText})`);
    }

    const arrayBuffer = await response.arrayBuffer();
    // console.log('?')
    // console.log(arrayBuffer)
    // const buffer = Buffer.from(arrayBuffer);
    return arrayBuffer;
  } catch (error) {
    console.error('Error al cargar la imagen desde la URL:', error);
    throw error;
  }
}



  async function loadImage(file:any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (event:any) {
        const img = new Image();
        img.onload = function () {
          const canvas = document.createElement('canvas');
          const ctx : any = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          resolve(canvas);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  }

  async function resizeImage(image:any, maxWidth:number, maxHeight:number) {
    const picaInstance = pica();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const ratio = Math.min(maxWidth / image.width, maxHeight / image.height);
    const newWidth = image.width * ratio * 0.5;
    const newHeight = image.height * ratio * 0.5;

    canvas.width = newWidth;
    canvas.height = newHeight;

    return await picaInstance.resize(image, canvas, { unsharpAmount: 50 })
    // return picaInstance.resize(image, canvas, { unsharpAmount: 80 })
    // return new Promise((resolve, reject) => {
    //   // picaInstance.resize(image, canvas, { unsharpAmount: 80 }, function (result:any) {
    //   //   resolve(result);
    //   // });
    //   picaInstance.resize(image, canvas, { unsharpAmount: 80 }).then(result=>{resolve(result)})
    // });
  }

  const initialValues_: any = {"people":[{"name":"","surname":""},{"name":"","surname":""},{"name":"","surname":""}]}

  const [ initialValues, setInitialValues ] = useState<any>({"people":[{"name":"","surname":""}]})


  const schema = yup.object().shape({
    people: yup.array().of(
      yup.object().shape({
        name: yup.string().required('Required'),
        surname: yup.string().required('Required')
      })
    )
  })


////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////


const [ cuantos_archivos, setCuantosArchivos ] = useState<any>([])

  const cargarImagenesParaReporte = async ()=>{
    const files : any = fileInputRef.current.files;
    let archis = []

    let INIT_VALUES : any = {
      people : []
    }

    if (files.length === 0) {
      toast.warning('Selecciona al menos una imagen.');
      return;
    }

  const letterWidth = 612; // 8.5 x 72 (72 DPI)
  const letterHeight = 792; // 11 x 72 (72 DPI)

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const image : any = await loadImage(file);
      const resizedImage : any = resizeImage(image, letterWidth+300, letterHeight+400);
      let recortada = await resizedImage
      // const image_ = await pdfDoc.embedPng(base64ToArrayBuffer(recortada.toDataURL().split(',')[1]));
      archis.push({
        detalles : "",
        base64 : recortada.toDataURL()
      })
      INIT_VALUES.people.push({ "name" : "", "surname" : "" })
    }

    setCuantosArchivos(archis)

    setInitialValues(INIT_VALUES)

  }

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////






  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Generar Reporte</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // cuantos_archivos.length
          }





          {
            cuantos_archivos.length === 0 && (
                <>
                      <div>
<div className="mb-3">
  <label className="form-label">Default file input example</label>
  <input type="file" ref={fileInputRef} accept=".jpg,.jpeg" multiple className="form-control" onChange={cargarImagenesParaReporte} />
</div>
                        
                        {
                          // <button onClick={cargarImagenesParaReporte} className="btn btn-xl btn-success">Cargar Imagenes</button>
                        }


                        {
                          // <button onClick={generarPDFConImagenes}>Generar PDF</button>
                        }
                        <div id="tag_pdf"></div>
                      </div>
                </>
              )
          }



          {
            cuantos_archivos.length > 0 && (
                <>

                          <Formik
                            initialValues={initialValues}
                            enableReinitialize
                            onSubmit={values => {
                              console.log(values);
                            }}
                            validationSchema={schema}
                            render={({ values, errors }) => {
                              // console.log(values, errors);
                              return (
                                      <Form>
                                        {
                                          // JSON.stringify(values)
                                        }
                                        <FieldArray
                                          name="people"
                                          render={arrayHelpers => (<>
                                            
                                                <div key={ Math.random().toString(36).substr(2, 9) }>
                                                  {arrayHelpers.form.values.people.map((person:any, index:number) => (
                                                    <div className="row" key={index}>
                                                      <div className="col-sm-4">
                                                        <div className="mb-3 text-center">
                                                          <img src={cuantos_archivos[index].base64}  height="180px" />
                                                        </div>
                                                      </div>
                                                      <div className="col-sm-8">
                                                        <div className="mb-3">
                                                          <label htmlFor="exampleFormControlInput1" className="form-label">Detalles de la imagen reportada</label>
                                                          <Field name={`people.${index}.name`} component={FieldComponent} />
                                                        </div>
                                                        {
                                                          // <Field name={`people.${index}.surname`} component={FieldComponent} />
                                                        }
                                                        {
                                                          // <button type="button" onClick={() => arrayHelpers.push({ name: '', surname: '' })}>+</button>
                                                        }
                                                        {
                                                          // <button type="button" onClick={() => arrayHelpers.remove(index)}>-</button>
                                                        }
                                                      </div>
                                                    </div>
                                                  ))}
                                                  <div>
                                                    <button className="btn btn-lg btn-success" type="button">Cargar Reporte</button>
                                                  </div>
                                                </div>

                                          </>)}
                                        />
                                      </Form>
                                      )
                            }}
                          />



                </>
              )
          }











        </Modal.Body>
        {


        // <Modal.Footer>
        //   <Button variant="secondary" onClick={handleClose}>
        //     Cancelar
        //   </Button>
        //   <Button variant="success" type="button">Ok</Button>
        // </Modal.Footer>

        }
      </Modal>
    </>
  );
}


export default ModalPDFPrueba;