import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import AuthService from '../../features/auth/auth.service';
import RequestService from "../../services/request.service";


import { Formik } from 'formik';
import * as yup from 'yup';


import Dialog from '../../services/Dialogs'


const ModalRegistroEntradaSalida = (params:any) => {
  const user = AuthService.getCurrentUser();
  const { show, handleClose, tipo, visita } = params;
  const [cargando, setCargando] = useState<any>(true);
  const [coordenadas, setCoords] = useState<any>(null);

  useEffect(() => {
    const geolocationAPI = navigator.geolocation;

    if (!geolocationAPI) {
      toast.warning('Tu dispositivo no puede registrar la localización. Contacta con la Gerencia.',{ icon : '🛜' });
      handleClose();
      return;
    }

    geolocationAPI.getCurrentPosition((position:any) => {
      // console.log(position.coords)
      // const { coords } = position ;
      setCoords({ lat : position.coords.latitude, lng : position.coords.longitude });
      // Logica adicional...
      setCargando(false);
    }, (error) => {
      handleClose();
      toast.error(`Debes habilitar la localización del dispositivo para poder acceder a esta opción`,{ icon : '🛜' });
    });

  }, [handleClose]);




  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
    idestatus_atencion : yup.number().required(mensajes_validacion_generico),
  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{

      let tipo_servicio = "";
      if(user.rol==='ROLE_TECNICO') tipo_servicio = 'mantenimiento'
      if(user.rol==='ROLE_CONSERVACION') tipo_servicio = 'mantenimiento' //conservacion
      if(user.rol==='ROLE_LIMPIEZA') tipo_servicio = 'limpieza'


        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          // let res_ = await RequestService.update( `URL_REQUEST` , { ...registro, campo_fecha : moment(registro.campo_fecha).format('YYYY-MM-DD') })
          let res = await RequestService.update(`operador/reportar/${tipo_servicio}/${tipo==='ENTRADA'?'entrada':'salida'}/${visita.uuid}`,{ coods : { lat : coordenadas.lat, lng : coordenadas.lng }, idestatus_atencion : registro.idestatus_atencion })

          if(res){
            toast.success(`El registro de ${(tipo==='ENTRADA'?'entrada':'salida')} realizó`)
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }






  return (
    <>
      <Modal show={show}  onHide={handleClose} backdrop="static"> 
        <Modal.Body>
          
          <h4 className="nm">{tipo === 'ENTRADA' ? 'REPORTE DE ACCESO' : 'REPORTE DE SALIDA'}</h4>

          {cargando ? (
            <div className="loader">
              <p className="nm">Localizando...</p>
              <div className="loaderBar"></div>
            </div>
          ) : (
            <React.Fragment>
              {
                // JSON.stringify(coordenadas)
              }           




              <Formik
                validationSchema={schema}
                onSubmit={(values,e)=>{enviarFormulario(values, e)}}
                initialValues={{
                      idestatus_atencion : null,
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form id='my-form' noValidate onSubmit={handleSubmit}>

           
                  {
                    // JSON.stringify(values)
                  }

                  {
                    tipo === 'ENTRADA' && (
                        <Form.Group controlId="idestatus_atencion">
                          <Form.Label>¿Pudiste acceder con el cliente?</Form.Label>
                            <Form.Check   isInvalid={!!errors.idestatus_atencion} type={'radio'} id={`2`} label={`TODO BIEN, REGISTRAR ENTRADA`} checked={values.idestatus_atencion===2} onChange={(e:any)=>{setFieldValue('idestatus_atencion',2)}} />
                            <Form.Check   isInvalid={!!errors.idestatus_atencion} type={'radio'} id={`9`} label={`LOCAL CERRADO`} checked={values.idestatus_atencion===9} onChange={(e:any)=>{setFieldValue('idestatus_atencion',9)}}  />
                            <Form.Check   isInvalid={!!errors.idestatus_atencion} type={'radio'} id={`999`} label={`REPROGRAMAR LA CITA`} checked={values.idestatus_atencion===999} onChange={(e:any)=>{setFieldValue('idestatus_atencion',999)}}  />
                            <Form.Control.Feedback type="invalid">{<>{errors.idestatus_atencion}</>}</Form.Control.Feedback>
                        </Form.Group>
                      )
                  }


                  {
                    tipo === 'SALIDA' && (
                        <Form.Group controlId="idestatus_atencion">
                          <Form.Label>¿Haz realizado todas las actividades?</Form.Label>
                            <Form.Check   isInvalid={!!errors.idestatus_atencion} type={'radio'} id={`4`} label={`HE TERMINADO, MARCAR DE ATENDIDA`} checked={values.idestatus_atencion===4} onChange={(e:any)=>{setFieldValue('idestatus_atencion',4)}} />
                            <Form.Check   isInvalid={!!errors.idestatus_atencion} type={'radio'} id={`6`} label={`SE SUSPENDIÓ EL SERVICIO`} checked={values.idestatus_atencion===6} onChange={(e:any)=>{setFieldValue('idestatus_atencion',6)}}  />
                            <Form.Control.Feedback type="invalid">{<>{errors.idestatus_atencion}</>}</Form.Control.Feedback>
                        </Form.Group>
                      )
                  }





                  </Form>
                )}
              </Formik>




              <div className="mt-2">
                <Button variant="light" onClick={handleClose}>
                  Cancelar
                </Button>{' '}
                <Button variant="secondary" form='my-form' type="submit">
                  Reportar
                </Button>
              </div>




            </React.Fragment>
          )}






        </Modal.Body> 
      </Modal>
    </>
  );
}

export default ModalRegistroEntradaSalida;
