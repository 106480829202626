import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import moment from 'moment'

import { formatToTwoDecimals, sumarPrecios } from '../../services/funcionesPublicas'


import ModalRegistroPagoServicioExtra from './ModalRegistroPagoServicioExtra'


const ModalServiciosExtrasPeriodoPorBoutique = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, periodo, boutique } = params

  const [ servicios_boutique, setServicios_boutique ] = useState<any>([])
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarServicios_boutique = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`contabilidad/servicios_extras/${periodo.uuid}/${boutique.uuid }`)
      setServicios_boutique(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarServicios_boutique()
  }, []);





  const [ show_ModalRegistroPagoServicioExtra, setModalRegistroPagoServicioExtra ] = useState<boolean>(false)
  const [ servicio_seleccionado, setServicioSeleccionado ] = useState<any>(null)
  const handleShow_ModalRegistroPagoServicioExtra = (servicio:any) => {
    setServicioSeleccionado(servicio)
    setModalRegistroPagoServicioExtra(true)
  }
  const handleClose_ModalRegistroPagoServicioExtra = () => {
    cargarServicios_boutique()
    setModalRegistroPagoServicioExtra(false)
  }

  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Registro de Pagos de Servicios Extras por Periodo</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <button onClick={cargarServicios_boutique}>xxxxx</button>
        <br/>
          {

            // JSON.stringify(boutique)
          }

          <small>Periodo</small>
          <div className="row">
            <div className="col-6">
              <small>Fecha de Inicio</small>
              <h6>{periodo.fecha_inicio}</h6>
            </div>
            <div className="col-6">
              <small>Fecha de Fin</small>
              <h6>{periodo.fecha_fin}</h6>
              
            </div>
          </div>

          {
            // JSON.stringify(periodo)
          }

          {

            boutique.servicios_boutique.length > 0 && (
                <React.Fragment>
          <small>Servicios</small>

                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col">Folio</th>
                      <th scope="col">Tipo de Servicio</th>
                      <th scope="col">Servicio</th>
                      <th scope="col">Materiales</th>
                      <th scope="col">Cobrar</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    servicios_boutique.map((servicio:any,indice:number)=>
                          <React.Fragment key={indice}>
                              <tr>
                                <td className="v text-left" style={{width:200}}>
                                  <small><b>{servicio.idpresupuesto?.folio}</b></small>
                            {
                              // JSON.stringify(servicio.idpresupuesto?.costo_servicio)
                            }
                                </td>
                                <td className="v text-left">
                                  <p className="nm"><small>{servicio.idpresupuesto?.descripcion}</small></p>
                                  <small>DÍA {moment(servicio.fecha_programada).utc().format('DD/MMM/YYYY').replace('.','').toUpperCase()}</small>
                                </td>
                                <td className="v text-right" style={{width:120}}>
                                {
                                  // JSON.stringify(servicio.fecha_programada)
                                }
                                  <small>$ {formatToTwoDecimals(servicio.idpresupuesto?.costo_servicio)}</small>
                                </td>
                                <td className="v text-right" style={{width:120}}>
                                  <small>$ {formatToTwoDecimals(sumarPrecios(servicio.materiales_actividades))}</small>
                                </td>
                                <td className="v text-right" style={{width:120}}>
                                  <small>$ { formatToTwoDecimals( (servicio.idpresupuesto?.costo_servicio) + (sumarPrecios(servicio.materiales_actividades)) ) }</small>
                                </td>
                                <td className="v text-center" style={{width:40}}>
                                    <button onClick={(e:any)=>{handleShow_ModalRegistroPagoServicioExtra(servicio)}} className="btn btn-xs btn-warning"><i className="fa fa-calendar"/></button>
                                </td>
                              </tr>
                          </React.Fragment>
                      )
                  }
                  </tbody>
                </table>

                </React.Fragment>
              )

          }


        </Modal.Body>
      </Modal>




  {
    show_ModalRegistroPagoServicioExtra && (
      <ModalRegistroPagoServicioExtra show={show_ModalRegistroPagoServicioExtra} handleClose={handleClose_ModalRegistroPagoServicioExtra} servicio={servicio_seleccionado} />
      )

  }


    </>
  );
}


export default ModalServiciosExtrasPeriodoPorBoutique;