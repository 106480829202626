import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'



import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import Dialog from '../../services/Dialogs'

import moment from 'moment'

const ModalNuevoEgreso = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params


  const [ egresos, setRegistos] = useState<any>([])

  const cargarRegistros = async() => {

    try{
      let res = await RequestService.getEndPoint('catalogo_egresos')
      setRegistos(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar')
    }

  }

  const [ cuentas_bancarias, setCuentasBancarias] = useState<any>([])

  const cargarCuentas = async() => {

    try{
      let res = await RequestService.getEndPoint('catalogo_cuentas_bancarias')
      setCuentasBancarias(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar')
    }

  }

  const [ departamentos, setDepartamentos] = useState<any>([])

  const cargarDepartamentos = async() => {

    try{
      let res = await RequestService.getEndPoint('lista_deptos')
      setDepartamentos(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar')
    }

  }



  React.useEffect(() => {
    cargarRegistros()
    cargarCuentas()
    // cargarSucursales()
    // cargarDepartamentos()
  }, []);



  const [ sucursales, setSucursales] = useState<any>([])

  const cargarSucursales = async() => {

    try{
      let res = await RequestService.getEndPoint('sucursales')
      // console.log(res.data)
      setSucursales(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de Gerentes')
    }

  }


  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          descripcion : yup.string().required(mensajes_validacion_generico),
          // uuid_sucursal : yup.string().required(mensajes_validacion_generico),
          uuid_tipo_ingreso : yup.string().required(mensajes_validacion_generico),
          monto : yup.number().required(mensajes_validacion_generico),
          fecha_movimiento : yup.string().nullable().required(mensajes_validacion_generico),
          archivo : yup.string() ,

          forma_pago : yup.string().required(mensajes_validacion_generico),
          idcuenta_banco : yup.string().required(mensajes_validacion_generico),

          // iddepartamento : yup.string().required(mensajes_validacion_generico),


  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro de la información?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.create( `egresos/crear` , { ...registro, fecha_movimiento : moment(registro.fecha_movimiento).format('YYYY-MM-DD') })

          if(res_){
            toast.success('El registro se creó correctamente')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
          setSeEnvio(false)
            toast.error("Problemas durante el registro")
            // console.log(e.response.data)
          } 
          
        }
       
  }


    const handleFileChange = (event:any,setFieldValue:any) => {
        const file = event.target.files[0];
        
        if (file) {
            const reader : any = new FileReader();

            reader.onloadend = function (e:any) {
                const base64 = reader.result.split(',')[1];
                // setBase64String(base64);
                setFieldValue('archivo',base64)
                // console.log(base64)
            };

            reader.readAsDataURL(file);
        }
    }



  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Registro Egreso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }


    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            descripcion : "", 
            monto : "", 
            fecha_movimiento : new Date(), 
            archivo : "", 
            uuid_tipo_ingreso : "", 
            uuid_sucursal : "", 


            forma_pago : "", 
            idcuenta_banco : "", 
            iddepartamento : "", 

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>



            <Form.Group className="mb-3">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="descripcion"
                value={values.descripcion}
                onChange={(e)=>{ setFieldValue('descripcion', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.descripcion}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.descripcion}</>} </Form.Control.Feedback>
            </Form.Group>





            <Form.Group className="mb-3">
              <Form.Label>Fecha</Form.Label>
              <Form.Control
                required
                type="hidden"
                value={values.fecha_movimiento?values.fecha_movimiento.toString():''}
                isInvalid={!!errors.fecha_movimiento}
              />
                    <DatePicker selected={values.fecha_movimiento} className="form-control"  dateFormat="yyyy-MM-dd" maxDate={new Date()} onChange={(date) => setFieldValue('fecha_movimiento', date ) } />
              <Form.Control.Feedback type="invalid"> {<>{errors.fecha_movimiento}</>} </Form.Control.Feedback>
            </Form.Group>




            <Form.Group className="mb-3">
              <Form.Label htmlFor="evidencia">Archivo de Evidencia</Form.Label>
              <Form.Control
                required
                type="file"
                accept=".pdf,.jpg,.jpeg,.png"
                // value={values.archivo}
                id="evidencia"
                onChange={(e)=>{  handleFileChange(e,setFieldValue) }}
                isInvalid={!!errors.archivo}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.archivo}</>} </Form.Control.Feedback>
            </Form.Group>





            <Form.Group className="mb-3">
              <Form.Label>Monto</Form.Label>
              <Form.Control
                type="text"
                required
                name="monto"
                value={values.monto}
                onChange={(e)=>{ setFieldValue('monto', e.target.value ) }}
                isInvalid={!!errors.monto}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.monto}</>} </Form.Control.Feedback>
            </Form.Group>



              <div className="mb-3">
                <label className="form-label">Tipo de Egreso</label>
                  <Form.Select aria-label="Selección de Tipo de Egreso" required value={values.uuid_tipo_ingreso} onChange={(e)=>{ setFieldValue('uuid_tipo_ingreso',e.target.value) }} isInvalid={!!errors.uuid_tipo_ingreso} >
                    <option>Selecciona el Tipo de Egreso</option>
                    { egresos.map((ingreso:any)=><option key={ Math.random().toString(36).substr(2, 9) } value={ingreso.uuid}>{ingreso.descripcion}</option>)}

                  </Form.Select>
                  <Form.Control.Feedback type="invalid"> {errors.uuid_tipo_ingreso} </Form.Control.Feedback>
              </div>







              <div className="mb-3">
                <label className="form-label">Cuenta Bancaria</label>
                  <Form.Select aria-label="Selección de Sucursal" required value={values.idcuenta_banco} onChange={(e)=>{ setFieldValue('idcuenta_banco',e.target.value) }} isInvalid={!!errors.idcuenta_banco} >
                    <option>Selecciona una sucursal</option>
                    { cuentas_bancarias.map((cuenta:any)=><option key={ Math.random().toString(36).substr(2, 9) } value={cuenta.uuid}>{cuenta.banco} - {cuenta.descripcion} </option>)}

                  </Form.Select>
                  <Form.Control.Feedback type="invalid"> {errors.idcuenta_banco} </Form.Control.Feedback>
              </div>





              <div className="mb-3">
                <label className="form-label">Forma de Pago</label>
                  <Form.Select aria-label="Selección de Sucursal" required value={values.forma_pago} onChange={(e)=>{ setFieldValue('forma_pago',e.target.value) }} isInvalid={!!errors.forma_pago} >
                    <option>Selecciona una Forma</option>
                    <option value={'EFECTIVO'}>EFECTIVO</option>
                    <option value={'TRANSFERENCIA'}>TRANSFERENCIA</option>
                    <option value={'CHEQUE'}>CHEQUE</option>
                    <option value={'DEPÓSITO EFECTIVO'}>DEPÓSITO EFECTIVO</option>

                  </Form.Select>
                  <Form.Control.Feedback type="invalid"> {errors.forma_pago} </Form.Control.Feedback>
              </div>








        </Form>
      )}
    </Formik>







        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' disabled={envio_server} type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalNuevoEgreso;