import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import moment from 'moment'

import { SERVER_ } from  '../../config';

import { formatToTwoDecimals } from '../../services/funcionesPublicas'

const ModalVisualizarRegistroPago = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, boutique, periodo, tipo_servicio } = params

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Registro de Pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <small>Periodo</small>
          <h4 >DEL {periodo.fecha_inicio} AL {periodo.fecha_fin}</h4>

          <small>Boutique</small>
          <h5>{boutique.nombre_boutique}</h5>

          {
            tipo_servicio === 'MANTENIMIENTO' && (
                <React.Fragment>
                  
                    <small>Tipo de Ingreso</small>
                    <h5>{boutique.pago_iguala_mantenimiento?.idtipo_ingreso_egreso?.descripcion}</h5>

                    <div className="row">
                      <div className="col-sm-4">
                          <small>Monto</small>
                          <h5>$ {formatToTwoDecimals(boutique.pago_iguala_mantenimiento?.monto)}</h5>
                      </div>
                      <div className="col-sm-8">
                          <small>Forma de Pago</small>
                          <h5>{boutique.pago_iguala_mantenimiento?.forma_pago}</h5>
                      </div>
                    </div>

                    <small>Fecha del Movimiento</small>
                    <h5>{moment(boutique.pago_iguala_mantenimiento?.fecha_movimiento).format('DD/MMM/YYYY').replace('.','').toUpperCase()}</h5>

                    <small>Fecha del Creacion</small>
                    <h5>{moment(boutique.pago_iguala_mantenimiento?.fecha_creacion).format('DD/MMM/YYYY HH:mm').replace('.','').toUpperCase()} hrs</h5>


                    <div className="row">
                      <div className="col-sm-8">
                          <small>Referencia del Movimiento</small>
                          <h5> {boutique.pago_iguala_mantenimiento?.referencia_movimiento||'-'}</h5>
                      </div>
                      <div className="col-sm-4">
                          <small>Número de Pedido</small>
                          <h5>{boutique.pago_iguala_mantenimiento?.numero_pedido||'-'}</h5>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col-sm-6">
                          <small>Contra Recibo</small>
                          <h5> {boutique.pago_iguala_mantenimiento?.contra_recibo||'-'}</h5>
                      </div>
                      <div className="col-sm-6">
                          <small>Fecha del Pedidoo</small>
                          <h5>{boutique.pago_iguala_mantenimiento?.fecha_pedido ? '-' : moment(boutique.pago_iguala_mantenimiento?.fecha_pedido).format('DD/MMM/YYYY').toUpperCase().replace('.','')}</h5>
                      </div>
                    </div>


                          <small>Número de Factura</small>
                          <h5>{boutique.pago_iguala_mantenimiento?.numero_factura||'-'}</h5>


                    {
                      boutique.pago_iguala_mantenimiento?.archivo && (<iframe src={`${SERVER_}contabilidad/archivo/${boutique.pago_iguala_mantenimiento?.uuid}`} style={{width:'100%',height:500}} />)
                    }

                </React.Fragment>
              )
          }

          {
            tipo_servicio === 'CONSERVACION' && (
                <React.Fragment>
                  
                    <small>Tipo de Ingreso</small>
                    <h5>{boutique.pago_iguala_conservacion?.idtipo_ingreso_egreso?.descripcion}</h5>

                    <div className="row">
                      <div className="col-sm-4">
                          <small>Monto</small>
                          <h5>$ {formatToTwoDecimals(boutique.pago_iguala_conservacion?.monto)}</h5>
                      </div>
                      <div className="col-sm-8">
                          <small>Forma de Pago</small>
                          <h5>{boutique.pago_iguala_conservacion?.forma_pago}</h5>
                      </div>
                    </div>

                    <small>Fecha del Movimiento</small>
                    <h5>{moment(boutique.pago_iguala_conservacion?.fecha_movimiento).format('DD/MMM/YYYY').replace('.','').toUpperCase()}</h5>

                    <small>Fecha del Creacion</small>
                    <h5>{moment(boutique.pago_iguala_conservacion?.fecha_creacion).format('DD/MMM/YYYY HH:mm').replace('.','').toUpperCase()} hrs</h5>



                    <div className="row">
                      <div className="col-sm-8">
                          <small>Referencia del Movimiento</small>
                          <h5> {boutique.pago_iguala_conservacion?.referencia_movimiento||'-'}</h5>
                      </div>
                      <div className="col-sm-4">
                          <small>Número de Pedido</small>
                          <h5>{boutique.pago_iguala_conservacion?.numero_pedido||'-'}</h5>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col-sm-6">
                          <small>Contra Recibo</small>
                          <h5> {boutique.pago_iguala_conservacion?.contra_recibo||'-'}</h5>
                      </div>
                      <div className="col-sm-6">
                          <small>Fecha del Pedidoo</small>
                          <h5>{boutique.pago_iguala_conservacion?.fecha_pedido ? '-' : moment(boutique.pago_iguala_conservacion?.fecha_pedido).format('DD/MMM/YYYY').toUpperCase().replace('.','')}</h5>
                      </div>
                    </div>


                          <small>Número de Factura</small>
                          <h5>{boutique.pago_iguala_conservacion?.numero_factura||'-'}</h5>



                    {
                      boutique.pago_iguala_conservacion?.archivo && (<iframe src={`${SERVER_}contabilidad/archivo/${boutique.pago_iguala_conservacion?.uuid}`} style={{width:'100%',height:500}} />)
                    }
                    
                </React.Fragment>
              )
          }

          {
            tipo_servicio === 'LIMPIEZA' && (
                <React.Fragment>
                  
                    <small>Tipo de Ingreso</small>
                    <h5>{boutique.pago_iguala_limpieza?.idtipo_ingreso_egreso?.descripcion}</h5>

                    <div className="row">
                      <div className="col-sm-4">
                          <small>Monto</small>
                          <h5>$ {formatToTwoDecimals(boutique.pago_iguala_limpieza?.monto)}</h5>
                      </div>
                      <div className="col-sm-8">
                          <small>Forma de Pago</small>
                          <h5>{boutique.pago_iguala_limpieza?.forma_pago}</h5>
                      </div>
                    </div>

                    <small>Fecha del Movimiento</small>
                    <h5>{moment(boutique.pago_iguala_limpieza?.fecha_movimiento).format('DD/MMM/YYYY').replace('.','').toUpperCase()}</h5>

                    <small>Fecha del Creacion</small>
                    <h5>{moment(boutique.pago_iguala_limpieza?.fecha_creacion).format('DD/MMM/YYYY HH:mm').replace('.','').toUpperCase()} hrs</h5>


                    <div className="row">
                      <div className="col-sm-8">
                          <small>Referencia del Movimiento</small>
                          <h5> {boutique.pago_iguala_limpieza?.referencia_movimiento||'-'}</h5>
                      </div>
                      <div className="col-sm-4">
                          <small>Número de Pedido</small>
                          <h5>{boutique.pago_iguala_limpieza?.numero_pedido||'-'}</h5>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col-sm-6">
                          <small>Contra Recibo</small>
                          <h5> {boutique.pago_iguala_limpieza?.contra_recibo||'-'}</h5>
                      </div>
                      <div className="col-sm-6">
                          <small>Fecha del Pedidoo</small>
                          <h5>{boutique.pago_iguala_limpieza?.fecha_pedido ? '-' : moment(boutique.pago_iguala_limpieza?.fecha_pedido).format('DD/MMM/YYYY').toUpperCase().replace('.','')}</h5>
                      </div>
                    </div>


                          <small>Número de Factura</small>
                          <h5>{boutique.pago_iguala_limpieza?.numero_factura||'-'}</h5>




                    {
                      boutique.pago_iguala_limpieza?.archivo && (<iframe src={`${SERVER_}contabilidad/archivo/${boutique.pago_iguala_limpieza?.uuid}`} style={{width:'100%',height:500}} />)
                    }
                    
                </React.Fragment>
              )
          }




          {
            tipo_servicio === 'EXTRAS' && (
                <React.Fragment>
                  
                    <small>Tipo de Ingreso</small>
                    <h5>{boutique.pago_extras?.idtipo_ingreso_egreso?.descripcion}</h5>

                    <div className="row">
                      <div className="col-sm-4">
                          <small>Monto</small>
                          <h5>$ {formatToTwoDecimals(boutique.pago_extras?.monto)}</h5>
                      </div>
                      <div className="col-sm-8">
                          <small>Forma de Pago</small>
                          <h5>{boutique.pago_extras?.forma_pago}</h5>
                      </div>
                    </div>

                    <small>Fecha del Movimiento</small>
                    <h5>{moment(boutique.pago_extras?.fecha_movimiento).format('DD/MMM/YYYY').replace('.','').toUpperCase()}</h5>

                    <small>Fecha del Creacion</small>
                    <h5>{moment(boutique.pago_extras?.fecha_creacion).format('DD/MMM/YYYY HH:mm').replace('.','').toUpperCase()} hrs</h5>


                    <div className="row">
                      <div className="col-sm-8">
                          <small>Referencia del Movimiento</small>
                          <h5> {boutique.pago_extras?.referencia_movimiento||'-'}</h5>
                      </div>
                      <div className="col-sm-4">
                          <small>Número de Pedido</small>
                          <h5>{boutique.pago_extras?.numero_pedido||'-'}</h5>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col-sm-6">
                          <small>Contra Recibo</small>
                          <h5> {boutique.pago_extras?.contra_recibo||'-'}</h5>
                      </div>
                      <div className="col-sm-6">
                          <small>Fecha del Pedidoo</small>
                          <h5>{boutique.pago_extras?.fecha_pedido ? '-' : moment(boutique.pago_extras?.fecha_pedido).format('DD/MMM/YYYY').toUpperCase().replace('.','')}</h5>
                      </div>
                    </div>


                          <small>Número de Factura</small>
                          <h5>{boutique.pago_extras?.numero_factura||'-'}</h5>




                    {
                      boutique.pago_extras?.archivo && (<iframe src={`${SERVER_}contabilidad/archivo/${boutique.pago_extras?.uuid}`} style={{width:'100%',height:500}} />)
                    }
                    
                </React.Fragment>
              )
          }





          {
            tipo_servicio === 'REFACCIONAMIENTO' && (
                <React.Fragment>
                  
                    <small>Tipo de Ingreso</small>
                    <h5>{boutique.pago_refaccionamiento?.idtipo_ingreso_egreso?.descripcion}</h5>

                    <div className="row">
                      <div className="col-sm-4">
                          <small>Monto</small>
                          <h5>$ {formatToTwoDecimals(boutique.pago_refaccionamiento?.monto)}</h5>
                      </div>
                      <div className="col-sm-8">
                          <small>Forma de Pago</small>
                          <h5>{boutique.pago_refaccionamiento?.forma_pago}</h5>
                      </div>
                    </div>

                    <small>Fecha del Movimiento</small>
                    <h5>{moment(boutique.pago_refaccionamiento?.fecha_movimiento).format('DD/MMM/YYYY').replace('.','').toUpperCase()}</h5>

                    <small>Fecha del Creacion</small>
                    <h5>{moment(boutique.pago_refaccionamiento?.fecha_creacion).format('DD/MMM/YYYY HH:mm').replace('.','').toUpperCase()} hrs</h5>


                    <div className="row">
                      <div className="col-sm-8">
                          <small>Referencia del Movimiento</small>
                          <h5> {boutique.pago_refaccionamiento?.referencia_movimiento||'-'}</h5>
                      </div>
                      <div className="col-sm-4">
                          <small>Número de Pedido</small>
                          <h5>{boutique.pago_refaccionamiento?.numero_pedido||'-'}</h5>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col-sm-6">
                          <small>Contra Recibo</small>
                          <h5> {boutique.pago_refaccionamiento?.contra_recibo||'-'}</h5>
                      </div>
                      <div className="col-sm-6">
                          <small>Fecha del Pedidoo</small>
                          <h5>{boutique.pago_refaccionamiento?.fecha_pedido ? '-' : moment(boutique.pago_refaccionamiento?.fecha_pedido).format('DD/MMM/YYYY').toUpperCase().replace('.','')}</h5>
                      </div>
                    </div>


                          <small>Número de Factura</small>
                          <h5>{boutique.pago_refaccionamiento?.numero_factura||'-'}</h5>




                    {
                      boutique.pago_refaccionamiento?.archivo && (<iframe src={`${SERVER_}contabilidad/archivo/${boutique.pago_refaccionamiento?.uuid}`} style={{width:'100%',height:500}} />)
                    }
                    
                </React.Fragment>
              )
          }






          {
            // JSON.stringify(boutique.pago_iguala_mantenimiento?.monto)
          }
        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalVisualizarRegistroPago;