import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { SERVER_ } from  '../../config';

const ModalDetallesPagoProgramado = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, pago_programado } = params

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Detalles del Pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(pago_programado.idegreso)
          }

            <div className="card mb-0">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h5 className="card-title mb-0">Pago: {pago_programado.folio}</h5>
                <span className={`badge status-${pago_programado.estatus_pago.toLowerCase()}`}>{pago_programado.estatus_pago}</span>
              </div>
              <div className="card-body">
                {pago_programado.proveedor && <p><strong>Proveedor:</strong> {pago_programado.proveedor} ({pago_programado.razon_social || 'Razón social no disponible'})</p>}
                <p><strong>Fecha Programada:</strong> {new Date(pago_programado.fecha_programada).toLocaleDateString()}</p>
                <p><strong>Detalles del Pago:</strong> {pago_programado.detalles_pago}</p>
                <p><strong>Monto:</strong> ${pago_programado.monto}</p>
                {pago_programado.nombre_contacto && <p><strong>Contacto:</strong> {pago_programado.nombre_contacto} ({pago_programado.telefono_contacto || 'Teléfono no disponible'})</p>}
                {pago_programado.idegreso && <p><strong>Forma de Pago:</strong> {pago_programado.idegreso.forma_pago}</p>}

                {
                  (pago_programado.idegreso !== null && pago_programado.idegreso?.archivo !== null && pago_programado.idegreso?.archivo !== '') && (
                      <p><strong>Evidencia del Pago:</strong> <a target="_blank" href={`${SERVER_}contabilidad/archivo/${pago_programado.idegreso?.uuid}`}>Abrir Archivo <i className="fa fa-file-o"/></a></p>
                  )
                }

              </div>
            </div>


        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalDetallesPagoProgramado;