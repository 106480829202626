import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import Dialog from '../../services/Dialogs'

import moment from 'moment'


const ModalCambiarEstatusTicket = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, ticket } = params


  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
          idestatus_ticket : yup.string().required(mensajes_validacion_generico),
  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro de la información?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.update( `ticket/cambiar_estatus/${ticket.uuid_ticket}` , { ...registro })

          if(res_){
            toast.success('El estatus del ticket se cambió')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }



  const [ estatus_ticket, setEstatus_ticket ] = useState<any>([])
  const cargarEstatus_ticket = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`lista/estatus_ticket`)
      let A = response.data
      A.shift()
      setEstatus_ticket(A)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarEstatus_ticket()
  }, []);



    


  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Cambiar Estatus del Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>



    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            idestatus_ticket : ticket.idestatus_ticket.id === 1 ? "" : ticket.idestatus_ticket.id,

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>


{
  // JSON.stringify(estatus_ticket)
}

                            <Form.Group controlId="idarea">
                              <Form.Label>Estatus del Ticket</Form.Label>
                                {
                                  estatus_ticket.map((estatus:any)=>
                                    <Form.Check inline isInvalid={!!errors.idestatus_ticket} type={'radio'} id={`default-${estatus.id}`} 
                                      label={estatus.estatus} checked={values.idestatus_ticket===estatus.id} 
                                      onChange={(e:any)=>{setFieldValue('idestatus_ticket',estatus.id)}} key={ Math.random().toString(36).substr(2, 9) }/>
                                  )
                                }

                                <Form.Control.Feedback type="invalid">{<>{errors.idestatus_ticket}</>}</Form.Control.Feedback>
                            </Form.Group>




        </Form>
      )}
    </Formik>





        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalCambiarEstatusTicket;