import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'



import Pagination from "../../services/Pagination";
import { Table, Form, Dropdown } from 'react-bootstrap'


import ModalAgregarHerramienta from './ModalAgregarHerramienta'
import ModalAlmacenes from './ModalAlmacenes'

import ModalHerramientaInformacion from './ModalHerramientaInformacion'

import ModalHerramientaCargarFotos from './ModalHerramientaCargarFotos'

import ModalHerramientaEditar from './ModalHerramientaEditar'


import { SERVER_ } from '../../config'


import { estadoCuidadoHerramienta } from '../../services/funcionesPublicas'

import Dialog from '../../services/Dialogs'



import ModalAsignarHerramientas from './ModalAsignarHerramientas'

import ModalAsignamientos from './ModalAsignamientos'




import './inventario.scss'

const Inventario = () =>{




  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 30
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`busqueda_herramientas/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true,true,true,true,true])


  const todos = () =>{
    setEstatus([true,true,true,true,true])
  }
  const ninguno = () =>{
    setEstatus([false,false,false,false,false])
  }



  const [ show_ModalAgregarHerramienta, setModalAgregarHerramienta ] = useState<boolean>(false)
  const handleShow_ModalAgregarHerramienta = () => {
    setModalAgregarHerramienta(true)
  }
  const handleClose_ModalAgregarHerramienta = () => {
    setModalAgregarHerramienta(false)
  }




  const [ show_ModalAlmacenes, setModalAlmacenes ] = useState<boolean>(false)
  const handleShow_ModalAlmacenes = () => {
    setModalAlmacenes(true)
  }
  const handleClose_ModalAlmacenes = () => {
    setModalAlmacenes(false)
  }


  const [ herramienta_seleccionada, setHerramientaSeleccionada ] = useState<any>(null)

  const [ show_ModalHerramientaInformacion, setModalHerramientaInformacion ] = useState<boolean>(false)
  const handleShow_ModalHerramientaInformacion = (herramienta:any) => {
    setHerramientaSeleccionada(herramienta)
    setModalHerramientaInformacion(true)
  }
  const handleClose_ModalHerramientaInformacion = () => {
    setModalHerramientaInformacion(false)
  }





  const [ show_ModalHerramientaCargarFotos, setModalHerramientaCargarFotos ] = useState<boolean>(false)
  const handleShow_ModalHerramientaCargarFotos = (herramienta:any) => {
    setHerramientaSeleccionada(herramienta)
    setModalHerramientaCargarFotos(true)
  }
  const handleClose_ModalHerramientaCargarFotos = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalHerramientaCargarFotos(false)
  }



  const [ show_ModalHerramientaEditar, setModalHerramientaEditar ] = useState<boolean>(false)
  const handleShow_ModalHerramientaEditar = (herramienta:any) => {
    setHerramientaSeleccionada(herramienta)
    setModalHerramientaEditar(true)
  }
  const handleClose_ModalHerramientaEditar = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalHerramientaEditar(false)
  }




      React.useEffect(() => {
        busqueda()
      }, []);






const dialog = new Dialog();

const [ envio_server, setSeEnvio ] = useState<boolean>(false)
const cambiarEstatusHerramienta = async (event:any, uuid_herramienta:string, estatus:string) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `herramienta/cambiar_estatus/${uuid_herramienta}` , { estatus : estatus })

        if(res_){
          toast.success('Consulta_correcta')
          setTimeout(()=>{
             busqueda()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}




    

  const [ show_ModalAsignarHerramientas, setModalAsignarHerramientas ] = useState<boolean>(false)
  const handleShow_ModalAsignarHerramientas = () => {
    setModalAsignarHerramientas(true)
  }
  const handleClose_ModalAsignarHerramientas = () => {
    setModalAsignarHerramientas(false)
  }



  const [ show_ModalAsignamientos, setModalAsignamientos ] = useState<boolean>(false)
  const handleShow_ModalAsignamientos = () => {
    setModalAsignamientos(true)
  }
  const handleClose_ModalAsignamientos = () => {
    setModalAsignamientos(false)
  }

      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Inventario</h4>
                    <hr/>
                    


                    <div className="row">
                    
                        <div className="mb-2">
                            <label className="form-label">Búsqueda</label>
                            <div className="hstack gap-3">
                              <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                              <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                              <div className="vr"></div>
                              <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                            </div>
                        </div>
                        
                        <div>
                          <Form.Check inline name="group1" type="checkbox" label="EN ALMACEN" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
                          <Form.Check inline name="group1" type="checkbox" label="ASIGNADO" checked={arreglo_estatus[1]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}} id={`opciones_2`} />
                          <Form.Check inline name="group1" type="checkbox" label="EXTRAVIADO" checked={arreglo_estatus[2]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}} id={`opciones_3`} />
                          <Form.Check inline name="group1" type="checkbox" label="VENDIDA" checked={arreglo_estatus[3]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}} id={`opciones_4`} />
                          <Form.Check inline name="group1" type="checkbox" label="BAJA" checked={arreglo_estatus[4]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[4] = e.target.checked;  setEstatus(ne);}} id={`opciones_5`} />
                        </div>

                      <ul className="list-inline">
                        <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                        <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalAgregarHerramienta()}}>Agregar Herramienta</small></li>
                        <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalAlmacenes()}}>Almacenes</small></li>
                        <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalAsignarHerramientas()}}>Asignar Herramientas</small></li>
                        <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalAsignamientos()}}>Asignamientos</small></li>
                      </ul>

                    </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col" colSpan={2}>Imágenes</th>
                                              <th scope="col">Tipo</th>
                                              <th scope="col"></th>
                                              <th scope="col"># Serie</th>
                                              <th scope="col">Marca / Modelo</th>
                                              <th scope="col">Estado</th>
                                              <th scope="col">Estatus</th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (herramienta:any,indice_herramienta:number) =>
                                                  <tr key={ indice_herramienta } style={{fontSize:12}}>
                                                    <td className="v text-left"  style={{"width":"50px"}} onClick={(e:any)=>{handleShow_ModalHerramientaCargarFotos(herramienta)}}>
                                                             {
                                                              // JSON.stringify(herramienta)
                                                             }
                                                             <img   src={`${SERVER_}herramienta_imagen/foto_a/${herramienta.uuid_herramienta}/${Math.random().toString(36).substr(2, 9)}`} height="45px" style={{border:'solid 1px'}} />
                                                    </td>
                                                    <td className="v text-left"  style={{"width":"50px"}} onClick={(e:any)=>{handleShow_ModalHerramientaCargarFotos(herramienta)}}>
                                                             {
                                                              // JSON.stringify(herramienta)
                                                             }
                                                             <img src={`${SERVER_}herramienta_imagen/foto_b/${herramienta.uuid_herramienta}/${Math.random().toString(36).substr(2, 9)}`} height="45px" style={{border:'solid 1px'}} />
                                                    </td>
                                                    
                                                    <td className="v text-left"   >
                                                        <p className="nm"><b> {herramienta.tipo_herramienta} </b></p>
                                                    </td>
                                                    <td className="v text-left"  style={{"width":"200px"}}>
                                                        <p className="nm"><small> {herramienta.descripcion} </small></p>
                                                    </td>
                                                    <td className="v text-left"  style={{"width":"120px"}}>
                                                        <p className="nm"><small><b>{herramienta.numero_serie}</b></small></p>
                                                    </td>
                                                    <td className="v text-left"  style={{"width":"140px"}}>
                                                        <p className="nm"><b>{herramienta.marca}</b></p>
                                                        <p className="nm"><small>{herramienta.modelo}</small></p>
                                                    </td>
                                                    <td className="v text-left"  style={{"width":"100px"}}>
                                                        <p className="nm"><small><b>{estadoCuidadoHerramienta(herramienta.estado)}</b></small></p>
                                                    </td>
                                                    <td  className={`v text-left idestatus_herramienta_${herramienta.idestatus_herramienta}`} style={{"width":"200px"}}>
                                                        <p className="nm"><small>{herramienta.estatus}</small></p>
                                                        {
                                                          [2].indexOf(herramienta.idestatus_herramienta*1) > -1 && (
                                                              <React.Fragment>
                                                                <p className="nm"><small>{herramienta.idusuario_asignado.nombres} {herramienta.idusuario_asignado.paterno} {herramienta.idusuario_asignado.materno}</small></p>
                                                                {
                                                                  // JSON.stringify(herramienta.idusuario_asignado)
                                                                }
                                                              </React.Fragment>
                                                            )
                                                        }
                                                        {
                                                          [1].indexOf(herramienta.idestatus_herramienta*1) > -1 && (
                                                              <React.Fragment>
                                                                <p className="nm"><small> {herramienta.almacen} </small></p>
                                                                {
                                                                  !herramienta.idalmacen && (<p className="nm"><i className="fa fa-warning"/> Sin Almacen Asignado</p>)
                                                                }
                                                              </React.Fragment>
                                                            )
                                                        }
                                                    </td>

                                                    <td className="v text-left"  style={{"width":"40px"}}>
                                                        <button type="button" onClick={(e:any)=>{handleShow_ModalHerramientaInformacion(herramienta)}} className="btn btn-link btn-sm"><i className="fa fa-address-book-o"/></button>
                                                    </td>
                                                    <td className="v text-left"  style={{"width":"40px"}}>
                                                        <button type="button" onClick={(e:any)=>{handleShow_ModalHerramientaEditar(herramienta)}} className="btn btn-warning btn-sm"><i className="fa fa-edit"/></button>
                                                    </td>
                                                    <td className="v text-left"  style={{"width":"40px"}}>

                                                        {
                                                          [1,2].indexOf(herramienta.idestatus_herramienta*1) > -1 && (
                                                                  <Dropdown>
                                                                    <Dropdown.Toggle variant="success" size="sm" id="dropdown-basic">
                                                                      <i className="fa fa-gear"/>
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                      <Dropdown.Item onClick={(e:any)=>{cambiarEstatusHerramienta(e, herramienta.uuid_herramienta, 'extrativado')}}>EXTRAVIADO</Dropdown.Item>
                                                                      <Dropdown.Item onClick={(e:any)=>{cambiarEstatusHerramienta(e, herramienta.uuid_herramienta, 'vendida')}}>VENDIDA</Dropdown.Item>
                                                                      <Dropdown.Item onClick={(e:any)=>{cambiarEstatusHerramienta(e, herramienta.uuid_herramienta, 'baja')}}>BAJA</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                  </Dropdown>
                                                            )
                                                        }



                                                    </td>
                                                    
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}








                </div>
              </div>

            </div>



  {
    show_ModalAgregarHerramienta && (
      <ModalAgregarHerramienta show={show_ModalAgregarHerramienta} handleClose={handleClose_ModalAgregarHerramienta}  />
      )

  }



  {
    show_ModalAlmacenes && (
      <ModalAlmacenes show={show_ModalAlmacenes} handleClose={handleClose_ModalAlmacenes}  />
      )

  }



  {
    show_ModalHerramientaInformacion && (
      <ModalHerramientaInformacion show={show_ModalHerramientaInformacion} handleClose={handleClose_ModalHerramientaInformacion} herramienta={herramienta_seleccionada}  />
      )

  }


  {
    show_ModalHerramientaCargarFotos && (
      <ModalHerramientaCargarFotos show={show_ModalHerramientaCargarFotos} handleClose={handleClose_ModalHerramientaCargarFotos} herramienta={herramienta_seleccionada} />
      )

  }


  {
    show_ModalHerramientaEditar && (
      <ModalHerramientaEditar show={show_ModalHerramientaEditar} handleClose={handleClose_ModalHerramientaEditar} herramienta={herramienta_seleccionada} />
      )

  }




  {
    show_ModalAsignarHerramientas && (
      <ModalAsignarHerramientas show={show_ModalAsignarHerramientas} handleClose={handleClose_ModalAsignarHerramientas}  />
      )

  }





  {
    show_ModalAsignamientos && (
      <ModalAsignamientos show={show_ModalAsignamientos} handleClose={handleClose_ModalAsignamientos}  />
      )

  }


          </>

      );
  

}

export default Inventario
