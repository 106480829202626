import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import Dialog from '../../services/Dialogs'

import { formatearNumero, condicionesPago, calcularTiempoTranscurrido, tipoActividadLabel, ordenarPorTipo } from '../../services/funcionesPublicas'

import moment from 'moment'


const ModalRevisarPresupuesto = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, presupuesto } = params

  const dialog = new Dialog()

const confirmarreporteEntradaSalida = async(e:any ) => {
    try{

        await dialog.open({
          accept: 'SI',
          cancel: 'NO',
          dialogClass: '',
          message: 'Estás seguro?',
          target: e.target,
          template: ``
        })
        let dialogo : any = await dialog.waitForUser()
        
        if(dialogo){
          let res = await RequestService.update(`presupuesto/aceptar_cliente/${presupuesto.uuid_presupuesto}`, {} )
          if(res.data){
            toast.success('Se actualizo el estatus del Presupuesto')
            handleClose()
          }
          
        }

    }
    catch(e){
      toast.error('Tuvimos problemas  ')
    }

  }


const cancelarPresupuesto = async(e:any ) => {
    try{

        await dialog.open({
          accept: 'SI',
          cancel: 'NO',
          dialogClass: '',
          message: 'Estás seguro?',
          target: e.target,
          template: ``
        })
        let dialogo : any = await dialog.waitForUser()
        
        if(dialogo){
          let res = await RequestService.update(`presupuesto/cancelar/${presupuesto.uuid_presupuesto}`, {} )
          if(res.data){
            toast.success('Se actualizo el estatus del Presupuesto')
            handleClose()
          }
          
        }

    }
    catch(e){
      toast.error('Tuvimos problemas  ')
    }

  }




  React.useEffect(() => {
    sumarSubtotales()
  }, []);



  const [ suma_subtotal, setSuma ] = useState<number>(0)

  const sumarSubtotales = () =>{
    let s = 0
    console.log(presupuesto.material_actividades)
    for(let actividad of presupuesto.material_actividades){
      s += ( actividad.costo * actividad.cantidad )
    }
    setSuma(s)

  }


const [ envio_server, setSeEnvio ] = useState<boolean>(false)

const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        // let res_ = await RequestService.update( `URL_REQUEST` , {  actividades : actividades })
        let res_ = await RequestService.update(`presupuesto/enviar_valiacion_gerencia/${presupuesto.uuid_presupuesto}`, { registro : registro }  )

        if(res_){
          toast.success('MENSAJE')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}

const enviarPresupuestos = async(e:any ) => {
    try{

        await dialog.open({
          accept: 'SI',
          cancel: 'NO',
          dialogClass: '',
          message: 'Estás seguro?',
          target: e.target,
          template: `<p>El Presupuesto se enviará a Modificación</p>`
        })
        let dialogo : any = await dialog.waitForUser()
        
        if(dialogo){
          let res = await RequestService.update(`presupuesto/modificaciones/${presupuesto.uuid_presupuesto}`, {} )
          if(res.data){
            toast.success('Se actualizo el estatus del Presupuesto')
            handleClose()
          }
          
        }

    }
    catch(e){
      toast.error('Tuvimos problemas  ')
    }

  }
  


  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Detalles del Presupuesto</Modal.Title>
        </Modal.Header>
        <Modal.Body>



        <div className="row">
          <div className="col-sm-4">
          {
            // JSON.stringify(presupuesto)
          }

          <h6 className="mt-2">Folio</h6>
          <p>{presupuesto.folio}</p>

          <h6 className="mt-2">Boutique</h6>
          <p className="nm">{presupuesto.idboutique.nombre_boutique}</p>
          {
            presupuesto.solicitado && (<p className="nm"><i className="fa fa-star"/> Solicitado por la Boutique</p>)
          }


          <h6 className="mt-">Fecha de Creación</h6>
          <p className="nm">{moment(presupuesto.fecha_creacion).format('YYYY-MM-DD hh:mm:ss')}</p>
          <p className="nm"><small>{calcularTiempoTranscurrido(presupuesto.fecha_creacion)}</small></p>

                    <h5 className="mt-2">Descripción General</h5>
                    <p>{presupuesto.descripcion}</p>

          <h6 className="mt-2">Descripción de la Solicitud de Presupuesto</h6>
          <ol>
            {
              presupuesto.actividades_propuestas.map((actividad:any)=>
                  <li>
                    <p className="nm"><small>{actividad.actividad}</small></p>
                  </li>
                )
            }
          </ol>

          <h6 className="mt-2">Condiciones de Pago</h6>
          <p>{condicionesPago(presupuesto.tipo_condicion_pago)}</p>

          <h6 className="mt-2">Condiciones de Ejecución</h6>
          <p><small>{presupuesto.condiciones_ejecucion}</small></p>

          <h6 className="mt-2">Vigencia de Precios</h6>
          <p>{presupuesto.vigencia_dias_precios}</p>


          <h6 className="mt-2">Tiempo Estimado de la Obra</h6>
          <p>{presupuesto.dias_finaliza_estimados} Día(s)</p>


          </div>
          <div className="col-sm-8">
            {
              // JSON.stringify(presupuesto.material_actividades)
            }


                  <h5 className="mt-2">Explosión de precios unitarios</h5>

                  <table className="table table-sm" style={{fontSize:12}}>
                    <thead>
                      <tr>
                        <th scope="col">Tipo</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Unidad</th>
                        <th scope="col">Cantidad</th>
                        <th scope="col">P.U.</th>
                        <th scope="col">Costo</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      [...presupuesto.material_actividades].sort(ordenarPorTipo).map((material:any,index:number)=>


                      <tr>
                        <td className="v" style={{width:120}}>
                          <p className="nm">
                          {
                            // JSON.stringify(material.idunidad_medida)
                          }
                          <small><b>{tipoActividadLabel(material.tipo)}</b></small>
                          </p>
                        </td>
                        <td>
                          <p className="nm"><small>{material.descripcion}</small></p>
                        </td>
                        <td className="v" style={{width:120}}>
                          {material.idunidad_medida&&(<p className="nm"><small>{material.idunidad_medida.unidad_medida}</small></p>)}
                        </td>
                        <td className="v" style={{width:80}}>
                          <p className="nm text-right">{formatearNumero(parseFloat(material.cantidad)||0)}</p>
                        </td>
                        <td className="v" style={{width:80}}>
                          <p className="nm text-right">$ {formatearNumero(parseFloat(material.costo)||0)}</p>
                        </td>
                        <td className="v" style={{width:80}}>
                          <p className="nm text-right">$ {formatearNumero(parseFloat((material.costo)||0)*(parseFloat(material.cantidad)||0))}</p>
                        </td>
                      </tr>

                        )
                    }

                    {
                      !presupuesto.entra_iguala && (

                          <React.Fragment>

                            <tr className="table-subs">
                              <td className="v" style={{width:100}} colSpan={5}>
                                <p className="nm text-right"><b>SUB TOTAL:</b></p>
                              </td>
                              <td className="v" style={{width:100}}>
                                <p className="nm text-right">$ {formatearNumero(suma_subtotal||0)}</p>
                              </td>
                            </tr>
                            <tr className="table-subs">
                              <td className="v" style={{width:100}} colSpan={5}>
                                <p className="nm text-right"><b>SERVICIO:</b></p>
                              </td>
                              <td className="v" style={{width:100}}>
                                <p className="nm text-right">$ {formatearNumero(presupuesto.costo_servicio)}</p>
                              </td>
                            </tr>
                            <tr className="table-subs">
                              <td className="v" style={{width:100}} colSpan={5}>
                                <p className="nm text-right"><b>SERVICIO + SUB TOTAL:</b></p>
                              </td>
                              <td className="v" style={{width:100}}>
                                <p className="nm text-right">$ {formatearNumero(presupuesto.costo_servicio + (suma_subtotal||0))}</p>
                              </td>
                            </tr>
                            <tr className="table-impuestos">
                              <td className="v" style={{width:100}} colSpan={5}>
                                <p className="nm text-right"><b>IMPUESTOS:</b></p>
                              </td>
                              <td className="v" style={{width:100}}>
                                <p className="nm text-right">$ {formatearNumero((presupuesto.costo_servicio + (suma_subtotal||0))*.16)}</p>
                              </td>
                            </tr>
                            <tr className="table-total">
                              <td className="v" style={{width:100}} colSpan={5}>
                                <p className="nm text-right"><b>TOTAL:</b></p>
                              </td>
                              <td className="v" style={{width:100}}>
                                <p className="nm text-right">$ {formatearNumero( (presupuesto.costo_servicio + (suma_subtotal||0)) + ((presupuesto.costo_servicio + (suma_subtotal||0))*.16)  )}</p>
                              </td>
                            </tr>

                          </React.Fragment>

                        )
                    }

                    </tbody>
                  </table>

                  {
                    // presupuesto.entra_iguala?'si':'no'
                  }

          </div>
        </div>





        </Modal.Body>


      {
        [4].indexOf(presupuesto.idestatus_presupuesto.id) > -1 && (


        <Modal.Footer>
          <Button variant="danger"  onClick={cancelarPresupuesto}>
            Cancelar 
          </Button>
          <Button variant="warning"  onClick={enviarPresupuestos}>
            Enviar a cambios 
          </Button>
          <Button variant="success" onClick={confirmarreporteEntradaSalida} type="button">Aceptar  </Button>
        </Modal.Footer>

        
          )
      }

      </Modal>
    </>
  );
}



export default ModalRevisarPresupuesto;