import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { formatearFechaHora, formatearFechaEstandar, formatearNumero } from '../../services/funcionesPublicas'

import ModalRequisicionDetalles from './ModalRequisicionDetalles'


interface Elemento {
    folio: string;
    concepto: string;
    tipo: string;
    con_factura: boolean;
    monto_total: number;
    cantidad: number;
    unidad_medida: string | null;
    fecha_limite: string;
    seleccionado: boolean;
}


const ModalPresupuestosDeRequisiciones = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params


  const [ requisiciones_presupuesto, setRequisiciones_presupuesto ] = useState<Elemento[] >([])
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarRequisiciones_presupuesto = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`requisiciones/presupuestos`)
      setRequisiciones_presupuesto(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarRequisiciones_presupuesto()
  }, []);



const sumaMontoTotalSeleccionado = (elementos: Elemento[]): number => {
    return elementos.reduce((acc:any, item:any) => item.seleccionado ? acc + item.monto_total : acc, 0);
}

const sumaMontoTotalSeleccionadoConFactura = (elementos: Elemento[]): number => {
    return elementos.reduce((acc:any, item:any) => (item.seleccionado && item.con_factura) ? acc + item.monto_total : acc, 0);
}

const sumaMontoTotalSeleccionadoSinFactura = (elementos: Elemento[]): number => {
    return elementos.reduce((acc:any, item:any) => (item.seleccionado && !item.con_factura) ? acc + item.monto_total : acc, 0);
}


  const [ requisicion_seleccionada, setRequisicionSeleccionada ] = useState<any>(null)

  const [ show_ModalRequisicionDetalles, setModalRequisicionDetalles ] = useState<boolean>(false)
  const handleShow_ModalRequisicionDetalles = (requisicion:any) => {
    setRequisicionSeleccionada(requisicion)
    setModalRequisicionDetalles(true)
  }
  const handleClose_ModalRequisicionDetalles = () => {
        // busqueda()
    setModalRequisicionDetalles(false)
  }



  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Presupuestos de las Requisiciones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(requisiciones_presupuesto)
          }

          <div className="row">
            <div className="col-4">
              <small>Total</small>
              <h4 className="nm">$ {formatearNumero(sumaMontoTotalSeleccionado(requisiciones_presupuesto))}</h4>
            </div>
            <div className="col-4">
              <small>Con Factura</small>
              <h4 className="nm">$ {formatearNumero(sumaMontoTotalSeleccionadoConFactura(requisiciones_presupuesto))}</h4>
            </div>
            <div className="col-4">
              <small>Sin Factura</small>
              <h4 className="nm">$ {formatearNumero(sumaMontoTotalSeleccionadoSinFactura(requisiciones_presupuesto))}</h4>
            </div>
          </div>

          <hr/>

    <table className="table table-sm table-striped" style={{fontSize:10}}>
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col">Factura</th>
          <th scope="col">Folio</th>
          <th scope="col">Concepto</th>
          <th scope="col">Tipo</th>
          <th scope="col">Monto Total</th>
          <th scope="col">Cantidad</th>
          <th scope="col">Unidad de Medida</th>
          <th scope="col">Límite</th>
          <th scope="col">Creación</th>
        </tr>
      </thead>
      <tbody>
        {
          requisiciones_presupuesto.map((requisicion_elemento:any, index:number) => (
          <tr key={index}>
            <td className="v">
              <input
                type="checkbox"
                checked={requisicion_elemento.seleccionado}
                onChange={(e:any)=>{ let a = [...requisiciones_presupuesto]; a[index].seleccionado = e.target.checked; setRequisiciones_presupuesto(a); }}
              />
            </td>
              <td className="v" style={{"width":"20px"}}>
                  { !requisicion_elemento?.idestatus_elemento && (<>⌛</>) }
                  { [1].indexOf(requisicion_elemento?.idestatus_elemento) > -1 && (<>✅</>) }
                  { [2].indexOf(requisicion_elemento?.idestatus_elemento) > -1 && (<>❌</>) }
              </td>
            <td style={{width:80}} className="v text-center">{requisicion_elemento.con_factura?'SI':'NO'}</td>
            <td style={{width:120}} onClick={(e:any)=>{handleShow_ModalRequisicionDetalles(requisicion_elemento)}} className="v text-left pointer"><small><b>{requisicion_elemento.folio}</b></small></td>
            <td className="v text-left">{requisicion_elemento.concepto}</td>
            <td style={{width:120}} className="v text-left">{requisicion_elemento.tipo.toUpperCase()}</td>
            <td style={{width:80}} className="v text-right">$ {formatearNumero(requisicion_elemento.monto_total)}</td>
            <td style={{width:70}} className="v text-center">{requisicion_elemento.cantidad}</td>
            <td style={{width:100}} className="v text-left">{requisicion_elemento.unidad_medida}</td>
            <td style={{width:90}} className="v text-right">{formatearFechaEstandar(requisicion_elemento.fecha_limite)}</td>
            <td style={{width:90}} className="v text-right">{formatearFechaEstandar(requisicion_elemento.fecha_creacion)}</td>
          </tr>
        ))
        }
      </tbody>
    </table>

        </Modal.Body>
      </Modal>

            {
              show_ModalRequisicionDetalles && (
                <ModalRequisicionDetalles show={show_ModalRequisicionDetalles} handleClose={handleClose_ModalRequisicionDetalles} requisicion={requisicion_seleccionada} />
                )

            }

    </>
  );
}


export default ModalPresupuestosDeRequisiciones;