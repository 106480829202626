import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import {Button, Modal, Form, Table } from 'react-bootstrap';

import Pagination from "../../services/Pagination";


import moment from 'moment'
import 'moment/locale/es'


import ModalTecnicoNuevoPresupuesto from './ModalTecnicoNuevoPresupuesto'
import ModalDetallePresupuestoTecnico from './ModalDetallePresupuestoTecnico'

import ModalEditarPresupuesto from './ModalEditarPresupuesto'


import { tipoPresupuesto, calcularTiempoTranscurrido } from '../../services/funcionesPublicas'


import Dialog from '../../services/Dialogs'


import { SERVER_ } from  '../../config';

import './colores_presupuestos.scss'

const PresupuestosTecnico = () => {

  const dialog = new Dialog()

  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const [ show_ModalTecnicoNuevoPresupuesto, setModalTecnicoNuevoPresupuesto ] = useState<boolean>(false)
  const handleShow_ModalTecnicoNuevoPresupuesto = () => {
    setModalTecnicoNuevoPresupuesto(true)
  }
  const handleClose_ModalTecnicoNuevoPresupuesto = () => {
    busqueda()
    setModalTecnicoNuevoPresupuesto(false)
  }



  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let response : any = await RequestService.getEndPoint(`presupuestos/tecnicos/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(0)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true,true,true,true,true,true,true,true,true])


  const todos = () =>{
    setEstatus([true,true,true,true,true,true,true,true,true])
  }
  const ninguno = () =>{
    setEstatus([false,false,false,false,false,false,false,false,false])
  }


  const [ presupuesto_seleccionado, setPresupuesto ] = useState<any>({})

  const [ show_ModalDetallePresupuesto, setModalDetallePresupuesto ] = useState<boolean>(false)
  const handleShow_ModalDetallePresupuesto = (presupuesto:any) => {
    setPresupuesto(presupuesto)
    setModalDetallePresupuesto(true)
  }
  const handleClose_ModalDetallePresupuesto = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalDetallePresupuesto(false)
  }



  const [ show_ModalEditarPresupuesto, setModalEditarPresupuesto ] = useState<boolean>(false)
  const handleShow_ModalEditarPresupuesto = (presupuesto:any) => {
    setPresupuesto(presupuesto)
    setModalEditarPresupuesto(true)
  }
  const handleClose_ModalEditarPresupuesto = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalEditarPresupuesto(false)
  }






const eliminarPresupuesto = async (event:any,uuid_presupuesto:string) =>{

      await dialog.open({
        accept: 'Si, eliminar',
        cancel: 'Espera',
        dialogClass: '',
        message: 'ESPERA',
        target: event.target,
        template: `<p>La información se perderá</p>`
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.delete( `presupuesto/${uuid_presupuesto}` )

        if(res_){
          toast.warning('Se eliminó el Registro de Presupuesto')
          setTimeout(()=>{
            // handleClose()
            cargarMiReporte(currentPage===0?0:currentPage-1)
            // cargar()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}





  React.useEffect(() => {
    cargarMiReporte(0)
  }, []);

      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Mis Presupuestos</h4>
                    <hr/>

                    <ul className="list-inline">
                    </ul>
                    


                    <div className="row">
                    
                        <div className="mb-2">
                            <label className="form-label">Búsqueda</label>
                            <div className="hstack gap-3">
                              <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                              <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                              <div className="vr"></div>
                              <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                            </div>
                        </div>
                        
                        <div>
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="NUEVA SOLICITUD" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="CREANDO PRESUPUESTO" checked={arreglo_estatus[1]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}} id={`opciones_2`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="APROBACIÓN GERENCIA" checked={arreglo_estatus[2]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}} id={`opciones_3`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="APROBACIÓN CLIENTE" checked={arreglo_estatus[3]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}} id={`opciones_4`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="ACEPTADA POR CLIENTE" checked={arreglo_estatus[4]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[4] = e.target.checked;  setEstatus(ne);}} id={`opciones_5`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="INCLUIDO EN IGUALA" checked={arreglo_estatus[5]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[5] = e.target.checked;  setEstatus(ne);}} id={`opciones_6`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="ACEPTADA" checked={arreglo_estatus[6]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[6] = e.target.checked;  setEstatus(ne);}} id={`opciones_7`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="RECHAZADA" checked={arreglo_estatus[7]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[7] = e.target.checked;  setEstatus(ne);}} id={`opciones_8`} />
                            <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="CANCELADA" checked={arreglo_estatus[8]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[8] = e.target.checked;  setEstatus(ne);}} id={`opciones_9`} />
                        </div>
                        
                      <ul className="list-inline">
                        <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalTecnicoNuevoPresupuesto()}}>Nueva Solicitud</small></li>
                        <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                      </ul>

                    </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Folio</th>
                                              <th scope="col">Estatus</th>
                                              <th scope="col">Boutique</th>
                                              <th scope="col"></th>
                                              <th scope="col">Tipo</th>
                                              <th scope="col">Creación</th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (presupuesto:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                                    <td className={`v text-left _estatus_${presupuesto.idestatus_presupuesto.id}`} style={{"width":"160px"}}>
                                                        <p className="nm"><b>{presupuesto.folio}</b></p>                                                            
                                                    </td>
                                                    <td className={`v text-center estatus_presupuesto_${presupuesto.idestatus_presupuesto.id}`} style={{"width":"180px"}} >
                                                      <b>{presupuesto.idestatus_presupuesto.estatus}</b> {' '}
                                                       {[1].indexOf(presupuesto.idestatus_presupuesto.id) > -1 && (<i onClick={(e:any)=>{eliminarPresupuesto(e,presupuesto.uuid_presupuesto)}} className="fa pointer fa-trash"/>)}
                                                    </td>
                                                    <td className="v text-left" style={{"width":"240px"}} >
                                                      {presupuesto.idboutique&&(<p className="nm">{presupuesto.idboutique.nombre_boutique}</p>)}
                                                    </td>
                                                    <td className="v text-left"  >
                                                      <p className="nm"><small>{presupuesto.descripcion}</small> </p>
                                                      {
                                                        // <a href={`${SERVER_}ficha_presupuesto/${presupuesto.uuid_presupuesto}`} target="_blank"><i className="fa fa-link"/></a>
                                                      }
                                                    </td>
                                                    <td className={`v text-center ${presupuesto.tipo_presupuesto}`} style={{"width":"180px"}} >
                                                      <p className="nm">{tipoPresupuesto(presupuesto.tipo_presupuesto)}</p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"100px"}}>
                                                        <p className="nm"><b>{moment(presupuesto.fecha_creacion).format('YYYY-MM-DD')}</b></p>
                                                        <p className="nm"><small>{calcularTiempoTranscurrido(presupuesto.fecha_creacion)}</small></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"50px"}}>
                                                        <Button onClick={(e:any)=>{handleShow_ModalDetallePresupuesto(presupuesto)}} size="sm" variant="primary"><i className="fa fa-address-book-o"/></Button>
                                                    </td>

                                                    <td className="v text-left" style={{"width":"50px"}}>
                                                    
                                                        {
                                                          [1].indexOf(presupuesto.idestatus_presupuesto.id) > -1 && (
                                                                <Button onClick={(e:any)=>{handleShow_ModalEditarPresupuesto(presupuesto)}} size="sm" variant="warning"><i className="fa fa-edit"/></Button>
                                                            )
                                                        }
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}




                </div>
              </div>

            </div>


  {
    show_ModalTecnicoNuevoPresupuesto && (
      <ModalTecnicoNuevoPresupuesto show={show_ModalTecnicoNuevoPresupuesto} handleClose={handleClose_ModalTecnicoNuevoPresupuesto}  />
      )

  }





  {
    show_ModalDetallePresupuesto && (
      <ModalDetallePresupuestoTecnico show={show_ModalDetallePresupuesto} handleClose={handleClose_ModalDetallePresupuesto} presupuesto={presupuesto_seleccionado} />
      )

  }




  {
    show_ModalEditarPresupuesto && (
      <ModalEditarPresupuesto show={show_ModalEditarPresupuesto} handleClose={handleClose_ModalEditarPresupuesto}  presupuesto={presupuesto_seleccionado} />
      )

  }


          </>

      );
  

}

export default PresupuestosTecnico
