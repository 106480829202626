import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Form, Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import moment from 'moment'

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import { agregarDias, obtenerRangoFechasArreglo, obtenerDiaSemana, diasDeLaSemana, contarNoBaseODescanso } from '../../services/funcionesPublicas'


import Dialog from '../../services/Dialogs'


import ConfiguracionMantenimiento from './configuracionMantenimiento'
import ConfiguracionConservacion from './configuracionConservacion'
import ConfiguracionLimpieza from './configuracionLimpieza'
 

const ModalConfigurarNuevoCalendario = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params


const dialog = new Dialog();

  const [ calendario_configuraciones, setCalendario_configuraciones ] = useState<any>(null)
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarCalendario_configuraciones = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`calendarios/configuraciones`)
      setCalendario_configuraciones(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarCalendario_configuraciones()
  }, []);





    const [ fecha_fin, setFechaFin ] = useState<any>(new Date(agregarDias(new Date(),30)))
    const [ fecha_inicio, setFechaInicio ] =  useState<any>(new Date())



    const [ fechas, setFechas ] = useState<any>([])

    const [ calendario_generado, setCalendarioGenerado ] = useState<boolean>(false)
    const [ fechas_generadas, setFechasGeneradas ] = useState<boolean>(false)





    const crearConfiguracion = async(event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: `<p class="nm">Este calendario reemplazá al anterior</p>
                  <small><i class="fa fa-warning"></i> Este cambio es irreversible</small>
                  `
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `calendario/configuracion/crear` , { fecha_inicio : moment(fecha_inicio).format('YYYY-MM-DD'), fecha_fin : moment(fecha_fin).format('YYYY-MM-DD') })

        if(res_){
          toast.success('La nueva configuración fue creada')
          setTimeout(()=>{
            cargarCalendario_configuraciones()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}



  const [ panel, setPanel ] = useState<string>('MANTENIMIENTO')
    



const publicarABoutiques = async (event:any,estado:boolean) =>{

  let mensaje = 'Publicar Calendario'
  let submensaje = `<p>Mostrar el Calendario al Cliente</p>`
  let route = 'preview_boutique'
  let url_publicar = `calendario/${route}/${calendario_configuraciones.uuid}`

  if(!estado){
    mensaje = '🚧 Crear Visitas 🚧'
    submensaje = `<p class="nm"><b>¿Estás seguro?</b></p><small>Esta acción no podrá deshacerse y el calendario será aplicado</small>`
    route = 'crear_visitas'
    url_publicar = `calendario/crear_visitas/${calendario_configuraciones.uuid}` 
  }

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: mensaje,
        target: event.target,
        template: submensaje
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( url_publicar , {})
        // let res_ = await RequestService.update( `calendario/${route}/${calendario_configuraciones.uuid}` , {})

        if(res_){
          toast.success('El Calendario fue aplicado correctamente.',{ icon: "🟢"})

        let c = {...calendario_configuraciones}

        c.activa = true
        c.estatus = "PREVIEW_CLIENTE"

        if(!estado){
          c = null
        setCalendario_configuraciones(c)
          setTimeout(()=>{
            handleClose()
          },1000)
        }

        setCalendario_configuraciones(c)


        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}

const guardarCalendario = async (event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `calendario/guardar/${calendario_configuraciones.uuid}` , { configuracion: calendario_configuraciones  } )

        if(res_){
          toast.info('El Calendario se Guardo Correctamente', { icon : '💾' })
          setTimeout(()=>{
            // handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}







  return (
    <>
      <Modal show={show} fullscreen={true}  onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            
            <h5 className="nm">Configuración de Calendario</h5>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(calendario_configuraciones)
          }



          {
            !calendario_configuraciones && (
                <>
                  {
                    // crear calendario porque no tienes ninguno
                  }




                    <div className="row">
                      <div className="col-sm-6">

                            <div className="mb-3">
                              <Form.Label>Fecha de Inicio</Form.Label>
                              <DatePicker selected={fecha_inicio} className="form-control"  dateFormat="yyyy-MM-dd" onChange={(date) =>{ setFechaInicio(date); setFechaFin(new Date(agregarDias(date,30))) }} />
                            </div>

                      </div>
                      <div className="col-sm-6">

                            <div className="mb-3">
                              <Form.Label>Fecha de Fin</Form.Label>
                              <DatePicker selected={fecha_fin} className="form-control"  dateFormat="yyyy-MM-dd" minDate={fecha_inicio} onChange={(date) =>{ setFechaFin(date); }} />
                            </div>

                      </div>
                    </div>


                    <button type="button" onClick={(e:any)=>{crearConfiguracion(e)}} className="btn btn-primary">Generar Calendario</button>
                    
                    





                </>
              )
          }



          {
            calendario_configuraciones && (
                <>
                  
                  <div className="row align-items-center">
                    <div className="col-sm-6">

                            <div className="mb-3">
                              <Form.Label>Tipo de Calendario</Form.Label>

                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="tipo" id="inlineCheckbox1" checked={panel==='MANTENIMIENTO'} onChange={(e:any)=>{setPanel('MANTENIMIENTO')}} />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Sistemas de Enfriamiento</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="tipo" id="inlineCheckbox2" checked={panel==='CONSERVACION'} onChange={(e:any)=>{setPanel('CONSERVACION')}} />
                                <label className="form-check-label" htmlFor="inlineCheckbox2">Conservación de Espacios</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="tipo" id="inlineCheckbox3" checked={panel==='LIMPIEZA'} onChange={(e:any)=>{setPanel('LIMPIEZA')}} />
                                <label className="form-check-label" htmlFor="inlineCheckbox3">Servicio Limpieza</label>
                              </div>
                            </div>


                            <div className="mb-3">

                            {
                              // calendario_configuraciones.estatus
                            }
                              {
                                calendario_configuraciones.estatus === "CONFIGURANDO" && (
                                    <button type="button" onClick={(e:any)=>{publicarABoutiques(e,true)}} className="btn btn-primary">PUBLICAR CLIENTE <i className="fa fa-check"/></button>
                                  )
                              }
                              {
                                calendario_configuraciones.estatus === "PREVIEW_CLIENTE" && (
                                    <button type="button" onClick={(e:any)=>{publicarABoutiques(e,false)}} className="btn btn-secondary">CREAR VISITAS <i className="fa fa-check"/></button>
                                  )
                              }

                              {' '}
                              <button type="button" onClick={(e:any)=>{cargarCalendario_configuraciones()}} className="btn btn-mantenimiento"><i className="fa fa-database"/> Últimos Cambios</button>
                              {' '}
                              <button type="button" onClick={(e:any)=>{guardarCalendario(e)}} className="btn btn-primary">Guardar Calendario <i className="fa fa-save"/></button>

                            </div>

                    </div>
                    <div className="col-sm-6">
                      


                              {
                                calendario_configuraciones.estatus === "CONFIGURANDO" && (
                                      <div className="alert alert-dark" role="alert">
                                        <i className="fa fa-warning"/> El Calendario actual AÚN NO es visible para el cliente
                                      </div>
                                  )
                              }
                              {
                                calendario_configuraciones.estatus === "PREVIEW_CLIENTE" && (
                                      <div className="alert alert-success" role="alert">
                                        <i className="fa fa-warning"/> El Calendario actual es visible para el cliente
                                      </div>                                  )
                              }



                    </div>
                  </div>






                            {
                              panel==='MANTENIMIENTO' && (
                                  <>
                                        {
                                          // JSON.stringify(calendario_configuraciones.preview_calendario_mantenimiento)
                                        }

                                        <ConfiguracionMantenimiento configuracion={calendario_configuraciones} setCalendario_configuraciones={setCalendario_configuraciones} />
                                  </>
                                )
                            }


                            {
                              panel==='CONSERVACION' && (
                                  <>
                                        {
                                          // JSON.stringify(calendario_configuraciones.preview_calendario_conservacion)
                                        }
                                        <ConfiguracionConservacion configuracion={calendario_configuraciones} setCalendario_configuraciones={setCalendario_configuraciones} />
                                  </>
                                )
                            }

                            {
                              panel==='LIMPIEZA' && (
                                  <>
                                        {
                                          // JSON.stringify(calendario_configuraciones.preview_calendario_conservacion)
                                        }
                                        <ConfiguracionLimpieza configuracion={calendario_configuraciones} setCalendario_configuraciones={setCalendario_configuraciones} />
                                  </>
                                )
                            }



                </>
              )
          }


        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalConfigurarNuevoCalendario;