import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import moment from 'moment'

import { SERVER_ } from '../../config'

import { Form } from 'react-bootstrap';

const ModalReporteDePagosProgramados = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params


  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const onChange = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };



  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Reporte De Pagos Programados</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }

          <h6 className="mb-3">Selecciona un rango de fechas para el reporte:</h6>

          <DatePicker selected={startDate} onChange={onChange} startDate={startDate} endDate={endDate} selectsRange inline />


        <Button variant="primary" onClick={(e:any)=>{window.open(`${SERVER_}reporte_pagos_programados/download/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`)}}>Descargar Reporte</Button>



        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalReporteDePagosProgramados;