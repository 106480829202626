import React, { useState } from 'react'

import { Button, Modal, Tabs, Tab } from 'react-bootstrap'

import RequestService from "../../services/request.service";

import { SERVER_ } from  '../../config';

import { toast } from 'react-toastify'


import Dialog from '../../services/Dialogs'



const ModalAnexos = (props:any) => {

	const { usuario, onHideModalAnexos, showModalAnexos } = props

// 	const [ anexos_digitales, setAnexos ] = useState<any>([])

// 	const cargarAnexos = async ()=>{
// 		try{
// 			let res = await RequestService.getEndPoint('mesa_tencion/anexos_digitales/'+uuid_tramite)
// 			setAnexos(res.data)
// 		}
// 		catch(e){
// 			toast.error('Error al cargar los anexos')
// 		}
// 	}

  React.useEffect(() => {
    handleModalAnexosShow()
  }, []);

// const [ anexo_visualizar, setAnexoVisualizar ] = useState<string>('')
// const [ anexo_para_subir, setAnexoSubir ] = useState<string>('')
// const [ anexo_archivo_subir, setArchivoSubir ] = useState<any>('')

// // const setAnexoVer = (anexo:string)=>{
// //   setAnexoVisualizar(anexo)
// // }


    const dialog = new Dialog();


const [key, setKey] = useState<any>('home');


const [ anexo_visualizar, setAnexoVisualizar ] = useState<string>('')
const [ anexo_para_subir, setAnexoSubir ] = useState<string>('')
const [ anexo_archivo_subir, setArchivoSubir ] = useState<any>('')

const setAnexoVer = (anexo:string)=>{
  setAnexoVisualizar(anexo)
}

  const handleFileRead = async (event:any) => {
    const file = event.target.files[0]
    const base64 = await convertBase64(file)
    setArchivoSubir(base64)
  }


  const convertBase64 = (file:any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }


  const agregarNuevoAnexo = async () =>{
    try{
        if(await dialog.confirm('¿Estás seguro?',{})){
        let req = await  RequestService.update('usuario/subir_anexo/'+usuario.uuid, { uuid_requisito : anexo_para_subir, documento : anexo_archivo_subir})

        setAnexoVisualizar('')
        setAnexoSubir('')
        setArchivoSubir('')
          let req2 = await RequestService.getEndPoint('anexos_digitales/'+usuario.uuid)
          // console.log(req.data)
          setAnexosSeleccionados(req2.data)
          toast.success('Tu anexo se subio correctamente')
      }
    }
    catch(e){toast.error('Error')}
  }



  const [ anexos_tramite_seleccionado, setAnexosSeleccionados ] = useState<any>({
    anexos_digitales : [],
    anexos_por_subir : []
  })



  const [enviando, setEnviando] = useState(false);

  const eliminarAnexo = async (uuid:string) =>{
    try{
        if (await dialog.confirm( '¿Estas seguro?', {} )) {
        await RequestService.delete('eliminar-anexo-tramite/'+uuid)
        // cargarMiReporte(fecha)
        let req = await RequestService.getEndPoint('anexos_digitales/'+usuario.uuid)
        setAnexoVisualizar('')
        // console.log(req.data)
        setAnexosSeleccionados(req.data)

        toast.warning('Borraste un anexo. Espero que sepas lo que haces')
      }

    }
    catch(e){
      setEnviando(false)
      toast.error('No pudimos agregar el anexo digital. Comunicate con el administrador.')
    }  
  }



  const handleModalAnexosShow = async () => {
    try{
      // console.log(tramite)
      // setTramiteSeleccion(tramite)
      let req = await RequestService.getEndPoint('anexos_digitales/'+usuario.uuid)
      // console.log(req.data)
      setAnexosSeleccionados(req.data)
      // setModalAnexos(true)
    }
    catch(e){
      // setModalAnexos(false)
      toast.error('Error al cargar los anexos')
    }
  }




	return (

			<React.Fragment>

		      <Modal size="lg" show={showModalAnexos} onHide={onHideModalAnexos} backdrop="static">
		        <Modal.Header closeButton>
		          <Modal.Title>Anexos Digitales</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	
				
{
	// JSON.stringify(usuario)
}
				

				<small>Usuario</small>
				<h2>{usuario.nombres} {usuario.paterno} {usuario.materno} </h2>


				<hr/>

    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="home" title="Anexos">


              <div className="form-floating">
                <select className="form-select" value={anexo_visualizar} onChange={(e)=>{ setAnexoVisualizar(e.target.value) }} id="floatingSelect" aria-label="Floating label select example">
                <option disabled={anexo_visualizar===''?false:true} value="">Selecciona una opción</option>
                  {
                    anexos_tramite_seleccionado.anexos_digitales.map( (anexo:any) =>

                        <option key={ Math.random().toString(36).substr(2, 9) } value={anexo.uuid} >
                          {
                           	anexo.idtipo_documento.debe_visualizar_cliente && ('⚠️ ')
                           } 
                          {
                            (anexo.idtipo_documento.documento)
                          } 
                        </option>

                    )
                  }
                </select>
                <label htmlFor="floatingSelect">Anexo para visualizar</label>
              </div>


              <br />


              {
                anexo_visualizar != "" && (
                  <React.Fragment>
                    <object width="100%" height="700px" data={SERVER_+'anexo/'+anexo_visualizar} type="application/pdf">
                        <p>This browser does not support PDFs. Please download the PDF to view it: <a href="filename.pdf">Download PDF</a>.</p>
                    </object>

              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                    <button type="button" onClick={()=>{eliminarAnexo(anexo_visualizar)}} className="btn btn-link"><i className="fa fa-trash" /> Eliminar Anexo</button>
                </li>
              </ul>
                  </React.Fragment>
                )
              }



            {
              // JSON.stringify(anexos_tramite_seleccionado)
            }
      </Tab>
      <Tab eventKey="profile" title="Carga de Anexos">

          <h5>Cargar un Anexo del Trámite</h5>

          <div className="form-floating">
            <select className="form-select" id="floatingSelect" value={anexo_para_subir} onChange={(e)=>{ setAnexoSubir(e.target.value) }} aria-label="Anexos que puede subir:">
              <option disabled={anexo_para_subir===''?false:true} value="">Selecciona una opción</option>
              {
                anexos_tramite_seleccionado.anexos_por_subir.map( (anexo:any) =>
                    <option key={ Math.random().toString(36).substr(2, 9) } value={anexo.uuid_requisito} >
                      {
                       	anexo.debe_visualizar_cliente && ('⚠️ ')
                       } 
                       {
                        (anexo.requisito)
                      }

                    </option>
                )
              }
            </select>
            <label htmlFor="floatingSelect">Anexos que puede subir:</label>
          </div>


          <div className="mt-4 mb-4">
            <label htmlFor="formFileLg" className="form-label">Archivo para subir</label>
            <input className="form-control form-control-lg" id="formFileLg" type="file" accept="application/pdf" onChange={(event)=>{ handleFileRead(event); }} />
          </div>

          {
            // JSON.stringify(anexo_archivo_subir)

            anexo_archivo_subir !== '' && (

              <object data={anexo_archivo_subir} type="application/pdf" width="100%" height="900">
                <p>It appears you don't have a PDF plugin for this browser.
                No biggie... you can <a href="http://www.example.com/the.pdf">click here to
                download the PDF file.</a></p>
              </object>
          )

          }


          <div className="d-grid gap-2 mt-2">
            <button className="btn btn-primary" onClick={(e)=>{agregarNuevoAnexo()}} disabled={anexo_archivo_subir===''||anexo_para_subir===''} type="button">AGREGAR ANEXO</button>
          </div>





      </Tab>
    </Tabs>





		        </Modal.Body>
		      </Modal>

			</React.Fragment>

		)

}

export default ModalAnexos