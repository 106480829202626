import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import moment from 'moment'

import { SERVER_ } from  '../../config';


import Dialog from '../../services/Dialogs'

const ModalCargarAccesosOperacion = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, visita } = params


  const [ tiene_acceso, setTiene_acceso ] = useState<any>({
    empleado_anexos : []
  })
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarTiene_acceso = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`calendario/visita/informacion_acceso/${visita.idtipo_orden.id}/${visita.fecha_programada}/${visita.idboutique.uuid}/${visita.idtecnico.uuid}`)
      setTiene_acceso(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarTiene_acceso()
  }, []);




  const [ anexo_archivo_subir, setArchivoSubir ] = useState<any>('')


  const handleFileRead = async (event:any) => {
    const file = event.target.files[0]
    const base64 = await convertBase64(file)
    setArchivoSubir(base64)
  }


  const convertBase64 = (file:any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }




const dialog = new Dialog();

const enviarAnexo = async (event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `calendario/cargar_anexo_acceso/${visita.idtipo_orden.id}/${visita.fecha_programada}/${visita.idboutique.uuid}/${visita.idtecnico.uuid}` , { archivo : anexo_archivo_subir.split(',')[1] })

        if(res_){
          toast.success('El Anexo se agregó correctamente')
          setTimeout(()=>{
            cargarTiene_acceso()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){

          if (e.response?.status) {
            switch (e.response.status) {
              case 404:
                toast.error("Debes escribir algo")
                break;
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }
     
}




  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Cargar Acceso de Visita</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        {
          // JSON.stringify(visita)
        }


          <div className={` tipo_orden_${visita.idtipo_orden.id}`}>
          <small>Tipo de Visita</small>
               <h5>{visita.idtipo_orden.tipo_orden}</h5>
          </div>

          <small>Técnico</small>
          <h5>{visita.idtecnico.nombres} {visita.idtecnico.paterno} {visita.idtecnico.materno}</h5>


          
          { visita.idresponsable  && (<small>Responsable</small>)}
          { visita.idresponsable && (<p className="nm"><small>{visita.idresponsable.nombres} {visita.idresponsable.paterno} {visita.idresponsable.materno}</small></p>)}

          <small>Boutique</small>
          <h5>{visita.idboutique.nombre_boutique}</h5>
          <small>Sub Zona</small>
          <h5>{visita.idboutique.idsubarea.subarea}</h5>
          <small>Fecha</small>
          <h5>{moment(visita.fecha_programada).format('DD [DE] MMMM [DE] YYYY').toUpperCase()}</h5>

          {
            // JSON.stringify(tiene_acceso)
          }




          <div className="mt-4 mb-4">
            <label htmlFor="formFileLg" className="form-label">Archivo para subir</label>
            <input className="form-control form-control-lg" id="formFileLg" type="file" accept="application/pdf" onChange={(event)=>{ handleFileRead(event); }} />
          </div>

          <button type="button" onClick={enviarAnexo} disabled={anexo_archivo_subir===''} className="btn btn-primary">Cargar Acceso</button>




                      {
                        tiene_acceso.tiene_acceso && (
                            <React.Fragment>
                                <div className="alert alert-primary mt-4" role="alert">
                                    <p className="nm">El Acceso ha sido cargado </p>
                                    <a href={`${SERVER_}anexo_acceso/${tiene_acceso.tiene_acceso.uuid}`}  target="_blank">Ver Anexo de Acceso</a>
                                </div>
                            </React.Fragment>
                          )
                      }








        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalCargarAccesosOperacion;