import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import moment from 'moment'


import AvatarEditor from 'react-avatar-editor'



import Dialog from '../../services/Dialogs'

import { SERVER_ } from  '../../config';


 


const ModalClienteCrearUsuario = ( params : any ) => {

  const { show, handleClose, cliente, de_boutique } = params


  const dialog = new Dialog();



  const [ boutiques, setBoutiques ] = useState<any>([])
  const cargarBoutiques = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`cliente/boutiques/${cliente.uuid}`)
      setBoutiques(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarBoutiques()
  }, []);





  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

  username: yup.string().required(mensajes_validacion_generico).matches(/^[a-z0-9_.]+$/,'La contraseña solo puede tener letras y números. No se aceptan espacios ni caracteres especiales ni mayúsculas'),
  foto : yup.string().required(mensajes_validacion_generico),
  nombres : yup.string().required(mensajes_validacion_generico),
  paterno : yup.string().required(mensajes_validacion_generico),
  materno : yup.string(),


  // curp: yup.string(), //.required(mensajes_validacion_generico).matches( REG_CURP , "Formato de CURP invalido").required('El CURP es un campo obligatorio'),
  // rfc: yup.string(), //.required(mensajes_validacion_generico),
  email: yup.string().required(mensajes_validacion_generico).email('Formato de Email no valido').required('Este campo es obligatorio'),
  celular: yup.string().required(mensajes_validacion_generico).matches(/^\d{10}$/,"Formato no valido"),

  idboutique: !de_boutique ?  yup.string() : yup.string().required(mensajes_validacion_generico),


  });




const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: `
          <p class="text-center">
            <img className="img mt-4" src="${registro.foto}" width="120px" />
          </p>
          <small>Nombre(s)</small>
          <p class="nm">${registro.nombres} ${registro.paterno} ${registro.materno} </p>
          <small>Teléfono</small>
          <p class="nm">${registro.celular} </p>
        `
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{


        setSeEnvio(true)

        let URL_CREATE = `cliente/crear_usuario/${cliente.uuid}`
        if(de_boutique) URL_CREATE = `boutique/crear_usuario/${registro.idboutique}`

        let res_ = await RequestService.create( URL_CREATE , { ...registro, fecha_nacimiento : moment().format('YYYY-MM-DD'), foto : registro.foto.split(',')[1] })

        if(res_){
          toast.success('El Usuario fue creado correctamente')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          console.log(e)
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}


  const [ editor, setEditorRef ] = useState<any>(null)

  const [imgSrc, setImgSrc] = useState('')
  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      // setCrop({undefined}) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>{
        let imagen = reader.result?.toString() || ''
        if(imagen!==''){
          var imag_ = new Image();
          imag_.src = imagen;
          // console.log(imag_)
          // var newDataUri = imageToDataUri(imag_, targetWidth, targetHeight)
        }
        setImgSrc(imagen)

      })
      reader.readAsDataURL(e.target.files[0])
    }
  }


  const [ skale, setSkale ] = useState<any>(1)



const generarUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}




  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Acceso para Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Row className="mb-4">
            <Col md="3">
              <p className="nm text-center">
                <img src={`${SERVER_}cliente/logotipo/${cliente.uuid}`} style={{height:70}} />
              </p>
            </Col>
            <Col md="9">
              <small>Cliente</small>
              <h4>{cliente.cliente}</h4>
            </Col>
          </Row>
          {
            // JSON.stringify(cliente)
          }







    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={
        {

            username : "", 
            foto : "", 
            // foto_ : "", 
            nombres : "", 
            paterno : "", 
            materno : "", 
            rol : de_boutique ? "ROLE_BOUTIQUE" : "ROLE_CLIENTE", 
            celular : "", 
            email : "", 
            // curp : "", 

            idboutique : "", 


      }

        // {"username":"johnsmith","foto":"","foto_":"","nombres":"RICARDO","paterno":"PÉREZ","materno":"","rol":"ROLE_TECNICO","celular":"5551111111","email":"johndoe@example.com","curp":"GOMA010101HDFXXX01","rfc":"ABCD920101123"}



    }
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>


        {
          // JSON.stringify({...values,foto:'x'})
        }

              <div className="alert alert-primary" role="alert">
                

                  <h5>Información de Accesos</h5>

                        <Row>
                          <Form.Group as={Col} md="6" className="mb-3" controlId="username">
                            <Form.Label>Nombre de Usuario</Form.Label>
                            <Form.Control
                              required
                              name="username"
                              value={values.username}
                              onChange={(e)=>{ setFieldValue('username', (e.target.value) ) }}
                              isInvalid={!!errors.username}
                            />
                            <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                          </Form.Group>

                          {
                            de_boutique && (
                                  <Form.Group as={Col} md="6" className="mb-3" controlId="idboutique">
                                    <Form.Label>Boutique para acceder</Form.Label>
                                    <Form.Select required value={values.idboutique} onChange={(e)=>{ setFieldValue('idboutique',e.target.value) }} isInvalid={!!errors.idboutique} >
                                      <option>Selecciona una Boutique</option>
                                      { boutiques.map((boutique:any)=> <option key={ Math.random().toString(36).substr(2, 9) } value={boutique.uuid}>{boutique.nombre_boutique}</option> )} 
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.idboutique} </Form.Control.Feedback>
                                  </Form.Group>
                              )
                          }





                        </Row>

                    <hr/>
                    <p className="mb-0">Considera que por default la contraseña del nuevo usuario será su número de Celular. Recuerda que el usuario será responsable de cambiar su contraseña al acceder a la plataforma.</p>


              </div>





                  <h6>Información Personal</h6>


                  <Row>
                    <Col md="3">

                    {
                      values.foto === "" && (

                          <>

                            <Form.Group className="mb-3" controlId="foto">
                              <Form.Label>Selecciona una foto</Form.Label>
                              <Form.Control
                                required
                                type="file"
                                name="foto"
                                value={undefined}
                                accept="image/*" onChange={onSelectFile}
                                isInvalid={!!errors.foto}
                              />
                              <Form.Control.Feedback type="invalid">{errors.foto}</Form.Control.Feedback>
                            </Form.Group>

                                {
                                  imgSrc && (
                                      <>

                                        <AvatarEditor ref={setEditorRef} image={imgSrc} width={150} height={150} border={2} scale={skale} />

                                        <p>
                                          <input type='range' onChange={(e:any)=>{setSkale(e.target.value)}} min={1} max={10} step={.1} value={skale} className='custom-slider' />
                                        </p>

                                        <button type="button" onClick={(e)=>{ setFieldValue('foto',editor.getImage().toDataURL() ) }} className="btn btn-link"><small>Usar Foto <i className="fa fa-check-circle"/></small></button>

                                      </>
                                    )
                                }


                          </>

                        )
                    }

                    {
                      values.foto !== "" && (

                          <>
                              {
                                // JSON.stringify(values.foto)
                              }
                              <img className="img mt-4" src={values.foto} width="100%" />
                          </>

                        )
                    }

                      
                    </Col>
                    <Col md="9">
                      


                    <Form.Group   className="mb-3" controlId="nombres">
                      <Form.Label>Nombre(s)</Form.Label>
                      <Form.Control
                        required
                        name="nombres"
                        value={values.nombres}
                        onChange={(e)=>{ setFieldValue('nombres', (e.target.value.toUpperCase()) ) }}
                        isInvalid={!!errors.nombres}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">{errors.nombres}</Form.Control.Feedback>
                    </Form.Group>




                    <div className="row">
                      <div className="col-md-6">

                          <Form.Group   className="mb-3" controlId="paterno">
                            <Form.Label>Apellido Paterno</Form.Label>
                            <Form.Control
                              required
                              name="paterno"
                              value={values.paterno}
                              onChange={(e)=>{ setFieldValue('paterno', (e.target.value.toUpperCase()) ) }}
                              isInvalid={!!errors.paterno}
                            />
                            <Form.Control.Feedback type="invalid">{errors.paterno}</Form.Control.Feedback>
                          </Form.Group>

                      </div>
                      <div className="col-md-6">
                          <Form.Group   className="mb-3" controlId="materno">
                            <Form.Label>Apellido Materno</Form.Label>
                            <Form.Control
                              name="materno"
                              value={values.materno}
                              onChange={(e)=>{ setFieldValue('materno', (e.target.value.toUpperCase()) ) }}
                              isInvalid={!!errors.materno}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                          </Form.Group>
                        </div>
                    </div>

 
 


                    </Col>
                  </Row>






          <h5>Información de Contacto</h5>



          <Row>
            <Form.Group as={Col} md="7" className="mb-3" controlId="email">
              <Form.Label>Correo Electrónico</Form.Label>
              <Form.Control
                type="email"
                required
                name="email"
                value={values.email}
                onChange={(e)=>{ setFieldValue('email', e.target.value ) }}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.email} </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="5" className="mb-3" controlId="celular">
              <Form.Label>Celular</Form.Label>
              <Form.Control
                type="text"
                required
                name="celular"
                value={values.celular}
                onChange={(e)=>{ setFieldValue('celular',e.target.value) }}
                isInvalid={!!errors.celular}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.celular} </Form.Control.Feedback>
            </Form.Group>
          </Row>





        </Form>
      )}
    </Formik>





        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalClienteCrearUsuario;