import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'



import ModalAlmacenEditarInformacion from './ModalAlmacenEditarInformacion'

import ModalAlmacenAgregar from './ModalAlmacenAgregar'

const ModalAlmacenes = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params



  

  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let response : any = await RequestService.getEndPoint(`almacenes/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true])


  const todos = () =>{
    setEstatus([true])
  }
  const ninguno = () =>{
    setEstatus([false])
  }



  const [ almacen_seleccionado, setAlamacenSeleccionado ] = useState<any>(null)

  const [ show_ModalAlmacenEditarInformacion, setModalAlmacenEditarInformacion ] = useState<boolean>(false)
  const handleShow_ModalAlmacenEditarInformacion = (almacen:any) => {
    setAlamacenSeleccionado(almacen)
    setModalAlmacenEditarInformacion(true)
  }
  const handleClose_ModalAlmacenEditarInformacion = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalAlmacenEditarInformacion(false)
  }



  const [ show_ModalAlmacenAgregar, setModalAlmacenAgregar ] = useState<boolean>(false)
  const handleShow_ModalAlmacenAgregar = () => {
    setModalAlmacenAgregar(true)
  }
  const handleClose_ModalAlmacenAgregar = () => {
    setModalAlmacenAgregar(false)
  }


  
  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Modal ModalAlmacenes</Modal.Title>
        </Modal.Header>
        <Modal.Body>







        <div className="row">
        
            <div className="mb-2">
                <label className="form-label">Búsqueda</label>
                <div className="hstack gap-3">
                  <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                  <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                  <div className="vr"></div>
                  <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                </div>
            </div>
            
        </div>



            
          <ul className="list-inline">
            <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalAlmacenAgregar()}}>Nuevo Almacén</small></li>
          </ul>



                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Almacén</th>
                                              <th scope="col">Detalles</th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (almacen:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                        <p className="nm"><b>{almacen.almacen}</b></p>
                                                    </td>
                                                    <td className="v text-left"  >
                                                        <p className="nm"><small>{almacen.detalles}</small></p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"50px"}}>
                                                      <button type="button" onClick={(e:any)=>{handleShow_ModalAlmacenEditarInformacion(almacen)}} className="btn btn-link"><i className="fa fa-edit"/></button>
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}




        </Modal.Body>

      </Modal>





  {
    show_ModalAlmacenEditarInformacion && (
      <ModalAlmacenEditarInformacion show={show_ModalAlmacenEditarInformacion} handleClose={handleClose_ModalAlmacenEditarInformacion} almacen={almacen_seleccionado}  />
      )

  }


  {
    show_ModalAlmacenAgregar && (
      <ModalAlmacenAgregar show={show_ModalAlmacenAgregar} handleClose={handleClose_ModalAlmacenAgregar}  />
      )

  }


    </>
  );
}


export default ModalAlmacenes;