import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';


import { Form, Col, Row, FormCheck } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';


import { toast } from 'react-toastify'

import Dialog from '../../services/Dialogs'
import { calificaciones } from '../../services/funcionesPublicas'


const ModalBoutiqueEncuestaServicio = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, boutique, visita } = params


  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
          calificacion_boutique : yup.string().required(mensajes_validacion_generico),
  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: '<p>Tomaremos en cuenta tu calificación</p>'
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.create( `servicio/encuesta_boutique/${visita.uuid_servicio}` , { ...registro })

          if(res_){
            toast.success('Tu calificación fue registrada')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas al intentar enviar tu calificación.')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }


  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Encuesta de Servicio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }



          <Formik
            validationSchema={schema}
            onSubmit={(values,e)=>{enviarFormulario(values, e)}}
            initialValues={{

                  calificacion_boutique : "",

            }}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form id='my-form' noValidate onSubmit={handleSubmit}>

              <Form.Group controlId="calificacion_boutique">
                <Form.Label>Calificación de la Boutique</Form.Label>
                {[1, 2, 3, 4, 5].map((valor) => (
                  <Form.Check
                    key={valor}
                    type="radio"
                    label={`${calificaciones[valor]}`}
                    id={`calificacion${valor}`}
                    name="calificacion_boutique"
                    value={valor.toString()}
                    checked={values.calificacion_boutique === valor.toString()}
                    onChange={() => setFieldValue('calificacion_boutique', valor.toString())}
                    isInvalid={!!(touched.calificacion_boutique && errors.calificacion_boutique)}
                  />
                ))}
                <Form.Control.Feedback type="invalid">{errors.calificacion_boutique}</Form.Control.Feedback>
              </Form.Group>


              </Form>
            )}
          </Formik>



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Calificar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalBoutiqueEncuestaServicio;







