import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import { Button, Modal } from 'react-bootstrap'

import { toast } from 'react-toastify'

import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import AuthService from '../../features/auth/auth.service'


import Dialog from '../../services/Dialogs'

import moment from 'moment'


const ModalVehiculoRegistro = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params




  const dialog = new Dialog();





  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({


            numero_motor : yup.string().required(mensajes_validacion_generico),
            placas : yup.string().required(mensajes_validacion_generico),
            modelo : yup.string().required(mensajes_validacion_generico),
            marca : yup.string().required(mensajes_validacion_generico),

            servicio_cada : yup.number().required(mensajes_validacion_generico),
            detalles : yup.string().required(mensajes_validacion_generico),
            tiempo_servicio : yup.string().required(mensajes_validacion_generico),
            km_inicial : yup.number().required(mensajes_validacion_generico),

            color : yup.string().required(mensajes_validacion_generico),
            transmision : yup.string().required(mensajes_validacion_generico),



  });



const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `vehiculos/registro_vehicular` , { ...registro })

        if(res_){
          toast.success('MENSAJE')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}





  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Alta de Vehículo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }




    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

              numero_motor : "", 
              placas : "", 
              modelo : "", 
              marca : "", 

              servicio_cada : "", 
              detalles : "", 
              tiempo_servicio : "", 
              km_inicial : "", 
              color : "", 
              transmision : "", 


      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>




            <Form.Group   className="mb-3" controlId="marca">
              <Form.Label>Marca</Form.Label>
              <Form.Control
                required
                name="marca"
                value={values.marca}
                onChange={(e)=>{ setFieldValue('marca', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.marca}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.marca}</>} </Form.Control.Feedback>
            </Form.Group>



            <Form.Group   className="mb-3" controlId="modelo">
              <Form.Label>Modelo</Form.Label>
              <Form.Control
                required
                name="modelo"
                value={values.modelo}
                onChange={(e)=>{ setFieldValue('modelo', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.modelo}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.modelo}</>} </Form.Control.Feedback>
            </Form.Group>


            <div className="row">
              <div className="col-sm-5">
                  <Form.Group   className="mb-3" controlId="placas">
                    <Form.Label>Placas</Form.Label>
                    <Form.Control
                      required
                      name="placas"
                      value={values.placas}
                      onChange={(e)=>{ setFieldValue('placas', (e.target.value || "").toUpperCase() ) }}
                      isInvalid={!!errors.placas}
                    />
                    <Form.Control.Feedback type="invalid"> {<>{errors.placas}</>} </Form.Control.Feedback>
                  </Form.Group>
              </div>
              <div className="col-sm-7">
                  <Form.Group   className="mb-3" controlId="numero_motor">
                    <Form.Label>Número de Motor</Form.Label>
                    <Form.Control
                      required
                      name="numero_motor"
                      value={values.numero_motor}
                      onChange={(e)=>{ setFieldValue('numero_motor', (e.target.value || "").toUpperCase() ) }}
                      isInvalid={!!errors.numero_motor}
                    />
                    <Form.Control.Feedback type="invalid"> {<>{errors.numero_motor}</>} </Form.Control.Feedback>
                  </Form.Group>
              </div>
            </div>


            <div className="row">
              <div className="col-sm-6">
                  <Form.Group   className="mb-3" controlId="color">
                    <Form.Label>Color</Form.Label>
                    <Form.Control
                      required
                      name="color"
                      value={values.color}
                      onChange={(e)=>{ setFieldValue('color', (e.target.value || "").toUpperCase() ) }}
                      isInvalid={!!errors.color}
                    />
                    <Form.Control.Feedback type="invalid"> {<>{errors.color}</>} </Form.Control.Feedback>
                  </Form.Group>
              </div>
              <div className="col-sm-6">
                  <Form.Group   className="mb-3" controlId="transmision">
                    <Form.Label>Transmisión</Form.Label>
                      <Form.Select required name="transmision" value={values.transmision} onChange={(e)=>{ setFieldValue('transmision', (e.target.value || "").toUpperCase() ) }} isInvalid={!!errors.transmision} >
                        <option value="MANUAL">MANUAL</option>
                        <option value="AUTOMÁTICA">AUTOMÁTICA</option>
                      </Form.Select>
                    <Form.Control.Feedback type="invalid"> {<>{errors.transmision}</>} </Form.Control.Feedback>
                  </Form.Group>
              </div>
            </div>





            <div className="row">
              <div className="col-sm-6">

                <Form.Group   className="mb-3" controlId="servicio_cada">
                  <Form.Label>Servicio Cada (KM)</Form.Label>
                  <Form.Control
                    required
                    name="servicio_cada"
                    value={values.servicio_cada}
                    onChange={(e)=>{ setFieldValue('servicio_cada', (e.target.value || "").toUpperCase() ) }}
                    isInvalid={!!errors.servicio_cada}
                  />
                  <Form.Control.Feedback type="invalid"> {<>{errors.servicio_cada}</>} </Form.Control.Feedback>
                </Form.Group>

              </div>
              <div className="col-sm-6">

                  <Form.Group   className="mb-3" controlId="km_inicial">
                    <Form.Label>KM Inicial</Form.Label>
                    <Form.Control
                      required
                      name="km_inicial"
                      value={values.km_inicial}
                      onChange={(e)=>{ setFieldValue('km_inicial', (e.target.value || "").toUpperCase() ) }}
                      isInvalid={!!errors.km_inicial}
                    />
                    <Form.Control.Feedback type="invalid"> {<>{errors.km_inicial}</>} </Form.Control.Feedback>
                  </Form.Group>

              </div>
            </div>





            <Form.Group   className="mb-3" controlId="detalles">
              <Form.Label>Descripción o Detalles del Vehículo</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="detalles"
                value={values.detalles}
                onChange={(e)=>{ setFieldValue('detalles', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.detalles}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.detalles}</>} </Form.Control.Feedback>
            </Form.Group>

{
            <Form.Group   className="mb-3" controlId="tiempo_servicio">
              <Form.Label>Número de Tarjeta de Circulación</Form.Label>
              <Form.Control
                required
                name="tiempo_servicio"
                value={values.tiempo_servicio}
                onChange={(e)=>{ setFieldValue('tiempo_servicio', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.tiempo_servicio}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.tiempo_servicio}</>} </Form.Control.Feedback>
            </Form.Group>
}




 


        </Form>
      )}
    </Formik>




        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalVehiculoRegistro;