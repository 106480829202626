import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal, Form, Row, Col } from 'react-bootstrap';

import { toast } from 'react-toastify'

import moment from 'moment'

import ModalEquipoCriticoAgregarBoutique from './ModalEquipoCriticoAgregarBoutique'
import ModalEquipoCriticoEditarBoutique from './ModalEquipoCriticoEditarBoutique'

import FichaEmpleado from '../FichaEmpleado'

import { SERVER_ } from  '../../config';


import Dialog from '../../services/Dialogs'

import { calcularDiasPasados } from '../../services/funcionesPublicas'




const ModalEquiposCriticosBoutique = ( params : any ) => {

  const { show, handleClose, boutique_ } = params
  const [ boutique, setBoutique ] = useState<any>({ ... boutique_ })
  const dialog = new Dialog();

      const [ equipos_criticos, setEquipoCritico ] = useState<any>([])

      const cargar = async () =>{
        try{
            let res = await RequestService.getEndPoint(`boutique/equipos_criticos/${boutique.uuid}`)
            setEquipoCritico(res.data)

        }
        catch(e){
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
        }
      }

      React.useEffect(() => {
        cargar()
      }, []);



      const [ usuarios, setUsuarios ] = useState<any>([])

      const cargarTecnicosDisponibles = async () =>{
        try{
            let res = await RequestService.getEndPoint(`boutique/lista_tecnicos_disponibles/${boutique.uuid}`)
            setUsuarios(res.data)

        }
        catch(e){
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
        }
      }

      React.useEffect(() => {
        cargarTecnicosDisponibles()
        cargar()
      }, []);


const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const [ show_ModalEquipoCriticoAgregarBoutique, setModalEquipoCriticoAgregarBoutique ] = useState<boolean>(false)
  const handleShow_ModalEquipoCriticoAgregarBoutique = () => {
    setModalEquipoCriticoAgregarBoutique(true)
  }
  const handleClose_ModalEquipoCriticoAgregarBoutique = () => {
        cargar()
    setModalEquipoCriticoAgregarBoutique(false)
  }



  const [ equipo, setEquipo ] = useState<any>({})
  const [ show_ModalEquipoCriticoEditarBoutique, setModalEquipoCriticoEditarBoutique ] = useState<boolean>(false)
  const handleShow_ModalEquipoCriticoEditarBoutique = (equipo:any) => {
    setEquipo(equipo)
    setModalEquipoCriticoEditarBoutique(true)
  }
  const handleClose_ModalEquipoCriticoEditarBoutique = () => {
        cargar()
    setModalEquipoCriticoEditarBoutique(false)
  }


const [ uuid_usuario, setUUIDUsuario ] = useState<string>('')
const enviarFormulario = async (event:any) =>{
  if(uuid_usuario===''){
    toast.error('Debes de seleccionar un contrato')
    return
  }
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `boutique/agregar_tecnico_mantenimiento/${boutique.uuid}` , { uuid_usuario_mantenimiento : uuid_usuario })

        if(res_){
          toast.success('El Contrato se agrego correctamente')
          setTimeout(()=>{
            // cargar()
            cargarDetalleBoutique()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}







  const cargarDetalleBoutique = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`boutique/detalle/${boutique.uuid}`)
      setBoutique(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }




const eliminarResponsable = async (event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.delete( `boutique/tecnico_mantenimiento/${boutique.uuid}`)

        if(res_){
          toast.warning('Se elimino el response')
          setTimeout(()=>{
            cargarDetalleBoutique()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}




    


  return (
    <>
      <Modal size="xl" show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Equipos Críticos</Modal.Title>
        </Modal.Header>
        <Modal.Body>



          <Row>
            <Col md="6" sm="6">
                <p className="nm">Boutique</p>
                <h4>{boutique.nombre_boutique}</h4>
                <p className="nm">Localización</p>
                <h6>{boutique.idmunicipio.municipio}</h6>
            </Col>
            <Col md="6" sm="6">
              {
                // JSON.stringify(boutique.idusuario_mantenimiento)
              }

              {

                boutique.idusuario_mantenimiento && (
                    <>
                      <FichaEmpleado empleado={boutique.idusuario_mantenimiento} />
                      <button onClick={eliminarResponsable} className="btn btn-link btn-sm"><i className="fa fa-trash"/> Quitar Asignamiento</button>
                    </>
                )

              }


              {

                !boutique.idusuario_mantenimiento && (
                    <>
                      <p><i className="fa fa-warning"/> Debes seleccionar un Técnico de Mantenimiento para esta Boutique</p>

                  <Form.Group  className="mb-3" controlId="idequipo">
                    <Form.Label>Técnico de Mantenimiento</Form.Label>
                    <Form.Select value={uuid_usuario} onChange={(e:any)=>{setUUIDUsuario(e.target.value)}}>
                      <option>Selecciona un tipo de Usuario</option>
                      {
                        usuarios.map((usuario:any)=>
                          <option key={ Math.random().toString(36).substr(2, 9) } value={usuario.uuid}>{usuario.nombres} {usuario.paterno} {usuario.materno} </option>
                        )
                      }
                    </Form.Select>
                  </Form.Group>


                  <button type="button" onClick={enviarFormulario} className="btn btn-primary">Agregar</button>





                    </>
                )

              }
            </Col>
          </Row>


                    <ul className="list-inline" style={{marginBottom:0}}>
                      <li className="list-inline-item"><button type="button" onClick={handleShow_ModalEquipoCriticoAgregarBoutique} className="btn btn-link">Agregar Equipo para Mantenimiento</button></li>
                    </ul>



                    {
                      equipos_criticos.length === 0 && (
                          <React.Fragment>
                            
                          </React.Fragment>
                        )
                    }


                    {
                      equipos_criticos.length > 0 && (
                          <React.Fragment>


                                <table className="table">
                                  <thead style={{fontSize:10}}>
                                    <tr>
                                      <th scope="col">Activo</th>
                                      <th scope="col">Detalles del Equipo</th>
                                      <th scope="col">Descripción</th>
                                      <th scope="col">Marca / Modelo</th>
                                      <th scope="col">Último Mantenimiento</th>
                                      <th scope="col">Días para Mantenimiento</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                      {

                                        equipos_criticos.map((equipo:any,index_equipo:number)=>

                                            <tr key={index_equipo}>
                                              <td className="v" style={{width:50}}>
                                                <p className="nm text-center">{
                                                  equipo.activo?<i className="fa fa-check-circle"/>:''
                                                }</p>
                                              </td>
                                              <td className="v">
                                                <p className="nm"><b>{equipo.idequipo.idtipo_equipo.tipo_equipo}</b></p>
                                              </td>
                                              <td className="v"  style={{width:300}}>
                                                <p className="nm"><small>{equipo.detalles} </small></p>
                                              </td>
                                              <td className="v"  style={{width:200}}>
                                                <p className="nm"><b><small>{equipo.idequipo.marca}</small></b></p>
                                                <p className="nm"><small>{equipo.idequipo.modelo}</small></p>
                                              </td>
                                              <td className="v" style={{width:200}}><p className="nm"><small>{moment(equipo.fecha_ultimo_mantenimiento).utc().format('DD / MMMM / YYYY').toUpperCase()}</small></p></td>
                                              <td className={`v text-center ${ ( 0 > -1 ? 'table-danger-' : '') } `} style={{width:80}}>
                                                    <p className="nm">
                                                      { parseInt(equipo.dias_entre_mantenimiento) - calcularDiasPasados(new Date(equipo.fecha_ultimo_mantenimiento),new Date())}
                                                    </p>
                                                </td>
                                              <td className="v" style={{width:50}}>
                                                <button type="button" onClick={(e:any)=>{handleShow_ModalEquipoCriticoEditarBoutique(equipo)}} className="btn btn-sm nm btn-link"><i className="fa fa-edit"/></button>
                                              </td>
                                            </tr> 

                                        )


                                      }
                                  </tbody>
                                </table>


                          </React.Fragment>
                        )
                    }

          {
            // JSON.stringify(equipos_criticos)
          }



        </Modal.Body>

      </Modal>



  {
    show_ModalEquipoCriticoAgregarBoutique && (
      <ModalEquipoCriticoAgregarBoutique show={show_ModalEquipoCriticoAgregarBoutique} handleClose={handleClose_ModalEquipoCriticoAgregarBoutique} boutique={boutique} />
      )

  }


  {
    show_ModalEquipoCriticoEditarBoutique && (
      <ModalEquipoCriticoEditarBoutique show={show_ModalEquipoCriticoEditarBoutique} handleClose={handleClose_ModalEquipoCriticoEditarBoutique} boutique={boutique} equipo={equipo} />
      )

  }


    </>
  );
}


export default ModalEquiposCriticosBoutique;