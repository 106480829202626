import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import Dialog from '../../services/Dialogs'

import moment from 'moment'



import AgregarElementoRequisicion from './AgregarElementoRequisicion'
import EditarElementoRequisicion from './EditarElementoRequisicion'


import { sumarMontosTotales, tipoDeElementoRequisicion, formatearNumero, obtenerUnidadMedida } from '../../services/funcionesPublicas'



const ModalEditarRequisicion = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, requisicion, proveedor } = params


  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
          para_compras : yup.boolean(),
          descripcion : yup.string().required(mensajes_validacion_generico),
          fecha_limite : yup.string().required(mensajes_validacion_generico),
          idusuario_solicita : yup.string().nullable(),
  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

            if(requisiciones_listado.length == 0){
              toast.error('No puedes agregar una requisición sin ningún elemento')
              return 
            }

          setSeEnvio(true)
          let res_ = await RequestService.update( `requisicones/editar_requisicion/${requisicion.uuid_requisicion}` , { ...registro, fecha_limite : moment(registro.fecha_limite).format('YYYY-MM-DD'), requisiciones_listado : requisiciones_listado })

          if(res_){
            toast.warning('Los cambios en la requisición se guardaron correctamente')
            setTimeout(()=>{
              // handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }



  // const [ requisiciones_listado, setRequisicionListado ] = useState<any>([])
  const [ show_AgregarElementoRequisicion, setAgregarElementoRequisicion ] = useState<boolean>(false)
  const [ tipo_elemento_requisicion, setTipoElementoRequisicion ] = useState('')
  const handleShow_AgregarElementoRequisicion = (tipo_elemento_requisicion:string) => {
    setTipoElementoRequisicion(tipo_elemento_requisicion)
    setAgregarElementoRequisicion(true)
  }
  const handleClose_AgregarElementoRequisicion = () => {
    setAgregarElementoRequisicion(false)
  }


const eliminarRegistroPorIndice = (indice:number) => {
  if (indice >= 0 && indice < requisiciones_listado.length) {
    setRequisicionListado([...requisiciones_listado.slice(0, indice), ...requisiciones_listado.slice(indice + 1)]);

  } 
};


  const [ requisiciones_listado, setRequisicionListado ] = useState<any>([])
  const cargarRequisiciones_listado = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`requisicones/requisiciones_listado/${requisicion.uuid_requisicion}`)
      setRequisicionListado(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }




  const [ proveedores, setProveedores ] = useState<any>([])
  const cargarProveedores = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/proveedores`)
      setProveedores(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }




  React.useEffect(() => {
    cargarProveedores()
    cargarRequisiciones_listado()
    cargarTipo_unidad_medida()
    cargarLista_tecnicos()

  }, []);


  const [ tipo_unidad_medida, setTipo_unidad_medida ] = useState<any>([])
  const cargarTipo_unidad_medida = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/tipo_unidad_medida`)
      setTipo_unidad_medida(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }



  const [ show_EditarElementoRequisicion, setEditarElementoRequisicion ] = useState<boolean>(false)
  const [ indice_elemento_requisicion_seleccionado, setIndiceElementoRequisicionSeleccionado ] = useState<any>(null)
  const handleShow_EditarElementoRequisicion = (elemento:any,indice:number) => {
    setIndiceElementoRequisicionSeleccionado(indice)
    setEditarElementoRequisicion(true)
  }
  const handleClose_EditarElementoRequisicion = () => {
    setEditarElementoRequisicion(false)
  }


  const [ lista_quien_atiende, setLista_tecnicos ] = useState<any>({
      tecnicos : [],
      conservacion : [],
      limpieza : [],
      obra : [],
  })

  const cargarLista_tecnicos = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`zonas/lista_tecnicos`)
      setLista_tecnicos(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }

  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Editar la Requisición</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(requisicion.proveedor)
          }



    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={

                      proveedor ? 
                      {
                        para_compras : requisicion.para_compras,
                        descripcion : requisicion.descripcion,
                        fecha_limite : new Date( requisicion.fecha_limite ),
                        idproveedor : requisicion?.idproveedor?.uuid,
                        idusuario_solicita : requisicion.usuario_solicita ? requisicion.usuario_solicita.uuid : "",

                      }
                      : 
                      {
                        para_compras : requisicion.para_compras,
                        descripcion : requisicion.descripcion,
                        idusuario_solicita : requisicion.usuario_solicita ? requisicion.usuario_solicita.uuid : "",
                        fecha_limite : new Date( requisicion.fecha_limite )
                      }

      //   {

      //       descripcion : requisicion.descripcion,
      //       fecha_limite : new Date( requisicion.fecha_limite ),

      // }

    }
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>



        {
          // JSON.stringify(requisicion.idproveedor)
        }

        {
          // JSON.stringify(values.idproveedor)
        }


            <Form.Group className="mb-3" controlId="descripcion">
              <Form.Label>Descripción de la Requisición</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="descripcion"
                value={values.descripcion}
                onBlur={(e)=>{ setFieldValue('descripcion', e.target.value.toUpperCase() ) }}
                onChange={(e)=>{ setFieldValue('descripcion', e.target.value ) }}
                isInvalid={!!errors.descripcion}
                style={{textTransform:'uppercase'}}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.descripcion}</>} </Form.Control.Feedback>
            </Form.Group>


            <div className="row">
              <div className="col-sm-3">
                  <div className="mb-3">
                    <Form.Label>Fecha Límite</Form.Label>
                    <DatePicker selected={values.fecha_limite} className="form-control"  dateFormat="dd/MM/yyyy" minDate={new Date()} onChange={(date) => setFieldValue('fecha_limite', date ) } />
                  </div>

                  <div className="form-check mb-4">
                    <input className="form-check-input" type="checkbox" onChange={(e:any)=>{ setFieldValue('para_compras', e.target.checked ) }} checked={values.para_compras} id="para_compras" />
                    <label className="form-check-label" htmlFor="para_compras">
                      Esta requisición debe pasar por compras
                    </label>
                  </div>

              </div>
              {
                ["ROLE_RESPONSABLE_TECNICOS","AUXILIAR_OBRA"].indexOf(user.rol) > -1 && (
                    <div className="col-sm-5"> 


                         <Form.Group className="mb-3" controlId="idusuario_solicita">
                          <Form.Label>Selecciona a quien solicita la Requisición</Form.Label>
                          <Form.Select required name="idusuario_solicita" value={values.idusuario_solicita} onChange={(e)=>{ setFieldValue('idusuario_solicita', e.target.value ) }} isInvalid={!!errors.idusuario_solicita}>
                            <option value="">LA REQUISICIÓN ES MIA</option>

                                  <>

                                    {
                                      ["ROLE_RESPONSABLE_TECNICOS"].indexOf(user.rol) > -1 && (
                                        <>
                                            {
                                              lista_quien_atiende.conservacion.map((tecnico:any,tecnico_indice:number)=>
                                                  <option key={tecnico_indice} value={tecnico.uuid}>{tecnico.nombres} {tecnico.paterno} {tecnico.materno} </option>
                                                )
                                            }

                                            {
                                              lista_quien_atiende.tecnicos.map((tecnico:any,tecnico_indice:number)=>
                                                  <option key={tecnico_indice} value={tecnico.uuid}>{tecnico.nombres} {tecnico.paterno} {tecnico.materno} </option>
                                                )
                                            }
                                        </>
                                      )
                                    }



                                    {
                                      ["AUXILIAR_OBRA"].indexOf(user.rol) > -1 && (
                                        <>
                                          {
                                            lista_quien_atiende.obra.map((tecnico:any,tecnico_indice:number)=>
                                                <option key={tecnico_indice} value={tecnico.uuid}>{tecnico.nombres} {tecnico.paterno} {tecnico.materno} </option>
                                              )
                                          }
                                        </>
                                      )
                                    }


                                  </>


                          </Form.Select>
                          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid"> {<>{errors.idusuario_solicita}</>} </Form.Control.Feedback>
                        </Form.Group>


                    </div>
                  )
              }
              <div className="col-sm-4">
                    {
                      proveedor && (

                            <Form.Group className="mb-3" controlId="idproveedor">
                              <Form.Label>Selecciona el Proveedor</Form.Label>
                              <Form.Select required name="idproveedor" value={values.idproveedor} onChange={(e)=>{ setFieldValue('idproveedor', e.target.value ) }} isInvalid={!!errors.idproveedor}>
                                <option value="">SELECCIONA EL PROVEEDOR</option>
                                {
                                  proveedores.map( (proveedor:any,proveedor_indice:number) =>
                                    <React.Fragment key={proveedor_indice}>
                                              <option value={proveedor.uuid}> {proveedor.proveedor}</option>
                                    </React.Fragment>
                                  )
                                }

                              </Form.Select>
                              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid">{<>{errors.idproveedor}</>}</Form.Control.Feedback>
                            </Form.Group>

                        )
                    }
              </div>
            </div>




            { !proveedor && ( <button type="button" onClick={(e:any)=>{handleShow_AgregarElementoRequisicion('pago')}} className="btn btn-sm btn-link">Agregar Servicio</button> ) }
            { true && ( <button type="button" onClick={(e:any)=>{handleShow_AgregarElementoRequisicion('compra')}} className="btn btn-sm btn-link">Agregar Compra</button> ) }
            { !proveedor && ( <button type="button" onClick={(e:any)=>{handleShow_AgregarElementoRequisicion('viaticos')}} className="btn btn-sm btn-link">Agregar Víaticos</button> ) }
            { !proveedor && ( <button type="button" onClick={(e:any)=>{handleShow_AgregarElementoRequisicion('reembolso')}} className="btn btn-sm btn-link">Agregar Reembolso</button> ) }





            {
              requisiciones_listado.length === 0 && (
                  <div className="alert alert-danger mt-3">
                    <i className="fa fa-warning" /> Debes agregar por lo menos un elemento a la solicitud
                  </div>
                )
            }
            {
              requisiciones_listado.length > 0 && (
                  <div className="mt-3">

                      {
                        // JSON.stringify(requisiciones_listado)
                      }

                        <table className="table">
                          <thead>
                            <tr>
                              {
                                !proveedor && (<th scope="col">Factura</th>)
                              }
                              
                              <th scope="col">Tipo</th>
                              <th scope="col">Concepto</th>
                              <th scope="col">Cantidad</th>
                              <th scope="col">Unidad Medida</th>
                              <th scope="col">Monto</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              requisiciones_listado.map(( elemento:any, elemento_indice:number )=>
                                          <tr key={elemento_indice}>
                                              { 
                                                  !proveedor && (
                                                      <td className="v" style={{"width":"20px",textAlign:'center'}}>
                                                        { elemento.con_factura && <i className="fa fa-warning" />}
                                                      </td>
                                                  ) 
                                              }
                                            <td className="v" style={{"width":"160px"}}>
                                              <b>{tipoDeElementoRequisicion(elemento.tipo)}</b>
                                            </td>
                                            <td className="v">
                                              {elemento.concepto}
                                              {
                                                // JSON.stringify(elemento)
                                              }
                                            </td>

                                            { 
                                              ["pago","viaticos","reembolso"].indexOf(elemento.tipo) > -1 && (
                                                <>
                                                  <td className="v text-center" style={{"width":"80px"}}><small>N/A</small></td>
                                                  <td className="v text-center" style={{"width":"180px"}}><small>N/A</small></td>
                                                </>
                                              ) 
                                            }

                                            { 
                                              ["compra"].indexOf(elemento.tipo) > -1 && (
                                                <>
                                                  <td className="v text-center" style={{"width":"80px"}}>{elemento.cantidad}</td>
                                                  <td className="v text-center" style={{"width":"180px"}}>
                                                    {
                                                      // JSON.stringify(elemento)
                                                    }
                                                    {
                                                      obtenerUnidadMedida(tipo_unidad_medida,parseInt(elemento.idunidad_medida))
                                                    }
                                                  </td>
                                                </>
                                              ) 
                                            }
                                            
                                                  <td className="v" style={{"width":"100px"}}>$ {formatearNumero((elemento.monto_total*1))}</td>
                                            
                                            <td className="v" style={{"width":"40px"}}><button type="button" onClick={(e:any)=>{handleShow_EditarElementoRequisicion(elemento,elemento_indice)}} className="btn btn-sm btn-warning"><i className="fa fa-edit"/></button></td>
                                            <td className="v" style={{"width":"40px"}}><button type="button" onClick={(e:any)=>{eliminarRegistroPorIndice(elemento_indice)}} className="btn btn-sm btn-danger"><i className="fa fa-trash"/></button></td>
                                          </tr>
                                )
                            }

                            <tr>

                              {
                                !proveedor && (<td></td>)
                              }
                              <td></td>
                              <td></td>
                              <td></td>
                              <td className="v text-right"><b>Total</b></td>
                              <td>
                                $ {formatearNumero(sumarMontosTotales(requisiciones_listado))}
                              </td>


                              <td></td>
                              <td></td>


                            </tr>

                          </tbody>
                        </table>

                  </div>
                )
            }





        </Form>
      )}
    </Formik>



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>


      {
        show_AgregarElementoRequisicion && (
          <AgregarElementoRequisicion 
                                  show={show_AgregarElementoRequisicion} 
                                  handleClose={handleClose_AgregarElementoRequisicion} 
                                  tipo_elemento_requisicion={tipo_elemento_requisicion} 
                                  requisiciones_listado={requisiciones_listado} 
                                  setRequisicionListado={setRequisicionListado} 
                                  proveedor={proveedor}
              />
          )

      }



  {
    show_EditarElementoRequisicion && (
      <EditarElementoRequisicion show={show_EditarElementoRequisicion} handleClose={handleClose_EditarElementoRequisicion} 
                                requisiciones_listado={requisiciones_listado} 
                                setRequisicionListado={setRequisicionListado} 
                                indice_elemento_requisicion_seleccionado={indice_elemento_requisicion_seleccionado}
                                proveedor={proveedor} 

       />
      )

  }



    </>
  );
}


export default ModalEditarRequisicion;