import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { sumarMontosTotales, sumarMontosTotalesNoAprobados,  sumarMontosTotalesAprobados, tipoDeElementoRequisicion, formatearNumero, formatearFecha, formatearFechaHora } from '../../services/funcionesPublicas'

import { rol } from '../../services/rol'

import Dialog from '../../services/Dialogs'


import ModalEditarElemento from './ModalEditarElemento'



import { SERVER_ } from '../../config'

const ModalGestionSolicitudRequisicion = ( params : any ) => {



  const dialog = new Dialog();

  const user = AuthService.getCurrentUser()

  const { show, handleClose, requisicion } = params

  const [ registro_requisicion, setRegistro_requisicion ] = useState<any>({ requisiciones_listado : [] })
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)

  const cargarRegistro_requisicion = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`requisicion/detalles/${requisicion.uuid_requisicion}`)
      setRegistro_requisicion(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarRegistro_requisicion()
  }, []);




const cerrarRequisicion = async (event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: `<small>La requisición se cerrará y los elementos <br/> pasarán a marcarse como cancelados</small>`
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `requisiciones/cerrar/${requisicion.uuid_requisicion}` , { })

        if(res_){
          toast.warning('Le Requisción fue marcada como cancelada correctamente')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          if (e.response?.status) {
            switch (e.response.status) {
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }
     
}


const noAceptarElemento = async (event:any,elemento:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: `
          <label>
              Comentarios sobre la desición: <br/>
                  <textarea class="form-control" name="comentarios" value=""></textarea>
          </label>
        `
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{
          // console.log(dialogo)
          // return 
        setSeEnvio(true)
        let res_ = await RequestService.update( `requisicion/elemento/no_aceptar/${elemento.uuid}` , { comentarios : dialogo.comentarios })

        if(res_){
          toast.warning('El elemento fue cambiado de estado correctamente')
          setTimeout(()=>{
            cargarRegistro_requisicion()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          if (e.response?.status) {
            switch (e.response.status) {
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }
     
}

const AceptarElemento = async (event:any,elemento:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `requisicion/elemento/aceptar/${elemento.uuid}` , { })

        if(res_){
          toast.success('El elemento fue cambiado de estado correctamente')
          setTimeout(()=>{
            cargarRegistro_requisicion()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          if (e.response?.status) {
            switch (e.response.status) {
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }
     
}





const requisicionATransferencia = async (event:any) =>{

  let mensaje = ""

    mensaje = !requisicion.para_compras ? 
        `
          <p class="nm"> 💰 La Requisición pasará a transferencias</p>
        `
        :
        `
          <p class="nm"> 🛍️ La Requisición pasará a compras</p>
        `

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: mensaje

      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `requisiciones/transferencia/${requisicion.uuid_requisicion}` , { })

        if(res_){
          toast.success('Le Requisción se envió a transferencias')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          if (e.response?.status) {
            switch (e.response.status) {
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }
     
}


const aprobarRequisicion = async (event:any) =>{

  let mensaje = ""

    mensaje = `Esta requisción pasará a ser Atendida`
 

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: mensaje

      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `requisiciones/requisicion_aprobada/${requisicion.uuid_requisicion}` , { })

        if(res_){
          toast.success('Le Requisción se envió a transferencias')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          if (e.response?.status) {
            switch (e.response.status) {
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }
     
}


const todosElementosSeteados = (elementos: any) => {
    return elementos.every((elemento:any) => elemento.idestatus_elemento !== null);
};



const aprobarTodosElementos = async (event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `requisiciones/elementos/aceptar_todos/${requisicion.uuid_requisicion}` , { })

        if(res_){
          toast.warning('Todos los elementos fueron marcados de aceptados ✅')
          setTimeout(()=>{
            cargarRegistro_requisicion()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          if (e.response?.status) {
            switch (e.response.status) {
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }
     
}

const rechazarTodosElementos = async (event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `requisiciones/elementos/cancelar_todos/${requisicion.uuid_requisicion}` , { })

        if(res_){
          toast.warning('Todos los elementos fueron marcados de rechazados ❌')
          setTimeout(()=>{
            cargarRegistro_requisicion()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          if (e.response?.status) {
            switch (e.response.status) {
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }
     
}


  const [ elemento_seleccionado, setElementoSeleccionado ] = useState<any>(null)
  const [ show_ModalEditarElemento, setModalEditarElemento ] = useState<boolean>(false)
  const handleShow_ModalEditarElemento = (elemento:any) => {
    setElementoSeleccionado(elemento)
    setModalEditarElemento(true)
  }
  const handleClose_ModalEditarElemento = () => {
            cargarRegistro_requisicion()
    setModalEditarElemento(false)
  }


  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Gestión de la Solicitud de Requisición</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="row">
            <div className="col-3">
              
              <h6>Folio</h6>
              <small>{requisicion.folio}</small>

              <h6 className="mt-3">Requisitó</h6>
              <p className="nm"><small><b>{requisicion.nombres}</b> {requisicion.paterno} {requisicion.materno}</small></p>
              <p className="nm"><small style={{fontSize:10}}>{rol(requisicion.rol)}</small></p>

              <h6 className="mt-3">Fecha Límite</h6>
              <small>{formatearFecha(requisicion.fecha_limite)}</small>

              <h6 className="mt-3">Fecha de Creación</h6>
              <small>{formatearFechaHora(requisicion.fecha_creacion)}</small>



            <div className="row">
              <div className={`col-6- estatus_requisicion_${requisicion?.idestatus_requisicion?.id}`}>
                {
                  // JSON.stringify(requisicion.estatus)
                }
                      <h6 className="">Estatus</h6>
                      <small>{requisicion.estatus}</small>

              </div>
            </div>



              {
                requisicion.compra_proveedor && (
                    <div className="mt-2">
                      <h6 className="">Proveedor</h6>
                      {
                        // JSON.stringify(registro_requisicion.idproveedor)
                      }
                      <small>{registro_requisicion?.idproveedor?.proveedor} ({registro_requisicion?.idproveedor?.razon_social})</small>
                    </div>
                  )
              }

            </div>
            <div className="col-9">



            {
              requisicion.para_compras && (
                  <div className="pasara_por_compras">
                    Esta requisición será atendida por el Departamento de Compras
                  </div>
                )
            }



              <h6>Detalles de la Requisición</h6>
              <small>{requisicion.descripcion}</small>

              {
                // JSON.stringify(registro_requisicion.requisiciones_listado)
              }


                        <table className="table table-sm mt-2" style={{fontSize:12}}>
                        <caption>En espera de Aprobación ⌛ | ✅ Aprobada | ❌ No Aprobada</caption>
                          <thead>
                            <tr>
                              {
                                !registro_requisicion?.compra_proveedor && (<th scope="col">Factura</th>)
                              }
                              <th scope="col">Tipo</th>
                              <th scope="col">Estado</th>
                              <th scope="col">Concepto</th>
                              <th scope="col">Cantidad</th>
                              <th scope="col">Unidad Medida</th>
                              <th scope="col">Monto</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              registro_requisicion.requisiciones_listado.map(( elemento:any, elemento_indice:number )=>
                                          <tr className={`v ${ [2].indexOf(elemento?.idestatus_elemento?.id) > -1 ? 'disabled-row' : '' }`} key={elemento_indice}>
                                              { 
                                                  !registro_requisicion?.compra_proveedor && (
                                                      <td className="v" style={{"width":"20px",textAlign:'center'}}>
                                                        { elemento.con_factura && <i className="fa fa-warning" />}
                                                      </td>
                                                  ) 
                                              }
                                            <td className="v" style={{"width":"80px"}}>
                                              <small><b>{tipoDeElementoRequisicion(elemento.tipo)}</b></small>
                                            </td>
                                            <td className="v text-center" style={{"width":"50px"}}>
                                                { !elemento?.idestatus_elemento && (<>⌛</>) }
                                                { [1].indexOf(elemento?.idestatus_elemento?.id) > -1 && (<>✅</>) }
                                                { [2].indexOf(elemento?.idestatus_elemento?.id) > -1 && (<>❌</>) }
                                            </td>
                                            <td className="v">
                                              <p className="nm"><small>{
                                                elemento?.concepto
                                              } 

                                                        {
                                                          elemento?.documento_evidencia !== null && (
                                                                          <>
                                                                             <a onClick={()=>{ window.open(`${SERVER_}comprobaciones/evidencia/archivo/${elemento?.uuid}`) }} className="btn btn-sm btn-link nm"><i className="fa fa-file"/></a>
                                                                          </>
                                                                      )
                                                        }

                                              </small></p>

                                              {
                                                // JSON.stringify(elemento?.idestatus_elemento)
                                              }
                                              </td>

                                            { 
                                              ["pago","viaticos","reembolso"].indexOf(elemento.tipo) > -1 && (
                                                <>
                                                  <td className="v" style={{"width":"80px"}}><small>N/A</small></td>
                                                  <td className="v" style={{"width":"150px"}}><small>N/A</small></td>
                                                </>
                                              ) 
                                            }

                                            { 
                                              ["compra"].indexOf(elemento.tipo) > -1 && (
                                                <>
                                                  <td className="v" style={{"width":"80px"}}>{elemento.cantidad}</td>
                                                  <td className="v" style={{"width":"150px"}}>
                                                    {
                                                      // obtenerUnidadMedida(tipo_unidad_medida,parseInt(elemento.idunidad_medida))
                                                    }
                                                    {
                                                      elemento?.idunidad_medida?.unidad_medida
                                                    }
                                                  </td>
                                                </>
                                              ) 
                                            }
                                            
                                            <td style={{"width":"80px"}}>$ {formatearNumero((elemento.monto_total*1))}</td>


                                            <td style={{"width":"20px"}}>

                                              {
                                                // JSON.stringify(user.rol)
                                              }

                                              {
                                                (["ROLE_RESPONSABLE_OPERACION"].indexOf(user.rol) > -1 && [2].indexOf(requisicion.idestatus_requisicion) > -1) && (
                                                    <>

                                                        {
                                                          (elemento.idestatus_elemento === null || [1,2].indexOf(elemento?.idestatus_elemento?.id) > -1 ) && (
                                                              <button type="button" onClick={(e:any)=>{noAceptarElemento(e,elemento)}} className="btn btn-xs btn-danger"><i className="fa fa-thumbs-down"/></button>
                                                            )
                                                        }

                                                    </>
                                                  )
                                              }

                                              {
                                                (["ROLE_JEFE_ADMIN","ROLE_ADMIN"].indexOf(user.rol) > -1 && [2,3].indexOf(requisicion.idestatus_requisicion) > -1) && (
                                                    <>

                                                        {
                                                          (elemento.idestatus_elemento === null || [1,2].indexOf(elemento?.idestatus_elemento?.id) > -1 ) && (
                                                              <button type="button" onClick={(e:any)=>{noAceptarElemento(e,elemento)}} className="btn btn-xs btn-danger"><i className="fa fa-thumbs-down"/></button>
                                                            )
                                                        }

                                                    </>
                                                  )
                                              }




                                            </td>
                                            <td style={{"width":"20px"}}>

                                              {
                                                (["ROLE_RESPONSABLE_OPERACION"].indexOf(user.rol) > -1 && [2].indexOf(requisicion.idestatus_requisicion) > -1) && (
                                                    <>

                                                      {
                                                        (elemento.idestatus_elemento === null || [1,2].indexOf(elemento?.idestatus_elemento?.id) > -1) && (
                                                            <button type="button" onClick={(e:any)=>{AceptarElemento(e,elemento)}} className="btn btn-xs btn-success"><i className="fa fa-thumbs-up"/></button>
                                                          )
                                                      }

                                                    </>
                                                  )
                                              }


                                              {
                                                (["ROLE_JEFE_ADMIN","ROLE_ADMIN"].indexOf(user.rol) > -1 && [2,3].indexOf(requisicion.idestatus_requisicion) > -1) && (
                                                    <>

                                                      {
                                                        (elemento.idestatus_elemento === null || [1,2].indexOf(elemento?.idestatus_elemento?.id) > -1) && (
                                                            <button type="button" onClick={(e:any)=>{AceptarElemento(e,elemento)}} className="btn btn-xs btn-success"><i className="fa fa-thumbs-up"/></button>
                                                          )
                                                      }

                                                    </>
                                                  )
                                              }

                                            </td>

                                            <td style={{"width":"20px"}}>

                                              {
                                                (["ROLE_RESPONSABLE_OPERACION"].indexOf(user.rol) > -1 && [2].indexOf(requisicion.idestatus_requisicion) > -1) && (
                                                    <>

                                                      {
                                                        (elemento.idestatus_elemento === null) && (
                                                            <button type="button" onClick={(e:any)=>{handleShow_ModalEditarElemento(elemento)}} className="btn btn-xs btn-warning"><i className="fa fa-edit"/></button>
                                                          )
                                                      }

                                                    </>
                                                  )
                                              }


                                              {
                                                (["ROLE_JEFE_ADMIN","ROLE_ADMIN"].indexOf(user.rol) > -1 && [2,3].indexOf(requisicion.idestatus_requisicion) > -1) && (
                                                    <>

                                                      {
                                                        (elemento.idestatus_elemento === null) && (
                                                            <button type="button" onClick={(e:any)=>{handleShow_ModalEditarElemento(elemento)}} className="btn btn-xs btn-warning"><i className="fa fa-edit"/></button>
                                                          )
                                                      }

                                                    </>
                                                  )
                                              }


                                            </td>
{/*                                            <td style={{"width":"20px"}}>
                                              {
                                                (elemento.idestatus_elemento === null || [1,2].indexOf(elemento?.idestatus_elemento?.id) > -1) && (
                                                    <button type="button" onClick={(e:any)=>{AceptarElemento(e,elemento)}} className="btn btn-xs btn-mantenimiento"><i className="fa fa-upload"/></button>
                                                  )
                                              }
                                            </td>*/}
                                            
                                          </tr>
                                )
                            }

                            <tr>
                              <td colSpan={ !registro_requisicion.compra_proveedor ? 6 : 5} className="text-right"> <b>TOTAL</b> </td>
                              <td>
                                $ {formatearNumero(sumarMontosTotales(registro_requisicion.requisiciones_listado))}
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td colSpan={ !registro_requisicion.compra_proveedor ? 6 : 5} className="text-right"> <b>TOTAL APROBADO ✅</b> </td>
                              <td>
                                $ {formatearNumero(sumarMontosTotalesAprobados(registro_requisicion.requisiciones_listado))}
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td colSpan={ !registro_requisicion.compra_proveedor ? 6 : 5} className="text-right"> <b>TOTAL RECHAZADO ❌</b> </td>
                              <td>
                                $ {formatearNumero(sumarMontosTotalesNoAprobados(registro_requisicion.requisiciones_listado))}
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>

                          </tbody>
                        </table>






                                              {
                                                (["ROLE_RESPONSABLE_OPERACION"].indexOf(user.rol) > -1 && [2].indexOf(requisicion.idestatus_requisicion) > -1) && (
                                                    <>
                                                      <ul className="list-inline">
                                                        <li onClick={aprobarTodosElementos} className="list-inline-item"><button className="btn btn-sm btn-link">Aprobar Todas ✅ </button></li>
                                                        <li onClick={rechazarTodosElementos} className="list-inline-item"><button className="btn btn-sm btn-link">Rechazar Todas ❌ </button></li>
                                                      </ul>
                                                    </>
                                                  )
                                              }


                                              {
                                                (["ROLE_JEFE_ADMIN","ROLE_ADMIN"].indexOf(user.rol) > -1 && [2,3].indexOf(requisicion.idestatus_requisicion) > -1) && (
                                                    <>
                                                      <ul className="list-inline">
                                                        <li onClick={aprobarTodosElementos} className="list-inline-item"><button className="btn btn-sm btn-link">Aprobar Todas ✅ </button></li>
                                                        <li onClick={rechazarTodosElementos} className="list-inline-item"><button className="btn btn-sm btn-link">Rechazar Todas ❌ </button></li>
                                                      </ul>
                                                    </>
                                                  )
                                              }

                        
            </div>
          </div>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={cerrarRequisicion}> Cerrar la requisición </Button>

          {
            // JSON.stringify(registro_requisicion?.idestatus_requisicion?.id)
          }
              {
                (["ROLE_RESPONSABLE_OPERACION","RESIDENTE_OBRA","ROLE_JEFE_ADMIN","ROLE_ADMIN"].indexOf(user.rol) > -1 && [2].indexOf(registro_requisicion?.idestatus_requisicion?.id) > -1) && (<Button variant="success" disabled={!todosElementosSeteados(registro_requisicion.requisiciones_listado)} onClick={aprobarRequisicion}> APROBAR REQUISICIÓN <i className="fa fa-save"/></Button>)
              }

              {
                (["ROLE_JEFE_ADMIN","ROLE_ADMIN"].indexOf(user.rol) > -1 && [3].indexOf(registro_requisicion?.idestatus_requisicion?.id) > -1)
                && (<Button variant="success" disabled={!todosElementosSeteados(registro_requisicion.requisiciones_listado)} onClick={requisicionATransferencia}>PASAR A { !registro_requisicion.para_compras ? 'TRANSFERENCIA' : 'COMPRAS'} <i className="fa fa-save"/></Button>)
              }

          
        </Modal.Footer>

      </Modal>






  {
    show_ModalEditarElemento && (
      <ModalEditarElemento show={show_ModalEditarElemento} handleClose={handleClose_ModalEditarElemento} elemento={elemento_seleccionado} />
      )

  }












    </>
  );
}


export default ModalGestionSolicitudRequisicion;