import React, { useState } from "react";
import RequestService from "../../services/request.service";


import { Button, Modal, Table, Form, Row, Col } from 'react-bootstrap'


import Pagination from "../../services/Pagination";


import { toast } from 'react-toastify'

import AuthService from "../../features/auth/auth.service";


import { Formik } from 'formik';
import * as yup from 'yup';



import moment from 'moment'


import Dialog from '../../services/Dialogs'




import ModalResetPasswordUsuario from './ModalResetPasswordUsuario'


import ModalCambiarUsername from './ModalCambiarUsername'





import ModalCambiarFoto from '../ModalCambiarFoto'

import ModalNuevoUsuario from './ModalNuevoUsuario'

import ModalEditarUsuario from './ModalEditarUsuario'


import ModalGafete from './ModalGafete'



import ModalAnexos from './ModalAnexos'



import ModalRegistroMovimientosUsuario from './ModalRegistroMovimientosUsuario'




import { FormateaNumero } from '../../services/funcionesPublicas'
import { rol } from '../../services/rol'

import '../../rol_classes.scss'


import { SERVER_ } from  '../../config';



const RecursosHumanos = () =>{

    const dialog = new Dialog();

    const [allSessionsCountEmpleado,setCounterEmpleado] = useState(0);
    const sessionsPerPageEmpleado = 30
    const [currentPageEmpleado, setCurrentPageEmpleado] = useState(1);

    const [ paginaEmpleado, setPaginaEmpleado ] = useState(0)
    const [ text_busqueda_empleado, setTextoBusquedaEmpleado ] = useState('')


    const [ cargando_, setCargando ] = useState( true )


    const [ reporte, setReporteEmpleado ] = useState<any>([])


    const currentUser  = AuthService.getCurrentUser();

    const cargarMiReporteEmpleado = async(page:number)=>{
      try{
        setCargando(true)
        let skip = 0
        if(page===0) skip = 0; else skip = (page*sessionsPerPageEmpleado);
        let response : any = await RequestService.getEndPoint('recursos_humanos/'+arreglo_roles.join(',')+'/'+empleados_foraneos+'/lista_personal?where='+text_busqueda_empleado+'&skip='+skip+'&limit='+sessionsPerPageEmpleado)
        setCounterEmpleado(response.data.totalCount)

        setReporteEmpleado(response.data.results)
        setCargando(false)
      }
      catch(e){
        setCargando(false)
        toast.error('Problemas al intentar generar tu reporte. Contacta con los administradores del sistema')
      }
    }


    const busquedaEmpleados = async () =>{
      // setPagina(0)
      cargarMiReporteEmpleado(0)
      setCurrentPageEmpleado(0)
    }

    React.useEffect(() => {
      cargarMiReporteEmpleado(0)
    }, []);



 


  const [ arreglo_roles, setArregloRoles ] = useState<any>([true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true])

  const todos = () =>{
    setArregloRoles([true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true])
  }
  const ninguno = () =>{
    setArregloRoles([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])
  }


  const [ show_ModalResetPasswordUsuario, setModalResetPasswordUsuario ] = useState<boolean>(false)
  const handleShow_ModalResetPasswordUsuario = (usuario:any) => {
     setUsuarioSeleccionado(usuario)
    setModalResetPasswordUsuario(true)
  }
  const handleClose_ModalResetPasswordUsuario = () => {
    setModalResetPasswordUsuario(false)
  }






  const [ show_ModalCambiarUsername, setModalCambiarUsername ] = useState<boolean>(false)
  const handleShow_ModalCambiarUsername = (usuario:any) => {
     setUsuarioSeleccionado(usuario)
    setModalCambiarUsername(true)
  }
  const handleClose_ModalCambiarUsername = () => {
    cargarMiReporteEmpleado(currentPageEmpleado===0?0:currentPageEmpleado-1)
    setModalCambiarUsername(false)
  }


  const [ show_ModalNuevoUsuario, setModalNuevoUsuario ] = useState<boolean>(false)
  const handleShow_ModalNuevoUsuario = () => {
    setModalNuevoUsuario(true)
  }
  const handleClose_ModalNuevoUsuario = () => {
    setModalNuevoUsuario(false)
  }



  const [ usuario_seleccionado, setUsuarioSeleccionado ] = useState<any>({})
  const [ showModalAnexos, setShowModalAnexos ] = useState<any>(false)

  const onHideModalAnexos = () => {
    setShowModalAnexos(false)
  }

  const documentosAnexos = (usuario:string)=>{
      setUsuarioSeleccionado(usuario)
      setShowModalAnexos(true)
  }






  const [ show_ModalCambiarFoto, setModalCambiarFoto ] = useState<boolean>(false)
  const handleShow_ModalCambiarFoto = (usuario:any) => {
      setUsuarioSeleccionado(usuario)
    setModalCambiarFoto(true)
  }
  const handleClose_ModalCambiarFoto = () => {
    cargarMiReporteEmpleado(currentPageEmpleado===0?0:currentPageEmpleado-1)
    setModalCambiarFoto(false)
  }


  const [ show_ModalEditarUsuario, setModalEditarUsuario ] = useState<boolean>(false)
  const handleShow_ModalEditarUsuario = (usuario:any) => {
      setUsuarioSeleccionado(usuario)
    setModalEditarUsuario(true)
  }
  const handleClose_ModalEditarUsuario = () => {
    cargarMiReporteEmpleado(currentPageEmpleado===0?0:currentPageEmpleado-1)
    setModalEditarUsuario(false)
  }



  const [ show_ModalGafete, setModalGafete ] = useState<boolean>(false)
  const handleShow_ModalGafete = (usuario:any) => {
      setUsuarioSeleccionado(usuario)
    setModalGafete(true)
  }
  const handleClose_ModalGafete = () => {
    setModalGafete(false)
  }





  const activarUsuario = async ( uuid_usuario:string, index:number, estatus:boolean ) =>{

    let REPORTE_ORIGINAL = [...reporte]

    try{

      let res = await RequestService.update('usuarios/actualizar_estatus_acceso/'+uuid_usuario, { estatus : !estatus })
      if(res.data){
        REPORTE_ORIGINAL[index].activo = !estatus  
        setReporteEmpleado(REPORTE_ORIGINAL)    
        toast.warning('Cambiamos el estatus de este usuario')
      }
      else{
        REPORTE_ORIGINAL[index].activo = estatus  
        setReporteEmpleado(REPORTE_ORIGINAL)    
      }

    }
    catch(e){
      REPORTE_ORIGINAL[index].activo = estatus  
      setReporteEmpleado(REPORTE_ORIGINAL)
      toast.error('Tuvimos problemas al actualizar el estatus de acceso del usuario')
    }

    

  }



  const [ show_ModalRegistroMovimientosUsuario, setModalRegistroMovimientosUsuario ] = useState<boolean>(false)
  const handleShow_ModalRegistroMovimientosUsuario = (usuario:any) => {
      setUsuarioSeleccionado(usuario)
    setModalRegistroMovimientosUsuario(true)
  }
  const handleClose_ModalRegistroMovimientosUsuario = () => {
    cargarMiReporteEmpleado(currentPageEmpleado===0?0:currentPageEmpleado-1)
    setModalRegistroMovimientosUsuario(false)
  }

  const [ empleados_foraneos, setEmpleadosForaneos ] = useState<boolean>(false)


      return (
             
          <>
              <div className="container-fluid">
                  
                  <h2>Listado de Personal</h2>
                  <hr />


 
                          <div className="">
                            <label className="form-label">Búsqueda</label>
                            <div className="input-group mb-3">
                              <input type="text" className="form-control" onKeyDown={(e)=>{if(e.keyCode === 13){ busquedaEmpleados() }}} placeholder="" value={text_busqueda_empleado} onChange={event => setTextoBusquedaEmpleado(event.target.value)} />
                              <button onClick={busquedaEmpleados} className="btn btn-default" type="button" id="button-addon2"><i className="fa fa-search" /></button>
                            </div>
                          </div>



                          <div key={`inline-checkbox`} className="mb-1">

                          {
                            // JSON.stringify(arreglo_estatus)
                          }

                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('ROLE_ADMIN')}`} checked={arreglo_roles[0]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[0] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_1`} />
                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('ROLE_RESPONSABLE_TECNICOS')}`} checked={arreglo_roles[1]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[1] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_2`} />
                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('ROLE_RESPONSABLE_OPERACION')}`} checked={arreglo_roles[2]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[2] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_3`} />
                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('ROLE_TECNICO')}`} checked={arreglo_roles[3]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[3] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_4`} />
                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('ROLE_LIMPIEZA')}`} checked={arreglo_roles[4]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[4] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_5`} />
                            {
                              // <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('ROLE_FINANZAS')}`} checked={arreglo_roles[5]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[5] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_6`} />
                            }
                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('ROLE_RECURSOS_HUMANOS')}`} checked={arreglo_roles[6]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[6] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_7`} />
                            {
                              <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('ROLE_OBRA')}`} checked={arreglo_roles[7]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[7] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_8`} />
                            }
                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('ROLE_SISTEMAS')}`} checked={arreglo_roles[8]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[8] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_9`} />
                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('ROLE_RESPONSABLE_LIMPIEZA')}`} checked={arreglo_roles[9]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[9] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_10`} />
                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('ROLE_RESPONSABLE_PRESUPUESTO')}`} checked={arreglo_roles[10]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[10] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_11`} />
                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('ROLE_RECURSOS_MATERIALES')}`} checked={arreglo_roles[11]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[11] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_12`} />
                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('ROLE_SHEI')}`} checked={arreglo_roles[12]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[12] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_13`} />
                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('ROLE_ATENCION_CLIENTES')}`} checked={arreglo_roles[13]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[13] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_14`} />

                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('ROLE_ALMACEN')}`} checked={arreglo_roles[14]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[14] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_15`} />


                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('ROLE_MARKETING')}`} checked={arreglo_roles[15]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[15] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_16`} />
                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('ROLE_RECLUTAMIENTO')}`} checked={arreglo_roles[16]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[16] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_17`} />
                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('ROLE_JEFE_ADMIN')}`} checked={arreglo_roles[17]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[17] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_18`} />

                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('ROLE_CONTABILIDAD')}`} checked={arreglo_roles[18]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[18] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_19`} />

                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('RESIDENTE_OBRA')}`} checked={arreglo_roles[19]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[19] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_20`} />
                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('AUXILIAR_OBRA')}`} checked={arreglo_roles[20]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[20] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_21`} />
                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('ELECTRICO')}`} checked={arreglo_roles[21]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[21] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_22`} />
                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('AYUDANTE_ELECTRICO')}`} checked={arreglo_roles[22]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[22] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_23`} />
                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('TABLAROQUERO')}`} checked={arreglo_roles[23]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[23] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_24`} />

                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('ROLE_COMPRAS')}`} checked={arreglo_roles[24]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[24] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_25`} />
                            <Form.Check inline name="group_usuarios" className="chiquitita" type="checkbox" label={`${rol('ROLE_COMPROBACIONES')}`} checked={arreglo_roles[25]} onChange={(e)=>{ var ne = [...arreglo_roles]; ne[25] = e.target.checked;  setArregloRoles(ne);}} id={`opciones_26`} />

                          </div>


                          <ul className="list-inline">
                            <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                            <li className="list-inline-item"><small className="pointer" onClick={handleShow_ModalNuevoUsuario}>Nuevo Usuario</small></li>
                            <li className="list-inline-item">
                              <Form.Check  type={'checkbox'} className="chiquitita" label={`SOLO EMPLEADOS FORANEOS`} id={`disabled-default`} checked={empleados_foraneos} onChange={(e:any)=>{setEmpleadosForaneos(e.target.checked)}} />
                            </li>
                          </ul>

                                            {
                                              // JSON.stringify(arreglo_roles)
                                            }


                          {  (!cargando_) && (

                              <>



                                      



                                     {
                                       reporte.length > 0 && (

                                         <>


                                         <h5>Usuarios del Sistema</h5>


                                          <Table responsive="lg">
                                            <thead>
                                              <tr>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                                <th scope="col">CURP</th>
                                                <th scope="col">Ingreso</th>
                                                <th scope="col" colSpan={1}>Nombre </th>
                                                <th scope="col" colSpan={1}>Zona </th>
                                                <th scope="col">Usuario</th>
                                                <th scope="col">Email / Celular</th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {
                                                reporte.map( (usuario:any,indice_usuarios:number) =>
                                                    <tr key={ indice_usuarios } style={{fontSize:12}} className={ !usuario.activo ? 'table-warning' : '' }>
                                                      <td className={ 'v text-left ' + usuario.rol } style={{"width":"8px"}}>
                                                          
                                                      </td>
                                                    <td className="v" style={{"width":"50px"}}>
                                                      <img src={`${SERVER_}perfil/foto/${usuario.uuid}?k=${(Math.floor(Math.random() * 6) + 1)}`} style={{width:'100%'}} onClick={(e:any)=>{handleShow_ModalCambiarFoto(usuario)}} />
                                                    </td>
                                                    <td className="v" style={{"width":"40px"}}>
                                                      {
                                                        // <input type="checkbox" checked={usuario.activo} onChange={()=>{console.log(usuario.uuid,indice_usuarios,usuario.activo)}} />
                                                      }
                                                      {
                                                        // <input type="checkbox" checked={usuario.activo} onChange={()=>{activarUsuario(usuario.uuid,indice_usuarios,usuario.activo)}} />
                                                      }

                                                      <small>{usuario.activo?'ACTIVO':'BAJA'}</small>
                                                    </td>
                                                      <td className="v" style={{"width":"150px"}}>
                                                        <p className="nm">{rol(usuario.rol)} {' '} </p>
                                                      </td>
                                                      <td className="v text-left" style={{"width":"160px"}}>
                                                        <p className="nm">
                                                          <b>{usuario.curp}</b>
                                                          <br />
                                                          <small>{usuario.rfc}</small>
                                                        </p>
                                                      </td>
                                                      <td className="v text-left" style={{"width":"130px"}}>
                                                      {
                                                        // JSON.stringify(usuario.fecha_ingreso)
                                                      }
                                                        <small>{moment(usuario.fecha_ingreso).format('DD/MMM/YYYY').toUpperCase().replace('.','')}</small>
                                                        <br/>
                                                        <small>{moment(usuario.fecha_ingreso).from(moment())}</small>
                                                      </td>
                                                      <td className="v text-left" >
                                                        <p className="nm">
                                                            <b>{usuario.nombres} </b>
                                                            <br/>
                                                            <small>{usuario.paterno} {usuario.materno}</small>
                                                        </p>
                                                      </td>
                                                      <td className="v text-left" style={{"width":"140px"}}>
                                                            {
                                                              // JSON.stringify(usuario.idsucursal.nombre_sucursal)
                                                              // usuario.idsucursal && (
                                                              //     <>
                                                              //       <p className="nm">{usuario.idsucursal.nombre_sucursal}</p>
                                                              //       <small>{usuario.idsucursal.idestado.estado}</small>
                                                              //     </>
                                                              // )
                                                            }
                                                            <p className="nm">{ usuario.idsubarea.subarea }</p>
                                                            <small>{ usuario.idsubarea.idarea.area } { usuario.foraneo && (<i title="Foraneo" className="fa fa-exclamation-circle" />) }</small>
                                                      </td>
                                                      <td className="v text-left" style={{"width":"140px"}}>
                                                        {usuario.username} <i className="fa fa-edit" onClick={()=>{handleShow_ModalCambiarUsername(usuario)}} />
                                                      </td>

                                                      <td className="v text-right" style={{"width":"100px"}}>
                                                        <p className="nm"><small>{FormateaNumero(usuario.celular)}</small></p>
                                                        <small>{usuario.email}</small>
                                                      </td>
                                                      <td className="v text-right" style={{"width":"40px"}}>
                                                            <Button disabled={!usuario.activo} onClick={()=>{documentosAnexos(usuario)}} className="nm" size="sm" variant="link"><i className="fa fa-folder" /></Button>
                                                      </td>
                                                      <td className="v text-right" style={{"width":"40px"}}>
                                                            <Button disabled={!usuario.activo} variant="light" onClick={()=>{handleShow_ModalGafete(usuario)}} size="sm"><i className="fa fa-id-card"/></Button>
                                                      </td>
                                                      <td className="v text-right" style={{"width":"40px"}}>
                                                            <Button disabled={!usuario.activo} variant="warning" onClick={()=>{handleShow_ModalResetPasswordUsuario(usuario)}} size="sm"><i className="fa fa-key"/></Button>
                                                      </td>
                                                      <td className="v text-right" style={{"width":"40px"}}>
                                                            <Button disabled={!usuario.activo} onClick={(e:any)=>{handleShow_ModalEditarUsuario(usuario)}} size="sm"><i className="fa fa-pencil"/></Button>
                                                      </td>
                                                      <td className="v text-right" style={{"width":"40px"}}>

                                                            <Button onClick={(e:any)=>{handleShow_ModalRegistroMovimientosUsuario(usuario)}} size="sm"><i className="fa fa-exchange"/></Button>

                                                      </td>
                                                    </tr>
                                                 )
                                              }

                                            </tbody>
                                          </Table>


                                          <Pagination
                                            itemsCount={allSessionsCountEmpleado}
                                            itemsPerPage={sessionsPerPageEmpleado}
                                            currentPage={currentPageEmpleado}
                                            setCurrentPage={setCurrentPageEmpleado}
                                            cambiarPaginaEvento={cargarMiReporteEmpleado}
                                            alwaysShown={false}
                                          />




                                          </>



                                         )
                                     }






                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}





              </div>




  {
    show_ModalResetPasswordUsuario && (
      <ModalResetPasswordUsuario show={show_ModalResetPasswordUsuario} handleClose={handleClose_ModalResetPasswordUsuario} usuario={usuario_seleccionado} />
      )

  }


  {
    show_ModalCambiarUsername && (
      <ModalCambiarUsername show={show_ModalCambiarUsername} handleClose={handleClose_ModalCambiarUsername} usuario={usuario_seleccionado} />
      )

  }


  {
    show_ModalNuevoUsuario && (
      <ModalNuevoUsuario show={show_ModalNuevoUsuario} handleClose={handleClose_ModalNuevoUsuario} tipo_alta_usuario="RECURSOS_HUMANOS" />
      )

  }




      {
        showModalAnexos && (
            <ModalAnexos onHideModalAnexos={onHideModalAnexos} showModalAnexos={showModalAnexos} usuario={usuario_seleccionado} />
         )
      }





  {
    show_ModalCambiarFoto && (
      <ModalCambiarFoto show={show_ModalCambiarFoto} handleClose={handleClose_ModalCambiarFoto} usuario={usuario_seleccionado} />
      )

  }


  {
    show_ModalEditarUsuario && (
      <ModalEditarUsuario show={show_ModalEditarUsuario} handleClose={handleClose_ModalEditarUsuario} usuario={usuario_seleccionado}    />
      )

  }



  {
    show_ModalGafete && (
      <ModalGafete show={show_ModalGafete} handleClose={handleClose_ModalGafete} usuario={usuario_seleccionado}  />
      )

  }





  {
    show_ModalRegistroMovimientosUsuario && (
      <ModalRegistroMovimientosUsuario show={show_ModalRegistroMovimientosUsuario} handleClose={handleClose_ModalRegistroMovimientosUsuario}  usuario={usuario_seleccionado} />
      )

  }

          </>

      );
  

}

export default RecursosHumanos
