import React, { useState } from "react";
import RequestService from "../../services/request.service";
import AuthService from '../../features/auth/auth.service'

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'



import ModalAgregarCircular from './ModalAgregarCircular'



import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'


import { formatearFecha } from '../../services/funcionesPublicas'

const Circulares = () =>{


  const user = AuthService.getCurrentUser()



  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const [ circulares, setResultados ] = useState<any>([])

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let response : any = await RequestService.getEndPoint(`circulares/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }




  const [ show_ModalAgregarCircular, setModalAgregarCircular ] = useState<boolean>(false)
  const handleShow_ModalAgregarCircular = () => {
    setModalAgregarCircular(true)
  }
  const handleClose_ModalAgregarCircular = () => {
    cargarMiReporte(0)
    setModalAgregarCircular(false)
  }




  React.useEffect(() => {
    cargarMiReporte(0)
  }, []);





      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Circulares</h4>
                    <hr/>

                    {
                      ["ROLE_ADMIN"].indexOf(user.rol) > -1 && (
                            <ul className="list-inline">
                              <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalAgregarCircular()}}>Agregar Circular</small></li>
                            </ul>
                        )
                    }








                          {  (!cargando_) && (

                              <>

                                   {
                                     circulares.length > 0 && (

                                       <>



                                            {


                                              circulares.map( (circular:any,indice:number) =>
                                                  <React.Fragment key={ Math.random().toString(36).substr(2, 9) } >



                                                    <div className="card"  >
                                                      <div className="card-body">


                                                      <div className="html-container" dangerouslySetInnerHTML={{ __html: circular.circular }} />
                                                                                                          {
                                                                                                            // JSON.stringify(circular.circular)
                                                                                                          }

                                                      </div>
                                                      <div className="card-footer">
                                                        <small>Fecha de Publicación</small>
                                                        <p className="nm">{formatearFecha(circular.fecha_creacion)}</p>
                                                      </div>
                                                    </div>



                                                  </React.Fragment>
                                               )
                                            }


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}





                    {
                      // JSON.stringify(user.rol)
                    }
                    
                </div>
              </div>

            </div>






  {
    show_ModalAgregarCircular && (
      <ModalAgregarCircular show={show_ModalAgregarCircular} handleClose={handleClose_ModalAgregarCircular}  />
      )

  }


          </>

      );
  

}

export default Circulares
