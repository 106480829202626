import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'


import ModalBoutiqueCrearUsuario from './ModalBoutiqueCrearUsuario'

import moment from 'moment'

import { SERVER_ } from  '../../config';

import { FormateaNumero } from '../../services/funcionesPublicas'

import ModalResetPasswordUsuario from '../Recursos_humanos/ModalResetPasswordUsuario'
import ModalAnexos from '../Recursos_humanos/ModalAnexos'


const ModalBoutiquesUsuariosAcceso = ( params : any ) => {

  const { show, handleClose, boutique_ } = params


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})


  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let response : any = await RequestService.getEndPoint( `boutique/${boutique_.uuid}/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(0)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true])


  const todos = () =>{
    setEstatus([true])
  }
  const ninguno = () =>{
    setEstatus([false])
  }



  const [ show_ModalBoutiqueCrearUsuario, setModalBoutiqueCrearUsuario ] = useState<boolean>(false)
  const handleShow_ModalBoutiqueCrearUsuario = () => {
    setModalBoutiqueCrearUsuario(true)
  }
  const handleClose_ModalBoutiqueCrearUsuario = () => {
    setModalBoutiqueCrearUsuario(false)
  }

  const [ usuario_seleccionado, setUsuarioSeleccionado ] = useState<any>({})
  const [ showModalAnexos, setShowModalAnexos ] = useState<any>(false)

  const onHideModalAnexos = () => {
    setShowModalAnexos(false)
  }

  const documentosAnexos = (usuario:string)=>{
      setUsuarioSeleccionado(usuario)
      setShowModalAnexos(true)
  }


  const [ show_ModalResetPasswordUsuario, setModalResetPasswordUsuario ] = useState<boolean>(false)
  const handleShow_ModalResetPasswordUsuario = (usuario:any) => {
     setUsuarioSeleccionado(usuario)
    setModalResetPasswordUsuario(true)
  }
  const handleClose_ModalResetPasswordUsuario = () => {
    setModalResetPasswordUsuario(false)
  }

  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Usuarios con Acceso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(boutique_)
          }


        <div className="row">
        
            <div className="">
              <label className="form-label">Búsqueda</label>
              <div className="input-group mb-3">
                <input type="text" className="form-control" onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                <button onClick={busqueda} className="btn btn-default" type="button" ><i className="fa fa-search" /></button>
              </div>
            </div>

            <div>

            {
              // <Form.Check inline name="group1" type="checkbox" label="CAPTURA" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
            }

            </div>
            

          <ul className="list-inline">
            {
              // <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
            }
            <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalBoutiqueCrearUsuario()}}>Nuevo Usuario</small></li>
          </ul>

        </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col"></th>
                                              <th scope="col">CURP</th>
                                              <th scope="col">Ingreso</th>
                                              <th scope="col"  >Nombre </th>
                                              <th scope="col">Usuario</th>
                                              <th scope="col">Contacto</th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {
                                              resultados.map((usuario:any)=>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}} className={ !usuario.activo ? 'table-warning' : '' }>
                                                    <td className={ 'v text-left ' + usuario.rol } style={{"width":"80px"}}>
                                                        <img src={`${SERVER_}perfil/foto/${usuario.uuid_usuario}`} style={{width:'100%'}} />
                                                    </td>
                                                    <td className="v text-left" style={{"width":"160px"}}>
                                                      <p className="nm">
                                                        <b>{usuario.curp}</b>
                                                        <br />
                                                        <small>{usuario.rfc}</small>
                                                      </p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"150px"}}>
                                                      <small>{moment(usuario.fecha_creacion).format('YYYY-MM-DD')}</small>
                                                      <br/>
                                                      <small>{moment(usuario.fecha_creacion).from(moment())}</small>
                                                    </td>
                                                    <td className="v text-left" >
                                                      <p className="nm">
                                                          <b>{usuario.nombres} </b>
                                                          <br/>
                                                          <small>{usuario.paterno} {usuario.materno}</small>
                                                      </p>
                                                    </td>

                                                    <td className="v text-left" style={{"width":"120px"}}>
                                                        <p className="nm">{usuario.username}</p>
                                                    </td>

                                                    <td className="v text-right" style={{"width":"100px"}}>
                                                      <p className="nm"><small>{FormateaNumero(usuario.celular)}</small></p>
                                                      <small>{usuario.email}</small>
                                                    </td>
                                                    <td className="v text-right" style={{"width":"40px"}}>
                                                          <Button onClick={()=>{documentosAnexos(usuario)}} className="nm" size="sm" variant="link"><i className="fa fa-folder" /></Button>
                                                    </td>
                                                    <td className="v text-right" style={{"width":"40px"}}>
                                                          <Button  variant="warning" onClick={()=>{handleShow_ModalResetPasswordUsuario(usuario)}} size="sm"><i className="fa fa-key"/></Button>
                                                    </td>
                                                    <td className="v text-right" style={{"width":"40px"}}>

                                                      {
                                                        ['ROLE_DIRECTOR_COMERCIAL'].indexOf(usuario.rol) > -1 && (
                                                          <Button disabled={!usuario.activo} onClick={()=>{console.log(usuario)}} size="sm"><i className="fa fa-pencil"/></Button>
                                                        )
                                                      }
                                                      {
                                                        ['ROLE_ASESOR_BASE','ROLE_ASESOR'].indexOf(usuario.rol) > -1 && (
                                                          <Button disabled={!usuario.activo} onClick={()=>{console.log(usuario)}} size="sm"><i className="fa fa-pencil"/></Button>
                                                        )
                                                      }
                                                      {
                                                        ['ROLE_GERENTE','ROLE_GERENTE_JR','ROLE_GERENTE_CONSOLIDADO','ROLE_ASISTENTE'].indexOf(usuario.rol) > -1 && (
                                                          <Button disabled={!usuario.activo} onClick={()=>{console.log(usuario)}} size="sm"><i className="fa fa-pencil"/></Button>
                                                        )
                                                      }
                                                        

                                                          <Button disabled={!usuario.activo} onClick={()=>{console.log(usuario)}} size="sm"><i className="fa fa-pencil"/></Button>

                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}



        </Modal.Body>

      </Modal>




  {
    show_ModalBoutiqueCrearUsuario && (
      <ModalBoutiqueCrearUsuario show={show_ModalBoutiqueCrearUsuario} handleClose={handleClose_ModalBoutiqueCrearUsuario} boutique_={boutique_} />
      )

  }



  {
    show_ModalResetPasswordUsuario && (
      <ModalResetPasswordUsuario show={show_ModalResetPasswordUsuario} handleClose={handleClose_ModalResetPasswordUsuario} usuario={usuario_seleccionado} />
      )

  }


      {
        showModalAnexos && (
            <ModalAnexos onHideModalAnexos={onHideModalAnexos} showModalAnexos={showModalAnexos} usuario={usuario_seleccionado} />
         )
      }



    </>
  );
}


export default ModalBoutiquesUsuariosAcceso;