import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'


const ModalServiciosExtras = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, periodo } = params


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 50
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`cobranza_servicios/${arreglo_estatus}/${periodo.uuid}/busqueda_servicios_extras?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e:any){

      if (e.response?.status) {
        switch (e.response.status) {
          default:
            toast.error(e.response?.data)
        }
      }
      else{
        toast.error("Problemas con la busqueda")
      }
      setCargando(false)

    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true,true,true,true,true])


  const todos = () =>{
    setEstatus([true,true,true,true,true])
  }
  const ninguno = () =>{
    setEstatus([false,false,false,false,false])
  }


      React.useEffect(() => {
        busqueda()
      }, []);






  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Servicios Extras del Periodo</Modal.Title>
        </Modal.Header>
        <Modal.Body>




        <div className="row">
        
            <div className="mb-2">
                <label className="form-label">Búsqueda</label>
                <div className="hstack gap-3">
                  <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                  <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                  <div className="vr"></div>
                  <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                </div>
            </div>
            
            <div>
              <Form.Check inline name="group1" type="checkbox" label="MANTENIMIENTO" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_0`} />
              <Form.Check inline name="group1" type="checkbox" label="CONSERVACIÓN" checked={arreglo_estatus[1]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
              <Form.Check inline name="group1" type="checkbox" label="LIMPIEZA" checked={arreglo_estatus[2]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}} id={`opciones_2`} />
              <Form.Check inline name="group1" type="checkbox" label="SUMINISTRO" checked={arreglo_estatus[3]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}} id={`opciones_3`} />
              <Form.Check inline name="group1" type="checkbox" label="OTROS SERVICIOS" checked={arreglo_estatus[4]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[4] = e.target.checked;  setEstatus(ne);}} id={`opciones_4`} />
            </div>
            
          <ul className="list-inline">
            <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
          </ul>

        </div>







              {  (!cargando_) && (

                  <>

                       {
                         resultados.length > 0 && (

                           <>


                           <h5>Resultados</h5>


                            <Table responsive="lg">
                              <thead style={{fontSize:9}}>
                                <tr>
                                  <th scope="col">Folio</th>
                                  <th scope="col">Boutique</th>
                                  <th scope="col">Cliente</th>
                                  <th scope="col">Tipo de Servicio</th>
                                  <th scope="col">Pagado</th>
                                </tr>
                              </thead>
                              <tbody>
                                {


                                  resultados.map( (cual:any,indice:number) =>
                                      <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                        <td className="v text-left" style={{"width":"180px"}} >
                                            <p className="nm"><small><b>{cual.folio}</b></small></p>
                                        </td>
                                        <td className="v text-left" >
                                            <small>{cual.nombre_boutique} </small>
                                        </td>
                                        <td className="v text-left" style={{"width":"180px"}} >
                                            <p className="nm"><small><b>{cual.cliente}</b></small></p>
                                        </td>
                                        <td className="v text-left" style={{"width":"280px"}} >
                                            <p className="nm"><small><b>{cual.tipo_orden}</b></small></p>
                                        </td>
                                        <td className="v text-left" style={{"width":"90px"}} >
                                            {
                                              !cual.pago_servicio && (<i className="fa fa-edit" />)
                                            }
                                        </td>
                                      </tr>
                                   )
                                }

                              </tbody>
                            </Table>


                              <Pagination
                                itemsCount={allSessionsCount}
                                itemsPerPage={sessionsPerPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                cambiarPaginaEvento={cargarMiReporte}
                                alwaysShown={false}
                              />


                            </>



                           )
                         
                       }


                  </>

               )}

              {  cargando_ && (

                  <>
                    <div className="loader">
                      <div className="loaderBar"></div>
                    </div>
                  </>


               )}





        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalServiciosExtras;