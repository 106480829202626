import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal, Form, Col, Row } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { SERVER_ } from  '../../config';

import Dialog from '../../services/Dialogs'


const ModalTareasConservacionBoutique = ( params : any ) => {

  const { show, handleClose, boutique_ } = params
  const [ boutique, setBoutique ] = useState<any>({ ... boutique_ })

  const dialog = new Dialog();
  
  const [ tareas_limpieza_default, setTareas_limpieza ] = useState<any>([])
  const cargarTareas_limpieza = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`tareas_conservacion_default`)
      setTareas_limpieza(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarTareas_limpieza()
        cargarTecnicosDisponibles()
  }, []);


   const [ conservacion_instrucciones, setInstrucciones ] = useState<any>(boutique.conservacion_instrucciones)

    

const [ envio_server, setSeEnvio ] = useState<boolean>(false)
const enviarFormulario = async (event:any) =>{
  if(!conservacion_instrucciones || conservacion_instrucciones.length === 0){
    toast.error('Debes agregar por lo menos una actividad ')
    return
  }
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `boutique/tareas_conservacion/${boutique.uuid}` , { conservacion_instrucciones : conservacion_instrucciones })

        if(res_){
          toast.success('Consulta_correcta')
          setTimeout(()=>{
            // cargar()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}


const insertValue = ( index:number, before:boolean = true) => {
  let arr = [...conservacion_instrucciones]
  if (before) {
    arr.splice(index, 0, "-");
  } else {
    arr.splice(index + 1, 0, "d-");
  }
  setInstrucciones(arr)
}




      const [ usuarios, setUsuarios ] = useState<any>([])

      const cargarTecnicosDisponibles = async () =>{
        try{
            let res = await RequestService.getEndPoint(`boutique/lista_conservacion_disponibles/${boutique.uuid}`)
            setUsuarios(res.data)

        }
        catch(e){
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
        }
      }




  const cargarDetalleBoutique = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`boutique/detalle/${boutique.uuid}`)
      setBoutique(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


const [ uuid_usuario, setUUIDUsuario ] = useState<string>('')
const enviarFormularioOperador = async (event:any) =>{
  if(uuid_usuario===''){
    toast.error('Debes de seleccionar un operador')
    return
  }
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `boutique/agregar_operador_conservacion/${boutique.uuid}` , { uuid_usuario : uuid_usuario })

        if(res_){
          toast.success('El operador se agrego correctamente')
          setTimeout(()=>{
        cargarTareas_limpieza()
        cargarTecnicosDisponibles()
        cargarDetalleBoutique()

          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}




const eliminarResponsable = async (event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.delete( `boutique/operador_conservacion/${boutique.uuid}`)

        if(res_){
          toast.warning('Se elimino el response')
          setTimeout(()=>{
            cargarDetalleBoutique()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}



  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Tareas de Conservación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }



          <Row>
            <Col md="6" sm="6">
                <p className="nm">Boutique</p>
                <h4>{boutique.nombre_boutique}</h4>
                <p className="nm">Localización</p>
                <h6>{boutique.idmunicipio.municipio}</h6>
            </Col>
            <Col md="6" sm="6">
              {
                // JSON.stringify(boutique.idusuario_conservacion)
              }

              {

                boutique.idusuario_conservacion && (
                    <>
                    {
                      // JSON.stringify(boutique.idusuario_conservacion)
                    }

                      <ul className="list-group">
                        <li className="list-group-item" style={{padding: '2px'}}>
                          <div className="row">
                            <div className="col-md-3">
                              <img src={`${SERVER_}perfil/foto/${boutique.idusuario_conservacion.uuid}?k=${(Math.floor(Math.random() * 6) + 1)}`} alt="Usuario 1" className="img-thumbnail" style={{width:100,maxWidth:'none !important'}} />
                            </div>
                            <div className="col-md-9">
                              <h5 className="mt-1 mb-1">{boutique.idusuario_conservacion.nombres} {boutique.idusuario_conservacion.paterno} {boutique.idusuario_conservacion.materno} </h5>
                              <p className="nm">OPERADOR DE LIMPIEZA</p>
                              <p className="nm"><button onClick={eliminarResponsable} className="btn btn-link btn-sm"><i className="fa fa-trash"/></button></p>
                            </div>
                          </div>
                        </li>
                      </ul>

                    </>
                )

              }


              {

                !boutique.idusuario_conservacion && (
                    <>
                      <p><i className="fa fa-warning"/> Debes seleccionar un Técnico de Mantenimiento para esta Boutique</p>

                        <Form.Group  className="mb-3" controlId="idequipo">
                          <Form.Label>Técnico de Mantenimiento</Form.Label>
                          <Form.Select value={uuid_usuario} onChange={(e:any)=>{setUUIDUsuario(e.target.value)}}>
                            <option>Selecciona un tipo de Usuario</option>
                            {
                              usuarios.map((usuario:any)=>
                                <option key={ Math.random().toString(36).substr(2, 9) } value={usuario.uuid}>{usuario.nombres} {usuario.paterno} {usuario.materno} </option>
                              )
                            }
                          </Form.Select>
                        </Form.Group>


                        <button type="button" onClick={enviarFormularioOperador} className="btn mb-2 btn-primary">Agregar</button>





                    </>
                )

              }
            </Col>
          </Row>







          {
            !conservacion_instrucciones && (
              <>
                  <div className="alert alert-danger" role="alert">
                    <h5>Aún no configuras las actividades de limpieza a realizar</h5>
                    <p className="nm">Puedes dar clic <button onClick={(e:any)=>{setInstrucciones(tareas_limpieza_default)}} className="btn btn-link btn-sm">aquí</button> para cargar la lista de actividades por defecto</p>
                  </div>
              </>
            )
          }



          {
            conservacion_instrucciones && (
              <>

          {
            // JSON.stringify(conservacion_instrucciones)
          }

                  <div className="alert alert-info" role="alert">
                    <h5>Configura las actividades</h5>
                    <p className="nm">Puedes dar clic <button onClick={(e:any)=>{setInstrucciones(tareas_limpieza_default)}} className="btn btn-link btn-sm">aquí</button> para cargar la lista de actividades por defecto</p>
                  </div>

                {
                  conservacion_instrucciones.map((instruccion:any,index:number)=>
                        <div key={ index } className="hstack gap-3 mb-2">
                          <input className="form-control me-auto" type="text" value={instruccion} onChange={(e:any)=>{ let i = [...conservacion_instrucciones]; i[index] = e.target.value.toUpperCase(); setInstrucciones(i) }} />
                          <button type="button" onClick={(e:any)=>{insertValue(index,true)}} className="btn btn-sm btn-secondary"><i className="fa fa-hand-o-up"/></button>
                          <button type="button" onClick={(e:any)=>{insertValue(index,false)}} className="btn btn-sm btn-secondary"><i className="fa fa-hand-o-down"/></button>
                          <div className="vr"></div>
                          <button type="button" onClick={(e:any)=>{ let i = [...conservacion_instrucciones]; i.splice(index, 1); setInstrucciones(i) }} className="btn btn-sm btn-outline-danger"><i className="fa fa-trash"/></button>
                        </div>
                  )
                }

              </>
            )
          }



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" onClick={enviarFormulario} type="button">Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalTareasConservacionBoutique;