import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import { toast } from 'react-toastify'


import moment from 'moment'


import Dialog from '../../services/Dialogs'




const ModalActividadesNoConcluidaReagendar = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, actividad } = params

  const dialog = new Dialog();



  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          indicaciones : yup.string().required(mensajes_validacion_generico),
          fecha_programada : yup.string().required(mensajes_validacion_generico),

          idresponsable : yup.string().required(mensajes_validacion_generico),
          idtecnico : yup.string().required(mensajes_validacion_generico),

  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)




  React.useEffect(() => {
    cargarLista_responsables()
    cargarLista_tecnicos()
    // cargarTipo_orden_trabajo()
    // cargarBoutiques()
  }, []);


  const [ lista_responsables, setLista_responsables ] = useState<any>({
    mantenimiento : []
  })
  const cargarLista_responsables = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`zonas/lista_responsables`)
      setLista_responsables(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  const [ lista_tecnicos, setLista_tecnicos ] = useState<any>([])
  const cargarLista_tecnicos = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`zonas/lista_tecnicos`)
      setLista_tecnicos(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }






  const [ fechas_nueva_actividad, setNuevaFecha ] = useState<any>([])
  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro de la información?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){

          if(!buscaPorFecha(fechas_nueva_actividad,moment(registro.fecha_programada).format('YYYY-MM-DD'))){
              let reg = {...registro}
              reg.fecha_programada = moment(registro.fecha_programada).format('YYYY-MM-DD')
              setNuevaFecha(ordenarPorFecha([...fechas_nueva_actividad, reg ]))
          }
          else{
            toast.warning('Esta fecha ya fue registrada para concluir la atención')
          }
          
        }
       
  }




const confirmareliminarActividad = async(indice:number,e:any) => {
    try{

        await dialog.open({
          accept: 'SI',
          cancel: 'NO',
          dialogClass: '',
          message: 'Estás seguro?',
          target: e.target,
          template: ` `
        })
        let dialogo : any = await dialog.waitForUser()
        
        if(dialogo){
          let regs = [...fechas_nueva_actividad]
          regs.splice(indice,1)
          setNuevaFecha(regs)
        }

    }
    catch(e){
      toast.error('Tuvimos problemas')
    }

  }



const buscaPorFecha = (arr:any, fechaBuscada:string)=> {
    return arr.some((item:any) => item.fecha_programada === fechaBuscada);
}

const ordenarPorFecha = (arr:any) => {
    return arr.sort((a:any, b:any) => (new Date(a.fecha_programada)).getTime() - (new Date(b.fecha_programada)).getTime() );
}

const buscarPorUUID = (arreglo:any, uuid_busqueda:string)=> {
    let registro = arreglo.find((item:any) => item.uuid === uuid_busqueda);
    if (registro) {
        return `${registro.nombres} ${registro.paterno} ${registro.materno}`;
    } else {
        return null;
    }
}


  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Reagendar Actividad</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <small>Boutique</small>
          <h5>{actividad.idboutique.nombre_boutique}</h5>

          <div className="row">
            <div className="col">
              <small>Técnico</small>
              <h5>{actividad.idtecnico.nombres} {actividad.idtecnico.paterno} {actividad.idtecnico.materno} </h5>
            </div>
            <div className="col">
              <small>Responsable</small>
              <h5>{actividad.idresponsable.nombres} {actividad.idresponsable.paterno} {actividad.idresponsable.materno} </h5>
            </div>
          </div>




              <hr/>


          <div className="row">
            <div className="col">

          <small>Cantidad de días solicitados por el técnico</small>
          <h5>{actividad.numero_dias_solicitados}</h5>


          <small>Explicación de la solicitud de días extras para terminar el servicio</small>
          <h5>{actividad.dias_extras_solicitud}</h5>

          {
            // JSON.stringify(actividad)
          }


              <hr/>

              <Formik
                validationSchema={schema}
                onSubmit={(values,e)=>{enviarFormulario(values, e)}}
                initialValues={{

                      indicaciones : "",
                      fecha_programada : new Date(),

                  idresponsable : "",
                  idtecnico : "",

                }}
              >
                {({
                  handleSubmit,
                  resetForm,
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form id='my-form' noValidate onSubmit={handleSubmit}>

                  <h6>Agregar Fechas</h6>

                  {
                    // JSON.stringify(values)
                  }


                      <Form.Group className="mb-3" controlId="indicaciones">
                        <Form.Label>indicaciones</Form.Label>
                        <Form.Control
                          as="textarea"
                          required
                          name="indicaciones"
                          value={values.indicaciones}
                          onChange={(e)=>{ setFieldValue('indicaciones', (e.target.value || "").toUpperCase() ) }}
                          isInvalid={!!errors.indicaciones}
                        />
                        <Form.Control.Feedback type="invalid"> {<>{errors.indicaciones}</>} </Form.Control.Feedback>
                      </Form.Group>


                      <div className="row">
                        <div className="col-sm-6">
                            <div className="mb-3">
                              <Form.Label>Fecha</Form.Label>
                              <DatePicker selected={values.fecha_programada} className="form-control"  dateFormat="yyyy-MM-dd" minDate={new Date()} onChange={(date) => setFieldValue('fecha_programada', date ) } />
                            </div>
                        </div>
                      </div>


                        <h5>Responsables de la Actividad</h5>


                         <Form.Group className="mb-3" controlId="idresponsable">
                          <Form.Label>Selecciona un Responsable</Form.Label>
                          <Form.Select required name="idresponsable" value={values.idresponsable} onChange={(e)=>{ setFieldValue('idresponsable', e.target.value ) }} isInvalid={!!errors.idresponsable}>
                            <option value="">SELECCIONA UN RESPONSABLE</option>
                              {
                                lista_responsables.mantenimiento.map((responsable:any)=>
                                    <option key={ Math.random().toString(36).substr(2, 9) } value={responsable.uuid}>{responsable.nombres} {responsable.paterno} {responsable.materno} </option>
                                  )
                              }
                          </Form.Select>
                          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid"> {<>{errors.idresponsable}</>} </Form.Control.Feedback>
                        </Form.Group>



                         <Form.Group className="mb-3" controlId="idtecnico">
                          <Form.Label>Selecciona un Técnico</Form.Label>
                          <Form.Select required name="idtecnico" value={values.idtecnico} onChange={(e)=>{ setFieldValue('idtecnico', e.target.value ) }} isInvalid={!!errors.idtecnico}>
                            <option value="">SELECCIONA UN TÉCNICO</option>
                              {
                                lista_tecnicos.map((tecnico:any)=>
                                    <option key={ Math.random().toString(36).substr(2, 9) } value={tecnico.uuid}>{tecnico.nombres} {tecnico.paterno} {tecnico.materno} </option>
                                  )
                              }
                          </Form.Select>
                          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid"> {<>{errors.idtecnico}</>} </Form.Control.Feedback>
                        </Form.Group>



                      <div className="col-12">
                        <button type="submit" className="btn btn-sm btn-primary">Agregar Fecha</button>
                      </div>


                  </Form>
                )}
              </Formik>



            </div> 
            <div className="col-6">
              
            {
              JSON.stringify(fechas_nueva_actividad)
            }

              <ol className="list-group list-group-numbered">
                {
                  fechas_nueva_actividad.map((actividad:any,index_actividad:number)=>

                          <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                              <div className="fw-bold"><h6 className="nm">{moment(actividad.fecha_programada).format('DD [DE] MMMM [DE] YYYY').toUpperCase()}</h6></div>
                              <p className="nm"><b>Técnico: </b>{buscarPorUUID(lista_tecnicos,actividad.idtecnico)||'--'}</p>
                              <p className="nm"><b>Responsable: </b>{buscarPorUUID(lista_responsables.mantenimiento,actividad.idresponsable)||'--'}</p>
                              <small>{actividad.indicaciones}</small> <i className="fa fa-trash" onClick={(e:any)=>{confirmareliminarActividad(index_actividad,e)}} />
                            </div>
                          </li>

                    )
                }
              </ol>



            </div> 
          </div>


        </Modal.Body>
        {
          fechas_nueva_actividad.length > 0 && (
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Cancelar
                </Button>
                <Button variant="success" type="button">Registrar Nuevas Actividades</Button>
              </Modal.Footer>
            )
        }

      </Modal>
    </>
  );
}


export default ModalActividadesNoConcluidaReagendar;