import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { SERVER_ } from  '../../config';


import { rol } from '../../services/rol'

const ModalVisitasEmpleados = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params


  const [ empleados_para_visitas, setEmpleados_para_visitas ] = useState<any>([])
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarEmpleados_para_visitas = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`calendario/empleados_para_visitas`)
      setEmpleados_para_visitas(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar la lista de personal')
    }
  }


  React.useEffect(() => {
    cargarEmpleados_para_visitas()
  }, []);




  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Personal Asignado para Visitas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(empleados_para_visitas)
          }
          <ol className="list-group">
            {
              empleados_para_visitas.map( (personal:any,inidice_personal:number) =>
                <React.Fragment key={inidice_personal}>
                  <li className="list-group-item" >
                    {
                      // JSON.stringify(personal)
                    }
                  <div className="row">
                    <div className="col-md-2">
                      <img
                        src={`${SERVER_}perfil/foto/`+personal.uuid_usuario}
                        className="img-fluid rounded-circle"
                      />
                    </div>
                    <div className="col-md-10">
                      <h5 className="nm">{personal.nombres}</h5>
                      <p className="nm">{personal.paterno}</p>
                      <p className="nm"><small>{rol(personal.rol)}</small> </p>
                    </div>
                  </div>


                  {
                    personal.documentacion.length > 0 && (
                        <React.Fragment>
                            <p className="nm"><small><b>Documentación</b></small></p>
                            <ul>
                              {
                                personal.documentacion.map((documento:any,indice_documento:number)=>
                                  <React.Fragment>
                                    {
                                      documento.idtipo_documento.debe_visualizar_cliente && (
                                          <li key={indice_documento}>
                                            {
                                              // JSON.stringify(documento)
                                            }
                                            <p className="nm"><a target="_blank" href={`${SERVER_}anexo/${documento.uuid}`}>{documento.idtipo_documento.documento}</a></p>
                                          </li>
                                        )
                                    }
                                  </React.Fragment>
                                  )
                              }
                            </ul>
                        </React.Fragment>
                      )
                  }



                  </li>
                </React.Fragment>

               )
            }
          </ol>
        </Modal.Body> 
      </Modal>
    </>
  );
}


export default ModalVisitasEmpleados;