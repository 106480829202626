import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal, Tabs, Tab } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { SERVER_ } from  '../../config';


const ModalCobros = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params

  const [ boutiques_cobrar, setBoutiques_cobrar ] = useState<any>({
          por_boutiques : [],
          por_clientes : []

  })
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarBoutiques_cobrar = async(periodoSeleccionado:string)=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`lista_cobranza/${periodoSeleccionado}`)
      setBoutiques_cobrar(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarBoutiques_cobrar(periodo_seleccionado)
    cargarPeriodos()
  }, []);


const reestructurarDatos = (boutiques:any) => {
  const resultado : any = [];

  boutiques.forEach((boutique:any) => {
    const idarea = boutique.idsubarea.idarea.uuid;
    const idsubarea = boutique.idsubarea.uuid;

    // Busca si ya existe un área con el mismo UUID
    const areaExistente = resultado.find((area:any) => area.uuid === idarea);

    if (areaExistente) {
      // Busca si ya existe una subárea con el mismo UUID
      const subareaExistente = areaExistente.subareas.find((subarea:any) => subarea.uuid === idsubarea);

      if (subareaExistente) {
        // Agrega la boutique a la subárea existente
        subareaExistente.boutiques.push(boutique);
      } else {
        // Crea una nueva subárea y agrega la boutique
        areaExistente.subareas.push({
          uuid: idsubarea,
          subarea: boutique.idsubarea.subarea,
          boutiques: [boutique]
        });
      }
    } else {
      // Crea un nuevo área, subárea y agrega la boutique
      resultado.push({
        uuid: idarea,
        area: boutique.idsubarea.idarea.area,
        idresponsable_mantenimiento: boutique.idsubarea.idarea.idresponsable_mantenimiento,
        idresponsable_conservacion: boutique.idsubarea.idarea.idresponsable_conservacion,
        idresponsable_limpieza: boutique.idsubarea.idarea.idresponsable_limpieza,
        subareas: [
          {
            uuid: idsubarea,
            subarea: boutique.idsubarea.subarea,
            boutiques: [boutique]
          }
        ]
      });
    }
  });

  return resultado;
}

 
  const [ periodo_seleccionado, setPeriodoSeleccionado ] = useState<any>("DEFAULT")
  const [ periodos, setPeriodos ] = useState<any>([])
  const cargarPeriodos = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`lista/periodos`)
      setPeriodos(response.data)
      setPeriodoSeleccionado(response.data[0].uuid)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }
 





  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Refaccionamiento</Modal.Title>
        </Modal.Header>
        <Modal.Body>






        <div className="mb-3">
          <label htmlFor="formFile" className="form-label">Perido</label>
          <select className="form-select" value={periodo_seleccionado} onChange={(e:any)=>{ setPeriodoSeleccionado(e.target.value); cargarBoutiques_cobrar(e.target.value) }} >
            {
              periodos.map((periodo:any,indice_periodo:number)=><option key={indice_periodo} value={periodo.uuid}>PERIODO DEL {periodo.fecha_inicio} AL {periodo.fecha_fin}</option>)
            }
          </select>
        </div>




                          {  (!envio_server) && (

                              <>


                                {
                                  boutiques_cobrar?.por_clientes.length > 0 && (


                                        <table className="table">
                                          <thead>
                                            <tr>
                                              <th scope="col">Cliente</th>
                                              <th scope="col">R.F.C.</th>
                                              <th scope="col">Razón Social</th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {
                                              boutiques_cobrar.por_clientes.map( (cliente:any,indice_boutique:number)=>
                                                  <tr key={indice_boutique}>
                                                    <td style={{width:200}} className="v">
                                                      <p className="nm"><b>{cliente.cliente}</b></p>
                                                    </td>
                                                    <td style={{width:240}} className="v">
                                                      <p className="nm"> {cliente.rfc} </p>
                                                    </td>
                                                    <td className="v">
                                                      <p className="nm"> {cliente.razon_social} </p>
                                                    </td>
                                                    <td style={{width:40}} className="v text-center">
                                                        <button onClick={(e:any)=>{ window.open(`${SERVER_}cobranza/clientes/descarga_cobro/${periodo_seleccionado}/${cliente.uuid}`) }} className="btn btn-sm btn-primary"><i className="fa fa-download"/></button>
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </table>


                                    )
                                }



                              </>

                           )}

                          {  envio_server && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}




 




          {
            // JSON.stringify(boutiques_cobrar)
          }
        </Modal.Body> 
      </Modal>
    </>
  );
}


export default ModalCobros;