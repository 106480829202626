import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import moment from 'moment'

import Dialog from '../../services/Dialogs'

const ModalEquipoCriticoEditarBoutique = ( params : any ) => {

  const { show, handleClose, boutique,equipo } = params


  const dialog = new Dialog();


      const [ equipos_criticos, setEquipoCritico ] = useState<any>([])

      const cargar = async () =>{
        try{
            let res = await RequestService.getEndPoint(`equipos_criticos`)
            setEquipoCritico(res.data)

        }
        catch(e){
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
        }
      }

      React.useEffect(() => {
        cargar()
      }, []);


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          idequipo : yup.string().required(mensajes_validacion_generico),
          detalles : yup.string().required(mensajes_validacion_generico),
          dias_entre_mantenimiento : yup.number().required(mensajes_validacion_generico),
          activo : yup.boolean(),
          fecha_ultimo_mantenimiento : yup.string().required(mensajes_validacion_generico),

  });

const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `boutique/equipo_critico/${equipo.uuid}` , { ...registro, fecha_ultimo_mantenimiento : moment(registro.fecha_ultimo_mantenimiento).format('YYYY-MM-DD') })

        if(res_){
          toast.success('Se actualizó la información del equipo')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Cambiar los Datos </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }


          <p className="nm">Boutique</p>
          <h4>{boutique.nombre_boutique}</h4>


          <Formik
            validationSchema={schema}
            onSubmit={(values,e)=>{enviarFormulario(values, e)}}
            initialValues={{


                  idequipo : equipo.idequipo.uuid,
                  detalles : equipo.detalles,
                  dias_entre_mantenimiento : equipo.dias_entre_mantenimiento,
                  activo : equipo.activo,
                  fecha_ultimo_mantenimiento : new Date(),

            }}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form id='my-form' noValidate onSubmit={handleSubmit}>

{
  // JSON.stringify(equipo)
}


                  <Form.Group  className="mb-3" controlId="idequipo">
                    <Form.Label>Equipo Crítico</Form.Label>
                    <Form.Select required value={values.idequipo} onChange={(e)=>{ setFieldValue('idequipo',e.target.value) }} isInvalid={!!errors.idequipo} >
                      <option>Selecciona un tipo de Usuario</option>
                      {
                        equipos_criticos.map((equipo:any)=>
                          <option key={ Math.random().toString(36).substr(2, 9) } value={equipo.uuid}>{equipo.tipo_equipo} | {equipo.marca} - {equipo.modelo} </option>
                        )
                      }
                    </Form.Select>
                  </Form.Group>


                  <Form.Group   className="mb-3" controlId="detalles">
                    <Form.Label>Detalles del Equipo</Form.Label>
                    <Form.Control
                      as="textarea"
                      required
                      name="detalles"
                      value={values.detalles}
                      onChange={(e)=>{ setFieldValue('detalles', (e.target.value || "").toUpperCase() ) }}
                      isInvalid={!!errors.detalles}
                    />
                  </Form.Group>



                  <div className="row">
                    <div className="col-sm-6">
                        <div className="mb-3">
                          <Form.Label>Último Mantenimiento</Form.Label>
                          <DatePicker selected={values.fecha_ultimo_mantenimiento} className="form-control"  dateFormat="yyyy-MM-dd" maxDate={new Date()} onChange={(date) => setFieldValue('fecha_ultimo_mantenimiento', date ) } />
                        </div>
                    </div>

                    <div className="col-sm-6">
                          <Form.Group className="mb-3" controlId="dias_entre_mantenimiento">
                            <Form.Label>Días entre mantenimiento</Form.Label>
                            <Form.Control
                              type="number"
                              name="dias_entre_mantenimiento"
                              value={values.dias_entre_mantenimiento}
                              onChange={(e)=>{ setFieldValue('dias_entre_mantenimiento', (e.target.value || "").toUpperCase() ) }}
                              isInvalid={!!errors.dias_entre_mantenimiento}
                            />
                          </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                          <Form.Label>Estatus del Equipo</Form.Label>
                            <div className="form-check">
                              <input className="form-check-input" type="checkbox" checked={values.activo} onChange={(e:any)=>{setFieldValue('activo',e.target.checked)}} id="flexCheckDefault" />
                              <label className="form-check-label" htmlFor="flexCheckDefault">
                                Activo
                              </label>
                            </div>
                    </div>
                  </div>


              </Form>
            )}
          </Formik>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Agregar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalEquipoCriticoEditarBoutique;