import React, { useState } from 'react';

import {Button, Modal, Col, Row, Form } from 'react-bootstrap';

import RequestService from "../../services/request.service";


import { toast } from 'react-toastify'


import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import moment from 'moment'

import Dialog from '../../services/Dialogs'

const ModalNuevoContratoCliente = ( params : any ) => {

  const { show, handleClose, cliente } = params


      const [ tipos_contrato, setTipoContrato ] = useState<any>([])

      const cargar = async () =>{
        try{
            let res = await RequestService.getEndPoint(`tipos_de_contrato`)
            setTipoContrato(res.data)

        }
        catch(e){
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
        }
      }

      React.useEffect(() => {
        cargar()
      }, []);


  const dialog = new Dialog();





  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          detalles : yup.string(),
          idtipo_contrato : yup.number().nullable(),
          fecha_inicio : yup.string().required(mensajes_validacion_generico),
          fecha_fin : yup.string(),
            mantenimiento : yup.boolean(),
            limpieza : yup.boolean(),
            conservacion : yup.boolean(),

  });



const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `cliente/contrato/agregar/${cliente.uuid}` , { ...registro, fecha_inicio : moment(registro.fecha_inicio).format('YYYY-MM-DD'), fecha_fin : moment(registro.fecha_fin).format('YYYY-MM-DD') })

        if(res_){
          toast.success('MENSAJE')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}

const agregar6Meses = (fecha:any)=>  {
  const nuevaFecha = new Date(fecha);
  const dia = nuevaFecha.getDate();
  
  nuevaFecha.setMonth(nuevaFecha.getMonth() + 6);
  
  // Ajustar el día si se pasa del último día del mes
  const ultimoDiaMes = new Date(nuevaFecha.getFullYear(), nuevaFecha.getMonth() + 1, 0).getDate();
  nuevaFecha.setDate(Math.min(dia, ultimoDiaMes));
  
  return nuevaFecha;
}

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Agregar Contrato del Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(tipos_contrato)
          }


    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            idtipo_contrato : 1,
            detalles : "",
            fecha_inicio : new Date(),
            // fecha_fin : "",
            fecha_fin : agregar6Meses(new Date()),

            mantenimiento : true,
            limpieza : true,
            conservacion : true,

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-formx' noValidate onSubmit={handleSubmit}>



{
  // JSON.stringify(values)
}


              <small className="nm">Cliente</small>

              <h4>{cliente.cliente}</h4>

              <div className="mb-3">
                <label className="form-label">Tipo de Contrato</label>

                    {
                      tipos_contrato.map((tipo:any)=>

                            <div className="form-check form-check-inline" key={ Math.random().toString(36).substr(2, 9) }>
                              <input className="form-check-input" type="radio" name="flexRadioDefault" checked={values.idtipo_contrato==tipo.id} onChange={(e:any)=>{setFieldValue('idtipo_contrato',tipo.id)}} id={`id_${tipo.id}`} />
                              <label className="form-check-label" htmlFor={`id_${tipo.id}`}>
                                {tipo.tipo_contrato}
                              </label>
                            </div>


                        )
                    }

              </div>




            <Form.Group   className="mb-3" controlId="detalles">
              <Form.Label>Detalles del Contrato</Form.Label>
              <Form.Control
                as="textarea"
                name="detalles"
                value={values.detalles}
                onChange={(e)=>{ setFieldValue('detalles', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.detalles}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>



            <div className="row">
              <div className="col-sm-6">
                  <div className="mb-3">
                    <Form.Label>Fecha de Inicio del Contrato</Form.Label>
                    <DatePicker selected={values.fecha_inicio} className="form-control"  dateFormat="yyyy-MM-dd"   onChange={(date) => setFieldValue('fecha_inicio', date ) } />
                  </div>
              </div>
              <div className="col-sm-6">
                  <div className="mb-3">
                    <Form.Label>Fecha de Fin del Conrato</Form.Label>
                    <DatePicker selected={values.fecha_fin} className="form-control"  dateFormat="yyyy-MM-dd" minDate={values.fecha_inicio} onChange={(date) => setFieldValue('fecha_fin', date||'' ) } />
                  </div>
              </div>
            </div>


<div className="mb-3">
<Form.Label>Servicios que incluye el Contrato</Form.Label>
  <div className="form-check form-check-inline">
    <input className="form-check-input" type="checkbox" id="mantenimiento" checked={values.mantenimiento} onChange={(e:any)=>{setFieldValue('mantenimiento',e.target.checked)}} />
    <label className="form-check-label" htmlFor="mantenimiento">
      Servicio de Mantenimiento
    </label>
  </div>
  <div className="form-check form-check-inline">
    <input className="form-check-input" type="checkbox" id="Limpieza" checked={values.limpieza} onChange={(e:any)=>{setFieldValue('limpieza',e.target.checked)}} />
    <label className="form-check-label" htmlFor="Limpieza">
      Servicio de Limpieza
    </label>
  </div>
  <div className="form-check form-check-inline">
    <input className="form-check-input" type="checkbox" id="Reparación" checked={values.conservacion} onChange={(e:any)=>{setFieldValue('conservacion',e.target.checked)}} />
    <label className="form-check-label" htmlFor="Reparación">
      Servicio de Conservación
    </label>
  </div>
</div>

 


        </Form>
      )}
    </Formik>



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-formx' type="submit">Agregar Contrato <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalNuevoContratoCliente;