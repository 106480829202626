import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal, Table, Tabs, Tab } from 'react-bootstrap';

import { toast } from 'react-toastify'

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import moment from 'moment'


import { rol } from '../../services/rol'

import { obtenerDiaSemana } from '../../services/funcionesPublicas'

const ModalReporteFaltasRetardos = ( params : any ) => {

  const { show, handleClose } = params

  const [ cargando_, setCargando ] = useState<boolean>(false)

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());


  const [startDate_, setStartDate_] = useState(new Date());
  const [endDate_, setEndDate_] = useState(new Date());

  const onChangeRango = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    // cargarMiReporte()
  };

  const [ reporte, setReporte ] = useState<any>({
              faltas_retardos : [],
              vacaciones : [],
              licencias : [],
              cumpleanos : []
            })


  const cargarMiReporte = async( )=>{
    try{
      setCargando(true)
      let response : any = await RequestService.getEndPoint(`reportes/incidencias/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`)
      setReporte(response.data)
      setStartDate_(startDate)
      setEndDate_(endDate)

      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }



  const eliminarMovimiento = async(uuid_movimiento:string)=>{
    try{
      setCargando(true)
      let response : any = await RequestService.delete(`movimiento/eliminar/${uuid_movimiento}`)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar eliminar el movimiento')
    }
  }


const generarCSV = (arregloDeObjetos:any, nombreArchivo:any,camposIncluidos:any) => {
  // const camposIncluidos = ;

  const contenidoCSV = arregloDeObjetos.map((objeto:any) =>
    camposIncluidos.map((campo:any) => obtenerValorAnidado(objeto, campo)).join(',')
  );

  const encabezados = camposIncluidos.map((campo:any) => campo.split('.').pop()); // Obtén solo el último segmento para los encabezados
  const csv = [encabezados.join(','), ...contenidoCSV].join('\n');
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  const enlaceDescarga = document.createElement('a');
  enlaceDescarga.href = URL.createObjectURL(blob);
  enlaceDescarga.download = nombreArchivo + '.csv';

  enlaceDescarga.click();
  URL.revokeObjectURL(enlaceDescarga.href);
}

const obtenerValorAnidado = (objeto:any, campo:any) => {
  return campo.split('.').reduce((acumulador:any, segmento:any) => acumulador && acumulador[segmento], objeto);
}




  return (
    <>
      <Modal show={show} fullscreen={true}   onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Reporte de Faltas y Retardos</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <div className="row">
              <div className="col-sm-2">
                


                    <label className="form-label">Fecha del Reporte</label>
                    <div className="text-center">
                      <DatePicker onChange={onChangeRango} inline className="form-control" dateFormat="dd/MM/yyyy" maxDate={new Date()} startDate={startDate} endDate={endDate} selectsRange />
                    </div>


                    <div className="d-grid gap-2 d-md-flex justify-content-md-e-nd mt-3">
                        <button className="btn btn-primary" type="button" onClick={cargarMiReporte}><i className="fa fa-search" /> Cargar Reporte</button>
                    </div>

              </div>
              <div className="col">
                


                    <h4 className="nm-">
                      {
                        moment(startDate_).format('YYYY-MM-DD') !== moment(endDate_).format('YYYY-MM-DD') && ('REPORTE ENTRE '+ moment(startDate_).format('DD [DE] MMMM [DEL] YYYY').toUpperCase() + ' Y EL ' + moment(endDate_).format('DD [DE] MMMM [DEL] YYYY').toUpperCase() )
                      }
                      {
                        moment(startDate_).format('YYYY-MM-DD') === moment(endDate_).format('YYYY-MM-DD') && ('REPORTE PARA EL '+moment(startDate_).format('DD [DE] MMMM [DEL] YYYY').toUpperCase())
                      }
                    </h4>


                    {
                      // JSON.stringify(reporte)
                    }


                          {  (!cargando_) && (

                              <>
 
    <Tabs
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="Faltas y Retardos">
        {
          // JSON.stringify(reporte.faltas_retardos)
        }

          { reporte.faltas_retardos.length > 0 && (<button onClick={(e:any)=>{generarCSV( reporte.faltas_retardos.map((falta:any)=>{ return { ...falta, fecha : moment(falta.fecha).format('YYYY-MM-DD') } }) , 'reporte_faltas_retardos',['fecha', 'idempleado.nombres', 'idempleado.paterno', 'idempleado.materno', 'idempleado.curp', 'observaciones', 'justificada', 'tipo', 'fecha_creacion'])}} className="btn btn-link bnt-sm">Descargar Listado</button>)}



          {
            reporte.faltas_retardos.length > 0 && (

            <table className="table">
              <thead style={{fontSize:10}}>
                <tr>
                  <th scope="col">Fecha</th>
                  <th scope="col">Empleado</th>
                  <th scope="col">CURP</th>
                  <th scope="col">Tipo de Usuario</th>
                  <th scope="col">Detalles</th>
                  <th scope="col">Tipo</th>
                  <th scope="col">Justificado</th>
                </tr>
              </thead>
              <tbody>
                  {
                    reporte.faltas_retardos.map((falta:any)=>
                    <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:10}}>
                      <td className="v" style={{width:100}}>{moment(falta.fecha).format('YYYY-MM-DD')}</td>
                      <td className="v" style={{width:350}}>
                      <p className="nm" ><b>{falta.idempleado.nombres}</b> {falta.idempleado.paterno} {falta.idempleado.materno}</p>
                      </td>
                      <td className="v" style={{width:350}}>
                        <small>{(falta.idempleado.curp)}</small> 
                      </td>
                      <td className="v" style={{width:350}}>
                        <small>{rol(falta.idempleado.rol)}</small> 
                      </td>
                      <td className="v">
                      <p className="nm"> {falta.observaciones}</p>
                      </td>
                      <td className="v text-center" style={{width:50}}>{falta.tipo}</td>
                      <td className="v text-center" style={{width:50}}>{falta.justificada?'SI':'NO'}</td>
                    </tr>
                      )
                  }
              </tbody>
            </table>

            )
          }

          {
            reporte.faltas_retardos.length === 0 && (<p><i className="fa fa-warning"/> No cuenta con registros</p>)
          }




      </Tab>
      <Tab eventKey="profile" title="Vacaciones">
      {
        // JSON.stringify(reporte.vacaciones)
      }

          {reporte.vacaciones.length > 0 &&(<button onClick={(e:any)=>{generarCSV( reporte.vacaciones.map((vacacion:any)=>{ return {...vacacion, fecha : moment(vacacion.fecha).format('YYYY-MM-DD')  } }) , 'reporte_vacaciones',['fecha', 'idempleado.nombres', 'idempleado.paterno', 'idempleado.materno', 'idempleado.curp', 'idsolicitud.detalles_solicitud','idsolicitud.fecha_creacion'])}} className="btn btn-link bnt-sm">Descargar Listado</button>)}

          {
            reporte.vacaciones.length > 0 && (

            <table className="table">
              <thead style={{fontSize:10}}>
                <tr>
                  <th scope="col">Fecha</th>
                  <th scope="col">Empleado</th>
                  <th scope="col">CURP</th>
                  <th scope="col">Tipo de Usuario</th>
                  <th scope="col">Detalles</th>
                </tr>
              </thead>
              <tbody>
                  {
                    reporte.vacaciones.map((vacacion:any)=>
                    <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:10}}>
                      <td className="v" style={{width:100}}>{moment(vacacion.fecha).format('YYYY-MM-DD')}</td>
                      <td className="v" style={{width:350}}>
                      <p className="nm" ><b>{vacacion.idempleado.nombres}</b> {vacacion.idempleado.paterno} {vacacion.idempleado.materno}</p>
                      </td>
                      <td className="v" style={{width:350}}>
                        <small>{(vacacion.idempleado.curp)}</small> 
                      </td>
                      <td className="v" style={{width:350}}>
                        <small>{rol(vacacion.idempleado.rol)}</small> 
                      </td>
                      <td className="v">
                      <p className="nm"> {vacacion.idsolicitud.detalles_solicitud}</p>
                      </td>
                    </tr>
                      )
                  }
              </tbody>
            </table>

            )
          }

          {
            reporte.vacaciones.length === 0 && (<p><i className="fa fa-warning"/> No cuenta con registros</p>)
          }



      </Tab>
      <Tab eventKey="contact" title="Licencias" >
      {
        // JSON.stringify(reporte.licencias)
      }

          {reporte.licencias.length > 0 &&(<button onClick={(e:any)=>{generarCSV( reporte.licencias.map((licencia:any)=>{ return { ...licencia, fecha : moment(licencia.fecha).format('YYYY-MM-DD'), rol : rol(licencia.idempleado.rol) } }) , 'reporte_licencias',['fecha', 'idempleado.nombres', 'idempleado.paterno', 'idempleado.materno', 'idempleado.curp', 'rol', 'idsolicitud.detalles_solicitud','idsolicitud.fecha_creacion'])}} className="btn btn-link bnt-sm">Descargar Listado</button>)}

          {
            reporte.licencias.length > 0 && (

            <table className="table">
              <thead style={{fontSize:10}}>
                <tr>
                  <th scope="col">Fecha</th>
                  <th scope="col">Empleado</th>
                  <th scope="col">CURP</th>
                  <th scope="col">Tipo de Usuario</th>
                  <th scope="col">Detalles</th>
                </tr>
              </thead>
              <tbody>
                  {
                    reporte.licencias.map((licencia:any)=>
                    <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:10}}>
                      <td className="v" style={{width:100}}>{moment(licencia.fecha).format('YYYY-MM-DD')}</td>
                      <td className="v" style={{width:350}}>
                      <p className="nm" ><b>{licencia.idempleado.nombres}</b> {licencia.idempleado.paterno} {licencia.idempleado.materno}</p>
                      </td>
                      <td className="v" style={{width:350}}>
                        <small>{(licencia.idempleado.curp)}</small> 
                      </td>
                      <td className="v" style={{width:350}}>
                        <small>{rol(licencia.idempleado.rol)}</small> 
                      </td>
                      <td className="v">
                      <p className="nm"> {licencia.idsolicitud.detalles_solicitud}</p>
                      </td>
                    </tr>
                      )
                  }
              </tbody>
            </table>

            )
          }

          {
            reporte.licencias.length === 0 && (<p><i className="fa fa-warning"/> No cuenta con registros</p>)
          }




      </Tab>
      <Tab eventKey="births" title="Cumpleaños" >


          {reporte.cumpleanos.length > 0 &&(<button onClick={(e:any)=>{generarCSV(reporte.cumpleanos.map((cumple:any)=>{ return {... cumple, fecha_nacimiento : moment(cumple.fecha_nacimiento).format('YYYY-MM-DD'), rol : rol(cumple.rol) } }), 'reporte_cumpleaños',['fecha_nacimiento', 'nombres', 'paterno', 'materno', 'curp', 'rol'])}} className="btn btn-link bnt-sm">Descargar Listado</button>)}

          {
            reporte.cumpleanos.length > 0 && (

            <table className="table">
              <thead style={{fontSize:10}}>
                <tr>
                  <th scope="col">Fecha</th>
                  <th scope="col">Día</th>
                  <th scope="col">Empleado</th>
                  <th scope="col">CURP</th>
                  <th scope="col">Tipo de Usuario</th>
                </tr>
              </thead>
              <tbody>
                  {
                    reporte.cumpleanos.map((cumple:any)=>
                    <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:10}}>
                      <td className="v" style={{width:100}}>{moment(cumple.fecha_nacimiento).format('YYYY-MM-DD')}</td>

                      <td className="v" style={{width:150}}>
                        <p className="nm">{obtenerDiaSemana(cumple.fecha_nacimiento)}</p> 
                      </td>
                      <td className="v" style={{width:350}}>
                      <p className="nm" ><b>{cumple.nombres}</b> {cumple.paterno} {cumple.materno}</p>
                      </td>
                      <td className="v" style={{width:350}}>
                        <small>{(cumple.curp)}</small> 
                      </td>
                      <td className="v" style={{width:350}}>
                        <small>{rol(cumple.rol)}</small> 
                      </td>
                    </tr>
                      )
                  }
              </tbody>
            </table>

            )
          }

          {
            reporte.cumpleanos.length === 0 && (<p><i className="fa fa-warning"/> No cuenta con registros</p>)
          }




      </Tab>
    </Tabs>


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}


              </div>
            </div>



        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalReporteFaltasRetardos;