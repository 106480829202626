import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { FormateaNumero, obtenerNombreDia, diferenciaHoras, obtenerDiaSemana } from '../../services/funcionesPublicas'

import moment from 'moment'
import 'moment/locale/es'

const ModalInfoVisita = ( params : any ) => {

  const { show, handleClose, boutique,  } = params

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Detalles del Servicio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(boutique)
          }

            <small>Boutique</small>
            <h3>{ boutique.idboutique.nombre_boutique }</h3>


            <h6 className=" ">{boutique.idboutique.direccion}</h6>
            <h6 className=" ">{boutique.idboutique.idestado.estado}, {boutique.idboutique.idmunicipio.municipio}</h6>
            <p className="nm"><b>Contacto Boutique: </b> {boutique.idboutique.nombre_contacto} <small><i className="fa fa-phone"/> <a href={`tel:${boutique.idboutique.telefono_contacto}`}>{FormateaNumero(boutique.idboutique.telefono_contacto)}</a></small></p>
            { boutique.idboutique.idresponsable_limpieza !== null && (<p className="nm"><b>Responsable Limpieza: </b> {boutique.idboutique.idresponsable_limpieza.nombres}  <small><i className="fa fa-phone"/> <a href={`tel:${boutique.idboutique.idresponsable_limpieza.celular}`}>{FormateaNumero(boutique.idboutique.idresponsable_limpieza.celular)}</a></small></p>) }
            { boutique.idboutique.idresponsable_mantenimiento!== null && (<p className="nm"><b>Responsable Mantenimiento: </b> {boutique.idboutique.idresponsable_limpieza.nombres}  <small><i className="fa fa-phone"/> <a href={`tel:${boutique.idboutique.idresponsable_limpieza.celular}`}>{FormateaNumero(boutique.idboutique.idresponsable_limpieza.celular)}</a></small></p>) }
            <p className="nm"><b>Día: </b>   <small> {obtenerNombreDia(moment().day())} {moment().format('DD [de] MMMM [de] YYYY')} </small> </p>


            <p className="nm"><b>Indicaciones del Servicio: </b></p>            
            <p className="nm"><small> {(boutique.indicaciones)} </small> </p>
            <ul>
              {
                // boutique.idboutique.limpieza_instrucciones.map((tarea:any)=>
                //     <li key={ Math.random().toString(36).substr(2, 9) }><small>{tarea}</small></li>
                //   )
              }
            </ul> 



          {
            ([3,4].indexOf(boutique.idestatus_atencion.id) > -1 && boutique.detalles_atencion !== '') && (
                    <React.Fragment>
                        <small>Detalles de la Atención</small>
                        <h6>{boutique.detalles_atencion}</h6>
                    </React.Fragment>
              )
          }


                                    {
                                      [7].indexOf(boutique.idtipo_orden.id) > -1 && (
                                          <React.Fragment>
                                            {
                                              boutique.idboutique.conservacion_instrucciones_por_dia !== null && (
                                                  <React.Fragment>
                                                    
                                                      <small className="b">Instrucciones para hoy: </small>
                                                      <p className="nm">
                                                            { moment().day() === 1 && (boutique.idboutique.conservacion_instrucciones_por_dia.lunes||'Realizar actividades normales') }
                                                            { moment().day() === 2 && (boutique.idboutique.conservacion_instrucciones_por_dia.martes||'Realizar actividades normales') }
                                                            { moment().day() === 3 && (boutique.idboutique.conservacion_instrucciones_por_dia.miercoles||'Realizar actividades normales') }
                                                            { moment().day() === 4 && (boutique.idboutique.conservacion_instrucciones_por_dia.jueves||'Realizar actividades normales') }
                                                            { moment().day() === 5 && (boutique.idboutique.conservacion_instrucciones_por_dia.viernes||'Realizar actividades normales') }
                                                            { moment().day() === 6 && (boutique.idboutique.conservacion_instrucciones_por_dia.sabado||'Realizar actividades normales') }
                                                            { moment().day() === 7 && (boutique.idboutique.conservacion_instrucciones_por_dia.domingo||'Realizar actividades normales') }

                                                      </p>

                                                      {
                                                        // JSON.stringify(boutique.idboutique.conservacion_instrucciones_por_dia)
                                                      }

                                                  </React.Fragment>
                                                )
                                            }

                                            {
                                              boutique.idboutique.limpieza_instrucciones !== null && (
                                                  <React.Fragment>
                                                    
                                                      <small className="b">Instrucciones para Limpieza</small>
                                                      <ol >
                                                        {
                                                          boutique.idboutique.limpieza_instrucciones.map((actividad:any,numero_actividad:number)=>
                                                              <li key={numero_actividad}><small>  {actividad}</small></li>
                                                            )
                                                        }
                                                      </ol>

                                                  </React.Fragment>
                                                )
                                            }


                                            {
                                              // JSON.stringify(tareas_trabajadas.limpieza)
                                            }




                                                {
                                                  // JSON.stringify(boutique.actividades_realizadas.limpieza)
                                                }
                                                      {
                                                        // tareas_trabajadas.limpieza.map((actividad:any,index:number)=>
                                                        //     <Form.Check type={'checkbox'} label={`${actividad.actividad}`} checked={actividad.realizada} onChange={(e:any)=>{ let a = {...tareas_trabajadas}; a.limpieza[index].realizada = e.target.checked; setTareasTrabajadas(a)  }} id={`disabled-default-${index}`} />
                                                        //   )
                                                      }
                                                      


                                          </React.Fragment>
                                        )
                                    }





              {
                boutique.fecha_inicio_servicio && (
                    <React.Fragment>

                      <div className="row mt-3">
                        <div className={`col-sm-6`}>
                          <small>Fecha de Inicio del Servicio</small>
                          <h5>{moment(boutique.fecha_inicio_servicio).format('YYYY-MM-DD HH:mm:ss')} hrs</h5>
                        </div>
                        <div className="col-sm-6">
                          {
                            boutique.fecha_fin_servicio && (
                                <React.Fragment>
                                  <small>Fecha de Fin del Servicio</small>
                                  <h5>{moment(boutique.fecha_fin_servicio).format('YYYY-MM-DD HH:mm:ss')} hrs</h5>
                                </React.Fragment>
                              )
                          }
                        </div>
                      </div>

                    </React.Fragment>
                  )
              }




              {
                boutique.fecha_fin_servicio && (
                    <React.Fragment>
                        <small>Tiempo entre reporte de entrada y salida</small>
                        <h5>{diferenciaHoras(boutique.fecha_inicio_servicio, boutique.fecha_fin_servicio)}</h5>
                    </React.Fragment>
                  )
              }






          {
            // JSON.stringify(boutique.idboutique.limpieza_instrucciones)
          }
        </Modal.Body>

      </Modal>
    </>
  );
}


export default ModalInfoVisita;